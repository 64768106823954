import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import "./DrawerOverlay.scss";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import PhaseCalculation from "../Phase/phaseCalculationUtil";
import { Typography, Grid, Box, TextField } from "@material-ui/core";
import { isDigit, getFloat } from "../../../libs/utils";

const useStyles = makeStyles(() => ({
  gutters: {
    padding: "10px 10px 10px 30px",
  },
  toolbarTitle: {
    fontSize: 20,
    lineHeight: 1.25,
    fontWeight: 300,
  },
  headlabel: {
    marginTop: "20px",
    color: "#084d4f",
    fontWeight: "bold",
    fontSize: "12px",
  },
  textField: {
    marginTop: '15px',
    marginBottom: '15px'
  }

}));

function CostRateDrawer(props) {
  const classes = useStyles();

  const {
    resource = {},
    estimate = {},
    updateResourceFn,
    refreshData,
    onClose,
  } = props;
  const [cost, setCost] = useState(0);
  const [rate, setRate] = useState(0);

  useEffect(() => {
    const phaseCalculationCls = new PhaseCalculation({}, {}, estimate);
    const costVal = phaseCalculationCls.resourceCalculate(
      resource,
      "cost",
      false
    );

    const hoursVal = phaseCalculationCls.resourceHours(resource, false) || 1;
    const amountVal = phaseCalculationCls.resourceCalculate(
      resource,
      "amount",
      false
    );

    setCost(costVal / hoursVal);
    setRate(amountVal / hoursVal);
  }, [estimate]);

  const getRangeValue = (input) => {
    let val = input;
    const max = 10000;

    if (val.startsWith('$')) {
      val = val.substring(1, val.length);
      input = val;
    }
    val = val.replace(/,/g, '');
    let temp = NaN;
    try {
      temp = getFloat(val);
    } catch {
      temp = NaN;
    }

    if (temp && temp >= 100000) {
      return;
    }
    if (isNaN(temp)) {
      temp = 0;
    }

    if (temp < 0) {
      temp = 0;
    }
    if (temp > max) {
      return;
    }
    return temp;
  }

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={props.open}
        className={props.clsName + ' new_drawer_item'}
        PaperProps={{
          style: {
            minWidth: 400,
            maxWidth: 400,
          },
        }}
      >
        <Toolbar classes={{ gutters: classes.gutters }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12}>
              <h5>
                Edit Resource Cost/Rate
                <div className="cross" onClick={props.onClose}>
                  <CloseIcon htmlColor="#084d4f" />
                </div>
              </h5>
            </Grid>

          </Grid>
        </Toolbar>
        <Divider />

        <Box px={4} >
          <div className="side-item">

            <div className="input-wrap" >
              <Typography className={classes.headlabel} variant="h3" component="p">
                RESOURCE COST
              </Typography>
              <TextField
                value={`$${cost}`}
                defaultValue={`$${cost}`}
                placeholder="Cost"
                label="Cost"
                aria-labelledby="Cost"
                variant="outlined"
                className={classes.textField}
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (isDigit(e.target.value) === false) {
                    return false;
                  }
                  const temp = getRangeValue(e.target.value);
                  if (!temp) {
                    setCost('');
                    return false;
                  }
                  setCost(temp);
                }
                }
              />
              < Typography className={classes.headlabel} variant="h3" component="p" >
                BILLABLE RATE / HOUR
              </Typography>
              <TextField
                value={`$${rate}`}
                defaultValue={`$${rate}`}
                placeholder="Rate"
                label="Rate"
                aria-labelledby="Rate"
                variant="outlined"
                className={classes.textField}
                style={{ width: "100%" }}
                onChange={(e) => {
                  if (isDigit(e.target.value) === false) {
                    return false;
                  }
                  const temp = getRangeValue(e.target.value);
                  if (!temp) {
                    setRate('');
                    return false;
                  }
                  setRate(temp);
                }}
              />
              <Button
                disabled={!rate || !cost}
                onClick={() => {
                  updateResourceFn({ cost, amount: rate }, resource);
                  refreshData();
                  onClose();
                }}
                variant="contained"
                type="button"
                className="duplicate apply"
              >
                Save
              </Button>
            </div>
          </div>
        </Box>
      </Drawer>
    </React.Fragment >
  );
}

export default withRouter(CostRateDrawer);
