import React from "react";
import latinize from "latinize";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Manage_menu from "../ManageMenuItem";
import Highlighter from "react-highlight-words";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import SvgIcon from "@material-ui/core/SvgIcon";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import PaginationList from "../pagination";
import { isTrue } from "../../../libs/utils";
const styles = (theme) => ({
  panel: {
    marginTop: theme.spacing(1.3),
  },
  subtitle: {
    flex: "0 0 100%",
    maxWidth: "100%",
    marginBottom: 10,
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.23,
    letterSpacing: 0.6,
    textTransform: "uppercase",
    color: "#084d4f",
  },
  title: {
    flex: "0 0 100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  icon: {
    width: 28,
    height: 28,
    marginRight: 10,
    fill: "#000000",
  },
  caption: {
    flex: "0 0 100%",
    maxWidth: "100%",
    marginTop: 5,
    fontSize: "12px",
    lineHeight: 1.23,
  },
  archivetitle: {
    display: "inline-block",
    verticalAlign: "top",
    fontSize: "12px",
    color: "#000000",
    backgroundColor: "rgba(8,77,79,0.10)",
    padding: "5px 11px",
    borderRadius: "13px",
    marginLeft: "10px",
    textTransform: "capitalize",
  },
  boxtitle: {
    display: "inline-block",
    verticalAlign: "top",
  },
  menuItem: {
    padding: "8px 14px !important",
    "&:hover .MuiListItemIcon-root svg path": {
      fill: "#000000",
    },
  },
  listItemWrap: {
    minWidth: 36,
  },
  listIcon: {
    width: 27,
    height: 27,
  },
  pagination: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& nav": {
      boxShadow: "none",
      height: "auto",
      backgroundColor: "transparent",
    },
    "& ul": {
      position: "relative",
      top: "0px",
      width: "100%",
      border: "0px",
      boxShadow: "none",
      backgroundColor: "transparent",
      justifyContent: "center",
    },
  },
});
function AdminIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.614"
      height="21.083"
      viewBox="0 0 21.614 21.083"
      style={{ marginRight: 10 + "px" }}
    >
      <g>
        <g>
          <path
            d="M11.238 10.609a4.275 4.275 0 0 0 0-8.549 4.275 4.275 0 1 0 0 8.549zm0-6.411A2.136 2.136 0 1 1 9.1 6.335 2.137 2.137 0 0 1 11.238 4.2z"
            transform="translate(-2.688 -2.06)"
          />
          <path
            d="M13 18a5.948 5.948 0 0 1 .087-.978H4.826c.245-.769 3.527-2.137 6.412-2.137a11.739 11.739 0 0 1 2.47.288 6.036 6.036 0 0 1 1.5-1.82 15.481 15.481 0 0 0-3.968-.606c-2.85 0-8.55 1.432-8.55 4.275v2.138h10.424A6.076 6.076 0 0 1 13 18z"
            transform="translate(-2.688 -2.06)"
          />
        </g>
        <path
          fillRule="evenodd"
          d="M23.158 18a3.933 3.933 0 0 1-.036.5l1.085.849a.259.259 0 0 1 .062.329l-1.029 1.783a.254.254 0 0 1-.221.129.266.266 0 0 1-.092-.016l-1.281-.514a3.934 3.934 0 0 1-.869.5l-.2 1.363a.251.251 0 0 1-.252.216h-2.053a.252.252 0 0 1-.252-.216l-.195-1.363a3.747 3.747 0 0 1-.869-.5l-1.281.514a.225.225 0 0 1-.087.016.259.259 0 0 1-.227-.129l-1.028-1.779a.259.259 0 0 1 .062-.329l1.085-.853a3.546 3.546 0 0 1 0-1.008l-1.08-.845a.253.253 0 0 1-.062-.329l1.028-1.779a.257.257 0 0 1 .222-.129.274.274 0 0 1 .092.016l1.281.514a3.934 3.934 0 0 1 .869-.5l.195-1.363a.252.252 0 0 1 .252-.216h2.057a.251.251 0 0 1 .252.216l.2 1.363a3.747 3.747 0 0 1 .869.5l1.281-.514a.221.221 0 0 1 .087-.016.257.257 0 0 1 .226.129l1.029 1.779a.259.259 0 0 1-.062.329l-1.085.849a3.933 3.933 0 0 1 .027.504zM19.3 15.943A2.057 2.057 0 1 0 21.358 18a2.056 2.056 0 0 0-2.058-2.057z"
          transform="translate(-2.688 -2.06)"
        />
      </g>
    </svg>
  );
}
function DisciplineIcon({ iconColor, ...props }) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 20.916 18.679"
      >
        <g id="Group_1361" transform="translate(-7142 5735.132)">
          <path
            id="Path_1861"
            d="M1.394 3h18.128a1.612 1.612 0 0 1 1.394 1.756l-.007 12.294a1.607 1.607 0 0 1-1.387 1.756H1.394A1.612 1.612 0 0 1 0 17.05V4.756A1.612 1.612 0 0 1 1.394 3zm0 14.05h18.128V4.756H1.394z"
            className="cls-1"
            fillRule="evenodd"
            transform="translate(7142 -5735.26)"
          />
          <g id="Group_1341" transform="translate(7154.802 -5728.53)">
            <path
              id="Path_1867"
              d="M7 7v1.32h5.509V7z"
              transform="translate(-7 -7)"
            />
            <path
              id="Path_1870"
              d="M7 7v1.32h5.509V7z"
              transform="translate(-7 -4.214)"
            />
          </g>
          <path
            id="Path_1868"
            d="M8.885 4a2.442 2.442 0 1 0 2.442 2.442A2.442 2.442 0 0 0 8.885 4zm1.221 2.442a1.221 1.221 0 1 0-1.221 1.221 1.225 1.225 0 0 0 1.221-1.221zm2.442 5.5c-.122-.434-2.015-1.221-3.663-1.221s-3.541.788-3.663 1.227v.6h7.327zM4 11.938C4 10.313 7.254 9.5 8.885 9.5s4.885.818 4.885 2.442v1.832H4z"
            className="cls-1"
            fillRule="evenodd"
            transform="translate(7141.032 -5733.625)"
          />
          <path
            id="Path_1881"
            fill="none"
            stroke={iconColor ? iconColor : "#000"}
            strokeLinecap="round"
            strokeWidth="2px"
            d="M6018.7-5449.976a2.186 2.186 0 0 1 2.156-2.157 2.185 2.185 0 0 1 2.156 2.157"
            transform="translate(1131.603 -282)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
class InfoPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      textToHighlight: ``,
      activeIndex: -1,
      caseSensitive: false,
      page: 1
    };
  }

  optionChange = (value, itemsid, type) => {
    this.props.optionValue(value, itemsid, type);
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
    });
  };

  estimatesCount = (itemIndex) => {
    for (let i = 0; i < this.props.orgList.length; i++) {
      if (this.props.orgList[i]["id"] === itemIndex) {
        const estList = this.props.orgList[i]["estimates"];
        const estimateList = [];
        if (estList) {
          for (let l = 0; l < estList.length; l++) {
            for (let k = 0; k < this.props.arrayItemEstimate.length; k++) {
              if (
                this.props.arrayItemEstimate[k]["id"] === estList[l]["id"]
              ) {
                estimateList.push({
                  id: this.props.arrayItemEstimate[k]["id"],
                  title: this.props.arrayItemEstimate[k]["projectName"],
                });
              }
            }
          }
        }
        return estimateList.length
      }
    }
    return
  };
  render() {
    const { classes, items = [], paginationLimit = 20, update = 0 } = this.props;
    let menuList = this.props.list;
    const optionChange = this.optionChange;
    const searchText = this.props.searchWords;
    const pagename = this.props.pagename;
    const organizationList = this.props.orgList;
    const { activeIndex, caseSensitive } = this.state;
    const searchWords = searchText.split(/\s/).filter((word) => word);

    return (
      <div>
        <React.Fragment>
          {items
            .slice(
              paginationLimit * this.state.page -
              paginationLimit,
              paginationLimit * this.state.page
            )
            .map((item, index) => {
              const enableDelete = this.props.enableDelete
                ? this.props.enableDelete(item)
                : true;
              if (enableDelete === false) {
                menuList = menuList.filter((d) => d.type !== "Delete");
              }
              const isAdmin = item.subtitle === "admin";
              let PropIcon = isAdmin ? AdminIcon : PersonOutlinedIcon;
              if (item.subtitle === "admin") {
                PropIcon = AdminIcon;
              } else if (item.subtitle === "user") {
                PropIcon = PersonOutlinedIcon;
              } else if (item.subtitle === "Role") {
                PropIcon = PeopleOutlineOutlinedIcon;
              } else if (item.subtitle === "Vendor") {
                PropIcon = WorkOutlineOutlinedIcon;
              }
              //        { icon: <PeopleOutlineOutlinedIcon />, name: 'Role' },
              //{ icon: <WorkOutlineOutlinedIcon />, name: 'Vendor' },
              if (pagename === "discipline") {
                PropIcon = DisciplineIcon;
              } else if (pagename === "tags") {
                PropIcon = LocalOfferOutlinedIcon;
              } else if (pagename === "task") {
                PropIcon = DoneAllOutlinedIcon;
              } else if (pagename === "phases") {
                PropIcon = DateRangeOutlinedIcon;
              } else if (pagename === "people") {
                PropIcon = PermContactCalendarOutlinedIcon;
              } else if (pagename === "organization") {
                PropIcon = BusinessOutlinedIcon;
              }

              const { borderColor = 'red', archive = 'false' } = item;
              const title = item.title || `${item.firstname} ${item.lastname}`;

              return (
                <React.Fragment key={index}>
                  {title.length > 0 ?
                    <Paper
                      key={`${item.id}${update || 0}`}
                      variant="outlined"
                      className={classes.panel}
                      style={{ margin: "0 0 10px" }}
                    >
                      <Box
                        pl={3.5}
                        pr={2.2}
                        py={2.5}
                        borderLeft={3}
                        borderColor={borderColor || 'red'}
                        className={`${isTrue(archive) ? "archiveShow " : ""
                          } ${isTrue(item.deactivate) ? "archiveShow " : ""} ${item.subtitle === "admin" ? "admin " : ""
                          } ${item.unconfirmed === true ? "unconfirmed " : ""}`}
                      >
                        <Grid container>
                          <Grid container alignItems="center" item sm={11} xs={9}>
                            {item.subtitle && (
                              <Typography
                                variant="subtitle1"
                                component="h6"
                                className={classes.subtitle}
                              >
                                <Box fontWeight="fontWeightBold">
                                  {item.subtitle}
                                  {item.admin} {item.contactType}
                                </Box>
                              </Typography>
                            )}
                            <Typography
                              variant="h5"
                              component="h4"
                              className={classes.title}
                              onClick={() =>
                                optionChange("edit", item.id, item.contactType)
                              }
                            >
                              <PropIcon className={classes.icon} />
                              <Box className="box-title" fontWeight={500}>
                                <Highlighter
                                  activeClassName={styles.Active}
                                  activeIndex={activeIndex}
                                  caseSensitive={caseSensitive}
                                  highlightClassName={styles.Highlight}
                                  highlightStyle={{
                                    fontWeight: "900",
                                    backgroundColor: "transparent",
                                  }}
                                  sanitize={latinize}
                                  searchWords={searchWords}
                                  textToHighlight={title}

                                />
                              </Box>
                              {(isTrue(archive) ||
                                isTrue(item.deactivate) ||
                                item.unconfirmed === true) && (
                                  <Typography
                                    component="span"
                                    className={classes.archivetitle}
                                  >
                                    {isTrue(item.archive) && "archived"}
                                    {isTrue(item.deactivate) && "deactivated"}
                                    {item.unconfirmed === true && "Unconfirmed"}
                                  </Typography>
                                )}
                            </Typography>
                            {(item.caption ||
                              item.people ||
                              item.estimates ||
                              item.email ||
                              item.organization) && (
                                <Typography
                                  variant="caption"
                                  component="small"
                                  className={classes.caption}
                                >
                                  {item.caption && item.caption}
                                  {item.people && item.people.length + " people"}
                                  {item.people && item.estimates && ", "}
                                  {item.estimates &&
                                    this.estimatesCount(item.id) + " estimates "}
                                  {item.organization &&
                                    pagename === "people" &&
                                    organizationList.map(function (item) {
                                      return (
                                        <React.Fragment key={item.id}>
                                          {item.id === item.organization && item.title}
                                        </React.Fragment>
                                      );
                                    })}
                                  {item.email && item.organization
                                    ? ", " + item.email
                                    : item.email}
                                </Typography>
                              )}
                          </Grid>
                          <Grid
                            container
                            justify="flex-end"
                            alignItems="center"
                            item
                            sm={1}
                            xs={3}
                          >
                            <Manage_menu
                              optionsList={menuList}
                              itemsid={item.id}
                              type={item.contactType || 'People'}
                              optionValues={optionChange}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                    </Paper>
                    : null}
                </React.Fragment>
              );
            })}

        </React.Fragment>
        <div className={this.props.classes.pagination}>
          {items.length > 10 && (
            <PaginationList
              count={Math.ceil((items.length / paginationLimit))}
              page={this.state.page}
              handlePageChange={this.handlePageChange}
            />
          )}
        </div>
      </div>

    );
  }
}

export default withStyles(styles)(InfoPanel);
