import React from "react";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import AddChips from "../reusable-components/addChips";
import { withStyles } from "@material-ui/core/styles";
import { debounce } from "lodash";
import fire from "../../config/firebase";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});
class ChipsArray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chipData: [],
      chipname: "",
      deleteKey: "",
      arrayAction: "",
    };
    this.chiptags = [...this.state.chipData];
    this.getTable = this.getTable.bind(this);

    this.getKey = this.getKey.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }
  handleDelete(chipToDelete) {
    const { tagsList } = this.props;
    const newTaglist = tagsList.filter((d) => d.title !== chipToDelete);
    const companyId = localStorage.getItem('companyId');
    if (this.getKey() && companyId) {
      const userRef = fire.database().ref(companyId).child(this.getTable()).child(this.getKey());
      userRef.update({
        tags: newTaglist,
      });
      this.props.refreshData();
    }
  }
  handleComboBox = debounce((newValue) => {
    let chipname = "";
    if (newValue) {
      chipname = newValue;
      this.setState({
        chipname: newValue["title"],
        arrayAction: "new",
      });

      if (typeof newValue.title === "object") {
        this.setState({ chipname: newValue.inputValue });
        chipname = newValue.inputValue;
      }
      const tagsList = JSON.parse(JSON.stringify(this.props.tagsList));
      if (chipname) {
        tagsList.push({
          title: chipname.title ? chipname.title : chipname,
        });
        const companyId = localStorage.getItem('companyId');
        const userRef = fire.database().ref(companyId).child(this.getTable()).child(this.getKey());
        userRef.update({
          tags: tagsList,
        });
        this.props.refreshData();
      }
    }
  }, 300);
  getTable() {
    const { dataType, isTemplate } = this.props;
    if (dataType) {
      return dataType;
    }
    if (isTemplate) {
      return "templates";
    }
    return "estimates";
  }
  getKey() {
    const arr = this.props.location.pathname.split('/');
    const key = arr[arr.length - 1];
    return this.props.itemId || key;
  }
  // refreshData() {
  //   const key = this.getKey();
  //   if (key) {
  //     const companyId = this.props.companyId || localStorage.getItem('companyId');
  //     const estimateRef = fire.database().ref(companyId).child(this.getTable()).child(key);
  //     estimateRef.once("value").then((snapshot) => {
  //       const tags = snapshot.val();
  //       estimateRef.off("value");
  //       this.setState({ tagsList: tags ? tags.tags || [] : [] });
  //     });
  //   }
  // }
  componentDidMount() {

  }
  render() {
    const { classes, allTags = [] } = this.props;
    const tempAllTags = allTags.filter(d => { return this.props.tagsList.find(t => (t.title || '').toLowerCase() === (d.title || '').toLowerCase()) === undefined });
    const curKey = this.getKey();
    //triming Tag included here as well.
    function capitalizeFirstLetter(str = "") {
      return str && str.length > 0 ? str.charAt(0).toUpperCase() + (str.length <= 5 ? str.slice(1, 5) : str.slice(1, 5) + '...') : str;
    }
    return (
      <Paper component="ul" className={classes.root}>
        {this.props.tagsList.map(({ title = "" }, index) => {
          return (
            <li
              key={curKey + index}
            //Tags Error
            //className={title.length > 30 ? "errorState" : null}
            >
              <Chip
                disabled={this.props.show ? true : false}
                label={title}
                onDelete={() => this.handleDelete(title)}
                className={classes.chip}
              />
            </li>
          );
        })}
        {this.props.shared !== true && this.props.tagsList.length < 3 ? (
          <AddChips
            key={this.getKey() + "addchips" + tempAllTags.length}
            classes={this.props.classes.addTask}
            resourceItem={this.props.resourceItem}
            title={"+ Tag"}
            show={this.itemShow}
            selectedValue={this.handleComboBox}
            tags={allTags}
            allTags={tempAllTags}
            refreshData={this.props.refreshData}
            disabled={this.props.show}
          />
        ) : (
          ""
        )}
      </Paper>
    );
  }
}

const withstyle = withStyles(styles)(ChipsArray);

export default compose(withRouter)(withstyle);
