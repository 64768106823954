import AddIcon from "@material-ui/icons/Add";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import "../../../assets/scss/autocomplete.scss";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import fire from "../../../config/firebase";
import Moment from "moment";
import { debounce } from "lodash";
import { isTrue, isFalse } from "../../../libs/utils";
const filter = createFilterOptions();

export default function AutoCompleteCmp({
  classes,
  cla,
  id,
  dataType,
  show,
  title,
  mange,
  itemList = [],
  newEstimate,
  newContactValue,
  resourceItem,
}) {
  const companyId = localStorage.getItem('companyId');
  const activeItems = itemList.filter((d) => isFalse(d.archive));
  const [value, setValue] = React.useState(null);

  return (
    <Autocomplete
      autoSelect
      value={value}
      className={cla + " " + classes}
      id={id}
      onChange={debounce((event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          if (dataType === "organization") {
            const listRef = fire.database().ref(companyId).child("organizations");
            const itemData = {
              title: newValue.inputValue,
              date: Moment(new Date()).format("MM/DD/YYYY"),
              contactType: "organization",
              location: "",
              street: "",
              suite: "",
              city: "",
              state: "",
              pin: "",
              archive: "false",
              note: "",
            };
            listRef.push(itemData);
          } else if (dataType === "people") {
            const listRef = fire.database().ref(companyId).child("people");
            const itemData = {
              title: newValue.inputValue,
              date: Moment(new Date()).format("MM/DD/YYYY"),
              contactType: "People",
              jobTitle: "",
              email: "",
              phone: "",
              archive: "false",
              organization: "",
              note: "",
            };
            listRef.push(itemData);
            newContactValue(newValue.inputValue);
          }
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
          if (dataType === "people") {
            newContactValue(newValue);
          }
        }
        if (resourceItem) {
          resourceItem();
        }
        if (show) {
          show();
        }
        //copied these lines
        if (newValue.inputValue) {
          localStorage.setItem(title, newValue.inputValue);
          newEstimate(title, newValue.inputValue);
        } else {
          localStorage.setItem(title, newValue["title"]);
          newEstimate(title, newValue["title"]);
        }
        //to here
        mange();
      })}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        filtered.sort((a, b) => a?.title?.localeCompare(b?.title));
        if (params.inputValue !== "") {
          filtered.push({
            title: (
              <span className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}
              </span>
            ),
            inputValue: params.inputValue,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      blurOnSelect
      handleHomeEndKeys
      options={activeItems}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => <TextField {...params} label={title} />}
    />
  );
}
