import "../libs/gantt/codebase/sources/skins/dhtmlxgantt_material.css";
import "./sources/styles/main.scss";

import React, { useEffect, useRef } from "react";
import * as dhx from "../libs/calendar/codebase/calendar";
import { Gantt } from "../libs/gantt/codebase/sources/dhtmlxgantt";
import GanttView from "./sources/scripts/GanttView";

import { subscribeActions } from "./actionsToSubscribe";

const GanttContainer = ({ estimate, gantt, roles, task,
    onError, onTasksChanged, onRoleNameAdded, updateCnt, refreshCallBack, addResource }) => {

    const containerId = "gantt";
    const ganttView = useRef();
    useEffect(() => {
        loadRoles();
    }, [roles]);

    useEffect(() => {
        window.dhx = dhx;
        window.gantt = Gantt.getGanttInstance();
        return () => {
            if (window.gantt) {
                window.gantt.destructor();
            }
        };
    }, []);

    useEffect(() => {
        if (ganttView.current) {
            const scrollPos = window.gantt.getScrollState();
            ganttView.current.updateGantt(gantt, estimate)
            window.gantt.scrollTo(scrollPos.x, scrollPos.y);
        }
    }, [updateCnt])

    const loadRoles = () => {
        if (roles) {
            const roleList = {
                internal: roles.internal,
                external: roles.external,
                project: roles.project,
            }

            if (ganttView.current) {
                ganttView.current.updateRoleNames(roleList);
            }
        }
    };
    useEffect(() => {

        if (roles && task) {
            const data = {
                data: task.data || [],
                links: task.links || [],
            };

            if (!ganttView.current) {
                ganttView.current = new GanttView(
                    containerId,
                    data,
                    estimate,
                    roles,
                    refreshCallBack,
                    addResource
                );
                subscribeActions(window.gantt, { onError, onTasksChanged, onRoleNameAdded });
                ganttView.current.init();
                loadRoles();
            }
            if (gantt) {
                const scrollPos = window.gantt.getScrollState();
                ganttView.current.updateGantt(gantt, estimate);
                window.gantt.scrollTo(scrollPos.x, scrollPos.y);
            }
        }

    }, [gantt, task, roles]);

    return <div id={containerId} className='gantt-container' />
}

export default GanttContainer
