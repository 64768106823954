import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    resources: null,
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

export const getResourcesAsync = createAsyncThunk(
    'resources/getResources',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const refList = fire.database().ref(companyId).child("resources");
        const snapshotTag = await refList.once("value");
        const resources = snapshotTag.val();
        refList.off("value");
        const temp = [];
        for (const id in resources) {
            temp.push({
                id,
                ...resources[id]
            })
        }
        return { resources: temp };
    }
);

// Redux Toolkit slice
export const getResourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResourcesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getResourcesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.resources = action.payload.resources
            });
    },
});

export default getResourcesSlice.reducer;
