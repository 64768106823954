import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function Billing() {
  return (
    <React.Fragment>
      <Box component="section" className="billing" style={{ minHeight: 'calc(100vh - 60px)', backgroundColor: 'rgba(8, 77, 79, 0.05)', }}>
        <Box className="container">
          <Typography variant="h1" component="h1" style={{ fontWeight: 300, fontSize: 40 }}>
            Billing page coming soon.....
          </Typography>

        </Box>
      </Box>
    </React.Fragment>

  );
}

export default Billing;