import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export default class SideSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showdata: [],
      tasks: [],
    }
  }

  sectionExpand = (e) => {
    e.currentTarget.closest(".inner-content").classList.toggle('active');
  }

  componentDidMount = () => {
    const taskNames = []
    if (this.props.tasks) {
      this.props.tasks.forEach(task => {
        task.taskName.length > 0 ? taskNames.push({ name: task.taskName, id: task.taskId }) : taskNames.push({ name: 'Task Name', id: task.taskId })
      });
      this.setState({ tasks: taskNames })
    }
  }

  taskMap = () => {
    return this.state.tasks.map((task) => (<li key={task.id}><span>{task.name || 'Task Name'}</span></li>))
  }

  render() {
    return (
      <div className="inner-content" data-key={this.props.keyite}>
        <span>
          <p className="phase-text" onClick={this.sectionExpand}>
            <span style={{ 'display': 'inline' }}>
              {this.props.name !== '' ? this.props.name : "Phase Name"}
            </span>
            <ExpandMoreIcon className="arrow-icon" htmlColor='#000' />
          </p>
        </span>

        <ul>
          {this.state.tasks.length > 0 ? this.taskMap() : ''}
        </ul>

      </div>
    )
  }
}