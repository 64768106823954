import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import AllEstimates from "../reusable-components/AllEstimates";

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    tag: {
      background: "transparent",
      border: " 1px solid lightgray",
      boxShadow: "none",
      padding: "1px 10px",
      borderRadius: "20px",
      color: "#000",
      fontSize: "11px",
      width: "auto !important",
      display: "inline-block",
    },
    newestimate: {
      backgroundColor: "#000000",
      color: "#ffffff",
      borderRadius: "50px",
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        backgroundColor: "#000000",
      },
    },
    // formControl: {
    //   margin: "0px",
    //   "& svg": {
    //     color: "#000000",
    //     right: "10px",
    //   },
    // },
    // selectEmpty: {
    //   marginTop: "0px",
    //   "&:after": {
    //     display: "none",
    //   },
    //   "&:before": {
    //     display: "none",
    //   },
    // },
    sortBy: {
      marginTop: "21px",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    sortCol: {
      textAlign: "right",
      position: "relative",
    },
    innerItem: {
      padding: "20px 30px",
    },
    pagination: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      "& nav": {
        boxShadow: "none",
        height: "auto",
        backgroundColor: "transparent",
      },
      "& ul": {
        position: "relative",
        top: "0px",
        width: "100%",
        border: "0px",
        boxShadow: "none",
        backgroundColor: "transparent",
        justifyContent: "center",
      },
    },
  })
);
const Estimates = ({ update = () => { } }) => {
  const classes = useStyles();
  const item = (
    <React.Fragment>
      <AllEstimates classes={classes} update={update} />
    </React.Fragment>
  );
  return <React.Fragment>{item}</React.Fragment>;
};

export default Estimates;
