import PhaseCalculation from "./phaseCalculationUtil";
export default class EstimateCalculationUtil {
  constructor(estimate = {}, calculation = []) {
    this.estimate = Object.assign({}, { ...estimate });
    this.estimate.definedhourlyRate =
      this.estimate.billableSwitch && this.estimate.definedhourlyRate
        ? this.estimate.definedhourlyRate
        : null;
    this.calculation = calculation;
    this.totalEstimatePrice = this.totalEstimatePrice.bind(this);
    this.totalEstimateHours = this.totalEstimateHours.bind(this);
    this.totalEstimateCost = this.totalEstimateCost.bind(this);
    this.totalEstimateProfit = this.totalEstimateProfit.bind(this);
    this.totalEstimateProfitPercentage =
      this.totalEstimateProfitPercentage.bind(this);
    this.getDiscountValue = this.getDiscountValue.bind(this);
    this.getNearest = this.getNearest.bind(this);
    this.getAfterDiscountPrice = this.getAfterDiscountPrice.bind(this);
  }
  getAfterDiscountPrice(round = true) {
    const result = this.getNearest(this.totalEstimatePrice(round) - this.getDiscountValue());
    return result;
  }
  getNearest(value) {
    if (!this.estimate.rounding || !this.estimate.rounding === 'none') {
      return value;
    }
    const rounding = this.estimate.rounding;
    let roundingNumber = 0;
    if (rounding === `nearest10`) {
      roundingNumber = 10;
    }
    if (rounding === `nearest100`) {
      roundingNumber = 100;
    }
    if (rounding === `nearest1000`) {
      roundingNumber = 1000;
    }
    if (roundingNumber > value || !roundingNumber) {
      return value;
    }
    const temp = parseFloat(value) / parseFloat(roundingNumber);
    const temp2 = Math.round(temp);
    const rounded = temp2 * roundingNumber;
    return rounded;
  }
  getDiscountValue() {
    const { discountType } = this.estimate;
    let { discountValue = 0 } = this.estimate;
    if (!discountValue || discountValue === "" || discountValue === "0") {
      return 0;
    }
    if (discountValue === 0) {
      return 0;
    }
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (typeof discountValue !== "number") {
      if (specialChars.test(discountValue)) {
        discountValue = discountValue.replace(/[^a-zA-Z0-9]/g, "");
      }
      discountValue = parseFloat(discountValue);
    }
    if (discountType === "%") {
      const temp = (this.totalEstimatePrice() * discountValue) / 100;
      return Math.round(temp);
    }
    return discountValue;
  }
  totalEstimatePrice(round = true) {
    const { phases = [], expenses = [] } = this.estimate;
    let total = 0;
    for (const phase of phases) {
      if (phase.exclude !== true) {
        const phaseCalculation = new PhaseCalculation(
          phase,
          this.calculation,
          this.estimate
        );
        total += phaseCalculation.totalPhasePrice();
      }
    }

    for (const expense of expenses) {
      if (expense.exclude !== true) {
        const cost = expense.cost * expense.qty
        total += cost;
      }
    }
    return total;
  }
  totalExpensePrice() {
    const { expenses = [] } = this.estimate;
    let total = 0;
    for (const expense of expenses) {
      if (expense.exclude !== true) {
        const cost = expense.cost * expense.qty
        total += cost;
      }
    }

    return total;
  }
  totalEstimateHours() {
    const { phases = [] } = this.estimate;

    let total = 0;
    for (const phase of phases) {
      if (phase.exclude !== true) {
        const phaseCalculation = new PhaseCalculation(
          phase,
          this.calculation,
          this.estimate
        );
        total += phaseCalculation.totalPhaseHours();
      }
    }
    return total;
  }
  totalEstimateCost() {
    const { phases = [] } = this.estimate;
    let total = 0;
    for (const phase of phases) {
      if (phase.exclude !== true) {
        const phaseCalculation = new PhaseCalculation(
          phase,
          this.calculation,
          this.estimate
        );

        total += phaseCalculation.totalPhaseCost();
      }
    }
    return isNaN(total) ? 0 : total;
  }
  totalEstimateProfit() {
    const profit = (
      this.totalEstimatePrice() -
      this.totalEstimateCost() -
      this.getDiscountValue()
    );
    return isNaN(profit) ? 0 : profit;
  }
  totalEstimateProfitPercentage() {
    const profit = this.totalEstimateProfit();
    const price = this.totalEstimatePrice();

    if (!price) {
      return 0;
    }
    const result = Math.round((profit / price) * 100);
    return isNaN(result) ? 0 : result;
  }
}
