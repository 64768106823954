import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    company: {},
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

// asynchronous function with createAsyncThunk
export const getCompanyAsync = createAsyncThunk(
    'company/getCompany',
    async (query = {}) => {

        const companyId = query.companyId || localStorage.getItem('companyId');
        const refCompany = fire.database().ref(companyId).child("company");
        const companySnapshot = await refCompany.once("value");
        const company = companySnapshot.val();
        refCompany.off("value");
        const calculations = (company.arrayItem || []).map((autocalculation) => {
            autocalculation.figure = parseInt(autocalculation.figure);
            return autocalculation;
        });
        return { company, calculations };
    }
);

// Redux Toolkit slice
export const getCompanySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCompanyAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.company = action.payload.company
                state.calculations = action.payload.calculations

            });
    },
});
// export const { getCompany } = getCompanySlice.actions;
// more code...
export default getCompanySlice.reducer;
