import React from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../assets/scss/time-based.scss";
import "../../assets/scss/time-based-summary.scss";
import "../../assets/scss/autocomplete.scss";
import Drag_Icon from "../../assets/images/drag_icon.svg";
import { debounce } from 'lodash';
import Moment from 'moment';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import CalenderDrawer from "../small-components/CalenderDrawer/calender-drawer";
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import AutoCompleteM from '../reusable-components/autoComplete/autoCompleteM';
import Select from '@material-ui/core/Select';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Menu_hover_item from '../reusable-components/ItemHoverTimeBased';
import fire from '../../config/firebase';
import {
  Close,
} from "@material-ui/icons";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  MenuItem
} from "@material-ui/core";
const GreenRadio = withStyles({
  root: {
    color: "#084d4f",
    "&$checked": {
      color: "#084d4f",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
class AddTimeResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarTypes: "week",
      hours: "",
      totalHours: "",
      hourType: "single",
      startDate: Moment(new Date()).format('dddd, MMMM D YYYY'),
      endDate: "",
      finalHours: "",
      weekdays: 0,
      qty: 1,
      inputKey: 1,
      errorState: false,
      resourcesRole: [],
      resourcesVendor: [],
    }
  }

  CalendarTypeFun = debounce((type) => {
    this.setState({ calendarTypes: type });
  }, 100);
  hourHandler = debounce((value) => {
    if (this.state.calendarTypes === "days" && value > 24) {
      this.setState({ errorState: true });
    } else if (this.state.calendarTypes === "week" && value > 168) {
      this.setState({ errorState: true });
    } else {
      this.setState({ errorState: false });
    }
    this.setState({ hours: value });
  }, 100);

  totalhourHandler = debounce((value) => {
    this.setState({ totalHours: value });
  }, 100);
  hourTypes = debounce((value) => {
    this.setState({ hourType: value });
  }, 100);
  onDateChange = debounce((calenderValue) => {
    this.setState({ startDate: Moment(calenderValue[0]).format('dddd, MMMM D YYYY') });
    this.setState({ endDate: Moment(calenderValue[1]).format('dddd, MMMM D YYYY') });
    const totalDays = Math.round((calenderValue[1] - calenderValue[0]) / (1000 * 60 * 60 * 24));
    const now = Moment(calenderValue[0]).clone(), dates = [];
    while (now.isSameOrBefore(Moment(calenderValue[1]))) {
      dates.push(now.format('dddd'));
      now.add(1, 'days');
    }
    let weekends = 0;
    for (let i = 0; i < dates.length; i++) {
      if (dates[i] === "Saturday" || dates[i] === "Sunday") {
        weekends++;
      }
    }
    this.setState({ weekdays: totalDays - weekends });
  }, 100);
  saveHours = debounce((anchor, open, type) => {
    if (this.state.hourType === "single") {
      if (this.state.calendarTypes === "days") {
        this.setState({ finalHours: Math.round(this.state.hours * this.state.weekdays) });
      } else {
        const hoursperDay = this.state.hours / 5;
        this.setState({ finalHours: Math.round(hoursperDay * this.state.weekdays) });
      }
    } else {
      this.setState({ finalHours: Math.round(this.state.totalHours) });
    }
    this.setState({ ...this.state, [anchor]: open, overlayType: type });
  }, 100);
  toggleDrawer = (anchor, open, type) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ ...this.state, [anchor]: open, overlayType: type });
  }
  qtyIncrease = () => {
    this.setState({ qty: this.state.qty + 1 });
  }
  qtyDecrease = () => {
    if (this.state.qty === 1)
      return false;
    this.setState({ qty: this.state.qty - 1 });
  }

  qtydelete = () => {
    this.props.delete(this.props.keyite);
  }
  componentDidMount = () => {
    const companyId = localStorage.getItem('companyId');
    const ref = fire.database().ref(companyId).child('resources');
    ref.once('value').then((snapshot) => {
      const resources = snapshot.val();
      ref.off('value');
      const resourceList = [];
      const role = "Role";
      const vendor = "Vendor";
      let roleList = "", vendorList = "";
      for (const id in resources) {
        resources[id].icon = WorkOutlineOutlinedIcon;
        resourceList.push({ id, ...resources[id] });
      }
      roleList = resourceList.filter(function (i) {
        return (
          i.subtitle.toLowerCase().match(role.toLowerCase())
        )
      });
      vendorList = resourceList.filter(function (i) {
        return (
          i.subtitle.toLowerCase().match(vendor.toLowerCase())
        )
      });
      this.setState({
        resourcesRole: roleList,
        resourcesVendor: vendorList
      })
    }, () => {
      // console.log("Error: " + error.code);
    });
  }
  render() {
    return (
      <React.Fragment>
        <Drawer anchor={'right'} open={this.state.right} onClose={this.toggleDrawer('right', false)}>
          {this.state.overlayType === 'calender' ?
            <div className="calender-drawer">
              <div className="intro-header">
                <Grid item xs={12}>
                  <Typography variant="h2" component="h2">
                    Resource Duration
                  </Typography>
                  <span onClick={this.toggleDrawer('right', false)}>
                    <Close htmlColor="#084d4f" style={{ width: 33, height: 33 }} />
                  </span>
                </Grid>
              </div>
              <Grid container className="manage-info">
                <Grid item xs={1}>
                  <div className="option-outer">
                    <FormControl component="fieldset">
                      <RadioGroup defaultValue="single" aria-label="gender" name="gender1" onChange={(e) => this.hourTypes(e.target.value)}>
                        <FormControlLabel control={<GreenRadio value="single" />} />
                        <FormControlLabel control={<GreenRadio value="total" />} />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={11} className="select-outer">
                  <Grid className="hour-box" item xs={12}>
                    <Grid item xs={2} className={"inputbox"}>
                      {" "}
                      <TextField
                        id="singleHour"
                        className={
                          `${(parseInt(this.state.hours) < 1 || (this.state.errorState) === true) ? "error" : ""}`
                        }
                        variant="outlined"
                        placeholder="0"
                        onChange={(e) => this.hourHandler(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <p>Hours per</p>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={"selectbox"}>
                        <Select
                          defaultValue={"week"}
                          inputProps={{
                            name: "name",
                            id: "uncontrolled-native",
                          }}
                        >
                          <MenuItem value={"days"} onClick={() => this.CalendarTypeFun('days')}>days</MenuItem>
                          <MenuItem value={"week"} onClick={() => this.CalendarTypeFun('week')}>week</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid className="hour-box" item xs={12}>
                    <Grid item xs={2}>
                      {" "}
                      <TextField
                        id="totalHour"
                        className={`${(parseInt(this.state.totalHours) < 1 || parseInt(this.state.totalHours.length) > 4) ? "error" : ""}`}
                        variant="outlined"
                        placeholder="0"
                        onChange={(e) => this.totalhourHandler(e.target.value)} />
                    </Grid>
                    <Grid item xs={10}>
                      <p>Total Hours</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="calender-box">
                <CalenderDrawer datechange={this.onDateChange} type={this.state.calendarTypes} />
              </Grid>
              <Grid item xs={12} className="button-wrap">
                <Button className={`newestimate ${(this.state.hours || this.state.totalHours) && (this.state.endDate && this.state.startDate) ? "" : "disabled"}`} onClick={() => this.saveHours('right', false)}  >Save</Button>
                <span
                  className={"delete-box"} onClick={this.toggleDrawer('right', false)}
                >
                  Delete
                </span>
              </Grid>
            </div>
            : ''}

        </Drawer>
        <Grid container className="item">
          <Grid item xs={6} className="input-box">
            <span className="icon-box">
              <img src={Drag_Icon} alt="drag icon" />
            </span>
            {this.props.type === 'Role' ? <PeopleOutlineOutlinedIcon htmlColor="#2ab7ca"
              className="resource_icon" /> : <WorkOutlineOutlinedIcon htmlColor="#2ab7ca" className="resource_icon" />}
            <AutoCompleteM
              dataList={this.props.type === 'Role' ? this.state.resourcesRole : this.state.resourcesVendor}
              title={'Role Name'} />
          </Grid>

          <Grid item xs={2}>
            <Box className="input-wrap">
              <input value={this.state.qty} type="number" min="0" />
              {this.state.qty < 2 ?
                <DeleteOutlined
                  htmlColor="#000000"
                  fontSize="small"
                  className="delete"
                  onClick={this.qtydelete}
                />
                :
                <RemoveIcon
                  htmlColor="#000000"
                  fontSize="small"
                  className="delete"
                  onClick={this.qtyDecrease}
                />
              }

              <Add
                htmlColor="#000000"
                fontSize="small"
                className="add"
                onClick={this.qtyIncrease}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Close htmlColor="#084d4f99" />
          </Grid>
          <Grid item xs={2}>
            {this.state.finalHours ?
              <input defaultValue={this.state.finalHours} />
              :
              <IconButton aria-label="open calendar" className="add-hours-btn" onClick={this.toggleDrawer('right', true, 'calender')}>
                <AddCircleOutline htmlColor="#084d4f" />
              </IconButton>
            }
            <Menu_hover_item deleteHandle={this.qtydelete} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default AddTimeResources;
