import { Button, Typography } from "@material-ui/core";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import SaveTemplate from "../small-components/SaveTemplate/SaveTemplate";
import Drawer from "@material-ui/core/Drawer";
import DuplicateTemplate from "../small-components/DuplicateTemplate/duplicate-template";
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { getCopyName } from "../../libs/getCopyName";
import { debounce } from "lodash";
import Moment from "moment";
import React from "react";
import loaderimage from "../../assets/images/PageLoader.svg";
import "../../assets/scss/all-estimates.scss";
import fire from "../../config/firebase";
import NoItemAddedEstimate from "./NoItemAddedEstimate";
import AlertDialog from "../small-components/AlertDialog/AlertDialog";
import FilterBox from "../small-components/FilterBox";
import FilterList from "../small-components/FilterList";
// import NoContent from '../small-components/nocontent';
import PaginationList from "../small-components/pagination";
import EstimateFor from "../reusable-components/EstimateFor";
import SettingsTable from "../small-components/SettingTemplate/SettingTemplate";
import { withRouter } from "react-router-dom";
import { isTrue } from "../../libs/utils";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
class AllEstimates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: "",
      orgAge: "",
      key: "",
      typeCast: "",
      arrayItem: [],

      page: 1,
      editList: [
        {
          type: "Edit",
          icon: <CreateIcon htmlColor="#000000" fontSize="small" />,
          id: "edit",
        },
        {
          type: "Save As Template",
          icon: <InsertDriveFileIcon htmlColor="#000000" fontSize="small" />,
          id: "SaveTemplate",
        },
        {
          type: "Duplicate",
          icon: <FileCopyIcon htmlColor="#000000" fontSize="small" />,
          id: "duplicate",
        },
        {
          type: "Delete",
          icon: <DeleteIcon htmlColor="#000000" fontSize="small" />,
          id: "delete",
        },
        {
          type: "Archive",
          icon: <ArchiveOutlinedIcon htmlColor="#000" />,
          id: "archive",
        },
        {
          type: "Unarchive",
          icon: <UnarchiveOutlinedIcon htmlColor="#000" />,
          id: "unarchive",
        },
      ],
      listToShow: [],
      sort: "recent",
      itemKey: "",
      deleteId: "",
      favoriteValue: "",
      dialogOpen: false,
      drawerState: false,
      optionAction: "",
      uniqueId: "",
      loader: true,

      users: [],
      favoriteestimate: [],
      searchKey: "",
      defaultSearchValue: "",
      allTags: [],
      overlayType: "",
      drawerCount: {
        setting: 0
      },
      right: false,
      key: "",
      itemName: "",
      showArchive: false,
      organization: false
    };
    //this.items = [...this.state.EstimateItem];
    this.paginationCount = 6;
    this.refreshData = this.refreshData.bind(this);
  }
  refreshData() {
    if (this.props.update) {
      this.props.update();
    }
    const undoData = [];
    localStorage.setItem("undoData", JSON.stringify(undoData));
    const companyId = localStorage.getItem('companyId');
    const reftagList = fire.database().ref(companyId).child("tags");
    reftagList.once("value").then((snapshot) => {
      const tags = snapshot.val();
      reftagList.off("value");
      const allTags = [];
      for (const id in tags) {
        allTags.push({ id, ...tags[id] });
      }
      this.setState({ allTags });
    });

    const ref = fire.database().ref(companyId).child("estimates");
    ref.once("value").then(
      (snapshot) => {
        const estimates = snapshot.val();
        ref.off("value");
        let estimateList = [];
        for (const id in estimates) {
          if (estimates[id].tags === undefined) {
            estimates[id].tags = [];
          }
          estimateList.unshift({ id, ...estimates[id] });
        }
        this.items = estimateList;
        estimateList = estimateList.filter(function (i) {
          return i.filterType !== undefined
        });
        this.setState({ arrayItem: estimateList, loader: false });
      },
      (error) => {
        console.log("Error: " + error.code);
      }
    );
    //users
    const userListRef = fire.database().ref(companyId).child("users");
    userListRef.once("value").then((snapshot) => {
      const users = snapshot.val();
      userListRef.off("value");
      const userlist = [];
      for (const id in users) {
        userlist.push({ id, database: "users", ...users[id] });
      }
      this.setState({ users: userlist });
    });
    //Current User

    const searchORG = localStorage.getItem("searchText");
    if (searchORG) {
      this.setState({
        defaultSearchValue: searchORG,
        searchKey: searchORG,
      });
    }
    setTimeout(() => {
      localStorage.setItem("searchText", "");
    }, 500);
  }

  componentDidMount = () => {
    this.refreshData();
  };

  closeHandler = () => {
    this.setState({ dialogOpen: false });
  };
  handleSort = (e) => {
    this.setState({ sort: e });
  };
  handleFavorite = (id) => {
    const userEmail = this.props.user.email;
    for (let i = 0; i < this.state.users.length; i++) {
      if (this.state.users[i]["caption"] === userEmail) {
        const userID = this.state.users[i]["id"];
        let favoriteestimate = [];
        if (this.state.users[i]["favoriteestimate"]) {
          favoriteestimate = this.state.users[i]["favoriteestimate"];
        }
        let recentExist = 0;
        let recentExistIndex = 0;
        this.setState({ favoriteestimate: favoriteestimate });
        if (this.state.users[i]["favoriteestimate"]) {
          favoriteestimate = this.state.users[i]["favoriteestimate"];
          for (let k = 0; k < favoriteestimate.length; k++) {
            if (favoriteestimate[k]) {
              if (id === favoriteestimate[k]["id"]) {
                recentExist = 1;
                recentExistIndex = k;
              }
            }
          }
        }
        if (recentExist === 1) {
          favoriteestimate.splice(recentExistIndex, 1);
          const companyId = localStorage.getItem('companyId');
          const userRef = fire.database().ref(companyId).child("users").child(userID);
          userRef.update({
            favoriteestimate: favoriteestimate,
          });
        }
        if (recentExist === 0) {
          favoriteestimate.unshift({ id });
          const companyId = localStorage.getItem('companyId');

          const userRef = fire.database().ref(companyId).child("users").child(userID);
          userRef.update({
            favoriteestimate: favoriteestimate,
          });
        }
      }
    }
    this.refreshData();
  };

  handleArchive = () => {
    const archive = !this.state.showArchive
    this.setState({ showArchive: archive })
  }
  handlePages = (key) => {
    const val = key.toLowerCase().split(" ").join("-");
    if (val === "time-based")
      this.setState({
        typeCast: true,
      });
    else
      this.setState({
        typeCast: true,
      });
  };
  inputText = debounce((e) => {
    this.setState({
      key: e,
      page: 1,
    });
  }, 700);
  handleChange = (event) => {
    this.setState({
      age: event.target.value,
      page: 1,
    });
  };

  handleOrganization = (event) => {
    this.setState({
      orgAge: event.target.value,
      page: 1, organization: true
    });
  };

  handlePageChange = (event, value) => {
    this.setState({
      page: value,
    });
  };

  overlay = (e) => {
    e.preventDefault();
    document.querySelector(".overlay").classList.add("activeOverlay");
    document.querySelector("body").classList.add("estimateAdditon");
  };

  toggleDrawer = (event) => {
    const anchor = "right"
    const open = !this.state.right
    this.setState({
      ...this.state,
      [anchor]: open,
    });
  };

  optionChange = debounce((value, itemIndex) => {
    this.setState({ key: itemIndex })
    const companyId = localStorage.getItem('companyId');
    let estimateRef;
    if (itemIndex) {
      estimateRef = fire.database().ref(companyId).child("estimates").child(itemIndex);

    }
    const item = this.state.arrayItem.find((ele) => ele.id == itemIndex)
    if (value === "Delete") {
      this.setState({
        dialogOpen: true,
        phaseKey: itemIndex,
        optionAction: value,
        drawerState: false,
      });
    } else if (value === "Duplicate") {
      this.toggleDrawer();
      this.setState({ overlayType: "duplicate", itemName: item.projectName });
    } else if (value === "Edit") {
      const item = this.items.find((d) => d.id === itemIndex);
      if (item) {
        this.setState(
          {
            phaseKey: itemIndex,
            optionAction: value,
            drawerState: true,
            drawerType: "old",
            drawerDefaultName: item["title"],
            drawerDefaultColor: item["borderColor"],
            typeCast: true,
          },
          () => {
            let temptype = "";
            if (item.typeOf === "TIME BASED ESTIMATE") {
              temptype = "-timebased";
            }
            this.props.history.push(
              `/estimates${temptype}/` + encodeURI(item.projectName) + "/" + item["id"]
            );
          }
        );
      } else {
        // setTimeout(() => {
        //   this.setState({
        //     optionAction: "",
        //   });
        // }, 1000);
      }
    } else if (value === "Save") {
      this.toggleDrawer()
      this.setState({ overlayType: "save", itemName: item.projectName })
    } else if (value === "Archive") {
      estimateRef.update({ 'archive': true });
    } else if (value === "Unarchive") {
      estimateRef.update({ 'archive': false });
    }
    if (value !== 'Edit') {
      this.refreshData();
    }
  });

  drawerDelete = () => {
    this.setState({
      dialogOpen: true,
      optionAction: "delete",
    });
  };
  deleteHandler = debounce((key) => {
    const companyId = localStorage.getItem('companyId');
    const estimateRef = fire.database().ref(companyId).child("estimates").child(key);
    estimateRef.remove();

    this.setState({
      deleteId: key,
      dialogOpen: false,
      page: 1,
    });
    setTimeout(() => {
      this.setState({
        deleteId: "",
      });
    }, 300);
    this.refreshData();
  });
  searchHandler = debounce((value) => {
    this.setState({
      searchKey: value,
    });
  });
  handleCloseEditScreen = () => {
    this.setState({
      typeCast: false,
    });
  };
  render() {
    const userEmail = this.props.user.email;
    const { searchKey, showArchive } = this.state;
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";

    const th = this;
    let listToShow = this.state.arrayItem;
    let favoriteestimates = [];
    for (let i = 0; i < this.state.users.length; i++) {
      if (this.state.users[i]["caption"] === userEmail) {
        const favoriteestimate = this.state.users[i]["favoriteestimate"];
        favoriteestimates = favoriteestimate;
      }
    }
    // Filters
    if (th.state.organization && th.state.orgAge) {
      listToShow = listToShow.filter(function (i) {
        return (i.organization || '').toLowerCase() === ((th.state.orgAge || '').toLowerCase());
      })
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age === "" &&
      searchKey === ""
    ) {
      listToShow = this.state.arrayItem.sort(function (a, b) {
        if (th.state.sort === "ascending") {
          return a["projectName"].localeCompare(b["projectName"]);
        } else if (th.state.sort === "descending") {
          return b["projectName"].localeCompare(a["projectName"]);
        } else if (th.state.sort === "recent") {
          return b.timestamp - a.timestamp;
        } else {
          return a["id"].toString().localeCompare(b["id"].toString());
        }
      });
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age !== "" &&
      searchKey === ""
    ) {
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["projectName"].localeCompare(b["projectName"]);
          } else if (th.state.sort === "descending") {
            return b["projectName"].localeCompare(a["projectName"]);
          } else if (th.state.sort === "recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          return i.filterType.toLowerCase().match(th.state.age.toLowerCase());
        });
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age === "" &&
      searchKey !== ""
    ) {
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["projectName"].localeCompare(b["projectName"]);
          } else if (th.state.sort === "descending") {
            return b["projectName"].localeCompare(a["projectName"]);
          } else if (th.state.sort === "recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          const search = searchKey.toLowerCase().split(" ").join("_");
          const itemName = (i.organization || "") + " " + i.projectName;
          const name = itemName.toLowerCase().split(" ").join("_");
          return name.toLowerCase().match(search) || (i.tags && i.tags.length && !!i.tags.find(d => d.title && d.title.toLowerCase().match(searchKey) !== null));
        });
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age !== "" &&
      searchKey !== ""
    ) {
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["projectName"].localeCompare(b["projectName"]);
          } else if (th.state.sort === "descending") {
            return b["projectName"].localeCompare(a["projectName"]);
          } else if (th.state.sort === "recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          const search = searchKey.toLowerCase().split(" ").join("_");
          const itemName = i.organization ? i.organization : i.projectName;
          const name = itemName.toLowerCase().split(" ").join("_");
          return (
            name.toLowerCase().match(search) &&
            i.filterType.toLowerCase().match(th.state.age.toLowerCase())
          ) || (i.tags && i.tags.length && !!i.tags.find(d => d.title && d.title.toLowerCase().match(searchKey) !== null));
        });
    } else if (th.state.sort === "favourites") {
      listToShow = [];
      for (let i = 0; i < favoriteestimates.length; i++) {
        for (let l = 0; l < this.state.arrayItem.length; l++) {
          if (this.state.arrayItem[l] && favoriteestimates[i]) {
            if (favoriteestimates[i]["id"] === this.state.arrayItem[l]["id"]) {
              listToShow.push(this.state.arrayItem[l]);
            }
          }
        }
      }
      if (this.state.age !== "" && searchKey === "") {
        listToShow = listToShow.filter(function (i) {
          return i.filterType.toLowerCase().match(th.state.age.toLowerCase());
        });
      } else if (this.state.age === "" && searchKey !== "") {
        listToShow = listToShow.filter(function (i) {
          const search = searchKey.toLowerCase().split(" ").join("_");
          const itemName = i.organization ? i.organization : i.projectName;
          const name = itemName.toLowerCase().split(" ").join("_");
          return name.toLowerCase().match(search) || (i.tags && i.tags.length && !!i.tags.find(d => d.title && d.title.toLowerCase().match(searchKey) !== null))
        });
      } else if (this.state.age !== "" && searchKey !== "") {
        listToShow = listToShow.filter(function (i) {
          const search = searchKey.toLowerCase().split(" ").join("_");
          const itemName = i.organization ? i.organization : i.projectName;
          const name = itemName.toLowerCase().split(" ").join("_");
          return (
            name.toLowerCase().match(search) &&
            i.filterType.toLowerCase().match(th.state.age.toLowerCase())
          );
        });
      }
    }
    const count = Math.ceil(listToShow.length / this.paginationCount);

    if (showArchive) {
      listToShow = listToShow.filter(
        (d) => d.projectName
      );
    } else {
      listToShow = listToShow.filter(
        (d) => !isTrue(d.archive) && d.projectName
      );
    }
    return (
      <React.Fragment>
        {this.state.typeCast ? (
          <section
            className="estimated-list"
            style={{ minHeight: "calc(100vh - 60px)" }}
          >
            <EstimateFor
              closeEditScreen={this.handleCloseEditScreen}
              title={
                isTrue(this.state.typeCast) ? "Time Based" : "Task Based"
              }
              type={this.state.typeCast}
            />
            <SettingsTable
              type={this.state.typeCast}
              update={this.props.update}
            />
          </section>
        ) : (
          <section
            className="all-estimates"
            style={{ minHeight: "calc(100vh - 60px)" }}
          >
            {this.state.loader === true ? (
              <div className="loader-container">
                <object
                  data={loaderimage}
                  type="image/svg+xml"
                  aria-label="loader"
                ></object>
              </div>
            ) : (
              <div className="container">
                <div className="section-intro ">
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ fontSize: "1.65rem" }}
                  >
                    Manage Estimates
                  </Typography>
                  <Button
                    style={{ marginTop: 7 }}
                    className={this.props.classes.newestimate}
                    onClick={this.overlay}
                  >
                    <span className="icon-outer">
                      <AddCircleOutlineIcon htmlColor="#ffffff" />
                    </span>
                    Create New Estimate{" "}
                  </Button>
                </div>

                <div className="filters">
                  <FilterBox
                    handleChange={this.handleChange}
                    age={this.state.age}
                    handleSort={this.handleSort}
                    classes={this.props.classes}
                    change={this.searchHandler}
                    defaultSearchValue={this.state.defaultSearchValue}
                    dataType={dataType}
                    handleArchive={this.handleArchive}
                    handleOrganization={this.handleOrganization}
                    orgAge={this.state.orgAge}
                  />
                </div>
                {listToShow.length > 0 ? (
                  <FilterList
                    showArchive={this.state.showArchive}
                    items={listToShow}
                    page={this.state.page}
                    classes={this.props.classes}
                    list={this.state.editList}
                    date={true}
                    typecast={this.handlePages}
                    handleFavorite={this.handleFavorite}
                    optionChange={this.optionChange}
                    favoriteestimate={favoriteestimates}
                    dataType={dataType}
                    refreshData={this.refreshData}
                    allTags={this.state.allTags}
                    users={this.state.users}
                  />
                ) : (
                  <NoItemAddedEstimate
                    content_type="Estimates"
                    Button_type="Estimate"
                    onClick={this.overlay}
                    createNew={this.handleCreate}
                  />
                )}
                <div className={this.props.classes.pagination}>
                  {count > 1 && (
                    <PaginationList
                      count={count}
                      page={this.state.page}
                      handlePageChange={this.handlePageChange}
                    />
                  )}
                </div>
              </div>
            )}
          </section>
        )}
        <Drawer
          anchor={"right"}
          open={this.state.right}
          onClose={this.toggleDrawer}
        >
          {this.state.overlayType === "save" ? (
            <SaveTemplate
              itemKey={this.state.key}
              projectName={this.state.itemName}
              dataType={"estimates"}
              closeEditScreen={this.handleCloseEditScreen}
              onClose={this.toggleDrawer}
            />) : null}
          {this.state.overlayType === "duplicate" ? (
            <DuplicateTemplate
              itemKey={this.state.key}
              projectName={this.state.itemName}
              onClose={this.toggleDrawer}
              refreshData={this.refreshData}
            />) : null}
        </Drawer>

        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={""}
          text={"Do you want to permanently delete this estimates ?"}
        />
      </React.Fragment>
    );
  }
}

function MapStateToProps(state) {
  return {
    user: state.getUser.user,
  };
}
function MapDispatchToProps() {
  return {

  };
}

export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(AllEstimates);
