import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    tasks: [],
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

// asynchronous function with createAsyncThunk
export const getTasksAsync = createAsyncThunk(
    'tasks/getTasks',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const listRef = fire.database().ref(companyId).child('tasks');
        const set = new Set();
        const snapshot = await listRef.once('value');
        const list = snapshot.val();
        listRef.off('value');
        const listArr = [];
        for (const id in list) {
            if (set.has(list[id].title) === false) {
                listArr.push({ id, ...list[id] });
                set.add(list[id].title);
            }
        }
        //this.setState({ taskList: listArr, loader: false });

        return { tasks: listArr };
    }
);

// Redux Toolkit slice
export const getTasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTasksAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTasksAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tasks = action.payload.tasks
            });
    },
});

export default getTasksSlice.reducer;
