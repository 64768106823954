
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import "../../../assets/scss/autocomplete.scss";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import makeStyles from '@material-ui/core/styles/makeStyles';
const filter = createFilterOptions();
const styles = makeStyles(({
  autoMenuDropdown: {
    '& input':
    {
      border: '0px !important',
      height:
        'auto !important',
      fontSize:
        '14px !important',
      boxShadow:
        'none !important',
    }
    ,
  }
}));
export default function AutoCompleteEstimatic(props) {
  const [value, setValue] = React.useState(null);
  const classes = styles(props);
  return (
    <Autocomplete
      value={value}
      className={props.cla + ' ' + classes.autoMenuDropdown}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
        props.selectedValue(newValue, newValue['email'], props.type);
        setValue({
          value: "",
        });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      id={props.idName}
      options={(props.nameList || []).filter(d => d.archive !== 'true' && d.projectName)}
      filterSelectedOptions={true}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          console.log("string")
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        if (option.projectName) {
          return option.projectName;
        }
      }}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.projectName, inputValue);
        const parts = parse(option.projectName, matches);
        return (
          <div>
            {parts.map((part, index) => (
              <React.Fragment key={index}>
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 300 }}>
                  {part.text}
                </span>
              </React.Fragment>
            ))}
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={props.labelName} margin="normal" />
      )}
    />

  );
}