import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import "../../../assets/scss/autocomplete.scss";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import makeStyles from '@material-ui/core/styles/makeStyles';
const filter = createFilterOptions();
const styles = makeStyles(({
  autoMenuDropdown: {
    '& input':
    {
      border: '0px !important',
      height:
        'auto !important',
      fontSize:
        '14px !important',
      boxShadow:
        'none !important',
    }
    ,
  }
}));
export default function FreeSoloCreateOption(props) {
  const [value, setValue] = React.useState(null);
  const classes = styles(props);
  console.log(props.nameList)

  return (
    <Autocomplete
      value={value}
      className={props.cla + ' ' + classes.autoMenuDropdown}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
        props.selectedValue(newValue, newValue['email'], props.type);
        setValue({
          value: "",
        });
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        params.inputValue = (params.inputValue).replace(/\s\s+/g, ' ').replace(/^\s+|\s+$/g, '');
        if (params.inputValue !== '') {
          filtered.push({
            title: (<span className={`add-button ${props.classes}`}><AddIcon /> Add "{params.inputValue}" as a new {props.type}</span>),
            inputValue: params.inputValue,
          });
        }
        return filtered;
      }}
      id={props.idName}
      options={(props.nameList || []).filter(d => d.archive !== 'true')}
      filterSelectedOptions={true}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);
        const matchesEmail = match(option.email, inputValue);
        const partsEmail = parse(option.email, matchesEmail);
        return (
          <div>
            {parts.map((part, index) => (
              <React.Fragment key={index}>
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 300 }}>
                  {part.text}
                </span>
              </React.Fragment>
            ))}
            <div style={{ display: "block" }}></div>
            {partsEmail.map((part, index) => (
              <React.Fragment key={index}>
                <span key={index + "1"} style={{ fontWeight: part.highlight ? 700 : 300, fontSize: '11px' }}>
                  {part.text}
                </span>
              </React.Fragment>
            ))}
          </div>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={props.labelName} margin="normal" />
      )}
    />

  );
}