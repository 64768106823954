import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIconOutlined from "@material-ui/icons/EditOutlined";
import EditModal from "./EditModal";
import '../../../assets/scss/item-hover-based.scss';
import RestoreIcon from '@material-ui/icons/Restore';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import fire from "../../../config/firebase";
import { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Moment from "moment";
const useStyles = makeStyles(() => ({
  buttonHover: {
    '& svg': {
      fill: '#ffff !important'
    },
    "&:hover": {
      backgroundColor: '#084d4f0d',
      '& button': {
        '&:hover': {
          '& svg': {
            fill: '#e70a89 !important'
          }
        }
      },
    },
  },
  dotMenu: {
    marginRight: '5px',
    marginLeft: '-30px',
    justifyContent: 'flex-end',
    width: "20px",
    display: 'flex',
    marginBottom: '10px',
  },
}));

function BackupWithDotMenu(props) {
  const classes = useStyles();
  const {
    estimateBackup,
    allUsers,
    query,
    refreshData,
    refreshDataEstimate,
    close
  } = props;

  const backupDate = Moment(new Date(estimateBackup?.date)).format("MMMM DD, HH:MM A");
  const backupUser = estimateBackup?.estimate?.lastEditor;
  const username = allUsers?.find((user) => user.id === backupUser);
  const [open, setOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const restoreBackup = async () => {
    if (estimateBackup) {
      const estimateRef = fire.database().ref(query.companyId).child(query.dataType).child(query.estimateKey);
      estimateRef.update(estimateBackup.estimate);
      refreshDataEstimate();
      debugger;
    }
    close();
  }
  const handleRestore = () => {
    setOpen((prevOpen) => !prevOpen);
    restoreBackup();
  };
  const handleEdit = () => {
    setOpen((prevOpen) => !prevOpen);
    setShowEditModal(true)
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (event) => {
    event.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  if (!estimateBackup?.estimate) {
    return null;
  }

  return (
    <div className={'hover-wrap'}>
      {showEditModal &&
        <EditModal
          open={showEditModal}
          refreshData={refreshData}
          backupId={estimateBackup.date}
          defaultValue={estimateBackup.name || backupDate}
          query={query}
          close={close}
          closeDialog={() => setShowEditModal(false)}
          estimateKey={estimateBackup.estimateKey}
        />}
      <div className={classes.buttonHover}>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          onClick={handleToggle}
        >
          <div className="col">
            <div><span className="modifier_name_date">{estimateBackup.name || backupDate}</span></div>
            <div><span className="modifier_name_date">{username?.firstname}</span></div>
          </div>
          <div className={classes.dotMenu}>
            <MoreVertIcon />
          </div>
        </Button >
      </div>
      <Popper style={{ zIndex: 100 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom', zIndex: 9,
            }}
          >
            <Paper className="select-history-menu-wrap" >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-summary" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={() => handleRestore()}>
                    <RestoreIcon htmlColor="#000" />
                    Restore
                  </MenuItem>
                  <MenuItem onClick={() => handleEdit()}>
                    <EditIconOutlined htmlColor="#000" />
                    Rename
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default withRouter(BackupWithDotMenu);
