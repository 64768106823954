import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import '../../../assets/scss/item-hover-based.scss';
import MenuItem from '@material-ui/core/MenuItem';
import { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import MenuDropdown from "../MenuDropdown/MenuDropdown";
import fire from "../../../config/firebase";
import Box from "@material-ui/core/Box";
import BackupWithDotMenu from "./BackupWithDotMenu";
import * as getUsersActionCreators from '../../../store/users/getUsers';
import * as getEstimateBackupActionCreators from '../../../store/estimateBackup/getEstimateBackup';

import Moment from "moment";
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'absolute',
    right: '-49px',
    top: '13px'
  },
  gutters: {
    padding: "10px 10px 10px 30px",
  },
  headlabel: {
    marginTop: "25px",
    marginBottom: "2px",
    color: "#084d4f",
    fontWeight: "bold",
    fontSize: "12px",
  },
  searchField: {
    marginLeft: "10px",
    marginBottom: "10px"
  },

  menuDropdown: {
    width: "260px"
  },
  menuDropdownInput: {
    padding: "5.5px 10px",
    fontWeight: 300,
  },
  menuDropdownIcon: {
    marginRight: "5.5px",
  },

}));

function HistoryDrawer(props) {
  const classes = useStyles();
  const {
    // estimate = {},
    query = {},
    refreshData: refreshDataEstimate,
    users,
    estimateBackup
  } = props;

  const estimateBackupList = Object.values(estimateBackup || {});

  const [backupFiltered, setBackupFiltered] = useState([]);
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [currentUserUid, setCurrentUserUid] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const currentUser = users?.find((user) => user.id === currentUserUid);
  const sortedBackupFiltered = backupFiltered?.sort((b, a) => a.date - b.date).filter(d => d.estimate);
  if (sortedBackupFiltered.length) {
    debugger;
  }
  const [lastEdited, setLastEdited] = useState("");

  const refreshData = () => {
    fetchUsers();
    const { getUsersAsync } = props.getUsersAction;
    getUsersAsync({});
    const { getEstimateBackupAsync } = props.getEstimateBackupAction;
    getEstimateBackupAsync(query);
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (estimateBackupList.length) {
      const lastBackup = estimateBackupList[estimateBackupList.length - 1];
      setLastEdited(Moment(new Date(lastBackup?.estimate?.modified)).format("MMMM YYYY"));
      setBackupFiltered(estimateBackupList);
    }
  }, [JSON.stringify(estimateBackupList)]);

  useEffect(() => {
    if (users?.length) {
      debugger;
      if (estimateBackupList.length === 0) {
        setUsersFiltered(users);
      } else {
        const userSet = new Set([estimateBackupList.map(d => d.estimate.lastEditor)]);
        setUsersFiltered(users.filter(u => userSet.has(u.id)));
      }
    }
  }, [JSON.stringify(users), JSON.stringify(estimateBackupList)]);

  const fetchUsers = async () => {
    const currentUser = fire.auth().currentUser;
    setCurrentUserUid(currentUser.uid);
  };

  const handleSelectedUser = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setBackupFiltered(estimateBackupList);
    } else if (value === currentUser.id) {
      const newList = estimateBackupList.filter((backup) => backup.estimate.lastEditor === value)
      setBackupFiltered(newList)
    } else {
      const newList = estimateBackupList.filter((backup) => backup.estimate.lastEditor === value)
      setBackupFiltered(newList)
    }
    setSelectedUser(event.target.value);
  };

  return (
    <div className="new_drawer_item">
      <Toolbar classes={{ gutters: classes.gutters }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <h5>
              Version History
              <div className="cross" onClick={props.onClose}>
                <CloseIcon htmlColor="#084d4f" />
              </div>
            </h5>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <Box >
        <Box className={classes.searchField} px={3}>
          <Typography className={classes.headlabel} variant="h3" component="p">
            LAST EDITED ON {lastEdited?.toUpperCase()}
          </Typography>
          <Grid >
            <MenuDropdown
              showLabel={true}
              label=" Filter By User"
              className={classes.menuDropdown}
              selectInputProps={{
                classes: {
                  root: classes.menuDropdownInput,
                  icon: classes.menuDropdownIcon,
                },
              }}
              value={selectedUser}
              change={handleSelectedUser}
            >
              <MenuItem value="all">
                All versions
              </MenuItem>
              <MenuItem value={currentUser?.id}>
                {currentUser?.firstname}
              </MenuItem>
              {usersFiltered.filter((user) => user?.id !== currentUser?.id).map((user, index) =>
                <MenuItem key={index} value={user.id}>{user.firstname}</MenuItem>
              )}
            </MenuDropdown>
          </Grid>
        </Box>
        {sortedBackupFiltered?.map((backup, index) =>
          <BackupWithDotMenu
            key={backup.id + index}
            allUsers={users}
            estimateBackup={backup}
            query={query}
            refreshData={refreshData}
            refreshDataEstimate={refreshDataEstimate}
            close={props.onClose}
          />
        )}
      </Box>
    </div>
  );
}

function MapStateToProps(state) {
  return {
    users: state.getUsers.users,
    estimateBackup: state.getEstimateBackup.estimateBackup,
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getUsersAction: bindActionCreators(
      getUsersActionCreators,
      dispatch
    ),
    getEstimateBackupAction: bindActionCreators(
      getEstimateBackupActionCreators,
      dispatch
    ),
  };
}

export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(HistoryDrawer);
