import React from "react";
import { compose, bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import FilterList from "../../small-components/FilterList";
import "./template-list.scss";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FilterBox from "../../small-components/FilterBox";
import PaginationList from "../../small-components/pagination";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { debounce } from "lodash";
import SettingsTable from "../SettingsTable";
import EstimateFor from "../EstimateFor";
import AlertDialog from "../../small-components/AlertDialog/AlertDialog";
// import NoContent from '../../small-components/nocontent';
import CreateTemplate from "../../reusable-components/createTemplate";
import fire from "../../../config/firebase";
import { isTrue } from "../../../libs/utils";
import SaveTemplate from "../../small-components/SaveTemplate/SaveTemplate";
import DuplicateTemplate from "../../small-components/DuplicateTemplate/duplicate-template";
import Drawer from "@material-ui/core/Drawer";

class TemplateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      age: "",
      orgAge: "",
      key: "",
      typeCast: "",
      ListItem: [],
      editList: [
        {
          type: "Create Estimate",
          icon: <TouchAppIcon htmlColor="#000000" fontSize="small" />,
          id: "create-estimates",
        },
        {
          type: "Edit",
          icon: <CreateIcon htmlColor="#000000" fontSize="small" />,
          id: "edit",
        },
        {
          type: "Duplicate",
          icon: <FileCopyIcon htmlColor="#000000" fontSize="small" />,
          id: "duplicate",
        },
        {
          type: "Delete",
          icon: <DeleteIcon htmlColor="#000000" fontSize="small" />,
          id: "delete",
        },
      ],
      anchorStyle: {
        fontSize: "20px",
        textDecoration: "underline",
        position: "absolute",
        top: "0",
        zIndex: "99999999",
        cursor: "pointer",
      },
      sort: "recent",
      itemKey: "",
      deleteId: "",
      favoriteValue: "",
      dialogOpen: false,
      optionAction: "",
      uniqueId: "",
      newTemplateArray: "",
      loader: true,
      arrayItem: [],
      users: [],
      favoriteestimate: [],
      searchKey: "",
      dataLength: 0,
      overlayType: "",
      drawerCount: {
        setting: 0
      },
      right: false,
      itemName: "",
      organization: false
    };
    this.paginationCount = 6;
    this.refreshData = this.refreshData.bind(this);
  }
  handleSort = (e) => {
    this.setState({ sort: e });
  };
  handleFavorite = (id) => {
    for (let i = 0; i < this.state.users.length; i++) {
      if (this.state.users[i]["caption"] === this.props.user.email) {
        const userID = this.state.users[i]["id"];
        let favoriteItems = [];
        if (this.state.users[i]["favoritetemplates"]) {
          favoriteItems = this.state.users[i]["favoritetemplates"];
        }
        let recentExist = 0;
        const recentExistIndex = 0;
        this.setState({ favoriteestimate: favoriteItems });
        if (this.state.users[i]["favoritetemplates"]) {
          favoriteItems = this.state.users[i]["favoritetemplates"];
          for (let k = 0; k < favoriteItems.length; k++) {
            if (id === favoriteItems[k]["id"]) {
              recentExist = 1;
            }
          }
        }
        const companyId = localStorage.getItem('companyId');
        if (recentExist === 1) {
          favoriteItems.splice(recentExistIndex, 1);
          const userRef = fire.database().ref(companyId).child("users").child(userID);
          userRef.update({
            favoritetemplates: favoriteItems,
          });
        }
        if (recentExist === 0) {
          favoriteItems.unshift({ id });
          const userRef = fire.database().ref(companyId).child("users").child(userID);
          userRef.update({
            favoritetemplates: favoriteItems,
          });
        }
      }
    }
    this.refreshData();
  };
  inputText = debounce((e) => {
    this.setState({
      key: e,
      page: 1,
    });
  }, 700);
  handlePageChange = (event, value) => {
    this.setState({
      page: value,
    });
  };
  handleChange = (event) => {
    this.setState({
      age: event.target.value,
      page: 1,
    });
  };

  handleOrganization = (event) => {
    this.setState({
      orgAge: event.target.value,
      page: 1,
      organization: true
    });
  };

  handlePages = (key) => {
    const val = key.toLowerCase().split(" ").join("-");
    if (val === "time-based")
      this.setState({
        typeCast: true,
      });
    else
      this.setState({
        typeCast: true,
      });
  };
  toggleDrawer = () => {
    const anchor = "right"
    const open = !this.state.right
    this.setState({
      ...this.state,
      [anchor]: open,
    });
  };
  optionChange = debounce((value, itemIndex) => {
    this.setState({ key: itemIndex })
    const item = this.state.arrayItem.find((ele) => ele.id === itemIndex)

    if (value === "Delete") {
      this.setState({
        dialogOpen: true,
        phaseKey: itemIndex,
        optionAction: value,
        drawerState: false,
      });
    } else if (value === "Duplicate") {
      this.toggleDrawer()
      this.setState({ overlayType: "duplicate", itemName: item.projectName })
    } else if (value === "Edit") {
      if (item) {
        this.setState(
          {
            phaseKey: itemIndex,
            optionAction: value,
            drawerState: true,
            drawerType: "old",
            drawerDefaultName: item["title"],
            drawerDefaultColor: item["borderColor"],
            typeCast: true,
          },
          () => {
            let temptype = "";
            if (item.typeOf === "TIME BASED ESTIMATE") {
              temptype = "-timebased";
            }
            this.props.history.push(
              `/templates${temptype}/` + encodeURI(item.projectName) + "/" + item["id"]
            );
          }
        );
      } else {
        setTimeout(() => {
          this.setState({
            optionAction: "",
          });
        }, 1000);
      }
      // for (const item of this.state.ListItem) {
      //   if (item["id"] === itemIndex) {
      //     this.setState({
      //       phaseKey: itemIndex,
      //       optionAction: value,
      //       drawerState: true,
      //       drawerType: "old",
      //       drawerDefaultName: item["title"],
      //       drawerDefaultColor: item["borderColor"],
      //       typeCast: true,
      //     });
      //   }
      // }
      // setTimeout(() => {
      //   this.setState({
      //     optionAction: "",
      //   });
      // }, 1000);
      // this.props.checkEditScreen("template");
    } else if (value === "Estimate") {
      this.toggleDrawer()
      this.setState({ overlayType: "save", itemName: item.projectName })
    }
    this.refreshData();
  });
  drawerDelete = () => {
    this.setState({
      dialogOpen: true,
      optionAction: "delete",
    });
  };
  closeHandler = () => {
    this.setState({ dialogOpen: false });
  };
  deleteHandler = debounce((key) => {
    const companyId = localStorage.getItem('companyId');
    const estimateRef = fire.database().ref(companyId).child("templates").child(key);
    estimateRef.remove();
    this.setState({
      deleteId: key,
      dialogOpen: false,
      page: 1,
    });
    setTimeout(() => {
      this.setState({
        deleteId: "",
      });
    }, 300);
    this.refreshData();
  });
  templateId = (arr) => {
    this.setState({ newTemplateArray: arr });
    setTimeout(() => {
      this.setState({
        newTemplateArray: "",
      });
    }, 200);
  };
  createNewTemplate = (e) => {
    e.preventDefault();
    document.querySelector(".overlay").classList.add("activeOverlay");
    document.querySelector("body").classList.add("estimateAdditon");
  };
  searchHandler = debounce((value) => {
    this.setState({
      searchKey: value,
    });
  });
  refreshData() {
    const companyId = localStorage.getItem('companyId');
    const reftagList = fire.database().ref(companyId).child("tags");
    reftagList.once("value").then((snapshot) => {
      const tags = snapshot.val();
      reftagList.off("value");
      const allTags = [];
      for (const id in tags) {
        allTags.push({ id, ...tags[id] });
      }
      this.setState({ allTags });
    });
    const ref = fire.database().ref(companyId).child("templates");
    ref.once("value").then(
      (snapshot) => {
        const templates = snapshot.val();
        ref.off("value");
        const templatesList = [];
        for (const id in templates) {
          if (!templates[id].tags) {
            templates[id].tags = [];
          }
          templatesList.unshift({ id, ...templates[id] });
        }
        this.setState({
          ListItem: templatesList,
          arrayItem: templatesList,
          dataLength: templatesList.length,
          loader: false,
        });
      },
      (error) => {
        console.log("Error: " + error.code);
      }
    );
    if (this.props.update) {
      this.props.update();
    }

  }
  componentDidMount = () => {
    this.refreshData();

    //users
    const companyId = localStorage.getItem('companyId');
    const userListRef = fire.database().ref(companyId).child("users");
    userListRef.once("value").then((snapshot) => {
      const users = snapshot.val();
      userListRef.off("value");
      const userlist = [];
      for (const id in users) {
        userlist.push({ id, database: "users", ...users[id] });
      }
      this.setState({ users: userlist });
    });
    //Current User

  };
  handleCloseEditScreen = () => {
    this.setState({
      typeCast: false,
    });
  };
  render() {
    const { searchKey } = this.state;
    const { estimateType, user } = this.props;
    const th = this;
    let listToShow = this.state.arrayItem ? [...this.state.arrayItem] : [];
    let favoriteestimates = [];
    for (let i = 0; i < this.state.users.length; i++) {
      if (this.state.users[i]["caption"] === user.email) {
        const favoriteestimate = this.state.users[i]["favoritetemplates"];
        favoriteestimates = favoriteestimate;
      }
    }
    // Filters
    if (th.state.organization) {
      listToShow = listToShow.filter(function (i) {
        return (i.organization || '').toLowerCase() === ((th.state.orgAge || '').toLowerCase());
      })
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age === "" &&
      searchKey === ""
    ) {
      listToShow = this.state.arrayItem.sort(function (a, b) {
        if (th.state.sort === "ascending") {
          return a["projectName"].localeCompare(b["projectName"]);
        } else if (th.state.sort === "descending") {
          return b["projectName"].localeCompare(a["projectName"]);
        } else if (th.state.sort === "recent") {
          return b.timestamp - a.timestamp;
        } else {
          return a["id"].toString().localeCompare(b["id"].toString());
        }
      });
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age !== "" &&
      searchKey === ""
    ) {
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["projectName"].localeCompare(b["projectName"]);
          } else if (th.state.sort === "descending") {
            return b["projectName"].localeCompare(a["projectName"]);
          } else if (th.state.sort === "recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          return i.filterType.toLowerCase().match(th.state.age.toLowerCase());
        });
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age === "" &&
      searchKey !== ""
    ) {
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["projectName"].localeCompare(b["projectName"]);
          } else if (th.state.sort === "descending") {
            return b["projectName"].localeCompare(a["projectName"]);
          } else if (th.state.sort === "recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          return i.projectName
            .toLowerCase()
            .match(th.state.searchKey.toLowerCase()) || (i.tags && i.tags.length && !!i.tags.find(d => d.title && d.title.toLowerCase().match(searchKey) !== null));
        });
    } else if (
      th.state.sort !== "favourites" &&
      this.state.age !== "" &&
      searchKey !== ""
    ) {
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["projectName"].localeCompare(b["projectName"]);
          } else if (th.state.sort === "descending") {
            return b["projectName"].localeCompare(a["projectName"]);
          } else if (th.state.sort === "recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          return (
            i.projectName
              .toLowerCase()
              .match(searchKey.toLowerCase()) &&
            i.filterType.toLowerCase().match(th.state.age.toLowerCase())
          ) || (i.tags && i.tags.length && !!i.tags.find(d => d.title && d.title.toLowerCase().match(searchKey) !== null));
        });
    } else if (th.state.sort === "favourites") {
      listToShow = [];
      for (let i = 0; i < favoriteestimates.length; i++) {
        for (let l = 0; l < this.state.arrayItem.length; l++) {
          if (favoriteestimates[i]["id"] === this.state.arrayItem[l]["id"]) {
            listToShow.push(this.state.arrayItem[l]);
          }
        }
      }
      if (this.state.age !== "" && searchKey === "") {
        listToShow = listToShow.filter(function (i) {
          return i.filterType.toLowerCase().match(th.state.age.toLowerCase());
        });
      } else if (this.state.age === "" && searchKey !== "") {
        listToShow = listToShow.filter(function (i) {
          return i.projectName
            .toLowerCase()
            .match(searchKey.toLowerCase());
        });
      } else if (this.state.age !== "" && searchKey !== "") {
        listToShow = listToShow.filter(function (i) {
          return (
            i.projectName
              .toLowerCase()
              .match(searchKey.toLowerCase()) &&
            i.filterType.toLowerCase().match(th.state.age.toLowerCase())
          );
        });
      }
    }
    listToShow = listToShow.filter(
      (d) => !isTrue(d.archive) && d.projectName
    );
    const count = Math.ceil(listToShow.length / this.paginationCount);
    const showEmpty = () => {
      const res = listToShow.length === 0 && this.state.loader === false;
      return res;
    };
    return (
      <React.Fragment>
        {this.state.typeCast && (
          <React.Fragment>
            <EstimateFor
              closeEditScreen={this.handleCloseEditScreen}
              title={
                isTrue(this.state.typeCast) ? "Time Based" : "Task Based"
              }
              type={this.state.typeCast}
            />
            <SettingsTable
              type={this.state.typeCast}
              update={this.props.update}
            />
          </React.Fragment>
        )}

        {
          <div className="time-based-table">
            <div className="section-intro">
              <Typography variant="h5" gutterBottom>
                Manage Templates
              </Typography>
              <Button
                className={this.props.classes.newestimate}
                onClick={this.createNewTemplate}
              >
                Create Template
              </Button>
            </div>
            <div className="filters">
              <FilterBox
                handleChange={this.handleChange}
                age={this.state.age}
                handleSort={this.handleSort}
                classes={this.props.classes}
                placeholder="Search Template"
                change={this.searchHandler}
                defaultSearchValue=""
                handleOrganization={this.handleOrganization}
                orgAge={this.state.orgAge}
              />
            </div>
            {showEmpty() ? (
              <CreateTemplate
                classes={this.props.classes}
                templateId={this.templateId}
                createTemplate={this.createNewTemplate}
                estimateType={estimateType}
                key={listToShow.length + "create"}
                listToShow={listToShow}
                refreshData={this.refreshData}
              />
            ) : null}
            <FilterList
              items={listToShow.length ? listToShow : []}
              page={this.state.page}
              classes={this.props.classes}
              list={this.state.editList}
              date={true}
              typecast={this.handlePages}
              // overlay={this.createNew}
              handleFavorite={this.handleFavorite}
              optionChange={this.optionChange}
              favoriteestimate={favoriteestimates}
              dataType="templates"
              allTags={this.state.allTags}
              refreshData={this.refreshData}
              users={this.state.users}
            />

            <div className={this.props.classes.pagination}>
              {count > 1 && (
                <PaginationList
                  count={count}
                  page={this.state.page}
                  handlePageChange={this.handlePageChange}
                />
              )}
            </div>
          </div>
        }
        <Drawer
          anchor={"right"}
          open={this.state.right}
          onClose={this.toggleDrawer}
        >
          {this.state.overlayType === "save" ? (
            <SaveTemplate
              itemKey={this.state.key}
              dataType={"estimates"}
              projectName={this.state.itemName}
              closeEditScreen={this.handleCloseEditScreen}
              onClose={this.toggleDrawer}
            />) : null}
          {this.state.overlayType === "duplicate" ? (
            <DuplicateTemplate
              itemKey={this.state.key}
              projectName={this.state.itemName}
              onClose={this.toggleDrawer}
              refreshData={this.refreshData}
            />) : null}
        </Drawer>

        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={""}
        />
      </React.Fragment>
    );
  }
}

function MapStateToProps(state) {
  return {
    user: state.getUser.user,
  };
}
function MapDispatchToProps() {
  return {

  };
}

export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(TemplateList);