import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    users: [],
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

export const getUsersAsync = createAsyncThunk(
    'users/getUsers',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const userListRef = fire.database().ref(companyId).child("users");
        const userSnap = await userListRef.once("value");
        const usersSnap = userSnap.val() || {};
        userListRef.off("value");
        const userlist = [];

        for (const id in usersSnap) {
            userlist.push({ id, database: "users", ...usersSnap[id] });
        }

        return { users: userlist };
    }
);

// Redux Toolkit slice
export const getUsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUsersAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.users = action.payload.users
            });
    },
});

export default getUsersSlice.reducer;
