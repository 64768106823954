import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import {
  Typography,
  Grid,
  Link
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './login.scss';
import estimatic_logo from '../../../assets/images/Estimatic_Logo.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import $ from "jquery";

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    padding: '50px 0',
    alignItems: 'center',
    height: 'calc(100vh - 60px)',
    appearance: 'none',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  img_wrap: {
    maxWidth: '200px',
    width: '100%'
  },
  large_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: '#fff',
    padding: '59px 50px !important'

  },
  text_wrap: {
    fontSize: '20px',
    fontWeight: "100",
    paddingTop: '14px',
    marginBottom: '60px',
    lineHeight: 1.36

  },
  small_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderLeft: '0',
    borderRadius: '5px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    backgroundColor: '#fff',
    padding: '55px 50px 30px !important',
    maxWidth: '430px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },
  btn_wrap: {
    background: '#e70a89!important',
    width: '100%',
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'inherit',
    boxShadow: 'none',
    minHeight: '40px',
    '&:hover': {
      boxShadow: 'none',
      background: '#d00a7b !important',
    }
  },
  wrap_item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px'
  },

  bottom_item: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  errorMsg: {
    '& .MuiAlert-filledError': {
      backgroundColor: '#db263b',
      fontWeight: 300
    }
  }
}));
export default function LoginForm({ storedEmail, submit, change, forget, error }) {

  // const [open, setOpen] = React.useState(true);
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [remember, setRemember] = useState(storedEmail ? true : false)

  const [email, setEmail] = useState(storedEmail);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  $(document).on('input change', '.phase-name input', function () {
    const value = $(this).val();
    const index = $(this).parents('.phase-parent').index();
    $('#scope .small-col .inner-content:eq(' + index + ') .phase-text span').text(value);
  });

  const rememberMe = () => {
    setRemember(!remember);
  }

  const classes = useStyles();
  return (
    <div autoComplete="off" className={'form-wrap ' + classes.root}>
      <Grid container spacing={3} justify="center" className="inner-wrap">
        <Grid item xs={5} className={"large-block " + classes.large_block}>
          <div className={classes.img_wrap}>
            <img src={estimatic_logo} alt="Estimatic" />
          </div>
          <p className={classes.text_wrap}>
            Step into the world of estimation awesomeness! Estimate your projects and services with accuracy and ease.
          </p>
          <div className="img-wrap">
            {/* <img src={robo_form} alt="robot" /> */}
          </div>
        </Grid>
        <Grid item xs={4} className={classes.small_block}>
          <form className="form">
            <Typography variant="h5" component="h5" style={{ fontWeight: 300 }}>
              Log In
            </Typography>
            <TextField id="email" variant="outlined"
              type="text"
              label="Email"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
                change(e);
              }}
              style={{ fontWeight: 300 }}
              value={email}
            />
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                name="password"
                onChange={handleChange('password')}
                style={{ fontWeight: 300 }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <Button
              variant="contained"
              type="button"
              className={classes.btn_wrap}
              onClick={(e) => submit(e, values.password, remember)}>
              Sign in
            </Button>
            <Grid item xs className={'link-wrap ' + classes.wrap_item}>
              <FormControlLabel
                control={<Checkbox name="checkedA"
                  onChange={rememberMe}
                  defaultChecked={remember}
                />}
                label={"Remember me"}
              />
              <p>
                <Link href="#" onClick={forget}>
                  Forgot Password
                </Link>
              </p>
            </Grid>
          </form>

          <Box style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-end' }}>
            {error &&
              <Grid className={classes.errorMsg} style={{ width: '100%' }}>
              </Grid>}
            <Grid item xs className={'bottom-wrap ' + classes.bottom_item}>
              <p>
                Interested in being a beta user?&nbsp;
                <Link href="#">
                  Contact us!
                </Link>
              </p>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}