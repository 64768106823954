import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import "../../assets/scss/dashboard.scss";
import blue_oval from "../../assets/images/blue-oval.png";
import green_oval from "../../assets/images/green-oval.png";
// import {Alert, AlertTitle} from '@material-ui/lab';

import StarIcon from "@material-ui/icons/Star";
import Graph from "../small-components/graph";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import formatMoneyStrFriendly from "../../libs/formatMoneyStrFriendly";
import { Paper, Typography, Grid, MenuItem } from "@material-ui/core";
import Testinomial from "../reusable-components/TestimonialSlider/testimonial-slider";
import calc_dash from "../../assets/images/calc-dash.svg";
import dollar_dash from "../../assets/images/dollar-dash.svg";
import DateFnsUtils from "@date-io/date-fns";
import { isTrue } from "../../libs/utils";
import moment from "moment";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import nav_calc from "../../assets/images/nav-svg/nav-calc.svg";
import nav_calc_pink from "../../assets/images/nav-svg/nav-calc_pink.svg";
import plus_nav from "../../assets/images/nav-svg/plus_nav.svg";
import plus_nav_pink from "../../assets/images/nav-svg/plus_nav_pink.svg";
import clsx from "clsx";
import Moment from "moment";
import fire from "../../config/firebase";
import loaderimage from "../../assets/images/PageLoader.svg";
import DashboardContact from "../pages/DashboardContact.js";
import SettingsTable from "../reusable-components/SettingsTable";
import EstimateFor from "../reusable-components/EstimateFor";
import { Link, withRouter } from "react-router-dom";
import EstimateCalculation from "../small-components/Phase/estimateCalculationUtil";
import * as getEstimatesActionCreators from '../../store/estimates/getEstimates';
import * as getCompanyActionCreators from '../../store/company/getCompany';
import * as getUsersActionCreators from '../../store/users/getUsers';
import * as getTemplatesCreators from '../../store/templates/getTemplates';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    height: "250px",
    borderRadius: "5px",
    border: "solid 1px rgba(8, 77, 79, 0.1)",
    backgroundColor: "#fff",
    marginBottom: "30px",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  h5: {
    fontSize: "20px",
  },
  symbol_wrap: {
    display: "flex",
    backgroundColor: "#2ab7ca",
    fontSize: "20px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: "#fff",
    borderRadius: "50%",
    textTransform: "capitalize",
    height: "37px",
    width: "37px",
  },
  button: {
    borderRadius: "5px",
    backgroundColor: "rgba(8, 77, 79, 0.1)",
    boxShadow: "none",
    height: "40px",
    textTransform: "Capitalize",
    fontSize: "14px",
    marginLeft: "10px",
    ///fontWeight: 400,
    fontWeight: 300,
    "&:hover": {
      backgroundColor: "rgba(8, 77, 79, 0.2)",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "rgba(8, 77, 79, 0.1)",
      boxShadow: "none",
    },
  },
  buttonpink: {
    borderRadius: "5px",
    backgroundColor: "rgba(231, 10, 137, 1)",
    //fontWeight: 400,
    fontWeight: 300,
    boxShadow: "none",
    height: "40px",
    textTransform: "Capitalize",
    fontSize: "14px",
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d00a7b",
      boxShadow: "none",
    },
    "&:focus": {
      backgroundColor: "rgba(231, 10, 137, 1)",
      boxShadow: "none",
    },
  },
  alert_wrap: {
    position: "absolute",
    top: "-88px",
    left: "calc(50% - 395px)",
    zIndex: "1202",
    width: "780px",
    height: "50px",
    background: "#65a838",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.36",
    padding: "8px 23px  8px 19px",
  },
  animatedItema: {
    animationDelay: "300ms",
    animation: `$myEffect 600ms ${theme.transitions.easing.easeInOut}`,
    animationFillMode: "both",
  },
  animatedItemb: {
    animationDelay: "700ms",
    animation: `$myEffect2 1500ms ${theme.transitions.easing.easeInOut}`,
    animationFillMode: "both",
  },
  animatedItemc: {
    animationDelay: "300ms",
    animation: `$myEffect3 800ms ${theme.transitions.easing.easeInOut}`,
    animationFillMode: "both",
  },
  animatedItem: {
    animation: `$myEffect 800ms ${theme.transitions.easing.easeInOut}`,
    animationFillMode: "forwards",
  },
  animatedItem2: {
    animation: `$myEffect2 1500ms ${theme.transitions.easing.easeInOut}`,
    animationFillMode: "forwards",
  },
  animatedItem3: {
    animation: `$myEffect3 1200ms ${theme.transitions.easing.easeInOut}`,
    animationFillMode: "forwards",
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "scale(0.3)",
    },
    "50%": {
      opacity: 0,
      transform: "scale(0.6)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  "@keyframes myEffect2": {
    "0%": {
      transform: "rotate(0) scale(0)",
    },
    "70%": {
      transform: "rotate(360deg) scale(1.15)",
    },
    "100%": {
      transform: "rotate(360deg) scale(1)",
    },
  },
  "@keyframes myEffect3": {
    "0%": {
      transform: "scale(0)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cost: "hourly",
      graph: "month",
      selectedDate: new Date(moment().add(-365, 'days').format("h:mm a MMMM DD, YYYY")),
      value: 0,
      currentDate: Moment(new Date()).format("h:mm a MMMM DD, YYYY"),
      estimates: [],
      loader: true,
      contactDrawer: false,
      resourcesArray: [],
      users: [],
      userEmail: "",
      userName: "",
      typeCast: false,
      templatesItem: [],
      user: {}
    };
    this.refreshData = this.refreshData.bind(this);
    this.getQuery = this.getQuery.bind(this);
  }

  handleselect = (event) => {
    this.setState({ cost: event.target.value });
  };
  handlegraphselect = (event) => {
    this.setState({ graph: event.target.value });
  };
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  createNew = (e) => {
    e.preventDefault();
    // document.querySelector(".overlay").style.display = "block";
    document.querySelector(".overlay").classList.add("activeOverlay");
    document.querySelector("body").classList.add("estimateAdditon");
  };
  featuredHandle = (e, id, value, dataType) => {
    e.stopPropagation();
    let favoriteList = "favoriteestimate";
    if (dataType === "estimates") {
      favoriteList = "favoriteestimate";
    } else if (dataType === "templates") {
      favoriteList = "favoritetemplates";
    }
    const users = JSON.parse(JSON.stringify(this.props.users || []))
    for (let i = 0; i < users.length; i++) {
      if (users[i]["caption"] === this.state.userEmail) {
        const userID = users[i]["id"];
        let favoriteItems = [];
        if (users[i][favoriteList]) {
          favoriteItems = users[i][favoriteList];
        }
        let recentExist = 0;
        let recentExistIndex = 0;
        this.setState({ favoriteestimate: favoriteItems });
        if (favoriteItems.length > 0) {
          for (let k = 0; k < favoriteItems.length; k++) {
            if (favoriteItems[k]) {
              if (id === favoriteItems[k]["id"]) {
                recentExist = 1;
                recentExistIndex = k;
              }
            }
          }
        }
        if (recentExist === 1) {
          favoriteItems.splice(recentExistIndex, 1);
          const companyId = localStorage.getItem('companyId');
          const userRef = fire.database().ref(companyId).child("users").child(userID);

          if (dataType === "estimates") {
            userRef.update({
              favoriteestimate: favoriteItems,
            });
          } else if (dataType === "templates") {
            userRef.update({
              favoritetemplates: favoriteItems,
            });
          }
        }
        if (recentExist === 0) {
          favoriteItems.unshift({ id });
          const companyId = localStorage.getItem('companyId');
          const userRef = fire.database().ref(companyId).child("users").child(userID);

          if (dataType === "estimates") {
            userRef.update({
              favoriteestimate: favoriteItems,
            });
          } else if (dataType === "templates") {
            userRef.update({
              favoritetemplates: favoriteItems,
            });
          }
        }
      }
    }
    this.props.update();
  };
  toggleDrawer = (open, type) => () => {
    this.setState({ drawerState: open, drawerType: type });
  };
  componentDidMount = async () => {
    this.refreshData();
  };
  getQuery() {
    const companyId = localStorage.getItem('companyId');
    // const arr = this.props.location.pathname.split('/');
    // const estimateKey = arr[arr.length - 1];
    // const dataType = this.props.match.path.includes("estimates")
    //   ? "estimates"
    //   : "templates";
    return { companyId }
  }

  async refreshData() {
    const { user } = this.props;

    const query = this.getQuery();
    const { getEstimatesAsync } = this.props.getEstimatesAction;
    const { estimates } = (await getEstimatesAsync({})).payload;

    const { getCompanyAsync } = this.props.getCompanyAction;
    await getCompanyAsync(query);

    const temp = JSON.parse(JSON.stringify(estimates || []));

    const estimateList = temp.filter(
      (d) => !isTrue(d.archive) && d.projectName && d.date
    );

    if (estimateList.length > 0) {
      this.items = estimateList;
      const date = estimateList[0]["date"] || "23/09/2022";
      let minDate =
        "" +
        date.split("/")[1] +
        "/" +
        date.substr(0, date.indexOf("/")) +
        "/" +
        date.split("/")[2];
      for (let i = 0; i < estimateList.length; i++) {
        const date = estimateList[i]["date"] || "23/09/2022";
        const newDate =
          "" +
          date.split("/")[1] +
          "/" +
          date.substr(0, date.indexOf("/")) +
          "/" +
          date.split("/")[2];
        if (Date.parse(newDate) <= Date.parse(minDate)) {
          minDate = newDate;
        }
      }
      this.setState({
        estimates: estimateList,
        loader: false,
        selectedDate: new Date(moment().add(-365, 'days').format("h:mm a MMMM DD, YYYY")),
      });
    } else {
      this.setState({
        estimates: [],
        loader: false,
        selectedDate: new Date(moment().add(-365, 'days').format("h:mm a MMMM DD, YYYY")),
      })
    }
    // const companyId = localStorage.getItem('companyId');
    // const refCompany = fire.database().ref(companyId).child("company");
    // const companySnapshot = await refCompany.once("value");

    // const company = companySnapshot.val() || {};
    // refCompany.off("value");
    // const calculations = (company.arrayItem || []).map((autocalculation) => {
    //   autocalculation.figure = parseInt(autocalculation.figure);
    //   return autocalculation;
    // });
    // this.setState({ calculations, company });

    // const ref = fire.database().ref(companyId).child("estimates");
    // ref.once("value").then( // esref
    // (snapshot) => {
    // const estimates = snapshot.val();
    // ref.off("value");
    // let estimateList = [];
    // for (const id in estimates) {
    //   estimateList.unshift({ id, ...estimates[id] });
    // }

    // },
    //     (error) => {
    // console.log("Error: " + error.code);
    // }
    //     );
    //users
    // const companyId = localStorage.getItem('companyId');
    // const userListRef = fire.database().ref(companyId).child("users");
    // const snapshot = await userListRef.once("value");
    // const users = snapshot.val();
    // userListRef.off("value");
    // const userlist = [];
    // for (const id in users) {
    //   userlist.push({ id, database: "users", ...users[id] });
    // }
    const { getUsersAsync } = this.props.getUsersAction;
    const { users } = (await getUsersAsync({})).payload;

    const stateUpdate = { users }
    // this.setState(stateUpdate);
    // const companyId = localStorage.getItem('companyId');
    //Current User

    if (user) {
      stateUpdate.authUser = user;
      stateUpdate.userEmail = user.email;
      stateUpdate.userName = user.displayName;
      const userDb = users.find(d => d.caption === user.email);
      stateUpdate.user = userDb;
      this.setState(stateUpdate);
    }

    const { getTemplatesAsync } = this.props.getTemplatesAction;
    const templates = (await getTemplatesAsync(query)).payload;
    if (templates) {
      this.setState({ templatesItem: templates });
    }

  }

  newContact = () => {
    this.setState({ contactDrawer: true });
  };
  closeDrawer = (e) => {
    this.setState({ contactDrawer: e });
  };
  itemHandler = (id, dataType, name, typeOf) => {
    let temptype = "";
    if (typeOf === "TIME BASED ESTIMATE") {
      temptype = "-timebased";
    }
    if (dataType && dataType.includes("estimates")) {
      this.props.history.push(`/estimates${temptype}/${encodeURI(name)}/${id}`);
    } else {
      this.props.history.push(`/templates${temptype}/${encodeURI(name)}/${id}`);
    }

    // if (dataType === "estimates") {
    //   for (let i = 0; i < this.items.length; i++) {
    //     if (this.items[i]['id'] === itemIndex) {
    //       this.setState({
    //         typeCast: true
    //       });
    //     }
    //   }
    // } else if (dataType === "templates") {
    //   for (let i = 0; i < this.state.templatesItem.length; i++) {
    //     if (this.state.templatesItem[i]['id'] === itemIndex) {
    //       this.setState({
    //         typeCast: true
    //       });
    //     }
    //   }
    // }
  };
  handleCloseEditScreen = () => {
    this.setState({
      typeCast: false,
    });
  };
  componentWillReceiveProps(newProps) {
    if (newProps.updateCnt !== this.props.updateCnt) {
      this.refreshData();
    }
  }
  render() {
    const { updateCnt, classes } = this.props;
    const { calculations = {}, company = {} } = this.props;
    const { users = [] } = this.props;

    const templatesItem = this.state.templatesItem;
    let estimatesList = this.state.estimates;
    const favEstimatesList = [];
    let favEstimates = [];
    const favTemplatesList = [];
    let favTemplates = [];
    const featuredHandle = this.featuredHandle;
    const itemHandler = this.itemHandler;
    let hourlyRate = 0;
    let amount = 0;
    const { cost = "margin" } = this.state;
    let margin = 0;
    let finalValue = 0;
    const dateEstList = [];

    let firstChar = "";
    const name = this.state.userName;
    const email = this.state.userEmail;
    if (name) {
      firstChar = name.charAt(0);
    } else {
      firstChar = email.charAt(0);
    }
    //Get estmates according to date filter
    function addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    }
    const selectedDate = new Date(Date.parse(this.state.selectedDate));
    const min = Date.parse(addDays(selectedDate, -1));
    const max = Date.parse(Moment(new Date()).format("h:mm a MMMM DD, YYYY"));
    for (let i = 0; i < estimatesList.length; i++) {
      const date = estimatesList[i]["date"];

      if (date) {
        const newDate = date
        const estimatesDate = Date.parse(newDate);
        if (estimatesDate <= max && estimatesDate >= min) {
          dateEstList.unshift(estimatesList[i]);
        }
      }
    }

    //Get favorite estimates list for logged in user

    for (let i = 0; i < users.length; i++) {
      if (users[i]["caption"] === this.state.userEmail) {
        favEstimates = users[i]["favoriteestimate"];
      }
    }
    //Get favorite templates list for logged in user
    for (let i = 0; i < users.length; i++) {
      if (users[i]["caption"] === this.state.userEmail) {
        favTemplates = users[i]["favoritetemplates"];
      }
    }

    //Get average

    for (const estimates of estimatesList) {
      const estimateCalculator = new EstimateCalculation(
        estimates,
        calculations
      );
      const totalEstimatePrice = estimateCalculator.totalEstimatePrice();
      // const totalEstimateCost = estimateCalculator.totalEstimateCost();
      const totalEstimateHours = estimateCalculator.totalEstimateHours();

      // const totalEstimateProfit = estimateCalculator.totalEstimateProfit();
      const totalEstimateProfitPercentage =
        estimateCalculator.totalEstimateProfitPercentage();

      const afterDiscountPrice = estimateCalculator.getAfterDiscountPrice();
      // const afterDiscountPriceBeforeRounding = estimateCalculator.getAfterDiscountPrice(false);
      const billableSwitch =
        estimates.billableSwitch || company.billableSwitch || false;
      let tempHourlyRate = totalEstimatePrice / totalEstimateHours;
      if (billableSwitch && estimates.definedhourlyRate) {
        tempHourlyRate = parseFloat(estimates.definedhourlyRate);
      }
      hourlyRate += isNaN(tempHourlyRate) ? 0 : tempHourlyRate;
      amount += afterDiscountPrice;
      margin += totalEstimateProfitPercentage;
    }

    if (cost === "hourly") {
      finalValue = hourlyRate / estimatesList.length;
    } else if (cost === "price") {
      finalValue = amount / estimatesList.length;
    } else {
      finalValue = margin / estimatesList.length;
    }

    if (isNaN(finalValue)) {
      finalValue = 0;
    }
    estimatesList = this.state.estimates.sort(function (a, b) {
      return b.timestamp - a.timestamp;
    });

    if (favEstimates) {
      for (let i = 0; i < this.state.estimates.length; i++) {
        for (let k = 0; k < favEstimates.length; k++) {
          if (favEstimates[k]) {
            if (favEstimates[k]["id"] === estimatesList[i]["id"]) {
              favEstimatesList.unshift(estimatesList[i]);
            }
          }
        }
      }
    }
    if (favTemplates) {
      for (let i = 0; i < this.state.templatesItem.length; i++) {
        for (let k = 0; k < favTemplates.length; k++) {
          if (favTemplates[k]["id"] === templatesItem[i]["id"]) {
            favTemplatesList.unshift(templatesItem[i]);
          }
        }
      }
    }

    return (
      <React.Fragment key={updateCnt}>
        {this.state.loader === true ? (
          <div className="loader-container">
            <object data={loaderimage} type="image/svg+xml"></object>
          </div>
        ) : (
          <React.Fragment>
            {this.state.typeCast && false ? (
              <section
                className="estimated-list"
                style={{ minHeight: "calc(100vh - 60px)" }}
              >
                <EstimateFor
                  closeEditScreen={this.handleCloseEditScreen}
                  title={
                    isTrue(this.state.typeCast) ? "Time Based" : "Task Based"
                  }
                  type={this.state.typeCast}
                />
                <SettingsTable
                  type={this.state.typeCast}
                  update={this.props.update}
                />
              </section>
            ) : (
              <section className="dashboard">
                <div className="container">
                  {/* <Alert onClose={() => {}} className={classes.alert_wrap} variant="filled" severity="success">This is a success message!</Alert> */}
                  <div className="wrapper">
                    <Grid item xs className="title-wrap">
                      <Paper
                        style={{ background: "transparent", boxShadow: "none", display: "flex" }}
                      >
                        <span className={classes.symbol_wrap}>{firstChar}</span>
                        <Typography
                          variant="h5"
                          component="h5"
                          className="symbol-title-wrap"
                        >
                          Welcome back,{" "}
                          {this.state.user.firstname || this.state.userName || this.state.userEmail}
                          !<span> Today is {this.state.currentDate}</span>
                        </Typography>
                      </Paper>
                      <Paper
                        style={{ background: "transparent", boxShadow: "none" }}
                      >
                        <Button
                          variant="contained"
                          className={classes.button}
                          startIcon={<PermContactCalendarOutlinedIcon />}
                          onClick={this.newContact}
                        >
                          Add Contact
                        </Button>
                        <Button
                          onClick={this.createNew}
                          variant="contained"
                          className={classes.buttonpink}
                          startIcon={
                            <AddCircleOutlineOutlinedIcon htmlColor="#fff" />
                          }
                          style={{ fontWeight: 500 }}
                        >
                          Create New Estimate
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid>
                      <DashboardContact
                        contactDrawer={this.state.contactDrawer}
                        closeDrawer={this.closeDrawer}
                      />
                    </Grid>
                    <Grid container spacing={3} className="inner-wrap">
                      <Grid item xs className="inner-item">
                        <div className="estimates-wrap">
                          <Grid item>
                            <div className={classes.root}>
                              <AppBar position="static">
                                <Tabs
                                  value={this.state.value}
                                  onChange={this.handleChange}
                                  aria-label="simple tabs"
                                >
                                  <Tab label="Recent" {...a11yProps(0)} />
                                  <Tab
                                    label="Favorite Estimates"
                                    {...a11yProps(1)}
                                  />
                                  <Tab
                                    label="Favorite Templates"
                                    {...a11yProps(2)}
                                  />
                                </Tabs>
                              </AppBar>
                              <TabPanel
                                value={this.state.value}
                                index={0}
                                style={{ background: "#fff" }}
                              >
                                {estimatesList
                                  .slice(0, 5)
                                  .map(function (item, index) {
                                    let favorite = false;
                                    favEstimatesList &&
                                      favEstimatesList.map((i) => {
                                        if (item.id === i.id) {
                                          favorite = true;
                                        }
                                      });
                                    return (
                                      <React.Fragment key={item.id + index}>
                                        <Paper
                                          className="listitem"
                                          onClick={() =>
                                            itemHandler(
                                              item.id,
                                              "estimates",
                                              item.projectName,
                                              item.typeOf
                                            )
                                          }
                                        >
                                          <div
                                            className="info"
                                            style={{
                                              display: "inline-block",
                                              width: "calc(65% - 50px)",
                                            }}
                                          >
                                            <Link
                                              to={{
                                                pathname: `/estimates/${item.projectName}/${item.id}`,
                                                state: {
                                                  key: index.id,
                                                },
                                              }}
                                            >
                                              <Typography
                                                variant="body1"
                                                component="h6"
                                              >
                                                <img
                                                  src={nav_calc}
                                                  alt="icon"
                                                  className="unfeaturedIcon"
                                                />
                                                <img
                                                  src={nav_calc_pink}
                                                  alt="icon"
                                                  className="unfeaturedIcon"
                                                />
                                                {item.projectName}
                                              </Typography>
                                            </Link>
                                          </div>
                                          <div
                                            className="time"
                                            style={{ display: "inline-block" }}
                                            onClick={(e) =>
                                              featuredHandle(
                                                e,
                                                item.id,
                                                item.favorite,
                                                "estimates"
                                              )
                                            }
                                          >
                                            <span>
                                              {favorite === false ? (
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="21"
                                                  height="23"
                                                  viewBox="0 0 22 20.9"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M9.909 9.793L13 2.5l3.091 7.293 7.909.671-6.006 5.2L19.8 23.4 13 19.3l-6.8 4.1L8 15.667l-6-5.203zm4.158.869L13 8.143l-1.067 2.5-.517 1.221-1.32.11-2.717.226 2.068 1.793 1 .869-.3 1.3-.616 2.651 2.336-1.413L13 16.723l1.133.7 2.332 1.408-.616-2.651-.3-1.3 1-.869 2.068-1.793-2.717-.225-1.32-.11z"
                                                    transform="translate(-2 -2.5)"
                                                  />
                                                </svg>
                                              ) : (
                                                <StarIcon htmlColor="#2ab7ca" />
                                              )}
                                            </span>
                                          </div>
                                        </Paper>
                                      </React.Fragment>
                                    );
                                  })}
                                {estimatesList.length === 0 && (
                                  <Paper
                                    style={{
                                      boxShadow: "none",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      minHeight: "315px",
                                      alignContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="h6"
                                      style={{
                                        textAlign: "center",
                                        marginTop: "-30px",
                                        width: "100%",
                                        padding: "20px 0",
                                      }}
                                    >
                                      You currently have no estimates.
                                    </Typography>
                                    <Button
                                      onClick={this.createNew}
                                      variant="contained"
                                      className={classes.buttonpink}
                                      startIcon={
                                        <AddCircleOutlineOutlinedIcon htmlColor="#fff" />
                                      }
                                      style={{ fontWeight: 500 }}
                                    >
                                      Create New Estimate
                                    </Button>
                                  </Paper>
                                )}
                              </TabPanel>
                              <TabPanel
                                value={this.state.value}
                                index={1}
                                style={{ background: "#fff" }}
                              >
                                {favEstimatesList
                                  .slice(0, 5)
                                  .map(function (item, index) {
                                    return (
                                      <Paper
                                        key={index}
                                        className="listitem"
                                        onClick={() =>
                                          itemHandler(
                                            item.id,
                                            "estimates",
                                            item.projectName,
                                            item.typeOf
                                          )
                                        }
                                      >
                                        <div
                                          className="info"
                                          style={{
                                            display: "inline-block",
                                            width: "calc(65% - 50px)",
                                          }}
                                        >
                                          <Link
                                            to={{
                                              pathname: `/estimates/${item.projectName}/${item.id}`,
                                              state: {
                                                key: index.id,
                                              },
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              component="h6"
                                            >
                                              <img
                                                src={nav_calc}
                                                alt="icon"
                                                className="unfeaturedIcon"
                                              />
                                              <img
                                                src={nav_calc_pink}
                                                alt="icon"
                                                className="unfeaturedIcon"
                                              />
                                              {item.projectName}
                                            </Typography>
                                          </Link>
                                        </div>
                                        <div
                                          className="time"
                                          style={{ display: "inline-block" }}
                                          onClick={(e) =>
                                            featuredHandle(
                                              e,
                                              item.id,
                                              item.favorite,
                                              "estimates"
                                            )
                                          }
                                        >
                                          <span>
                                            <StarIcon htmlColor="#2ab7ca" />
                                          </span>
                                        </div>
                                      </Paper>
                                    );
                                  })}
                                {favEstimatesList.length === 0 && (
                                  <Paper
                                    style={{
                                      boxShadow: "none",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      minHeight: "315px",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="h6"
                                      style={{
                                        textAlign: "center",
                                        marginTop: "-35px",
                                      }}
                                    >
                                      You currently have no favorite estimates.
                                    </Typography>
                                  </Paper>
                                )}
                              </TabPanel>
                              <TabPanel
                                value={this.state.value}
                                index={2}
                                style={{ background: "#fff" }}
                              >
                                {favTemplatesList
                                  .slice(0, 5)
                                  .map((item, index) => {
                                    return (
                                      <Paper
                                        className="listitem"
                                        onClick={() =>
                                          itemHandler(
                                            item.id,
                                            "templates",
                                            item.projectName,
                                            item.typeOf
                                          )
                                        }
                                        key={index}
                                      >
                                        <div
                                          className="info"
                                          style={{
                                            display: "inline-block",
                                            width: "calc(65% - 50px)",
                                          }}
                                          key="1"
                                        >
                                          <Link
                                            to={{
                                              pathname: `/templates/${item.projectName}/${item.id}`,
                                              state: {
                                                key: index.id,
                                              },
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              component="h6"
                                            >
                                              <img
                                                src={plus_nav}
                                                alt="icon"
                                                className="unfeaturedIcon"
                                                key="1"
                                              />
                                              <img
                                                src={plus_nav_pink}
                                                alt="icon"
                                                className="unfeaturedIcon"
                                                key="2"
                                              />
                                              {item.projectName}
                                            </Typography>
                                          </Link>
                                        </div>
                                        <div
                                          className="time"
                                          style={{ display: "inline-block" }}
                                          onClick={(e) =>
                                            featuredHandle(
                                              e,
                                              item.id,
                                              item.favorite,
                                              "templates"
                                            )
                                          }
                                          key="2"
                                        >
                                          <span>
                                            <StarIcon htmlColor="#2ab7ca" />
                                          </span>
                                        </div>
                                      </Paper>
                                    );
                                  })}
                                {favTemplatesList.length === 0 && (
                                  <Paper
                                    style={{
                                      boxShadow: "none",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      minHeight: "315px",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="h6"
                                      style={{
                                        textAlign: "center",
                                        marginTop: "-35px",
                                      }}
                                    >
                                      You currently have no favorite Templates.
                                    </Typography>
                                  </Paper>
                                )}
                              </TabPanel>
                            </div>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs container className="inner-item">
                        <Grid item xs={6} className="number-wrap">
                          <Paper
                            elevation={0}
                            style={{
                              margin: "0 15px 0 0",
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                              position: "relative",
                            }}
                          >
                            <img
                              className={clsx(classes.animatedItem2)}
                              src={blue_oval}
                              alt=""
                              style={{ maxWidth: "290px" }}
                            />
                            <Paper className=" number-item-wrap">
                              <img
                                src={calc_dash}
                                alt="calculator"
                                className={clsx(classes.animatedItem)}
                              />
                              <Typography
                                variant="h2"
                                component="h2"
                                className={clsx(classes.animatedItem3)}
                              >
                                {dateEstList.length || 0}
                              </Typography>
                              <span className={clsx(classes.animatedItem)}>
                                Estimates since
                              </span>
                            </Paper>
                            <Paper className="calender-wrap-dashboard">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                  <KeyboardDatePicker
                                    autoOk={true}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline-2"
                                    label=""
                                    value={
                                      this.state.selectedDate ||
                                      new Date(moment().format("h:mm a MMMM DD, YYYY"))
                                    }
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                            </Paper>
                          </Paper>
                        </Grid>
                        <Grid item xs={6} className="number-wrap">
                          <Paper
                            elevation={0}
                            style={{
                              margin: "0 0 0 15px",
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                              position: "relative",
                            }}
                          >
                            <img
                              className={clsx(classes.animatedItemb)}
                              src={green_oval}
                              alt=""
                              style={{ maxWidth: "210px" }}
                            />
                            <Paper className=" number-item-wrap">
                              <img
                                src={dollar_dash}
                                alt="dollar"
                                className={clsx(classes.animatedItema)}
                              />
                              <Typography
                                variant="h2"
                                component="h2"
                                className={clsx(classes.animatedItemc)}
                              >
                                {formatMoneyStrFriendly({
                                  number: finalValue,
                                  maximumFractionDigits: 0,
                                  money: cost !== "margin",
                                  perTitle: cost === "margin" ? "%" : "",
                                })}{" "}
                              </Typography>
                              <span className={clsx(classes.animatedItema)}>
                                Average
                              </span>
                            </Paper>
                            <Paper
                              className="select-dash-wrap"
                              style={{ position: "absolute", bottom: "30px" }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <InputLabel id="simple-select-outlined-label">
                                  Cost
                                </InputLabel>
                                <Select
                                  labelId="simple-select-outlined-label"
                                  id="simple-select-outlined"
                                  value={cost || 0}
                                  onChange={this.handleselect}
                                  label="Cost"
                                  IconComponent={ExpandMoreIcon}
                                >
                                  <MenuItem value={"margin"}>Margin</MenuItem>
                                  <MenuItem value={"price"}>Price</MenuItem>
                                  <MenuItem value={"hourly"}>
                                    Hourly Rate
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Paper>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={6} className="testimonial-wrapper">
                        <Testinomial />
                      </Grid>
                      <Grid item xs={6} className="graph-wrapper">
                        <div className="graph">
                          <Paper className="calender-wrap-graph">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              {/* <Grid container justify="space-around"> */}
                              <Paper
                                className="select-dash-wrap"
                                style={{ marginTop: "28px" }}
                              >
                                <FormControl
                                  variant="outlined"
                                  className={classes.formControl}
                                >
                                  <InputLabel id="simple-select-outlined-label">
                                    Cost
                                  </InputLabel>
                                  <Select
                                    labelId="simple-select-outlined-label"
                                    id="simple-select-outlined"
                                    value={this.state.graph}
                                    onChange={this.handlegraphselect}
                                    label="Cost"
                                    IconComponent={ExpandMoreIcon}
                                  >
                                    <MenuItem value={"month"}>Month</MenuItem>
                                    <MenuItem value={"quarterly"}>
                                      Quarterly
                                    </MenuItem>
                                    <MenuItem value={"year"}>Year</MenuItem>
                                  </Select>
                                </FormControl>
                              </Paper>
                            </MuiPickersUtilsProvider>
                          </Paper>
                          <Typography variant="h5" gutterBottom>
                            <Graph
                              graphType={this.state.graph}
                              estimates={this.state.estimates}
                              title={"Total Estimates (dollar amount)"}
                              type={"area"}
                              calculations={this.calculations}
                            />
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function MapStateToProps(state) {
  return {
    estimates: state.getEstimates.estimates,
    templates: state.getTemplates.templates,
    // estimate: state.getEstimate.estimate,
    // tags: state.getTags.tags,
    // resources: state.getResources.resources,
    company: state.getCompany.company,
    users: state.getUsers.users,
    user: state.getUser.user,
    calculations: state.getCompany.calculations,
    // organizations: state.getOrganizations.organizations,
    // contacts: state.getContacts.contacts
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimatesAction: bindActionCreators(
      getEstimatesActionCreators,
      dispatch
    ),
    getCompanyAction: bindActionCreators(
      getCompanyActionCreators,
      dispatch
    ),
    getUsersAction: bindActionCreators(
      getUsersActionCreators,
      dispatch
    ),
    getTemplatesAction: bindActionCreators(
      getTemplatesCreators,
      dispatch
    ),

  };
}

const withStyled = withStyles(styles)(Dashboard);
// export default withRouter(withStyled);

export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(withStyled);
