export default class Zoom {
	constructor() {
		this.statusOfDatesDifferent = null;
	}

	init() {
		this.setConfig();
		this.attachEvents();
	}

	setConfig() {
		gantt.ext.zoom.init(this.getZoomConfig());
		gantt.ext.zoom.setLevel(this.getZoomLevel());
	}

	getZoomLevel() {
		const savedLevel = localStorage.level || "weeks";

		return this.convertValueForZoom(savedLevel);
	}

	convertValueForZoom(savedLevel) {
		return savedLevel.toLowerCase().slice(0, -1);
	}

	getZoomConfig() {
		return {
			levels: [
				{
					name: "day",
					label: "day",
					min_column_width: 135,
					scales: [
						{
							unit: "day",
							step: 1,
							format: "%M"
						},
						{
							unit: "day",
							step: 1,
							format: "%d"
						}
					]
				},
				{
					name: "week",
					label: "week",
					min_column_width: 135,
					scales: [
						{
							unit: "week",
							step: 1,
							format: date => this.setMonthInWeekScale(date)
						},
						{
							unit: "week",
							step: 1,
							format: date => this.setDaysInWeekScale(date)
						}
					]
				},
				{
					name: "month",
					label: "month",
					max_column_width: 135,
					scales: [
						{
							unit: "month",
							format: "%M"
						}
					]
				},
				{
					name: "monthX1",
					label: "month",
					min_column_width: 240,
					scales: [
						{
							unit: "month",
							format: "%M"
						}
					]
				},
				{
					name: "year",
					label: "year",
					max_column_width: 135,
					scales: [
						{
							unit: "year",
							step: 1,
							format: "%Y"
						}
					]
				},
				{
					name: "year",
					label: "year",
					max_column_width: 240,
					scales: [
						{
							unit: "year",
							step: 1,
							format: "%Y"
						}
					]
				}
			],
			useKey: "ctrlKey",
			trigger: "wheel",
			element() {
				return gantt.$root.querySelector(".gantt_task");
			}
		};
	}

	attachEvents() {
		gantt.$data.tasksStore.attachEvent("onStoreUpdated", (id, item, action) => {
			if (action === "add" || action === "delete") {
				this.initZoomSelector();
			}
		});

		gantt.attachEvent("onGanttRender", () => {
			this.initZoomSelector();
		});
	}

	initZoomSelector() {
		const inputId = "#change-zooming";
		const headerInput = gantt.$grid_scale.querySelector(inputId);

		if (headerInput) {
			const value = localStorage.level || this.defaultLevel;

			headerInput.value = value;
			headerInput.onchange = () => {
				const headerInputValue = headerInput.value;

				localStorage.level = headerInputValue;
				gantt.ext.zoom.setLevel(this.convertValueForZoom(headerInputValue));

				this.toggleActivePeriodClass(headerInputValue);
			};
		}
	}

	toggleActivePeriodClass(headerInputValue) {
		const periodContainer = gantt.$container.querySelector(".period-container");
		periodContainer.childNodes.forEach(item => {
			item.classList.remove("active");

			if (item.dataset.value === headerInputValue) {
				item.classList.add("active");
			}
		})
	}

	setDaysInWeekScale(date) {
		const getDays = gantt.date.date_to_str("%d");
		const getMonth = gantt.date.date_to_str("%M");
		const weekPeriod = this.getWeekPeriod(date);

		const isItDifftentDates = weekPeriod.isItDifferentBetweenStartAndEndDates;

		if (isItDifftentDates) {
			return `- ${getMonth(weekPeriod.getWeekEndOnSaturday)} ${+getDays(weekPeriod.getWeekEndOnSaturday)}`;
		}

		return `${+getDays(weekPeriod.getWeerStartOnSunday)} - ${+getDays(weekPeriod.getWeekEndOnSaturday)}`;
	}

	getWeekPeriod(date) {
		const getMonth = gantt.date.date_to_str("%M");
		const getWeekStart = gantt.date.week_start(date);
		const getWeerStartOnSunday = gantt.date.add(getWeekStart, -1, "day");
		const getWeekEndOnSaturday = gantt.date.add(getWeekStart, 5, "day");
		const isItDifferentBetweenStartAndEndDates = getMonth(getWeerStartOnSunday) !== getMonth(getWeekEndOnSaturday);

		return {
			getWeerStartOnSunday,
			getWeekEndOnSaturday,
			isItDifferentBetweenStartAndEndDates
		}
	}

	setMonthInWeekScale(date) {
		const getMonth = gantt.date.date_to_str("%M");
		const getDays = gantt.date.date_to_str("%d");
		const weekPeriod = this.getWeekPeriod(date);
		const isItDifftentDates = weekPeriod.isItDifferentBetweenStartAndEndDates;

		if (isItDifftentDates) {
			return `${getMonth(weekPeriod.getWeerStartOnSunday)} ${+getDays(weekPeriod.getWeerStartOnSunday)}`;
		}

		return getMonth(date);
	}
}
