import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

export const getUserAsync = createAsyncThunk(
    'user/getAuth',
    async (user) => {
        return { user };
    }
);

// Redux Toolkit slice
export const getUsersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.user = action.payload.user
            });
    },
});

export default getUsersSlice.reducer;
