
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import fire from '../../../config/firebase';
import "../../../assets/scss/autocomplete.scss";
import parse from 'autosuggest-highlight/parse';
import Moment from 'moment';
import matchRegex from 'autosuggest-highlight/match';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const filter = createFilterOptions();

function AutoCompletePhase({ selectValue, show,
  title, dataList = [], update, phaseName = '',
  cla, classes,
}) {
  const type = 'phases';
  const [value, setValue] = useState(selectValue);
  useEffect(() => {
    if (value && value !== selectValue && value.inputValue !== selectValue) {
      if (value && value.inputValue) {
        addNewItem(value.inputValue)
      } else {
        update({ phaseName: value.title });
      }
    }
    dataList.filter((d) => {
      d.archive !== 'true' && d.title.length > 0
    })
  }, [value])

  const addNewItem = (name) => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key]), {});
    const companyId = localStorage.getItem('companyId');

    const listRef = fire.database().ref(companyId).child(type);

    const itemData = {
      title: name,
      date: Moment(new Date()).format('DD/MM/YYYY'),
      borderColor: "#fffff"
    };
    listRef.once('value', (snap) => {
      const db = snap.val();
      listRef.off('value');

      const list = Object.values(db || []);
      const filteredList = list.filter(item => item.title === name);
      if (filteredList.length === 0) {
        listRef.push(itemData);
        setTimeout(() => {
          update({ phaseName: name });
        }, 100);

      }
    });
  }
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => { setOpen(false); }}>
      <Autocomplete
        autoSelect
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        defaultValue={phaseName}
        className={cla + ' ' + classes}
        onChange={(event, newValue) => {
          if (newValue === null) {
            return
          }
          setValue(newValue);
          if (show) {
            show();
          }
        }
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (type === "resources" && params.inputValue !== '') {
            filtered.push({
              title: <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}</span>,
              inputValue: params.inputValue,
            });

          }
          else if (params.inputValue !== '') {
            filtered.push({
              title: <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}</span>,
              inputValue: params.inputValue,
            });
          }
          return filtered;
        }
        }
        selectOnFocus
        clearOnBlur
        blurOnSelect
        handleHomeEndKeys
        options={(dataList || []).filter(d => d.archive !== 'true' && d.title.length > 0)}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(option, { inputValue }) => {
          const matches = matchRegex(option.title, inputValue);
          const parts = parse(option.title, matches);
          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}

        style={{ width: 550 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={title}
            onChange={(e) => {
              update({ phaseName: e.target.value });
            }}
          />
        )}
      />
    </ClickAwayListener>
  );
}
export default withRouter(AutoCompletePhase);