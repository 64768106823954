const rgb2lab = (rgb) => {
    let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
    y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
    z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;
    return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
}

const deltaE = (rgbA, rgbB) => {
    const labA = rgb2lab(rgbA);
    const labB = rgb2lab(rgbB);
    const deltaL = labA[0] - labB[0];
    const deltaA = labA[1] - labB[1];
    const deltaB = labA[2] - labB[2];
    const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    const deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    const sc = 1.0 + 0.045 * c1;
    const sh = 1.0 + 0.015 * c1;
    const deltaLKlsl = deltaL / (1.0);
    const deltaCkcsc = deltaC / (sc);
    const deltaHkhsh = deltaH / (sh);
    const i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
}
export const colorArray = [
    '#C91C5B', '#DB263B', '#E70A89',
    '#F46C37', '#F69136', '#E5B900',
    '#084D4F', '#008E8A', '#1B875E',
    '#0F821D', '#26A854', '#65A838',
    '#522D89', '#911B9B', '#8117CC',
    '#6562CC', '#3066BE', '#2D8AC9',
    '#759BC9', '#2AB7CA', '#9F000F',
    '#F62817', '#D4A017', '#FFA500',
    '#FDBD01', '#B0BF1A', '#85BB65',
    '#32CD32', '#347235', '#618A58',
    '#7C9D8E', '#3EB489', '#3A635C',
    '#007A78', '#4DABAA', '#46C7AF',
    '#45CDE3', '#40E0D0', '#78C7C7',
    '#81D8E6', '#16E2F5', '#82CAFF',
    '#5CB3FF', '#6698FF', '#1589FF',
    '#306EFF', '#2554E9', '#0000FF',
    '#0000CD', '#000091', '#67016E',
    '#B048B5', '#C458EC', '#FF00FF',
    '#FD349C'
];
export const getRandomColor = () => {
    const randomIndex = Math.random() * 100000;
    const color = colorArray[Math.floor(randomIndex % colorArray.length)];
    return color;
}
export const getNearestColor = (color) => {
    let min = Number.MAX_SAFE_INTEGER;
    let minColor = color;

    const clearHex = (d) => d.replace('#', '');

    const getIntArr = (d) => {
        const p1 = parseInt(d.substring(0, 2), 16)
        const p2 = parseInt(d.substring(2, 4), 16)
        const p3 = parseInt(d.substring(4, 6), 16)
        return [p1, p2, p3]
    }
    const sourceInt = getIntArr(clearHex(color));
    for (const col of colorArray) {
        const disatnce = deltaE(sourceInt, getIntArr(clearHex(col)));
        if (disatnce < min) {
            min = disatnce;
            minColor = col;
        }
    }
    return minColor;
}
