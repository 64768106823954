import React, { useEffect, useState } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import "../../assets/scss/setting-table.scss";
import Summary from "../small-components/summary";
import Expenses from "../small-components/expenses"
import Notes from "../small-components/notes/notes";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import EstimateCalculator from "./EstimateCalculator";
import Moment from "moment";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import SaveTemplate from "../small-components/SaveTemplate/SaveTemplate";
import * as getEstimateActionCreators from '../../store/estimate/getEstimate';
import fire from "../../config/firebase";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  indicator: {
    backgroundColor: "#e70a89",
    ripple: {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },
  buttonit: {
    // padding: '5px 15px 6px',
    borderRadius: "5px",
    backgroundColor: "#084d4f",
    color: "#fff",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#084d4f",
    },
  },
  plusIcon: {
    padding: 0,
  },
  addTask: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1.36",
    color: "#3066be",
  },
}));

function TaskBased(props) {
  const { estimate, company, users, organizations, contacts, updateCnt = 0, shared = false } = props;
  const tags = shared ? [] : props.tags;
  const calculations = shared ? estimate.calculations : props.calculations;
  const getQuery = () => {
    const arr = props.location.pathname.split('/');
    const estimateKey = arr[arr.length - 1];
    const companyId = localStorage.getItem('companyId') || `public/${estimateKey}`;
    const dataType = props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    const isTemplate = dataType === "templates";
    return { dataType, estimateKey, companyId, isTemplate }
  }
  const { isTemplate, companyId } = getQuery();
  const [showSaveTemplate, showTemplate] = useState(false);
  const [expensesDrawer, showExpenseDrawer] = useState(false);

  const classes = useStyles();
  const [value, setValue] = React.useState("scope");
  const [expenseId, setExpenseId] = React.useState("");

  const tabHandler = (type) => {
    setValue(type);
  };
  const toggleEstimateDrawer = () => {
    showTemplate(!showSaveTemplate);
  };

  const toggleExpenseDrawer = (id) => {
    if (shared) { return }
    setExpenseId(id);
    showExpenseDrawer(!expensesDrawer);
  }

  const createEstimate = () => {
    showTemplate(true);
    const copy = { ...estimate };
    copy.id = null;
    copy.date = Moment(new Date()).format("MM/DD/YYYY");
    copy.timestamp = Math.floor(Date.now() / 1000);
    copy.modified = Moment(new Date()).format("h:mm a MMMM DD, YYYY");
  };

  const [isEmpty, setIsEmpty] = useState(true);

  const updateExpense = (exp) => {
    const query = getQuery();
    const refInner = fire.database().ref(query.companyId).child(query.shared ? query.estimateKey : `${query.dataType}/${query.estimateKey}`);
    refInner.update(exp);
    const { getEstimateAsync } = props.getEstimateAction;
    getEstimateAsync(getQuery());
  }

  useEffect(() => {
    let temp = true;
    if (estimate) {
      const { phases = [] } = estimate;
      for (const phase of phases) {
        const { tasks = [] } = phase;
        for (const task of tasks) {
          const { resources = [] } = task;
          if (resources.length > 0) {
            temp = false;
          }
        }
      }
    }
    setIsEmpty(temp);
  }, [estimate]);

  if (!estimate || !tags || !company || !calculations || !users || !organizations || !contacts) {
    return null;
  }
  return (
    <div
      className="summary-details template-modify"
      style={{
        borderColor: isTemplate ? "#2ab7ca" : "#fff",
        borderWidth: isTemplate ? "3px" : "3px 0 0 0",
        borderStyle: "solid",
      }}
    >
      {showSaveTemplate ? (
        <Paper className="MuiDrawer-paper MuiDrawer-paperAnchorRight">
          <SaveTemplate projectName={estimate.projectName} closeEditScreen={toggleEstimateDrawer} onClose={toggleEstimateDrawer} />
        </Paper>
      ) : null}

      {isTemplate ? (
        <div className="violator-bar">
          <Typography variant="h5" component="h5">
            You are currently modifying a template
          </Typography>
          <div className="btn-wrap">
            <Button variant="contained" type="button" onClick={createEstimate}>
              Create Estimate
            </Button>
          </div>
        </div>
      ) : null}
      <div className="container">
        <ul className="tabs-wrap">
          <li className={value === "scope" ? "active" : ""}>
            <Button onClick={() => tabHandler("scope")}>
              <p>Scope</p>
            </Button>
          </li>
          {((shared == true && typeof estimate.expenses == 'object') || shared == false) &&
            <li
              className={
                value === "expenses" ? "active" : ""
              }
            >

              <Button onClick={() => tabHandler("expenses")}>
                <p>Expenses</p>
              </Button>
            </li>}
          {shared === false &&
            <li
              className={
                value === "summary" ? "active" : isEmpty ? "notActive" : ""
              }
            >
              {isEmpty ? (
                <p>Summary</p>
              ) : (
                <Button disabled={isEmpty} onClick={() => tabHandler("summary")}>
                  <p>Summary</p>
                </Button>
              )}
            </li>}
          {shared === false &&
            <li className={value === "notes" ? "active" : ""}>
              <Button onClick={() => tabHandler("notes")}>
                <p>Notes</p>
              </Button>
            </li>
          }
        </ul>
        <div className="content-wrapper">
          <div className={`scope-wrap ${value === "scope" ? "active" : ""}`}>
            <EstimateCalculator
              classes={classes}
              updateCnt={updateCnt}
              update={props.update}
              updateExpense={updateExpense}
              companyId={companyId}
              shared={shared}
              toggleExpenses={toggleExpenseDrawer}
              expensesDrawer={expensesDrawer}
              expenseId={expenseId}
            />
          </div>
          <div
            className={`expenses-wrap ${value === "expenses" ? "active" : ""}`}
          >
            {value === "expenses" ? (
              <Expenses
                toggle={toggleExpenseDrawer}
                companyId={companyId}
                shared={shared}
                updateExpense={updateExpense}
              />
            ) : null}
          </div>
          <div
            className={`summary-wrap ${value === "summary" ? "active" : ""}`}
          >
            {value === "summary" ? (
              <Summary />
            ) : null}
          </div>
          <div className={`notes-wrap ${value === "notes" ? "active" : ""}`}>
            <Notes />
          </div>
        </div>
      </div>
    </div>
  );
}

function MapStateToProps(state) {
  return {
    estimate: state.getEstimate.estimate,
    tags: state.getTags.tags,
    resources: state.getResources.resources,
    company: state.getCompany.company,
    users: state.getUsers.users,
    calculations: state.getCompany.calculations,
    organizations: state.getOrganizations.organizations,
    contacts: state.getContacts.contacts
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimateAction: bindActionCreators(
      getEstimateActionCreators,
      dispatch
    ),
  };
}
export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(TaskBased);
