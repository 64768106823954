import React from 'react';
import EstimateFor from '../reusable-components/EstimateFor';
import "../../assets/scss/new-estimatic.scss";
import TaskBased from "../reusable-components/TaskBased";
import TimeBased from "../reusable-components/TimeBased";

import { createStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    tag: {
      background: "transparent",
      boxShadow: 'none',
      padding: '1px 10px',
      borderRadius: '20px',
      color: '#000',
      fontSize: '11px',
      width: 'auto !important',
      display: 'inline-block',
    },
  }));
const AllPages = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className="estimated-list">
        <EstimateFor classes={classes} />
        <TaskBased update={props.update} updateCnt={props.updateCnt} />
        <TimeBased />
      </section>
    </React.Fragment>
  );
}

export default AllPages;