import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import InfoPanel from "../small-components/InfoPanel/InfoPanel";
import AsideDrawer from "../small-components/AsideDrawer/AsideDrawer";
import InputField from "../small-components/UI/InputField";
import { DefaultButton } from "../small-components/UI/Buttons";
// import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import "../../assets/scss/user-management.scss";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import PersonAddDisabledOutlinedIcon from "@material-ui/icons/PersonAddDisabledOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { debounce } from "lodash";
import Moment from "moment";
import validator from "validator";
import AlertDialog from "../small-components/AlertDialog/AlertDialog";
import { Alert } from "@material-ui/lab";
import NoContent from "../small-components/nocontent";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import axios from 'axios';
import uuid from "react-uuid";
import fire from "../../config/firebase";
import loaderimage from "../../assets/images/PageLoader.svg";
import { isTrue, isEmptyString } from "../../libs/utils";
import { getFunctionUrl } from "../../config/firebase";
import AdminUserFilter from "../small-components/userfilter/AdminUserFilter";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#ffffff",
    "&$checked": {
      color: "#084d4f",
    },
    "&$checked + $track": {
      backgroundColor: "rgba(8, 77, 79, 0.3)",
    },

    "& > .MuiButton-contained": {
      margin: "0px 5px",
    },
  },

  checked: {},

  track: {},
})(Switch);
const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  switchLabel: {
    fontSize: 14,
    color: "#000000",
  },
  formLabel: {
    fontSize: 14,
    color: "#000000",
  },
  menuDropdown: {
    marginLeft: 10,
  },
  menuDropdownInput: {
    padding: "5.5px 10px",
    paddingRight: "31px !important",
  },
  menuDropdownIcon: {
    marginRight: "5.5px",
  },
  pagination: {
    width: "auto",
    backgroundColor: "transparent !important",
    boxShadow: "none",
  },
  paginationItem: {
    width: 30,
    height: 30,
    padding: "5px 11px 6px",
    backgroundColor: "transparent !important",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  paginationItemIcon: {
    width: 24,
    height: 24,
  },
  paginationItemSelected: {
    backgroundColor: "#084d4f !important",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "rgba(8, 77, 79, 0.9) !important",
    },
  },
  paginationItemDisabled: {
    opacity: "0.5 !important",
  },
  label: {
    marginTop: "30px",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#084d4f",
    textTransform: "uppercase",
  },
  buttonGroupRoot: {
    marginTop: "14px",
  },
  grouped: {
    minWidth: "100px",
    padding: "5px 18px",
    backgroundColor: "#FFFFFF",
  },
  buttonLabel: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.36,
    letterSpacing: "0.14px",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#000000",
  },
  buttonOutlined: {
    borderColor: "#000000",
    borderRadius: "5px",
  },
  radio: {
    padding: "5px 10px",
    color: "#084d4f !important",
  },
  radioChecked: {
    color: "#084d4f !important",
  },
  radioLabel: {
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#084d4f",
    textTransform: "uppercase",
  },
  buttonWrap: {
    "& > .MuiButton-outlined": {
      marginLeft: 10,
    },
  },
  inputUser: {
    "& input": {
      fontWeight: 300,
    },
    "& label": {
      fontWeight: 300,
    },
  },
  asideMenuDropdown: {
    marginTop: 10,
    width: "100%",
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: "31px !important",
  },
  asideMenuDropdownIcon: {
    marginRight: "5.5px",
  },
  menuItem: {
    padding: "8px 14px !important",
    "&:hover .MuiListItemIcon-root svg path": {
      fill: "#000000",
    },
  },
  listItemWrap: {
    minWidth: 36,
  },
  listIcon: {
    width: 27,
    height: 27,
  },
  switchThumb: {
    height: "20px !important",
    paddingLeft: "0px !important",
    "&:after": {
      display: "none",
    },
    "&:before": {
      display: "none",
    },
  },
  userbtndisabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    color: "#fff !important",
    pointerEvents: "none",
  },
});
function AdminIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.614"
      height="21.083"
      viewBox="0 0 21.614 21.083"
      style={{ marginRight: 10 + "px" }}
    >
      <g>
        <g>
          <path
            d="M11.238 10.609a4.275 4.275 0 0 0 0-8.549 4.275 4.275 0 1 0 0 8.549zm0-6.411A2.136 2.136 0 1 1 9.1 6.335 2.137 2.137 0 0 1 11.238 4.2z"
            transform="translate(-2.688 -2.06)"
          />
          <path
            d="M13 18a5.948 5.948 0 0 1 .087-.978H4.826c.245-.769 3.527-2.137 6.412-2.137a11.739 11.739 0 0 1 2.47.288 6.036 6.036 0 0 1 1.5-1.82 15.481 15.481 0 0 0-3.968-.606c-2.85 0-8.55 1.432-8.55 4.275v2.138h10.424A6.076 6.076 0 0 1 13 18z"
            transform="translate(-2.688 -2.06)"
          />
        </g>
        <path
          fillRule="evenodd"
          d="M23.158 18a3.933 3.933 0 0 1-.036.5l1.085.849a.259.259 0 0 1 .062.329l-1.029 1.783a.254.254 0 0 1-.221.129.266.266 0 0 1-.092-.016l-1.281-.514a3.934 3.934 0 0 1-.869.5l-.2 1.363a.251.251 0 0 1-.252.216h-2.053a.252.252 0 0 1-.252-.216l-.195-1.363a3.747 3.747 0 0 1-.869-.5l-1.281.514a.225.225 0 0 1-.087.016.259.259 0 0 1-.227-.129l-1.028-1.779a.259.259 0 0 1 .062-.329l1.085-.853a3.546 3.546 0 0 1 0-1.008l-1.08-.845a.253.253 0 0 1-.062-.329l1.028-1.779a.257.257 0 0 1 .222-.129.274.274 0 0 1 .092.016l1.281.514a3.934 3.934 0 0 1 .869-.5l.195-1.363a.252.252 0 0 1 .252-.216h2.057a.251.251 0 0 1 .252.216l.2 1.363a3.747 3.747 0 0 1 .869.5l1.281-.514a.221.221 0 0 1 .087-.016.257.257 0 0 1 .226.129l1.029 1.779a.259.259 0 0 1-.062.329l-1.085.849a3.933 3.933 0 0 1 .027.504zM19.3 15.943A2.057 2.057 0 1 0 21.358 18a2.056 2.056 0 0 0-2.058-2.057z"
          transform="translate(-2.688 -2.06)"
        />
      </g>
    </svg>
  );
}
class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerState: false,
      checkedA: false,
      arrayItem: [],
      dialogOpen: false,
      editList: [
        {
          type: "Edit",
          icon: <EditOutlinedIcon htmlColor="#000" />,
          id: "edit",
          classname: "edit",
        },
        {
          type: "Deactivate User",
          icon: <PersonAddDisabledOutlinedIcon htmlColor="#000" />,
          id: "deactivate",
          classname: "deactivate",
        },
        {
          type: "Reactivate User",
          icon: <PersonAddOutlinedIcon htmlColor="#000" />,
          id: "reactivate",
          classname: "reactivate",
        },
        {
          type: "Revoke Admin Access",
          icon: <NotInterestedOutlinedIcon htmlColor="#000" />,
          id: "revoke",
          classname: "revoke",
        },
        {
          type: "Make Admin",
          icon: <AdminIcon htmlColor="#000" />,
          id: "admin",
          classname: "admin",
        },
        {
          type: "Remove User",
          icon: <RemoveCircleOutlineIcon htmlColor="#000" />,
          id: "remove",
          classname: "remove",
        },
        {
          type: "Resend Invite",
          icon: <SendOutlinedIcon htmlColor="#000" />,
          id: "resend",
          classname: "resend",
        },
      ],
      listToShow: [],
      page: 1,
      key: "",
      drawerFirstName: "",
      drawerLastName: "",
      drawerCaption: "",
      drawerRole: false,
      drawerDefaultFirstName: "",
      drawerDefaultLastName: "",
      drawerDefaultCaption: "",
      dataStatus: "",
      uniqueID: 0,
      archiveFilter: false,
      newUserPopup: false,
      popupName: "",
      popupEmail: "",
      itemKey: "",
      duplicateId: false,
      errorStateFName: false,
      errorStateLName: false,
      errorStateEmail: false,
      emailLabel: "Email Address",
      emailErrorClass: "",
      loader: true,
    };
    this.items = [...this.state.arrayItem];
    this.paginationCount = 10;
    this.refreshData = this.refreshData.bind(this);
    this.changeUser = this.changeUser.bind(this);
  }

  handleFirstName = (e) => {
    this.setState({ drawerFirstName: e });
    if (e === "") {
      this.setState({ errorStateFName: true });
    } else {
      this.setState({ errorStateFName: false });
    }
  };
  handleLastName = (e) => {
    this.setState({ drawerLastName: e });
    if (e === "") {
      this.setState({ errorStateLName: true });
    } else {
      this.setState({ errorStateLName: false });
    }
  };
  handleCaption = (email) => {
    this.setState({ drawerCaption: email });
    if (email === "") {
      this.setState({ errorStateEmail: true });
    } else {
      this.setState({ errorStateEmail: false });
    }
    let validemail = "",
      duplicateemail = "";
    if (validator.isEmail(email)) {
      validemail = "";
    } else {
      validemail = "Incorrect Email";
    }
    let count = this.state.arrayItem.length;
    for (let i = 0; i < count; i++) {
      if (this.state.arrayItem[i]["caption"] === email) {
        count = i;
        duplicateemail = "Duplicate Email";
      } else {
        duplicateemail = "";
      }
    }
    if (validemail !== "") {
      this.setState({
        emailLabel: validemail,
        emailErrorClass: "errorState",
      });
    } else if (duplicateemail !== "") {
      this.setState({
        emailLabel: duplicateemail,
        emailErrorClass: "errorState",
      });
    } else if (email === "") {
      this.setState({ emailErrorClass: "errorInput" });
    } else if (duplicateemail === "" && validemail === "" && email === "") {
      this.setState({
        emailLabel: "Email Address",
        emailErrorClass: "errorInput",
      });
    } else if (duplicateemail === "" && validemail === "" && email !== "") {
      this.setState({ emailLabel: "Email Address", emailErrorClass: "" });
    }
  };
  // Filter Archive
  handleArchiveFilter = debounce(() => {

    this.setState({
      archiveFilter: !this.state.archiveFilter,
    });
  });
  toggleDrawer = (open, type) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ drawerState: open, drawerType: type });
    if (open === false) {
      this.setState({
        key: "",
        drawerFirstName: "",
        drawerLastName: "",
        drawerCaption: "",
        drawerRole: false,
        drawerDefaultFirstName: "",
        drawerDefaultLastName: "",
        drawerDefaultCaption: "",
        dataStatus: "",
        uniqueID: 0,
        archiveFilter: false,
        errorStateFName: false,
        errorStateLName: false,
        errorStateEmail: false,
        emailLabel: "Email Address",
      });
    }
  };
  handleSwitch = () => {
    if (this.state.drawerRole === true) {
      this.setState({ drawerRole: false });
    } else {
      this.setState({ drawerRole: true });
    }
  };
  optionChange = debounce((value, itemIndex) => {
    const companyId = localStorage.getItem('companyId');
    if (value === "deactivate") {
      this.setState({
        dialogOpen: true,
        phaseKey: itemIndex,
      });
    } else if (value === "reactivate") {
      const userRef = fire.database().ref(companyId).child("users").child(itemIndex);
      userRef.update({
        deactivate: false,
      });
      this.changeUser(itemIndex, 'reactivate');
    } else if (value === "revoke") {
      const userRef = fire.database().ref(companyId).child("users").child(itemIndex);
      userRef.update({
        subtitle: "user",
      });
    } else if (value === "admin") {
      const userRef = fire.database().ref(companyId).child("users").child(itemIndex);
      userRef.update({
        subtitle: "admin",
      });
    } else if (value === "edit") {
      const user = this.state.arrayItem.find(d => d.id === itemIndex);
      if (user) {
        this.setState({
          phaseKey: itemIndex,
          optionAction: value,
          drawerState: true,
          drawerType: "old",
          drawerDefaultFirstName: user["firstname"],
          drawerDefaultLastName: user["lastname"],
          drawerDefaultCaption: user["caption"],
          drawerFirstName: user["firstname"],
          drawerLastName: user["lastname"],
          drawerCaption: user["caption"],
          drawerRole: user.subtitle === 'admin'
        });
      }

      setTimeout(() => {
        this.setState({
          optionAction: "",
        });
      }, 1000);
    } else if (value === "resend") {
      const user = this.state.arrayItem.find(d => d.id === itemIndex);
      const email = user && user.caption;
      if (email) {
        const logintoken = uuid();
        axios.get(`${getFunctionUrl()}/resendinvite?email=${encodeURIComponent(email)}&logintoken=${logintoken}`);
      }
    } else if (value === "remove") {
      const userRef = fire.database().ref(companyId).child("users").child(itemIndex);
      userRef.remove();
      this.changeUser(itemIndex, 'remove');
    }
    this.refreshData();
  });
  changeUser(itemIndex, action) {
    const user = this.state.arrayItem.find(d => d.id === itemIndex);
    const email = user && user.caption;
    if (email) {
      axios.get(`${getFunctionUrl()}/changeaccess?email=${encodeURIComponent(email)}&action=${action}`);
    }
  }

  deleteHandler = debounce((key) => {
    const user = this.state.arrayItem.find(d => d.id === key);
    const email = user && user.caption;
    const companyId = localStorage.getItem('companyId');
    const userRef = fire.database().ref(companyId).child("users").child(key);
    userRef.update({
      deactivate: true,
    });
    this.setState({
      dialogOpen: false,
      drawerState: false,
    });
    if (email) {
      axios.get(`${getFunctionUrl()}/changeaccess?email=${encodeURIComponent(email)}&action=deactivate`);
    }
    this.refreshData();
  });
  closeHandler = () => {
    this.setState({ dialogOpen: false });
  };
  handlernewUserPopup = () => {
    this.setState({
      newUserPopup: false,
    });
  };
  saveItem = async () => {
    if (isEmptyString(this.state.drawerFirstName)) {
      this.setState({ errorStateFName: true });
    }
    if (isEmptyString(this.state.drawerLastName)) {
      this.setState({ errorStateLName: true });
    }
    if (isEmptyString(this.state.drawerCaption)) {
      this.setState({ errorStateEmail: true, emailErrorClass: "errorInput" });
    }
    if (
      !isEmptyString(this.state.drawerFirstName) &&
      !isEmptyString(this.state.drawerLastName) &&
      !isEmptyString(this.state.drawerCaption)
    ) {
      if (this.state.drawerType === "new") {
        this.setState({
          newUserPopup: true,
        });
        //F Database
        let userType = "user";
        if (this.state.drawerRole === true) {
          userType = "admin";
        }

        const logintoken = uuid();
        const email = this.state.drawerCaption;

        const currentUser = fire.auth().currentUser;
        const currentUserEmail = currentUser.email;
        const currentUserInfo = this.state.arrayItem.find(d => d.caption === currentUserEmail);
        const { companyId } = currentUserInfo || {};

        //const currentUserUpdated = fire.auth().currentUser;

        try {
          // const UserCredential = await fire.auth().createUserWithEmailAndPassword(
          //   email,
          //   logintoken);

          const UserCredential = await axios.get(`${getFunctionUrl()}/createuser?email=${encodeURIComponent(email)}&logintoken=${logintoken}`,
            {
              headers: {
                'access-control-request-headers': 'content-type,x-client-version',
                'accept': '*/*'
              }
            },);

          const { uid } = UserCredential.data;
          const userData = {
            firstname: this.state.drawerFirstName || 'anonUser',
            lastname: this.state.drawerLastName || 'anonUser',
            caption: email,
            subtitle: userType,
            status: "",
            date: Moment(new Date()).format("MM/DD/YYYY"),
            deactivate: false,
            unconfirmed: true,
            companyId,
            uid
          };

          const userRef = fire.database().ref(companyId).child("users").child(uid);
          userRef.update(userData);

          const userCompanyRef = fire.database().ref('userCompany').child(uid);
          userCompanyRef.update({ companyId: companyId });

          axios.get(`${getFunctionUrl()}/emailtouser?email=${encodeURIComponent(email)}&logintoken=${logintoken}&firstname=${encodeURIComponent(userData.firstname)}&lastname=${encodeURIComponent(userData.lastname)}&companyId=${companyId}`,
            {
              headers: {
                'access-control-request-headers': 'content-type,x-client-version',
                'accept': '*/*'
              }
            },);
        } catch (err) {
          console.error(err);
        }
        this.setState({
          popupName:
            this.state.drawerFirstName + " " + this.state.drawerLastName,
          popupEmail: this.state.drawerCaption,
        });
      } else if (this.state.drawerType === "old") {

        let userType = "user",
          firstname = "",
          lastname = "",
          caption = "";
        // subtitle = "";
        if (this.state.drawerRole === true) {
          userType = "admin";
        }
        if (isEmptyString(this.state.drawerFirstName)) {
          firstname = "" + this.state.drawerDefaultFirstName + "";
        } else {
          firstname = "" + this.state.drawerFirstName + "";
        }
        if (isEmptyString(this.state.drawerLastName)) {
          lastname = "" + this.state.drawerDefaultLastName + "";
        } else {
          lastname = "" + this.state.drawerLastName + "";
        }
        if (isEmptyString(this.state.drawerCaption)) {
          caption = "" + this.state.drawerDefaultCaption + "";
        } else {
          caption = "" + this.state.drawerCaption + "";
        }
        // subtitle = "" + userType + "";
        const companyId = localStorage.getItem('companyId');
        const userRef = fire.database().ref(companyId).child("users").child(this.state.phaseKey);
        userRef.update({
          firstname: firstname,
          lastname: lastname,
          caption: caption,
          subtitle: userType,
        });
      }
      this.refreshData();
      this.setState({
        saveInfo: true,
        drawerState: false,
      });
      setTimeout(() => {
        this.setState({
          saveInfo: false,
          key: "",
          drawerFirstName: "",
          drawerLastName: "",
          drawerCaption: "",
          drawerRole: false,
          drawerDefaultFirstName: "",
          drawerDefaultLastName: "",
          drawerDefaultCaption: "",
          dataStatus: "",
          archiveFilter: false,
          errorStateFName: false,
          errorStateLName: false,
          errorStateEmail: false,
          emailLabel: "Email Address",
        });
      }, 500);
    };
    this.refreshData();
  };
  refreshData() {

    // const url_path = window.location.search;
    // if (url_path === "?new-user") {
    //   this.setState({ drawerState: true, drawerType: "new" });
    // }
    const companyId = localStorage.getItem('companyId');
    const userListRef = fire.database().ref(companyId).child("users");

    userListRef.once("value").then((snapshot) => {
      const users = snapshot.val();
      userListRef.off("value");
      const userlist = [];

      for (const id in users) {
        userlist.push({ id, ...users[id] });
      }
      this.setState({ arrayItem: userlist, loader: false });
    });
  }
  componentDidMount = () => {
    this.refreshData();
  };
  render() {
    const { classes } = this.props;
    const th = this;
    let listToShow = this.state.arrayItem;
    let deactivatesUsers = 0;
    for (let i = 0; i < this.state.arrayItem.length; i++) {
      if (isTrue(this.state.arrayItem[i]["deactivate"])) {
        deactivatesUsers = deactivatesUsers + 1;
      }
    }
    const { userFilter = 'all' } = this.state;
    const handleSort = (userFilter) => {
      this.setState({
        userFilter
      })
    };

    if (th.state.archiveFilter === true) {
      listToShow = this.state.arrayItem.sort(function (a, b) {
        return a["firstname"].localeCompare(b["firstname"]);
      });
    } else {
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          return a["firstname"].localeCompare(b["firstname"]);
        }) &&
        this.state.arrayItem.filter(function (i) {
          return `${i.deactivate}`
            .toLowerCase()
            .match(`${th.state.archiveFilter}`.toLowerCase());
        });
    }
    if (userFilter !== 'all') {
      listToShow = listToShow.filter(d => d.subtitle === userFilter);
    }

    return (
      <React.Fragment>
        {this.state.loader === true ? (
          <div className="loader-container">
            <object data={loaderimage} type="image/svg+xml"></object>
          </div>
        ) : (
          <Box
            className="user-management"
            component="div"
            style={{
              paddingTop: 92,
              backgroundColor: "rgba(8, 77, 79, 0.05)",
              minHeight: "calc(100vh - 60px)",
              position: "relative",
            }}
          >
            {this.state.newUserPopup === true && (
              <Alert
                onClose={this.handlernewUserPopup}
                className={classes.alert_wrap}
                variant="filled"
                severity="info"
              >
                {this.state.popupName} has been added. A confirmation email has
                been sent to {this.state.popupEmail}
              </Alert>
            )}
            <Box
              component="section"
              className="filter-menu"
              style={{ padding: "0 0 20px" }}
            >
              <Box className="container">
                <Grid container>
                  <Grid item xs={8}>
                    <Typography
                      variant="h5"
                      component="h5"
                      style={{ fontSize: 25, fontWeight: 100, marginTop: 6 }}
                    >
                      User Management
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      Manage users within your estimates workspace
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >

                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkedC"
                          onClick={this.handleArchiveFilter}
                          style={{ color: "#084D4F" }}
                        />
                      }
                      label=" Show Deactivated Users"
                      className={deactivatesUsers !== 0 ? "enabled" : "disabled"}
                      classes={{ label: classes.formLabel }}
                    />
                    <Box textAlign="right" paddingRight={1}>
                      <AdminUserFilter
                        handleSort={handleSort}
                      />

                    </Box>
                    <Box textAlign="right" >
                      <DefaultButton
                        variant="contained"
                        startIcon={<PersonAddOutlinedIcon />}
                        onClick={this.toggleDrawer(true, "new")}
                        id="newUser_btn"
                      >
                        Add User
                      </DefaultButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              component="section"
              className="panel-list"
              style={{ padding: "0 0 50px", marginTop: "10px" }}
            >
              <Box className="container">
                {listToShow.length > 0 ? (
                  <InfoPanel
                    items={listToShow}
                    page={this.state.page}
                    classes={this.props.classes || {}}
                    list={this.state.editList}
                    optionValue={this.optionChange}
                    paginationLimit={this.paginationCount}
                    searchWords={this.state.key}
                  />
                ) : (
                  <NoContent
                    title="Users. Let’s show them how it’s done."
                    description="Add Users here for your team."
                    Button_type="User"
                    createNew={this.toggleDrawer} />
                )}
              </Box>
            </Box>
            <Box component="aside" style={{ marginLeft: 79 }}>
              <AsideDrawer
                toolbarTitle={`${this.state.drawerType === "new" ? "Add" : "Edit"
                  } User`}
                open={this.state.drawerState}
                close={this.toggleDrawer(false)}
              >
                <Typography className={classes.label} id="name">
                  User Information
                </Typography>
                {/*//add errorInput here*/}
                <InputField
                  classes={classes.inputUser}
                  className={this.state.errorStateFName ? "errorInput" : ""}
                  label="First Name"
                  aria-labelledby="name"
                  required
                  defaultValue={this.state.drawerDefaultFirstName}
                  onChange={(e) => this.handleFirstName(e.target.value)}
                />
                {/*//add errorInput here*/}
                <InputField
                  classes={classes.inputUser}
                  className={this.state.errorStateLName ? "errorInput" : ""}
                  label="Last Name"
                  aria-labelledby="Last Name"
                  required
                  defaultValue={this.state.drawerDefaultLastName}
                  onChange={(e) => this.handleLastName(e.target.value)}
                />
                {/*//add errorInput here*/}
                <InputField
                  classes={classes.inputUser}
                  className={this.state.emailErrorClass}
                  label={`${this.state.emailLabel !== "Email Address"
                    ? this.state.emailLabel
                    : "Email Address"
                    }`}
                  aria-labelledby="Email Address"
                  required
                  defaultValue={this.state.drawerDefaultCaption}
                  onChange={(e) => this.handleCaption(e.target.value)}
                  helperText={this.state.duplicateId && "Email already exist"}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PurpleSwitch
                        checked={this.state.drawerRole}
                        onChange={this.handleSwitch}
                        classes={{ thumb: classes.switchThumb }}
                        name="checkedA"
                      />
                    }
                    label="Admin"
                    classes={{ label: classes.switchLabel }}
                  />
                </FormGroup>

                <Box className={classes.buttonWrap} mt={2}>
                  <DefaultButton variant="contained" onClick={this.saveItem}>
                    {`${this.state.drawerType === "new" ? "Create User" : "Save"
                      } `}{" "}
                  </DefaultButton>
                </Box>
              </AsideDrawer>
            </Box>
          </Box>
        )}
        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={""}
          text={"Do you want to deactivate this user?"}
          button={"Deactivate"}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UserManagement);
