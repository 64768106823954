import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    MenuItem,
} from '@material-ui/core';
import MenuDropdown from '../../small-components/MenuDropdown/MenuDropdown';

export default function AdminUserFilter(props) {

    const newStyles = makeStyles({
        sortCol: {
            ' .arrow-outer': {
                transform: 'rotate(0deg)',
                transition: 'all 0.3s  ease-in-out'
            }
        },
        menuDropdown: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        menuDropdownInput: {
            padding: '8.5px 10px',
            paddingRight: '31px !important',
            fontWeight: 300
        },
        menuDropdownIcon: {
            marginRight: '5.5px',
        },
        error: {
            border: "1px solid #db263b !important"
        }
    });

    const [sort, setSort] = React.useState('all');

    const handleSort = (event) => {
        setSort(event.target.value);
        props.handleSort(event.target.value);
    };

    const classes = newStyles();

    return (

        <MenuDropdown
            className={classes.menuDropdown}
            selectInputProps={{
                classes: {
                    root: classes.menuDropdownInput,
                    icon: classes.menuDropdownIcon,
                },
            }}
            getContentAnchorEl={null}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                }
            }}
            value={sort}
            change={handleSort}
        >
            <MenuItem value="all">All Users</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="user">Users</MenuItem>
        </MenuDropdown>

    )
}