import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles(() => ({
  gutters: {
    padding: '10px 10px 10px 30px'
  },
  toolbarTitle: {
    fontSize: 20,
    lineHeight: 1.25,
    fontWeight: 300
  }
}));

function AsideDrawer(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={props.open}
        //onClose={props.close}
        className={props.clsName}
        PaperProps={{
          style: {
            minWidth: 400,
            maxWidth: 400,
          },
        }}
      >
        <Toolbar classes={{ gutters: classes.gutters }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={9}>
              <Typography variant="h6" component="h5" className={classes.toolbarTitle}>
                {props.toolbarTitle}
              </Typography>
            </Grid>
            <Grid container justify="flex-end" item xs>
              <IconButton aria-label="delete" onClick={props.close}>
                <CloseOutlinedIcon fontSize="large" htmlColor="#084d4f80" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        <Box px={4}>{props.children}</Box>
      </Drawer>
    </React.Fragment>
  );
}

export default AsideDrawer;
