import React, { useEffect, useState } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { getCopyName } from '../../libs/getCopyName';
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import ExpenseItem from "./Expenses/expenseItem";
import formatMoneyStrFriendly from '../../libs/formatMoneyStrFriendly';
import * as getEstimateActionCreators from '../../store/estimate/getEstimate';
import uuid from "react-uuid";

const Expenses = (props) => {
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
    const [cost, setCost] = useState(0)
    const [expenses, setExpenses] = useState([])
    const [exclude, setExclude] = useState(false)
    const shared = props.shared

    const expenseTotal = (expenses) => {
        let total = 0;
        expenses?.forEach((exp) => {
            const cost = exp.cost * exp.qty
            total = total + cost
        })
        setCost(total)
    }

    const expenseExclude = (exp) => {
        const expenseList = [...expenses]
        const index = expenseList.findIndex((d) => d.expenseId === exp.expenseId);
        console.log(expenseList)
        expenseList[index] = {
            cost: expenseList[index].cost,
            expenseId: expenseList[index].expenseId,
            expenseName: expenseList[index].expenseName,
            expenseNotes: expenseList[index].expenseNotes,
            qty: expenseList[index].qty,
            exclude: !expenseList[index].exclude
        }
        props.updateExpense({ expenses: expenseList })

    }

    const qtyUpdate = (exp, qty) => {
        if (shared) { return }
        const expenseList = [...expenses]
        const index = expenseList.findIndex((d) => d.expenseId === exp.expenseId);
        console.log(expenseList)
        expenseList[index] = {
            cost: expenseList[index].cost,
            expenseId: expenseList[index].expenseId,
            expenseName: expenseList[index].expenseName,
            expenseNotes: expenseList[index].expenseNotes,
            qty: qty,
            exclude: expenseList[index].exclude
        }
        props.updateExpense({ expenses: expenseList })

    }

    const duplicateExpense = (expense) => {
        if (shared) { return }

        const expenseList = [...expenses]
        const newExpense = { ...expense }
        newExpense.expenseName = getCopyName(newExpense.expenseName, expenseList, 'expenseName');
        newExpense.expenseId = uuid();
        expenseList.push(newExpense)
        props.updateExpense({ expenses: expenseList })
        setExpenses(expenseList)
    }

    const deleteExpense = (expenseId) => {
        if (shared) { return }

        console.log('here', expenseId)
        const newExpense = expenses.filter(
            (d) => d.expenseId !== expenseId
        );
        props.updateExpense({ expenses: newExpense })
        setExpenses(newExpense);
    }

    useEffect(() => {
        setExpenses(props.estimate.expenses)
        // setExclude
    }, [props.estimate.expenses]);

    useEffect(() => {
        expenseTotal(expenses)
    }, [expenses]);

    return (
        <div id="expenses" className="table col l12 s12">
            <div className="row scope-inner expenses-card">
                <div className="col s12 col-inner">
                    <form>
                        <div className="row">
                            <div className="exp-title col s12 l10 m10">
                                <Typography variant="h4" component="h4">
                                    {"Estimate Expenses"}
                                </Typography>
                                <div className="bottom-text">
                                    <Typography variant="h6" component="h6">
                                        {expenses ? expenses.length + " count" : "0 count"}
                                    </Typography>
                                </div>
                            </div>
                            <div className="col s12 l2 m2 exp-total-cost">
                                <div>
                                    <span>Total Cost</span>
                                    <Tooltip title={cost === 0 || cost < 99999 ? "" : "$" + formatter.format(cost)} placement="top-end">
                                        {formatMoneyStrFriendly({ number: cost, numberFormat: true, beFriendly: true, maximumFractionDigits: 0 })}
                                    </Tooltip>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div className="col s12 col-outer">
                    <div className="row">

                        <div className="col l12 one-line-title">
                            <div className="input-field col s12 l6 m6">
                                <span>Expenses</span>
                            </div>
                            {expenses ?
                                <div className="col s12 l6 m6 input-cost">
                                    <div className="counting-expense-qty">
                                        <span>QTY</span>
                                    </div>
                                    <div className="list expense-cost">
                                        <span>Subtotal</span>
                                    </div>
                                </div>
                                : null
                            }
                        </div>

                        {expenses?.map((exp, index) => {
                            return (
                                <ExpenseItem
                                    key={`${exp.expenseName}-${index}`}
                                    expenseKey={`${exp.expenseName}-${index}`}
                                    expenseExclude={expenseExclude}
                                    qtyUpdate={qtyUpdate}
                                    expense={exp}
                                    duplicate={duplicateExpense}
                                    deleteExpense={deleteExpense}
                                    toggle={props.toggle}
                                />

                            )
                        })}

                        {
                            !shared ?
                                <div className={expenses ? "col s12  expense-submit" : "col s12 expense-submit empty_expense"}>
                                    <Button
                                        variant="contained"
                                        className={"new_expense_btn"}
                                        onClick={() => props.toggle('')}
                                    >
                                        Add New Expense
                                    </Button>
                                </div>
                                : null
                        }

                    </div>

                </div>
            </div>

        </div>

    )

}
function MapStateToProps(state) {
    return {
        estimate: state.getEstimate.estimate,
    };
}
function MapDispatchToProps(dispatch) {
    return {
        getEstimateAction: bindActionCreators(
            getEstimateActionCreators,
            dispatch
        ),
    };
}
export default compose(
    withRouter,
    connect(MapStateToProps, MapDispatchToProps)
)(Expenses);
