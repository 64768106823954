/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import uuid from "react-uuid";
import "../../assets/scss/summary.scss";
import Drawer from "@material-ui/core/Drawer";
import Discounted from "../small-components/Discounted/discounted";
import HistoryDrawer from "../small-components/DuplicateTemplate/HistoryDrawer";
import DuplicateTemplate from "../small-components/DuplicateTemplate/duplicate-template";
import SaveTemplate from "../small-components/SaveTemplate/SaveTemplate";
import SettingTemplate from "../small-components/SettingTemplate/SettingTemplate";
import DeleteTemplate from "../small-components/DeleteTemplates";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import StarIcon from "@material-ui/icons/Star";
import IconButton from "@material-ui/core/IconButton";
import ReplyIcon from "@material-ui/icons/Reply";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DomainIcon from "@material-ui/icons/Domain";
import ImagePlus from "../../assets/images/plus.svg";
import EditIconOutlined from "@material-ui/icons/EditOutlined";
import DeleteIconOutlined from "@material-ui/icons/DeleteOutlined";
import SelectMenu from "./SelectMenu";
import "../../assets/scss/autocomplete.scss";
import TextField from "@material-ui/core/TextField/TextField";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HeightIcon from "@material-ui/icons/Height";
import Estimate_Setting from "../../assets/images/setting-1.svg";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Sticky from "react-sticky-el";
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from "react-number-format";
import AlertDialog from "../small-components/AlertDialog/AlertDialog";
import ChipsArray from "../small-components/chips";
import fire from "../../config/firebase";
import { withRouter } from "react-router-dom";
import AutoCompleteOrganizations from "./autoComplete/autoCompleteOrganizations";
import EstimateCalculation from "../small-components/Phase/estimateCalculationUtil";
import FormatHour from "../../libs/formatHour";
import formatMoneyStrFriendly from "../../libs/formatMoneyStrFriendly";
import formatMoneyTimeBased from "../../libs/formatMoneyTimeBased";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import * as getEstimateActionCreators from '../../store/estimate/getEstimate';
import * as getResourcesActionCreators from '../../store/resources/getResources';
import * as getOrganizationsActionCreators from '../../store/organizations/getOrganizations';
import * as getContactsActionCreators from '../../store/contacts/getContacts';
import * as getUsersActionCreators from '../../store/users/getUsers';
import * as getTagsActionCreators from '../../store/tags/getTags';
import * as getEstimateBackupActionCreators from '../../store/estimateBackup/getEstimateBackup';
import { debounce, template } from 'lodash';
import Moment from "moment";

import ShareDialog from "./Shared/ShareDialog";

class EstimateFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      right: false,
      overlayType: "",
      dotClick: false,
      dotClick1: false,
      dotClick2: false,
      profitType: "%",
      profit_select: false,
      anchorEl: false,
      durationPopup: false,
      inputField: true,
      featured: false,
      dialogOpen: false,
      phaseKey: 0,
      deleteType: "",
      formatType: "days",
      newTitle: "",
      userEmail: "",
      favoriteestimate: [],
      resetDiscount: false,
      people: "",
      organization: "",
      name: "",
      viewType: '%',
      projectName: null,
      drawerCount: {
        setting: 0,
        undefined: 0,
      },
      showShareDialog: false

    };
    this.wrapperRef = React.createRef();
    this.wrapperRef1 = React.createRef();
    this.wrapperRef2 = React.createRef();

    this.refreshData = this.refreshData.bind(this);
    this.updateEstimate = this.updateEstimate.bind(this);
    this.shareEstimate = this.shareEstimate.bind(this);
    this.getQuery = this.getQuery.bind(this);
    this.handleBackUpEstimate = this.handleBackUpEstimate.bind(this)
  }
  componentDidMount = () => {
    const backUpEstimateId = setInterval(this.handleBackUpEstimate, 1200000);
    this.refreshData();
    const { user: authUser } = this.props;
    this.setState({
      userEmail: authUser.email, backUpEstimateId
    });
  };
  async componentWillUnmount() {
    clearInterval(this.state.backUpEstimateId);
    await this.handleBackUpEstimate({});
  }

  componentWillReceiveProps(newProps) {
    if (newProps.update !== this.props.update) {
      this.refreshData();
    }
  }
  shareEstimate({ hideResource = false, hideFinance = false }) {
    const { estimate } = this.props;
    if (estimate) {
      const shareKey = uuid().split('-')[0];
      const query = this.getQuery();
      const estimateRef = fire.database().ref('public').child(`${shareKey}/estimates/${shareKey}`);
      const sharedData = {
        tags: [],
        companyId: query.companyId,
        hideResource,
        hideFinance,
        shared: true,
        calculations: this.props.calculations
      }
      const newEstimateData = Object.assign({}, sharedData, estimate);
      estimateRef.update(newEstimateData);
      return `https://app.estimatic.com/shared-estimates/shared/${shareKey}`
    }
    return null;
  }

  async handleBackUpEstimate() {
    const { estimate } = this.props;

    const cloneEstimate = (item) => {
      const temp = JSON.parse(JSON.stringify(item || {}));
      delete temp.modified;
      delete temp.lastEditor;
      return temp;
    }
    const getAsString = (obj = {}) => {
      return JSON.stringify(obj);
    }
    const estimateDiff = (item1, item2) => {
      const diff = {};
      const keys1 = Object.keys(item1);
      for (let i = 0; i < keys1.length; i++) {
        const obj1 = item1[keys1[i]]
        const obj2 = item2[keys1[i]];
        if ((!!obj1 !== !!obj2) || getAsString(obj1) !== getAsString(obj2)) {
          diff[keys1[i]] = obj1;
        }
      }
      return diff;
    }
    if (estimate?.phases) {
      const { getEstimateBackupAsync } = this.props.getEstimateBackupAction
      const query = this.getQuery();
      const { estimateBackup } = (await getEstimateBackupAsync(query)).payload;
      const noBackup = (!estimateBackup || !Object.keys(estimateBackup || {}).length);

      if (noBackup) {
        const estimateRef = fire.database().ref(query.companyId).child('backups').child(query.dataType).child(query.estimateKey);
        const update = {};
        update[new Date().getTime()] = { date: new Date().getTime(), estimate };
        estimateRef.update(update);
      } else {
        const keys = Object.keys(estimateBackup).sort();
        const latestBackup = estimateBackup[keys[keys.length - 1]];
        const item1 = cloneEstimate(latestBackup.estimate || {});
        const item2 = cloneEstimate(estimate || {});
        // const isSame = JSON.stringify(item1) === JSON.stringify(item2);
        const diff1 = estimateDiff(item1, item2);
        const diff2 = estimateDiff(item2, item1);
        const isSame = Object.keys(diff1).length === 0 && Object.keys(diff2).length === 0;
        if (!isSame) {
          const estimateBackupRef = fire.database().ref(query.companyId).child('backups').child(query.dataType).child(query.estimateKey);
          const update = {};
          update[new Date().getTime()] = { date: new Date().getTime(), estimate };
          estimateBackupRef.update(update);
          this.updateEstimate({}, false);
        }
      }
    }
  }

  getQuery() {

    const companyId = localStorage.getItem('companyId');
    const arr = this.props.location.pathname.split('/');
    if (arr.length < 4) {
      return null;
    }
    const estimateKey = arr[arr.length - 1];
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    const dataTypeFavorite = this.props.match.path.includes("estimates")
      ? "favoriteestimate"
      : "favoritetemplates";
    const isTimebased = this.props.match.path.includes("timebased");
    const isTemplate = dataType === "templates";

    return { dataType, estimateKey, companyId, dataTypeFavorite, isTimebased, isTemplate }
  }
  async refreshData() {
    const { getEstimateAsync } = this.props.getEstimateAction;
    const { getOrganizationsAsync } = this.props.getOrganizationsAction;
    const { getContactsAsync } = this.props.getContactsAction;
    const query = this.getQuery();
    if (query) {
      getEstimateAsync(query);
      getOrganizationsAsync(query);
      getContactsAsync(this.query);
    }

  }

  handleBillableChange = (data) => {
    this.updateEstimate(data, true);
  };

  discountView = (type) => {
    this.setState({
      viewType: type,
    });
    this.dotOpen();
  };

  async updateEstimate(updateData, refreshData = true) {
    const query = this.getQuery();
    if (query) {
      const user = fire.auth().currentUser.uid;
      const date = Moment(new Date()).format("h:mm a MMMM DD, YYYY");
      const estimateRef = fire.database().ref(query.companyId).child(`${query.dataType}/${query.estimateKey}`);
      const newEstimateData = Object.assign({}, updateData);
      newEstimateData.lastEditor = user;
      newEstimateData.modified = date;
      estimateRef.update(newEstimateData);
      if (refreshData) {
        const { getEstimateAsync } = this.props.getEstimateAction;
        getEstimateAsync(this.getQuery());
      }
    }
  }

  dotOpen = () => {
    this.setState({ anchorEl: true });
    const currentState = this.state.dotClick;
    this.setState({ dotClick: !currentState });
  };
  dotOpen1 = () => {
    const currentState = this.state.dotClick1;
    this.setState({ dotClick1: !currentState });
  };
  toggleDrawer = (type) => () => {

    const { drawerCount = {} } = this.state;
    drawerCount[type] = (drawerCount[type] || 0) + 1;
    const anchor = "right"
    const open = !this.state.right;
    if (type === 'history') {
      this.handleBackUpEstimate();
    }

    this.setState({
      ...this.state,
      [anchor]: open,
      overlayType: type,
      dotClick: false,
      drawerCount
    });
  };
  durationMenu = () => {
    const currentState = this.state.durationPopup;
    this.setState({ durationPopup: !currentState });
  };
  deletePopup = (e) => {
    this.setState({
      dialogOpen: true,
      phaseKey: e,
      deleteType: "navlink",
    });
  };
  deleteHandler = () => {
    this.updateEstimate({
      discountType: null,
      discountValue: 0
    })
    this.setState({
      dialogOpen: false
    });
  };
  closeHandler = () => {
    this.setState({
      dialogOpen: false,
    });
  };
  updateBreadcrumb = async (value) => {

    const query = this.getQuery()
    if (query) {
      const currentEstimate = fire.database().ref(query.companyId).child(query.dataType + "/" + query.estimateKey);
      currentEstimate.update({
        projectName: value,
      });
      // this.refreshData();
      const { getEstimateAsync } = this.props.getEstimateAction;
      await getEstimateAsync(query);
      this.setState({ projectName: value });
    }
  };
  handleFormat = (e) => {
    this.setState({ formatType: e });
  };
  optionAction = (e) => {
    this.setState({ ...this.state, right: true, overlayType: e.toLowerCase() });
  };
  handleFavorite = async () => {
    console.log('fav')
    const query = this.getQuery();
    if (query) {

      const dataType = this.props.match.path.includes("estimates")
        ? "favoriteestimate"
        : "favoritetemplates";

      const { users, user: authUser } = this.props;
      const tempUsers = [...users]

      const user = tempUsers.find(d => d["caption"] === authUser.email)
      console.log(authUser)
      console.log(tempUsers)
      if (user) {
        let favoriteestimate = [];
        console.log(user)
        if (user[dataType]) {
          favoriteestimate = [...user[dataType]]
        }
        const found = favoriteestimate.find(d => d.id === query.estimateKey);
        const temp = {};

        const userRef = fire.database().ref(query.companyId).child("users").child(user.id);
        if (found) {
          favoriteestimate = favoriteestimate.filter(d => d.id !== query.estimateKey);
        } else {
          favoriteestimate.push({ id: query.estimateKey });
        }
        temp[dataType] = favoriteestimate;
        userRef.update(temp);
        this.setState({
          favoriteestimate
        });
      }
      const { getUsersAsync } = this.props.getUsersAction;
      await getUsersAsync({});
    }
  };

  handleCloseEditScreen = () => {
    if (this.props.closeEditScreen) {
      this.props.closeEditScreen("close");
    }
  };

  render() {
    const { estimate, tags, company, calculations, users, organizations, contacts } = this.props;
    const query = this.getQuery();

    if (!query || !estimate || !tags || !company || !calculations || !users || !organizations || !contacts || estimate.estimateKey !== query.estimateKey) {
      return null;
    }

    const { people: contact, discountType = null, organization, rounding } = estimate;
    const { dataType, dataTypeFavorite, isTimebased, isTemplate } = query;
    const { viewType = discountType, showShareDialog } = this.state;

    const targetMargin = parseInt(
      estimate.targetMargin || company.targetMargin || 0
    );
    const estimateCalculator = new EstimateCalculation(estimate, calculations);
    const arr = this.props.location.pathname.split('/');
    const key = arr[arr.length - 1];

    let favorite = false;
    const user = users.find(d => d.caption === this.state.userEmail);
    if (user) {
      if (user[dataTypeFavorite]) {
        const favoriteestimate = user[dataTypeFavorite]
        favorite = favoriteestimate.find((d) => d && d.id === key) !== undefined;
      }
    }

    let { projectName = '' } = estimate;
    if (this.state.projectName) {
      projectName = this.state.projectName

    }

    let startDate = null;
    let endDate = null;
    const getDateFromStr = (str) => {
      const dateParts = str.split(" ")[0].split("-");
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    };
    if (isTimebased) {
      if (estimate && estimate.phases) {
        for (const phase of estimate.phases) {
          if (phase.tasks) {
            for (const task of phase.tasks) {
              if (task.resources) {
                for (const resource of task.resources) {
                  if (resource.start_date) {
                    const curStartDate = getDateFromStr(resource.start_date);
                    if (
                      startDate === null ||
                      startDate.getTime() > curStartDate.getTime()
                    ) {
                      startDate = curStartDate;
                    }
                  }
                  if (resource.end_date) {
                    const currentEndDate = getDateFromStr(resource.end_date);
                    const correctDay = new Date(currentEndDate.getTime());
                    correctDay.setDate(currentEndDate.getDate() - 1);
                    if (endDate === null || endDate.getTime() < correctDay.getTime()
                    ) {
                      endDate = correctDay;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    const billableSwitch =
      estimate.billableSwitch || company.billableSwitch || false;

    let { discountValue = 0 } = estimate;

    const discountFomatted = (() => {
      let formatValue = "";
      if (discountType === "%" && discountValue) {
        formatValue = Math.round(discountValue);
      }
      if (discountType === "" && discountValue) {
        formatValue = Math.round(discountValue);
      } else {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (specialChars.test(discountValue)) {
          discountValue = discountValue.replace(/[^a-zA-Z0-9]/g, "");
        }
        const roundValue = Math.round(discountValue || 0);
        formatValue = roundValue
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return formatValue;
    })();

    const totalEstimatePrice = estimateCalculator.totalEstimatePrice();

    const totalExpensePrice = estimateCalculator.totalExpensePrice();
    const totalEstimateCost = estimateCalculator.totalEstimateCost();
    const totalEstimateHours = estimateCalculator.totalEstimateHours();

    const totalEstimateProfit = estimateCalculator.totalEstimateProfit();
    const totalEstimateProfitPercentage =
      estimateCalculator.totalEstimateProfitPercentage();
    const afterDiscountPrice = estimateCalculator.getAfterDiscountPrice();
    const afterDiscountPriceWithoutRouding = estimateCalculator.getAfterDiscountPrice(false);
    let roundedTotal = afterDiscountPrice;
    let nearest = estimateCalculator.getNearest(rounding) != "none" ? estimateCalculator.getNearest(rounding) : 0

    if (nearest) {
      roundedTotal = Math.round(afterDiscountPrice / nearest) * nearest;
    }

    let marginColor = "#db263b";

    const targetBudget = parseInt(
      estimate.targetBudget || 0
    );
    let targetWidth = 0;
    if (roundedTotal) {
      targetWidth = (roundedTotal / targetBudget) * 100 >= 100 ?
        100
        : 1 > (roundedTotal / targetBudget) * 100 > 0 ?
          1
          : (roundedTotal / targetBudget) * 100
    }

    const targetStyling = {
      width: targetWidth,
      backgroundColor: roundedTotal > targetBudget ? "red" : "green",
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });

    const formatterExpense = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    });

    const targetMessage = targetWidth >= 100 ?
      formatter.format(afterDiscountPrice - targetBudget) + " over budget"
      : formatter.format(targetBudget - afterDiscountPrice) + " under budget"

    const vendorUpSell = parseInt(
      estimate.vendorUpSell || company.vendorUpSell || 0
    );

    if (billableSwitch) {
      if (
        totalEstimateProfitPercentage > targetMargin &&
        totalEstimateProfitPercentage > vendorUpSell
      ) {
        marginColor = "#65a838";
      } else {
        marginColor = "#db263b";
      }
    }
    const getDiff = (timeUnit) => {
      if (!startDate || !endDate) {
        return "";
      }
      const Difference_In_Time = endDate.getTime() - startDate.getTime();
      const DifferenceInDays = Difference_In_Time / (1000 * 3600 * 24);
      if (timeUnit === "week") {
        return Math.floor(DifferenceInDays / 7) + " Weeks";
      }
      return (DifferenceInDays / 30).toFixed(1) + " Months";
    };
    const getMMDD = (d) => {
      if (!d) {
        return "";
      }
      return (
        d.toLocaleString("default", { month: "short" }) + " " + d.getDate()
      );
    };
    const showDiscount = () => {
      const { viewType = discountType } = this.state;

      if (discountType === "%") {
        if (viewType === "%") {
          return discountFomatted + discountType;
        }
        const disNum = (estimateCalculator.getDiscountValue()).toLocaleString()
        return `$${disNum}`;
      }

      if (discountType === "$") {
        if (viewType === "$") {
          return discountType + discountFomatted;
        }
        if (!totalEstimatePrice || totalEstimatePrice <= 0) {
          return `0%`;
        }
        return (
          (
            (100 * estimateCalculator.getDiscountValue()) /
            totalEstimatePrice
          ).toFixed(1) + "%"
        );
      }

      if (discountType === "") {
        if (viewType === "0") {
          return discountType + discountFomatted;
        }
        if (!totalEstimatePrice || totalEstimatePrice <= 0) {
          return `0`;
        }
        const disNum = estimateCalculator.getDiscountValue()
        return `${disNum}`;

      }

    };

    const billableRate = parseFloat(estimate.definedhourlyRate) ? parseFloat(estimate.definedhourlyRate) : totalEstimateHours ? totalEstimatePrice / totalEstimateHours : 0;

    return (
      <div className="summary" key={key}>
        <div className="container" style={{ maxWidth: "1330px", padding: "0" }}>
          {showShareDialog && <ShareDialog isTemplate={isTemplate} isTimebased={isTimebased} closeDialog={() => this.setState({ showShareDialog: false })} open={showShareDialog} onClose={() => { }} onShareEstimate={this.shareEstimate} />}
          <div className="row top-content">
            <div className="col l2 top-content ">
              <span className="title">
                <Typography variant="h6" component="h6">
                  {this.props.title + (isTemplate ? " Template" : " Estimate")}
                </Typography>
              </span>
              <span className="violator-ribbon">
                <Typography variant="h6" component="h6">
                  {this.props.title + " Template"}
                </Typography>
              </span>
              <div className="directives">
                <Tooltip title="Favorite" placement="top">

                  <IconButton
                    className="new-featured"
                    onClick={this.handleFavorite}
                  >
                    {favorite ? (
                      <StarIcon htmlColor="#2ab7ca" />
                    ) : (
                      <svg
                        className="featured"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 22 20.9"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.909 9.793L13 2.5l3.091 7.293 7.909.671-6.006 5.2L19.8 23.4 13 19.3l-6.8 4.1L8 15.667l-6-5.203zm4.158.869L13 8.143l-1.067 2.5-.517 1.221-1.32.11-2.717.226 2.068 1.793 1 .869-.3 1.3-.616 2.651 2.336-1.413L13 16.723l1.133.7 2.332 1.408-.616-2.651-.3-1.3 1-.869 2.068-1.793-2.717-.225-1.32-.11z"
                          transform="translate(-2 -2.5)"
                        />
                      </svg>
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Share" placement="top">
                  <IconButton
                    className="new-featured"
                    onClick={() => {
                      this.setState({ showShareDialog: true });
                    }}
                  >
                    <span style={{ marginLeft: 22, cursor: 'pointer' }}>
                      <ReplyIcon
                        htmlColor="#000" className="mirror-reply" />
                    </span>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Estimate Settings" placement="top">
                  <IconButton
                    className="setting-wrap"
                    onClick={this.toggleDrawer("setting")}

                  >
                    <span style={{ marginLeft: 22, cursor: 'pointer' }}>
                      <img
                        src={Estimate_Setting}
                        alt="setting"
                      />
                    </span>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Estimate Actions" placement="top-end">

                  <span className="drawer_menu_button">
                    <SelectMenu
                      isTemplate={isTemplate}
                      optionAction={this.optionAction}
                      keyite={key}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="row row-wrap-counting">
            <div className="col l12 s12 m12 ">
              <div
                className={`main-title-wrap`}
              >
                <TextField
                  id="project_title"
                  variant="outlined"
                  type="text"
                  label={
                    dataType === "templates"
                      ? "Template Name"
                      : "Estimate Name"
                  }
                  key={query.estimateKey}
                  defaultValue={projectName}
                  onChange={(e) => this.updateBreadcrumb(e.target.value)}
                />
              </div>

              <div className="row tag-row">
                <div className="col l8">
                  <div className="company-name">
                    <div className="inner-title-wrap">
                      <AutoCompleteOrganizations
                        key={organization + key + "organization"}
                        dataKey={key}
                        title={"organization"}
                        table="organizations"
                        contactType="Organization"
                        tableKey="organization"
                        update={(orgData) => {
                          this.updateEstimate(orgData);
                        }}
                        selectValue={organization}
                        options={organizations}
                      />
                      <DomainIcon className="company_icon" />
                    </div>
                  </div>
                  <div className="client-name">
                    <div className="inner-title-wrap">
                      <AutoCompleteOrganizations
                        key={organization + key + "people"}
                        dataKey={contact}
                        title={"Contact"}
                        table="people"
                        contactType="People"
                        tableKey="people"
                        update={(contactData) => {
                          this.updateEstimate(contactData);
                        }}
                        selectValue={contact}
                        options={contacts}
                      />
                      <PermContactCalendarOutlinedIcon className="client_icon" />
                    </div>
                  </div>
                  <div className="tag-name" ref={this.wrapperRef2}>
                    <ChipsArray
                      dataType={dataType}
                      tagsList={this.props.estimate?.tags || []}
                      refreshData={() => {
                        const { getTagsAsync } = this.props.getTagsAction;
                        getTagsAsync({});
                        this.refreshData();
                      }}
                      allTags={this.props.tags}
                      itemId={key}
                      key={key}
                    />
                  </div>
                </div>
                <div className="col l4 estimate-chips">
                  <div className={totalExpensePrice > 0 ? "estimate-drops" : "estimate-drops full-width"} >
                    <div
                      className={
                        this.state.dotClick
                          ? "active discount-item"
                          : "discount-item"
                      }
                    >
                      <span
                        style={{
                          width: "100%",
                          padding: "16px 20px 14px 20px",
                          borderRadius: "5px",
                          backgroundColor: "rgba(8, 77, 79, 0.05)",
                          color: "#084d4f",
                        }}
                      >
                        DISCOUNT{" "}
                        {discountType !== null ? (
                          <React.Fragment>
                            <span className="discount-added">
                              {showDiscount()}
                            </span>

                            <MoreVertIcon
                              className="profit-dots"
                              onClick={this.dotOpen}
                              ref={this.wrapperRef1}
                            />
                            {this.state.dotClick && (
                              <ClickAwayListener
                                onClickAway={() => {

                                  this.setState({ dotClick: false })
                                }
                                }
                              >
                                <ul>
                                  {viewType === "%" ? (
                                    <li onClick={() => this.discountView("$")}>
                                      <AttachMoneyIcon />
                                      View as USD
                                    </li>
                                  ) : (
                                    <li
                                      onClick={() => {
                                        this.discountView("%");
                                      }}
                                    >
                                      <span>%</span>
                                      View as Percentage
                                    </li>
                                  )}
                                  <li
                                    onClick={this.toggleDrawer(
                                      "discount"
                                    )}
                                  >
                                    <EditIconOutlined />
                                    Edit Discount
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.setState({
                                        dialogOpen: true,
                                        dotClick: false
                                      });
                                    }}
                                  >
                                    <DeleteIconOutlined />
                                    Delete
                                  </li>
                                </ul>
                              </ClickAwayListener>
                            )}
                          </React.Fragment>
                        ) : (
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              right: "22px",
                              top: "14px",
                              position: "absolute",
                              cursor: "pointer",
                            }}
                            src={ImagePlus}
                            alt="plus"
                            onClick={this.toggleDrawer("discount")}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  {totalExpensePrice > 0 && <div className="estimate-drops">
                    <div
                      className={
                        this.state.dotClick
                          ? "active discount-item"
                          : "discount-item"
                      }
                    >
                      <Tooltip title={totalExpensePrice === 0 || totalExpensePrice < 99999 ? "" : "$" + formatterExpense.format(totalExpensePrice)} placement="top-end">
                        <span
                          style={{
                            width: "100%",
                            padding: "16px 20px 14px 20px",
                            borderRadius: "5px",
                            backgroundColor: "rgba(8, 77, 79, 0.05)",
                            color: "#084d4f",
                          }}
                        >
                          EXPENSES {" "}
                          <span className="discount-added">
                            {formatMoneyStrFriendly({ number: totalExpensePrice, numberFormat: true, beFriendly: true, maximumFractionDigits: 0 })}
                          </span>
                        </span>
                      </Tooltip>
                    </div></div>
                  }

                </div>
              </div>
              <Sticky
                topOffset={-77}
                stickyClassName={"calcSticky"}
                boundaryElement="header"
                relative="true"
                top='94px'
              >
                <div className="row">
                  <div className="col l8 countings">
                    {isTimebased && (

                      <div className="date-wrap">
                        <Typography variant="h6" component="h6">
                          START & END
                        </Typography>
                        <span>
                          {this.state.formatType === "days"
                            ? getMMDD(startDate) + " - " + getMMDD(endDate)
                            : this.state.formatType === "weeks"
                              ? getDiff("week")
                              : getDiff("month")}
                        </span>

                        <span className="open-dots">
                          <MoreVertIcon
                            htmlColor="#e70a89"
                            onClick={this.durationMenu}
                          />{" "}
                        </span>
                        {this.state.durationPopup && (

                          <ClickAwayListener onClickAway={this.durationMenu}>
                            <div className="menu-item">
                              <div className="upper-grid active">
                                <span className="icon-outer">
                                  <DateRangeIcon htmlColor="#e70a89" />
                                </span>
                                <span className="text-box"> Start & End </span>
                              </div>
                              <div className="lower-grid">
                                <span className="icon-outer">
                                  <HeightIcon />
                                </span>
                                <span className="text-box"> Duration </span>
                                <List component="nav" className="nav-outer">
                                  <ListItem
                                    className={`${this.state.formatType === "days"
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <ListItemText
                                      primary="Days"
                                      onClick={() => this.handleFormat("days")}
                                    />
                                  </ListItem>
                                  <ListItem
                                    className={`${this.state.formatType === "weeks"
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <ListItemText
                                      primary="Weeks"
                                      onClick={() => this.handleFormat("weeks")}
                                    />
                                  </ListItem>
                                  <ListItem
                                    className={`${this.state.formatType === "months"
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <ListItemText
                                      primary="Months"
                                      onClick={() => this.handleFormat("months")}
                                    />
                                  </ListItem>
                                </List>
                              </div>
                            </div>
                          </ClickAwayListener>
                        )}
                      </div>

                    )}
                    <div className="hour-wrap">
                      <Typography variant="h6" component="h6">
                        Hours
                      </Typography>
                      <span>{FormatHour({ number: totalEstimateHours })}</span>
                    </div>
                    <div className="cost-wrap">
                      <Typography variant="h6" component="h6">
                        Cost
                      </Typography>
                      <span>
                        {formatMoneyTimeBased({
                          number: totalEstimateCost,
                          money: true,
                          maximumFractionDigits: 0,
                        })}
                      </span>
                    </div>
                    <div className="bill-wrap">
                      <Typography variant="h6" component="h6">
                        BILLING RATE
                      </Typography>
                      <span>
                        {!billableSwitch
                          ? "N/A"
                          : formatMoneyStrFriendly({
                            number: isNaN(billableRate) ? 0 : billableRate,
                            perTitle: "/hr",
                            money: true,
                            maximumFractionDigits: 0,
                          })}
                        {/* FormatMoney(estimates.definedhourlyRate ? estimates.definedhourlyRate : (totalEstimatePrice / totalEstimateHours), "/hr")} */}
                      </span>
                    </div>
                    <div
                      className={
                        this.state.dotClick1
                          ? "active profit-margin"
                          : "profit-margin"
                      }
                    >
                      <Typography variant="h6" component="h6">
                        Profit Margin
                      </Typography>
                      <span
                        //color: '#65a838'
                        style={{ color: marginColor }}
                      >
                        {billableSwitch !== true ? (
                          "N/A"
                        ) : (
                          <>
                            {this.state.profitType === "$"
                              ? "$" + ((totalEstimateProfit).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""}
                            {this.state.profitType === "%"
                              ? totalEstimateProfitPercentage + "%"
                              : ""}
                          </>
                        )}
                      </span>
                      <MoreVertIcon
                        className="profit-dots"
                        onClick={this.dotOpen1}
                        ref={this.wrapperRef}
                      />
                      {this.state.dotClick1 && (
                        <ClickAwayListener
                          onClickAway={() =>
                            this.setState({ dotClick1: false })
                          }
                        >
                          <ul>
                            <li
                              onClick={() => this.setState({ profitType: "$" })}
                              className={
                                this.state.profitType === "$" ? "active" : ""
                              }
                            >
                              <AttachMoneyIcon />
                              View as USD
                            </li>
                            <li
                              onClick={() => this.setState({ profitType: "%" })}
                              className={
                                this.state.profitType === "%" ? "active" : ""
                              }
                            >
                              <span>%</span>
                              View as Percentage
                            </li>
                          </ul>
                        </ClickAwayListener>
                      )}
                    </div>

                  </div>
                  <Tooltip
                    title={nearest ? "Rounded from: " + formatter.format(afterDiscountPriceWithoutRouding) : ""}
                    placement="top-end"
                  >
                    <div
                      className={`col l4 total ${this.state.defaultTotal !== totalEstimatePrice
                        ? "discountAdded"
                        : ""
                        }`}
                    >
                      <div className="estimate-window">
                        <div className="target-budget">
                          <h6>TOTAL</h6>
                          {targetBudget ?
                            <Tooltip
                              title={targetWidth == 0 ? "" : targetMessage}
                              placement="bottom-start"
                            >
                              <div className="target-hover">
                                <p>{formatter.format(targetBudget)}</p>

                                <div className="target-bar">
                                  <span style={targetStyling} ></span>
                                </div>
                              </div>
                            </Tooltip>

                            : null}

                        </div>

                        <div className="estimate-total">
                          <h2 style={{ fontSize: 45 }}>
                            {formatMoneyTimeBased({
                              number: roundedTotal.toFixed(0).slice(0, 10),
                              money: true,
                              maximumFractionDigits: 0,
                              beFriendly: false,
                              grandTotal: true
                            })}
                          </h2>
                          {afterDiscountPrice.toFixed(0) ===
                            totalEstimatePrice.toFixed(0) ? null : (
                            <h5>
                              <strike>
                                <NumberFormat
                                  id="no_discount"
                                  displayType={"text"}
                                  isNumericString={true}
                                  thousandSeparator={true}
                                  value={
                                    this.state.defaultTotal !== totalEstimatePrice &&
                                    totalEstimatePrice.toFixed(0).slice(0, 10)
                                  }
                                  prefix={"$"}
                                />
                              </strike>
                            </h5>
                          )}
                        </div>

                      </div>
                    </div>
                  </Tooltip>
                </div>
              </Sticky>
            </div>
          </div>
          <Drawer
            anchor={"right"}
            open={this.state.right}
            onClose={this.toggleDrawer()}
          >
            {this.state.overlayType === "discount" ? (
              <Discounted
                key={key}
                onClose={this.toggleDrawer()}
                discountApplied={this.discountApplied}
                estimates={estimate}
                price={totalEstimatePrice}
                resetDiscount={this.state.resetDiscount}
                update={this.updateEstimate}
              />
            ) : null}
            {this.state.overlayType === "duplicate" ? (
              <DuplicateTemplate
                originalName={estimate?.name || null}
                projectName={projectName}
                onClose={this.toggleDrawer('')}
              />
            ) : this.state.overlayType === "save" ? (
              <SaveTemplate
                dataKey={key}
                projectName={projectName}
                closeEditScreen={this.handleCloseEditScreen}
                dataType={dataType}
                onClose={this.toggleDrawer('')}
              />
            ) : this.state.overlayType === "history" ? (
              <HistoryDrawer
                key={key}
                resource={[]}
                refreshData={this.refreshData}
                estimate={estimate}
                onClose={this.toggleDrawer('')}
                query={this.getQuery()}
              />
            ) : null}
            {this.state.overlayType === "setting" ? (
              <SettingTemplate
                key={key}
                refreshData={this.refreshData}
                onClose={this.toggleDrawer()}
                updateEstimate={this.updateEstimate}
                currentProfit={totalEstimateProfitPercentage}
                estimate={estimate}
                drawerCount={this.state.drawerCount['setting'] || 0}
              />
            ) : null}
            {this.state.overlayType === "delete" ? (
              <DeleteTemplate
                name={projectName}
                onClose={this.toggleDrawer()}
                dataKey={key}
              />
            ) : null}
          </Drawer>
        </div>
        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          deleteType={this.state.deleteType}
          name={"discount"} />
      </div>
    );
  }
}

// export default withRouter(EstimateFor);

function MapStateToProps(state) {
  return {
    estimate: state.getEstimate.estimate,
    estimateBackup: state.getEstimateBackup.estimateBackup,
    tags: state.getTags.tags,
    user: state.getUser.user,
    resources: state.getResources.resources,
    company: state.getCompany.company,
    users: state.getUsers.users,
    calculations: state.getCompany.calculations,
    organizations: state.getOrganizations.organizations,
    contacts: state.getContacts.contacts
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimateAction: bindActionCreators(
      getEstimateActionCreators,
      dispatch
    ),

    getResourcesAction: bindActionCreators(
      getResourcesActionCreators,
      dispatch
    ),

    getOrganizationsAction: bindActionCreators(
      getOrganizationsActionCreators,
      dispatch
    ),
    getContactsAction: bindActionCreators(
      getContactsActionCreators,
      dispatch
    ),
    getUsersAction: bindActionCreators(
      getUsersActionCreators,
      dispatch
    ),
    getTagsAction: bindActionCreators(
      getTagsActionCreators,
      dispatch
    ),
    getEstimateBackupAction: bindActionCreators(
      getEstimateBackupActionCreators,
      dispatch
    ),

  };
}

export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(EstimateFor);
