import React, { useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import estimatic_logo from '../../assets/images/Estimatic_Logo.svg';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import robo_form from '../../assets/images/robo-form.svg';
import { Link } from "react-router-dom";
import { getFunctionUrl } from '../../config/firebase';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '50px 0',
    alignItems: 'center',
    height: 'calc(100vh - 60px)',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  img_wrap: {
    maxWidth: '200px',
    width: '100%'
  },
  anchor: {
    borderRadius: '5px',
    backgroundColor: '#e70a89',
    background: '#e70a89!important',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: '0.14px',
    textAlign: 'center',
    width: '100%',
    color: '#fff',
    textTransform: 'inherit',
    boxShadow: 'none',
    padding: '10px',
    '&:hover': {
      boxShadow: 'none',
      background: '#d00a7b !important',
    }
  },
  large_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: '#fff',
    padding: '55px 50px !important'

  },
  intro_wrap: {
    fontSize: '20px',
    fontWeight: "100",
    paddingTop: '15px',
    marginBottom: '60px'

  },
  text_wrap: {
    fontSize: '14px',
    maxWidth: '260px',
    margin: '7px 0 18px',
    display: 'block',
    float: 'none !important'

  },
  small_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderLeft: '0',
    borderRadius: '5px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    backgroundColor: '#fff',
    padding: '55px 50px 30px !important',
    maxWidth: '430px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },
  btn_wrap: {
    background: '#e70a89!important',
    width: '100%',
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'inherit',
    boxShadow: 'none',
    minHeight: '40px',
    '&:hover': {
      boxShadow: 'none',
      background: '#d00a7b !important',
    }
  },
  wrap_item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px'
  },
  bottom_item: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  success_heading: {
    marginBottom: '15px !important'
  },
  success_para: {
    fontSize: '14px',
    lineHeight: '1.36',
    marginBottom: '20px'
  }
}));
export default function ForgetPassword() {
  const [emailSent, setEmailSent] = useState(false);
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: '',
    email: '',
    weight: '',
    weightRange: '',
    showEmail: false,
    error: true,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const submitPass = (e) => {
    e.preventDefault();
    axios.get(`${getFunctionUrl()}/resetpassword?email=${encodeURIComponent(values.email)}&time=${new Date().getTime()}`,
      {
        headers: {
          'access-control-request-headers': 'content-type,x-client-version',
          'accept': '*/*'
        }
      },).then(() => {
        setEmailSent(true);
      })
  }

  return (
    <React.Fragment>
      <div className="login-form ">
        <div className={'form-wrap ' + classes.root}>

          {emailSent === false ?
            (<Grid container spacing={3} justify="center" className="inner-wrap">
              <Grid item xs={5} className={"large-block " + classes.large_block}>
                <div className={classes.img_wrap}>
                  <img src={estimatic_logo} alt="Estimatic" />
                </div>
                <p className={classes.intro_wrap}>
                  Feeling forgetful? Don't worry. We'll get you estimating again in no time!
                </p>
                <div className="img-wrap">
                  <img src={robo_form} alt="robot" />
                </div>
              </Grid>
              <Grid item xs={4} className={classes.small_block} style={{ paddingBottom: '40px', position: 'relative' }}>
                <form className="form">
                  <Typography variant="h6" component="h6" style={{ fontWeight: 300 }}>
                    Forgot Password
                  </Typography>
                  <span className={classes.text_wrap} style={{ fontWeight: 300 }}>
                    Enter your email:
                  </span>
                  <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      value={values.email}
                      name="email"
                      onChange={handleChange('email')}
                      labelWidth={70}
                    />
                  </FormControl>
                  <Button style={{ fontWeight: 500 }}
                    variant="contained"
                    type="button"
                    className={classes.btn_wrap}
                    onClick={submitPass}>
                    Submit
                  </Button>
                </form>
                {/*{values.strength && (*/}
                {/*<Box className={classes.pwdBox} style={{ position: 'absolute', left: 0, bottom: 0, textAlign: 'center', width: '100%', padding: '0 51px 30px' }}>*/}
                {/*<Alert onClose={closeAlert} severity="warning">*/}
                {/*<AlertTitle style={{fontWeight: 300}}>Weak password</AlertTitle>*/}
                {/*</Alert>*/}
                {/*</Box>*/}
                {/*)}*/}
              </Grid>
            </Grid>
            )
            :
            (
              <Grid container spacing={3} justify="center" className="inner-wrap">
                <Grid item xs={5} className={"large-block " + classes.large_block}>
                  <div className={classes.img_wrap}>
                    <img src={estimatic_logo} alt="Estimatic" />
                  </div>
                  <p className={classes.text_wrap}>
                    Feeling forgetful? Don't worry. We'll get you estimating again in no time!
                  </p>
                  <div className="img-wrap">
                    <img src={robo_form} alt="robot" />
                  </div>
                </Grid>
                <Grid item xs={4} className={classes.small_block}>
                  <form className="form">
                    <Typography variant="h5" component="h5" className={classes.success_heading}>
                      Reset instructions sent!
                    </Typography>
                    <Typography variant="p" component="p" className={classes.success_para}>
                      If an account exists for that email address, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.
                    </Typography>
                    <Link variant="contained"

                      type="button" className={classes.anchor} to="/">Return to Login Screen</Link>
                  </form>
                </Grid>
              </Grid>

            )
          }
        </div>
      </div>
    </React.Fragment>
  )
}
