import React, { Component } from 'react';
import Chart from "react-apexcharts";

class Canvas extends Component {
	constructor(props) {
		super(props);
		this.state = {
			empty: false,
			series: this.props.perc,
			options: {
				chart: {
					width: 221,
					type: 'donut',
				},
				stroke: {
					width: 0
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [1],
					dropShadow: {
						enabled: false,
					},
					formatter: function (val) {
						return Math.round(val) + '%'
					}
				},
				fill: {
					type: '',
				},
				legend: {
					show: false
				},
				tooltip: {
					enabled: true,
					y: {
						show: true,
						formatter: function (val) {
							return Math.round(val) + '%'
						},
					},
				},
				colors: this.props.color,
				labels: this.props.name,
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 200
						}
					}
				}]
			},
		};
	}

	componentDidMount = () => {
		const costExists = this.props.data
		if (costExists) {
			const found = costExists.filter((item) => item.cost > 0)
			if (found.length == 0 && this.props.perc[0] === 100) {
				this.setState({ empty: !this.state.empty })
			}
		}

	}

	render() {
		return (
			<div className="chart-wrap">
				<div id="chart" className={this.state.empty ? "empty" : "full"} style={{ 'marginTop': '22px' }}>
					<Chart
						options={this.state.options}
						series={this.state.series}
						type="donut"
						width="350"
					/>
					<div style={{
						color: '#084d4f',
						padding: "10px",
						fontFamily: 'NotoSans',
						fontSize: '12',
						textAlign: 'center',
					}}>PRICE PER {this.props.sectionName}</div>
				</div>
			</div>
		);
	}

}

export default Canvas;