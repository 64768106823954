import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import AutoCompletePhase from "../../reusable-components/autoComplete/autoCompletePhase";
import PhaseTotals from "./PhaseTotals";
import Task from "../Task";
import Button from "@material-ui/core/Button";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import UnfoldLessOutlinedIcon from "@material-ui/icons/UnfoldLessOutlined";
import UnfoldMoreOutlinedIcon from "@material-ui/icons/UnfoldMoreOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ClearIcons from "../../../libs/icons";
import { Typography } from "@material-ui/core";
import drag_icon from "../../../assets/images/drag_icon.svg";
import "../../../assets/scss/summary.scss";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import $ from "jquery";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AlertDialog from "../AlertDialog/AlertDialog";
import Tooltip from "@material-ui/core/Tooltip";
import uuid from "react-uuid";
import fire from "../../../config/firebase";
import Calculations from "./Calculations";
import { withRouter } from "react-router-dom";
import { orderItems, sortItems, getOrderInt } from "../../../libs/utils";
import PhaseCalculation from "./phaseCalculationUtil";
import { getCopyName } from "../../../libs/getCopyName";
import Moment from "moment";
import * as getEstimateActionCreators from '../../../store/estimate/getEstimate';
const set = new Set();
class Phase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {},
      empty: true,
      buttonShow: true,
      expandTask: false,
      offsetY: 0,
      dialogOpen: false,
      phaseKey: "",
      arrowState: false,
      inputValue1: 0,
      inputValue2: 0,
      arrayTest: [],
      tasksOrdered: null
    };
    this.wrapperRef = React.createRef();
    this.wrapperRef1 = React.createRef();
    this.el = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.showButton = this.showButton.bind(this);
    this.handleCtrlZ = this.handleCtrlZ.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.reorder = this.reorder.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);

    this.closeHandler = this.closeHandler.bind(this);
    this.deletePhase = this.deletePhase.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.collapsePhase = this.collapsePhase.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.expandPhase = this.expandPhase.bind(this);
    this.enableCalculationToggleHandle =
      this.enableCalculationToggleHandle.bind(this);

    this.openDrop = this.openDrop.bind(this);

    this.duplicateTask = this.duplicateTask.bind(this);
    this.updatePhase = this.updatePhase.bind(this);
    this.reorderTasks = this.reorderTasks.bind(this);
    this.range = this.range.bind(this);
    this.refreshData = this.refreshData.bind(this);

    this.addtask = this.addtask.bind(this);
    this.getQuery = this.getQuery.bind(this);
    this.checkPhaseExclude = this.checkPhaseExclude.bind(this);
  }

  updatePhase(data, timestamp = new Date().getTime()) {
    const query = this.getQuery();
    const user = fire.auth().currentUser.uid;
    const date = Moment(new Date()).format("h:mm a MMMM DD, YYYY")
    const estimateRef = fire.database().ref(query.companyId).child(`${query.dataType}/${query.estimateKey}`);
    const refPhaseArr = fire
      .database()
      .ref(query.companyId).child(`${query.dataType}/${query.estimateKey}/phases`);
    refPhaseArr.once("value").then(async (snapshot) => {
      const arr = snapshot.val();
      refPhaseArr.off("value");
      if (set.has(timestamp)) {
        return;
      }
      set.add(timestamp);
      const phaseIndex = arr.findIndex((d) => d.phaseId === query.phaseId);
      const phase = arr[phaseIndex];

      if (phase) {
        await estimateRef.update({ 'lastEditor': user, 'modified': date });
        await refPhaseArr.child(phaseIndex).update(data);
        const { getEstimateAsync } = this.props.getEstimateAction;
        await getEstimateAsync(this.getQuery());
        setTimeout(() => {
          this.setState({
            tasksOrdered: null
          })
        }, 1000);

      }
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.swapped || newProps.touched) {
      this.setState({
        offsetY: this.el.getBoundingClientRect().top,
      });
    }
  }
  handleCtrlZ(event) {
    if (["INPUT", "TEXTAREA"].indexOf(event.target.tagName) > -1) {
      return;
    }
    const { ctrlKey, keyCode, metaKey } = event;
    if ((!!ctrlKey || !!metaKey) && keyCode === 90) {
      const undoData = JSON.parse(localStorage.getItem("undoData"));
      let spliceData = "";
      let phases = [];
      let tasks = [];
      let parentIndex = "";
      const { estimates: temp } = this.props;
      const estimate = { ...temp }
      if (undoData.length > 0 && undoData[0]["action"] === "addTask") {
        parentIndex = undoData[0]["parentIndex"];
        let flag = 0;

        if (flag === 0) {
          flag = 1;
          phases = estimate["phases"];
          tasks = phases[parentIndex]["tasks"];
          tasks.splice(tasks.length - 1, 1);
          phases[parentIndex]["tasks"] = tasks;
        }

        if (undoData.length > 1) {
          spliceData = undoData.splice(0, 1);
        } else {
          spliceData = [];
        }
        const arr = this.props.location.pathname.split('/');
        const key = arr[arr.length - 1];
        const dataType = this.props.match.path.includes("estimates")
          ? "estimates"
          : "templates";
        const { companyId, shared } = this.props;
        const listRef = fire.database().ref(companyId).child(shared ? key : dataType + "/" + key);
        listRef.update({
          phases: phases,
        });
        localStorage.setItem("undoData", JSON.stringify(spliceData));
      }
    }
  }
  componentDidUpdate() {
    const { offsetY } = this.state;
    if (offsetY) {
      const { top } = this.el.getBoundingClientRect();
      this.transform(offsetY - top);
      setTimeout(() => {
        this.resetTransform();
        this.setState({ offsetY: 0 });
      }, 10);
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleCtrlZ);
    document.addEventListener("click", this.handleClickOutside);
    $(document).on("click", function () {
      $(".summary-details .phase-parent").each(function () {
        const parentIndex = $(this).index();
        const text = $(this).find(".phaseName input[type=text]").val();
        if (text) {
          $(
            ".summary-details .outer-content .inner-content:eq(" +
            parentIndex +
            ") .phase-text span"
          ).text(text);
        }
        $(this)
          .find(".task-count")
          .each(function () {
            const tasktext = $(this).find(".taskName input[type=text]").val();
            const taskIndex = $(this).index();
            if (tasktext) {
              $(
                ".summary-details .outer-content .inner-content:eq(" +
                parentIndex +
                ") ul li:eq(" +
                taskIndex +
                ") span"
              ).text(tasktext);
            }
          });
      });
    });
    $(document).on("click", ".taskDuplicate", function () {
      const phaseIndex = $(this).parents(".phase-parent").index();
      let value = $(this).parents(".task-count").find(".taskName input").val();
      if (value === "") {
        value = "Task Name";
      }
      $("#scope .small-col .inner-content:eq(" + phaseIndex + ") ul").append(
        "<li><span>" + value + "</span></li>"
      );
    });
    $(document).on("click", ".taskDelete", function () {
      const phaseIndex = $(this).parents(".phase-parent").index();
      const taskIndex = $(this).parents(".task-count").index();
      $(
        "#scope .small-col .inner-content:eq(" +
        phaseIndex +
        ") ul li:eq(" +
        taskIndex +
        ")"
      ).addClass("tbd");
    });
    $(document).on("click", ".close-overlay", function () {
      $("#scope .small-col .inner-content ul li.tbd").removeClass("tbd");
    });
    $(document).on("click", ".delete-confirm", function () {
      $("#scope .small-col .inner-content ul li.tbd").remove();
    });
    $(document).on("input change", ".switchInput", function () {
      $(this).attr("data-value", $(this).val());
    });
    // this.refreshData();
  }
  refreshData() {

  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.props.shared !== true) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        if (this.state.expandTask) {
          this.setState({
            expandTask: false,
          });
        }
      }
    }
  }
  range(min, max) {
    if (min < max) {
      return [...Array(max - min + 1).keys()].map((i) => i + min);
    } else {
      return [...Array(min - max + 1).keys()].map((i) => i + max);
    }
  }
  reorderTasks(data) {
    if (
      !data ||
      !data.source ||
      !data.destination ||
      data.destination === data.source
    ) {
      return;
    }
    const { tasks = [] } = this.props.phase;
    const tasksClone = JSON.parse(JSON.stringify(tasks));
    const source = tasksClone.find((d) => d.taskId === data.source);
    const destination = tasksClone.find((d) => d.taskId === data.destination);
    const tasksOrdered = orderItems({
      list: tasksClone,
      field: "taskOrder",
      source,
      destination,
      nameField: "taskName",
    });
    this.setState({
      tasksOrdered
    });
    this.updatePhase({ tasks: tasksOrdered });
  }
  onDragEnd(result) {
    const { tasks } = this.props.phase;
    if (!result.destination) {
      return;
    }
    const data = {
      phaseId: this.props.phase.phaseId,
      source: tasks[result.source.index].taskId,
      destination: tasks[result.destination.index].taskId,
    };
    this.reorderTasks(data);
  }
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }
  getQuery() {
    const { companyId, shared } = this.props
    const arr = this.props.location.pathname.split('/');
    const estimateKey = arr[arr.length - 1];
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    return { dataType, estimateKey, companyId, shared, phaseId: this.props.phase.phaseId }
  }
  updateTask(task, data) {
    const query = this.getQuery();
    const { phases } = this.props.estimate;
    const phaseIndex = phases.findIndex((d) => d.phaseId === query.phaseId);
    const phase = phases[phaseIndex];
    const user = fire.auth().currentUser.uid;
    const date = Moment(new Date()).format("h:mm a MMMM DD, YYYY")
    const estimateRef = fire.database().ref(query.companyId).child(`${query.dataType}/${query.estimateKey}`);
    if (phase) {
      const taskIndex = phase.tasks.findIndex(
        (d) => d.taskId === task.taskId
      );
      const refInner = fire.database().ref(query.companyId).child(query.shared ? query.estimateKey : `${query.dataType}/${query.estimateKey}`);

      if (taskIndex >= 0) {
        refInner
          .child("phases")
          .child(phaseIndex)
          .child("tasks")
          .child(taskIndex)
          .update(data);
        const { getEstimateAsync } = this.props.getEstimateAction;
        getEstimateAsync(this.getQuery());
        estimateRef.update({ 'lastEditor': user, 'modified': date });
      }
    }

  }
  addtask(e) {
    const { phase = {} } = this.props;
    const { tasks = [] } = phase;
    const task = {
      taskId: uuid(),
      taskName: "",
      resources: [],
      taskOrder: tasks.length + 1,
      qty: 1,
    };
    tasks.push(task);
    this.updatePhase({ tasks });
    const parent = e.currentTarget.closest(".phase-parent");
    const parentIndex = $(parent).index();
    // Save add task info to undo data
    const undoData = JSON.parse(localStorage.getItem("undoData")) || [];

    undoData.unshift({
      state: "tasksAsComponents",
      action: "addTask",
      parentIndex: parentIndex,
    });

    localStorage.setItem("undoData", JSON.stringify(undoData));

    // $(".summary-details .outer-content .inner-content:eq(" + parentIndex + ") ul").append("<li><span>Task Name</span></li>");
    // let target = "";
    // if (tasks.length === 0) {
    //   target = $(".task-outer:eq(" + parentIndex + ")");
    //   setTimeout(function () {
    //     $('html, body').animate({
    //       scrollTop: $(target).offset()?.top - 77
    //     }, 500);
    //   }, 500);
    // } else {
    //   target = $(".task-outer:eq(" + parentIndex + ") .task-count:eq(" + (tasks.length - 1) + ")");
    //   if (target) {
    //     const lessTop = $(target).outerHeight();
    //     setTimeout(function () {
    //       $('html, body').animate({
    //         scrollTop: $(target).offset()?.top + (lessTop - 50)
    //       }, 500);
    //     }, 500);
    //   }
    // };
  }
  showButton() {
    this.setState({
      buttonShow: true,
    });
  }
  duplicateTask(task) {
    const { tasks = [] } = this.props.phase;
    const duplicate = { ...task };
    duplicate.taskName = getCopyName(duplicate.taskName, tasks, 'taskName');
    duplicate.taskId = uuid();
    const order = getOrderInt(duplicate.taskOrder) + 1;
    duplicate.taskOrder = order;

    tasks.forEach((item) => {
      const position = getOrderInt(item.taskOrder);
      if (order <= position) {
        const newOrder = position + 1;
        item.taskOrder = newOrder;
      }
    });
    tasks.push(duplicate);
    this.updatePhase({ tasks });
  }
  deleteTask(task) {
    const { tasks = [] } = this.props.phase;
    const taskSource = tasks.find((d) => d.taskId === task.taskId);
    const orderDeleted = getOrderInt(taskSource.taskOrder);
    const newTasks = tasks.filter((d) => d.taskId !== task.taskId);

    newTasks.forEach((task) => {
      const position = getOrderInt(task.taskOrder);
      if (orderDeleted < position) {
        task.taskOrder = position - 1;
      }
    });
    this.updatePhase({ tasks: newTasks });
  }
  closeHandler() {
    this.setState({ dialogOpen: false });
  }
  deletePhase() {
    this.props.deletePhase(this.props.phase);
  }
  checkPhaseExclude = (e) => {
    const row = e.currentTarget.closest(".scope-inner");
    $(row).find(".task-outer").slideUp();
    const { phase } = this.props;
    const exclude = (phase && phase.exclude) || false;
    this.updatePhase({ exclude: !exclude });
  }

  collapsePhase = (e) => {
    const row = e.currentTarget.closest(".scope-inner");
    $(row).find(".task-outer").slideUp();
    this.setState({ ...this.state, arrowState: true });
  }
  expandPhase = (e) => {
    const row = e.currentTarget.closest(".scope-inner");
    $(row).find(".task-outer").slideDown();
    // const phase = e.currentTarget.closest(".phase-parent").getAttribute("data-rbd-draggable-id");
    // document.querySelector(`[data-rbd-draggable-id=${phase}]`).scrollIntoView({ behavior: "smooth" })
    this.setState({ ...this.state, arrowState: false });
  }

  collapseAll(e) {
    // debugger;
    const row = e.currentTarget.closest(".scope-inner");
    $(row).find(".resource").slideUp();
    //$(row).find(".task-outer").slideDown();
    // this.setState({ ...this.state, arrowState: true });
  }
  expandAll(e) {
    const row = e.currentTarget.closest(".scope-inner");
    //$(row).find(".resource").slideUp();
    $(row).find(".resource").slideDown();
    // this.setState({ ...this.state, arrowState: false });
  }

  enableCalculationToggleHandle(calculation) {
    const { calculations } = this.props;
    const curCalculation = calculations.find(
      (d) => d.dataKey === calculation.dataKey
    );
    if (curCalculation) {
      const { phaseCalculations = {} } = this.props.phase;
      if (phaseCalculations[calculation.id] === undefined) {
        phaseCalculations[calculation.id] = false;
      }
      phaseCalculations[calculation.id] = !phaseCalculations[calculation.id];
      this.updatePhase({ phaseCalculations });
    }
  }

  openDrop() {
    this.setState((prevState) => ({
      expandTask: !prevState.expandTask,
    }));
  }

  render() {

    const query = this.getQuery();
    let { phases, phase, calculations = [], estimate = {}, billableSwitch = false, toggleEditDrawer, editVendorValue, toggleEditCostRateDrawer } = this.props;
    if (query.shared) {
      calculations = estimate.calculations
    }
    const { hideFinance } = estimate;
    console.log(hideFinance)
    const arrayItem = []
    for (const id in phases) {
      arrayItem.push({ id, ...phases[id] });
    }

    const { phaseCalculations = {}, phaseCalculationsOverwrite = {} } = phase;
    const phaseCalculationCls = new PhaseCalculation(
      phase,
      calculations,
      estimate
    );

    const { phaseName, tasks = [] } = phase;

    const { expandTask, dialogOpen, arrowState } = this.state;
    if (tasks) {
      tasks.forEach((task) => {
        if (!task.resources) {
          task.resources = [];
        }
        if (!task.qty) {
          task.qty = 1;
        }
      });
    }

    const { tasksOrdered } = this.state;
    const sortedTasks = tasksOrdered || (tasks
      ? sortItems({ list: tasks, field: "taskOrder", nameField: "taskName" })
      : []);
    const count = sortedTasks.length || 0;

    const totalPhaseHours = phaseCalculationCls.totalPhaseHours();
    const totalPhaseHoursWithoutAuto = phaseCalculationCls.totalPhaseHours(false);
    const totalPhaseCost = phaseCalculationCls.totalPhaseCost();
    const totalPhaseCostAutoOff = phaseCalculationCls.totalPhaseCost(false);
    const totalPhasePriceWithoutAuto = phaseCalculationCls.totalPhasePrice(
      false,
      true
    );
    const opacity = phase && phase.exclude ? 0.3 : 1;
    const exclude = (phase && phase.exclude) || this.props.exclude;
    return (
      <div className="row scope-inner card">
        <div className="col s12 col-inner">
          <span className="icon-box">
            <img src={drag_icon} alt="Drag" />
          </span>
          <form>
            <div className="row" >
              <div className="input-field col s12 l6 m6 phaseName">
                <span>Phase</span>
                <div className={exclude ? 'excludePhase' : ''} >
                  <AutoCompletePhase
                    dataList={arrayItem}
                    classes={this.props.classes.addTask}
                    title={"Phase Name"}
                    phaseId={phase.phaseId}
                    type="phase"
                    cla={"phase-name"}
                    selectValue={phaseName}
                    update={(data) => { this.updatePhase(data); this.refreshData(); }}
                  />
                </div>

                <div className="bottom-text" style={{ opacity }}  >
                  <Typography variant="h6" component="h6">
                    {count === 1 ? count + " Task" : count + " Tasks"}
                  </Typography>
                </div>
              </div>
              <PhaseTotals
                opacity={opacity}
                key={phase.phaseId + billableSwitch}
                totalPhaseHours={totalPhaseHours}
                totalPhaseCost={totalPhaseCost}
                shared={query.shared}
                totalPhasePrice={phaseCalculationCls.totalPhasePrice(
                  true,
                  true
                )}
              />
            </div>
          </form>

          {query.shared !== true &&
            <div
              className={expandTask ? "active content-outer" : "content-outer"}
            >

              <span className="top-arrow" style={{}}>
                {count > 0 ? (
                  arrowState ? (
                    <Tooltip placement="right" title="Expand" aria-label="add">
                      <ExpandLessIcon
                        htmlColor="#000"
                        onClick={this.expandPhase}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="right" title="Collapse" aria-label="add">
                      <ExpandMoreIcon
                        htmlColor="#000"
                        onClick={this.collapsePhase}
                      />
                    </Tooltip>
                  )
                ) : (
                  ""
                )}
              </span>
              <MoreVertOutlinedIcon
                htmlColor="#000"
                className="open-close"
                onClick={this.openDrop}
                ref={this.wrapperRef}
              />
              <div>
                {expandTask && (
                  <ul>
                    <li onClick={this.collapseAll}>
                      <span className="img-box">
                        <UnfoldLessOutlinedIcon htmlColor="#000" />
                      </span>{" "}
                      Collapse All Tasks
                    </li>
                    <li onClick={this.expandAll}>
                      <span className="img-box">
                        <UnfoldMoreOutlinedIcon htmlColor="#000" />
                      </span>{" "}
                      Expand All Tasks
                    </li>
                    <li
                      className="phaseDuplicate"
                      onClick={() => this.props.createDuplicate(this.props.phase)}
                    >
                      <span className="img-box">
                        <FilterNoneIcon
                          htmlColor="#000"
                          className="mirror_filter"
                        />
                      </span>
                      Duplicate
                    </li>
                    <li
                      className="phaseDuplicate"
                      onClick={this.checkPhaseExclude}
                    >
                      <span className="img-box" >
                        {exclude ? <ClearIcons
                          htmlColor="#000"
                          className="clear_icon"
                          type="exclude" />
                          :
                          <ClearIcons
                            htmlColor="#000"
                            className="clear_icon"
                            type="include" />
                        }
                      </span>
                      {exclude ? 'Include' : 'Exclude'}
                    </li>
                    <li
                      className="phaseDelete"
                      onClick={() => this.setState({ dialogOpen: true })}
                    >
                      <span className="img-box">
                        <DeleteOutlineOutlinedIcon htmlColor="#000" />
                      </span>
                      Delete
                    </li>
                  </ul>
                )}
              </div>
            </div>}
        </div>

        {!exclude && <div className="col s12 task-outer">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {sortedTasks?.map((task, index) => (
                    <Draggable
                      key={"drag" + task.taskId + estimate?.billableSwitch}
                      draggableId={`tag-${task.taskId}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="task-count"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Task
                            key={task.taskId + estimate?.billableSwitch}
                            refreshData={this.props.refreshData}
                            classes={this.props.classes.addTask}
                            buttonItem={this.showButton}
                            toggleDrawer={this.props.toggleDrawer}
                            drawerInfoUpdate={this.props.drawerInfoUpdate}
                            totalTaskPrice={phaseCalculationCls.totalTaskPrice(
                              task,
                              false,
                              false
                            )}
                            // updateCnt={updateCnt}
                            duplicateTask={this.duplicateTask}
                            deleteTask={this.deleteTask}
                            keyite={index}
                            updateTask={this.updateTask}
                            phaseId={phase.phaseId}
                            task={task}
                            toggleEditDrawer={toggleEditDrawer}
                            toggleEditCostRateDrawer={toggleEditCostRateDrawer}
                            editVendorValue={editVendorValue}
                            companyId={query.companyId}
                            shared={query.shared}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
            {query.shared !== true &&
              <Button
                variant="contained"
                className={"new_task_btn " + this.props.classes.buttonit}
                onClick={this.addtask}
              >
                Add New Task
              </Button>}
          </DragDropContext>
          {sortedTasks && hideFinance !== false ? (
            <div className="auto-fill">
              <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item xs={6}>
                  <Paper>
                    <p>Auto Calculations wow</p>
                  </Paper>
                </Grid>
                <Grid item xs={6} className="values-wrap">
                  {hideFinance !== true && <Grid item xs={2} className="wrap-count">
                    <Paper>
                      <p>Hours</p>
                    </Paper>
                  </Grid>}
                  <Grid item xs={2} className="cost-wrap">
                    <Paper>
                      <p>Cost</p>
                    </Paper>
                  </Grid>
                  <Grid item xs={2} className="price-wrap">
                    <Paper>
                      <p>Subtotal</p>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <ul>
                {calculations?.map((calculation, index) => {
                  return (
                    <Calculations
                      key={`${calculation.id}-${index}`}
                      enabled={phaseCalculations[calculation.id] === true}
                      phaseCalculation={phaseCalculations[calculation.id]}
                      calculation={
                        phaseCalculationsOverwrite[calculation.id] ||
                        calculation
                      }
                      phaseCalculationsOverwrite={phaseCalculationsOverwrite}
                      totalPhaseCost={totalPhaseCostAutoOff}
                      totalPhaseHoursWithoutAuto={totalPhaseHoursWithoutAuto}
                      totalPhasePriceWithoutAuto={totalPhasePriceWithoutAuto}
                      enableCalculationToggleHandle={
                        this.enableCalculationToggleHandle
                      }
                      updatePhase={this.updatePhase}
                    />
                  );
                })}
              </ul>
            </div>
          ) : null}
        </div>
        }
        <AlertDialog
          open={dialogOpen}
          onClose={this.closeHandler}
          keyValue={this.deletePhase}
          name={"phase"}
        />
      </div>
    );
  }
}

function MapStateToProps(state) {
  return {
    estimate: state.getEstimate.estimate,
    phases: state.getPhases.phases,
    calculations: state.getCompany.calculations,
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimateAction: bindActionCreators(
      getEstimateActionCreators,
      dispatch
    ),
  };
}

export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(Phase);
