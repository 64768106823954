import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import AsideDrawer from '../../components/small-components/AsideDrawer/AsideDrawer';
import InputField from '../../components/small-components/UI/InputField';
import { DefaultButton } from '../../components/small-components/UI/Buttons';
import TextField from '@material-ui/core/TextField';
import uuid from "react-uuid";
import Grid from "@material-ui/core/Grid";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import * as getEstimateActionCreators from '../../store/estimate/getEstimate';
import "../../assets/scss/expense-drawer.scss"


const ExpensesDrawer = (props) => {

    const [errorState, setErrorState] = useState(false)
    const [cost, setCost] = useState(1)
    const [qty, setQty] = useState(1)
    const [name, setName] = useState('')
    const [note, setNote] = useState('')
    const [errorName, setErrorName] = useState(false)
    const [errorCost, setErrorCost] = useState(false)
    const [errorQty, setErrorQty] = useState(false)

    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

    const drawerInput = (e) => {
        setName(e);
        if (e !== "") {
            setErrorName(false);
        } else {
            setErrorName(true);
        }
    };

    const qtyChange = (num) => {
        const re = /^[0-9]/g;
        if (num !== '' && re.test(num) === false) {
            return;
        }
        if (num.length > 3) {
            return;
        }
        setQty(num)
        if (num !== "") {
            setErrorQty(false);
        } else {
            setErrorQty(true);
        }
    }

    const costChange = (num) => {
        const re = /^[0-9]/g;
        if (num !== '' && re.test(num) === false) {
            return;
        }
        if (num.length > 5) {
            return;
        }
        setCost(num)
        if (num !== "") {
            setErrorCost(false);
        } else {
            setErrorCost(true);
        }
    }

    const handleNoteChange = (text) => {
        setNote(text)
    }

    const saveItem = (item) => {
        if (name === "") {
            console.log(name)
            setErrorName(true)
        }
        if (cost === 0) {
            setErrorCost(true)
        }
        if (qty === 0) {
            setErrorQty(true)
        }
        if (name !== "" &&
            cost !== 0 &&
            qty !== 0
        ) {
            props.toggle();
            let source = props.estimate.expenses || []
            let expenses = [...source]
            if (props.id) {
                const index = source.findIndex((d) => d.expenseId === props.id);
                expenses[index] = {
                    cost,
                    expenseId: expenses[index].expenseId,
                    expenseName: name,
                    expenseNotes: note,
                    qty,
                    exclude: expenses[index].exclude

                }

            } else {
                const expenseData = {
                    cost,
                    expenseId: uuid(),
                    expenseName: name,
                    expenseNotes: note,
                    qty,
                    exclude: false

                };
                expenses.push(expenseData)

            }

            props.onSuccess({ expenses: expenses })

        }
    }

    useEffect(() => {
        if (props.id) {
            let expenses = props.estimate.expenses
            const index = expenses.findIndex((d) => d.expenseId === props.id);

            setCost(expenses[index].cost)
            setName(expenses[index].expenseName)
            setQty(expenses[index].qty)
            setNote(expenses[index].expenseNotes)
        }
    }, [])

    return (
        <Box component="aside" style={{ marginLeft: 79 }}>
            <AsideDrawer
                toolbarTitle={'Add New Expense'}
                open={true}
                close={props.toggle}
                clsName="expense-drawer"
                name={"wow"}
            >
                <Grid item className="expenseNotes" xs={12} >

                    <Typography component="span" id="expenseName">
                        Name of Expense
                    </Typography>
                    <InputField
                        label={"Expense Name"}
                        defaultValue={name ? name : ""}
                        aria-labelledby="name"
                        required={true}
                        className={errorName === true && "errorInput"}
                        onChange={(e) => drawerInput(e.target.value)}
                    />
                </Grid>

                <Grid item className="expenseNotes" xs={12} >
                    <Typography component="span" id="expense-cost">
                        Expense Cost
                    </Typography>
                    <InputField
                        className={`expense_cost ${errorCost === true && "errorInput"}`}
                        type="number"
                        label="Cost"
                        aria-labelledby="expense-cost"
                        adornment="$"
                        adornmentPosition="end"
                        required
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        value={cost || ''}
                        onChange={(e) => costChange(e.target.value)}
                    />
                </Grid>

                <Grid item className="expenseNotes" xs={12} >
                    <Typography
                        component="span"
                    >
                        Description
                    </Typography>
                    <TextField
                        aria-label="minimum height"
                        multiline
                        rows={4}
                        placeholder="Enter notes here"
                        defaultValue={note}
                        onChange={(e) =>
                            handleNoteChange(e.target.value)
                        }
                        style={{
                            fontSize: 14,
                            color: "#000",
                            lineHeight: 1.36,
                            borderRadius: 5,
                            border: "solid 1px",
                            borderColor: "rgba(8, 77, 79, 0.1)",
                            padding: "10px 10px",
                            outline: "none",
                            marginTop: "10px",
                        }}
                    />
                </Grid>

                <Grid item className="expenseNotes" xs={12} >

                    <Typography component="span" id="expense-quantity">
                        Quantity *
                    </Typography>
                    <InputField
                        className={`expense_cost ${errorQty === true && "errorInput"}`}
                        type="number"
                        aria-labelledby="expense-cost"
                        required
                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        value={qty || ''}
                        onChange={(e) => qtyChange(e.target.value)}
                    />
                </Grid>

                <Box mt={2}>
                    <DefaultButton variant="contained"
                        onClick={saveItem}>Save</DefaultButton>
                </Box>
            </AsideDrawer>

        </Box >



    )

}
function MapStateToProps(state) {
    return {
        estimate: state.getEstimate.estimate,
    };
}
function MapDispatchToProps(dispatch) {
    return {
        getEstimateAction: bindActionCreators(
            getEstimateActionCreators,
            dispatch
        ),
    };
}
export default compose(
    withRouter,
    connect(MapStateToProps, MapDispatchToProps)
)(ExpensesDrawer);
