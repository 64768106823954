import React from "react";
import "../../assets/scss/time-based.scss";
import "../../assets/scss/time-based-summary.scss";
import "../../assets/scss/autocomplete.scss";
import Drag_Icon from "../../assets/images/drag_icon.svg";
import SpeedDial from "./SpeedDial";
import ComboBox from '../reusable-components/autoComplete/autoCompleteM'
import AddTimeResources from './AddTimeResources';
import AlertDialog from '../small-components/AlertDialog/AlertDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import $ from 'jquery';
import fire from '../../config/firebase';
import {
  AddCircle,
  MenuOpen,
} from "@material-ui/icons";
import {
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";

class AddTimePhase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourcesAppend: [],
      speed: false,
      showResources: false,
      buttonShow: true,
      overlay: "",
      phaseButton: true,
      phaseKey: "",
      count: 0,
      arrowState: false,
      arrayItem: []
    }
  }

  resourceAdd = (r) => {
    this.setState({
      speed: true,
      type: r,
    });
    setTimeout(() => {
      this.setState({
        count: this.state.count + 1,
        resourcesAppend: [...this.state.resourcesAppend,
        <AddTimeResources
          type={this.state.type}
          key={this.state.resourcesAppend.length}
          showResources={this.state.showResources}
          overlayType={this.overlayTypes}
          finalHours={this.state.finalHours}
          keyite={this.state.resourcesAppend.length}
          delete={this.deleteTask}
        />
        ],
        buttonShow: false,
      });
    }, 100)
  }
  showButton = () => {
    this.setState({
      buttonShow: true
    })
  }
  addPhase = () => {
    this.props.phase();
    this.setState({ phaseButton: false });
  }

  toggleDrawer = (anchor, open, type) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ ...this.state, [anchor]: open, overlayType: type });

  }
  overlayTypes = (anchor, open, type) => {
    this.props.overlayType(anchor, open, type);
  }
  deleteTask = (e) => {
    // if (this.state.resourcesAppend.length == 1) {
    //   return;
    // }
    this.setState({
      dialogOpen: true,
      phaseKey: e,
    })
  }
  deleteHandler = (key) => {
    this.setState({
      resourcesAppend: this.state.resourcesAppend.filter((item, index) => index !== key),
      count: this.state.count - 1,
      dialogOpen: false,
    })
  }
  closeHandler = () => {
    this.setState({ dialogOpen: false });
  }
  arrowHandler = (e, state) => {
    const parent = e.currentTarget.closest(".scope-table");
    $(parent).find(".scope-table-body").slideToggle();
    $(parent).addClass("hi")
    if (state === false) {
      this.setState({ ...this.state, arrowState: true });
    } else {
      this.setState({ ...this.state, arrowState: false });
    }
  }
  componentDidMount = () => {
    const companyId = localStorage.getItem('companyId');
    const listRef = fire.database().ref(companyId).child('phases');
    listRef.once('value').then((snapshot) => {
      const list = snapshot.val();
      listRef.off('value');
      const listArr = [];
      for (const id in list) {
        listArr.push({ id, ...list[id] });
      }
      this.setState({ arrayItem: listArr, loader: false });
    })
  }
  render() {
    return (
      <React.Fragment>
        <div className="scope-table">
          <Grid container className="scope-table-header">
            <Grid item xs={7}>
              <div className="scope-autocomplete">
                <span className="icon-box">
                  <img src={Drag_Icon} alt="drag icon" />
                </span>
                <form>
                  <div className="row">
                    <div className="input-field">
                      <span>Phase</span>
                      <ComboBox dataList={this.state.arrayItem} title={"Phase Name"} />
                      <div className="bottom-text">
                        <h6>{this.state.count === 1 ?
                          this.state.count + " Resource"
                          :
                          this.state.count + " Resources"
                        }
                        </h6>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
            <Grid item xs={5}>
              <Grid container style={{ textAlign: "right" }}>
                <Grid item xs={4}>
                  <Typography variant="h6" component="h6">HOURS</Typography>
                  <Typography variant="h4" component="h4">36</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" component="h6">COST</Typography>
                  <Typography variant="h4" component="h4">
                    $31,500
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" component="h6">PRICE</Typography>
                  <Typography variant="h4" component="h4">
                    $63,000
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {this.state.count > 0 ?
              this.state.arrowState ?
                <Tooltip placement="right" title="Expand" aria-label="add">
                  <ExpandMoreIcon className="expand-more-carrot" onClick={(e) => this.arrowHandler(e, this.state.arrowState)} />
                </Tooltip>
                : <Tooltip placement="right" title="Collapse" aria-label="add">
                  <ExpandLessIcon className="expand-more-carrot" onClick={(e) => this.arrowHandler(e, this.state.arrowState)} />
                </Tooltip>
              : ""
            }
          </Grid>
          <Grid container className="scope-table-body">
            <Grid item xs={6} className="table-content">
              <MenuOpen htmlColor="#084d4f" className="menu-toggle" />
              <Grid container className="table-heading">
                <Grid item xs={6}>
                  <Typography variant="h6" component="h6">
                    Resources
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="h6" component="h6">QTY</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <Typography variant="h6" component="h6">HOURS</Typography>
                </Grid>
              </Grid>
              <Grid container className="table-body">
                {this.state.resourcesAppend}
              </Grid>
              <Grid container className="table-footer">
                <Grid item xs={12} className="speed-dial-btn">
                  <SpeedDial click={this.resourceAdd} buttonItem={this.showButton} />
                </Grid>
              </Grid>
            </Grid>
            {/* <Timeline timelineTypes={this.state.timelineType} /> */}
            <Grid item xs={6} className="duration-content">
              <Grid container className="duration-outer">
                <Grid item xs className="duration-item"></Grid>
                <Grid item xs className="duration-item"></Grid>
                <Grid item xs className="duration-item"></Grid>
                <Grid item xs className="duration-item"></Grid>
                <Grid item xs className="duration-item"></Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.state.phaseButton ?
            <Tooltip title="Add a Phase" interactive placement="top">
              <div className="plus-row">
                <IconButton aria-label="add" className="plus-icon" onClick={this.addPhase}>
                  <AddCircle
                    style={{ fontSize: 44, color: "#e70a89" }}
                  />
                </IconButton>
              </div>
            </Tooltip>
            : ""
          }

        </div>
        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={""} />
      </React.Fragment>
    );
  }
}

export default AddTimePhase;
