/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import uuid from "react-uuid";
import "../../../assets/scss/summary.scss";
import Drawer from "@material-ui/core/Drawer";
import DuplicateTemplate from "../../small-components/DuplicateTemplate/duplicate-template";
import SaveTemplate from "../../small-components/SaveTemplate/SaveTemplate";
import SettingTemplate from "../../small-components/SettingTemplate/SettingTemplate";
import DeleteTemplate from "../../small-components/DeleteTemplates";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DomainIcon from "@material-ui/icons/Domain";
import ImagePlus from "../../../assets/images/plus.svg";
import EditIconOutlined from "@material-ui/icons/EditOutlined";
import DeleteIconOutlined from "@material-ui/icons/DeleteOutlined";
import "../../../assets/scss/autocomplete.scss";
import TextField from "@material-ui/core/TextField/TextField";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HeightIcon from "@material-ui/icons/Height";
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Sticky from "react-sticky-el";
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormat from "react-number-format";
import AlertDialog from "../../small-components/AlertDialog/AlertDialog";
import ChipsArray from "../../small-components/chips";
import fire from "../../../config/firebase";
import { withRouter } from "react-router-dom";
import AutoCompleteOrganizations from "../autoComplete/autoCompleteOrganizations";
import EstimateCalculation from "../../small-components/Phase/estimateCalculationUtil";
import FormatHour from "../../../libs/formatHour";
// import formatMoneyTimeBased from "../../../libs/formatMoneyTimeBased";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import * as getEstimateActionCreators from '../../../store/estimate/getEstimate';
import * as getResourcesActionCreators from '../../../store/resources/getResources';
import * as getOrganizationsActionCreators from '../../../store/organizations/getOrganizations';
import * as getContactsActionCreators from '../../../store/contacts/getContacts';
import formatMoneyStrFriendly from "../../../libs/formatMoneyStrFriendly";
import * as getUsersActionCreators from '../../../store/users/getUsers';
class SharedEstimateFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      right: false,
      overlayType: "",
      dotClick: false,
      dotClick1: false,
      dotClick2: false,
      profitType: "%",
      profit_select: false,
      anchorEl: false,
      durationPopup: false,
      inputField: true,
      featured: false,
      dialogOpen: false,
      phaseKey: 0,
      deleteType: "",
      formatType: "days",
      newTitle: "",
      // users: [],
      userEmail: "",
      favoriteestimate: [],
      resetDiscount: false,
      people: "",
      organization: "",
      name: "",
      // organizations: [],
      // contacts: [],
      // allTags: [],
      // estimates: {},
      // company: {},
      viewType: '%',
      projectName: null,
      rounding: 0,
      drawerCount: {
        setting: 0,
        undefined: 0,
      }
    };
    this.wrapperRef = React.createRef();
    this.wrapperRef1 = React.createRef();
    this.wrapperRef2 = React.createRef();

    this.refreshData = this.refreshData.bind(this);
    this.updateEstimate = this.updateEstimate.bind(this);
    this.shareEstimate = this.shareEstimate.bind(this);
    this.getQuery = this.getQuery.bind(this);
  }
  componentDidMount = () => {
    this.refreshData();
  };
  componentWillReceiveProps(newProps) {
    if (newProps.update !== this.props.update) {
      this.refreshData();
    }
  }
  shareEstimate() {
    const { estimate } = this.props;
    if (estimate) {
      const shareKey = uuid().split('-')[0];
      const query = this.getQuery();
      const estimateRef = fire.database().ref('public').child(`estimates/${shareKey}`);
      const sharedData = {
        tags: [],
        companyId: query.companyId
      }
      const newEstimateData = Object.assign({}, sharedData, estimate);
      estimateRef.update(newEstimateData);
    }
  }
  getQuery() {
    const arr = this.props.location.pathname.split('/');
    const estimateKey = arr[arr.length - 1];
    // const companyId = localStorage.getItem('companyId') || `public/${estimateKey}`;
    const companyId = `public/${estimateKey}`;
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";

    return { dataType, estimateKey, companyId }
  }
  async refreshData() {

    const { getEstimateAsync } = this.props.getEstimateAction;
    // const { getTagsAsync } = this.props.getTagsAction;
    // const { getCompanyAsync } = this.props.getCompanyAction;
    // const { getUsersAsync } = this.props.getUsersAction;
    const { getOrganizationsAsync } = this.props.getOrganizationsAction;
    const { getContactsAsync } = this.props.getContactsAction;
    const query = this.getQuery();

    getEstimateAsync(query);
    // await getTagsAsync({});
    // await getCompanyAsync({});
    // await getUsersAsync({});
    getOrganizationsAsync(query);
    getContactsAsync(query);

    // this.props.updateFn?.();
    // const companyId = localStorage.getItem('companyId');

    // const refCompany = fire.database().ref(companyId).child("company");
    // const companySnapshot = await refCompany.once("value");

    // const company = companySnapshot.val();
    // refCompany.off("value");
    // const calculations = (company.arrayItem || []).map((autocalculation) => {
    //   autocalculation.figure = parseInt(autocalculation.figure);
    //   return autocalculation;
    // });

    //get reources from firebase
    // const refEstimate = fire.database().ref(companyId).child("resources");
    // refEstimate.off("value");

    // const userListRef = fire.database().ref(companyId).child("users");
    // const userSnap = await userListRef.once("value");
    // const users = userSnap.val();
    // userListRef.off("value");
    // const userlist = [];
    // for (const id in users) {
    //   userlist.push({ id, database: "users", ...users[id] });
    // }

    // const orgsRef = fire.database().ref(companyId).child("organizations");
    // const orgSnap = await orgsRef.once("value");
    // const orgs = orgSnap.val();
    // orgsRef.off("value");

    // const orgsList = [];
    // for (const id in orgs) {
    //   orgsList.push({ id, database: "organizations", ...orgs[id] });
    // }
    // const peopleRef = fire.database().ref(companyId).child("people");
    // const peopleSnap = await peopleRef.once("value");
    // const contacts = peopleSnap.val();
    // peopleRef.off("value");
    // const contactList = [];
    // for (const id in contacts) {
    //   if (!isTrue(contacts[id].archive)) {
    //     contactList.push({ id, database: "people", ...contacts[id] });
    //   }
    // }

    // const estimateCalculator = new EstimateCalculation(this.props.estimate, calculations);
    // const totalEstimatePrice = estimateCalculator.totalEstimatePrice();
    // const afterDiscountPrice =
    //   totalEstimatePrice - estimateCalculator.getDiscountValue();

    // estimates.total = afterDiscountPrice
    // this.updateEstimate(estimates, false)

    // this.setState({
    //   contacts: contactList,
    //   users: userlist,
    //   tags: estimates.tags || [],
    //   people: estimates.people,
    //   organization: estimates.organization,
    //   estimates: estimates,
    //   allTags,
    //   organizations: orgsList,
    //   company,
    //   calculations,
    //   rounding: estimates.rounding,
    // });
    //Current User
    fire.auth().onAuthStateChanged((user) => {
      if (user && !this.state.userEmail) {
        this.setState({
          userEmail: user.email,
        });
      }
    });
  }

  handleBillableChange = (data) => {
    this.updateEstimate(data, true);
  };

  discountView = (type) => {
    this.setState({
      viewType: type,
    });
    this.dotOpen();
  };

  async updateEstimate(updateData, refreshData = true) {
    const query = this.getQuery()
    const estimateRef = fire.database().ref(query.companyId).child(`${query.dataType}/${query.estimateKey}`);
    const newEstimateData = Object.assign({}, updateData);
    estimateRef.update(newEstimateData);

    if (refreshData) {
      const { getEstimateAsync } = this.props.getEstimateAction;
      getEstimateAsync(this.getQuery());
    }
  }

  dotOpen = () => {
    this.setState({ anchorEl: true });
    const currentState = this.state.dotClick;
    this.setState({ dotClick: !currentState });
  };
  dotOpen1 = () => {
    const currentState = this.state.dotClick1;
    this.setState({ dotClick1: !currentState });
  };
  toggleDrawer = (type) => (event) => {

    const { drawerCount = {} } = this.state;
    drawerCount[type] = (drawerCount[type] || 0) + 1;
    const anchor = "right"
    const open = !this.state.right

    this.setState({
      ...this.state,
      [anchor]: open,
      overlayType: type,
      dotClick: false,
      drawerCount
    });
  };
  durationMenu = () => {
    const currentState = this.state.durationPopup;
    this.setState({ durationPopup: !currentState });
  };
  deletePopup = (e) => {
    this.setState({
      dialogOpen: true,
      phaseKey: e,
      deleteType: "navlink",
    });
  };
  deleteHandler = () => {
    this.updateEstimate({
      discountType: null,
      discountValue: 0
    })
    this.setState({
      dialogOpen: false
    });
  };
  closeHandler = () => {
    this.setState({
      dialogOpen: false,
    });
  };
  updateBreadcrumb = async (value) => {

    const query = this.getQuery()
    const currentEstimate = fire.database().ref(query.companyId).child(query.dataType + "/" + query.estimateKey);
    currentEstimate.update({
      projectName: value,
    });
    // this.refreshData();
    const { getEstimateAsync } = this.props.getEstimateAction;
    await getEstimateAsync(this.getQuery());
    this.setState({ projectName: value });
  };
  handleFormat = (e) => {
    this.setState({ formatType: e });
  };
  optionAction = (e) => {
    this.setState({ ...this.state, right: true, overlayType: e.toLowerCase() });
  };
  handleFavorite = async () => {
    const query = this.getQuery();
    const dataType = this.props.match.path.includes("estimates")
      ? "favoriteestimate"
      : "favoritetemplates";

    const { users } = this.props;
    const tempUsers = [...users]

    const user = tempUsers.find(d => d["caption"] === this.state.userEmail)
    if (user) {
      let favoriteestimate = [];
      if (user[dataType]) {
        favoriteestimate = [...user[dataType]]
      }
      const found = favoriteestimate.find(d => d.id === query.estimateKey);
      const temp = {};

      const userRef = fire.database().ref(query.companyId).child("users").child(user.id);
      if (found) {
        favoriteestimate = favoriteestimate.filter(d => d.id !== query.estimateKey);
      } else {
        favoriteestimate.push({ id: query.estimateKey });
      }
      temp[dataType] = favoriteestimate;
      userRef.update(temp);
      this.setState({
        favoriteestimate
      });
    }
    const { getUsersAsync } = this.props.getUsersAction;
    await getUsersAsync({});
  };

  handleCloseEditScreen = () => {
    if (this.props.closeEditScreen) {
      this.props.closeEditScreen("close");
    }
  };

  render() {

    const { companyId } = this.getQuery();
    const { estimate, tags = [], company = {}, users, organizations, contacts } = this.props;
    const calculations = estimate.calculations


    if (!estimate || !company || !calculations || !users || !organizations || !contacts) {
      return null;
    }
    const { hideResource, hideFinance } = estimate
    const { people: contact, discountType = null, organization, rounding } = estimate;

    const targetMargin = parseInt(
      estimate.targetMargin || company.targetMargin || 0
    );
    const estimateCalculator = new EstimateCalculation(estimate, calculations);
    // const {  } = estimate;
    // const estimateCalculator = new EstimateCalculation(estimate, calculations);
    // const totalEstimatePrice = estimateCalculator.totalEstimatePrice();
    // const afterDiscountPrice =
    //   totalEstimatePrice - estimateCalculator.getDiscountValue();

    //estimates.total = afterDiscountPrice
    //this.updateEstimate(estimates, false)

    /*this.setState({
      contacts: contactList,
      users: userlist,
      tags: estimates.tags || [],
      people: estimates.people,
      organization: estimates.organization,
      estimates: estimates,
      allTags,
      organizations: orgsList,
      company,
      calculations,
      rounding: estimates.rounding,
    });*/
    const arr = this.props.location.pathname.split('/');
    const key = arr[arr.length - 1];
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    const dataTypeFavorite = this.props.match.path.includes("estimates")
      ? "favoriteestimate"
      : "favoritetemplates";
    const isTimebased = this.props.match.path.includes("timebased");

    const isTemplate = dataType === "templates";

    // const contact = this.state.people;
    // const rounding = this.state.rounding
    let favorite = false;
    const user = users.find(d => d.caption === this.state.userEmail);
    if (user) {
      if (user[dataTypeFavorite]) {
        const favoriteestimate = user[dataTypeFavorite]
        favorite = favoriteestimate.find((d) => d && d.id === key) !== undefined;
      }
    }

    let { projectName = '' } = estimate;
    if (this.state.projectName) {
      projectName = this.state.projectName
    }

    let startDate = null;
    let endDate = null;
    const getDateFromStr = (str) => {
      const dateParts = str.split(" ")[0].split("-");
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    };
    if (isTimebased) {
      if (estimate && estimate.phases) {
        for (const phase of estimate.phases) {
          if (phase.tasks) {
            for (const task of phase.tasks) {
              if (task.resources) {
                for (const resource of task.resources) {
                  if (resource.start_date) {
                    const curStartDate = getDateFromStr(resource.start_date);
                    if (
                      startDate === null ||
                      startDate.getTime() > curStartDate.getTime()
                    ) {
                      startDate = curStartDate;
                    }
                  }
                  if (resource.end_date) {
                    const currentEndDate = getDateFromStr(resource.end_date);
                    const correctDay = new Date(currentEndDate.getTime());
                    correctDay.setDate(currentEndDate.getDate() - 1);
                    if (endDate === null || endDate.getTime() < correctDay.getTime()
                    ) {
                      endDate = correctDay;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    const { viewType = discountType } = this.state;
    const billableSwitch =
      estimate.billableSwitch || company.billableSwitch || false;

    let { discountValue = 0 } = estimate;

    const discountFomatted = (() => {
      let formatValue = "";
      if (discountType === "%" && discountValue) {
        formatValue = Math.round(discountValue);
      }
      if (discountType === "" && discountValue) {
        formatValue = Math.round(discountValue);
      } else {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (specialChars.test(discountValue)) {
          discountValue = discountValue.replace(/[^a-zA-Z0-9]/g, "");
        }
        const roundValue = Math.round(discountValue || 0);
        formatValue = roundValue
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return formatValue;
    })();

    const totalEstimatePrice = estimateCalculator.totalEstimatePrice();
    const totalExpensePrice = estimateCalculator.totalExpensePrice();
    const totalEstimateCost = estimateCalculator.totalEstimateCost();
    const totalEstimateHours = estimateCalculator.totalEstimateHours();

    const totalEstimateProfit = estimateCalculator.totalEstimateProfit();
    const totalEstimateProfitPercentage =
      estimateCalculator.totalEstimateProfitPercentage();
    const afterDiscountPrice = estimateCalculator.getAfterDiscountPrice();
    let nearest = estimateCalculator.getNearest(rounding) != "none" ? estimateCalculator.getNearest(rounding) : 0
    // totalEstimatePrice - estimateCalculator.getDiscountValue();
    let roundedTotal = afterDiscountPrice;
    if (nearest) {
      roundedTotal = Math.round(afterDiscountPrice / nearest) * nearest;
    }
    console.log(typeof (estimate.discountValue))
    let marginColor = "#db263b";

    const vendorUpSell = parseInt(
      estimate.vendorUpSell || company.vendorUpSell || 0
    );

    if (billableSwitch) {
      if (
        totalEstimateProfitPercentage > targetMargin &&
        totalEstimateProfitPercentage > vendorUpSell
      ) {
        marginColor = "#65a838";
      } else {
        marginColor = "#db263b";
      }
    }
    const getDiff = (timeUnit) => {
      if (!startDate || !endDate) {
        return "";
      }
      const Difference_In_Time = endDate.getTime() - startDate.getTime();
      const DifferenceInDays = Difference_In_Time / (1000 * 3600 * 24);
      if (timeUnit === "week") {
        return Math.floor(DifferenceInDays / 7) + " Weeks";
      }
      return (DifferenceInDays / 30).toFixed(1) + " Months";
    };
    const getMMDD = (d) => {
      if (!d) {
        return "";
      }
      return (
        d.toLocaleString("default", { month: "short" }) + " " + d.getDate()
      );
    };
    const showDiscount = () => {
      const { viewType = discountType } = this.state;

      if (discountType === "%") {
        if (viewType === "%") {
          return discountFomatted + discountType;
        }
        const disNum = (estimateCalculator.getDiscountValue()).toLocaleString()
        return `$${disNum}`;
      }

      if (discountType === "$") {
        if (viewType === "$") {
          return discountType + discountFomatted;
        }
        if (!totalEstimatePrice || totalEstimatePrice <= 0) {
          return `0%`;
        }
        return (
          (
            (100 * estimateCalculator.getDiscountValue()) /
            totalEstimatePrice
          ).toFixed(1) + "%"
        );
      }

      if (discountType === "") {
        if (viewType === "0") {
          return discountType + discountFomatted;
        }
        if (!totalEstimatePrice || totalEstimatePrice <= 0) {
          return `0`;
        }
        const disNum = estimateCalculator.getDiscountValue()
        return `${disNum}`;

      }

    };
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });

    const billableRate = parseFloat(estimate.definedhourlyRate) || totalEstimateHours ? totalEstimatePrice / totalEstimateHours : 0;
    console.log(estimate.discountValue)

    return (
      <div className="summary" key={key}>
        <div className="container" style={{ maxWidth: "1330px", padding: "0" }}>
          <div className="row top-content">
            <div className="col l2 top-content ">
              <span className="title">
                <Typography variant="h6" component="h6">
                  {this.props.title + (isTemplate ? " Template" : " Estimate")}
                </Typography>
              </span>
              <span className="violator-ribbon">
                <Typography variant="h6" component="h6">
                  {this.props.title + " Template"}
                </Typography>
              </span>

            </div>
          </div>
          <div className="row row-wrap-counting">
            <div className="col l12 s12 m12 ">
              <div
                className={`main-title-wrap`}
              >
                <TextField
                  id="project_title"
                  variant="outlined"
                  //maxLength={10}
                  type="text"
                  // label="Estimate Name"
                  label={
                    dataType === "templates"
                      ? "Template Name"
                      : "Estimate Name"
                  }
                  value={projectName}
                  onChange={(e) => this.updateBreadcrumb(e.target.value)}
                />
              </div>

              <div className="row tag-row">
                <div className="col l8">
                  <div className="company-name">
                    <div className="inner-title-wrap">
                      <AutoCompleteOrganizations
                        key={organization + key + "organization"}
                        dataKey={key}
                        title={"organization"}
                        table="organizations"
                        contactType="Organization"
                        tableKey="organization"
                        update={(orgData) => {
                          this.updateEstimate(orgData);
                        }}
                        selectValue={organization}
                        options={organizations}
                      />
                      <DomainIcon className="company_icon" />
                    </div>
                  </div>
                  <div className="client-name">
                    <div className="inner-title-wrap">
                      <AutoCompleteOrganizations
                        key={organization + key + "people"}
                        dataKey={contact}
                        title={"Contact"}
                        table="people"
                        contactType="People"
                        tableKey="people"
                        update={(contactData) => {
                          this.updateEstimate(contactData);
                        }}
                        selectValue={contact}
                        options={contacts}
                      />
                      <PermContactCalendarOutlinedIcon className="client_icon" />
                    </div>
                  </div>
                  <div className="tag-name" ref={this.wrapperRef2}>
                    <ChipsArray
                      dataType={dataType}
                      tagsList={this.estimates?.tags || []}
                      refreshData={() => {
                        const { getTagsAsync } = this.props.getTagsAction;
                        getTagsAsync({});
                      }}
                      allTags={this.props.tags || []}
                      companyId={companyId}
                      itemId={key}
                      shared={true}
                      key={key}
                    />
                  </div>
                </div>
                <div className="col l4 estimate-chips">
                  {estimate.discountValue > 0 &&
                    <div className={totalExpensePrice > 0 ? "estimate-drops" : "estimate-drops full-width"} >
                      <div
                        className={
                          this.state.dotClick
                            ? "active discount-item"
                            : "discount-item"
                        }
                      >
                        <span
                          style={{
                            width: "100%",
                            padding: "16px 20px 14px 20px",
                            borderRadius: "5px",
                            backgroundColor: "rgba(8, 77, 79, 0.05)",
                            color: "#084d4f",
                          }}
                        >
                          DISCOUNT{" "}
                          {discountType !== null ? (
                            <React.Fragment>
                              <span className="discount-added">
                                {showDiscount()}
                              </span>

                              <MoreVertIcon
                                className="profit-dots"
                                onClick={this.dotOpen}
                                ref={this.wrapperRef1}
                              />
                              {this.state.dotClick && (
                                <ClickAwayListener
                                  onClickAway={() => {

                                    this.setState({ dotClick: false })
                                  }
                                  }
                                >
                                  <ul>
                                    {viewType === "%" ? (
                                      <li onClick={() => this.discountView("$")}>
                                        <AttachMoneyIcon />
                                        View as USD
                                      </li>
                                    ) : (
                                      <li
                                        onClick={() => {
                                          this.discountView("%");
                                        }}
                                      >
                                        <span>%</span>
                                        View as Percentage
                                      </li>
                                    )}
                                  </ul>
                                </ClickAwayListener>
                              )}
                            </React.Fragment>
                          ) : (
                            <img
                              style={{
                                width: "20px",
                                height: "20px",
                                right: "22px",
                                top: "14px",
                                position: "absolute",
                                cursor: "pointer",
                              }}
                              src={ImagePlus}
                              alt="plus"
                              onClick={this.toggleDrawer("discount")}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  }

                  {totalExpensePrice > 0 && <div className="estimate-drops">
                    <div
                      className={
                        this.state.dotClick
                          ? "active discount-item"
                          : "discount-item"
                      }
                    >
                      <Tooltip title={totalExpensePrice === 0 || totalExpensePrice < 99999 ? "" : "$" + formatterExpense.format(totalExpensePrice)} placement="top-end">
                        <span
                          style={{
                            width: "100%",
                            padding: "16px 20px 14px 20px",
                            borderRadius: "5px",
                            backgroundColor: "rgba(8, 77, 79, 0.05)",
                            color: "#084d4f",
                          }}
                        >
                          EXPENSES {" "}
                          <span className="discount-added">
                            {formatMoneyStrFriendly({ number: totalExpensePrice, numberFormat: true, beFriendly: true, maximumFractionDigits: 0 })}
                          </span>
                        </span>
                      </Tooltip>
                    </div></div>
                  }

                </div>
              </div>
              <Sticky
                topOffset={-77}
                stickyClassName={"calcSticky"}
                boundaryElement="header"
                relative="true"
                top='94px'
              >
                <div className="row">
                  <div className="col l8 countings">
                    {isTimebased && (

                      <div className="date-wrap">
                        <Typography variant="h6" component="h6">
                          START & END
                        </Typography>
                        <span>
                          {this.state.formatType === "days"
                            ? getMMDD(startDate) + " - " + getMMDD(endDate)
                            : this.state.formatType === "weeks"
                              ? getDiff("week")
                              : getDiff("month")}
                        </span>

                        <span className="open-dots">
                          <MoreVertIcon
                            htmlColor="#e70a89"
                            onClick={this.durationMenu}
                          />{" "}
                        </span>
                        {this.state.durationPopup && (

                          <ClickAwayListener onClickAway={this.durationMenu}>
                            <div className="menu-item">
                              <div className="upper-grid active">
                                <span className="icon-outer">
                                  <DateRangeIcon htmlColor="#e70a89" />
                                </span>
                                <span className="text-box"> Start & End </span>
                              </div>
                              <div className="lower-grid">
                                <span className="icon-outer">
                                  <HeightIcon />
                                </span>
                                <span className="text-box"> Duration </span>
                                <List component="nav" className="nav-outer">
                                  <ListItem
                                    className={`${this.state.formatType === "days"
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <ListItemText
                                      primary="Days"
                                      onClick={() => this.handleFormat("days")}
                                    />
                                  </ListItem>
                                  <ListItem
                                    className={`${this.state.formatType === "weeks"
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <ListItemText
                                      primary="Weeks"
                                      onClick={() => this.handleFormat("weeks")}
                                    />
                                  </ListItem>
                                  <ListItem
                                    className={`${this.state.formatType === "months"
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <ListItemText
                                      primary="Months"
                                      onClick={() => this.handleFormat("months")}
                                    />
                                  </ListItem>
                                </List>
                              </div>
                            </div>
                          </ClickAwayListener>
                        )}
                      </div>

                    )}
                    <div className="hour-wrap">
                      <Typography variant="h6" component="h6">
                        Hours
                      </Typography>
                      <span>{FormatHour({ number: totalEstimateHours })}</span>
                    </div>
                    <div className="bill-wrap">
                      <Typography variant="h6" component="h6">
                        BILLING RATE
                      </Typography>
                      <span>
                        {!billableSwitch
                          ? "N/A"
                          : formatMoneyStrFriendly({
                            number: isNaN(billableRate) ? 0 : billableRate,
                            perTitle: "/hr",
                            money: true,
                            maximumFractionDigits: 0,
                          })}
                        {/* FormatMoney(estimates.definedhourlyRate ? estimates.definedhourlyRate : (totalEstimatePrice / totalEstimateHours), "/hr")} */}
                      </span>
                    </div>

                  </div>
                  <Tooltip
                    title={rounding ? "Rounded from: " + formatter.format(afterDiscountPrice) : ""}
                    placement="top-end"
                  >

                    <div
                      className={`col l4 total ${this.state.defaultTotal !== totalEstimatePrice
                        ? "discountAdded"
                        : ""
                        }`}
                    >
                      <div className="estimate-window" >
                        <h6>TOTAL</h6>
                        <h2>
                          <NumberFormat
                            id="discount"
                            displayType={"text"}
                            isNumericString={true}
                            thousandSeparator={true}
                            value={roundedTotal.toFixed(0).slice(0, 10)}
                            prefix={"$"}
                          />
                        </h2>
                        {afterDiscountPrice.toFixed(0) ===
                          totalEstimatePrice.toFixed(0) ? null : (
                          <h5>
                            <strike>
                              <NumberFormat
                                id="no_discount"
                                displayType={"text"}
                                isNumericString={true}
                                thousandSeparator={true}
                                value={
                                  this.state.defaultTotal !== totalEstimatePrice &&
                                  totalEstimatePrice.toFixed(0).slice(0, 10)
                                }
                                prefix={"$"}
                              />
                            </strike>
                          </h5>
                        )}
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </Sticky>
            </div>
          </div>
          <Drawer
            anchor={"right"}
            open={this.state.right}
            onClose={this.toggleDrawer()}
          >

            {this.state.overlayType === "duplicate" ? (
              <DuplicateTemplate
                originalName={estimate?.name || null}
                projectName={this.props.match.params.projectName}
                onClose={this.toggleDrawer('')}
              />
            ) : this.state.overlayType === "save" ? (
              <SaveTemplate
                dataKey={key}
                projectName={this.props.match.params.projectName}
                closeEditScreen={this.handleCloseEditScreen}
                dataType={dataType}
                onClose={this.toggleDrawer('')}
              />
            ) : null}
            {this.state.overlayType === "setting" ? (
              <SettingTemplate
                key={key}
                refreshData={this.refreshData}
                onClose={this.toggleDrawer()}
                updateEstimate={this.updateEstimate}
                currentProfit={totalEstimateProfitPercentage}
                estimate={estimate}
                company={company}
                drawerCount={this.state.drawerCount['setting'] || 0}
              />
            ) : null}
            {this.state.overlayType === "delete" ? (
              <DeleteTemplate
                name={this.props.match.params.projectName}
                onClose={this.toggleDrawer()}
                dataKey={key}
              />
            ) : null}
          </Drawer>
        </div>
        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          deleteType={this.state.deleteType}
          name={"discount"} />
      </div>
    );
  }
}

// export default withRouter(EstimateFor);

function MapStateToProps(state) {
  return {
    estimate: state.getEstimate.estimate,
    tags: state.getTags.tags,
    resources: state.getResources.resources,
    company: state.getCompany.company,
    users: state.getUsers.users,
    calculations: state.getCompany.calculations,
    organizations: state.getOrganizations.organizations,
    contacts: state.getContacts.contacts
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimateAction: bindActionCreators(
      getEstimateActionCreators,
      dispatch
    ),

    getResourcesAction: bindActionCreators(
      getResourcesActionCreators,
      dispatch
    ),

    getOrganizationsAction: bindActionCreators(
      getOrganizationsActionCreators,
      dispatch
    ),
    getContactsAction: bindActionCreators(
      getContactsActionCreators,
      dispatch
    ),
    getUsersAction: bindActionCreators(
      getUsersActionCreators,
      dispatch
    ),

  };
}

export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(SharedEstimateFor);