import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

const DefaultStyledButton = withStyles({
  root: {
		color: '#ffffff',
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 20px',
    lineHeight: 1.4,
		letterSpacing: '0.14px',
    backgroundColor: '#e70a89 !important',
		textTransform: 'capitalize',
    '&:disabled': {
      backgroundColor: 'rgba(8, 77, 79, 0.05) !important',
      color: '#084d4f',
    },
    '&:hover': {
      backgroundColor: '#c40c76 !important'
    }
  },
})(Button);

const OutlineStyledButton = withStyles({
  root: {
		color: '#000000',
    fontSize: 14,
		fontWeight: 500,
    padding: '9px 20px',
		border: 'solid 1px #000000',
    lineHeight: 1.4,
		letterSpacing: '0.14px',
    backgroundColor: 'transparent !important',
		textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#000000 !important',
			color: '#ffffff'
    }
  },
})(Button);

function DefaultButton(props) {
  return <DefaultStyledButton {...props} disableElevation>{props.children}</DefaultStyledButton>;
}

function OutlineButton(props) {
	return <OutlineStyledButton {...props} disableElevation>{props.children}</OutlineStyledButton>;
}

export { DefaultButton, OutlineButton };
