import React from "react";
import Box from '@material-ui/core/Box';
import "../../assets/scss/pagecontent.scss";
function Legal() {

    return (
        <React.Fragment>
            <Box component="section" className="container">
                <Box component="div" className="row page-content">
                    <div className="col s12 l12">
                        <h1>LEGAL DISCLAIMERS</h1>
                        <p>Last updated September 08, 2023</p>
                        <h2>WEBSITE DISCLAIMER</h2>
                        <p>The information provided by Estimatic, Inc. ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>") on <a href="https://estimatic.com/">https://estimatic.com</a> (the "<strong>Site</strong>") and our application is for general informational purposes only. All information on the Site and our application is provided in good faith. However, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR APPLICATION. YOUR USE OF THE SITE AND OUR APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR APPLICATION IS SOLELY AT YOUR OWN RISK.</p>
                        <h2>EXTERNAL LINKS DISCLAIMER</h2>
                        <p>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
                        <h2>TESTIMONIALS DISCLAIMER</h2>
                        <p>The Site may contain user testimonials of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those users and may not represent all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY.</p>
                        <p>The testimonials on the Site are submitted in various forms, such as text, audio, and/or video, and are reviewed by us before posting. They appear on the Site verbatim as given by the users, except for correcting grammar or typing errors. Some testimonials may have been shortened for brevity, where the full testimonial contained extraneous information irrelevant to the general public.</p>
                        <p>The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or compensated for them.</p>
                    </div>
                </Box>
            </Box>
        </React.Fragment >
    )

}

export default Legal;