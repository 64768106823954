import React from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import '../../../assets/scss/summary.scss';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import fire from "../../../config/firebase";
import { withRouter } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";

const options = {
  entityStyleFn: (entity) => {
    if (entity.get('type').toLowerCase() === 'link') {
      const data = entity.getData();
      return {
        element: 'a',
        attributes: {
          href: data.url,
          target: data.targetOption
        }
      };
    }
  }
};

class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReadOnly: true,
      editorState: EditorState.createEmpty(),
      editorContentHtml: null,
      owner: "Anonymous",
      lastEditor: "Anonymous",
      created: "Anonymous",
      modified: "Anonymous"
    };
  }

  componentDidMount = async () => {
    const { key } = this.props.match.params;
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    const companyId = localStorage.getItem('companyId'); // ref(companyId).child
    if (!companyId) {
      return;
    }
    const estimateRef = fire.database().ref(companyId).child(dataType + "/" + key)
    const userRef = fire.database().ref(companyId).child("users")
    userRef.once("value").then(
      (snapshot) => {
        const users = snapshot.val();
        userRef.off("value");

        estimateRef.once("value").then(
          (snapshot) => {
            const estimates = snapshot.val();
            estimateRef.off("value");
            if (estimates) {
              if (estimates.notes) {
                const editorState = EditorState.createWithContent(
                  convertFromRaw(JSON.parse(estimates.notes.content))
                );
                const contentState = editorState.getCurrentContent();
                this.setState({
                  editorState,
                  editorContentHtml: stateToHTML(contentState, options)
                });
              } else {
                const editorState = EditorState.createEmpty();
                const contentState = editorState.getCurrentContent();
                this.setState({
                  editorState,
                  editorContentHtml: stateToHTML(contentState, options)
                });
              }
              const lastEditor = `${users[estimates.lastEditor]?.firstname || ''} ${users[estimates.lastEditor]?.lastname || ''}`;
              const owner = `${users[estimates.creator]?.firstname || ''} ${users[estimates.creator]?.lastname || ''}`;

              this.setState({
                modified: estimates.modified,
                created: estimates.date,
                owner,
                lastEditor
              });
            }
          },
          (error) => {
            console.log("Error: " + error.code);
          }
        );

      })

  };

  onEditorStateChange = (editorState) => {
    const contentState = editorState.getCurrentContent();
    this.saveContent(contentState);
    this.setState({
      editorState,
      editorContentHtml: stateToHTML(contentState, options)
    });
  };

  getQuery = () => {

    const companyId = localStorage.getItem('companyId');
    const arr = this.props.location.pathname.split('/');
    if (arr.length < 4) {
      return null;
    }
    const estimateKey = arr[arr.length - 1];
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    const dataTypeFavorite = this.props.match.path.includes("estimates")
      ? "favoriteestimate"
      : "favoritetemplates";
    const isTimebased = this.props.match.path.includes("timebased");
    const isTemplate = dataType === "templates";

    return { dataType, estimateKey, companyId, dataTypeFavorite, isTimebased, isTemplate }
  }

  saveContent = (content) => {
    const query = this.getQuery();
    const notesRef = fire.database().ref(query.companyId).child(query.dataType + "/" + query.estimateKey + "/notes");
    const notes = {
      content: JSON.stringify(convertToRaw(content)),
    };
    notesRef.update(notes);
    window.localStorage.setItem("content", notes.content);
  };

  render() {
    const { editorState, isReadOnly = false, editorContentHtml = null, lastEditor, created, owner, modified } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div id="notes" className="table col s9 l10">
            {isReadOnly ?
              <Grid>
                <Paper style={{ padding: 30, backgroundColor: '#FEFBEF', minHeight: 480 }} >
                  <div className="viewModeStyle"
                    dangerouslySetInnerHTML={{ __html: editorContentHtml }}
                  /></Paper></Grid> :
              <div className="input-field" >
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  readOnly={isReadOnly}
                  onEditorStateChange={this.onEditorStateChange}
                  placeholder="Types your notes here..."
                  toolbar={{
                    options: ["inline", "link", "list"],
                    inline: {
                      options: ["bold", "italic", "underline"],
                    },
                    link: {
                      options: ["link"],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                  }}
                />
              </div>}
            <Button
              className="note-button"
              onClick={() => this.setState({ isReadOnly: !isReadOnly })}
            >
              {isReadOnly ? 'Edit' : 'View'}
            </Button>
          </div >
          <div id="note-details" className="col s3 l2">
            <div className="small-col">
              <div className="inner-title" >
                <span className="text-outer">Estimate Details</span>
              </div>
              <div className="outer-title" >
                <div className="outer-items" >
                  <p>Owner</p>
                  <p>{owner}</p>
                </div>
                <div className="outer-items" >
                  <p>Created</p>
                  <p>{created}</p>
                </div>
                <div className="outer-items" >
                  <p>Last Editor</p>
                  <p>{lastEditor}</p>
                </div>
                <div className="outer-items" >
                  <p>Modified</p>
                  <p>{modified}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Notes);
