import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isTrue } from "../../libs/utils";
const initialState = {
    contacts: null,
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

export const getContactsAsync = createAsyncThunk(
    'contacts/getContacts',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const peopleRef = fire.database().ref(companyId).child("people");
        const peopleSnap = await peopleRef.once("value");
        const contacts = peopleSnap.val() || {};
        peopleRef.off("value");
        const contactList = [];
        for (const id in contacts) {
            if (!isTrue(contacts[id].archive)) {
                contactList.push({ id, database: "people", ...contacts[id] });
            }
        }
        return { contacts: contactList };
    }
);

// Redux Toolkit slice
export const getContactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getContactsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getContactsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.contacts = action.payload.contacts

            });
    },
});

export default getContactsSlice.reducer;
