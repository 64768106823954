import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Box from '@material-ui/core/Box';
export default function PaginationList(props) {
  return (
    <div className="pagination">
      <Box
        className="pagination-style"
        display="flex"
        justifyContent="center"
        mt={2.2}
      >
        <Pagination
          count={props.count}
          page={props.page}
          onChange={props.handlePageChange} />
      </Box>
    </div>
  )
}