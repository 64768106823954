import React from 'react';
import Contacts from '../components/pages/Contacts';
// import AllPages from "./pages/AllPages";
import Billing from "./pages/Billing";
import CompanySettings from "./pages/CompanySettings";
// import Dashboard from './pages/Dashboard';

import ManageDisciplines from './pages/ManageDisciplines';
import ManagePhases from './pages/ManagePhases';
import ManageResources from './pages/ManageResources';
import ManageTags from "./pages/ManageTags";
import ManageTasks from "./pages/ManageTasks";
import NewUser from './pages/NewUser';
// import PageNotFound from './pages/PageNotFound';
import Profile from "./pages/Profile";
import Privacy from './pages/Privacy';
import Legal from './pages/Legal';
import UpgradePlan from './pages/UpgradePlan';
import UserManagement from "./pages/UserManagement";
import GanttContainerApp from "./timeline/ganttContainer/GanttContainerApp";
import Header from './navigation/Header';
const routeslogFn = ({ updateCnt = 0, updateFn = () => { }, changeRecord = () => { }, setChangeRecord = () => { } }) => {
  const arr = [
    {
      path: '/timeline',
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <GanttContainerApp updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/billing",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <Billing updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
      admin: true
    },
    {
      path: "/upgrade-plan",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <UpgradePlan updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
      admin: true
    },
    {
      path: "/privacy-policy",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <Privacy updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/legal",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <Legal updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/new-user",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <NewUser updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: '/contacts',
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <Contacts updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: '/manage-resources',
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <ManageResources updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },

    {
      path: '/manage-phases',
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <ManagePhases updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/manage-disciplines",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <ManageDisciplines />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/manage-tasks",

      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <ManageTasks setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/manage-tags",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <ManageTags updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/company-settings",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <CompanySettings updateCnt={updateCnt}
          updateFn={updateFn}
          setChangeRecord={setChangeRecord}
          changeRecord={changeRecord}
        />
      </React.Fragment>,
      exact: true,
      admin: true
    },
    {
      path: "/My-profile",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <Profile updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
    },
    {
      path: "/user-management",
      component: <React.Fragment>
        <Header updateCnt={updateCnt} updateFn={updateFn} setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
        <UserManagement
          updateCnt={updateCnt}
          updateFn={updateFn}
          setChangeRecord={setChangeRecord}
          changeRecord={changeRecord} />
      </React.Fragment>,
      exact: true,
      admin: true
    },

    // {
    //   path: '*',
    //   component: PageNotFound,
    //   exact: true,
    // },

  ];
  return arr;

}
export default routeslogFn;