import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import SearchBox from "../small-components/SearchBox/SearchBox";
import MenuDropdown from "../small-components/MenuDropdown/MenuDropdown";
import InfoPanel from "../small-components/InfoPanel/InfoPanel";
import AsideDrawer from "../small-components/AsideDrawer/AsideDrawer";
import AlertDialog from "../small-components/AlertDialog/AlertDialog";
import InputField from "../small-components/UI/InputField";
import ColorPicker from "../small-components/ColorPicker/ColorPicker";
import { DefaultButton, OutlineButton } from "../small-components/UI/Buttons";
import { withStyles } from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Moment from "moment";
import "../../assets/scss/manage.scss";
import { debounce } from "lodash";
import NoContent from "../small-components/nocontent";
import fire from "../../config/firebase";
import loaderimage from "../../assets/images/PageLoader.svg";
import { getNearestColor, colorArray } from "../../libs/color/colorUtils";
import { getCopyName } from "../../libs/getCopyName";
import { isEmptyString } from "../../libs/utils";
const styles = () => ({
  menuDropdownInput: {
    padding: "5.5px 10px",
  },
  menuDropdownIcon: {
    marginRight: "5.5px",
  },
  pagination: {
    width: "auto",
    backgroundColor: "transparent !important",
    boxShadow: "none",
  },
  outerlabel: {
    "& label": {
      fontWeight: 300,
    },
  },
  paginationItem: {
    width: 30,
    height: 30,
    padding: "5px 11px 6px",
    backgroundColor: "transparent !important",
    fontSize: 14,
    //fontWeight: 400,
    fontWeight: 300,
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  paginationItemIcon: {
    width: 24,
    height: 24,
  },
  paginationItemSelected: {
    backgroundColor: "#084d4f !important",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "rgba(8, 77, 79, 0.9) !important",
    },
  },
  paginationItemDisabled: {
    opacity: "0.5 !important",
  },
  label: {
    marginTop: "30px",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#084d4f",
    textTransform: "uppercase",
  },
  buttonWrap: {
    "& > .MuiButton-outlined": {
      marginLeft: 10,
    },
  },
  asideMenuDropdown: {
    marginTop: 10,
    width: "100%",
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: "31px !important",
  },
  asideMenuDropdownIcon: {
    marginRight: "5.5px",
  },
});

function DisciplineIcon({ iconColor, ...props }) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 20.916 18.679"
      >
        <g id="Group_1361" transform="translate(-7142 5735.132)">
          <path
            id="Path_1861"
            d="M1.394 3h18.128a1.612 1.612 0 0 1 1.394 1.756l-.007 12.294a1.607 1.607 0 0 1-1.387 1.756H1.394A1.612 1.612 0 0 1 0 17.05V4.756A1.612 1.612 0 0 1 1.394 3zm0 14.05h18.128V4.756H1.394z"
            className="cls-1"
            fillRule="evenodd"
            transform="translate(7142 -5735.26)"
          />
          <g id="Group_1341" transform="translate(7154.802 -5728.53)">
            <path
              id="Path_1867"
              d="M7 7v1.32h5.509V7z"
              transform="translate(-7 -7)"
            />
            <path
              id="Path_1870"
              d="M7 7v1.32h5.509V7z"
              transform="translate(-7 -4.214)"
            />
          </g>
          <path
            id="Path_1868"
            d="M8.885 4a2.442 2.442 0 1 0 2.442 2.442A2.442 2.442 0 0 0 8.885 4zm1.221 2.442a1.221 1.221 0 1 0-1.221 1.221 1.225 1.225 0 0 0 1.221-1.221zm2.442 5.5c-.122-.434-2.015-1.221-3.663-1.221s-3.541.788-3.663 1.227v.6h7.327zM4 11.938C4 10.313 7.254 9.5 8.885 9.5s4.885.818 4.885 2.442v1.832H4z"
            className="cls-1"
            fillRule="evenodd"
            transform="translate(7141.032 -5733.625)"
          />
          <path
            id="Path_1881"
            fill="none"
            stroke={iconColor ? iconColor : "#000"}
            strokeLinecap="round"
            strokeWidth="2px"
            d="M6018.7-5449.976a2.186 2.186 0 0 1 2.156-2.157 2.185 2.185 0 0 1 2.156 2.157"
            transform="translate(1131.603 -282)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

class ManageDisciplines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "most-recent",
      drawerState: false,
      dialogOpen: false,
      phaseKey: "",
      key: "",
      typeCast: "",
      arrayItem: [],
      page: 1,
      listToShow: [],
      itemKey: "",
      optionAction: "",
      drawerName: "",
      drawerColor: "",
      drawerType: "",
      saveInfo: false,
      drawerDefaultName: "",
      drawerDefaultColor: "",
      uniqueId: 3,
      loader: true,
      randomColor: "#00ff59",
      // colorArray: ['#ff0000', '#faff00', '#00ff15', '#0010ff', '#ff004a'],
      dataLength: 0,
      update: 0,
      drawerNameError: false
    };
    this.items = [...this.state.arrayItem];
    this.paginationCount = 10;
    this.refreshData = this.refreshData.bind(this);
    this.drawerInput = this.drawerInput.bind(this);

  }
  handleSort = (event) => {
    this.setState({ sort: event.target.value });
  };
  handlePages = (e, key) => {
    const val = key.toLowerCase().split(" ").join("-");
    if (val === "time-based")
      this.setState({
        typeCast: true,
      });
    else
      this.setState({
        typeCast: true,
      });
  };
  inputText = debounce((e) => {
    this.setState({
      key: e,
      page: 1,
    });
  }, 700);
  handleChange = (event) => {
    this.setState({
      age: event.target.value,
      page: 1,
    });
  };
  handlePageChange = (event, value) => {
    this.setState({
      page: value,
    });
  };
  createNew = (e, id) => {
    e.preventDefault();
    document.querySelector(".item-option").classList.remove("activeOption");
    document.getElementById(id).style.display = "block";
    document.querySelector(".estimates-settings").style.display = "block";
  };
  overlay = (e) => {
    e.preventDefault();
    document.querySelector(".overlay").style.display = "block";
  };

  toggleDrawer = (open, type) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === true) {
      const randomIndex = Math.random() * 100000;
      const color = colorArray[Math.floor(randomIndex % colorArray.length)];
      this.setState({ randomColor: color });
    }
    this.setState({ drawerState: open, drawerType: type, drawerNameError: false, drawerName: "" });
  };
  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };
  handleDialogClose = () => {
    this.setState({ dialogOpen: false, drawerName: "" });
  };

  optionChange = debounce((value, itemIndex) => {
    if (value === "delete") {
      this.setState({
        dialogOpen: true,
        phaseKey: itemIndex,
        optionAction: value,
      });
    } else if (value === "duplicate") {
      let title = "",
        bordercolor = "";
      for (let i = 0; i < this.state.arrayItem.length; i++) {
        if (this.state.arrayItem[i]["id"] === itemIndex) {
          title = getCopyName(this.state.arrayItem[i]["title"], this.state.arrayItem, 'title');
          bordercolor = this.state.arrayItem[i]["borderColor"];
        }
      }
      const companyId = localStorage.getItem('companyId');
      const listRef = fire.database().ref(companyId).child("disciplines");
      const itemData = {
        title: title,
        borderColor: bordercolor,
        date: Moment(new Date()).format("MM/DD/YYYY"),
      };
      listRef.push(itemData);
    } else {
      for (let i = 0; i < this.state.arrayItem.length; i++) {
        if (this.state.arrayItem[i]["id"] === itemIndex) {
          this.setState({
            phaseKey: itemIndex,
            optionAction: value,
            drawerState: true,
            drawerType: "old",
            drawerDefaultName: this.state.arrayItem[i]["title"],
            drawerName: this.state.arrayItem[i]["title"],
            drawerDefaultColor: this.state.arrayItem[i]["borderColor"],
          });
        }
      }
      setTimeout(() => {
        this.setState({
          optionAction: "",
        });
      }, 1000);
    }
    this.refreshData();
  });
  drawerDelete = () => {
    this.setState({
      dialogOpen: true,
      optionAction: "delete",
    });
  };
  deleteHandler = debounce((key) => {
    const companyId = localStorage.getItem('companyId');
    const userRef = fire.database().ref(companyId).child("disciplines").child(key);
    userRef.remove();
    this.setState({
      dialogOpen: false,
      drawerState: false,
    });
    this.refreshData();
  });
  closeHandler = () => {
    this.setState({ dialogOpen: false });
  };

  drawerInput(value) {
    this.setState({
      drawerName: value.trimStart(),
      drawerNameError: isEmptyString(value)
    });
  }

  colorHandler = debounce((e) => {
    this.setState({ drawerColor: e });
  }, 300);
  saveItem = () => {
    const companyId = localStorage.getItem('companyId');
    const { drawerName, drawerDefaultName, drawerColor, drawerDefaultColor } = this.state;
    if (isEmptyString(drawerName)) {
      this.setState({ drawerNameError: true });
      return;
    }
    if (this.state.drawerType === "new") {
      const listRef = fire.database().ref(companyId).child("disciplines");
      let colorCode = this.state.randomColor;
      if (this.state.drawerColor) {
        colorCode = this.state.drawerColor;
      }
      const itemData = {
        title: drawerName,
        borderColor: getNearestColor(colorCode),
        date: Moment(new Date()).format("MM/DD/YYYY"),
        timestamp: Math.floor(Date.now() / 1000),
      };
      listRef.push(itemData);
    } else if (this.state.drawerType === "old") {
      const title = drawerName || drawerDefaultName || "";
      let bordercolor = drawerColor || drawerDefaultColor || "";
      bordercolor = getNearestColor(bordercolor);
      const listRef = fire
        .database().ref(companyId).child("disciplines")
        .child(this.state.phaseKey);
      listRef.update({
        title: title,
        borderColor: bordercolor,
      });
    }
    setTimeout(() => {
      this.setState({
        saveInfo: false,
        drawerName: "",
        drawerType: "",
        drawerColor: "",
        drawerState: false,
      });
    }, 500);
    this.refreshData();
  };
  refreshData() {
    const companyId = localStorage.getItem('companyId');
    const listRef = fire.database().ref(companyId).child("disciplines");
    listRef.once("value").then((snapshot) => {
      const list = snapshot.val();
      listRef.off("value");
      const listArr = [];
      for (const id in list) {
        listArr.push({ id, ...list[id] });
      }
      // const findOther = listArr.find((d) => d.title === "Other");
      // if (!findOther) {
      //   const other = {
      //     borderColor: "#DB263B",
      //     date: "20/10/2022",
      //     timestamp: 1666287058,
      //     title: "Other",
      //   };
      //   listRef.push(other);
      // }
      this.setState({
        arrayItem: listArr,
        loader: false,
        dataLength: listArr.length,
        update: this.state.update + 1
      });
    });
    const randomIndex = Math.random() * 100000;
    const color = colorArray[Math.floor(randomIndex % colorArray.length)];
    this.setState({ randomColor: color });
  }
  componentDidMount = () => {
    this.refreshData();
  };
  handleCreate = (open, type) => {
    this.setState({ drawerState: open, drawerType: type });
  };

  render() {
    const { classes } = this.props;
    const { drawerName, drawerNameError } = this.state;
    const th = this;
    let listToShow = this.state.arrayItem;

    // Filters
    if (th.state.sort !== "" && th.state.key === "")
      listToShow = this.state.arrayItem.sort(function (a, b) {
        if (th.state.sort === "ascending") {
          return a["title"].localeCompare(b["title"]);
        } else if (th.state.sort === "descending") {
          return b["title"].localeCompare(a["title"]);
        } else if (th.state.sort === "most-recent") {
          return b.timestamp - a.timestamp;
        } else {
          return a["id"].toString().localeCompare(b["id"].toString());
        }
      });
    else if (th.state.sort === "" && th.state.key !== "")
      listToShow = this.state.arrayItem.filter(function (i) {
        return i.title.toLowerCase().match(th.state.key.toLowerCase());
      });
    else if (th.state.sort !== "" && th.state.key !== "")
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          return i.title.toLowerCase().match(th.state.key.toLowerCase());
        });
    else
      listToShow = this.state.arrayItem.filter(function (i) {
        return i;
      });
    // const count = Math.ceil(listToShow.length / this.paginationCount);
    return (
      <React.Fragment>
        {this.state.loader === true ? (
          <div className="loader-container">
            <object data={loaderimage} type="image/svg+xml"></object>
          </div>
        ) : (
          <Box
            className="manage-disciplines"
            style={{
              paddingTop: 77,
              minHeight: "calc(100vh - 60px)",
              backgroundColor: "rgba(8,77,79,0.05)",
            }}
          >
            {this.state.dataLength > 0 ? <Box
              component="section"
              className="filter-menu"
              style={{ padding: "21px 0 20px" }}
            >
              <Box className="container">
                <Grid container>
                  <Grid item xs={10}>
                    <Typography
                      variant="h5"
                      component="h5"
                      style={{ fontWeight: 100, fontSize: "1.65rem" }}
                    >
                      Manage Disciplines
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right" style={{ marginTop: 7 }}>
                      <DefaultButton
                        variant="contained"
                        startIcon={<DisciplineIcon iconColor="#fff" />}
                        onClick={this.toggleDrawer(true, "new")}
                      >
                        Add Discipline
                      </DefaultButton>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="filter-wrap" style={{ marginTop: 25 }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <SearchBox
                        placeholder="Search disciplines"
                        change={this.inputText}
                      ></SearchBox>
                    </Grid>
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="center"
                      item
                      xs={6}
                    >
                      <MenuDropdown
                        className={classes.menuDropdown}
                        selectInputProps={{
                          classes: {
                            root: classes.menuDropdownInput,
                            icon: classes.menuDropdownIcon,
                          },
                        }}
                        size="small"
                        value={this.state.sort}
                        change={this.handleSort}
                      >
                        <MenuItem value="most-recent">Most Recent</MenuItem>
                        <MenuItem value="ascending">Ascending</MenuItem>
                        <MenuItem value="descending">Descending</MenuItem>
                      </MenuDropdown>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

            </Box> : null}
            <Box
              component="section"
              className="panel-list paneList"
              style={{ padding: "0 0 50px", minHeight: "635px" }}
            >
              <Box className="container">
                {listToShow.length > 0 ? (
                  <InfoPanel
                    items={listToShow}
                    page={this.state.page}
                    classes={this.props.classes}
                    update={this.state.update}
                    list={[
                      {
                        type: "Edit",
                        icon: <EditOutlinedIcon htmlColor="#000" />,
                        id: "edit",
                      },
                      {
                        type: "Duplicate",
                        icon: (
                          <FileCopyOutlinedIcon
                            htmlColor="#000"
                            className="mirror_filter"
                          />
                        ),
                        id: "duplicate",
                      },
                      {
                        type: "Delete",
                        icon: <DeleteOutlinedIcon htmlColor="#000" />,
                        id: "delete",
                      },
                    ]}
                    enableDelete={() => true}
                    optionValue={this.optionChange}
                    paginationLimit={this.paginationCount}
                    searchWords={this.state.key}
                    pagename="discipline"
                  />
                ) : (
                  <NoContent title="Disciplines. The skills you need for your projects."
                    description="Disciplines are like departments in a company, such as Design, Copywriting, or Development. Add disciplines here to assign to your resources, and see costs in the estimate summary broken down by the type of work."
                    Button_type="disciplines"
                    createNew={this.handleCreate}
                    searching={this.state.dataLength > 0} />
                )}
                {/* <div className={this.props.classes.pagination}>
                  {count > 1 && (
                    <Box
                      className="pagination-style"
                      display="flex"
                      justifyContent="center"
                      mt={2.2}
                    >
                      <Pagination
                        count={count}
                        page={this.state.page}
                        onChange={this.handlePageChange}
                      />
                    </Box>
                  )}
                </div> */}
              </Box>
            </Box>
            <Box component="aside" style={{ marginLeft: 79 }}>
              <AsideDrawer
                toolbarTitle={`${this.state.drawerType === "new" ? "Add" : "Edit"
                  } Discipline`}
                open={this.state.drawerState}
                close={this.toggleDrawer(false)}
              >
                <Typography className={classes.label} id="name">
                  Name
                </Typography>
                <InputField
                  //className={classes.outerlabel}
                  className={
                    drawerNameError && "errorInput"
                  }
                  label={"Discipline Name"}
                  defaultValue={
                    this.state.drawerType === "old"
                      ? this.state.drawerDefaultName
                      : ""
                  }
                  aria-labelledby="name"
                  onChange={(e) => this.drawerInput(e.target.value)}
                />
                <Typography className={classes.label} id="color-picker">
                  Color
                </Typography>
                <ColorPicker
                  colorCode={this.colorHandler}
                  defaultColor={
                    this.state.drawerType === "old"
                      ? this.state.drawerDefaultColor
                      : this.state.randomColor
                  }
                />
                <Box className={classes.buttonWrap} mt={2}>
                  <DefaultButton variant="contained"
                    onClick={drawerNameError === false ? this.saveItem : () => { }}>
                    Save
                  </DefaultButton>
                  {this.state.drawerType === "old" ? (
                    <OutlineButton
                      variant="outlined"
                      onClick={this.drawerDelete}
                    >
                      Delete
                    </OutlineButton>
                  ) : (
                    ""
                  )}
                </Box>
              </AsideDrawer>
            </Box>
          </Box >
        )
        }
        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={"discipline"}
        />
      </React.Fragment >
    );
  }
}
export default withStyles(styles)(ManageDisciplines);
