import React from "react";
import { HuePicker } from "react-color";
import Box from "@material-ui/core/Box";

function CustomPointer() {
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        backgroundColor: "rgb(248, 248, 248)",
        cursor: "pointer",
        transform: "translate(-10px, -7px)",
        boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.16)',
      }}
    ></div>
  );
}

function ColorPicker(props) {
  const [color, setColor] = React.useState(props.defaultColor);

  const handleChange = (newColor) => {
    setColor(newColor.hex);
  };

  const handleChangeComplete = (newColor) => {
    setColor(newColor.hex);
    props.colorCode(newColor.hex);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          flex="0 0 40px"
          width="40px"
          height="40px"
          my={1}
          mr={1}
          borderRadius="borderRadius"
          bgcolor={color}
          marginRight="12px"
        />
        <HuePicker
          height="6px"
          color={color}
          pointer={CustomPointer}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
        />
      </Box>
    </React.Fragment>
  );
}

export default ColorPicker;
