
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import fire from '../../../config/firebase';
import "../../../assets/scss/autocomplete.scss";
import parse from 'autosuggest-highlight/parse';
import Moment from 'moment';
import matchRegex from 'autosuggest-highlight/match';
import { getRandomColor } from '../../../libs/color/colorUtils';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const filter = createFilterOptions();

function AutoCompleteTask({ selectValue, show, insert = () => { },
  title, dataList = [], update, taskName = '',
  cla, classes,
}) {
  const type = 'tasks';
  const [value, setValue] = useState(selectValue);

  const addNewItem = (name) => {
    if (name !== undefined) {
      update({ taskName: name }); //updates phase
      insert({  // adds to tasks table
        borderColor: getRandomColor(),
        date: Moment(new Date()).format('MM/DD/yyyy'),
        timestamp: new Date().getTime(),
        title: name
      })
    }
  }
  useEffect(() => {
    if (value && value.inputValue) {
      addNewItem(value.inputValue)
    } else {
      if (value && value.title !== undefined) {
        update({ taskName: value.title });
      }
    }
    if (show) {
      show();
    }
  }, [value]);
  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => { setOpen(false); }}>
      <Autocomplete
        autoSelect
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        defaultValue={taskName}
        className={cla + ' ' + classes}
        onChange={(event, newValue) => {
          if (newValue === null) { return }
          setValue(newValue);
        }
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (type === "resources" && params.inputValue !== '') {
            filtered.push({
              title: <span key={filtered.length} className={`add-button ${classes}`}><AddIcon /> Add "{params.inputValue}" as a new {title}</span>,
              inputValue: params.inputValue,
            });
          }
          else if (params.inputValue !== '') {
            filtered.push({
              title: <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}</span>,
              inputValue: params.inputValue,
            });
          }
          return filtered;
        }
        }
        selectOnFocus
        clearOnBlur
        blurOnSelect
        handleHomeEndKeys
        options={(dataList || []).filter(d => d.archive !== 'true')}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(option, { inputValue }) => {
          const matches = matchRegex(option.title, inputValue);
          const parts = parse(option.title, matches);
          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}

        style={{ width: 550 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={title}
            onChange={(e) => {
              update({ taskName: e.target.value });
            }}
          />
        )}
      />
    </ClickAwayListener >
  );
}
export default withRouter(AutoCompleteTask);