import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    phases: [],
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

// asynchronous function with createAsyncThunk
export const getPhasesAsync = createAsyncThunk(
    'phases/getPhases',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const listRef = fire.database().ref(companyId).child("phases");
        const snapshot = await listRef.once("value");
        const list = snapshot.val();
        listRef.off("value");
        const listArr = [];
        for (const id in list) {
            listArr.push({ id, ...list[id] });
        }

        return { phases: listArr };
    }
);

// Redux Toolkit slice
export const getPhasesSlice = createSlice({
    name: 'phases',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPhasesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPhasesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.phases = action.payload.phases
            });
    },
});

export default getPhasesSlice.reducer;
