export function getOrderInt(input) {
    const typeofInput = typeof input;
    if (typeofInput === 'number') {
        return input;
    }
    if (input === 'string') {
        if (input.includes('-')) {
            try {
                const order = parseInt(input.replace('phase-', '').replace('task-'));
                return order;
            } catch (err) {
                return 1;
            }
        }
        return 0;
    }
    return input;
}
export function orderItems({ list, field, source, destination, nameField = 'phaseName' }) {
    if (!source || !destination || !nameField || !field || !list) {
        console.error('orderItems: missing parameters')
        return list;
    }
    for (const item of list) {
        item[field] = getOrderInt(item[field]);
    }
    list.sort((a, b) => {
        if (a[field] !== b[field]) {
            return a[field] - b[field];
        }
        return a[nameField].localeCompare(b[nameField]);
    });

    if (source[field] > destination[field]) { /// moved from bottom to top etc..
        const tempOrder = destination[field];
        for (let i = 0; i < list.length; i++) {
            if (list[i][field] >= destination[field]) {
                list[i][field]++;
            }
        }
        source[field] = tempOrder;
    } else {
        const tempOrder = destination[field];
        for (let i = 0; i < list.length; i++) {
            if (list[i][field] <= destination[field]) {
                list[i][field]--;
            }
        }
        source[field] = tempOrder;
    }
    list.sort((a, b) => a[field] - b[field]);
    for (let i = 0; i < list.length; i++) {
        list[i][field] = i + 1;
    }
    return list;
}
export function sortItems({ list, field, nameField }) {
    if (!nameField || !field || !list) {
        console.error('sortItems: missing parameters')
        return list;
    }
    for (const item of list) {
        item[field] = getOrderInt(item[field]);
    }
    list.sort((a, b) => {
        if (a[field] !== b[field]) {
            return a[field] - b[field];
        }
        return a[nameField].localeCompare(b[nameField]);
    });
    return list;
}

export function ObjectToList(list = {}) {
    const keys = Object.keys(list);
    const result = [];
    for (const key of keys) {
        result.push({ id: key, ...list[key] });
    }
    return result;
}

export const isTrue = (val) => {
    return val === true || `${val}` === 'true';
};
export const isFalse = (val) => {
    return val === false || `${val}` === 'false';
};

export const isEmptyString = (val) => {
    return val === undefined || val === null || val.length === 0 || val.trim().length === 0;
};

export const isDigit = (inp = '') => {
    const valid = ['$', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
    for (let i = 0; i < inp.length; i++) {
        if (valid.includes(inp[i]) === false) {
            return false;
        }
    }
    return true;
}
export const isNumber = (inp = '') => {
    const valid = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    for (let i = 0; i < inp.length; i++) {
        if (valid.includes(inp[i]) === false) {
            return false;
        }
    }
    return true;
}
export const getFloat = (value = 0) => {
    return parseFloat(parseFloat(value).toFixed(2));
}