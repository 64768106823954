import React, { Component } from "react";
import "../../assets/scss/overlay.scss";

class CreateNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createStyle: {
        width: "87%",
        margin: "0 auto",
      },
      overlay_content_wrap: {
        animation: "fadeInRight 0.25s linear",
        animationDuration: "0.25s",
      },
    };
  }

  render() {
    const { dataType = "estimates" } = this.props;
    const friendlyName = dataType === "estimates" ? "estimates" : "template";
    return (
      <React.Fragment>
        <div style={this.state.overlay_content_wrap}>
          <h1>Let's get started!</h1>
          <p className="big-paragraph subtitle">
            Please choose the type of {friendlyName} you'd like to create.
          </p>
          <div className="row ">
            <div className="col s12 l5 m5">
              <a href="#">
                <div
                  className="card"
                  onClick={(e) => {
                    this.props.click("task-based");
                    this.props.overlay(e, "false");
                  }}
                >
                  <h6>Task Based</h6>
                  <p>
                    An {friendlyName} based off of certain tasks that need to be
                    completed.
                  </p>
                </div>
              </a>
            </div>
            <div className="col s12 l5 m5">
              <a href="#">
                <div
                  className="card"
                  onClick={(e) => {
                    this.props.click("time-based");
                    this.props.overlay(e, "true");
                  }}
                >
                  <h6>Time Based</h6>
                  <p>
                    An {friendlyName} based off the amount of work done within a
                    given timeframe.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default CreateNew;
