import React from 'react';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  label: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.42,
    letterSpacing: '0.6px',
    color: '#084d4f',
    //textTransform: 'uppercase',
  },
});

function Legend({className, children, ...props}) {
  const classes = useStyles();

  return <Typography className={clsx(classes.label, className)} {...props}>{children}</Typography>;
}

export default Legend;
