
const state = {
    timeoutId: undefined,
    unsavedChanges: {
        tasks: [],
        links: [],
    },
};

const fixDragForDataProcessor = (gantt) => {
    // FIXME: check solution

    // --- FIX --- : add update of parent dates on drag of child task to dataProcessor
    let parentDates = {} // { parentId: { start_date: Date, end_date: Date }}
    gantt.attachEvent("onBeforeTaskDrag", (id) => {
        gantt.eachParent((parent) => {
            if (parent.$level !== 0) {
                parentDates[parent.id] = {
                    start_date: parent.start_date,
                    end_date: parent.end_date,
                }
            }
        }, id)

        return true
    }, null)

    gantt.attachEvent('onAfterTaskDrag', (id) => {
        gantt.eachParent((parent) => {
            const oldDates = parentDates[parent.id]
            if (
                oldDates &&
                (oldDates.start_date.getTime() !==
                    parent.start_date.getTime() ||
                    oldDates.end_date.getTime() !==
                    parent.end_date.getTime())
            ) {

                gantt.updateTask(parent.id, parent)
            }
        }, id)

        // clear to prevent multiple updated after drag of project itself
        parentDates = {}
    }, null)
    // --- END OF FIX ---
}

const initDataProcessor = (gantt, onTasksChanged, role) => {
    gantt.createDataProcessor(
        (
            entity,
            action,
            data,
            id
        ) => {
            // types: IGanttTaskOperation, IGanttLinkOperation
            if (state.timeoutId) {
                clearTimeout(state.timeoutId)
            }

            state.timeoutId = setTimeout(() => {
                // if you insert new records into the database - be sure to apply db ids on the client
                // using gantt.changeTaskId, gantt.changeLinkId

                const data = {
                    tasks: state.unsavedChanges.tasks.slice(),
                    links: state.unsavedChanges.links.slice(),
                }

                // reset store of changes
                state.unsavedChanges = {
                    tasks: [],
                    links: [],
                }

                if (data.tasks.length || data.links.length) {
                    onTasksChanged(data, role)
                }
            }, 50);

            // delete custom props
            if (data && data['!nativeeditor_status']) {
                delete data['!nativeeditor_status']
            }

            // push changes to store
            const item = {
                entity: entity,
                action: action,
                data: data,
                id: id,
            }

            if (
                entity === 'task'
                && data
                && (data.type === gantt.config.types.project
                    || data.type === gantt.config.types.task)
            ) {
                // doesn't send to dataprocessor 'header', 'button' and other
                // allowed to store only project and task operations
                if (data && data.target) {
                    // after vertical reoder project has target, but i don't know what it mean
                    // so i get index (zero-based) of project among all projects
                    data.index_after_reoder = gantt.getTaskIndex(id)
                }
                state.unsavedChanges.tasks.push(item)
            } else if (entity === 'link') {
                state.unsavedChanges.links.push(item)
            }
        }
    )
}

export const subscribeActions = (
    gantt,
    actions
) => {
    if (actions) {
        const { onError, onTasksChanged, onRoleNameAdded } = actions;

        if (onError) {
            gantt.attachEvent(
                'onError',
                (errorMessage) => {
                    return onError(errorMessage)
                },
                null
            )
        }

        if (onTasksChanged) {
            fixDragForDataProcessor(gantt);
            initDataProcessor(gantt, onTasksChanged)
        }

        if (onRoleNameAdded) {
            // custom event
            gantt.attachEvent(
                'onRoleNameAdded',
                (type, role) => {
                    onRoleNameAdded(type, role);
                    // initDataProcessor(gantt, onTasksChanged, role);
                },
                null
            )
        }
    }
}
