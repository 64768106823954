import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SaveAsTemplate from "../../assets/images/templates.svg";
import SaveAsTemplate_pink from "../../assets/images/templates_pink.svg";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const optionAction = (e, type, id) => {
    localStorage.setItem("dataType", props.dataType);
    localStorage.setItem("projectName", props.name);
    props.optionAction(type, id);
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className="select-menu-wrap">
                <ClickAwayListener onClickAway={(e) => optionAction(e, "", "")}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-summary"
                    onKeyDown={handleListKeyDown}
                  >
                    {props.list.map((item, index) => {
                      {
                        if (props.showArchive && item.type === "Unarchive") {
                          return (
                            <MenuItem
                              onClick={(e) =>
                                optionAction(
                                  e,
                                  "Unarchive",
                                  props.id,
                                  props.dataType
                                )
                              }
                              key={index}
                            >
                              <UnarchiveOutlinedIcon />
                              Unarchive
                            </MenuItem>
                          );

                        } else if (!props.showArchive && item.type === "Save As Template") {
                          return (
                            <MenuItem
                              onClick={(e) =>
                                optionAction(
                                  e,
                                  "Save",
                                  props.id,
                                  props.dataType
                                )
                              }
                              key={index}
                            >
                              <img src={SaveAsTemplate} alt="save" />
                              <img
                                src={SaveAsTemplate_pink}
                                className="template_pink"
                                alt="save pink"
                              />
                              Save As Template
                            </MenuItem>
                          );
                        } else if (!props.showArchive && item.type === "Create Estimate") {
                          return (
                            <MenuItem
                              onClick={(e) =>
                                optionAction(
                                  e,
                                  "Estimate",
                                  props.id,
                                  props.dataType
                                )
                              }
                              key={index}
                            >
                              <img src={SaveAsTemplate} alt="estimates" />
                              <img
                                src={SaveAsTemplate_pink}
                                className="template_pink"
                                alt="save pink"
                              />
                              Create Estimate
                            </MenuItem>
                          );
                        } else if (!props.showArchive && item.type === "Edit") {
                          return (
                            <MenuItem
                              onClick={(e) =>
                                optionAction(
                                  e,
                                  "Edit",
                                  props.id,
                                  props.dataType
                                )
                              }
                              key={index}
                            >
                              <EditOutlinedIcon htmlColor="#000" />
                              Edit
                            </MenuItem>
                          );
                        } else if (!props.showArchive && item.type === "Duplicate") {
                          return (
                            <MenuItem
                              onClick={(e) =>
                                optionAction(
                                  e,
                                  "Duplicate",
                                  props.id,
                                  props.dataType
                                )
                              }
                              key={index}
                            >
                              <FilterNoneIcon
                                htmlColor="#000"
                                className="mirror_filter"
                              />
                              Duplicate
                            </MenuItem>
                          );
                        } else if (!props.showArchive && item.type === "Delete") {
                          return (
                            <MenuItem
                              onClick={(e) =>
                                optionAction(
                                  e,
                                  "Delete",
                                  props.id,
                                  props.dataType
                                )
                              }
                              key={index}
                            >
                              <DeleteOutlinedIcon />
                              Delete
                            </MenuItem>
                          );
                        } else if (!props.showArchive && item.type === "Archive") {
                          return (
                            <MenuItem
                              onClick={(e) =>
                                optionAction(
                                  e,
                                  "Archive",
                                  props.id,
                                  props.dataType
                                )
                              }
                              key={index}
                            >
                              <ArchiveOutlinedIcon />
                              Archive
                            </MenuItem>
                          );
                        }
                      }
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
