import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    templates: [],
    status: 'loading'
};

export const getTemplatesAsync = createAsyncThunk(
    'templates/getTemplates',
    async (query = {}) => {

        const companyId = query.companyId || localStorage.getItem('companyId');
        const ref = fire.database().ref(companyId).child('templates');
        const refsnapshot = await ref.once("value");
        const templates = JSON.parse(JSON.stringify(refsnapshot.val() || {}));
        ref.off("value");
        const result = []

        for (const id in templates) {
            result.push({ id, ...templates[id] });
            if (!templates[id].tags) {
                templates[id].tags = [];
            }
        }
        return result;
    }
);

// Redux Toolkit slice
export const getTemplatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        // getEstimate: (state) => {
        //     state.estimate = state.payload
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTemplatesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTemplatesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.templates = action.payload.templates
            });
    },
});

export default getTemplatesSlice.reducer;
