import React from "react";
import "../../assets/scss/all-estimates.scss";
import Moment from "moment";
import { Typography } from "@material-ui/core";
import FilterList from "../small-components/FilterList";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid/Grid";
import template_empty_img from "../../assets/images/template_empty.svg";
import { DefaultButton } from "../small-components/UI/Buttons";
import fire from "../../config/firebase";
import { isTrue } from "../../libs/utils";
export default class CreateTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      age: "",
      key: "",
      typeCast: "",
      listToShow: [],
      sort: "ascending",
      itemKey: "",
      deleteId: "",
      favoriteValue: "",
      dialogOpen: false,
      optionAction: "",
      uniqueId: "",
      page: 1,
      arrayItem: [],
    };
  }
  templateId = (id) => {
    const companyId = localStorage.getItem('companyId');
    let copy = "";
    for (let i = 0; i < this.state.arrayItem.length; i++) {
      if (this.state.arrayItem[i]["id"] === id) {
        copy = this.state.arrayItem[i];
        copy.id = null;
        copy.date = Moment(new Date()).format("MM/DD/YYYY");
        copy.timestamp = Math.floor(Date.now() / 1000);
        copy.modified = Moment(new Date()).format("h:mm a MMMM DD, YYYY");
        const templatesRef = fire.database().ref(companyId).child("templates");
        templatesRef.push(copy);

        if (this.props.refreshData) {
          this.props.refreshData();
        }
      }
    }
  };
  createTemplate = () => {
    this.props.createTemplate();
  };
  componentDidMount = () => {
    const companyId = localStorage.getItem('companyId');
    const ref = fire.database().ref(companyId).child("estimates");
    ref.once("value").then(
      (snapshot) => {
        const estimates = snapshot.val();
        ref.off("value");
        const estimateList = [];
        for (const id in estimates) {
          if (estimates[id].projectName && !isTrue(estimates[id].archive)) {
            if (estimates[id].tags === undefined) {
              estimates[id].tags = [];
            }
            estimateList.push({ id, ...estimates[id] });
          }
        }
        this.setState({ arrayItem: estimateList });
      },
      (error) => {
        console.log("Error: " + error.code);
      }
    );
  };

  render() {
    const listToShow = this.state.arrayItem;
    return (
      <React.Fragment>
        <section
          className="all-estimates templateNew "
          style={{
            marginLeft: "0",
            backgroundColor: "transparent",
            paddingTop: 2,
            paddingBottom: 0,
          }}
        >
          <div>
            <Grid container style={{ maxWidth: 1080, margin: "0 auto 27px" }}>
              <Grid item xs={3}>
                <Box maxWidth={226} maxHeight={208} position="relative">
                  <img
                    src={`${template_empty_img}`}
                    alt="Template empty"
                    style={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box style={{ paddingLeft: "13px" }}>
                  <Typography
                    variant="h1"
                    component="h1"
                    style={{
                      fontSize: "40px",
                      fontWeight: 400,
                      lineHeight: 1.38,
                      marginBottom: "10px",
                    }}
                  >
                    Templates. Do something great. Repeat.
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    style={{
                      fontWeight: 100,
                      fontSize: "20px",
                      lineHeight: 1.35,
                      marginBottom: "31px",
                    }}
                  >
                    Need to reuse certain estimates multiple times? Try creating
                    a template today.
                  </Typography>
                  <DefaultButton onClick={this.props.createTemplate}>
                    Create Template
                  </DefaultButton>
                </Box>
              </Grid>
              <Grid></Grid>
            </Grid>

            {listToShow.length > 0 && (
              <React.Fragment>
                <div className="tempshowButton">
                  <div className="title">
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{
                        fontWeight: 600,
                        fontSize: "12px",
                        lineHeight: 1.42,
                        marginBottom: "10px",
                        color: "#084d4f",
                        letterSpacing: "0.6px",
                      }}
                    >
                      RECENT ESTIMATES
                    </Typography>
                  </div>
                  <FilterList
                    items={listToShow}
                    page={this.state.page}
                    classes={this.props.classes}
                    list={this.state.editList}
                    date={true}
                    typecast={this.handlePages}
                    // overlay={this.createNew}
                    handleFavorite={this.handleFavorite}
                    optionChange={this.optionChange}
                    templateId={this.templateId}
                    dataType="estimates"
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </section>
      </React.Fragment>
    );
  }
}
