import React, { useEffect, useState } from "react";
import { Paper, IconButton, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
import StarIcon from "@material-ui/icons/Star";
import icon1 from "../../assets/images/estimates.svg";
import SelectEstimate from "../reusable-components/SelectEstimate";
import { DefaultButton } from "./UI/Buttons";
import ChipsArray from "../small-components/chips";
import { Link } from "react-router-dom";

export default function FilterList(props) {
  const { dataType = "", allTags, users } = props;
  const [show, setShow] = useState(props.showArchive)
  const findUser = (lastEditorId) => {
    const editorName = users.find((user) => user.id === lastEditorId)
    return editorName?.firstname
  }
  const handleFavorite = (id, e) => {
    props.handleFavorite(id, e);
  };
  const handleArchive = () => {
    setShow(props.showArchive)
  }
  useEffect(() => { handleArchive() }, [props.showArchive])
  const useStyles = makeStyles({
    filter_icon_outer: {
      "& .defaut-black": {
        display: "block",
      },
      "& .template_pink": {
        display: "none",
      },
      "&:hover": {
        "& .defaut-black": {
          display: "none",
        },
        "& .template_pink": {
          display: "block",
        },
      },
    },
    archivetitle: {
      display: "inline-block",
      verticalAlign: "top",
      fontSize: "12px",
      color: "#000000",
      backgroundColor: "rgba(8,77,79,0.10)",
      padding: "5px 11px",
      borderRadius: "13px",
      marginLeft: "10px",
      textTransform: "capitalize",
    },
    disableHover: {
      "&:hover": {
        cursor: 'default',
        '& svg': {
          color: '#000 !important',
        }
      }
    }

  });
  const classes = useStyles();
  const optionAction = (e, id) => {
    props.optionChange(e, id);
  };
  const saveTamplate = (id) => {
    props.templateId(id);
  };
  const items = props.items
  if (items.length === 0) {
    return null;
  }
  const makeSingular = (word) => {
    if (word && (word.endsWith('S') || word.endsWith('s'))) {
      return word.substring(0, word.length - 1)
    }
    return word;
  }
  return (
    <Grid container className="outer-box filter-temp-est ">
      {items.slice(6 * props.page - 6, 6 * props.page).map((curItem) => {
        const favorite =
          props.favoriteestimate &&
          props.favoriteestimate.find((item) => {
            return item && item.id === curItem.id;
          }) !== undefined;
        let tempDataType = dataType;
        let tempPlural = "s";
        let titleName = tempDataType;
        const showStyle = curItem.archive ? " archive" : "";
        if (curItem.typeOf.startsWith("TIME BASED")) {
          tempDataType = (dataType === 'templates' ? 'templates' : 'estimates') + "-timebased";
          titleName = (dataType === 'templates' ? 'template' : 'estimate');
          tempPlural = "";
        }
        return (
          <Grid item className={props.classes.item} xs={12} key={curItem.id}>
            <Paper className={props.classes.innerItem + showStyle} elevation={0}>
              <div
                className={`item-intro`}
                key="0"
              >
                <Typography variant="h6" key="t0">
                  {curItem.filterType === "task-based"
                    ? "TASK BASED "
                    : "TIME BASED "}
                  {makeSingular(titleName.toUpperCase())}
                </Typography>
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.contentOuter}
                  key="t1"
                >
                  <img src={icon1} alt="estimates"></img>
                  {curItem.archive ?
                    <div><h3 className="filter-list-name"> {curItem.projectName} </h3></div>
                    :
                    <Link
                      to={{
                        pathname: `/${tempDataType}${tempDataType.endsWith("s") ? "" : tempPlural
                          }/${encodeURI(curItem.projectName)}/${curItem.id}`,
                        state: {
                          key: curItem.id,
                        },
                      }}
                    >
                      <h3 className="filter-list-name"> {curItem.projectName} </h3>
                    </Link>
                  }
                  {(show && curItem.archive) && (
                    <Typography
                      component="span"
                      className={classes.archivetitle}
                    >
                      archive
                    </Typography>
                  )}
                </Typography>

                <div className="info" key="t2">
                  <ChipsArray
                    show={curItem.archive}
                    key={curItem.id}
                    itemId={curItem.id}
                    tagsList={curItem.tags}
                    dataType={dataType}
                    refreshData={props.refreshData}
                    allTags={allTags}
                  ></ChipsArray>
                </div>
              </div>

              <div className="item-option" key="1">
                <div className="option-outer" key="d0">
                  {curItem.modified ? (
                    <div className="text-wrapper" key="1">
                      <Typography variant="body1" color="textSecondary">
                        Last modified
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {curItem.modified}
                      </Typography>
                      <Typography variant="subtitle3" color="textSecondary" style={{ textAlign: 'left' }}>
                        {`by ${findUser(curItem.lastEditor)}`}{"     "}
                      </Typography>
                    </div>
                  ) : (
                    <div className="text-wrapper" key="1"></div>
                  )}

                  <div className="featured-icon" key="2">
                    <IconButton
                      aria-label="featured"
                      className={curItem.archive ? classes.disableHover : ""}
                      onClick={(e) => {
                        curItem.archive ? e.preventDefault() :
                          handleFavorite(curItem.id, curItem.favorite)

                      }
                      }
                    >
                      {favorite === true ? (
                        <StarIcon htmlColor="#2ab7ca" />
                      ) : (
                        <GradeOutlinedIcon htmlColor="#000" />
                      )}
                    </IconButton>
                  </div>
                  <div
                    className="select-box"
                    key="3"
                    style={{
                      display: "inline-block",
                      maxWidth: "35px",
                      position: "relative",
                      top: "3px",
                    }}
                  >
                    <SelectEstimate
                      list={props.list}
                      name={curItem.projectName}
                      optionAction={optionAction}
                      id={curItem.id}
                      showArchive={curItem.archive}
                      placement="bottom"
                    />
                  </div>
                </div>
                <div className="buttonOuter" key="2">
                  <DefaultButton
                    onClick={() => saveTamplate(curItem.id)}
                    style={{ backgroundColor: "#084d4f", color: "#ffffff" }}
                  >
                    Save as a Template
                  </DefaultButton>
                </div>
              </div>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}
