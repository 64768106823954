import React from 'react';
import Signup from '../reusable-components/SignUp/SignupForm';
class SignUpPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <Signup />
      </React.Fragment>
    )
  }

}

export default SignUpPage;