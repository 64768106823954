import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import AsideDrawer from '../components/small-components/AsideDrawer/AsideDrawer';
import Typography from '@material-ui/core/Typography';
import InputField from '../components/small-components/UI/InputField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ColorPicker from '../components/small-components/ColorPicker/ColorPicker';
import MenuDropdown from '../components/small-components/MenuDropdown/MenuDropdown';
import MenuItem from '@material-ui/core/MenuItem';
import fire from '../config/firebase';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import Moment from 'moment';
import { DefaultButton } from '../components/small-components/UI/Buttons';
import { debounce } from 'lodash';
import { colorArray } from '../libs/color/colorUtils';

const styles = () => ({
  menuDropdown: {
    marginLeft: 10,
  },
  menuDropdownInput: {
    padding: '5.5px 10px',
    paddingRight: '31px !important',
  },
  menuDropdownIcon: {
    marginRight: '5.5px',
  },
  pagination: {
    width: 'auto',
    backgroundColor: 'transparent !important',
    boxShadow: 'none'
  },
  paginationItem: {
    width: 30,
    height: 30,
    padding: '5px 11px 6px',
    backgroundColor: 'transparent !important',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.36,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
    }
  },
  paginationItemIcon: {
    width: 24,
    height: 24
  },
  paginationItemSelected: {
    backgroundColor: '#084d4f !important',
    color: '#ffffff !important',
    '&:hover': {
      backgroundColor: 'rgba(8, 77, 79, 0.9) !important',
    }
  },
  paginationItemDisabled: {
    opacity: '0.5 !important'
  },
  label: {
    marginTop: '30px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: 1.42,
    letterSpacing: '0.6px',
    color: '#084d4f',
    textTransform: 'uppercase',
  },
  buttonGroupRoot: {
    marginTop: '14px',
  },
  grouped: {
    minWidth: '100px',
    padding: '5px 18px',
    backgroundColor: '#FFFFFF',
  },
  buttonLabel: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: 1.36,
    letterSpacing: '0.14px',
    textAlign: 'center',
    textTransform: 'capitalize',
    color: '#000000',
  },
  buttonOutlined: {
    borderColor: '#000000',
    borderRadius: '5px',
    '&.active': {
      backgroundColor: 'rgba(8, 77, 79, 0.05)',
      '& span': {
        color: '#e70a89'

      },
    },
  },
  radio: {
    padding: '5px 10px',
    color: '#084d4f !important',
  },
  radioChecked: {
    color: '#084d4f !important',
  },
  radioLabel: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.42,
    letterSpacing: '0.6px',
    color: '#084d4f',
    textTransform: 'uppercase',
  },
  buttonWrap: {
    '& > .MuiButton-outlined': {
      marginLeft: 10,
    },
  },
  asideMenuDropdown: {
    marginTop: 10,
    width: '100%',
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: '31px !important',
  },
  asideMenuDropdownIcon: {
    marginRight: '5.5px',
  },
});

class ResourceDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: 'most-recent',
      discipline: '',
      type: 'new',
      drawerState: false,
      dialogOpen: false,
      phaseKey: "",
      key: '',
      typeCast: '',
      arrayItem: [],
      arrayDiscipline: [],
      page: 1,
      listToShow: [],
      itemKey: '',
      optionAction: '',
      drawerName: props.newResourceData?.resourceName || '',
      drawerColor: '',
      drawerType: 'new',
      saveInfo: false,
      drawerDefaultName: '',
      drawerDefaultColor: '#00ff59',
      resourceType: 'Role',
      drawerDiscipline: '',
      cost: '',
      drawerDefaultCost: 0,
      amountType: "rate",
      defaultamountType: "",
      amount: 0,
      drawerDefaultAmount: 0,
      uniqueId: 0,
      errorStateName: false,
      errorStateCost: false,
      errorStateAmount: false,
      loader: true,
      randomColor: '#00ff59',
      // colorArray: ['#ff0000', '#faff00', '#00ff15', '#0010ff', '#ff004a'],
      dataLength: 0
    }
    this.items = [...this.state.arrayItem];
    this.paginationCount = 10;

  };

  componentDidMount = () => {
    const companyId = localStorage.getItem('companyId');
    const listRef = fire.database().ref(companyId).child('disciplines');
    listRef.once('value').then((snapshot) => {
      listRef.off('value');
      const list = snapshot.val();
      const listArr = [''];
      for (const id in list) {
        listArr.push({ id, ...list[id] });
      }
      const defaultDsicipline = '';
      if (listArr && listArr.length > 0) {
        // const otherDiscipline = listArr.find(d => d.title === '');
        // if (otherDiscipline) {
        //   defaultDsicipline = otherDiscipline.title;
        // } else {
        //   defaultDsicipline = listArr[0].title;
        // }
        this.setState({ arrayDiscipline: listArr, drawerDiscipline: defaultDsicipline });
      }
    });
    const randomIndex = Math.random() * 100000;
    const color = colorArray[Math.floor(randomIndex % colorArray.length)];
    this.setState({
      randomColor: color,
      amountType: this.props.newResourceData.type === "Role" ? "rate" : "percentage"
    });

    let vendor = 0;
    let role = 0

    const estimateRef = fire.database().ref(companyId).child(`${this.props.dataType}/${this.props.id.key}`)
    const companyRef = fire.database().ref(companyId).child(`company`);

    estimateRef.once('value').then((snapshot) => {
      const list = snapshot.val();
      if (list.billableSwitch) {
        if (this.props.newResourceData.type === "Vendor") {
          list.profitMargin ? vendor = list.profitMargin : "nothing";
        } else {
          list.definedhourlyRate?.length > 0 ? role = list.definedhourlyRate : "nothing";
        }
      }
      companyRef.once('value').then((snapshot) => {
        const list = snapshot.val();

        if (this.props.newResourceData.type === "Vendor") {
          vendor === 0 ? vendor = list.vendorUpsell : "nothing"
          this.setState({ amount: vendor })

        } else {
          role = 0 ? role = list.hourlyRate : "nothing"
          this.setState({ amount: role })

        }
        this.setState({ cost: list.hourlyCost })
      });

    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.newResourceData?.resoureName !== this.props.newResourceData?.resoureName) {
      this.setState({ drawerName: nextProps?.newResourceData?.resoureName });
      return true
    } else if (nextState.open !== this.props.open) {
      // this.setState({ drawerState: nextProps.open })
      return true
    }
    else {
      return false
    }
  }

  colorHandler = debounce((e) => {
    this.setState({ drawerColor: e });
  }, 300);
  handleDrawerAmount = (val = '') => {
    const re = /^[0-9]/g;
    if (val !== '' && re.test(val) === false) {
      return;
    }
    if (val.length > 5) {
      return;
    }
    this.setState({ amount: val });
    if (val !== "") {
      this.setState({ errorStateAmount: false });
    } else {
      this.setState({ errorStateAmount: true });
    }
  };
  handleDrawerCost = (val) => {
    val = val || '';
    const re = /^[0-9]/g;
    if (val !== '' && re.test(val) === false) {
      return;
    }
    if (val.length > 5) {
      return;
    }
    this.setState({ cost: val });
    if (val !== "") {
      this.setState({ errorStateCost: false });
    } else {
      this.setState({ errorStateCost: true });
    }
  }
  handleAmountType = (e) => {
    const val = e.target.value;
    this.setState({ amountType: val });
  }
  handleDiscipline = (event) => {
    this.setState({ discipline: event.target.value });
  };
  handleDrawerDiscipline = (event) => {
    this.setState({ drawerDiscipline: event.target.value });
  };
  drawerInput = debounce((e) => {
    this.setState({ drawerName: e });
    if (e !== "") {
      this.setState({ errorStateName: false });
    } else {
      this.setState({ errorStateName: true });
    }
  }, 300);

  toggleDrawer = (open, type) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open === true) {
      const randomIndex = Math.random() * 100000;
      const color = colorArray[Math.floor(randomIndex % colorArray.length)];
      this.setState({ randomColor: color });
    }
    this.props.toggle();
    this.setState({ drawerType: type });
    this.setState({
      errorStateName: false,
      errorStateCost: false,
      errorStateAmount: false,
    });

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  handlerResourceType = (type) => {
    this.setState({
      resourceType: type,
      errorStateName: false,
      errorStateCost: false,
      errorStateAmount: false,
    });
  }
  saveItem = () => {
    if (this.state.drawerName === "") {
      this.setState({
        errorStateName: true
      });
    }
    if (this.state.cost === 0) {
      this.setState({
        errorStateCost: true
      });
    }
    if (this.state.amount === 0) {
      this.setState({
        errorStateAmount: true
      });
    }
    if (this.state.drawerName !== "" &&
      this.state.cost !== 0 &&
      this.state.amount !== 0
    ) {
      this.props.toggle();
      setTimeout(() => {
        this.setState({
          saveInfo: false,
          drawerName: '',
          drawerType: '',
        });
      }, 500);

      let new_icon;
      if (this.props.newResourceData?.type === "Role") {
        new_icon = GroupOutlinedIcon;
      } else {
        new_icon = WorkOutlineOutlinedIcon;
      }
      let colorCode = this.state.randomColor;
      if (this.state.drawerColor) {
        colorCode = this.state.drawerColor;
      }
      const resourceData = {
        title: this.state.drawerName,
        borderColor: colorCode,
        caption: this.state.drawerDiscipline,
        subtitle: this.props.newResourceData?.type,
        icon: JSON.stringify(new_icon || {}),
        cost: parseFloat(this.state.cost || 0),
        amountType: this.state.amountType,
        amount: parseInt(this.state.amount || 0),
        date: Moment(new Date()).format('DD/MM/YYYY'),
        resourceName: this.state.drawerName
      };

      if (this.props.onSuccess) {
        this.props.onSuccess(resourceData);
      }
    }
  }

  render() {
    const { classes, newResourceData = {} } = this.props;
    const { type } = newResourceData;
    console.log("render", this.state)
    // let amountType = type === "Role" ? "rate" : "percentage"

    return (
      <Box component="aside" style={{ marginLeft: 79 }}>
        <AsideDrawer
          toolbarTitle={`${this.state.drawerType === "new" ? "Add" : 'Edit'} ${this.props.newResourceData.type}`}
          open={this.props.open}
          close={this.toggleDrawer(false)}
          clsName="resource-drawer"
          name={this.state.drawerName}
        >
          {/* <Typography className={classes.label} id="resource-type">
            Resource Type {this.props.newResourceData?.type}
          </Typography>
          <ButtonGroup
            aria-labelledby="resource-type"
            classes={{
              root: classes.buttonGroupRoot,
              grouped: classes.grouped
            }}
          >{this.props.newResourceData?.type === "Role" ?
            <Button className={`${this.props.newResourceData?.type === "Role" ? "active" : ""}`}
              classes={{
                label: classes.buttonLabel,
                outlined: classes.buttonOutlined
              }}
              onClick={() => this.handlerResourceType("Role")}
            >
              Role
            </Button> :
            <Button className={`${type === "Vendor" ? "active" : ""}`} classes={{
              label: classes.buttonLabel,
              outlined: classes.buttonOutlined
            }}
              onClick={() => this.handlerResourceType("Vendor")}
            >
              Vendor
            </Button>
            }

          </ButtonGroup> */}
          <Typography className={classes.label} id="name">
            Name
          </Typography>
          <InputField
            label={type + " Name"}
            defaultValue={this.state.drawerName}
            aria-labelledby="name"
            required={this.state.drawerName ? false : true}
            className={this.state.errorStateName === true && "errorInput"}
            onChange={(e) => this.drawerInput(e.target.value)}
          />
          {type === "Role" ?
            <React.Fragment>
              <Typography className={classes.label} id="hourly-cost">
                Hourly Cost
              </Typography>
              <InputField
                className={`resource_hourly_cost ${this.state.errorStateCost === true && "errorInput"}`}
                type="number"
                label="Amount"
                aria-labelledby="hourly-cost"
                adornment="$"
                adornmentPosition="end"
                required
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={this.state.cost || ''}
                defaultValue={this.state.drawerType === 'old' ?
                  this.state.drawerDefaultCost
                  : ''}
                onChange={(e) => this.handleDrawerCost(e.target.value)}
              />
            </React.Fragment>
            : ""}
          {type === "Vendor" ?
            <React.Fragment>
              <Typography className={classes.label} id="hourly-cost">
                Hourly Cost
              </Typography>
              <InputField
                className='resource_hourly_cost'
                type="number"
                label="Amount"
                aria-labelledby="hourly-cost"
                adornment="$"
                adornmentPosition="end"
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                value={this.state.cost || ''}
                defaultValue={this.state.drawerType === 'old' ?
                  this.state.drawerDefaultCost
                  : ''}
                onChange={(e) => this.handleDrawerCost(e.target.value)}
              />
            </React.Fragment>
            : ""}
          <Typography className={classes.label} id="amount"></Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="amount"
              name="amount"
              value={this.state.value}
              defaultValue={this.state.amountType}
              onChange={(e) => this.handleAmountType(e)}
            >
              <FormControlLabel
                value="rate"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.radioChecked
                    }}
                  />
                }
                label="Billable Rate"
                classes={{ label: classes.radioLabel }}
              />
              <FormControlLabel
                value="percentage"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.radioChecked
                    }}
                  />
                }
                label="Upsell Percentage"
                classes={{ label: classes.radioLabel }}
              />
            </RadioGroup>
          </FormControl>
          <InputField
            type="number"
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            label="Amount"
            className={`${this.state.amountType === "percentage" ? 'percentage_num' : "dollar_cost"}  ${this.state.errorStateAmount === true ? "errorInput" : ''}`}
            aria-labelledby="amount"
            amountType={this.state.amountType}
            adornment={`${this.state.amountType === "percentage" ? '%' : "$"}`}
            adornmentPosition={`${this.state.amountType === "percentage" ? "end" : "start"}`}
            required
            defaultValue={this.state.drawerType === 'old' ?
              this.state.drawerDefaultAmount
              : ''}
            value={this.state.amount || ''}
            onChange={(e) => {
              const re = /^[0-9]+$/g;
              if (e.target.value === '' || re.test(e.target.value)) {
                this.handleDrawerAmount(e.target.value)
              }
            }
            }
          />

          <Typography className={classes.label} id="discipline">
            Discipline
          </Typography>
          <MenuDropdown
            className={classes.asideMenuDropdown}
            selectInputProps={{
              classes: {
                root: classes.asideMenuDropdownInput,
                icon: classes.asideMenuDropdownIcon,
              },
            }}
            aria-labelledby="discipline"
            value={this.state.drawerDiscipline}
            change={this.handleDrawerDiscipline}
          >
            {this.state.arrayDiscipline.map(function (item, index) {
              return (
                <MenuItem key={index + item.timestamp} value={item.title}>{item.title}</MenuItem>
              )
            })}
          </MenuDropdown>
          <Typography className={classes.label} id="color-picker">
            Color
          </Typography>
          <ColorPicker
            colorCode={this.colorHandler}
            defaultColor={
              this.state.drawerType === 'old' ?
                this.state.drawerDefaultColor
                : this.state.randomColor} />
          <Box className={classes.buttonWrap} mt={2}>
            <DefaultButton variant="contained"
              // className={`${this.state.amount && this.state.drawerName ? "" : "resource-btn-disabled"}`}
              onClick={this.saveItem}>Save</DefaultButton>
          </Box>
        </AsideDrawer>

      </Box >
    );
  }

}

export default withStyles(styles)(ResourceDrawer);