import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import List from "@material-ui/core/List";
import AsideDrawer from "../small-components/AsideDrawer/AsideDrawer";
import MenuDropdown from "../small-components/MenuDropdown/MenuDropdown";
import InputField from "../small-components/UI/InputField";
import { DefaultButton } from "../small-components/UI/Buttons";
import EstimaticSkyGondolaImage from "../../assets/images/new-svgs/do_something.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
import { debounce } from "lodash";
import TextField from "@material-ui/core/TextField";
import DomainIcon from "@material-ui/icons/Domain";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import Button from "@material-ui/core/Button";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import AutocompleteContact from "../reusable-components/autoComplete/autoCompleteContacts";
import AutoCompleteEstimatic from "../reusable-components/autoComplete/autoCompleteEstimatic";
import AddContact from "../small-components/AddContact";
import Moment from "moment";
import validator from "validator";
import Link from "@material-ui/core/Link";
import fire from "../../config/firebase";
import { isFalse } from "../../libs/utils";

const styles = () => ({
  "@global": {
    body: {
      "&.menuOpen": {
        "& .MuiListItemSecondaryAction-root": {
          opacity: 1,
        },
      },
      "& .MuiFormGroup-root": {
        "&.disabled": {
          pointerEvents: "none",
          "& label": {
            "& > span": {
              color: "rgba(0,0,0,0.4)",
            },
            "& svg": {
              fill: "none",
              border: "2px solid rgba(8,77,79,0.3)",
              height: "18px",
              width: "17px",
              borderRadius: "2px",
            },
          },
        },
      },
    },
    ".MuiFormHelperText-root": {
      color: "#ff0000",
    },
    " .MuiAutocomplete-paper": {
      boxShadow: "none !important",
    },
  },

  contactInfo: {
    " & .MuiButton-root": {
      "&:hover": {
        backgroundColor: "transparent",
        "& svg path": {
          fill: "#e70a89",
        },
      },
    },
    "& div": {
      border: 0,
      fontWeight: 500,
    },
    "& small": {
      color: "#084d4f",
    },
    "& .archiveShow": {
      backgroundColor: "rgba(8,77,79,0.1)",
      borderRadius: 4,
      "& h4": {
        opacity: 0.5,
      },
      "& .MuiList-root": {
        "& li:last-child": {
          display: "flex",
        },
        "& li": {
          display: "none;",
        },
      },
    },
    "& .MuiList-root": {
      "& li:last-child": {
        display: "none",
      },
      // '& li':{
      //   display: 'none;'
      // }
    },
  },

  menuDropdownInput: {
    padding: "5.5px 10px",
    fontWeight: 300,
  },
  menuDropdownIcon: {
    marginRight: "5.5px",
  },
  paper: {
    background: `url(${EstimaticSkyGondolaImage})`,
  },
  gridWidthThree: {
    flexGrow: 0,
    maxWidth: 276,
    flexBasis: 276,
  },
  gridWidthNine: {
    flexGrow: 0,
    maxWidth: "calc(100% - 276px)",
    flexBasis: "calc(100% - 276px)",
  },
  numberBox: {
    marginTop: "10px !important",
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
    "& input": {
      padding: "0 10px !important",
      border: "0!important",
      marginBottom: "0!important",
      boxShadow: "none !important",
    },
    "& .MuiFormLabel-filled": {
      color: "rgba(8, 77, 79, 0.5) !important",
      top: "0",
    },
    "& label.Mui-focused": {
      color: "rgba(8, 77, 79, 0.5) !important",
      top: "0",
    },
    "& .MuiInputLabel-formControl": {
      top: "-6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(8, 77, 79, 0.1) !important",
    },
  },
  gridSpacingSix: {
    width: "calc(100% + 50px)",
    margin: "0 -25px",
    "& > .MuiGrid-item": {
      padding: "0 25px",
    },
  },
  titleOuter: {
    fontSize: "40px",
    fontWeight: 500,
    lineHeight: 1.38,
    letterSpacing: "normal",
  },
  description: {
    marginTop: 10,
    fontSize: "20px",
    fontWeight: 300,
    lineHeight: 1.35,
    letterSpacing: "normal",
  },
  buttonWrap: {
    marginTop: 30,
    "& button": {
      border: "0 !important",
      minWidth: "72px !important",
      minHeight: "40px !important",
      marginBottom: "30px !important",
      "& svg": {
        fontSize: "25px !important",
      },
    },
  },
  infoTitle: {
    "& button": {
      marginTop: 6,
    },
  },
  label: {
    margin: "30px 0 0",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#084d4f",
    textTransform: "uppercase",
  },
  buttonGroupRoot: {
    marginTop: "14px",
  },
  grouped: {
    minWidth: "100px",
    padding: "5px 18px",
    backgroundColor: "#FFFFFF",
  },
  buttonLabel: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.36,
    letterSpacing: "0.14px",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#000000",
  },
  buttonOutlined: {
    borderColor: "#000000",
    borderRadius: "5px",
  },
  asideMenuDropdown: {
    marginTop: 10,
    width: "100%",
  },
  autoMenuDropdown: {
    "& input": {
      border: "0px !important",
      height: "auto !important",
      fontSize: "14px !important",
      boxShadow: "none !important",
    },
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: "31px !important",
    boxSizing: "border-box",
  },
  asidesubMenuDropdownInput: {
    minWidth: "100% !important",
    width: "100% !important",
    padding: "10px 24px 10px 10px !important",
    boxSizing: "border-box",
    height: "100%",
  },
  asideMenuDropdownIcon: {
    marginRight: "5.5px",
  },

  archive: {
    "&.disabled": {
      pointerEvents: "none",
      "& > span": {
        color: "rgba(0,0,0,0.4)",
      },
    },
    "&.enabled": {
      "& > span": {
        color: "rgba(0,0,0,1)",
      },
    },
    "& > span": {
      color: "rgba(0,0,0,1)",
    },
    "& svg": {
      color: "rgba(8,77,79,.3)",
    },

    "& > span.Mui-checked": {
      color: "rgba(8,77,79,1) !important",
      "& svg": {
        color: "rgba(8,77,79,1)",
      },
      "& + span": {
        color: "rgba(0,0,0,1)",
      },
    },
    "& .MuiIconButton-label": {
      position: "relative",
      backgroundColor: "#fff",
      height: 13,
      width: 16,
    },
  },
  headlabel: {
    marginTop: "20px",
    color: "#084d4f",
    fontWeight: "bold",
    fontSize: "12px",
  },
  sideTabwrap: {
    "& .MuiTab-textColorInherit": {
      opacity: "1 !important",
    },
    "& .MuiTab-wrapper": {
      textTransform: "capitalize ",
      color: "#000000",
      letterSpacing: "0.14px",
      opacity: "1 !important",
      lineHeight: 1.1,
      fontWeight: 300,
    },
    "& .MuiTabs-flexContainer": {
      border: "solid 1px #000000",
      borderRadius: 5,
      width: 260,
      marginTop: 9,
    },
    "& .MuiTabPanel-root": {
      padding: 0,
      "& label": {
        fontWeight: 300,
      },
    },
    "& button": {
      minWidth: "130px",
      minHeight: "19px",
      "&:first-child": {
        borderRight: "solid 1px #000000",
      },
      "&.Mui-selected": {
        backgroundColor: "rgba(8, 77, 79, 0.05)",
        padding: "8px 12px",
        "& .MuiTab-wrapper": {
          color: "#e70a89",
        },
      },
    },
    "& .MuiTabs-indicator": {
      opacity: 0,
    },
  },

  iconSelectbox: {
    position: "relative",

    "& p": {
      position: "absolute",
      bottom: 8,
      left: 0,
    },
    "& svg": {
      fontSize: "1.9rem",
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
    },

    "& label": {
      left: 33,
      top: -8,
      fontSize: "14px",
      color: "#000000",
      "&.Mui-focused": {
        left: 1,
        color: "#084d4f",
        "& + .MuiInput-underline": {
          "&:before": {
            opacity: 1,
          },
        },
      },
      "&.MuiFormLabel-filled": {
        left: 1,
        color: "#084d4f",
        "& + .MuiInput-underline": {
          "&:before": {
            opacity: 1,
          },
        },
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& button": {
        display: "none",
      },
    },
    // '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    //  borderBottom: '0 !important',
    // }
    "& .MuiInput-underline": {
      padding: "0 0 0 0px !important",
      "&:after": {
        opacity: 0,
      },
      "&:before": {
        opacity: 0,
        borderBottom: "1.5px solid rgba(0, 0, 0, 1) !important",
        width: "calc(100% - 20px)",
      },
    },
    "& input": {
      fontWeight: 500,
      padding: "0 0 0 38px !important",
      lineHeight: 1.4,
      //marginBottom: '10px !important',
    },
  },

  peopleInfo: {
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
    "& .MuiListItem-root": {
      paddingLeft: 0,
      "& .MuiListItemText-root.MuiListItemText-multiline": {
        "& span": {
          marginTop: 0,
        },
      },
      "& span": {
        marginTop: 9,
      },
    },
    "& .MuiSvgIcon-root": {
      width: 30,
      height: 30,
    },
    "& .MuiIconButton-root": {
      minWidth: 20,
      minHeight: 20,
      border: "0 !important",
    },
    "& .MuiListItemSecondaryAction-root": {
      opacity: 0,
      right: "-24px",
    },
    "& .MuiListItem-container": {
      "& .MuiListItemSecondaryAction-root": {
        "&.click-state": {
          opacity: " 1 !important",
        },
      },
      cursor: "pointer",
      "&:hover": {
        "& .MuiListItemSecondaryAction-root": {
          opacity: 1,
        },
      },
    },
  },
  outerTab: {
    "& .MuiTab-root": {
      minWidth: 90,
      fontSize: 14,
      marginLeft: 11,
      padding: "0",
      fontWeight: 400,
      color: "#000000",
      opacity: 1,
      textTransform: "capitalize",
      "&:after": {
        content: "",
        position: "absolute",
        left: 0,
        bottom: "0",
        width: "100%",
        height: "5px",
        backgroundColor: "#e70a89",
        opacity: 0,
      },
      "&:hover": {
        color: "#e70a89",
      },
      "&.Mui-selected": {
        "& span": {
          color: "#e70a89",
        },
        "& .MuiTouchRipple-root": {
          backgroundColor: "transparent",
        },
        "&:after": {
          opacity: 1,
        },
      },
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#e70a89",
    },
  },
  addWrap: {
    backgroundColor: "#084d4f !important",
    border: "0 !important",
    color: "#fff !important",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  userbtndisabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    color: "#fff !important",
    pointerEvents: "none",
  },
});
function CalculatorIcon({ iconColor, ...props }) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="23"
        viewBox="0 0 20 23"
      >
        <defs>
          <clipPath id="lods2ple2a">
            <path fill="none" d="M0 0H20V23H0z" />
          </clipPath>
        </defs>
        <g clip-path="url(#lods2ple2a)">
          <path
            d="M16.572 0H3.088A3.017 3.017 0 0 0 0 2.934V19.1a3.017 3.017 0 0 0 3.088 2.934h13.484A3.017 3.017 0 0 0 19.66 19.1V2.934A3.017 3.017 0 0 0 16.572 0M17.6 19.1a1.006 1.006 0 0 1-1.029.978H3.088a1.006 1.006 0 0 1-1.029-.978V2.934a1.006 1.006 0 0 1 1.029-.978h13.484a1.006 1.006 0 0 1 1.029.978zM4.117 9.681h11.426v-5.77H4.117zm2.059-3.814h7.308v1.858H6.176zm-1.54 10.2h6.275v1.956H4.636zm2.055-2.476H4.632v-1.954h2.059zm2.162-1.956h2.059v1.956h-2.06zm4.117 0h2.059v1.956H12.97zm0 4.432h2.059v1.956H12.97z"
            transform="translate(0 .3)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "most-recent",
      sortPeople: "most-recent",
      drawerState: false,
      dialogOpen: false,
      phaseKey: "",
      key: "",
      keyPeople: "",
      typeCast: "",
      arrayItem: [],
      arrayItemPeople: [],
      arrayItemEstimate: [],
      page: 1,
      pagePeople: 1,
      editList: [
        {
          type: "Edit",
          icon: <EditOutlinedIcon htmlColor="#000" />,
          id: "edit",
        },
        {
          type: "Archive",
          icon: <ArchiveOutlinedIcon htmlColor="#000" />,
          id: "archive",
        },
        {
          type: "Unarchive",
          icon: <UnarchiveOutlinedIcon htmlColor="#000" />,
          id: "unarchive",
        },
      ],
      listToShow: [],
      listToShowPeople: [],
      itemKey: "",
      optionAction: "",
      drawerType: "",
      contacttype: "1",
      saveInfo: false,
      tabValue: "1",
      uniqueId: 8,
      uniqueIdPeople: 10,
      archiveFilter: "false",
      archiveFilterPeople: "false",
      peopleList: [],
      estimatesList: [],
      organizationList: [],
      newPeopleList: [],
      neworganizationList: [],
      peopleCount: 0,
      removeKey: "",
      removeListType: "",
      action: "",
      listTitle: "",
      listEmail: "",
      listContactType: "",
      addPerson: false,
      addEstimate: false,
      addorganization: false,
      drawerContactType: "",
      //organization
      organizationName: "",
      organizationLocation: "",
      organizationStreet: "",
      organizationSuite: "",
      organizationCity: "",
      organizationState: "",
      organizationPin: "",
      organizationNote: "",
      organizationID: "",
      organizationTitle: "",
      //default values
      defaultorganizationName: "",
      defaultorganizationLocation: "",
      defaultorganizationStreet: "",
      defaultorganizationCity: "",
      defaultorganizationSuite: "",
      defaultorganizationPin: "",
      defaultorganizationNote: "",
      organizationStateDefault: "",
      peopleListDefault: [],
      estimatesListDefault: [],
      //People
      peopleFullName: "",
      peopleJobTitle: "",
      peopleEmail: "",
      validPeopleEmail: true,
      peoplePhoneNumber: "",
      peopleNote: "",
      //default values
      defaultPeopleFullName: "",
      defaultPeopleJobTitle: "",
      defaultPeopleEmail: "",
      defaultPeoplePhoneNumber: "",
      defaultPeopleNote: "",
      organizationTitleDefault: "",
      removePeople: false,
      peopleRemoveKey: "",
      errorStateOrgName: false,
      errorStatePeopleName: false,
      errorStateEmail: false,
      emailLabel: "Email Address",
      loader: true,
      searchTextOrg: "",
      searchTextPpl: "",
      removeEstimate: false,
      estimateRemoveKey: "",
    };
    this.paginationCount = 10;
  }

  //organization
  handleorganizationName = debounce((value) => {
    this.setState({ organizationName: value });
    if (value === "" || value.substring(0) === " ") {
      this.setState({ errorStateOrgName: true });
    } else {
      this.setState({ errorStateOrgName: false });
    }
  }, 100);
  handleorganizationLocation = (value) => {
    this.setState({ organizationLocation: value });
  };
  handleorganizationStreet = (value) => {
    this.setState({ organizationStreet: value });
  };
  handleorganizationSuite = (value) => {
    this.setState({ organizationSuite: value });
  };
  handleorganizationCity = (value) => {
    this.setState({ organizationCity: value });
  };
  handleorganizationState = (event) => {
    this.setState({ organizationState: event.target.value });
  };
  handleorganizationPin = (value) => {
    this.setState({ organizationPin: value });
  };
  handleorganizationNote = (value) => {
    this.setState({ organizationNote: value });
  };
  //People
  handlePeopleFullName = (value) => {
    this.setState({ peopleFullName: value });
    if (value === "" || value.substring(0) === " ") {
      this.setState({ errorStatePeopleName: true });
    } else {
      this.setState({ errorStatePeopleName: false });
    }
  };
  handlePeopleJobTitle = (value) => {
    this.setState({ peopleJobTitle: value });
  };
  handlePeopleEmail = (email) => {
    this.setState({ peopleEmail: email });
    let validemail = "",
      duplicateemail = "";
    if (validator.isEmail(email)) {
      validemail = "";
    } else {
      validemail = "Incorrect Email";
    }
    let count = this.state.arrayItemPeople.length;
    for (let i = 0; i < count; i++) {
      if (this.state.arrayItemPeople[i]["email"] === email) {
        count = i;
        duplicateemail = "Duplicate Email";
      } else {
        duplicateemail = "";
      }
    }
    if (validemail !== "") {
      this.setState({ emailLabel: validemail });
    } else if (duplicateemail !== "") {
      this.setState({ emailLabel: duplicateemail });
    } else if (duplicateemail === "" && validemail === "") {
      this.setState({ emailLabel: "Email Address" });
    }
  };
  handlePeoplePhoneNumber = (value) => {
    this.setState({ peoplePhoneNumber: value });
  };
  handlePeopleNote = (value) => {
    this.setState({ peopleNote: value });
  };
  showAddPerson = () => {
    this.setState({
      addPerson: true,
    });
  };
  showAddorganization = () => {
    this.setState({
      addorganization: true,
    });
  };
  showAddEstimate = () => {
    this.setState({
      addEstimate: true,
    });
  };

  //Sort type
  handleSort = (event) => {
    this.setState({
      sort: event.target.value,
      page: 1,
    });
  };
  handleSortPeople = (event) => {
    this.setState({
      sortPeople: event.target.value,
      pagePeople: 1,
    });
  };
  //city Type
  handleCity = (event) => {
    this.setState({ city: event.target.value });
  };

  //Search input
  inputText = debounce((e) => {
    this.setState({
      key: e,
      page: 1,
    });
  }, 700);
  inputTextPeople = debounce((e) => {
    this.setState({
      keyPeople: e,
      pagePeople: 1,
    });
  }, 700);

  // Filter Archive
  handleArchiveFilter = debounce(() => {
    if (isFalse(this.state.archiveFilter))
      this.setState({
        archiveFilter: "true",
        page: 1,
      });
    else
      this.setState({
        archiveFilter: "false",
        page: 1,
      });
  });
  handleArchiveFilterPeople = debounce(() => {
    if (isFalse(this.state.archiveFilterPeople))
      this.setState({
        archiveFilterPeople: "true",
        pagePeople: 1,
      });
    else
      this.setState({
        archiveFilterPeople: "false",
        pagePeople: 1,
      });
  });

  //Contact content list: organization/People
  tabHandle = (value) => {
    this.setState({ tabValue: value });
  };

  // Contact Type in side drawer: organization/People
  contactTypeHandle = (value) => {
    this.setState({ contacttype: value });
  };

  //Pagination
  handlePageChange = (event, value) => {
    this.setState({
      page: value,
    });
  };
  handlePageChangePeople = (event, value) => {
    this.setState({
      pagePeople: value,
    });
  };

  //See all people
  handleAllPeople = () => {
    this.setState({
      drawerState: false,
      tabValue: "2",
      keyPeople: this.state.organizationName,
      searchTextPpl: this.state.organizationName,
    });
  };

  //Side Drawer
  toggleDrawer = (open, type) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ drawerState: open, drawerType: type });
    if (open === false) {
      this.setState({
        saveInfo: false,
        peopleList: [],
        organizationList: [],
        peopleCount: 0,
        neworganizationList: [],
        newPeopleList: [],
        addPerson: false,
        addEstimate: false,
        //organization
        organizationName: "",
        organizationStreet: "",
        organizationSuite: "",
        organizationCity: "",
        organizationState: "",
        organizationPin: "",
        //default values
        defaultorganizationName: "",
        defaultorganizationStreet: "",
        defaultorganizationCity: "",
        defaultorganizationSuite: "",
        defaultorganizationPin: "",
        //People
        peopleFullName: "",
        peopleJobTitle: "",
        peopleEmail: "",
        peoplePhoneNumber: "",
        //default values
        defaultPeopleFullName: "",
        defaultPeopleJobTitle: "",
        defaultPeopleEmail: "",
        defaultPeoplePhoneNumber: "",

        errorStateOrgName: false,
        errorStatePeopleName: false,
        emailLabel: "Email Address",
        orgDataLength: 0,
        pplDataLength: 0,
        estimatesList: [],
      });
      this.peopleDropdownList = [...this.state.arrayItemPeople];
    }
  };

  //Delete overlay
  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };
  //Delete overlay
  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };
  handleArchive = (itemIndex, contacttype) => {
    this.setState({
      phaseKey: itemIndex,
      contacttype: contacttype,
      optionAction: "archive",
      drawerState: false,
    });
  };
  optionChange = debounce((value, itemIndex, contacttype) => {
    const companyId = localStorage.getItem('companyId');
    if (value === "archive") {
      if (contacttype === "organization") {
        const userRef = fire.database().ref(companyId).child("organizations").child(itemIndex);
        userRef.update({
          archive: "true",
        });
      } else if (contacttype === "People") {
        const userRef = fire.database().ref(companyId).child("people").child(itemIndex);
        userRef.update({
          archive: "true",
        });
      }
    } else if (value === "unarchive") {
      if (contacttype === "organization") {
        const userRef = fire.database().ref(companyId).child("organizations").child(itemIndex);
        userRef.update({
          archive: "false",
        });
      } else if (contacttype === "People") {
        const userRef = fire.database().ref(companyId).child("people").child(itemIndex);
        userRef.update({
          archive: "false",
        });
      }
    } else if (value === "edit") {
      if (contacttype === "organization") {
        for (let i = 0; i < this.state.arrayItem.length; i++) {
          if (this.state.arrayItem[i]["id"] === itemIndex) {
            const pplList = [],
              peopleList = this.state.arrayItem[i]["people"];
            if (peopleList) {
              for (let l = 0; l < peopleList.length; l++) {
                for (let k = 0; k < this.state.arrayItemPeople.length; k++) {
                  if (
                    this.state.arrayItemPeople[k]["id"] === peopleList[l]["id"]
                  ) {
                    pplList.push({
                      id: this.state.arrayItemPeople[k]["id"],
                      title: this.state.arrayItemPeople[k]["title"],
                      email: this.state.arrayItemPeople[k]["email"],
                    });
                  }
                }
              }
            }
            const estList = this.state.arrayItem[i]["estimates"];
            const estimateList = [];
            if (estList) {
              for (let l = 0; l < estList.length; l++) {
                for (let k = 0; k < this.state.arrayItemEstimate.length; k++) {
                  if (
                    this.state.arrayItemEstimate[k]["id"] === estList[l]["id"]
                  ) {
                    estimateList.push({
                      id: this.state.arrayItemEstimate[k]["id"],
                      title: this.state.arrayItemEstimate[k]["projectName"],
                    });
                  }
                }
              }
            }
            this.setState({
              drawerContactType: contacttype,
              phaseKey: itemIndex,
              optionAction: value,
              drawerState: true,
              drawerType: "old",
              contacttype: "1",
              organizationName: "" + this.state.arrayItem[i]["title"] + "",
              defaultorganizationName:
                "" + this.state.arrayItem[i]["title"] + "",
              defaultorganizationStreet:
                "" + this.state.arrayItem[i]["street"] + "",
              defaultorganizationSuite:
                "" + this.state.arrayItem[i]["suite"] + "",
              defaultorganizationPin: "" + this.state.arrayItem[i]["pin"] + "",
              defaultorganizationNote:
                "" + this.state.arrayItem[i]["note"] + "",
              defaultorganizationCity: this.state.arrayItem[i]["city"],
              organizationState: this.state.arrayItem[i]["state"],
              organizationStateDefault: this.state.arrayItem[i]["state"],
              peopleList: pplList,
              estimatesList: estimateList,
              peopleListDefault: [...pplList],
              estimatesListDefault: [...estimateList],
            });
          }
        }
      } else if (contacttype === "People") {
        for (let i = 0; i < this.state.arrayItemPeople.length; i++) {
          if (this.state.arrayItemPeople[i]["id"] === itemIndex) {
            let orgTitle = "";
            const peopleId = this.state.arrayItemPeople[i]["organization"];

            for (let l = 0; l < this.state.arrayItem.length; l++) {
              if (this.state.arrayItem[l]["id"] === peopleId) {
                orgTitle = this.state.arrayItem[l]["title"];
              }
            }
            const estList = this.state.arrayItemPeople[i]["estimates"];
            const estimateList = [];
            if (estList) {
              for (let l = 0; l < estList.length; l++) {
                for (let k = 0; k < this.state.arrayItemEstimate.length; k++) {
                  if (
                    this.state.arrayItemEstimate[k]["id"] === estList[l]["id"]
                  ) {
                    estimateList.push({
                      id: this.state.arrayItemEstimate[k]["id"],
                      title: this.state.arrayItemEstimate[k]["projectName"],
                    });
                  }
                }
              }
            }
            this.setState({
              drawerContactType: "Person",
              phaseKey: itemIndex,
              optionAction: value,
              drawerState: true,
              drawerType: "old",
              contacttype: "2",
              peopleFullName: this.state.arrayItemPeople[i]["title"],
              defaultPeopleFullName:
                "" + this.state.arrayItemPeople[i]["title"] + "",
              defaultPeopleJobTitle:
                "" + this.state.arrayItemPeople[i]["jobTitle"] + "",
              peopleEmail: "" + this.state.arrayItemPeople[i]["email"] + "",
              defaultPeopleEmail:
                "" + this.state.arrayItemPeople[i]["email"] + "",
              defaultPeoplePhoneNumber:
                "" + this.state.arrayItemPeople[i]["phone"] + "",
              organizationID: this.state.arrayItemPeople[i]["organization"],
              organizationTitle: orgTitle,
              organizationTitleDefault: orgTitle,
              defaultPeopleNote:
                "" + this.state.arrayItemPeople[i]["note"] + "",
              validPeopleEmail: true,
              estimatesList: estimateList,
              estimatesListDefault: [...estimateList],
            });
          }
        }
      }
      setTimeout(() => {
        this.setState({
          optionAction: "",
        });
      }, 1000);
    }
  });
  drawerDelete = () => {
    this.setState({
      dialogOpen: true,
      optionAction: "delete",
    });
  };
  deleteHandler = debounce((key) => {
    this.setState({
      itemKey: key,
      dialogOpen: false,
      drawerState: false,
    });
    setTimeout(() => {
      this.setState({
        itemKey: "",
      });
    }, 300);
  });
  closeHandler = () => {
    this.setState({
      dialogOpen: false,
      drawerState: false,
      estimatesListDefault: [],
      organizationTitleDefault: [],
      peopleListDefault: [],
      defaultorganizationName: "",
      defaultorganizationLocation: "",
      defaultorganizationStreet: "",
      defaultorganizationCity: "",
      defaultorganizationSuite: "",
      defaultorganizationPin: "",
      defaultorganizationNote: "",
      organizationStateDefault: "",
      defaultPeopleFullName: "",
      defaultPeopleJobTitle: "",
      defaultPeopleEmail: "",
      defaultPeoplePhoneNumber: "",
      defaultPeopleNote: "",
    });
  };
  drawerInput = debounce((e) => {
    this.setState({ drawerName: e });
  }, 300);
  colorHandler = debounce((e) => {
    this.setState({ drawerColor: e });
  }, 300);
  removeItem = (key, contacttype) => {
    if (contacttype === "organization") {
      this.setState({
        organizationID: "",
        organizationTitle: "",
        addorganization: false,
      });
    } else if (contacttype === "people") {
      let newIndex = "";
      for (let l = 0; l < this.state.peopleList.length; l++) {
        if (this.state.peopleList[l]["id"] === key) {
          newIndex = l;
        }
      }
      this.state.peopleList.splice(newIndex, 1);
      this.setState({
        removePeople: true,
        peopleRemoveKey: key,
      });
      setTimeout(() => {
        this.setState({
          removePeople: false,
          peopleRemoveKey: "",
        });
      }, 300);
    } else if (contacttype === "estimates") {
      let newIndex = "";
      for (let l = 0; l < this.state.estimatesList.length; l++) {
        if (this.state.estimatesList[l]["id"] === key) {
          newIndex = l;
        }
      }
      this.state.estimatesList.splice(newIndex, 1);
      this.setState({
        removeEstimate: true,
        estimateRemoveKey: key,
      });
      setTimeout(() => {
        this.setState({
          removeEstimate: false,
          estimateRemoveKey: "",
        });
      }, 300);
    }
  };
  //Append items from autocomplete
  handleAutoContact = debounce((newValue, email, type) => {
    const companyId = localStorage.getItem('companyId');
    if (type === "contacts") {
      this.setState({ addPerson: false });
    }
    if (type === "estimates") {
      this.setState({ addEstimate: false });
    }
    if (newValue) {
      if (newValue && newValue.inputValue) {
        if (type === "contacts") {
          const listRef = fire.database().ref(companyId).child("people");
          const itemData = {
            title: newValue.inputValue,
            date: Moment(new Date()).format("MM/DD/YYYY"),
            contactType: "People",
            jobTitle: "",
            email: "",
            phone: "",
            archive: "false",
            organization: "",
            note: "",
            timestamp: Math.floor(Date.now() / 1000),
          };
          listRef.push(itemData);
          for (let i = 0; i < this.state.arrayItemPeople.length; i++) {
            if (
              this.state.arrayItemPeople[i]["title"] === newValue.inputValue
            ) {
              this.state.peopleList.push({
                id: this.state.arrayItemPeople[i]["id"],
                title: this.state.arrayItemPeople[i]["title"],
                email: this.state.arrayItemPeople[i]["email"],
              });
            }
          }
        } else if (type === "organization") {
          const listRef = fire.database().ref(companyId).child("organizations");
          const itemData = {
            title: newValue.inputValue,
            date: Moment(new Date()).format("MM/DD/YYYY"),
            contactType: "organization",
            location: "",
            street: "",
            suite: "",
            city: "",
            state: "",
            pin: "",
            archive: "false",
            note: "",
            people: [],
            timestamp: Math.floor(Date.now() / 1000),
          };
          listRef.push(itemData);
          let orgID = "",
            orgTitle = "";
          for (let i = 0; i < this.state.arrayItem.length; i++) {
            if (this.state.arrayItem[i]["title"] === newValue.inputValue) {
              orgID = this.state.arrayItem[i]["id"];
              orgTitle = this.state.arrayItem[i]["title"];
            }
          }
          this.setState({
            organizationID: orgID,
            organizationTitle: orgTitle,
            addorganization: false,
          });
        }
      } else {
        if (type === "contacts") {
          this.state.peopleList.push({
            id: newValue["id"],
            title: newValue["title"],
            email: email,
          });
        } else if (type === "organization") {
          this.setState({
            organizationID: newValue["id"],
            organizationTitle: newValue["title"],
            addorganization: false,
          });
        } else if (type === "estimates") {
          this.state.estimatesList.push({
            id: newValue["id"],
            title: newValue["projectName"],
          });
        }
      }
    }
    setTimeout(() => {
      this.setState({
        listContactType: "",
        action: "",
      });
    }, 100);
  }, 300);

  //Save
  saveItem = () => {
    const companyId = localStorage.getItem('companyId');
    if (
      (this.state.organizationName === "" ||
        this.state.organizationName.substring(0) === " ") &&
      this.state.contacttype === "1"
    ) {
      this.setState({ errorStateOrgName: true });
    } else if (
      (this.state.organizationName !== "" ||
        this.state.organizationName.substring(0) !== " ") &&
      this.state.contacttype === "1"
    ) {
      this.props.closeDrawer(false);
      const listRef = fire.database().ref(companyId).child("organizations");
      const itemData = {
        title: this.state.organizationName,
        date: Moment(new Date()).format("MM/DD/YYYY"),
        contactType: "organization",
        location: this.state.organizationLocation,
        street: this.state.organizationStreet,
        suite: this.state.organizationSuite,
        city: this.state.organizationCity,
        state: this.state.organizationState,
        pin: this.state.organizationPin,
        people: this.state.peopleList,
        archive: "false",
        note: "",
        estimates: this.state.estimatesList,
      };
      listRef.push(itemData);
      setTimeout(() => {
        for (let i = 0; i < this.state.arrayItem.length; i++) {
          if (
            this.state.arrayItem[i]["title"] === this.state.organizationName
          ) {
            const peopleData = this.state.arrayItem[i]["people"];
            if (peopleData) {
              for (let p = 0; p < peopleData.length; p++) {
                const peoplelistRef = fire
                  .database().ref(companyId).child("people")
                  .child(peopleData[p]["id"]);
                peoplelistRef.update({
                  organization: this.state.arrayItem[i]["id"],
                });
              }
            }
            const estimates = this.state.arrayItem[i]["estimates"];
            if (estimates) {
              for (let p = 0; p < estimates.length; p++) {
                const estimateRef = fire
                  .database().ref(companyId).child("estimates")
                  .child(estimates[p]["id"]);
                estimateRef.update({
                  organization: this.state.arrayItem[i]["title"],
                });
              }
            }
          }
        }
      }, 1000);

      setTimeout(() => {
        this.setState({
          saveInfo: false,
          drawerType: "",
          peopleList: [],
          organizationList: [],
          newPeopleList: [],
          neworganizationList: [],
          addorganization: false,
          addPerson: false,
          errorStateOrgName: false,
          errorStatePeopleName: false,
          emailLabel: "Email Address",
          estimatesList: [],
        });
        this.peopleDropdownList = [...this.state.arrayItemPeople];
        this.closeHandler();
      }, 500);
    }

    if (
      (this.state.peopleFullName === "" ||
        this.state.peopleFullName.substring(0) === " ") &&
      this.state.contacttype === "2"
    ) {
      this.setState({ errorStatePeopleName: true });
    } else if (
      (this.state.peopleFullName !== "" ||
        this.state.peopleFullName.substring(0) !== " ") &&
      this.state.contacttype === "2"
    ) {
      this.props.closeDrawer(false);
      const listRef = fire.database().ref(companyId).child("people");
      const itemData = {
        title: this.state.peopleFullName,
        date: Moment(new Date()).format("MM/DD/YYYY"),
        contactType: "People",
        jobTitle: this.state.peopleJobTitle,
        email: this.state.peopleEmail,
        phone: this.state.peoplePhoneNumber,
        archive: "false",
        organization: this.state.organizationID,
        note: "",
        estimates: this.state.estimatesList,
      };
      listRef.push(itemData);
      setTimeout(() => {
        for (let i = 0; i < this.state.arrayItemPeople.length; i++) {
          if (
            this.state.arrayItemPeople[i]["title"] === this.state.peopleFullName
          ) {
            const orgData = this.state.arrayItemPeople[i]["organization"];
            if (orgData) {
              const arr = [
                {
                  email: this.state.arrayItemPeople[i]["email"],
                  id: this.state.arrayItemPeople[i]["id"],
                  title: this.state.arrayItemPeople[i]["title"],
                },
              ];
              const peoplelistRef = fire
                .database().ref(companyId).child("organizations")
                .child(orgData);
              peoplelistRef.update({
                people: arr,
              });
            }
          }
        }
      }, 1000);

      setTimeout(() => {
        this.setState({
          saveInfo: false,
          drawerType: "",
          peopleList: [],
          organizationList: [],
          newPeopleList: [],
          neworganizationList: [],
          addorganization: false,
          addPerson: false,
          errorStateOrgName: false,
          errorStatePeopleName: false,
          emailLabel: "Email Address",
          estimatesList: [],
        });
        this.closeHandler();
        this.peopleDropdownList = [...this.state.arrayItemPeople];
      }, 500);
    }
  };
  componentDidMount = () => {
    const companyId = localStorage.getItem('companyId');
    const searchItemTable = localStorage.getItem("searchItemTable");
    //Fetch organization data
    const orgArray = [];
    const organizationListRef = fire.database().ref(companyId).child("organizations");
    organizationListRef.once("value").then((snapshot) => {
      const listOrg = snapshot.val();
      organizationListRef.off("value");
      const arrOrg = [];
      for (const id in listOrg) {
        arrOrg.unshift({ id, ...listOrg[id] });
        orgArray.unshift({ id, ...listOrg[id] });
      }
      this.setState({ arrayItem: arrOrg, loader: false });
      if (searchItemTable === "organizations") {
        this.searchItem();
      }
      //fetch people data
      const peopleListRef = fire.database().ref(companyId).child("people");
      peopleListRef.once("value").then((snapshot) => {
        const listPeople = snapshot.val();
        peopleListRef.off("value");
        const arrPeople = [];
        for (const id in listPeople) {
          arrPeople.unshift({ id, ...listPeople[id] });
        }
        for (let i = 0; i < orgArray.length; i++) {
          for (let l = 0; l < arrPeople.length; l++) {
            if (arrPeople[l]["organization"]) {
              if (orgArray[i]["id"] === arrPeople[l]["organization"]) {
                arrPeople[l]["organizationName"] = orgArray[i]["title"];
              }
            }
          }
        }
        this.setState({ arrayItemPeople: arrPeople });
        this.peopleDropdownList = [...this.state.arrayItemPeople];
        if (searchItemTable === "people") {
          this.searchItem();
        }
        setTimeout(() => {
          localStorage.setItem("searchText", "");
        }, 500);
      });
    });

    //Fetch estimates data
    const estimateListRef = fire.database().ref(companyId).child("estimates");
    estimateListRef.once("value").then((snapshot) => {
      const listEstimate = snapshot.val();
      estimateListRef.off("value");
      const arrEstimate = [];
      for (const id in listEstimate) {
        arrEstimate.push({ id, ...listEstimate[id] });
      }
      this.setState({ arrayItemEstimate: arrEstimate });

      this.estimateDropdownList = [...arrEstimate];
    });
  };
  searchItem = () => {
    const searchItemID = localStorage.getItem("searchItemID");
    const searchText = localStorage.getItem("searchText");
    const searchItemTable = localStorage.getItem("searchItemTable");
    if (searchItemID !== "" && searchItemTable !== "") {
      if (searchItemTable === "organizations") {
        this.setState({
          tabValue: "1",
          key: searchText,
          page: 1,
          searchTextOrg: searchText,
        });
      } else {
        this.setState({
          tabValue: "2",
          keyPeople: searchText,
          pagePeople: 1,
          searchTextPpl: searchText,
        });
      }
    }
    setTimeout(() => {
      localStorage.setItem("searchText", "");
      localStorage.setItem("searchItemID", "");
      localStorage.setItem("searchItemTable", "");
    }, 1000);
  };
  handlesearchEstimate = () => {
    localStorage.setItem("searchText", this.state.organizationName);
  };
  handleCreate = (open, type, button) => {
    this.setState({ drawerState: open, drawerType: type });
    if (button === "People") {
      this.setState({ contacttype: "2" });
    }
  };
  closeDrawer = () => {
    if (this.state.contacttype === "1") {
      if (this.state.drawerType === "new") {
        if (this.state.organizationName !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else {
          this.closeHandler();
        }
      } else if (this.state.drawerType === "old") {
        if (
          this.state.organizationName !== this.state.defaultorganizationName
        ) {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.organizationLocation !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.organizationStreet !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.organizationSuite !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.organizationCity !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.organizationPin !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.organizationNote !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (
          this.state.organizationStateDefault !== this.state.organizationState
        ) {
          this.setState({
            dialogOpen: true,
          });
        } else if (
          this.state.peopleListDefault.length !== this.state.peopleList.length
        ) {
          this.setState({
            dialogOpen: true,
          });
        } else if (
          this.state.estimatesListDefault.length !==
          this.state.estimatesList.length
        ) {
          this.setState({
            dialogOpen: true,
          });
        } else {
          this.closeHandler();
        }
      }
    }
    if (this.state.contacttype === "2") {
      if (this.state.drawerType === "new") {
        if (this.state.peopleFullName !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else {
          this.closeHandler();
        }
      } else if (this.state.drawerType === "old") {
        if (this.state.peopleFullName !== this.state.defaultPeopleFullName) {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.peopleJobTitle !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.peopleEmail !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.peoplePhoneNumber !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (this.state.peopleNote !== "") {
          this.setState({
            dialogOpen: true,
          });
        } else if (
          this.state.estimatesListDefault.length !==
          this.state.estimatesList.length
        ) {
          this.setState({
            dialogOpen: true,
          });
        } else if (
          this.state.organizationTitleDefault !== this.state.organizationTitle
        ) {
          this.setState({
            dialogOpen: true,
          });
        } else {
          this.closeHandler();
        }
      }
    }
    this.props.closeDrawer();
  };
  saveContactHandler = () => {
    this.setState({ dialogOpen: false });
    this.saveItem();
  };
  render() {
    const { classes } = this.props;
    const listToShow = this.state.arrayItem;
    const listToShowPeople = this.state.arrayItemPeople;
    const estimatesList = this.state.estimatesList;
    const estimateDropdownList = this.estimateDropdownList;
    const peopleList = this.state.peopleList;
    const peopleDropdownList = this.peopleDropdownList;

    if (listToShowPeople.length > 0) {
      let newIndex = "";
      for (let i = 0; i < peopleList.length; i++) {
        for (let l = 0; l < peopleDropdownList.length; l++) {
          if (peopleList[i]["title"] === peopleDropdownList[l]["title"]) {
            newIndex = l;
            peopleDropdownList.splice(newIndex, 1);
          }
        }
      }
    }
    if (estimatesList.length > 0) {
      let newIndex = "";
      for (let i = 0; i < estimatesList.length; i++) {
        for (let l = 0; l < estimateDropdownList.length; l++) {
          if (
            estimatesList[i]["title"] === estimateDropdownList[l]["projectName"]
          ) {
            newIndex = l;
            estimateDropdownList.splice(newIndex, 1);
          }
        }
      }
    }
    if (this.state.removePeople === true) {
      let newIndex = "";
      for (let l = 0; l < this.state.peopleList.length; l++) {
        if (this.state.peopleList[l]["id"] === this.state.peopleRemoveKey) {
          newIndex = l;
          this.state.peopleList.splice(newIndex, 1);
        }
      }
    }
    if (this.state.removeEstimate === true) {
      let newIndex = "";
      for (let l = 0; l < this.state.estimatesList.length; l++) {
        if (
          this.state.estimatesList[l]["id"] === this.state.estimateRemoveKey
        ) {
          newIndex = l;
          this.state.estimatesList.splice(newIndex, 1);
        }
      }
    }

    const removeItem = this.removeItem;
    return (
      <React.Fragment>
        <Box>
          <AsideDrawer
            toolbarTitle="Add Contact"
            open={this.props.contactDrawer}
            close={this.closeDrawer}
          >
            <Box className={classes.tabBox}>
              <Typography className={classes.label} id="resource-type">
                Contact Type
              </Typography>
              <Box className={classes.sideTabwrap}>
                <TabContext value={this.state.contacttype}>
                  <TabList aria-label="tabs" className={classes.tabOuter}>
                    <Tab
                      label="Organization"
                      value="1"
                      onClick={() => this.contactTypeHandle("1")}
                    />
                    <Tab
                      label="Person"
                      value="2"
                      onClick={() => this.contactTypeHandle("2")}
                    />
                  </TabList>

                  <TabPanel value="1">
                    <Box>
                      <Typography
                        className={classes.headlabel}
                        id="name"
                        style={{ textTransform: "uppercase" }}
                      >
                        Organization Name
                      </Typography>
                      <InputField
                        label=" Name"
                        aria-labelledby="name"
                        required
                        className={
                          this.state.errorStateOrgName === true && "errorInput"
                        }
                        onChange={(e) =>
                          this.handleorganizationName(e.target.value)
                        }
                      />
                      <Typography className={classes.label} id="address">
                        Address
                      </Typography>

                      <Grid container>
                        <Grid item xs={12}>
                          <InputField
                            label="Location Name"
                            aria-labelledby="address"
                            onChange={(e) =>
                              this.handleorganizationLocation(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={8} style={{ padding: "0 10px 0 0px" }}>
                          <InputField
                            label="Street"
                            aria-labelledby="address"
                            onChange={(e) =>
                              this.handleorganizationStreet(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputField
                            label="Suite #"
                            aria-labelledby="address"
                            onChange={(e) =>
                              this.handleorganizationSuite(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={{ padding: "0 10px 0 0px" }}>
                          <InputField
                            label="City"
                            aria-labelledby="discipline"
                          // value={this.state.organizationCity}
                          // change={this.handleorganizationCity}
                          />
                        </Grid>
                        <Grid item xs={3} style={{ padding: "0 10px 0 0px" }}>
                          <MenuDropdown
                            className={classes.asideMenuDropdown}
                            selectInputProps={{
                              classes: {
                                root: classes.asidesubMenuDropdownInput,
                                icon: classes.asideMenuDropdownIcon,
                              },
                            }}
                            aria-labelledby="discipline"
                            value={this.state.organizationState}
                            change={this.handleorganizationState}
                          >
                            <MenuItem value="">State</MenuItem>
                            <MenuItem value="AL">Alabama</MenuItem>
                            <MenuItem value="AK">Alaska</MenuItem>
                            <MenuItem value="AZ">Arizona</MenuItem>
                            <MenuItem value="AR">Arkansas</MenuItem>
                            <MenuItem value="CA">California</MenuItem>
                            <MenuItem value="CO">Colorado</MenuItem>
                            <MenuItem value="CT">Connecticut</MenuItem>
                            <MenuItem value="DE">Delaware</MenuItem>
                            <MenuItem value="DC">District Of Columbia</MenuItem>
                            <MenuItem value="FL">Florida</MenuItem>
                            <MenuItem value="GA">Georgia</MenuItem>
                            <MenuItem value="HI">Hawaii</MenuItem>
                            <MenuItem value="ID">Idaho</MenuItem>
                            <MenuItem value="IL">Illinois</MenuItem>
                            <MenuItem value="IN">Indiana</MenuItem>
                            <MenuItem value="IA">Iowa</MenuItem>
                            <MenuItem value="KS">Kansas</MenuItem>
                            <MenuItem value="KY">Kentucky</MenuItem>
                            <MenuItem value="LA">Louisiana</MenuItem>
                            <MenuItem value="ME">Maine</MenuItem>
                            <MenuItem value="MD">Maryland</MenuItem>
                            <MenuItem value="MA">Massachusetts</MenuItem>
                            <MenuItem value="MI">Michigan</MenuItem>
                            <MenuItem value="MN">Minnesota</MenuItem>
                            <MenuItem value="MS">Mississippi</MenuItem>
                            <MenuItem value="MO">Missouri</MenuItem>
                            <MenuItem value="MT">Montana</MenuItem>
                            <MenuItem value="NE">Nebraska</MenuItem>
                            <MenuItem value="NV">Nevada</MenuItem>
                            <MenuItem value="NH">New Hampshire</MenuItem>
                            <MenuItem value="NJ">New Jersey</MenuItem>
                            <MenuItem value="NM">New Mexico</MenuItem>
                            <MenuItem value="NY">New York</MenuItem>
                            <MenuItem value="NC">North Carolina</MenuItem>
                            <MenuItem value="ND">North Dakota</MenuItem>
                            <MenuItem value="OH">Ohio</MenuItem>
                            <MenuItem value="OK">Oklahoma</MenuItem>
                            <MenuItem value="OR">Oregon</MenuItem>
                            <MenuItem value="PA">Pennsylvania</MenuItem>
                            <MenuItem value="RI">Rhode Island</MenuItem>
                            <MenuItem value="SC">South Carolina</MenuItem>
                            <MenuItem value="SD">South Dakota</MenuItem>
                            <MenuItem value="TN">Tennessee</MenuItem>
                            <MenuItem value="TX">Texas</MenuItem>
                            <MenuItem value="UT">Utah</MenuItem>
                            <MenuItem value="VT">Vermont</MenuItem>
                            <MenuItem value="VA">Virginia</MenuItem>
                            <MenuItem value="WA">Washington</MenuItem>
                            <MenuItem value="WV">West Virginia</MenuItem>
                            <MenuItem value="WI">Wisconsin</MenuItem>
                            <MenuItem value="WY">Wyoming</MenuItem>
                          </MenuDropdown>
                        </Grid>
                        <Grid item xs={4}>
                          <InputField
                            label="Zip"
                            aria-labelledby="address"
                            onChange={(e) =>
                              this.handleorganizationPin(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Typography className={classes.label} id="people">
                        People
                      </Typography>
                      <Grid item xs={12}>
                        <List className={classes.peopleInfo}>
                          {peopleList &&
                            peopleList.map(function (item) {
                              return (
                                <AddContact
                                  key={item.id}
                                  keyite={item.id}
                                  title={item.title}
                                  email={item.email}
                                  icon={
                                    <PermContactCalendarOutlinedIcon htmlColor="#000" />
                                  }
                                  contacttype={"people"}
                                  removeItem={removeItem}
                                />
                              );
                            })}
                        </List>
                      </Grid>
                      {this.state.addPerson === true && (
                        <Box className={classes.iconSelectbox}>
                          <Typography className={classes.iconOuter}>
                            {" "}
                            <PermContactCalendarOutlinedIcon />{" "}
                          </Typography>
                          <AutocompleteContact
                            selectedValue={this.handleAutoContact}
                            labelName="Person’s Name"
                            class={classes.autoMenuDropdown}
                            idName="select-on-focus-company"
                            type="contacts"
                            nameList={peopleDropdownList}
                          />
                        </Box>
                      )}
                      {this.state.addPerson === false && (
                        <Grid item xs={12}>
                          <Button
                            className={classes.addWrap}
                            onClick={this.showAddPerson}
                          >
                            Add Person
                          </Button>
                        </Grid>
                      )}
                    </Box>
                    <Box className={classes.buttonWrap}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          className={classes.label}
                          id="people"
                          style={{ paddingRight: "10px" }}
                        >
                          Estimates
                        </Typography>
                        <Typography>
                          <Link
                            href="/estimates"
                            style={{ color: "#084d4f", fontSize: 12 }}
                            onClick={this.handlesearchEstimate()}
                          >
                            {" "}
                            See All Estimates
                          </Link>
                        </Typography>
                      </Grid>
                      {/* Estimates */}
                      <Grid item xs={12}>
                        <List className={classes.peopleInfo}>
                          {estimatesList &&
                            estimatesList.map(function (item) {
                              return (
                                <AddContact
                                  key={item.id}
                                  keyite={item.id}
                                  title={item.title}
                                  icon={<CalculatorIcon htmlColor="#000" />}
                                  contacttype={"estimates"}
                                  removeItem={removeItem}
                                />
                              );
                            })}
                        </List>
                      </Grid>
                      {this.state.addEstimate === true && (
                        <Box className={classes.iconSelectbox}>
                          <Typography className={classes.iconOuter}>
                            {" "}
                            <PermContactCalendarOutlinedIcon />{" "}
                          </Typography>
                          <AutoCompleteEstimatic
                            selectedValue={this.handleAutoContact}
                            labelName="Estimate Name"
                            class={classes.autoMenuDropdown}
                            idName="select-on-focus-company"
                            type="estimates"
                            nameList={estimateDropdownList}
                          />
                        </Box>
                      )}
                      {this.state.addEstimate === false && (
                        <Grid item xs={12}>
                          <Button
                            className={classes.addWrap}
                            onClick={this.showAddEstimate}
                          >
                            Add Estimate
                          </Button>
                        </Grid>
                      )}
                      <DefaultButton
                        variant="contained"
                        onClick={this.saveItem}
                      >
                        Save
                      </DefaultButton>
                    </Box>
                  </TabPanel>
                  <TabPanel value="2">
                    <Typography className={classes.headlabel} id="name">
                      PERSONAL INFORMATION
                    </Typography>

                    <Box>
                      {/*//add errorInput here*/}
                      <InputField
                        label="Full Name*"
                        aria-labelledby="Full Name*"
                        className={
                          this.state.errorStatePeopleName === true &&
                          "errorInput"
                        }
                        onChange={(e) =>
                          this.handlePeopleFullName(e.target.value)
                        }
                      />

                      <InputField
                        label="Job Title"
                        aria-labelledby="Job Tile"
                        onChange={(e) =>
                          this.handlePeopleJobTitle(e.target.value)
                        }
                      />

                      <InputField
                        className={`${this.state.emailLabel !== "Email Address"
                          ? "errorState"
                          : ""
                          }`}
                        label={`${this.state.emailLabel !== "Email Address"
                          ? this.state.emailLabel
                          : "Email Address"
                          }`}
                        aria-labelledby="Email Address"
                        onChange={(e) => this.handlePeopleEmail(e.target.value)}
                      />

                      <TextField
                        label="Phone Number"
                        aria-labelledby="Phone Number"
                        type="number"
                        variant="outlined"
                        className={classes.numberBox}
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          this.handlePeoplePhoneNumber(e.target.value)
                        }
                      />
                    </Box>

                    <Typography className={classes.label} id="people">
                      Organization
                    </Typography>
                    <Grid item xs={12}>
                      <List className={classes.peopleInfo}>
                        {this.state.organizationTitle && (
                          <AddContact
                            key={this.state.organizationID}
                            keyite={this.state.organizationID}
                            title={this.state.organizationTitle}
                            icon={<DomainIcon htmlColor="#000" />}
                            removeItem={removeItem}
                            contacttype={"organization"}
                          />
                        )}
                      </List>
                    </Grid>
                    {this.state.addorganization === true &&
                      this.state.organizationID === "" && (
                        <Box className={classes.iconSelectbox}>
                          <Typography className={classes.iconOuter}>
                            {" "}
                            <DomainIcon />{" "}
                          </Typography>

                          <AutocompleteContact
                            selectedValue={this.handleAutoContact}
                            labelName="Organization Name"
                            idName="select-on-focus-company"
                            type="organization"
                            nameList={listToShow}
                          />
                        </Box>
                      )}
                    {this.state.addorganization === false &&
                      this.state.organizationID === "" && (
                        <Grid item xs={12}>
                          <Button
                            className={classes.addWrap}
                            onClick={this.showAddorganization}
                          >
                            Add Organization
                          </Button>
                        </Grid>
                      )}
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        className={classes.label}
                        id="people"
                        style={{ paddingRight: "10px" }}
                      >
                        Estimates
                      </Typography>
                      <Typography>
                        <Link
                          href="/estimates"
                          style={{ color: "#084d4f", fontSize: 12 }}
                          onClick={this.handlesearchEstimate()}
                        >
                          {" "}
                          See All Estimates
                        </Link>
                      </Typography>
                    </Grid>
                    {/* Estimates */}
                    <Grid item xs={12}>
                      <List className={classes.peopleInfo}>
                        {estimatesList &&
                          estimatesList.map(function (item) {
                            return (
                              <AddContact
                                key={item.id}
                                keyite={item.id}
                                title={item.title}
                                icon={<CalculatorIcon htmlColor="#000" />}
                                contacttype={"estimates"}
                                removeItem={removeItem}
                              />
                            );
                          })}
                      </List>
                    </Grid>
                    {this.state.addEstimate === true && (
                      <Box className={classes.iconSelectbox}>
                        <Typography className={classes.iconOuter}>
                          {" "}
                          <PermContactCalendarOutlinedIcon />{" "}
                        </Typography>
                        <AutoCompleteEstimatic
                          selectedValue={this.handleAutoContact}
                          labelName="Estimate Name"
                          class={classes.autoMenuDropdown}
                          idName="select-on-focus-company"
                          type="estimates"
                          nameList={estimateDropdownList}
                        />
                      </Box>
                    )}
                    {this.state.addEstimate === false && (
                      <Grid item xs={12}>
                        <Button
                          className={classes.addWrap}
                          onClick={this.showAddEstimate}
                        >
                          Add Estimate
                        </Button>
                      </Grid>
                    )}
                    <Box className={classes.buttonWrap}>
                      <DefaultButton
                        variant="contained"
                        onClick={this.saveItem}
                      >
                        Save
                      </DefaultButton>
                    </Box>
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>
          </AsideDrawer>
        </Box>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Contacts);
