import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    estimateBackup: {},
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

// asynchronous function with createAsyncThunk
export const getEstimateBackupAsync = createAsyncThunk(
    'estimateBackup/getEstimateBackup',
    async (query = {}) => {
        const { companyId, estimateKey, dataType } = query;
        const ref = fire.database().ref(companyId).child('backups').child(dataType).child(estimateKey);
        const refsnapshot = await ref.once("value");
        const estimateBackup = refsnapshot.val() || {};
        ref.off("value");
        return { estimateBackup };
    }
);

// Redux Toolkit slice
export const getEstimateBackupSlice = createSlice({
    name: 'estimateBackup',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEstimateBackupAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEstimateBackupAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.estimateBackup = action.payload.estimateBackup
            });
    },
});
export default getEstimateBackupSlice.reducer;
