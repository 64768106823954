import React from 'react';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import formatMoneyStrFriendly from '../../../libs/formatMoneyStrFriendly';
import formatHour from '../../../libs/formatHour';
export default function PhaseTotals({ totalPhaseHours, totalPhaseCost, totalPhasePrice, shared, opacity }) {
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
    return (
        <div className="col s12 l6 m6 counting-phase">
            <div key="1">
                <span>Hours</span>
                <div style={{ opacity }}>
                    <Typography variant="h4" component="h4" id="totalPhaseHours">
                        {formatHour({ number: totalPhaseHours })}
                    </Typography>
                </div>
            </div>
            {shared !== true && <div key="2">
                <span>Cost</span>
                <div style={{ opacity }}>
                    <Tooltip title={totalPhaseCost === 0 || totalPhaseCost < 99999 ? "" : "$" + formatter.format(totalPhaseCost)} placement="top-end">
                        {formatMoneyStrFriendly({ number: totalPhaseCost, numberFormat: true, beFriendly: true, maximumFractionDigits: 0 })}
                    </Tooltip>
                </div>
            </div>}
            <div key="3">
                <span>Price</span>
                <div style={{ opacity }}>
                    <Tooltip title={totalPhasePrice === 0 || totalPhasePrice < 99999 ? "" : "$" + formatter.format(totalPhasePrice)} placement="top-end">
                        {formatMoneyStrFriendly({ number: totalPhasePrice, numberFormat: true, beFriendly: true, maximumFractionDigits: 0 })}
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}