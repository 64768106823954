import React, { useState } from 'react';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import estimatic_logo from '../../assets/images/Estimatic_Logo.svg';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import robo_form from '../../assets/images/robo-form.svg';
import queryString from 'query-string';
import fire from '../../config/firebase';
import { Link } from "react-router-dom";
import { getFunctionUrl } from '../../config/firebase';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '50px 0',
    alignItems: 'center',
    height: 'calc(100vh - 60px)',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  img_wrap: {
    maxWidth: '200px',
    width: '100%'
  },
  anchor: {
    borderRadius: '5px',
    backgroundColor: '#e70a89',
    background: '#e70a89!important',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.36',
    letterSpacing: '0.14px',
    textAlign: 'center',
    width: '100%',
    color: '#fff',
    textTransform: 'inherit',
    boxShadow: 'none',
    padding: '10px',
    '&:hover': {
      boxShadow: 'none',
      background: '#d00a7b !important',
    }
  },
  large_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: '#fff',
    padding: '55px 50px !important'

  },
  intro_wrap: {
    fontSize: '20px',
    fontWeight: "100",
    paddingTop: '15px',
    marginBottom: '60px'

  },
  text_wrap: {
    fontSize: '14px',
    maxWidth: '260px',
    margin: '7px 0 18px',
    display: 'block',
    float: 'none !important'

  },
  small_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderLeft: '0',
    borderRadius: '5px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    backgroundColor: '#fff',
    padding: '55px 50px 30px !important',
    maxWidth: '430px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },
  btn_wrap: {
    background: '#e70a89!important',
    width: '100%',
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'inherit',
    boxShadow: 'none',
    minHeight: '40px',
    '&:hover': {
      boxShadow: 'none',
      background: '#d00a7b !important',
    }
  },
  wrap_item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px'
  },
  bottom_item: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  success_heading: {
    marginBottom: '15px !important'
  },
  success_para: {
    fontSize: '14px',
    lineHeight: '1.36',
    marginBottom: '20px'
  }
}));
function ResetPassword(props) {
  const { email, logintoken } = queryString.parse(props.location.search);
  const [errors, setErrors] = useState([]);
  const success = false;
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };
  const checkPassword = (pass) => {
    const errors = [];
    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(pass)) {
      errors.push('whitespace');
    }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(pass)) {
      errors.push("Uppercase");
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(pass)) {
      errors.push("Lowercase");
    }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(pass)) {
      errors.push("Digit");
    }

    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    if (!isContainsSymbol.test(pass)) {
      errors.push("Special");
    }

    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(pass)) {
      errors.push("Length");
    }

    setErrors(errors);
    return errors.length === 0;

  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitPass = (e) => {
    e.preventDefault();
    if (checkPassword(values.password) === false) {
      return false;
    }
    axios.get(`${getFunctionUrl()}/changepass?email=${encodeURIComponent(email)}&logintoken=${logintoken}&newpass=${values.password}&time=${new Date().getTime()}`,
      {
        headers: {
          'access-control-request-headers': 'content-type,x-client-version',
          'accept': '*/*'
        }
      },).then(() => {
        fire.auth().signInWithEmailAndPassword(email, values.password).then(async (authResult) => {
          const userCompanyRef = fire.database().ref('userCompany').child(authResult.user.uid);
          const userCompanySnap = await userCompanyRef.once('value');
          const userCompany = userCompanySnap.val();
          const { companyId } = userCompany || {};
          localStorage.setItem('companyId', companyId);
          props.history.push("/dashboard");
        }).catch(() => {
        });
      });
  }
  const getLi = (text, code) => {
    return <li style={{ color: errors.find(d => d === code) ? 'red' : '' }}>{text}
    </li>
  };
  return (

    <React.Fragment>
      <div className="login-form ">
        <div className={'form-wrap ' + classes.root}>

          {success === false ?
            (<Grid container spacing={3} justify="center" className="inner-wrap">
              <Grid item xs={5} className={"large-block " + classes.large_block}>
                <div className={classes.img_wrap}>
                  <img src={estimatic_logo} alt="Estimatic" />
                </div>
                <p className={classes.intro_wrap}>
                  In faucibus nulla vel urna fringilla congue. Pellentesque scelerisque condimentum enim a facilisis. Donec feugiat odio non.
                </p>
                <div className="img-wrap">
                  <img src={robo_form} alt="robot" />
                </div>
              </Grid>
              <Grid item xs={4} className={classes.small_block} style={{ paddingBottom: '40px', position: 'relative' }}>
                <form className="form">
                  <Typography variant="h6" component="h6" style={{ fontWeight: 300 }}>
                    Reset Password
                  </Typography>
                  <span className={classes.text_wrap} style={{ fontWeight: 300 }}>
                    Enter a new password for your Estimatic account:
                  </span>
                  <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      name="password"
                      onChange={handleChange('password')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                  <Typography style={{ fontWeight: 300 }}>
                    Password must:
                  </Typography>
                  <ol style={{ listStyle: 'square' }}>
                    {getLi('not contain Whitespaces.', 'whitespace')}
                    {getLi('have at least one Uppercase Character.', 'Uppercase')}
                    {getLi('have at least one Lowercase Character.', 'Lowercase')}
                    {getLi('have at least one Digit.', 'Digit')}
                    {getLi('have at least one Special Character.', 'Special')}
                    {getLi('8-16 Characters Long.', 'Length')}
                  </ol>
                  <Button style={{ fontWeight: 500 }}
                    variant="contained"
                    type="button"
                    className={classes.btn_wrap}
                    onClick={submitPass}>
                    Submit
                  </Button>
                </form>
                {/*{values.strength && (*/}
                {/*<Box className={classes.pwdBox} style={{ position: 'absolute', left: 0, bottom: 0, textAlign: 'center', width: '100%', padding: '0 51px 30px' }}>*/}
                {/*<Alert onClose={closeAlert} severity="warning">*/}
                {/*<AlertTitle style={{fontWeight: 300}}>Weak password</AlertTitle>*/}
                {/*</Alert>*/}
                {/*</Box>*/}
                {/*)}*/}
              </Grid>
            </Grid>
            )
            :
            (
              <Grid container spacing={3} justify="center" className="inner-wrap">
                <Grid item xs={5} className={"large-block " + classes.large_block}>
                  <div className={classes.img_wrap}>
                    <img src={estimatic_logo} alt="Estimatic" />
                  </div>
                  <p className={classes.text_wrap}>
                    In faucibus nulla vel urna fringilla congue. Pellentesque scelerisque condimentum enim a facilisis. Donec feugiat odio non.
                  </p>
                  <div className="img-wrap">
                    <img src={robo_form} alt="robot" />
                  </div>
                </Grid>
                <Grid item xs={4} className={classes.small_block}>
                  <form className="form">
                    <Typography variant="h5" component="h5" className={classes.success_heading}>
                      Success!
                    </Typography>
                    <Typography variant="p" component="p" className={classes.success_para}>
                      Sign in using your new password
                    </Typography>
                    <Link variant="contained"
                      type="button" className={classes.anchor} to="/">Return to Login Screen</Link>

                  </form>
                </Grid>
              </Grid>

            )
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ResetPassword);