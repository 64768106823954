import React, { useEffect, useState, useRef, createRef } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import formatMoneyStrFriendly from '../../../libs/formatMoneyStrFriendly';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import * as getEstimateActionCreators from '../../../store/estimate/getEstimate';
import ClearIcons from "../../../libs/icons";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AlertDialog from "../AlertDialog/AlertDialog";

const ExpenseItem = ({ expense, qtyUpdate, duplicate, toggle, deleteExpense, expenseExclude }) => {
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
    const [toggleMenu, setMenu] = useState(false);
    const [enableClickAway, setEnableClickAway] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const shared = false
    const wrapperRef = useRef();
    const toggleRef = useRef()
    const [qty, setQty] = useState(expense.qty)
    toggleRef.current = toggleMenu

    const openDrop = () => {
        if (!toggleMenu) setMenu(true);
    }
    const isDigit = (inp = '') => {
        const valid = ['$', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
        for (let i = 0; i < inp.length; i++) {
            if (valid.includes(inp[i]) === false) {
                ;
                return false;
            }
        }
        return true;
    }
    const qtyIncrease = () => {
        const val = parseInt(qty || 0) + 1
        setQty(val)
        qtyUpdate(expense, val);
    }
    const qtyDecrease = () => {
        if (qty === 1) { return false; }
        const val = parseInt(qty) - 1
        setQty(val)
        qtyUpdate(expense, val)
    }
    const qtyChange = (num) => {
        const val = parseInt(num);
        if (val < 1) {
            if (qty !== 1) {
                qtyUpdate(expense, 1);
            }
        } else {
            if (qty !== val) {
                qtyUpdate(expense, val);
            }
        }
    }

    const closeDrop = (event) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            if (toggleRef) setMenu(false);
        }
    }

    useEffect(() => {
        document.addEventListener("click", closeDrop)
        return () => {
            document.removeEventListener("click", closeDrop)
        }
    }, [])

    return (
        <div className={'col l12 expense-values'}>
            <div className={expense.exclude ? "excludeExpense input-field col s12 l8 m8" : "input-field col s12 l6 m6"}>
                <Tooltip title={expense.expenseNotes && `Description:${expense.expenseNotes}`} placement='top-start'>
                    <Typography variant="h6" component="h6">
                        {expense.expenseName}
                    </Typography>
                </Tooltip>
            </div>

            <div className={expense.exclude ? "excludeExpense col s12 l4 m4 input-cost" : "col s12 l6 m6 input-cost"}>
                <div className="counting-expense-qty">
                    <div className="list expense-qty">
                        {/* <input type="number" readOnly min="1" value={expense.qty} /> */}
                        <ClickAwayListener onClickAway={enableClickAway ? () => {
                            console.log(`fire ClickAwayListener`);
                            setEnableClickAway(false);
                            qtyChange(qty)
                            qtyUpdate(expense, qty);
                        } : () => { }}>
                            <input type="number" min="1" value={qty}
                                onChange={(e) => {
                                    console.log(`fire Changed`);
                                    setEnableClickAway(true);
                                    if (isDigit(e.target.value) === false) {
                                        return false;
                                    }
                                    else {
                                        const val = e.target.value > 1 ? parseInt(e.target.value) : 1
                                        setQty(val);
                                    }
                                }}
                                onKeyPress={(event) => {
                                    if (event.charCode === 13 && qty) {
                                        setEnableClickAway(true);
                                        qtyChange(qty)
                                        qtyUpdate(expense, qty)
                                        event.target.blur();
                                    }
                                }}
                            />
                        </ClickAwayListener>
                        {shared !== true &&
                            (qty < 2 ?
                                <DeleteOutlinedIcon
                                    htmlColor="#000000"
                                    fontSize="small"
                                    className="delete"
                                    onClick={() => {
                                        setDialogOpen(true);
                                    }}
                                />
                                :
                                <RemoveIcon
                                    htmlColor="#000000"
                                    fontSize="small"
                                    className="delete"
                                    onClick={() => {
                                        qtyDecrease();
                                        setEnableClickAway(true);
                                    }}
                                />)
                        }
                        {shared !== true &&
                            <AddIcon
                                htmlColor="#000000"
                                fontSize="small"
                                className="add"
                                onClick={() => {
                                    qtyIncrease();
                                    setEnableClickAway(true);
                                }
                                } />
                        }
                    </div>
                </div >
                <div className="list expense-cost">
                    <Tooltip title={expense.cost * expense.qty === 0 || expense.cost * expense.qty < 99999 ? "" : "$" + formatter.format(expense.cost * expense.qty)} placement="top-end">
                        {formatMoneyStrFriendly({ number: expense.cost * expense.qty, numberFormat: true, beFriendly: true, maximumFractionDigits: 0 })}
                    </Tooltip>
                </div>
                <MoreVertOutlinedIcon
                    htmlColor="#000"
                    onClick={openDrop}
                    ref={wrapperRef}
                    className={toggleMenu ? "expense-hover active" : "expense-hover"}
                />
            </div >
            {
                toggleMenu && (
                    <ul className="expenseMenu">
                        <li
                            className="expenseEdit"
                            onClick={() => toggle(expense.expenseId)}
                        >
                            <span className="img-box">
                                <DeleteOutlineOutlinedIcon htmlColor="#000" />
                            </span>
                            Edit
                        </li>
                        <li
                            className="expenseDuplicate"
                            onClick={() => duplicate(expense)}
                        >
                            <span className="img-box">
                                <FilterNoneIcon
                                    htmlColor="#000"
                                    className="mirror_filter"
                                />
                            </span>
                            Duplicate
                        </li>
                        <li
                            className="expenseDelete"
                            onClick={() => deleteExpense(expense.expenseId)}
                        >
                            <span className="img-box">
                                <DeleteOutlineOutlinedIcon htmlColor="#000" />
                            </span>
                            Delete
                        </li>
                        <li
                            className="expenseEclude"
                            onClick={(e) => expenseExclude(expense)}
                        >
                            <span className="img-box" >
                                {expense.exclude ? <ClearIcons
                                    htmlColor="#000"
                                    className="clear_icon"
                                    type="exclude" />
                                    :
                                    <ClearIcons
                                        htmlColor="#000"
                                        className="clear_icon"
                                        type="include" />
                                }
                            </span>
                            {expense.exclude ? 'Include' : 'Exclude'}
                        </li>

                    </ul>
                )
            }
            <AlertDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                keyValue={deleteExpense}
                name={"expense"}
                phaseKey={expense.expenseId}
            />
        </div >

    )
}
function MapStateToProps(state) {
    return {
        estimate: state.getEstimate.estimate,
    };
}
function MapDispatchToProps(dispatch) {
    return {
        getEstimateAction: bindActionCreators(
            getEstimateActionCreators,
            dispatch
        ),
    };
}
export default compose(
    withRouter,
    connect(MapStateToProps, MapDispatchToProps)
)(ExpenseItem);
