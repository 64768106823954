import React from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ResourceDrawer from "../resourceDrawer";
import ExpensesDrawer from "../small-components/expense-drawer";
import SideSection from "../small-components/SideSection";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Phase from "../small-components/Phase/Phase";
import MenuOpenIcon from "../../assets/images/menu-black.svg";
import menuopen_black from "../../assets/images/menu-open_black.svg";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import $ from "jquery";
import Sticky from "react-sticky-el";
import uuid from "react-uuid";
import fire from "../../config/firebase";
import { getCopyName } from "../../libs/getCopyName";
import { getOrderInt, orderItems, sortItems } from "../../libs/utils";
import NewDrawer from '../small-components/DuplicateTemplate/newDrawer'
import CostRateDrawer from "../small-components/DuplicateTemplate/costRateDrawer";
// import EditVendor from '../small-components/DuplicateTemplate/EditVendor';
import Moment from "moment";
import * as getEstimateActionCreators from '../../store/estimate/getEstimate';
const set = new Set();

class EstimateCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 0,
      countPhase: 0,
      expandSection: false,
      count: 0,
      newResourceName: "",
      newResourceType: "",
      newResourceData: {},
      drawerState: true,
      dialogOpen: false,
      ResourceDrawerOpen: false,
      EditVendorDrawer: false,
      EditCostRateDrawer: false,
      resource: null,
      drawerOnSuccess: null,
      drawerOnCancel: null,
      timestamp: 0,
      updateResourceFn: () => { },
      phasesOrdered: null
    };
    this.refreshData = this.refreshData.bind(this);
    this.createDuplicatePhase = this.createDuplicatePhase.bind(this);
    this.createPhase = this.createPhase.bind(this);
    this.reorderPhases = this.reorderPhases.bind(this);
    this.handleCtrlZ = this.handleCtrlZ.bind(this);
    this.getQuery = this.getQuery.bind(this);

    this.addPhase = this.addPhase.bind(this);
    this.updateEstimateTable = this.updateEstimateTable.bind(this);
    this.range = this.range.bind(this);

    // this.updateResourceHours = this.updateResourceHours.bind(this);
    // this.updateResourceName = this.updateResourceName.bind(this);

    this.deleteHandler = this.deleteHandler.bind(this);

    this.closeHandler = this.closeHandler.bind(this);
    this.sectionExpand = this.sectionExpand.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.drawerInfoUpdate = this.drawerInfoUpdate.bind(this);

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.deletePhase = this.deletePhase.bind(this);
    this.toggleEditDrawer = this.toggleEditDrawer.bind(this);
    this.toggleEditCostRateDrawer = this.toggleEditCostRateDrawer.bind(this);
    this.getInitPhase = this.getInitPhase.bind(this);
  }

  getQuery() {
    const companyId = localStorage.getItem('companyId');
    const arr = this.props.location.pathname.split('/');
    const estimateKey = arr[arr.length - 1];
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    return { dataType, estimateKey, companyId, shared: this.props.shared }
  }

  handleCtrlZ(event) {
    const query = this.getQuery();
    if (["INPUT", "TEXTAREA"].indexOf(event.target.tagName) > -1) {
      return;
    }
    const { ctrlKey, keyCode, metaKey } = event;
    if ((!!ctrlKey || !!metaKey) && keyCode === 90) {
      const undoData = JSON.parse(localStorage.getItem("undoData"));
      let spliceData = "";
      let phases = [];
      if (undoData.length > 0 && undoData[0]["action"] === "addPhase") {
        let flag = 0;
        if (this.props.estimate) {
          if (flag === 0) {
            flag = 1;
            phases = JSON.parse(JSON.stringify(this.props.estimates["phases"]));
            phases.splice(phases.length - 1, 1);
          }
        }
        if (undoData.length > 1) {
          spliceData = undoData.splice(0, 1);
        } else {
          spliceData = [];
        }
        let listRef = fire.database().ref(query.companyId).child("estimates/" + query.estimateKey);
        if (query.dataType === "templates") {
          listRef = fire.database().ref(query.companyId).child("templates/" + query.estimateKey);
        }

        listRef.update({
          phases: phases,
        });
        localStorage.setItem("undoData", JSON.stringify(spliceData));
      }
    }
  }
  async refreshData({ change = true, refresh = true } = {}, timestamp = new Date().getTime()) {
    const query = this.getQuery();
    if (!query.companyId) {
      return;
    }

    const estimateRef = fire.database().ref(query.companyId).child(`${query.dataType}/${query.estimateKey}`);
    if (change) {
      const user = fire.auth().currentUser.uid;
      const date = Moment(new Date()).format("h:mm a MMMM DD, YYYY")
      await estimateRef.update({ 'lastEditor': user, 'modified': date });
      // add get estimate
    }

    if (this.props.estimate) {
      const estimate = JSON.parse(JSON.stringify(this.props.estimate || {}));
      if (set.has(timestamp)) {
        return;
      }
      set.add(timestamp);

      if (estimate.phases) {
        this.setState({
          estimate,
          timestamp,
        });
      } else {
        if (estimate && !estimate.phases) {
          const phase = this.getInitPhase(estimate.phases)
          estimate.phases = [phase];
        }
        this.setState(
          {
            estimate,
          },
          () => this.updateEstimateTable({ phases: estimate.phases }, false)
        );
      }
      if (refresh) {
        const { getEstimateAsync } = this.props.getEstimateAction;
        getEstimateAsync(query);
      }
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.updateCnt !== this.props.updateCnt) {
      this.props.updateCnt === 1 ? this.refreshData({ change: false, refresh: false }) : this.refreshData({ change: true, refresh: false });
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleCtrlZ);
    $(document).on("input change", ".phase-name input", function () {
      const value = $(this).val();
      const index = $(this).parents(".phase-parent").index();
      $(
        "#scope .small-col .inner-content:eq(" + index + ") .phase-text span"
      ).text(value);
    });
    $(document).on("input change", ".taskName input", function () {
      const value = $(this).val();
      const index = $(this).parents(".task-count").index();
      const parentIndex = $(this).parents(".phase-parent").index();
      if (
        index >=
        $("#scope .small-col .inner-content:eq(" + parentIndex + ") li").length
      ) {
        $("#scope .small-col .inner-content:eq(" + parentIndex + ") ul").append(
          "<li><span>-" + value + "</span></li>"
        );
      } else {
        $(
          "#scope .small-col .inner-content:eq(" +
          parentIndex +
          ") li:eq(" +
          index +
          ") span"
        ).text(value);
      }
    });

    $(document).on(
      "click",
      ".summary-details .small-col .phase-text > span",
      function () {
        const text = $(this).parents(".inner-content").index();
        $(".phase-parent").each(function () {
          const valu = $(this).index();
          if (text === valu) {
            $("html, body").animate(
              {
                scrollTop: $(this).offset().top - 90,
              },
              500
            );
          }
        });
      }
    );
    $(document).on(
      "click",
      ".summary-details .small-col .phase-text > span",
      function () {
        const text = $(this).parents(".inner-content").index();
        $(".phase-parent").each(function () {
          const valu = $(this).index();
          if (text === valu) {
            $("html, body").animate(
              {
                scrollTop: $(this).offset().top - 90,
              },
              500
            );
          }
        });
      }
    );
    $(document).on(
      "click",
      ".summary-details .small-col .inner-content ul li",
      function () {
        const index = $(this).parents(".inner-content").index();
        const index1 = $(this).index();
        $(".phase-parent:eq(" + index + ") .task-count").each(function () {
          const valu = $(this).index();
          if (index1 === valu) {
            $("html, body").animate(
              {
                scrollTop: $(this).offset().top - 90,
              },
              500
            );
          }
        });
      }
    );
    $(document).on(
      "click",
      ".summary-details .small-col .inner-title .logo-wrap",
      function () {
        if ($(this).parents(".scope-tab").hasClass("hide-content")) {
          $(this).parents(".scope-tab").removeClass("hide-content");
          $(this)
            .parents(".scope-tab")
            .find(" .small-col .inner-title .text-outer")
            .fadeIn(1000);
          $(this)
            .parents(".scope-tab")
            .find(" .small-col .outer-content ")
            .fadeIn(1000);
        } else {
          $(this).parents(".scope-tab").addClass("hide-content");
          $(this)
            .parents(".scope-tab")
            .find(" .small-col .inner-title  .text-outer")
            .fadeOut(50);
          $(this)
            .parents(".scope-tab")
            .find(" .small-col .outer-content ")
            .fadeOut(50);
        }
      }
    );
    $(document).on("click", ".summary-details .tabs-wrap li", function () {
      $(".estimated-list .scope-tab .small-grid div").removeAttr("style");
    });
    // this.refreshData({ change: false, refresh: false });

    // To add animation on adding new phase div

    $(document).on('click', '.summary-details .scope-tab .plus-row button', function () {
      const phase_len = $('.summary-details .phase-parent').length - 1;
      $('.phase-parent').removeClass('new-item');
      const take_len = $('.phase-parent:eq(' + phase_len + ')');
      take_len.addClass('new-item');
      // const phase_length = $('.summary-details .phase-parent').length - 1;
      // const take_length = $('.phase-parent:eq(' + phase_length + ')');
      setTimeout(function () {
        $('.phase-parent').removeClass('new-item');
      }, 500)
    })
    if ($('section.estimated-list').length > 0) {
      $('html').addClass('edit_estimates');
    }
    // --- end --
  }
  getInitPhase(phases = []) {
    const num = (phases).length + 1;
    const generatedPhaseId = uuid();
    const phase = {
      phaseId: generatedPhaseId,
      phaseOrder: num,
      phaseName: "",
      tasks: [],
    };
    return phase;
  }
  addPhase() {
    let undoData = JSON.parse(localStorage.getItem("undoData"));
    if (!undoData) {
      undoData = [];
    }
    undoData.unshift({
      state: "phasesAsComponents",
      action: "addPhase",
    });
    localStorage.setItem("undoData", JSON.stringify(undoData));

    const { estimate = {} } = this.props;
    const { phases = [] } = { ...estimate };

    const phase = this.getInitPhase(phases);
    const temp = [...phases]
    temp.push(phase);
    this.updateEstimateTable({ phases: temp });
  }
  async updateEstimateTable(updateData, refreshData = true) {

    const query = this.getQuery();
    const estimateRef = fire.database().ref(query.companyId).child(`${query.dataType}/${query.estimateKey}`);
    const { estimate: temp } = this.props;
    const estimate = { ...temp };
    let type = "",
      filterType = "";
    if (estimate.typeOf === "TIME BASED ESTIMATE") {
      type = "TIME BASED ESTIMATE";
      filterType = "time-based";
    } else {
      type = "TASK BASED ESTIMATE";
      filterType = "task-based";
    }

    estimate.type = type;
    estimate.filterType = filterType;
    const newEstimateData = Object.assign({}, estimate, updateData);
    await estimateRef.update(newEstimateData);
    setTimeout(() => {
      this.setState({
        phasesOrdered: null
      })
    }, 1000);
    if (refreshData) {
      this.refreshData({ change: true, refresh: true });
    }
  }
  range(min, max) {
    if (min < max) {
      return [...Array(max - min + 1).keys()].map((i) => i + min);
    } else {
      return [...Array(min - max + 1).keys()].map((i) => i + max);
    }
  }
  reorderPhases(result) {
    const { estimate } = this.props;
    const { phases } = { ...estimate };
    const phasesClone = JSON.parse(JSON.stringify(phases));

    const data = {
      source: phasesClone[result.source.index],
      destination: phasesClone[result.destination.index],
    };
    if (
      !data ||
      !data.source ||
      !data.destination ||
      data.destination.phaseId === data.source.phaseId
    ) {
      return;
    }

    const phasesOrdered = orderItems({
      list: phasesClone,
      field: "phaseOrder",
      source: data.source,
      destination: data.destination,
      nameField: "phaseName",
    });
    this.setState({
      phasesOrdered
    })

    this.updateEstimateTable({ phases: phasesOrdered });
  }

  createDuplicatePhase(sourcePhase) {

    const copy = { ...sourcePhase };
    copy.phaseName = getCopyName(copy.phaseName || 'Phase Name', this.props.estimate.phases, 'phaseName');
    copy.phaseId = uuid();
    this.createPhase(copy);
  }
  createPhase(copy) {

    const phaseState = Array.from(this.props.estimate.phases);
    let order = 0;
    phaseState.forEach((phase) => {
      order = Math.max(getOrderInt(phase.phaseOrder) + 1, order);
    });
    copy.phaseId = uuid();
    copy.phaseOrder = order;
    phaseState.push(copy);
    this.updateEstimateTable({ phases: phaseState });
  }
  deletePhase(phase) {
    let phases = this.props.estimate.phases.filter(
      (d) => d.phaseId !== phase.phaseId
    );
    if (!phases || phases.length === 0) {
      const initPhase = this.getInitPhase(phases);
      phases = [initPhase];
    }
    this.updateEstimateTable({ phases });
  }

  deleteHandler(key) {
    const phases = [...this.props.estimate.phases];
    const phaseIndex = phases.findIndex((d) => d.phaseId === key);
    const phase = phases[phaseIndex];
    const order = getOrderInt(phase.phaseOrder);
    const newPhases = phases.filter((d) => d.phaseId !== key);
    newPhases.forEach((phase) => {
      const position = getOrderInt(phase.phaseOrder);
      if (order < position) {
        phase.phaseOrder = position - 1;
      }
    });
    this.updateEstimateTable({ phases: newPhases });
  }
  closeHandler() {
    this.setState({ dialogOpen: false });
  }
  sectionExpand(e) {
    e.currentTarget.closest(".inner-content").classList.toggle("active");
  }
  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    this.reorderPhases(result);
  }
  drawerInfoUpdate(data, drawerOnSuccess, drawerOnCancel) {
    this.setState({
      newResourceName: data.resourceName,
      newResourceType: data.type,
      newResourceData: data,
      drawerOnSuccess,
      drawerOnCancel,
    });
  }
  toggleDrawer() {
    this.setState({
      ResourceDrawerOpen: !this.state.ResourceDrawerOpen,
    });
  }
  toggleEditDrawer(resource, updateResourceFn = () => { }) {
    this.setState({
      EditVendorDrawer: !this.state.EditVendorDrawer,
      resource: !this.state.EditVendorDrawer ? resource : null,
      updateResourceFn: updateResourceFn
    });
  }
  toggleEditCostRateDrawer(resource, updateResourceFn = () => { }) {
    if (this.state.EditCostRateDrawer) {
      this.setState({
        EditCostRateDrawer: false,
        resource: null,
        updateResourceFn: null
      });
    } else {
      this.setState({
        EditCostRateDrawer: true,
        resource: resource,
        updateResourceFn
      });
    }

  }
  render() {
    const { companyId, shared, location } = this.props;
    const { phasesOrdered } = this.state;
    const arr = location.pathname.split('/');
    const key = arr[arr.length - 1];
    const { estimate = {} } = this.props;
    const phases = JSON.parse(JSON.stringify(estimate.phases || []));
    const sortedPhases = phasesOrdered || sortItems({ list: phases, field: "phaseOrder", nameField: "phaseName" });
    const query = this.getQuery();
    if (!query || estimate.estimateKey !== query.estimateKey) {
      return null;
    }
    return (
      <div id="scope" className="col s12 l12 scope-tab table">
        <div className="row">
          <div className="col s10 large-grid" style={{ position: "relative" }}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                    {sortedPhases?.map((phase, index) => (
                      <Draggable
                        key={"dragkey-" + phase.phaseId}
                        draggableId={`drag-${phase.phaseId}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="phase-parent"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Phase
                              key={phase.phaseId}
                              // updateCnt={updateCnt}
                              deletePhase={this.deletePhase}
                              refreshData={this.refreshData}
                              estimateKey={key}
                              classes={this.props.classes}
                              createDuplicate={this.createDuplicatePhase}
                              toggleDrawer={this.toggleDrawer}
                              drawerInfoUpdate={this.drawerInfoUpdate}
                              phase={phase}
                              update={this.props.update}
                              toggleEditDrawer={this.toggleEditDrawer}
                              toggleEditCostRateDrawer={this.toggleEditCostRateDrawer}
                              editVendorValue={this.state.editVendorValue}
                              companyId={companyId}
                              shared={shared}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>

            {shared !== true && <Tooltip title="Add a Phase" interactive placement="top">
              <div className="plus-row">
                <IconButton
                  aria-label="add"
                  className={this.props.classes.plusIcon}
                  onClick={this.addPhase}
                >
                  <AddCircleIcon style={{ fontSize: 44, color: "#e70a89" }} />
                </IconButton>
              </div>
            </Tooltip>}
          </div>
          <div className="col s2 small-grid">
            <Sticky
              topOffset={-237}
              stickyClassName={"sidenavsSticky"}
              boundaryElement="header"
              relative="true"
            >
              <div className="small-col">
                <div className="inner-title">
                  <span className={"logo-wrap"}>
                    <img src={MenuOpenIcon} alt="menu" className="close-icon" />
                    <img
                      className="open-icon"
                      src={menuopen_black}
                      alt="open"
                    />
                  </span>
                  <span className={"text-outer"}> Sections</span>
                </div>
                <div className="outer-content">
                  {this.props.estimate?.phases?.map((phase) => (
                    <SideSection
                      classes={this.props.classes}
                      key={
                        phase.phaseId + phase?.tasks?.join((d) => d.taskName)
                      }
                      keyite={
                        phase.phaseId + phase?.tasks?.join((d) => d.taskName)
                      }
                      name={phase.phaseName}
                      tasks={phase.tasks ? phase.tasks : null}
                    />
                  ))}
                </div>
              </div>
            </Sticky>
          </div>
        </div>
        {this.state.ResourceDrawerOpen ? (
          <ResourceDrawer
            key={this.state.newResourceData.newResourceName}
            id={this.props.match.params}
            dataType={query.dataType}
            open={this.state.ResourceDrawerOpen}
            toggle={this.toggleDrawer}
            newResourceData={this.state.newResourceData}
            onSuccess={this.state.drawerOnSuccess}
            onCancel={() => {
              this.refreshData();
              if (this.state.drawerOnCancel) {
                this.state.drawerOnCancel();
              }
            }}
          />
        ) : null}

        {this.props.expensesDrawer ? (
          <ExpensesDrawer
            id={this.props.expenseId}
            dataType={query.dataType}
            open={this.props.expensesDrawer}
            toggle={this.props.toggleExpenses}
            onSuccess={this.props.updateExpense}
          />
        ) : null}

        {this.state.updateResourceFn && this.state.EditVendorDrawer && this.state.resource ?
          <div className='MuiPaper-root MuiDrawer-paper MuiDrawer-paperAnchorRight MuiPaper-elevation16' style={{ zIndex: '1500 !important' }}>
            <div id='duplicate' className='side-item'>
              {/* <EditVendor
            key={this.state.resource.resourceId}
            resource={this.state.resource}
            refreshData={this.refreshData}
            toggleEditDrawer={this.toggleEditDrawer}
            estimate={estimates}
            updateResourceFn={this.state.updateResourceFn}
            onClose={() => {
              this.setState({
                EditVendorDrawer: false,
                resource: null,
                updateResourceFn: null
              });
            }}
          /> */}

              <NewDrawer
                key={this.state.resource.resourceId}
                resource={this.state.resource}
                refreshData={this.refreshData}
                toggleEditDrawer={this.toggleEditDrawer}
                estimate={estimate}
                updateResourceFn={this.state.updateResourceFn}
                open={true}
                toggle={true}
                onClose={() => {
                  this.setState({
                    EditVendorDrawer: false,
                    resource: null,
                    updateResourceFn: null
                  });
                }}
              />

              {/* {this.state.EditVendorDrawer ?
          <NewDrawer open={true} toggle={true}/> : null} */}

            </div>
          </div> : null}
        {this.state.updateResourceFn && this.state.EditCostRateDrawer && this.state.resource ?
          <div className='MuiPaper-root MuiDrawer-paper MuiDrawer-paperAnchorRight MuiPaper-elevation16' style={{ zIndex: '1500 !important' }}>
            <div id='duplicate' className='side-item'>

              <CostRateDrawer
                key={this.state.resource.resourceId}
                resource={this.state.resource}
                refreshData={this.refreshData}
                toggleEditDrawer={this.toggleEditCostRateDrawer}
                estimate={estimate}
                updateResourceFn={this.state.updateResourceFn}
                open={true}
                toggle={true}
                onClose={() => {
                  this.setState({
                    EditCostRateDrawer: false,
                    resource: null,
                    updateResourceFn: null
                  });
                }}
              />

            </div>
          </div> : null}
      </div>
    );
  }
}
function MapStateToProps(state) {
  return {
    estimate: state.getEstimate.estimate,
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimateAction: bindActionCreators(
      getEstimateActionCreators,
      dispatch
    ),

  };
}
export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(EstimateCalculator);
