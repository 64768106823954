import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    disciplines: null,
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

export const getDisciplinesAsync = createAsyncThunk(
    'disciplines/getDisciplines',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const refList = fire.database().ref(companyId).child("disciplines");
        const snapshotTag = await refList.once("value");
        const disciplines = snapshotTag.val();
        refList.off("value");
        return { disciplines: Object.values(disciplines) };
    }
);

// Redux Toolkit slice
export const getDisciplinesSlice = createSlice({
    name: 'disciplines',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDisciplinesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDisciplinesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.disciplines = action.payload.disciplines
            });
    },
});

export default getDisciplinesSlice.reducer;
