import React from 'react';

export default function ClearIcons({ htmlColor = "#000", className = "", type = "" }) {

    return (
        <React.Fragment> {type == "include" ?
            <svg className={className + " MuiSvgIcon-root"} width="29.271" height="18.223" viewBox="0 0 29.271 18.223">
                <defs>
                    <clipPath id="6rll89pr6a">
                        <path data-name="Rectangle 3715" transform="translate(-2 -2)" style={{ fill: 'none' }} d="M0 0h29.271v18.222H0z" />
                    </clipPath>
                </defs>
                <g data-name="Group 23998">
                    <g data-name="Group 23997" style={{ clipPath: "url(#6rll89pr6a)" }} transform="translate(2 2)">
                        <path data-name="Path 25687" d="m.078 7.381.015.019a16.493 16.493 0 0 0 5.437 4.96 14.46 14.46 0 0 0 7.106 1.859 14.46 14.46 0 0 0 7.106-1.859A16.493 16.493 0 0 0 25.18 7.4l.006-.008.011-.017.016-.028.006-.011c.007-.013.012-.026.018-.04v0a.509.509 0 0 0 0-.368v0a.287.287 0 0 0-.018-.04l-.006-.01-.013-.032-.011-.017-.009-.009a16.494 16.494 0 0 0-5.438-4.962 14.512 14.512 0 0 0-14.212 0A16.494 16.494 0 0 0 .091 6.82l-.006.008-.011.017-.014.028-.007.013-.016.034v.01a.468.468 0 0 0-.013.04v.007c0 .014-.007.028-.01.043v.015a.5.5 0 0 0 .072.347m25.036-.337h0zm0 .134h0zm-.008-.168h0m0 .2h0m-.014.019h0m0-.238h0m-.006-.015h0M6.023 2.746a13.5 13.5 0 0 1 13.226 0 15.421 15.421 0 0 1 4.891 4.365 15.421 15.421 0 0 1-4.891 4.365 13.456 13.456 0 0 1-6.613 1.731 13.457 13.457 0 0 1-6.613-1.731 15.421 15.421 0 0 1-4.891-4.365 15.421 15.421 0 0 1 4.891-4.365"
                            style={{ strokeLinecap: 'round', strokeLineJoin: 'round', stroke: htmlColor }} />
                        <path data-name="Path 25688" d="M343.907 93.554a5.319 5.319 0 1 0-5.32-5.319 5.325 5.325 0 0 0 5.32 5.319m0-9.706a4.387 4.387 0 1 1-4.387 4.387 4.391 4.391 0 0 1 4.387-4.387" transform="translate(-331.271 -81.123)" style={{ stroke: htmlColor }} />
                    </g>
                    <path data-name="Line 96" transform="translate(2.5 2.076)" style={{ strokeWidth: '2px', strokeLinecap: 'round', stroke: htmlColor, fill: "none" }} d="M0 14 24 0" />
                </g>
            </svg>

            : type == "exclude" ?
                <svg className={className + " MuiSvgIcon-root"} width="29.271" height="18.223" viewBox="0 0 29.271 18.223">
                    <defs>
                        <clipPath id="xg2kky51ra">
                            <path data-name="Rectangle 3715" transform="translate(-2 -2)" style={{ fill: 'none' }} d="M0 0h29.271v18.222H0z" />
                        </clipPath>
                    </defs>
                    <g data-name="Group 23998">
                        <g data-name="Group 23997" style={{ clipPath: "url(#xg2kky51ra)" }} transform="translate(2 2)">
                            <path data-name="Path 25687" d="m.078 7.381.015.019a16.493 16.493 0 0 0 5.437 4.96 14.46 14.46 0 0 0 7.106 1.859 14.46 14.46 0 0 0 7.106-1.859A16.493 16.493 0 0 0 25.18 7.4l.006-.008.011-.017.016-.028.006-.011c.007-.013.012-.026.018-.04v0a.509.509 0 0 0 0-.368v0a.287.287 0 0 0-.018-.04l-.006-.01-.013-.032-.011-.017-.009-.009a16.494 16.494 0 0 0-5.438-4.962 14.512 14.512 0 0 0-14.212 0A16.494 16.494 0 0 0 .091 6.82l-.006.008-.011.017-.014.028-.007.013-.016.034v.01a.468.468 0 0 0-.013.04v.007c0 .014-.007.028-.01.043v.015a.5.5 0 0 0 .072.347m25.036-.337h0zm0 .134h0zm-.008-.168h0m0 .2h0m-.014.019h0m0-.238h0m-.006-.015h0M6.023 2.746a13.5 13.5 0 0 1 13.226 0 15.421 15.421 0 0 1 4.891 4.365 15.421 15.421 0 0 1-4.891 4.365 13.456 13.456 0 0 1-6.613 1.731 13.457 13.457 0 0 1-6.613-1.731 15.421 15.421 0 0 1-4.891-4.365 15.421 15.421 0 0 1 4.891-4.365"
                                style={{ strokeLinecap: 'round', strokeLineJoin: 'round', stroke: htmlColor }} />
                            <path data-name="Path 25688" d="M343.907 93.554a5.319 5.319 0 1 0-5.32-5.319 5.325 5.325 0 0 0 5.32 5.319m0-9.706a4.387 4.387 0 1 1-4.387 4.387 4.391 4.391 0 0 1 4.387-4.387" transform="translate(-331.271 -81.123)" style={{ stroke: htmlColor }} />
                        </g>
                    </g>
                </svg>
                : null}  </React.Fragment>

    )



}