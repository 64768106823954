import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import '../../assets/scss/manage.scss';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    menuIcon: {
        '& svg': {
            marginRight: '10px'
        }
    }

}));

export default function MenuListComposition(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const optionChange = (event, value, itemsid, type) => {
        props.optionValues(value, itemsid, type);
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    return (
        <div className={classes.root}>
            <div >
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    onClick={handleToggle}
                >
                    <MoreVertIcon />
                </Button >
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 2 }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom', zIndex: 9,
                            }}
                        >
                            <Paper className="select-menu-wrap" >
                                <ClickAwayListener onClickAway={(e) => optionChange(e, "", "")}>
                                    <MenuList autoFocusItem={open} id="menu-list-summary" onKeyDown={handleListKeyDown}>
                                        {
                                            props.optionsList.map(function (item) {
                                                return (
                                                    <MenuItem key={item.id} className={`${classes.menuIcon + ' ' + item.classname}`} onClick={(e) => optionChange(e, item.id, props.itemsid, props.type, props.contactType)} itemsid={props.itemsid}>{item.icon}{item.type}</MenuItem>
                                                )
                                            })
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}