import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import fire from "../../../config/firebase";
import "../../../assets/scss/autocomplete.scss";
import parse from "autosuggest-highlight/parse";
import Moment from "moment";
import matchRegex from "autosuggest-highlight/match";
import { isTrue } from "../../../libs/utils";
const filter = createFilterOptions();

function AutoCompleteorganizations({
  selectValue,
  show,
  table,
  dataKey,
  tableKey,
  title,
  options = [],
  contactType = 'People',
  update = () => {
    console.error(`AutoCompleteResource update not implemented`);
  },
  cla,
  classes,
}) {
  const set = new Set();
  const [value, setValue] = useState(selectValue);
  const addNewItem = (name) => {

    const updateData = {};
    updateData[tableKey || table] = name;
    update(updateData);
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key]), {});
    const companyId = localStorage.getItem('companyId');

    const listRef = fire.database().ref(companyId).child(table);
    const itemData = {
      title: name,
      date: Moment(new Date()).format("MM/DD/YYYY"),
      borderColor: "#fffff",
      contactType
    };

    listRef.once("value", (snap) => {
      if (set.has(name)) {
        return;
      }
      const db = snap.val();
      listRef.off("value");
      const filteredList = Object.values(db).filter(d => d.title === name);
      set.add(name);
      if (filteredList.length === 0) {
        listRef.push(itemData);
      }
    });
  };

  return (
    <Autocomplete
      key={dataKey}
      autoSelect
      value={value}
      defaultValue={selectValue}
      className={cla + " " + classes}
      onChange={(event, newValue) => {
        if (newValue === null) {
          return;
        }
        setValue(newValue);
        if (newValue && newValue.inputValue) {
          addNewItem(newValue.inputValue);
        } else {
          const data = {};
          data[tableKey || table] = newValue.title;
          update(data);
        }
        if (show) {
          show();
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        params.inputValue = (params.inputValue).replace(/\s\s+/g, ' ').replace(/^\s+|\s+$/g, '');
        if (params.inputValue !== "") {
          filtered.push({
            title: (
              <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}
              </span>
            ),
            inputValue: params.inputValue,
          });
        } else if (params.inputValue !== "") {
          filtered.push({
            title: (
              <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}
              </span>
            ),
            inputValue: params.inputValue,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      blurOnSelect
      handleHomeEndKeys
      options={(options || []).filter((d) => !isTrue(d.archive))}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(option, { inputValue }) => {
        const matches = matchRegex(option.title, inputValue);
        const parts = parse(option.title, matches);
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
      style={{ width: 150 }}
      freeSolo
      renderInput={(params) => <TextField {...params} label={title} />}
    />
  );
}
export default withRouter(AutoCompleteorganizations);
