import React from "react";
import "../../assets/scss/overlay.scss";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
const useStyles = makeStyles(() =>
  createStyles({
    newestimate: {
      borderRadius: "5px",
      backgroundColor: "#e70a89",
      marginTop: "20px",
      color: "#fff",
      minWidth: "100px",
      minHeight: "40px",
      marginRight: "10px",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#e70a89",
        boxShadow: "inset 0 -40px 10px -2px rgba(0,0,0,0.1)",
      },
    },
    newestimateback: {
      borderRadius: "5px",
      backgroundColor: "transparent",
      marginTop: "20px",
      color: "#000",
      minWidth: "100px",
      minHeight: "40px",
      marginRight: "10px",
      boxShadow: "none",
      border: "2px solid #000",
      "&:hover": {
        backgroundColor: "#000",
        color: "#fff",
        boxShadow: "inset 0 -40px 10px -2px rgba(0,0,0,0.1)",
      },
    },
    overlay_content_wrap: {
      animation: "fadeInRight 0.25s linear",
      animationDuration: "0.25s",
    },
  })
);
const CreateProject = (props) => {
  const classes = useStyles();
  const { Projectvalue = '' } = props;
  // const [key, typeKey] = React.useState(props.Projectvalue || '');
  // const inputKey = debounce((e) => {
  //   // typeKey(e);
  //   props.inputvalue(e);
  // }, 0);
  const { dataType = "estimates" } = props;
  const friendlyName = dataType === "estimates" ? "estimate" : "template";
  return (
    <React.Fragment>
      <div className={classes.overlay_content_wrap}>
        <h1>A few more details…</h1>
        <p className="big-paragraph">
          Please choose a name for your {friendlyName}.
        </p>
        <div>
          <TextField
            id="outlined-basic"
            value={Projectvalue}
            label={
              friendlyName + " Name"
            }
            variant="outlined"
            onChange={props.inputvalue}
          />
        </div>
        <Button onClick={props.onBack} className={classes.newestimateback}>
          Back
        </Button>
        <Button
          className={classes.newestimate}
          variant="contained"
          disabled={Projectvalue === "" ? true : false}
          onClick={props.click}
          key={"bnext"}
        >
          Next
        </Button>
      </div>
    </React.Fragment>
  );
};
export default CreateProject;
