import React from "react";
import "../../assets/scss/all-estimates.scss";
import { Typography } from "@material-ui/core";
import EstimaticSkyGondolaImage from "../../assets/images/manage_empty.svg";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid/Grid";
import { DefaultButton } from "../small-components/UI/Buttons";
const content_type = "Estimates";
const Button_type = "Add Resources";
export default function NoItems(props) {
  // const classes = useStyles();
  const handleCreate = () => {
    props.createNew(true, "new", props.Button_type);
  };

  const TAGS_TITLE = " Find and organize your estimates easily.";
  const TAGS_SUB_TITLE =
    "Add categories here that you can use to tag your estimates.";
  const RESOURCES_TITLE = " They get it done.";
  const RESOURCES_SUB_TITLE =
    "Add the employees and vendors who will do the work here.";
  const TASKS_TITLE = " Let’s show them how it’s done.";
  const TASKS_SUB_TITLE = "Add tasks that your resources will work on here.";
  const PHASES_TITLE = " Break it down.";
  const PHASES_SUB_TITLE =
    "Add phases here to break your projects down into smaller steps. The estimates will show how much each phase costs to get a more detailed picture.";
  const DISCIPLINES_TITLE = " The skills you need for your projects.";
  const DISCIPLINES_SUB_TITLE =
    "Disciplines are like departments in a company, such as Design, Copywriting, or Development. Add disciplines here to assign to your resources, and see costs in the estimates summary broken down by the type of work.";

  return (
    <React.Fragment>
      <Grid container style={{ maxWidth: 1080, margin: "0 auto 27px" }}>
        <Grid item xs={3}>
          <Box maxWidth={226} maxHeight={208} position="relative">
            <img
              src={`${EstimaticSkyGondolaImage}`}
              alt="Estimatic Sky Gondola"
              style={{ width: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box style={{ paddingLeft: "13px" }}>
            <Typography
              variant="h1"
              component="h1"
              style={{
                fontSize: "40px",
                fontWeight: 400,
                lineHeight: 1.38,
                marginBottom: "10px",
                wordBreak: "break-word",
              }}
            >
              {props.content_type ? props.content_type : content_type}
              {(() => {
                if (props.content_type === "Tags") {
                  return <>.{TAGS_TITLE}</>;
                } else if (props.content_type === "Phase") {
                  return <>s.{PHASES_TITLE}</>;
                } else if (props.content_type === "Tasks") {
                  return <>.{TASKS_TITLE}</>;
                } else if (props.content_type === "Resources") {
                  return <>.{RESOURCES_TITLE}</>;
                } else if (props.content_type === "Discipline") {
                  return <>s.{DISCIPLINES_TITLE}</>;
                } else {
                  return <>Please Contact Us</>;
                }
              })()}
            </Typography>
            <Typography
              variant="p"
              component="p"
              style={{
                fontWeight: 100,
                fontSize: "20px",
                lineHeight: 1.35,
                marginBottom: "31px",
                wordBreak: "break-word",
              }}
            >
              {(() => {
                if (props.content_type === "Tags") {
                  return <>{TAGS_SUB_TITLE}</>;
                } else if (props.content_type === "Phase") {
                  return <>{PHASES_SUB_TITLE}</>;
                } else if (props.content_type === "Tasks") {
                  return <>{TASKS_SUB_TITLE}</>;
                } else if (props.content_type === "Resources") {
                  return <>{RESOURCES_SUB_TITLE}</>;
                } else if (props.content_type === "Discipline") {
                  return <>{DISCIPLINES_SUB_TITLE}</>;
                } else {
                  return <>Please Contact Us</>;
                }
              })()}
            </Typography>
            <DefaultButton onClick={() => handleCreate()}>
              Create {props.Button_type ? props.Button_type : Button_type}
            </DefaultButton>
          </Box>
        </Grid>
        <Grid></Grid>
      </Grid>
    </React.Fragment>
  );
}
