import React from "react";
import "../../assets/scss/time-based.scss";
import "../../assets/scss/time-based-summary.scss";
import "../../assets/scss/autocomplete.scss";
import "../../assets/scss/setting-table.scss";
import AddTimePhase from "../small-components/AddTimePhase";
import GanttContainerApp from "../timeline/ganttContainer/GanttContainerApp";
import SaveTemplate from "../small-components/SaveTemplate/SaveTemplate";
import Summary from "../small-components/summary";
import Expenses from "../small-components/expenses"
import ExpensesDrawer from "../small-components/expense-drawer";
import Notes from "../small-components/notes/notes";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import { Button, Typography, Paper } from "@material-ui/core";
import fire from "../../config/firebase";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import * as getEstimateActionCreators from '../../store/estimate/getEstimate';

class TimeBased extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      phaseAppend: [],
      speed: false,
      buttonShow: true,
      overlay: "",
      value: "scope",
      showTemplate: false,
      template: {},
      expensesDrawer: false,
      expenseId: '',
    };
    this.updateExpense = this.updateExpense.bind(this);
    this.toggleExpenseDrawer = this.toggleExpenseDrawer.bind(this);

  }

  refreshData = () => {
    const dataType = this.props.match.path.includes("estimates-timebased")
      ? "estimates"
      : "templates";
    const pathArr = this.props.location.pathname.split('/');
    const key = pathArr[pathArr.length - 1];
    const companyId = localStorage.getItem('companyId');
    const ref = fire.database().ref(companyId).child(dataType + "/" + key);
    ref.once("value").then(
      (snapshot) => {
        const template = snapshot.val();
        ref.off("value");
        this.setState({ template: template });
      },
    );
  };

  componentDidMount() {
    this.refreshData()
  }

  getQuery = () => {
    const arr = this.props.location.pathname.split('/');
    const estimateKey = arr[arr.length - 1];
    const companyId = localStorage.getItem('companyId') || `public/${estimateKey}`;
    const dataType = this.props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    const isTemplate = dataType === "templates";
    return { dataType, estimateKey, companyId, isTemplate }
  }

  toggleExpenseDrawer = (id) => {
    console.log('toggle', this.state.expensesDrawer)

    this.setState({
      expensesDrawer: !this.state.expensesDrawer,
      expenseId: id
    })
  }

  updateExpense = (exp) => {
    const query = this.getQuery();
    console.log('Query: ', query)
    const refInner = fire.database().ref(query.companyId).child(`${query.dataType}/${query.estimateKey}`);
    refInner.update(exp);
    const { getEstimateAsync } = this.props.getEstimateAction;
    getEstimateAsync(this.getQuery());
  }

  autofill = (e, id) => {
    const myText = e.target.innerText || e.target.textContent;
    document.getElementById(id).value = myText;
  };

  addPhase = (r) => {
    this.setState({
      speed: true,
      type: r,
    });
    setTimeout(() => {
      this.setState({
        phaseAppend: [
          ...this.state.phaseAppend,
          <AddTimePhase
            type={this.state.type}
            key={this.state.phaseAppend.length}
            phase={this.addPhase}
          />,
        ],
        buttonShow: false,
      });
    }, 100);
  };
  showButton = () => {
    this.setState({
      buttonShow: true,
    });
  };
  toggleDrawer = (anchor, open, type) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ ...this.state, [anchor]: open, overlayType: type });
  };
  overlayTypes = (anchor, open, type) => {
    this.setState({ ...this.state, [anchor]: open, overlayType: type });
  };
  handleChange = (e) => {
    e.currentTarget.closest(".summary-table").classList.toggle("expandTime");
    const row = e.currentTarget.closest(".summary-table");
    $(row).find(".summary-body").slideToggle();
  };
  toggleEstimateDrawer = () => {
    this.setState({ showTemplate: !this.state.showTemplate })
  };
  render() {
    // const [value, setValue] = React.useState("scope");

    const { value } = this.state;
    const props = this.props;
    const pathArr = props.location.pathname.split('/');
    const key = pathArr[pathArr.length - 1];
    const { estimateType } = this.props;
    const dataType = props.match.path.includes("estimates")
      ? "estimates"
      : "templates";

    const isTemplate = dataType === "templates";
    const setValue = (val) => {
      this.setState({ value: val });
    };
    const tabHandler = (type) => {
      setValue(type);
    };
    const query = this.getQuery();

    return (
      <React.Fragment>
        <div className="scope-time" style={{
          borderColor: isTemplate ? "#2ab7ca" : "#fff",
          borderWidth: isTemplate ? "3px" : "3px 0 0 0",
          borderStyle: "solid",
        }} >
          {this.state.showTemplate ? (
            <Paper className="MuiDrawer-paper MuiDrawer-paperAnchorRight">
              <SaveTemplate projectName={this.state.template.projectName} closeEditScreen={this.toggleEstimateDrawer} onClose={this.toggleEstimateDrawer} />
            </Paper>
          ) : null}
          {isTemplate ? (
            <div className="violator-bar">
              <Typography variant="h5" component="h5">
                You are currently modifying a template
              </Typography>
              <div className="btn-wrap">
                <Button variant="contained" disabled={this.state.showTemplate} onClick={this.toggleEstimateDrawer}>
                  Create Estimate
                </Button>
              </div>
            </div>
          ) : null}
          <div className="container">
            <ul className="tabs-wrap">
              <li className={value === "scope" ? "active" : ""}>
                <Button onClick={() => tabHandler("scope")}>
                  <p>Scope</p>
                </Button>
              </li>
              <li className={value === "expenses" ? "active" : ""}>
                <Button onClick={() => tabHandler("expenses")}>
                  <p>Expenses</p>
                </Button>
              </li>
              <li className={value === "summary" ? "active" : ""}>
                <Button onClick={() => tabHandler("summary")}>
                  <p>Summary</p>
                </Button>
              </li>
              <li className={value === "notes" ? "active" : ""}>
                <Button onClick={() => tabHandler("notes")}>
                  <p>Notes</p>
                </Button>
              </li>
            </ul>
            <div className="content-wrapper">
              {value === "scope" ? (
                <div
                  className={`scope-wrap ${value === "scope" ? "active" : ""}`}
                >
                  <GanttContainerApp
                    updateCnt={this.props.updateCnt}
                    update={this.props.update}
                    estimateType={estimateType}
                    updateExpense={this.updateExpense}
                    toggleExpenses={this.toggleExpenseDrawer}
                    expensesDrawer={this.state.expensesDrawer}
                    expenseId={this.state.expenseId}
                  />
                </div>
              ) : null}
              {value === "expenses" ? (
                <div
                  className={`expenses-wrap ${value === "expenses" ? "active" : ""}`}
                >
                  <Expenses
                    toggle={this.toggleExpenseDrawer}
                    companyId={query.companyId}
                    shared={false}
                    updateExpense={this.updateExpense}
                  />
                </div>
              ) : null}
              {value === "summary" ? (
                <div
                  className={`summary-wrap ${value === "summary" ? "active" : ""
                    }`}
                >
                  <Summary
                    // dataType={dataType}
                    // dataKey={key}
                    key={"summary" + key}
                    data={this.state.template}
                  />
                </div>
              ) : null}
              {value === "notes" ? (
                <div
                  className={`notes-wrap ${value === "notes" ? "active" : ""}`}
                >
                  <Notes />
                </div>
              ) : null}
              {this.state.expensesDrawer ? (
                <ExpensesDrawer
                  id={this.state.expenseId}
                  dataType={dataType}
                  open={this.state.expensesDrawer}
                  toggle={this.toggleExpenseDrawer}
                  onSuccess={this.updateExpense}
                />
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function MapStateToProps(state) {
  return {
    estimate: state.getEstimate.estimate,
    resources: state.getResources.resources,
    company: state.getCompany.company,
    users: state.getUsers.users,
    calculations: state.getCompany.calculations,
    organizations: state.getOrganizations.organizations,
    contacts: state.getContacts.contacts
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimateAction: bindActionCreators(
      getEstimateActionCreators,
      dispatch
    ),
  };
}
export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(TimeBased);
