import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    estimate: {},
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

// asynchronous function with createAsyncThunk
export const getEstimateAsync = createAsyncThunk(
    'estimate/getEstimate',
    async (query = {}) => {
        const { companyId, estimateKey, dataType } = query;
        const ref = fire.database().ref(companyId).child(dataType).child(estimateKey);
        const refsnapshot = await ref.once("value");
        const estimate = refsnapshot.val() || {};
        estimate.estimateKey = estimateKey;
        ref.off("value");
        return { estimate };
    }
);

// Redux Toolkit slice
export const getEstimateSlice = createSlice({
    name: 'estimate',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEstimateAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEstimateAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.estimate = action.payload.estimate
            });
    },
});
export default getEstimateSlice.reducer;
