
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import "../../assets/scss/autocomplete.scss";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import fire from '../../config/firebase';
import Moment from 'moment';
const filter = createFilterOptions();

export default function AddChips(props) {
  const [value, setValue] = React.useState(null);
  const [disable, setDisable] = React.useState(false);
  const { allTags = [] } = props;
  const set = new Set();
  const tempAllTags = [];
  useEffect(() => {
    if (props.disabled) setDisable(true)
  }, [])

  const useStyles = makeStyles({
    disableHover: {
      "&:hover": {
        '& input': {
          cursor: 'default',
        }
      }
    }

  });
  const classes = useStyles();

  for (const tag of allTags) {
    const curKey = (tag.title || '').toLowerCase()
    if (set.has(curKey) === false) {
      tempAllTags.push(tag);
      set.add(curKey);
    }
  }
  let inputLength = 0;

  const addNewItem = (name) => {
    const companyId = localStorage.getItem('companyId');
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key]), {});

    const listRef = fire.database().ref(companyId).child("tags");
    const itemData = {
      title: name,
      date: Moment(new Date()).format('DD/MM/YYYY'),
      borderColor: "#fffff"
    }
    listRef.once('value').then(
      (snap) => {
        if (itemData) {
          const listDb = snap.val();
          if (listDb == null) {
            listRef.push(itemData);
          } else {
            const keys = Object.keys(listDb);
            const filteredList = [];
            for (const key of keys) {
              const item = listDb[key];

              if (item.title !== name) {
                filteredList.push(itemData);
              }
            }
            if (filteredList.length) {

              listRef.push(itemData);
              if (props.refreshData) {
                setTimeout(() => { props.refreshData() }, 3000)
              }
            }
          }

        }
      },
      (error) => {
        console.log("Error: " + error.code);
      }
    )
  }
  function capitalizeFirstLetter(str = "") {
    return str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  }
  return (
    <Autocomplete
      value={value}
      disabled={disable}
      className={disable ? classes.disableHover : "" + "" + props.cla + ' ' + props.classes}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          addNewItem(newValue.inputValue)
        } else {
          setValue(newValue);
        }
        if (props.resourceItem) {
          props.resourceItem();
        }
        if (props.show) {
          props.show();
        }
        props.selectedValue(newValue);
        setValue({
          value: "",
        });

      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const exists = props.tags.find((element) => {
          return element.title.toLowerCase().includes(params.inputValue)
        })
        // Suggest the creation of a new value
        if (params.inputValue !== '' && !exists) {
          filtered.push({
            title: <span className={`add-button ${props.classes}`}><AddIcon /> Add "{params.inputValue}" as a new tag</span>,
            inputValue: params.inputValue,
          });
        }
        inputLength = params.inputValue.length;
        return filtered;
      }}
      clearOnBlur
      noOptionsText={'Tag Is Already Selected'}
      options={tempAllTags}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);
        return (
          <div>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 300 }}>
                {capitalizeFirstLetter(part.text)}
              </span>
            ))}
          </div>
        );
      }}
      style={{ width: 170 }}
      renderInput={(params) => (
        <TextField className={`chipsInput ${inputLength > 30 && "error"}`} {...params} placeholder={props.title} />
      )}
    />
  );
}
