import React, { useState, useEffect } from "react";
import "./DrawerOverlay.scss";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import fire from "../../../config/firebase";
import Moment from "moment";
import { useHistory, withRouter } from "react-router-dom";

function SaveTemplate(props) {
  const history = useHistory();
  const pathArr = props.location.pathname.split('/');
  const key = pathArr[pathArr.length - 1];
  let dataKey;
  props.itemKey ? dataKey = props.itemKey : dataKey = key;

  const dataType = props.match.path.includes("estimates")
    ? "estimates"
    : "templates";
  const isTemplate = dataType === "templates";
  const [name, setName] = useState("(copy of) " + props.projectName);
  const [estimates, setEstimates] = useState([]);
  const [template, setTemplate] = useState([]);
  const handleName = (e) => {
    setName(e);
  };

  useEffect(() => {
    let mounted = true;

    setTimeout(() => {
      if (mounted) {
        const companyId = localStorage.getItem('companyId');
        const ref = fire.database().ref(companyId).child("estimates");
        console.log(dataKey)
        ref.once("value").then(
          (snapshot) => {
            const estimates = snapshot.val();
            ref.off("value");
            const estimateList = [];
            for (const id in estimates) {
              estimateList.push({ id, ...estimates[id] });
            }
            setEstimates(estimateList);
          },
          () => { }
        );

        const reftemplate = fire.database().ref(companyId).child("templates");
        reftemplate.once("value").then(
          (snapshot) => {
            const template = snapshot.val();
            reftemplate.off("value");
            const templateList = [];
            for (const id in template) {
              templateList.push({ id, ...template[id] });
            }
            setTemplate(templateList);

          },
          () => { }
        );
      }
    }, 500)

    return () => [
      mounted = false
    ]
  }, []);
  const handleSave = (event) => {
    if (event === "redirect") {
      if (dataType === "templates") {
        history.push(`/estimates/`);
      } else {
        history.push(`/templates/`);
      }
    } else {
      props.onClose();
    }
    const companyId = localStorage.getItem('companyId');
    let copy = "";
    if (dataType === "templates") {
      for (let i = 0; i < template.length; i++) {
        if (template[i]["id"] === dataKey) {
          copy = template[i];
          copy.id = null;
          if (name !== "") {
            copy.projectName = name;
          }
          copy.date = Moment(new Date()).format("MM/DD/YYYY");
          copy.timestamp = Math.floor(Date.now() / 1000);
          copy.modified = Moment(new Date()).format("h:mm a MMMM DD, YYYY");

          const estimateRef = fire.database().ref(companyId).child("estimates");
          estimateRef.push(copy);

        }
      }
    } else {
      for (let i = 0; i < estimates.length; i++) {
        if (estimates[i]["id"] === dataKey) {
          copy = estimates[i];
          copy.id = null;
          if (name !== "") {
            copy.projectName = name;
          }
          copy.date = Moment(new Date()).format("MM/DD/YYYY");
          copy.timestamp = Math.floor(Date.now() / 1000);
          copy.modified = Moment(new Date()).format("h:mm a MMMM DD, YYYY");
          const templatesRef = fire.database().ref(companyId).child("templates");
          templatesRef.push(copy);
          const estimateRef = fire.database().ref(companyId).child("estimates").child(dataKey);
          //This was removing Estimates
          //estimateRef.remove();
        }
      }
    }
  };
  return (
    <div id="SaveTemplate" className="side-item">
      <div className="title">
        <h5>
          Save As {isTemplate ? "Estimate" : "Template"}
          <div className="cross" onClick={props.onClose}>
            <CloseIcon htmlColor="rgba(8,77,79,0.5)" />
          </div>
        </h5>
      </div>
      <div className="content">
        {/*<img src={discount} alt="discount" />*/}
        <p>Are you sure you want to save as a estimates '{props.projectName}'?</p>
      </div>
      <div className="input-wrap">
        <div className="d_input">
          <TextField
            variant="outlined"
            type="text"
            label={`${isTemplate ? "Estimate Name" : "Template Name"}`}
            value={name}
            onChange={(e) => handleName(e.target.value)}
          />
        </div>
        <Button
          onClick={() => handleSave("close")}
          variant="contained"
          type="button"
          className="duplicate apply"
        >
          Save
        </Button>
        <Button
          variant="outlined"
          type="button"
          onClick={() => handleSave("redirect")}
          className="saveplus"
        >
          Save + Go To {isTemplate ? "Estimates" : "Templates"}
        </Button>
      </div>
    </div>
  );
}

export default withRouter(SaveTemplate);
