import {
  createGenerateClassName,
  StylesProvider,
} from "@material-ui/core/styles";
import "materialize-css/dist/css/materialize.min.css";
import React, { useEffect, useState } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from "react-router-dom";
import "./assets/scss/button.scss";
import "./assets/scss/style.scss";
import Footer from "./components/footer/TemplateFooter";
import Header from "./components/navigation/Header";
import SharedHeader from "./components/navigation/SharedHeader";
import AllPages from "./components/pages/AllPages";
import Dashboard from "./components/pages/Dashboard";
import EstimateFor from "./components/reusable-components/EstimateFor";
import SharedEstimateFor from "./components/reusable-components/Shared/SharedEstimateFor";
import SettingsTable from "./components/reusable-components/SettingsTable";
import routes from "./components/routes";
import routeslogFn from "./components/routes-log";
import fire from "./config/firebase";
import Estimates from "./components/pages/Estimates";
import Template from "./components/pages/Template";
import * as getUserActionCreators from './store/user/getUser';

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

function App({ location: { pathname }, history, getUserAction, user: userAuth }) {
  const companyId = localStorage.getItem('companyId');
  const [changeRecord, setChangeRecord] = useState({});
  const [userUid, setUserId] = useState(undefined);
  const isLogedin = companyId && userUid !== null;
  const [user, setUser] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [update, setUpdate] = useState(1);

  const authListner = () => {
    fire.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUserId(user.uid);
        setUser(user);
      } else {
        if (userUid !== null) {
          setUserId(null);
        }
      }
      const { getUserAsync } = getUserAction;
      await getUserAsync(user);
      setAuthLoaded(true);
    });
  }
  useEffect(() => {
    authListner();
  }, []);

  const incrementUpdate = () => {
    setUpdate(update + 1);
  };

  const routeslog = routeslogFn({
    updateCnt: update,
    updateFn: incrementUpdate,
    setChangeRecord,
    changeRecord
  });

  const fetchUsersDb = async () => {
    if (user && companyId) {
      const userListRef = fire.database().ref(companyId).child("users").child(user.uid);
      const snapshot = await userListRef.once("value");
      const userDb = snapshot.val();
      userListRef.off("value");
      if ((userDb) && (userDb.subtitle === 'admin')) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
    setLoaded(true);
  }
  useEffect(() => {
    if (authLoaded && !loaded) {
      fetchUsersDb();
    }
  }, [user, authLoaded, loaded]);

  const forwardTo = (url) => {
    if (loaded) {
      history.push(url);
    }
  };
  const AccessDenied = () => {
    forwardTo('/dashboard');
    return <></>
  }
  if (!loaded || !authLoaded) {
    return null;
  }

  return (
    <React.Fragment key={pathname}>
      <StylesProvider generateClassName={generateClassName}>
        {isLogedin ? (
          <Switch>
            <Route path="/estimates" exact={true}>
              <React.Fragment>
                <Header
                  updateCnt={update}
                  update={incrementUpdate}
                />
                <Estimates update={incrementUpdate} />
              </React.Fragment>
            </Route>
            <Route path="/templates" exact={true} key="1t">
              <React.Fragment>
                <Header updateCnt={update} update={incrementUpdate} />
                <Template update={incrementUpdate} />
              </React.Fragment>
            </Route>

            <Route path="/all-pages" exact={true}>
              <React.Fragment>
                <Header updateCnt={update} update={incrementUpdate} />
                <AllPages
                  update={incrementUpdate}
                  updateCnt={update}
                />
              </React.Fragment>
            </Route>

            <Route path="/" exact={true}>
              <React.Fragment>
                <Header updateCnt={update}
                  update={incrementUpdate} />
                <Dashboard update={incrementUpdate} updateCnt={update} />
              </React.Fragment>
            </Route>
            <Route path="/dashboard" exact={true}>
              <React.Fragment>
                <Header updateCnt={update}
                  update={incrementUpdate} />
                <Dashboard update={incrementUpdate} updateCnt={update} />
              </React.Fragment>
            </Route>
            <Route path="/estimates/:projectName/:key">
              <React.Fragment>
                <Header updateCnt={update}
                  update={incrementUpdate} />
                <section
                  className="estimated-list"
                  style={{ minHeight: "calc(100vh - 60px)" }}
                >
                  <EstimateFor
                    // update={update}
                    // updateFn={incrementUpdate}
                    title={"Task Based"}
                    // estimateType={"estimates"}
                    type={true}
                    key={pathname}
                  />
                  <SettingsTable
                    updateCnt={update}
                    update={incrementUpdate}
                    estimateType={"estimates"}
                  />
                </section>
              </React.Fragment>
            </Route>
            <Route path="/templates/:projectName/:key" key="2">
              <React.Fragment>
                <Header updateCnt={update}
                  update={incrementUpdate} />
                <section
                  className="estimated-list"
                  style={{ minHeight: "calc(100vh - 60px)" }}
                >
                  <EstimateFor
                    // update={update}
                    // updateFn={incrementUpdate}
                    title={"Task Based"}
                    // estimateType={"templates"}
                    key={pathname}
                    type={true}
                  />
                  <SettingsTable
                    updateCnt={update}
                    update={incrementUpdate}
                    estimateType={"templates"}
                  />
                </section>
              </React.Fragment>
            </Route>

            <Route path="/estimates-timebased/:projectName/:key" key="3">
              <React.Fragment>
                <Header updateCnt={update}
                  update={incrementUpdate} />
                <section
                  className="estimated-list"
                  style={{ minHeight: "calc(100vh - 60px)" }}
                >
                  <EstimateFor
                    // update={update}
                    // updateFn={incrementUpdate}
                    key={pathname}
                    title={"Time Based"}
                    // estimateType={"estimates-timebased"}
                    type={true}
                  />
                  <SettingsTable
                    updateCnt={update}
                    update={incrementUpdate}
                    estimateType={"estimates-timebased"}
                  />
                </section>
              </React.Fragment>
            </Route>
            <Route path="/templates-timebased/:projectName/:key" key="4">
              <React.Fragment>
                <Header updateCnt={update}
                  update={incrementUpdate} />
                <section
                  className="estimated-list"
                  style={{ minHeight: "calc(100vh - 60px)" }}
                >
                  <EstimateFor
                    // update={update}
                    // updateFn={incrementUpdate}
                    title={"Time Based"}
                    key={pathname}
                    // estimateType={"templates-timebased"}
                    type={true}
                  />
                  <SettingsTable
                    updateCnt={update}
                    update={incrementUpdate}
                    estimateType={"templates-timebased"}
                  />
                </section>
              </React.Fragment>
            </Route>
            {
              routeslog.map((route, index) => {
                return route.admin !== true || isAdmin ? <Route key={index} path={route.path} exact={route.exact}>
                  {route.component}
                </Route> : <Route key={route.path} path={route.path} exact={route.exact}> <AccessDenied /></Route>
              })}
            <Route path="/shared-estimates/:projectName/:key">
              <React.Fragment>
                <Header updateCnt={update}
                  update={() => { }} />
                <section
                  className="estimated-list share-estimate"
                  style={{ minHeight: "calc(100vh - 60px)" }}
                >
                  <SharedEstimateFor
                    update={0}
                    updateFn={() => { }}
                    title={"Task Based"}
                    type={true}
                    key={pathname}
                  />
                  <SettingsTable
                    updateCnt={0}
                    update={() => { }}
                    shared={true}
                    estimateType={"estimates"}
                  />
                </section>
              </React.Fragment>
            </Route>
          </Switch>
        ) : (
          !isLogedin && (
            <Switch key="2">
              <Route path="/shared-estimates/:projectName/:key">
                <React.Fragment>
                  <SharedHeader updateCnt={update}
                    update={() => { }} />
                  <section
                    className="estimated-list share-estimate"
                    style={{ minHeight: "calc(100vh - 60px)" }}
                  >
                    <SharedEstimateFor
                      update={0}
                      updateFn={() => { }}
                      title={"Task Based"}
                      type={true}
                      key={pathname}
                    />
                    <SettingsTable
                      updateCnt={0}
                      update={() => { }}
                      shared={true}
                      estimateType={"estimates"}
                    />
                  </section>
                </React.Fragment>
              </Route>
              {
                routes.map((route) => {
                  return route.admin !== true || isAdmin ? <Route key={route.path} {...route} /> : <Route key={route.path} path={route.path} exact={route.exact}> <AccessDenied /></Route>
                })}
            </Switch>
          )
        )
        }
        <Footer />
      </StylesProvider >
    </React.Fragment >
  );
}

// export default withRouter(App);

function MapStateToProps(state) {
  return {
    user: state.getUser.user
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getUserAction: bindActionCreators(
      getUserActionCreators,
      dispatch
    ),
  };
}
export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(App);
