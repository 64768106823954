import React from 'react';
import axios from 'axios';
import sha256 from 'crypto-js/sha256'

import '../../assets/scss/signup.scss';
import fire from '../../config/firebase';
import LoginForm from "../small-components/login/LoginForm";
import Login2FA from "../small-components/login/Login2FA";
import { Alert, AlertTitle } from "@material-ui/lab";
import { getFunctionUrl } from '../../config/firebase';
import {
  Grid,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase';
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem('storedEmail') || '',
      password: '',
      error: false,
      passwordReset: false,
      errorMsg: "Incorrect email or password",
      step2FA: false,
      patchalert: false
    };
    this.login = this.login.bind(this);
    this.login2FA = this.login2FA.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2FA = this.handleChange2FA.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleChange2FA(e) {
    this.setState({
      enteredToken: e.target.value
    });
  }
  login(e, password, remember = false) {

    e.preventDefault();
    const th = this;
    this.setState({
      password: password
    });
    const { email } = this.state;
    localStorage.setItem('storedEmail', remember ? email : '')

    fire.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        fire.auth().signInWithEmailAndPassword(email, password).then(async (authResult) => {

          const userCompanyRef = fire.database().ref('userCompany').child(authResult.user.uid);
          const userCompanySnap = await userCompanyRef.once('value');
          const userCompany = userCompanySnap.val();
          const { companyId = '' } = userCompany || {};
          const enable2fa = false;
          if (enable2fa) {
            const result = await axios.get(`${getFunctionUrl()}/send2faEmail?email=${encodeURIComponent(email)}&time=${new Date().getTime()}`,
              {
                headers: {
                  'access-control-request-headers': 'content-type,x-client-version',
                  'accept': '*/*'
                }
              });
            this.setState({ step2FA: true, token: result.data.token, companyId });

          } else {
            localStorage.setItem('companyId', companyId);
            setTimeout(() => {
              const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
              this.props.history.push(publicUrl);
            }, 1000);
          }
        }).catch((error) => {
          if (error.code === 'auth/invalid-email') {
            th.setState({
              errorMsg: "An error has occurred. Contact your admin for more information."
            })
          } else if (error.code === 'auth/internal-error') {
            th.setState({
              errorMsg: "We are currently facing issues. Please try again later."
            })
          } else {
            th.setState({
              errorMsg: "Incorrect email or password"
            })
          }
          th.setState({
            error: true
          });
        });
      });
  }

  login2FA(e, enetredToken) {

    e.preventDefault();
    const hash = sha256(enetredToken).toString();

    if (hash === this.state.token) {
      localStorage.setItem('companyId', this.state.companyId);
      setTimeout(() => {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
        this.props.history.push(publicUrl);
      }, 1000);
    }

  }

  forgotPassword = (e) => {
    e.preventDefault();
    this.props.history.push("/forgotpassword");
    // fire.auth().sendPasswordResetEmail(this.state.email)
    //   .then(function () {
    //     th.setState({
    //       passwordReset: true
    //     });
    //   }).catch(function (e) {
    //     console.log(e)
    //   })
  }
  closeAlert = () => {
    this.setState({
      error: false,
      passwordReset: false,
      patchalert: false
    })
  }

  componentDidMount = () => {
    localStorage.removeItem("honey:core-sdk")
    localStorage.removeItem("searchText")
    localStorage.removeItem("firebase:host:estimatic-staging-default-rtdb.firebaseio.com")
    localStorage.removeItem("storedEmail")
    localStorage.removeItem("companyId")
  }
  render() {
    const { step2FA = false } = this.state;
    return (
      <React.Fragment>
        <Grid item xs className="error" style={this.state.error ? { 'display': 'block' } : { 'display': 'none' }}>
          <Alert onClose={this.closeAlert} severity="error">
            <AlertTitle style={{ fontWeight: 100 }}>{this.state.errorMsg}</AlertTitle>
          </Alert>
        </Grid>
        <Grid item xs className="forget-pass-alert" style={this.state.passwordReset ? { 'display': 'block' } : { 'display': 'none' }}>
          <Alert onClose={this.closeAlert} severity="info">
            <AlertTitle style={{ fontWeight: 300 }}>Please check your email. If you have not received your email please check your spam/trash folder.</AlertTitle>
          </Alert>
        </Grid>
        <Grid item xs className="patch-alert" style={this.state.patchalert ? { 'display': 'block' } : { 'display': 'none' }}>
          <Alert onClose={this.closeAlert} severity="info">
            <AlertTitle style={{ fontWeight: 300 }}>There will be Site Maintenance on January 29 @5pm. Services will be unavaiable while we work on updates. Service should be available at 8pm. </AlertTitle>
          </Alert>
        </Grid>
        {step2FA ?
          <Login2FA
            error={this.state.error}
            submit={this.login2FA}
            change={this.handleChange2FA}
            forget={this.forgotPassword}
          />
          :
          <LoginForm
            error={this.state.error}
            submit={this.login}
            change={this.handleChange}
            forget={this.forgotPassword}
            storedEmail={localStorage.getItem('storedEmail')}
          />
        }
      </React.Fragment>
    )
  }
}

export default withRouter(LoginPage);