export const templateIcon = () => <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21.522"
    viewBox="0 0 20 21.522"
>
    <g>
        <g>
            <path
                fillRule="evenodd"
                d="M19.182 1H6.455a1.9 1.9 0 0 0-1.819 1.957v13.7a1.9 1.9 0 0 0 1.818 1.957h12.728A1.9 1.9 0 0 0 21 16.653V2.957A1.9 1.9 0 0 0 19.182 1zM2.818 4.913H1v15.653a1.9 1.9 0 0 0 1.818 1.957h14.546v-1.957H2.818zm3.637 11.74h12.727V2.957H6.455z"
                transform="translate(1590 -291) translate(-1590 291) translate(-1 -1)"
            />
        </g>
        <g>
            <path
                d="M45.172 14.781h-1.4v-1.4a.375.375 0 0 0-.375-.375h-.9a.375.375 0 0 0-.374.375v1.4h-1.4a.375.375 0 0 0-.374.375v.9a.375.375 0 0 0 .374.374h1.4v1.4a.374.374 0 0 0 .374.374h.9a.375.375 0 0 0 .375-.374v-1.4h1.4a.374.374 0 0 0 .375-.374v-.9a.375.375 0 0 0-.375-.375"
                transform="translate(1590 -291) translate(-1580.715 295.159) translate(-40.356 -13.01)"
            />
            <path
                d="M44.606 40.814H46a.375.375 0 0 0 .375-.374v-.9a.375.375 0 0 0-.375-.374h-3.63a.374.374 0 0 0-.375.374v.9a.374.374 0 0 0 .375.374h2.237z"
                transform="translate(1590 -291) translate(-1580.715 295.159) translate(-41.591 -31.72)"
            />
        </g>
    </g>
</svg>;
export const estimateIcon = () => <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="23"
    viewBox="0 0 20 23"
>
    <defs>
        <clipPath id="vzcsv22sga">
            <path fill="none" d="M0 0H20V23H0z" />
        </clipPath>
    </defs>
    <g clipPath="url(#vzcsv22sga)">
        <path
            d="M16.572 0H3.088A3.017 3.017 0 0 0 0 2.934V19.1a3.017 3.017 0 0 0 3.088 2.934h13.484A3.017 3.017 0 0 0 19.66 19.1V2.934A3.017 3.017 0 0 0 16.572 0M17.6 19.1a1.006 1.006 0 0 1-1.029.978H3.088a1.006 1.006 0 0 1-1.029-.978V2.934a1.006 1.006 0 0 1 1.029-.978h13.484a1.006 1.006 0 0 1 1.029.978zM4.117 9.681h11.426v-5.77H4.117zm2.059-3.814h7.308v1.858H6.176zm-1.54 10.2h6.275v1.956H4.636zm2.055-2.476H4.632v-1.954h2.059zm2.162-1.956h2.059v1.956h-2.06zm4.117 0h2.059v1.956H12.97zm0 4.432h2.059v1.956H12.97z"
            transform="translate(0 .3)"
        />
    </g>
</svg>