import React, { Component } from 'react';
import Slider from "react-slick";
import './testimonial-slider.scss';
import Image from '../../../assets/images/slider-dummy.png';
class Testinomial extends Component {
  constructor() {
    super();
  }
  render() {
    const data = [
      {
        'images': Image,
        'title': 'Coming Soon',
      },
      {
        'images': Image,
        'title': 'Coming Soon',
      },
      {
        'images': Image,
        'title': 'Coming Soon',
      },
    ]
    const items = []
    for (let i = 0; i < data.length; i++) {
      items.push(
        <div className="col-1" key={i}>
          <div className="description">
            <div className="author-info">
              <div className="icons">
                <img src={data[i].images} alt="icons" />
              </div>
            </div>
            <h4>{data[i].title}</h4>
          </div>
        </div>)
    }
    const settings = {
      dots: true,
      arrows: false,
      autoplay: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1
    };
    return (
      <div className="testimonial-slider">
        <div className="container">
          <h5>Tip of the day</h5>
          <Slider {...settings}>
            {items}
          </Slider>
        </div>
      </div>
    )
  }
}

export default Testinomial;