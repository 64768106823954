import React from 'react';
import ScopeTable from "../reusable-components/ScopeTable/ScopeTable";
import { createStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    newestimate: {
      backgroundColor: "#000000",
      color: "#ffffff",
      borderRadius: "50px",
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover": {
        backgroundColor: "#000000",
      }
    },

    sortBy: {
      marginTop: "21px",
      "&:hover": {
        backgroundColor: "transparent"
      },
      "&:focus": {
        backgroundColor: "transparent"
      }
    },
    sortCol: {
      textAlign: "right",
      position: "relative"
    },
    innerItem: {
      padding: "20px 30px",
    },
    pagination: {
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      "& nav": {
        boxShadow: "none",
        height: "auto",
        backgroundColor: "transparent"
      },
      "& ul": {
        position: "relative",
        top: "0px",
        width: "100%",
        border: "0px",
        boxShadow: "none",
        backgroundColor: "transparent",
        justifyContent: "center"
      }
    }
  }));
const Template = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ScopeTable classes={classes} estimateType="templates" update={props.update} />
    </React.Fragment>
  );
}

export default Template;