import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    tags: null,
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

// asynchronous function with createAsyncThunk
export const getTagsAsync = createAsyncThunk(
    'tags/getTags',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const reftagList = fire.database().ref(companyId).child("tags");
        const snapshotTag = await reftagList.once("value");
        const tags = snapshotTag.val();
        reftagList.off("value");
        const allTags = [];
        for (const id in tags) {
            allTags.push({ id, ...tags[id] });
        }
        return { tags: allTags };
    }
);

// Redux Toolkit slice
export const getTagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTagsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTagsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tags = action.payload.tags
            });
    },
});

export default getTagsSlice.reducer;
