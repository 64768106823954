import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconDropdown from "./IconDropdown/IconDropdown.js";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";
const IconMenuItem = React.forwardRef((props) => {
  const PropIcon = props.icon;
  const remove = () => {
    props.optionValues(props.itemsid, props.contacttype);
  };
  return (
    <MenuItem
      value={props.value}
      itemsid={props.itemsid}
      contacttype={props.contacttype}
      onClick={remove}
    >
      <ListItemIcon>{PropIcon && <PropIcon />}</ListItemIcon>
      <ListItemText primary={props.children} />
    </MenuItem>
  );
});
class addContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  remove = (keyite, contacttype) => {
    this.props.removeItem(keyite, contacttype);
  };
  handleClick = (id, database, title) => {
    if (database === "people") {
      this.props.openEditDrawer("edit", id, "People");
    } else if (database === "estimates") {
      localStorage.setItem("key", id);
      localStorage.setItem("editDatabase", "estimates");
      localStorage.setItem("projectName", title);
      localStorage.setItem("dataType", "estimates");
      this.props.history.push(`/estimates`);
    }
  };
  render() {
    return (
      <ListItem key={this.props.keyite} alignItems="flex-start">
        <ListItemIcon>{this.props.icon}</ListItemIcon>
        <ListItemText
          primary={this.props.title}
          secondary={
            this.props.email && (
              <React.Fragment>
                <Typography
                  component="span"
                  variant="span"
                  style={{ color: "#084d4f", fontSize: "12px" }}
                >
                  {" "}
                  {this.props.email}{" "}
                </Typography>
              </React.Fragment>
            )
          }
          onClick={() =>
            this.handleClick(
              this.props.keyite,
              this.props.contacttype,
              this.props.title
            )
          }
        ></ListItemText>
        <ListItemSecondaryAction>
          <IconDropdown>
            <IconMenuItem
              value="delete"
              icon={RemoveCircleOutlineIcon}
              itemsid={this.props.keyite}
              contacttype={this.props.contacttype}
              optionValues={this.remove}
            >
              {" "}
              Remove
            </IconMenuItem>
          </IconDropdown>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}
export default withRouter(addContact);
