import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import AsideDrawer from "../small-components/AsideDrawer/AsideDrawer";
import InputField from "../small-components/UI/InputField";
import Legend from "../small-components/UI/Legend";
import { DefaultButton } from "../small-components/UI/Buttons";
import StatBox from "../small-components/StatBox/StatBox";
import { withStyles } from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AlertDialog from "../small-components/AlertDialog/AlertDialog";
import { Alert, AlertTitle } from "@material-ui/lab";
import "../../assets/scss/company-setting.scss";
import { debounce } from "lodash";
import fire from "../../config/firebase";
import MenuDropdown from "../small-components/MenuDropdown/MenuDropdown";
import MenuItem from "@material-ui/core/MenuItem";
const styles = () => ({
  paragraph: {
    marginTop: 10,
    "& > .MuiTypography-colorPrimary": {
      color: "#084d4f",
    },
  },
  greenLabel: {
    "& .PrivateNotchedOutline-legendLabelled-52 > span": {
      color: "rgba(8, 77, 79, 0.5) !important",
    },
    "& .MuiFormLabel-root": {
      left: 6,
    },
    "& .MuiFormLabel-filled": {
      color: "rgba(8, 77, 79, 0.5) !important",
    },

    "& .MuiOutlinedInput-root": {
      "&::before": {
        content: '"$"',
        position: "absolute",
        left: 10,
        top: 12,
      },
      "& input": {
        paddingLeft: 10,
      },
    },
  },
  autoOuter: {
    paddingBottom: "10px",
  },
  tabWrap: {
    margin: 0,
    width: "100%",
    "& > .MuiGrid-item": {
      padding: 0,
      paddingRight: "59px",
      paddingBottom: "20px",
    },
    "& .btnoutr.active ": {
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
    "& span.MuiTypography-h5 ": {
      fontWeight: "500",
      "&+ div ": {
        "& button ": {
          minWidth: 20,
          marginRight: 2,
          padding: 0,
          "& svg ": {
            width: 20,
            height: 20,
          },
          "&+ div ": {
            "& .select-menu-wrap": {
              left: "-56px",
              position: "relative",
            },
          },
        },
      },
    },
  },

  paper: {
    marginTop: 32,
    padding: "32px 30px 50px",
  },
  paperOutlined: {
    borderColor: "rgba(8, 77, 79, 0.1)",
  },
  textField: {
    maxWidth: 461,
    marginLeft: -11,
    "&:hover $inputLabel": {
      opacity: 1,
    },
    "&:hover $outlinedInputFieldset": {
      opacity: 1,
    },
    "& label ": {
      fontWeight: 300,
    },
  },
  inputLabel: {
    opacity: 0,
  },
  outlinedInput: {
    fontSize: "25px !important",
    fontWeight: 300,
  },
  outlinedInputFieldset: {
    opacity: 0,
  },
  divider: {
    backgroundColor: "rgba(8, 77, 79, 0.2)",
  },
  gridSpacing: {
    width: "100%",
    margin: "0",
    "& > .MuiGrid-item": {
      marginTop: 20,
      padding: "0 40px 0 0",
    },
  },
  inputDescription: {
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 320,
    fontWeight: 300,
  },
  input: {
    marginTop: 20,
  },
  defaultButton: {
    minWidth: 108,
  },
  asideGridWidthFour: {
    flexGrow: 0,
    maxWidth: 100,
    flexBasis: 100,
  },
  asideGridWidthEight: {
    flexGrow: 0,
    maxWidth: "calc(100% - 100px)",
    flexBasis: "calc(100% - 100px)",
  },
  label: {
    marginTop: 30,
  },
  Inputlabel: {
    "& label": {
      fontWeight: 400,
    },
    "& input": {
      fontWeight: 400,
    },
  },
  radio: {
    padding: "5px 10px",
    color: "#084d4f !important",
  },
  radioChecked: {
    color: "#084d4f !important",
  },
  radioDisabled: {
    color: "#00000042 !important",
  },
  radioLabel: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#000000",
    textTransform: "capitalize",
    "&.Mui-disabled": {
      fontWeight: 400,
    },
  },
  buttonWrap: {
    "& > .MuiButton-outlined": {
      marginLeft: 10,
    },
  },
  title: {
    "& > input": {
      fontSize: "30px !important",
    },
  },
  actionButtonsWrap: {
    marginLeft: -5,
    paddingRight: 5,
  },
  actionButton: {
    marginTop: 5,
    padding: 5,
  },
  actionButtonLabelAdd: {
    width: 40,
    height: 40,
    borderRadius: 5,
    backgroundColor: "#e4eded",
  },
  actionButtonLabelRemove: {
    width: 40,
    height: 40,
    borderRadius: 5,
    backgroundColor: "#e4eded",
  },
  actionButtonIcon: {
    width: 24,
    height: 24,
  },
  asideButton: {
    minWidth: 100,
  },
  userbtndisabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
    color: "#fff !important",
    pointerEvents: "none",
  },
  asideMenuDropdown: {
    marginTop: 10,
    width: "100%",
  },
  asideMenuDropdownItem: {
    backgroundColor: "#fff !important",
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.08) !important",
    },
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: "31px !important",
  },
  asideMenuDropdownIcon: {
    marginRight: "5.5px",
  },
});

class CompanySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "",
      drawerState: false,
      dialogOpen: false,
      phaseKey: "",
      key: "",
      typeCast: "",
      arrayItem: [],
      editList: [
        {
          type: "Edit",
          icon: <EditOutlinedIcon htmlColor="#000" />,
          id: "edit",
        },
        {
          type: "Duplicate",
          icon: <FileCopyOutlinedIcon htmlColor="#000" />,
          id: "duplicate",
        },
        {
          type: "Delete",
          icon: <DeleteOutlinedIcon htmlColor="#000" />,
          id: "delete",
        },
      ],
      listToShow: [],
      itemKey: "",
      optionAction: "",
      drawerId: "",
      drawerName: "",
      drawerType: "",
      drawerValueType: "positive",
      drawerValue: "",
      saveInfo: false,
      drawerDefaultName: "",
      drawerDefaultValue: "",
      drawerDefaultType: "",
      uniqueID: 0,
      hourlyRate: 0,
      hourlyCost: 0,
      vendorUpsell: 0,
      targetMargin: 0,
      companyName: "",
      company: [],
      saveBtnDisable: true,
      applyBtnDisabled: true,
      showMsgSaved: false,
      error: false,
      rounding: 0,
      targetBudget: 5000,
    };
    this.refreshData = this.refreshData.bind(this);
    this.savedChanges = this.savedChanges.bind(this);
    this.company = this.company.bind(this);
    this.setSaveBtnDisable = this.setSaveBtnDisable.bind(this);
  }
  refreshData() {
    const companyId = localStorage.getItem('companyId');
    const refCompany = fire.database().ref(companyId).child("company");
    refCompany.once("value").then((snapshot) => {
      if (snapshot.exists()) {
        const company = snapshot.val();
        refCompany.off("value");
        console.log(company)
        this.setState({
          companyName: company.companyName,
          targetMargin: company.targetMargin,
          vendorUpsell: company.vendorUpsell,
          hourlyRate: company.hourlyRate,
          hourlyCost: company.hourlyCost,
          targetBudget: company.targetBudget,
          arrayItem: company.arrayItem || [],
          rounding: company.rounding,
        });
      }
    });
    if (this.props.updateFn) {
      this.props.updateFn();
    }
  }
  componentDidMount() {
    this.refreshData();
  }

  inputText = debounce((e) => {
    this.setState({
      key: e,
      page: 1,
    });
  }, 700);

  toggleDrawer = (open, type) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ drawerState: open, drawerType: type });
    if (open === false) {
      this.setState({
        drawerName: "",
        drawerType: "",
        drawerValueType: "positive",
        drawerValue: "",
        saveInfo: false,
        drawerDefaultName: "",
        drawerDefaultValue: "",
        drawerDefaultType: "",
      });
    }
  };
  toggleDraw = (type) => {
    this.setState({
      drawerState: true,
      drawerType: type,
      drawerValueType: "positive",
    });
  };
  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };
  setSaveBtnDisable(val) {

    if (this.props.setChangeRecord) {
      this.props.setChangeRecord({ CompanySettings: !val });
    }
    this.setState({ saveBtnDisable: val });
  }
  optionChange = debounce((value, itemId) => {
    if (value === "delete") {
      const arrayItem = this.state.arrayItem.slice();
      this.setState({
        dialogOpen: true,
        phaseKey: itemId,
        optionAction: value,
        arrayItem: arrayItem,
      });
    } else if (value === "duplicate") {
      this.setSaveBtnDisable(false);
      const arrayItem = this.state.arrayItem.slice();
      for (let i = 0; i < arrayItem.length; i++) {
        if (arrayItem[i].id === itemId) {
          const dupItem = {
            figure: this.items[i].figure,
            id: arrayItem.length + 1,
            legend: this.items[i].legend + " Copy",
            type: this.items[i].type,
          };
          arrayItem.push(dupItem);
          break;
        }
      }
      let maxValue = 0;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i]["id"] > maxValue) {
          maxValue = this.items[i]["id"];
        }
      }
      this.setState({
        arrayItem: arrayItem,
      });
      setTimeout(() => {
        this.setState({
          optionAction: "",
        });
      }, 1000);
    } else {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i]["id"] === itemId) {
          this.setState({
            drawerId: this.items[i]["id"],
            phaseKey: itemId,
            optionAction: value,
            drawerState: true,
            drawerType: "old",
            drawerDefaultName: this.items[i]["legend"],
            drawerDefaultValue: this.items[i]["figure"],
            drawerName: this.items[i]["legend"],
            drawerValue: this.items[i]["figure"],
            drawerValueType: this.items[i]["type"],
          });
        }
      }
      setTimeout(() => {
        this.setState({
          optionAction: "",
        });
      }, 1000);
    }
  });
  drawerDelete = () => {
    this.setState({
      dialogOpen: true,
      optionAction: "delete",
    });
  };
  deleteHandler = debounce((key) => {
    this.setSaveBtnDisable(false);
    const arrayItem = this.state.arrayItem.slice();
    for (let i = 0; i < arrayItem.length; i++) {
      if (arrayItem[i].id === key) {
        arrayItem.splice(i, 1);
        break;
      }
    }
    this.setState({
      itemKey: key,
      arrayItem: arrayItem,
      dialogOpen: false,
      drawerState: false,
    });
    setTimeout(() => {
      this.setState({
        itemKey: "",
      });
    }, 300);
  });
  //this is the one working to cancel
  closeHandler = () => {
    this.setState({ dialogOpen: false });
  };
  drawerInput = debounce((e) => {
    this.setState({ drawerName: e });
    this.setState({ applyBtnDisabled: false })
    //this.setSaveBtnDisable(false);
  }, 0);
  drawerFigureInput = debounce((e) => {
    this.setState({ drawerValue: e });
    this.setState({ applyBtnDisabled: false })
    //this.setSaveBtnDisable(false);
  }, 0);
  handleDrawerValueType = debounce((e) => {
    this.setState({ drawerValueType: e });
    this.setState({ applyBtnDisabled: false })
  }, 0);
  handleHourlyRate = debounce((e) => {
    this.setState({ hourlyRate: e });
    this.setSaveBtnDisable(false);
  }, 0);
  handleHourlyCost = debounce((e) => {
    this.setState({ hourlyCost: e });
    this.setSaveBtnDisable(false);
  }, 0);
  handleTargetBudget = debounce((e) => {
    if (e.length > 7) {
      return
    }
    this.setState({ targetBudget: e });
    this.setSaveBtnDisable(false);
  }, 0);
  handleVendorUpsell = debounce((e) => {
    this.setState({ vendorUpsell: e });
    this.setSaveBtnDisable(false);
  }, 0);
  handleTargetMargin = debounce((e) => {
    this.setState({ targetMargin: e });
    this.setSaveBtnDisable(false);
  }, 0);
  handleIsInput = debounce((e) => {
    this.setState({ companyName: e });
    this.setSaveBtnDisable(false);
  }, 0);
  handleRoundingOptions = (e) => {
    this.setState({ rounding: e });
    this.setSaveBtnDisable(false);
  };
  removeActive = () => {
    document
      .querySelector(".company-settings .btnoutr")
      .classList.remove("active");
  };
  saveItem = (e) => {
    this.setSaveBtnDisable(false);
    let arrayItems = this.state.arrayItem.slice();
    let maxValue = 0;
    if (
      this.state.drawerType === "new" ||
      this.state.optionAction === "duplicate"
    ) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i]["id"] > maxValue) {
          maxValue = this.items[i]["id"];
        }
      }
    }

    if (this.state.drawerType === "old") {
      for (let i = 0; i < arrayItems.length; i++) {
        if (arrayItems[i].id === this.state.drawerId) {
          arrayItems[i].figure = this.state.drawerValue;
          arrayItems[i].legend = this.state.drawerName;
          arrayItems[i].type = this.state.drawerType;
        }
      }
    } else {
      const arrayAutoAdd = {
        id: maxValue + 1,
        legend: this.state.drawerName,
        type: this.state.drawerValueType,
        figure: this.state.drawerValue,
      };
      arrayItems = [...arrayItems, arrayAutoAdd];
    }

    this.setState({
      saveInfo: true,
      drawerState: false,
      uniqueID: maxValue + 1,
      arrayItem: arrayItems || [],
      applyBtnDisabled: true,
    });
    setTimeout(() => {
      this.setState({
        saveInfo: false,
        drawerName: "",
        drawerType: "",
        drawerValueType: "positive",
        drawerValue: "",
        drawerDefaultName: "",
        drawerDefaultValue: "",
        drawerDefaultType: "",
      });
    }, 500);
    e.stopPropagation();
    document
      .querySelector(".company-settings .btnoutr")
      .classList.add("active");
  };

  closeAlert = () => {
    this.setState({
      error: false,
      showMsgSaved: false
    })
  }
  savedChanges() {

    return (<div>
      <Grid item xs className="error" style={{ paddingTop: '32px', display: 'block', position: 'fixed', zIndex: 99999, top: '-20px' }}>
        <Alert onClose={this.closeAlert} severity="success">
          <AlertTitle style={{ fontWeight: 100 }}>Your changes have been saved</AlertTitle>
        </Alert>
      </Grid>
    </div>
    )
  }
  //Testing Company Shant
  company() {
    const companyId = localStorage.getItem('companyId');
    const companyRef = fire.database().ref(companyId).child("company");

    companyRef.update({
      companyName: this.state.companyName,
      hourlyRate: this.state.hourlyRate,
      hourlyCost: this.state.hourlyCost,
      targetBudget: this.state.targetBudget,
      vendorUpsell: this.state.vendorUpsell,
      targetMargin: this.state.targetMargin,
      arrayItem: this.state.arrayItem || [],
      rounding: this.state.rounding,
    });
    this.savedChanges();
    this.refreshData();
    this.setSaveBtnDisable(true);
    this.setState({ showMsgSaved: true });
  }

  render() {
    //change the values to be the ones from
    this.items = [...this.state.arrayItem];
    const { classes } = this.props;
    const th = this;
    let listToShow = "";
    listToShow = this.items.filter(function (i) {
      return i;
    });
    // Option actions

    if (th.state.itemKey !== "") {
      let newIndex = "";
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i]["id"] === th.state.itemKey) {
          newIndex = i;
        }
      }
      this.items.splice(newIndex, 1);
      listToShow = this.items;
    } else if (th.state.optionAction === "duplicate") {
      let newArr = "";
      let newIndex = "";
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i]["id"] === th.state.phaseKey) {
          newIndex = i;
          newArr = {
            id: this.state.uniqueID,
            legend: this.items[i]["legend"],
            type: this.items[i]["type"],
            figure: this.items[i]["figure"],
          };
        }
      }
      this.items.splice(newIndex + 1, 0, newArr);
      listToShow = this.items;
    }
    // Add new
    if (th.state.drawerType === "new" && th.state.saveInfo === true) {
      this.items.push({
        id: this.state.uniqueID,
        legend: "" + th.state.drawerName + "",
        type: "" + th.state.drawerValueType + "",
        figure: th.state.drawerValue,
      });
      listToShow = this.items;
    }
    //Edit old value
    if (th.state.drawerType === "old" && th.state.saveInfo === true) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i]["id"] === th.state.phaseKey) {
          if (th.state.drawerName === "") {
            this.items[i]["legend"] = "" + th.state.drawerDefaultName + "";
          } else {
            this.items[i]["legend"] = "" + th.state.drawerName + "";
          }
          if (th.state.drawerValue === "") {
            this.items[i]["figure"] = th.state.drawerDefaultValue;
          } else {
            this.items[i]["figure"] = th.state.drawerValue;
          }
          this.items[i]["type"] = "" + th.state.drawerValueType + "";
        }
      }
      listToShow = this.items;
    }

    return (
      <Box
        className="company-settings"
        onClick={this.removeActive}
        style={{
          paddingTop: 77,
          minHeight: "calc(100vh - 60px)",
          backgroundColor: "rgba(8,77,79,0.05)",
        }}
      >
        <Box
          component="section"
          className="company-settings"
          style={{ padding: "23px 0" }}
        >
          <Box className="container">
            <Grid container>
              <Grid item xs>
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ fontWeight: 100, fontSize: 25 }}
                >
                  Company Settings
                </Typography>
                <Typography
                  className={classes.paragraph}
                  variant="body1"
                  component="p"
                  style={{ fontWeight: 300, fontSize: 14 }}
                >
                  Manage all company settings such as hourly rate, default
                  vendor upsell, and auto-calculations
                </Typography>
              </Grid>
            </Grid>
            <Paper
              classes={{ root: classes.paper, outlined: classes.paperOutlined }}
              variant="outlined"
              elevation={0}
            >
              <Box pb={4.375}>
                <Legend>Company Name</Legend>
                <InputField
                  className={classes.textField + " title"}
                  label="Name"
                  inputProps={this.state.inputProps}
                  inputLabelProps={this.state.inputLabelProps}
                  onChange={(e) => this.handleIsInput(e.target.value)}
                  value={this.state.companyName}
                  style={{ fontWeight: 100 }}
                ></InputField>
              </Box>
              <Divider className={classes.divider} />
              <Box pt={3.75} pb={4.375} className="default-wrap">
                <Typography
                  component="h6"
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: 20 }}
                >
                  Default Settings
                </Typography>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  spacing={8}
                  classes={{
                    "spacing-xs-8": classes.gridSpacing + " default-block",
                  }}
                >
                  <Grid item xs={3} className={classes.greenLabel}>
                    <Legend id="hourly-rate">Hourly Rate</Legend>
                    <Typography
                      className={classes.inputDescription}
                      variant="body1"
                      component="p"
                      aria-describedby="hourly-rate"
                    >
                      Your default hourly rate is rate at which all roles
                      are billed at by default
                    </Typography>
                    {/*//add errorSrtate here*/}
                    <InputField
                      classes={classes.input}
                      className={this.state.hourlyRate > 999 && "errorState"}
                      type="number"
                      label={
                        this.state.hourlyRate > 999 ? "Exceeds limit" : "Rate"
                      }
                      aria-labelledby="hourly-rate"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => this.handleHourlyRate(e.target.value)}
                      value={this.state.hourlyRate}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.greenLabel}>
                    <Legend id="hourly-cost">Hourly Cost</Legend>
                    <Typography
                      className={classes.inputDescription}
                      variant="body1"
                      component="p"
                      aria-describedby="hourly-cost"
                    >
                      Your default hourly cost rate at which all roles are
                      billed to the client.
                    </Typography>

                    <InputField
                      classes={classes.input}
                      type="number"
                      label="Rate"
                      aria-labelledby="hourly-cost"
                      // adornment="$"
                      //adornmentPosition="start"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => this.handleHourlyCost(e.target.value)}
                      value={this.state.hourlyCost}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Legend id="vendor-upsell">Vendor Upsell</Legend>
                    <Typography
                      className={classes.inputDescription}
                      variant="body1"
                      component="p"
                      aria-describedby="vendor-upsell"
                    >
                      Your default vendor upsell is the default percentage your
                      vendors will be marked up.
                    </Typography>
                    {/*//add errorSrtate here*/}
                    <InputField
                      className={this.state.vendorUpsell > 99 && "errorState"}
                      classes={classes.input}
                      type="number"
                      label={
                        this.state.vendorUpsell > 99
                          ? "Exceeds limit"
                          : "Percentage"
                      }
                      aria-labelledby="vendor-upsell"
                      adornment="%"
                      adornmentPosition="end"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => this.handleVendorUpsell(e.target.value)}
                      value={this.state.vendorUpsell}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Legend id="vendor-upsell">TARGET PROJECT MARGIN</Legend>
                    <Typography
                      className={classes.inputDescription}
                      variant="body1"
                      component="p"
                      aria-describedby="vendor-upsell"
                    >
                      Your default target margin is the default percentage for
                      your project.
                    </Typography>
                    {/*//add errorSrtate here*/}
                    <InputField
                      className={this.state.targetMargin > 99 && "errorState"}
                      classes={classes.input}
                      type="number"
                      label={
                        this.state.targetMargin > 99
                          ? "Exceeds limit"
                          : "Percentage"
                      }
                      aria-labelledby="target-Margin"
                      adornment="%"
                      adornmentPosition="end"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) => this.handleTargetMargin(e.target.value)}
                      value={this.state.targetMargin}
                    />
                  </Grid>

                </Grid>
              </Box>
              <Box pb={4.375} className="default-wrap">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={8}
                  classes={{
                    "spacing-xs-8": classes.gridSpacing + " default-block",
                  }}
                >
                  <Grid item xs={3} className={classes.greenLabel} >
                    <Legend id="rounding-options">Rounding Options</Legend>
                    <Typography
                      className={classes.inputDescription}
                      variant="body1"
                      component="p"
                      aria-describedby="rounding-options"
                    >
                      Set the rounding option for your estimate’s Project Total
                    </Typography>
                    <MenuDropdown
                      className={classes.asideMenuDropdown}
                      selectInputProps={{
                        classes: {
                          root: classes.asideMenuDropdownInput,
                          icon: classes.asideMenuDropdownIcon,
                        },
                      }}
                      aria-labelledby="options"
                      value={this.state.rounding}
                      change={(e) => {
                        this.handleRoundingOptions(e.target.value)
                      }}

                    >
                      <MenuItem key={0} value={0} className={classes.asideMenuDropdownItem}>
                        {'Round to: None'}
                      </MenuItem>
                      <MenuItem key={10} value={10} className={classes.asideMenuDropdownItem}>
                        {'Round to: Nearest Ten (10)'}
                      </MenuItem>
                      <MenuItem key={100} value={100} className={classes.asideMenuDropdownItem}>
                        {'Round to: Nearest Hundred (100)'}
                      </MenuItem>
                      <MenuItem key={1000} value={1000} className={classes.asideMenuDropdownItem}>
                        {'Round to: Nearest Thousand (1000)'}
                      </MenuItem>
                    </MenuDropdown>

                  </Grid>
                </Grid>
              </Box>

              <Divider className={classes.divider} />
              <Box
                py={3.75}
                className={classes.autoOuter + " calculation-wrap"}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  style={{ marginBottom: 13, fontWeight: 400, fontSize: 20 }}
                >
                  Auto Calculations
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={8}
                  className={classes.tabWrap}
                  classes={{
                    "spacing-xs-8": classes.gridSpacing + "drop-box drop-wrap",
                  }}
                >
                  <StatBox
                    items={listToShow}
                    page={this.state.page}
                    classes={this.props.classes + " drop-block"}
                    list={this.state.editList}
                    optionValue={this.optionChange}
                    triggerDrawer={this.toggleDraw}
                  />
                  {/* <StatBox legend="Add Calculation" onIconButtonClick={this.toggleDrawer(true, 'new')} /> */}
                </Grid>
              </Box>
              <Divider className={classes.divider} />
              <Box pt={3.75} onSubmit={this.onSubmit}>
                <DefaultButton
                  className={classes.defaultButton}
                  onClick={this.company}
                  disabled={!this.state.saveBtnDisable ? this.state.saveBtnDisable : true}
                >
                  Save
                </DefaultButton>
              </Box>
            </Paper>
            <Box mt={3.75}>
              <Typography
                component="h6"
                variant="h6"
                style={{ fontWeight: 400, fontSize: 20 }}
              >
                Deactivate Account
              </Typography>
              <Typography
                className={classes.paragraph}
                variant="body1"
                component="p"
                style={{ fontWeight: 300, fontSize: 14 }}
              >
                If you would like to end your subscription to Estimatic, you can
                reach out to us at{" "}
                <Link href="mailto:hello@estimatic.com">
                  hello@estimatic.com
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box component="aside">
          <AsideDrawer
            toolbarTitle={`${this.state.drawerType === "new" ? "Add" : "Edit"
              } Auto Calculation`}
            open={this.state.drawerState}
            close={this.toggleDrawer(false)}
          >
            <Grid container className="companyDrawer">
              <Grid item xs={12}>
                <Legend className={classes.label} id="name">
                  Calculation Name
                </Legend>
                <InputField
                  classes={classes.Inputlabel}
                  className={this.state.drawerName.length > 30 && "errorState"}
                  defaultValue={
                    this.state.drawerType === "old"
                      ? this.state.drawerDefaultName
                      : ""
                  }
                  onChange={(e) => this.drawerInput(e.target.value)}
                  label={
                    this.state.drawerName.length > 30
                      ? "Name is too long"
                      : "Name"
                  }
                  aria-labelledby="name"
                  required
                />
              </Grid>
              <Grid item xs={12} container alignItems="center">
                <Grid
                  item
                  xs={4}
                  classes={{ "grid-xs-4": classes.asideGridWidthFour }}
                >
                  <Legend className={classes.label} id="percentage">
                    Action
                  </Legend>
                  <Box className={classes.actionButtonsWrap}>
                    <IconButton
                      aria-label="add"
                      classes={{
                        root: classes.actionButton,
                        label: classes.actionButtonLabelAdd,
                      }}
                      onClick={() => this.handleDrawerValueType("positive")}
                      className={`drawer-value-type ${this.state.drawerValueType === "positive"
                        ? "active"
                        : ""
                        }`}
                    >
                      <AddRoundedIcon
                        classes={{ root: classes.actionButtonIcon }}
                        htmlColor={`${this.state.drawerValueType === "positive"
                          ? "#ffffff"
                          : "#769d9d"
                          }`}
                      />
                    </IconButton>
                    <IconButton
                      aria-label="remove"
                      classes={{
                        root: classes.actionButton,
                        label: classes.actionButtonLabelRemove,
                      }}
                      onClick={() => this.handleDrawerValueType("negative")}
                      className={`drawer-value-type ${this.state.drawerValueType === "negative"
                        ? "active"
                        : ""
                        }`}
                    >
                      <RemoveRoundedIcon
                        classes={{ root: classes.actionButtonIcon }}
                        htmlColor={`${this.state.drawerValueType === "negative"
                          ? "#ffffff"
                          : "#769d9d"
                          }`}
                      />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={8}
                  classes={{ "grid-xs-8": classes.asideGridWidthEight }}
                >
                  <Legend className={classes.label} id="percentage">
                    Percentage
                  </Legend>
                  <InputField
                    className={classes.Inputlabel}
                    label={
                      this.state.drawerValue > 99 ? "Exceeds limit" : "Amount"
                    }
                    aria-labelledby="percentage"
                    type="number"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    adornment="%"
                    adornmentPosition="end"
                    defaultValue={
                      this.state.drawerType === "old"
                        ? this.state.drawerDefaultValue
                        : ""
                    }
                    onChange={(e) => this.drawerFigureInput(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Legend className={classes.label} id="apply-to">
              Apply To
            </Legend>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="apply-to"
                defaultValue="phase"
                name="apply-to"
                onChange={this.handleValue}
              >
                <FormControlLabel
                  value="phase"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.radioChecked,
                        disabled: classes.radioDisabled,
                      }}
                    />
                  }
                  label="Each Phase"
                  classes={{ label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="task"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.radioChecked,
                        disabled: classes.radioDisabled,
                      }}
                    />
                  }
                  label="Each Task"
                  classes={{ label: classes.radioLabel }}
                  disabled
                />
                <FormControlLabel
                  value="estimates"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.radioChecked,
                        disabled: classes.radioDisabled,
                      }}
                    />
                  }
                  label="Total Estimate"
                  classes={{ label: classes.radioLabel }}
                  disabled
                />
              </RadioGroup>
            </FormControl>

            <Box className={classes.buttonWrap} mt={2}>
              <DefaultButton
                classes={{ root: classes.asideButton }}
                className={`${this.state.drawerName !== "" && this.state.drawerValue !== ""
                  ? ""
                  : classes.userbtndisabled
                  }`}
                disabled={this.state.applyBtnDisabled}
                onClick={this.saveItem}
              >
                Apply
              </DefaultButton>
            </Box>
          </AsideDrawer>
        </Box>
        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={""}
        //optionValue={this.optionChange}
        />

        {this.state.showMsgSaved ? this.savedChanges() : null}

      </Box>
    );
  }
}

export default withStyles(styles)(CompanySettings);
