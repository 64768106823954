import React, { useEffect, useState } from 'react';
import { compose } from "redux";
import { connect } from 'react-redux';
import AutoCompleteResource from '../../reusable-components/autoComplete/autoCompleteResource';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import ItemHoverBased from '../../reusable-components/ItemHoverBased';
import fire from '../../../config/firebase';
import { withRouter } from 'react-router-dom';
import AlertDialog from '../AlertDialog/AlertDialog';
import PhaseCalculation from '../Phase/phaseCalculationUtil';
import ResourceEdit from './ResourceEdit';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

function Resource({
    resource = {},
    addResource,
    updateResource: updateResourceFn,
    deleteResource,
    duplicateResource,
    editResources,
    classes,
    toggleEditDrawer,
    toggleEditCostRateDrawer,
    editVendorValue,
    companyId,
    estimate,
    shared
}) {
    const { hideFinance } = estimate;
    const [focusField, setFocusField] = useState(null);
    const [updateCnt, setUpdateCnt] = useState(0);
    const updateResource = (updatedData, resource, fieldId) => {
        if (fieldId) {
            setFocusField(fieldId);
        }
        return updateResourceFn(updatedData, resource);
    }
    const phaseCalculationCls = new PhaseCalculation(
        {},
        {},
        estimate
    );
    const amount = phaseCalculationCls.resourceCalculate(resource, 'amount', false);
    const cost = phaseCalculationCls.resourceCalculate(resource, 'cost', false);
    const { resourceName, hours, type } = resource;
    const [show, showFun] = React.useState(false);
    const [cnt, setCnt] = React.useState(0);
    const [qty, qtyFun] = React.useState(1);
    const [resourcesRole, setResourcesRole] = React.useState([]);
    const [resourcesVendor, setVendorResources] = React.useState([]);
    const [dialogOpen, setdialogOpen] = useState(false);

    const initAsync = async (resource) => {
        safeQtyFun(resource ? resource.qty : 1);
        if (!shared) {
            const ref = fire.database().ref(companyId).child('resources');
            const snapshot = await ref.once('value');
            const resources = snapshot.val();
            ref.off('value');
            const resourceList = [];
            const role = "Role";
            const vendor = "Vendor";
            const { resourceRateOption = 'defaultRate', resourceRates = [] } = estimate;
            const getRate = (resource) => {
                if (resourceRateOption === 'customRate' || resourceRateOption === 'singleRate') {
                    const item = resourceRates.find(d => d.id === resource.id);
                    if (item && item.rate) {
                        resource.amountType = 'rate';
                        resource.amount = item.rate;
                    }
                }
            }

            for (const id in resources) {
                resources[id].icon = resources[id].type === 'Role' ? PeopleOutlineOutlinedIcon : WorkOutlineOutlinedIcon;
                const item = { id, ...resources[id] };
                getRate(item);
                resourceList.push(item);
            }
            const roleList = resourceList.filter(function (resource) {
                return resource.subtitle?.toLowerCase().match(role.toLowerCase());
            });
            const vendorList = resourceList.filter(function (resource) {
                return resource.subtitle?.toLowerCase().match(vendor.toLowerCase());
            });
            setResourcesRole(roleList);
            setVendorResources(vendorList);
        }
    }
    const safeQtyFun = (val) => {
        qtyFun(parseInt(val) || 1)
    }

    useEffect(() => {
        initAsync(resource);
    }, [resource]);

    const itemShow = () => {
        setCnt(cnt + 1)
        showFun(true);
    }
    const qtyIncrease = () => {
        safeQtyFun(qty + 1);
        if (resource.qty !== qty + 1) {
            updateResource({ ...resource, qty: qty + 1 });
        }
    }
    const qtyDecrease = () => {
        if (qty === 1) { return false; }
        safeQtyFun(qty - 1);
        if (resource.qty !== qty - 1) {
            updateResource({ ...resource, qty: qty - 1 });
        }
    }

    const qtyChange = (val) => {
        if (shared) {
            return
        }

        if (val < 1) {
            safeQtyFun(1);
            if (resource.qty !== 1) {
                updateResource({ ...resource, qty: 1 });
            }
        } else {
            safeQtyFun(val);
            if (resource.qty !== val) {
                updateResource({ ...resource, qty: val });
            }
        }
    }

    const isDigit = (inp = '') => {
        const valid = ['$', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
        for (let i = 0; i < inp.length; i++) {
            if (valid.includes(inp[i]) === false) {
                ;
                return false;
            }
        }
        return true;
    }

    const qtydelete = () => {
        deleteResource(resource);
        setdialogOpen(false);
    }
    const closeHandler = () => {
        setdialogOpen(false);
    }
    const updateResourceLocal = (data, resource) => {
        updateResource(data, resource);
    }
    const { exclude = false } = resource;
    const opacity = exclude ? 0.3 : 1;

    return (
        <div style={{ marginBottom: '0' }} className={exclude ? "one-line-elment excludeResource" : "one-line-elment"}>

            <div className="list" >
                {
                    type === 'Role' ? <PeopleOutlineOutlinedIcon className="resource_icon"
                        htmlColor="#2ab7ca"
                    /> : <WorkOutlineOutlinedIcon htmlColor="#2ab7ca" className="resource_icon"
                    />

                }
                <AutoCompleteResource
                    key={resource.resourceId + resourceName + cnt}
                    classes={classes.addTask}
                    update={(updateData) => updateResource(updateData, resource)}
                    insert={(newResource) => {
                        addResource(newResource, resource);
                    }}
                    resourceName={resource.resourceName || ""}
                    title={`${(resource.type === 'Vendor' ? 'Vendor' : 'Role')}  Name`}
                    show={() => { itemShow(); }}
                    dataList={type === 'Role' ? resourcesRole : resourcesVendor}
                    type={type}
                />

            </div>
            {shared !== true &&
                <ItemHoverBased
                    toggleEditCostRateDrawer={() => toggleEditCostRateDrawer(resource, updateResourceLocal)}
                    deleteHandle={() => { setdialogOpen(true) }}
                    duplicateResource={() => {
                        duplicateResource(resource);
                    }
                    }
                    resource={resource}
                    updateResource={updateResourceFn}
                    hours={hours}
                    editResources={() => editResources(resource)}
                    toggleEditDrawer={() => toggleEditDrawer(resource, updateResourceLocal)}
                    editVendorValue={editVendorValue}
                />}
            {(show || resourceName) && (
                <React.Fragment>
                    <div className='resource-wrap'>
                        <div className={shared == true && hideFinance != true ? "list drop-box qty gray-circle hideFinance" : "list drop-box qty gray-circle "} >
                            <ClickAwayListener onClickAway={() => { qtyChange(qty) }}>
                                <input type="number" min="1" value={qty}
                                    onChange={(e) => {
                                        if (isDigit(e.target.value) === false) {
                                            return false;
                                        }
                                        else {
                                            const val = e.target.value > 1 ? e.target.value : 1
                                            safeQtyFun(val);
                                        }
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.charCode === 13 && qty) {
                                            //this.setState({ inputValue: event.target.value });
                                            qtyChange(qty)
                                        }
                                    }}
                                />
                            </ClickAwayListener>
                            {shared !== true && (qty < 2 ?
                                <DeleteOutlinedIcon
                                    htmlColor="#000000"
                                    fontSize="small"
                                    className="delete"
                                    onClick={() => { setdialogOpen(true) }}
                                />
                                :
                                <RemoveIcon
                                    htmlColor="#000000"
                                    fontSize="small"
                                    className="delete"
                                    onClick={qtyDecrease}
                                />
                            )}

                            {shared !== true && <AddIcon
                                htmlColor="#000000"
                                fontSize="small"
                                className="add"
                                onClick={qtyIncrease}
                            />}
                        </div>
                        {
                            ((shared == true && hideFinance == true) || shared == false) && <div className="list hrs" >
                                <ResourceEdit
                                    fieldKey={resource.resourceId + 'hours' + cost}
                                    resource={resource}
                                    updateResource={updateResource}
                                    editable={true}
                                    max={10000}
                                    field='hours'
                                    prefix=""
                                    isFocused={focusField === resource.resourceId + 'hours'}
                                    key={updateCnt + resource.resourceId + 'hours' + cost}
                                />
                            </div>}
                        {shared !== true && <div className="list cost" >
                            <ResourceEdit
                                //editable={resource.type === 'Vendor'}
                                resource={resource}
                                updateResource={updateResource}
                                value={cost}
                                max={100000}
                                refresh={() => { setUpdateCnt(updateCnt + 1) }}
                                field='cost'
                                fieldKey={resource.resourceId + 'cost' + cost}
                                isFocused={focusField === resource.resourceId + 'cost'}
                            />
                        </div>}
                        <div className="list subtotal" >
                            <ResourceEdit
                                editable={false}
                                resource={resource}
                                updateResource={updateResource}
                                value={amount}
                                prefix={resource.amountType === 'percentage' ? '%' : '$'}
                                field='amount'
                            />
                        </div>
                    </div>
                </React.Fragment>
            )}
            <AlertDialog
                open={dialogOpen}
                onClose={closeHandler}
                keyValue={qtydelete}
                name={"resource"}
            />

        </div>

    )
}
function MapStateToProps(state) {
    return {
        estimate: state.getEstimate.estimate,
    };
}
function MapDispatchToProps() {
    return {
    };
}

export default compose(
    withRouter,
    connect(MapStateToProps, MapDispatchToProps)
)(Resource);