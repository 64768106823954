import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
const useStyles = makeStyles((theme) => ({
    paper: {
        width: 300,
        padding: '0 4px',
        borderRadius: '23px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none',
        border: "1px solid #ffffff"

    },
    inputBase: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    input: {
        margin: '0 !important',
        borderBottom: '0 !important',
        fontSize: '18px !important',
        lineHeight: 1.39,
        paddingRight: "10px !important",
        '&:focus': {
            borderBottom: '0 !important',
            boxShadow: 'none !important',
        },
    },
    error: {
        border: "1px solid #db263b"
    },
    iconButton: {
        padding: 8,
        color: '#000000'
    },
    icon: {
        width: 28,
        height: 28,
    },
    cross: {
        position: 'relative',
        right: '10px',
        cursor: 'pointer'
        //display: 'none',
    },
    ClearIcon: {
        position: 'relative',
        right: '10px',
    },
}));

function SearchBox(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const inputHandler = (e) => {
        setValue(e);
        props.change(e);
        setError(false);
    }
    const clearInput = () => {
        setValue('');
        props.change('');
        document.getElementById("search-title").value = "";
    }
    const submithandler = (e) => {
        e.preventDefault();
        if (value === "") {
            setError(true);
        }
    }
    //var searchText = localStorage.getItem("searchText");
    const searchText = ""
    return (
        <Paper onSubmit={(e) => submithandler(e)}
            component="form" className={[classes.paper, error && classes.error].join(" ")} variant="outlined">
            <h5 primary={value}></h5>
            <IconButton className={classes.iconButton} aria-label="menu" onClick={(e) => submithandler(e)}>
                <SearchIcon className={classes.icon} />
            </IconButton>
            <InputBase
                className={classes.inputBase}
                placeholder={props.placeholder}
                onChange={(e) => inputHandler(e.target.value)}
                defaultValue={props.defaultValue ? props.defaultValue : searchText}
                id="search-title"
                inputProps={{
                    className: classes.input,
                    'aria-label': `${props.placeholder.toLowerCase()}`,
                }}
            />
            {value !== "" && (
                <ClearIcon onClick={clearInput} className={classes.cross} />
            )}

        </Paper>
    );
}

export default SearchBox;
