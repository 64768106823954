import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    estimates: [],
    status: 'loading'
};

export const getEstimatesAsync = createAsyncThunk(
    'estimates/getEstimates',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const ref = fire.database().ref(companyId).child('estimates');
        const refsnapshot = await ref.once("value");
        const estimates = JSON.parse(JSON.stringify(refsnapshot.val() || {}));
        ref.off("value");
        const result = [];
        for (const id in estimates) {
            result.push({ id, ...estimates[id] });
        }
        const res = { estimates: result };
        return res;
    }
);

// Redux Toolkit slice
export const getEstimatesSlice = createSlice({
    name: 'estimates',
    initialState,
    reducers: {
        // getEstimate: (state) => {
        //     state.estimate = state.payload
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEstimatesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEstimatesAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.estimates = action.payload.estimates

            });
    },
});

export default getEstimatesSlice.reducer;
