const initstate = {
  projects: [
    { id: 1, Title: 'iTero Dashboard Redesign', Date: '5:30pm November 23, 2020' },
    { id: 1, Title: 'Authentic8 Web Redesign', Date: '5:30pm November 23, 2020' },
    { id: 1, Title: 'Bitdefender Web Redesign', Date: '5:30pm November 23, 2020' }
  ]
};

const projectReducer = (state = initstate, action) => {
  switch (action.type) {
    case 'CREATE_PROJECT':
      console.log('create project', action.project)
      break;
    default:
      return state;
  }
  return state;
}

export default projectReducer;