function checkCopyName(title, list, fieldName) {
    const exists = list.find(d => d[fieldName] === title) !== undefined;
    return exists ? getCopyName(title, list, fieldName) : title;
}
function getCopyName(title, list = [], fieldName = 'title') {
    const copyword = '(copy';
    const index = title.indexOf(copyword);
    if (index === -1) {
        return checkCopyName(title + " (copy)", list, fieldName);
    }
    const firstpart = title.substring(0, index + copyword.length);
    const secondIndex = title.indexOf(')', index);
    const secondPart = title.substring(secondIndex, title.length);
    const currentnum = title.substring(index + copyword.length, secondIndex);
    let currentNumstr = '1'
    if (currentnum.length > 0) {
        try {
            currentNumstr = parseInt(currentnum) + 1;
        } catch (err) {

        }
    }
    return checkCopyName(firstpart + currentNumstr + secondPart, list, fieldName);

}
module.exports = {
    getCopyName
}
// let name = 'my name aa';
// for (let i = 0; i < 10; i++) {
//     name = getCopyName(name, [{ title: 'my name aa (copy1)' }], 'title');
//     console.log(name);
// }
// console.log(getCopyName('first (copy2)'));