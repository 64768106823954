
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import "../../../assets/scss/autocomplete.scss";
import parse from 'autosuggest-highlight/parse';
import matchRegex from 'autosuggest-highlight/match';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const filter = createFilterOptions();

function AutoCompleteResource({
  show, type = 'Role',
  title, dataList = [],
  update = () => { console.error(`AutoCompleteResource update not implemented`) },
  insert,
  resourceName = '',
  cla, classes, open
}) {
  const [value, setValue] = useState(resourceName);
  const addNewItem = (name) => {
    insert({ resourceName: name });
  }

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        if (e && e.target && e.target.ariaAutoComplete !== 'list') {
          if (e && e.target && e.target && e.target.parentElement
            && e.target.parentElement.classList && e.target.parentElement.classList[0] !== "MuiAutocomplete-option") {
            if (show) {
              show();
            }
          } else {
            console.log('list item selected');
          }
        }
      }}>
      <Autocomplete
        autoSelect
        value={value}
        defaultValue={resourceName}
        className={cla + ' ' + classes}
        onChange={(event, newValue) => {
          if (newValue === null) { return }
          setValue(newValue);
          if (newValue && newValue.inputValue) {
            addNewItem(newValue.inputValue);
          } else {
            if (typeof newValue === 'object') {
              update({ resourceName: newValue.title, ...newValue });
            } else {
              update({ resourceName: newValue });
            }
          }
          if (show) {
            show();
          }
        }
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (type === "resources" && params.inputValue !== '') {
            filtered.push({
              title: <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}</span>,
              inputValue: params.inputValue,
            });

          }
          else if (params.inputValue !== '') {
            filtered.push({
              title: <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}</span>,
              inputValue: params.inputValue,
            });
          }
          return filtered;
        }
        }
        selectOnFocus
        clearOnBlur
        blurOnSelect
        handleHomeEndKeys
        options={(dataList || []).filter(d => d.archive !== 'true')}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(option, { inputValue }) => {
          const matches = matchRegex(option.title, inputValue);
          const parts = parse(option.title, matches);
          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        style={{ width: 450 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={title} />
        )}
      />
    </ClickAwayListener>
  );
}
export default withRouter(AutoCompleteResource);