import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { DefaultButton } from '../small-components/UI/Buttons';
import Avatar from '@material-ui/core/Avatar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import fire from '../../config/firebase';
import { Alert, AlertTitle } from "@material-ui/lab";
const useStyles = makeStyles({
  heading: {
    fontSize: '25px',
    fontWeight: 100,
    lineHeight: 1.36,
    letterSpacing: 'normal',
  },
  infoBox: {
    marginLeft: '-32px',
    paddingBottom: '33px'
  },
  fieldOuter: {
    padding: '12px 32px !important'
  },
  title: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: 1.38,
    marginTop: '10px',
    letterSpacing: 'normal',
  },
  description: {
    marginTop: 10,
    fontSize: '12px',
    lineHeight: 1.35,
    letterSpacing: '0.6px',
    color: '#084d4f',
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  intro: {
    marginTop: 30,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: 1.35,
    letterSpacing: 'normal',
  },
  inputOuter: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(8, 77, 79, 0.1) !important;',
    },

    '& input': {
      border: '0 !important',
      fontSize: '14px !important',
      color: 'rgb(0, 0, 0)',
      //fontWeight: '400',
      fontWeight: 300,
      boxShadow: 'none',
      height: '30px !important',
      paddingTop: '4px !important',
      margin: '0 0 6px 0  !important ',
    },
    '&.Mui-focused': {
      border: '0 !important',
    },
    '& label': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '14px ',
      //fontWeight: '400',
      fontWeight: 300,
      top: '-8px',
      '&.MuiFormLabel-filled': {
        top: 0,
        color: 'rgba(8 ,77 ,79, 0.5)',
      },
      '&.Mui-focused': {
        top: 0,
        color: 'rgba(8 ,77 ,79, 0.5)',

      }

    },
  },
  errorState: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #db263b !important',
    },
  },
  paper: {
    //height: 140,
    width: '100%',
  },

  logo: {
    backgroundColor: '#2ab7ca',
    fontSize: '40px',
    width: '61px',
    height: '61px',
    marginBottom: '20px',
    textTransform: 'capitalize'
  },
  profile: {
    '& + .footer': {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      left: 0,
      height: '50px;',
      zIndex: 2
    }
  },
  control: {
    //padding: theme.spacing(2),
  },

  buttonWrap: {
    marginTop: 30,

    '& > .MuiButton-contained': {
      margin: '0px 5px',
    },
  }
});

function Profile(props) {
  const { user } = props;
  const classes = useStyles();
  const [username, updateUserName] = React.useState();
  const [oldpass, oldpassFun] = React.useState("");
  const [error, errorMessage] = React.useState(false);
  const [match, errorMessageMatch] = React.useState(false);
  const [weak, weakMessage] = React.useState(false);
  const [message, messageShows] = React.useState();
  const [values, setValues] = React.useState({
    password: '',
  });
  const [values_n, setValues_n] = React.useState({
    password: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //

  const handleChange_new = (prop) => (eventnew) => {
    setValues_n({ ...values_n, [prop]: eventnew.target.value });
  };
  const handleClickShowPassword_new = () => {
    setValues_n({ ...values_n, showPassword: !values_n.showPassword });
  };

  const handleMouseDownPassword_new = (event) => {
    event.preventDefault();
  };

  const handleOldPassword = (e) => {
    oldpassFun(e.target.value)
  }

  const userNameFun = (e) => {
    updateUserName(e.target.value)

  }

  const userUpdate = (user) => {
    let displayName = user.displayName;
    if (username !== '') {
      displayName = username
    } else {
      displayName = user.displayName
    }

    user.updateProfile({
      displayName: displayName
    }).then(() => {
      console.log(user);
      messageShows('Profile updated successfully.');
    }).catch(() => {

    });
  }
  const passUpdate = (user) => {
    user.updatePassword(values_n.password).then(() => {
      errorMessage(true)
      messageShows('Password updated successfully.');
    }).catch(() => {
      // An error ocurred
      // ...
    });
  }
  const closeAlert = () => {
    errorMessage(false)
    errorMessageMatch(false);
    weakMessage(false);
  }
  //
  const [emptyOld, setStateOldPass] = React.useState(false);
  const submitPass = (e) => {
    e.preventDefault();
    if (values.password !== "" && values_n.password !== "" && oldpass === "") {
      setStateOldPass(true);
      console.log("oldpass", oldpass);
    } else {
      setStateOldPass(false);
    }
    if (oldpass) {
      if (values.password.length < 4) {
        weakMessage(true);
      }
      if (values.password !== values_n.password) {
        errorMessageMatch(true);
      } else {
        fire.auth().signInWithEmailAndPassword(user.email, oldpass).then(() => {
          if (error === false && match === false && weak === false) {
            userUpdate(user);
            passUpdate(user);
          }
        }).catch(() => {
          errorMessage(true)
          messageShows('Old password not matched')
        });
      }
    } else {
      userUpdate(user);
    }
    if (props.updateFn) {
      setTimeout(() => { props.updateFn(); }, 500);
    }

  }
  return (
    <React.Fragment>
      {user && (<React.Fragment>
        {error && (
          <Grid item xs className="forget-pass-alert" style={{ top: '12px', zIndex: "1111", left: "80px" }}>
            <Alert onClose={closeAlert} severity="info">
              <AlertTitle>{message}</AlertTitle>
            </Alert>
          </Grid>
        )}
        {match && (
          <Grid item xs className="forget-pass-alert" style={{ top: '12px', zIndex: "1111", left: "80px" }}>
            <Grid item xs className="unmatched-pwd">
              <Alert onClose={closeAlert} variant="filled" severity="error">
                <AlertTitle>Password do not match</AlertTitle>
              </Alert>
            </Grid>
          </Grid>
        )}
        {weak && (
          <Grid item xs className="forget-pass-alert" style={{ top: '12px', zIndex: "1111", left: "80px" }}>
            <Grid item xs className="weak-pwd">
              <Alert onClose={closeAlert} variant="filled" severity="warning">
                <AlertTitle>Weak password</AlertTitle>
              </Alert>
            </Grid>
          </Grid>
        )}
        <Box className='profile-container' component="section" style={{
          backgroundColor: 'rgba(8, 77, 79, 0.05)', height: 'calc(100vh - 61px)', overflow: 'scroll'
        }}>

          <Box className="container">
            <Box py={3} style={{
              paddingTop: '19px'
            }}>
              <Box>
                <Typography className={classes.heading} variant="h2" component="h2">
                  My Profile
                </Typography>
                <Typography className={classes.title} variant="h6" component="h6">
                  Manage your profile settings
                </Typography>

              </Box>

              <Grid item xs={12} style={{
                marginTop: '30px'
              }}>

                <Paper className={classes.control || ''} style={{ padding: '0 30px', border: 'solid 1px rgba(8, 77, 79, 0.1)', boxShadow: 'none' }}>
                  <Grid style={{ borderBottom: 'solid 1px rgba(8, 77, 79, 0.2)', paddingTop: '28px' }}>
                    <div>

                      <Avatar className={classes.logo}>{user.displayName ? user.displayName.charAt(0) : 'S'}</Avatar>
                    </div>

                    <Grid container spacing={3} className={classes.infoBox}>

                      <Grid item xs={4} className={classes.fieldOuter}>
                        <Typography className={classes.description} variant="h6" component="h6">Full Name
                        </Typography>
                        <TextField
                          id="outlined-helperText"
                          label="Full Name"
                          defaultValue={user.displayName ? user.displayName : 'anonUser'}
                          variant="outlined"
                          onChange={userNameFun}
                          style={{
                            width: '100%',
                            marginTop: '12px',
                            textTransform: 'capitalize'
                          }}
                          className={classes.inputOuter}
                          InputProps={{
                            style: {
                              padding: '0 20px 0 12px',

                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.fieldOuter}>
                        <Typography className={classes.description} variant="h6" component="h6">
                          Email
                        </Typography>
                        <TextField
                          id="outlined-helperText"
                          className={classes.inputBox}
                          label="Email"
                          style={{
                            width: '100%', marginTop: '12px'
                          }}
                          // className={classes.inputOuter}
                          InputProps={{
                            style: {
                              padding: '0 20px 0 12px',

                            }
                          }}
                          defaultValue={user.email}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid></Grid>
                  <Grid style={{ borderBottom: 'solid 1px rgba(8, 77, 79, 0.2)' }}>

                    <Typography className={classes.intro} variant="h2" component="h2" style={{ marginBottom: '10px' }}>Change Password
                    </Typography>
                    <Grid container spacing={3} className={classes.infoBox} style={{ paddingBottom: '29px' }}>
                      <Grid item xs={4} className={classes.fieldOuter}>
                        <Typography className={classes.description} variant="h6" component="h6">
                          Old Password
                        </Typography>
                        <FormControl className={(classes.inputOuter)} variant="outlined" style={{ marginTop: '10px' }}>
                          <InputLabel htmlFor="outlined-adornment-password"> Old Password </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            //type={values.showPassword ? 'text' : 'password'}
                            type='password'
                            // value={oldpass}
                            style={{ padding: '0 20px 0 12px' }}
                            autoComplete="new-password"
                            className={emptyOld === true ? classes.errorState : ''}
                            onChange={handleOldPassword}
                            labelWidth={120}

                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4} className={classes.fieldOuter}>
                        <Typography className={classes.description} variant="h6" component="h6">
                          New Password
                        </Typography>
                        <FormControl className={(classes.inputOuter)} variant="outlined" style={{ marginTop: '10px' }}>
                          <InputLabel htmlFor="outlined-adornment-password"> New Password </InputLabel>
                          <OutlinedInput
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            style={{ padding: '0 20px 0 12px' }}
                            onChange={handleChange('password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  style={{ opacity: '0.4' }}
                                >
                                  {values.showPassword ? < Visibility htmlColor='#084d4f' /> :
                                    <VisibilityOff htmlColor='#084d4f' />}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={120}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} className={classes.fieldOuter}>
                        <Typography className={classes.description} variant="h6" component="h6">
                          Confirm Password
                        </Typography>
                        <FormControl className={(classes.inputOuter)} variant="outlined" style={{ marginTop: '12px' }}>
                          <InputLabel htmlFor="outlined-password"> Confirm Password</InputLabel>
                          <OutlinedInput
                            id="outlined-password"
                            type={values_n.showPassword ? 'text' : 'password'}
                            value={values_n.password}
                            style={{ padding: '0 20px 0 12px' }}
                            onChange={handleChange_new('password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword_new}
                                  onMouseDown={handleMouseDownPassword_new}
                                  edge="end"
                                  style={{ opacity: '0.4' }}
                                >
                                  {values_n.showPassword ? < Visibility htmlColor='#084d4f' /> :
                                    <VisibilityOff htmlColor='#084d4f' />}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={120}
                          />
                        </FormControl>
                      </Grid>
                    </Grid></Grid>

                  <Box className={classes.buttonWrap} style={{ paddingBottom: '29px' }}>

                    <DefaultButton
                      variant="contained"
                      style={{ minWidth: '108px', fontWeight: '500' }}
                      href="/"
                      onClick={submitPass}>
                      Save
                    </DefaultButton>
                  </Box>
                </Paper>
              </Grid>
            </Box>
          </Box>
        </Box>
      </React.Fragment>)
      }
    </React.Fragment >
  );
}

function MapStateToProps(state) {
  return {

    user: state.getUser.user,

  };
}
function MapDispatchToProps() {
  return {

  };
}

export default connect(MapStateToProps, MapDispatchToProps)(Profile);
