import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import '../../assets/scss/item-hover-based.scss';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        position: 'absolute',
        right: '0',
        top: '12px'
    },
    paper: {
        marginRight: theme.spacing(2),
    },

}));

export default function MenuListComposition(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const deleteHandle = () => {
        setOpen(false);
        props.deleteHandle("delete");
    }
    return (
        <div className={classes.root + ' hover-wrap'}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    onClick={handleToggle}
                >
                    <MoreVertIcon />
                </Button >
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'left bottom', zIndex: 9,
                            }}
                        >
                            <Paper className="select-menu-wrap" >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-summary" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose}>
                                            <FilterNoneIcon htmlColor="#000" className="mirror_filter" />
                                            Duplicate
                                        </MenuItem>
                                        <MenuItem onClick={deleteHandle}><DeleteOutlinedIcon htmlColor="#000" />Delete</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}