import React from "react";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import "./DrawerOverlay.scss";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
import MenuDropdown from "../MenuDropdown/MenuDropdown";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { Radio, RadioGroup } from "@material-ui/core";
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import * as getResourcesActionCreators from '../../../store/resources/getResources';

import { isDigit, getFloat } from "../../../libs/utils";
const styles = () => ({
  asideMenuDropdown: {
    marginTop: 10,
    marginBottom: 30,
    width: "100%",
  },
  asideMenuDropdownItem: {
    backgroundColor: "#fff !important",
    "&.Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.08) !important",
    },
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: "31px !important",
    "&.Mui-disabled": {
      borderColor: "#000 !important",
      color: "rgba(0,0,0,0.42)",
    },
  },
  asideMenuDropdownIcon: {
    marginRight: "5.5px",
  },
  radio: {
    padding: "5px 10px",
    color: "#084d4f !important",
  },
  radioChecked: {
    color: "#084d4f !important",
  },
  resourceLabel: {
    marginLeft: "15px",
    fontSize: "16px",
    fontFamily: "NotoSans",
    color: "#000000",
    width: "400px",
    display: "flex",
    marginTop: "15px",
    justifyContent: "flex-start",
  },
  resourceIcon: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "15px",

  },
  resourceDirection: {
    height: "60px",
    display: "flex",
    marginTop: "10px",
  },
  textField: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginRight: "50px",
  },

  radioLabel: {
    width: "239px",
    height: "19px",
    opacity: 1,
    fontFamily: "NotoSans",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000"
  },
  radioDescription: {
    margin: "-5px 51px 5px 31px"
  }
});

class SettingTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profitMargin: '',
      targetMargin: '',
      targetBudget: '',
      definedhourlyRate: '',
      billableSwitch: false,
      changed: false,
      rounding: '',
      resourceRateOption: "defaultRate",
      loader: false,
      resourceRates: []
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlerTargetMargin = this.handlerTargetMargin.bind(this);
    this.handlerProfitMargin = this.handlerProfitMargin.bind(this);
    this.handlerHourlyRate = this.handlerHourlyRate.bind(this);
    this.handlerTargetBudget = this.handlerTargetBudget.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.getResourceData = this.getResourceData.bind(this);
    this.getRangeValue = this.getRangeValue.bind(this);
    this.getResourceList = this.getResourceList.bind(this);
  }
  componentDidMount() {

    const { getResourcesAsync } = this.props.getResourcesAction;
    getResourcesAsync();

    const props = this.props;
    const { estimate } = this.props;
    const billableSwitch =
      props.estimate?.billableSwitch ||
      props.company?.billableSwitch || false;

    this.setState({
      profitMargin: props.estimate?.profitMargin,
      targetMargin: props.estimate?.targetMargin || '',
      definedhourlyRate:
        parseFloat(props.estimate?.definedhourlyRate) ||
        '',
      billableSwitch: billableSwitch,
      targetBudget: estimate?.targetBudget || '',
      rounding: estimate?.rounding || 0,
      changed: false,
      resourceRateOption: estimate?.resourceRateOption || "defaultRate",
      resourceRates: estimate?.resourceRates || []
    });
    this.getResourceData();

  }

  handlerTargetMargin(e) {
    const number = /^[0-9\b]+$/;
    if (e.target.value === "" || number.test(e.target.value)) {
      this.setState({
        targetMargin: e.target.value.slice(0, 10),
        changed: true,
      });
      e.target.value
        ? e.currentTarget.classList.add("field-value")
        : e.currentTarget.classList.remove("field-value");
    }
  }

  getRangeValue = (input) => {
    let val = input;
    const max = 10000;

    if (val.startsWith('$')) {
      val = val.substring(1, val.length);
      input = val;
    }
    val = val.replace(/,/g, '');
    let temp = NaN;
    try {
      temp = getFloat(val);
    } catch {
      temp = NaN;
    }

    if (temp && temp >= 100000) {
      return;
    }
    if (isNaN(temp)) {
      temp = 0;
    }

    if (temp < 0) {
      temp = 0;
    }
    if (temp > max) {
      return;
    }
    return temp;
  }
  handlerProfitMargin(e) {
    const number = /^[0-9\b]+$/;
    if (e.target.value === "" || number.test(e.target.value)) {
      this.setState({
        profitMargin: e.target.value.slice(0, 10),
        changed: true,
      });
      e.target.value
        ? e.currentTarget.classList.add("field-value")
        : e.currentTarget.classList.remove("field-value");
    }
  }

  handleChangeOption = (e) => {
    this.setState({
      resourceRateOption: e.target.value,
      changed: true
    });
  };

  handlerHourlyRate(e) {
    const number = /^[0-9\b]+$/;
    if (e.target.value === "" || number.test(e.target.value)) {
      this.setState({
        definedhourlyRate: e.target.value.slice(0, 10),
        changed: true,
      });
      e.target.value
        ? e.currentTarget.classList.add("field-value")
        : e.currentTarget.classList.remove("field-value");
    }
  }
  handlerTargetBudget(e) {
    const number = /^[0-9\b]+$/;
    if (e.target.value.length > 7) {
      return
    }
    if (e.target.value === "" || number.test(e.target.value)) {
      this.setState({
        targetBudget: e.target.value,
        changed: true,
      });
      e.target.value
        ? e.currentTarget.classList.add("field-value")
        : e.currentTarget.classList.remove("field-value");
    }
  }
  handleRoundingOptions = (e) => {
    this.setState({ rounding: e, changed: true, });
  }
  getResourceList() {
    const resources = [];
    const phases = JSON.parse(JSON.stringify(this.props.estimate?.phases || []));
    if (phases.length) {
      for (const phase of phases) {
        if (phase?.tasks) {
          for (const task of phase.tasks) {
            if (task?.resources) {
              for (const resource of task.resources) {
                if (resource.id) {
                  resources.push({ ...resource });
                }
              }
            }
          }
        }
      }
      return resources;
    }
  }

  getResourceData() {
    const resources = this.getResourceList();
    const resourceRatesList = [];
    for (const resource of resources) {
      resource.icon = resource.icon || WorkOutlineOutlinedIcon;
      if (resource.type === 'Vendor' && resource.amountType === "percentage") {
        resourceRatesList.push({ id: resource.id, rate: parseFloat((resource.amount * resource.cost) / 100 || 0) });
      } else {
        resourceRatesList.push({ id: resource.id, rate: parseFloat(resource.amount || 0) });
      }
    }

    this.setState({
      loader: false,
      resourceRates: this.state.resourceRates?.length ? this.state.resourceRates : resourceRatesList
    });
    return resourceRatesList;
  }

  handleSubmit(e) {
    const haveValue = (d) => {
      return d !== null && d !== undefined;
    };
    let { definedhourlyRate } = this.state;
    const { targetMargin, profitMargin, rounding, targetBudget,
      billableSwitch = false,
      resourceRateOption, resourceRates } = this.state;

    const { estimate, company } = this.props;
    const data = { billableSwitch };
    const setValue = (val, key) => {
      if (val !== company[key] || haveValue(estimate[key])) {
        data[key] = val;
      }
    };
    setValue(targetMargin, "targetMargin");
    setValue(profitMargin, "profitMargin");
    if (resourceRateOption !== 'singleRate') {
      definedhourlyRate = null;
    }
    setValue(definedhourlyRate, "definedhourlyRate");
    setValue(rounding, "rounding");
    setValue(targetBudget, "targetBudget");
    setValue(resourceRateOption, "resourceRateOption");

    data.resourceRates = resourceRateOption === 'customRate' ? resourceRates : [];
    // singleRate

    let changed = false;
    const phases = JSON.parse(JSON.stringify(estimate?.phases || []))
    if (phases.length) {
      for (const phase of phases) {
        if (phase?.tasks) {
          for (const task of phase.tasks) {
            if (task?.resources) {
              for (const resource of task.resources) {
                const customRate = resourceRates.find(d => d.id === resource.id);

                if (resourceRateOption === 'defaultRate') {
                  if (resource.amountBackup && resource.amount !== resource.amountBackup) {
                    resource.amount = resource.amountBackup;
                    changed = true;
                  }
                  if (resource.amountTypeBackup && resource.amountType !== resource.amountTypeBackup) {
                    resource.amountType = resource.amountTypeBackup;
                    changed = true;
                  }
                }
                if (resourceRateOption === 'customRate' || resourceRateOption === 'singleRate') {
                  const rate = resourceRateOption === 'singleRate' ? parseFloat(definedhourlyRate || 0) : parseFloat(customRate?.rate || resource?.amount || 0);
                  if (rate !== resource.amount) {
                    resource.amountBackup = resource.amount;
                    resource.amountTypeBackup = resource.amountType;
                    //
                    resource.amount = rate;
                    resource.amountType = 'rate';
                    changed = true;
                  }
                }
              }
            }
          }
        }
      }
    }
    if (changed) {
      data.phases = phases;
    }

    e.preventDefault();
    this.props.updateEstimate(data);
    this.props.onClose(e);
  }
  render() {
    const {
      billableSwitch = true,
      targetBudget = '',
      targetMargin = '',
      profitMargin = '',
      definedhourlyRate,
      changed = true,
      resourceRates = [],
      resourceRateOption
    } = this.state;
    const disabledInputs = billableSwitch === false;
    const resources = [];
    const temp = this.getResourceList();
    const set = new Set();
    for (const item of temp) {
      if (set.has(item.id) === false) {
        resources.push(item);
        set.add(item.id);
      }
    }

    const { classes } = this.props;
    return (
      <div id="setting" className="side-item">
        <div className="title">
          <h5>
            Estimate Setting
            <div className="cross" onClick={this.props.onClose}>
              <CloseIcon htmlColor="#084d4f" />
            </div>
          </h5>
        </div>
        <FormGroup className="bill_switch">
          <FormControlLabel
            control={<Switch />}
            label="Billable"
            checked={billableSwitch}
            onChange={() =>
              this.setState({ billableSwitch: !billableSwitch, changed: true })
            }
          />
        </FormGroup>
        <div className="input-wrap">
          <h6>TARGET PROJECT MARGIN</h6>
          <p>Used to calculate price, cost, and billable rate</p>
          <div className="d_input">
            <TextField
              variant="outlined"
              name="target"
              type="text"
              label="Target Margin"
              onChange={this.handlerTargetMargin}
              value={billableSwitch ? targetMargin : "N/A"}
              disabled={disabledInputs}
              InputProps={{
                endAdornment: <span className="side_percentage">%</span>,
              }}
            />
          </div>

          <h6>TARGET BUDGET</h6>
          <p>Set the target budget for this individual estimate.</p>
          <div className="d_input discitem">
            <TextField
              variant="outlined"
              name="target"
              type="text"
              label="Target Budget"
              onChange={this.handlerTargetBudget}
              value={billableSwitch ? targetBudget : "N/A"}
              disabled={disabledInputs}
              InputProps={{
                endAdornment: <span className="side_dollar">$</span>,
              }}
            />
          </div>

          <h6>VENDOR UPSELL</h6>
          <p>Set the percent profit margin for your contracted vendors</p>
          <div className="d_input">
            <TextField
              variant="outlined"
              type="text"
              label="Profit Margin"
              onChange={this.handlerProfitMargin}
              value={!billableSwitch ? "N/A" : profitMargin}
              disabled={disabledInputs}
              InputProps={{
                endAdornment: <span className="side_percentage">%</span>,
              }}
            />
          </div>

          <h6>ADJUST RESOURCE RATES</h6>
          <RadioGroup
            aria-label="apply-to"
            name="controlled-radio-buttons-group"
            value={resourceRateOption}
            onChange={this.handleChangeOption}
          >
            <FormControlLabel
              value="defaultRate"
              control={<Radio classes={{
                root: classes.radio,
                checked: classes.radioChecked
              }} />
              } label={<p className={classes.radioLabel} >Use Default Resource Rate</p>} />
            <p className={classes.radioDescription}>Apply standard billable rates set in system settings for all roles</p>
            <FormControlLabel
              value="singleRate"
              control={<Radio classes={{
                root: classes.radio,
                checked: classes.radioChecked
              }} />} label={<p className={classes.radioLabel} >Set Single Rate for Estimate</p>} />
            <p className={classes.radioDescription}> Apply a uniform billable rate for the entire estimate</p>
            {resourceRateOption === "singleRate" && <div className="d_input discitem">
              <TextField
                variant="outlined"
                onChange={this.handlerHourlyRate}
                InputProps={{
                  endAdornment: <span className="side_dollar">$</span>,
                }}
                type="text"
                label="Enter rate"
                value={!billableSwitch ? "N/A" : definedhourlyRate}
                disabled={disabledInputs}
              />
            </div>
            }
            <FormControlLabel
              onChange={this.getResourceData}
              value="customRate"
              control={<Radio classes={{
                root: classes.radio,
                checked: classes.radioChecked
              }} />} label={<p className={classes.radioLabel} >Edit Rates for this Estimate</p>} />
            <p className={classes.radioDescription}>Customize billable rates specifically for this estimate</p>
            {resourceRateOption === "customRate" &&

              resources?.map((resource, index = resource.id) => (
                <div key={index} className={classes.resourceDirection}>
                  {resource.subtitle === "Role" ? <PeopleOutlineOutlinedIcon className={classes.resourceIcon}
                    htmlColor="#000000"
                  /> : <WorkOutlineOutlinedIcon htmlColor="#000000`" className={classes.resourceIcon}
                  />
                  }
                  <span className={classes.resourceLabel} >
                    {resource.title}
                  </span>
                  <div className={classes.textField} >
                    <TextField
                      value={`$${resourceRates?.find((resourceRate) => {
                        return resourceRate && resource && resourceRate.id === resource.id;
                      })?.rate || 0}`}
                      defaultValue={`$${resource.amount}`}
                      placeholder="Rate"
                      label="Rate"
                      aria-labelledby="Rate"
                      variant="outlined"
                      size="small"
                      style={{ width: '100px' }}
                      onChange={(e) => {
                        if (isDigit(e.target.value) === false) {
                          return false;
                        }
                        const temp = this.getRangeValue(e.target.value);

                        this.setState({
                          resourceRates: resourceRates?.map((resourceRate) => resourceRate.id === resource.id ? { ...resourceRate, rate: temp } : resourceRate),
                          changed: true
                        }
                        );
                      }}
                    />
                  </div>
                </div>
              ))

            }
          </RadioGroup>

          <h6>ROUNDING OPTIONS</h6>
          <p>
            Set the rounding option for your estimate’s Project Total
          </p>

          <MenuDropdown
            className={`menudrop-round ${classes.asideMenuDropdown}`}
            selectInputProps={{
              classes: {
                root: classes.asideMenuDropdownInput,
                icon: classes.asideMenuDropdownIcon,
              },
            }}
            aria-labelledby="options"
            value={this.state.rounding}
            change={(e) => {
              this.handleRoundingOptions(e.target.value)
            }}
            disabled={disabledInputs}
          >
            <MenuItem key={0} value={'none'} className={classes.asideMenuDropdownItem} >
              {'Round to: None'}
            </MenuItem>
            <MenuItem key={10} value={`nearest10`} className={classes.asideMenuDropdownItem}>
              {'Round to: Nearest Ten (10)'}
            </MenuItem>
            <MenuItem key={100} value={`nearest100`} className={classes.asideMenuDropdownItem}>
              {'Round to: Nearest Hundred (100)'}
            </MenuItem>
            <MenuItem key={1000} value={`nearest1000`} className={classes.asideMenuDropdownItem}>
              {'Round to: Nearest Thousand (1000)'}
            </MenuItem>
          </MenuDropdown>

          <Button
            variant="contained"
            type="button"
            className="duplicate apply"
            disabled={!changed}
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

function MapStateToProps(state) {
  return {
    company: state.getCompany.company,
    // resources: state.getResources.resources,
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getResourcesAction: bindActionCreators(
      getResourcesActionCreators,
      dispatch
    ),

  };
}
const withStyled = withStyles(styles)(SettingTemplate);

export default connect(MapStateToProps, MapDispatchToProps)(withStyled);
