import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import fire from "../../../config/firebase";
import "../../../assets/scss/autocomplete.scss";
import parse from "autosuggest-highlight/parse";
import Moment from "moment";
import matchRegex from "autosuggest-highlight/match";
import { isTrue } from "../../../libs/utils";
const filter = createFilterOptions();

function AutoCompleteM({
  selectValue,
  match,
  resourceItem,
  show,
  resourceName,
  id,
  title,
  dataList,
  updateName,
  cla,
  classes,
  type,
  phaseId,
  toggleDrawer,
  nameValue,
}) {
  const [value, setValue] = useState(selectValue);
  const { key } = match.params;
  const dataType = match.path.includes("estimates") ? "estimates" : "templates";
  const companyId = localStorage.getItem('companyId');
  const estimateRef = fire.database().ref(companyId).child(dataType + "/" + key + "/phases");

  const databaseSelector = (type, key, value) => {
    if (type === "phase") {
      updateName(value.title);
    }
    if (type === "task") {
      updateName(value.title);
    }
  };

  const addNewItem = (type, name) => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key]), {});

    const companyId = localStorage.getItem('companyId');

    const listRef = fire.database().ref(companyId).child(type);
    const itemData = {
      title: name,
      date: Moment(new Date()).format("MM/DD/YYYY"),
      borderColor: "#fffff",
    };
    listRef.once("value", (snap) => {
      const db = snap.val();
      listRef.off("value");
      const filteredList = Object.filter(db, (item) => item.title === name);
      if (Object.keys(filteredList).length === 0) {
        listRef.push(itemData);
      }
    });
  };

  return (
    <Autocomplete
      autoSelect
      value={value}
      defaultValue={nameValue}
      className={cla + " " + classes}
      onChange={(event, newValue) => {
        if (newValue === null) {
          return;
        }
        if (type === "task" && newValue.title) {
          updateName(newValue.title);
        }
        if (type === "phase" && newValue.title) {
          updateName(newValue.title);
        }
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          if (type === "phase") {
            addNewItem("phases", newValue.inputValue);
          }
          if (type === "task") {
            addNewItem("tasks", newValue.inputValue);
          }
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
          if (phaseId) {
            estimateRef.once("value", (snap) => {
              const db = snap.val() || [];
              estimateRef.off("value");
              db.forEach((phase, index) => {
                if (phase.phaseId === phaseId) {
                  databaseSelector(type, index, newValue, estimateRef, snap);
                }
              });
            });
          }
        }
        if (resourceItem) {
          resourceItem();
        }
        if (show) {
          show();
        }

        if (newValue.hasOwnProperty("inputValue") && type === "resources") {
          const data = { type };
          toggleDrawer(newValue.inputValue, data);
          return;
        } else if (resourceName !== undefined) {
          if (newValue.inputValue) {
            resourceName(
              newValue.inputValue,
              newValue.cost,
              newValue.amount,
              id
            );
          } else {
            resourceName(newValue["title"], newValue.cost, newValue.amount, id);
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (type === "resources" && params.inputValue !== "") {
          filtered.push({
            title: (
              <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}
              </span>
            ),
            inputValue: params.inputValue,
          });
        } else if (params.inputValue !== "") {
          filtered.push({
            title: (
              <span key={filtered.length} className={`add-button ${classes}`}>
                <AddIcon /> Add "{params.inputValue}" as a new {title}
              </span>
            ),
            inputValue: params.inputValue,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      blurOnSelect
      handleHomeEndKeys
      options={(dataList || []).filter((d) => !isTrue(d.archive))}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option, { inputValue }) => {
        const matches = matchRegex(option.title, inputValue);
        const parts = parse(option.title, matches);
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
      style={{ width: 300 }}
      freeSolo
      renderInput={(params) => <TextField {...params} label={title} />}
    />
  );
}
export default withRouter(AutoCompleteM);
