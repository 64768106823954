import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Step, StepLabel, Paper, Grid } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import clsx from "clsx";
import CreateNew from "../reusable-components/CreateNew";
import CreateProject from "../reusable-components/CreateProject";
import ContactSave from "../reusable-components/ContactSave";
import newEstimate from "../../assets/images/estimate_overlay/select_estimate_type.svg";
import newEstimate2 from "../../assets/images/new-svgs/estimate-step-2.svg";
import newEstimateValueimg2 from "../../assets/images/new-svgs/estimate-step-3.svg";
import newEstimate3 from "../../assets/images/finishing_touch.svg";
import newEstimateValueimg3 from "../../assets/images/new-svgs/finalstep.svg";
import CloseIcon from "@material-ui/icons/Close";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  animate_in_img: {
    animation: "scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both",
    animationDuration: "0.25s",
  },
  addTask: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1.36",
    color: "#3066be",
  },
  newestimate: {
    borderRadius: "5px",
    backgroundColor: "#e70a89",
    marginTop: "20px",
    color: "#fff",
    minWidth: "100px",
    minHeight: "40px",
    marginRight: "20px",
  },
  rootGrid: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
    color: "#784af4",
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "none",
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "18px",
  },
  textField: {
    background: "transparent",
  },
}));
const useQontoStepIconStyles = makeStyles({
  root: {
    color: "rgba(8, 77, 79, 0.5)",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#e70a89",
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#e70a89",
    zIndex: 1,
    fontSize: 22,
  },
});
function getSteps(dataType = "Estimate") {
  return [
    "Select " + dataType + " Type",
    dataType + " Name",
    "Company and Contact",
  ];
}
function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};
function NewStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const dataType = props.match.path.includes("templates")
    ? "templates"
    : "estimates";
  const steps = getSteps(dataType === "estimates" ? "Estimate" : "Template");
  const [estimateType, setEstimateType] = React.useState("task-based");
  const [Projectvalue, setProjectvalue] = React.useState("");
  const [Contactvalue, setContactvalue] = React.useState("");
  const createStyle = {
    width: "87%",
    margin: "0 auto",
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setProjectvalue("");
    setContactvalue("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setTimeout(() => {
      setActiveStep(0);
    }, 100);
  };
  const CreateProjectvalue = (e) => {
    setProjectvalue(e.target.value);
  };

  const newContactValue = (value) => {
    setContactvalue(value);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="createNew" style={createStyle}>
        <div
          className="cross"
          onClick={(e) => {
            props.click(e);
          }}
        >
          <TouchRipple />
          <CloseIcon htmlColor="#084d4f" alt="cross" />
        </div>
        <div className={classes.rootGrid}>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              {/* <FormRow /> */}

              {activeStep === 0 && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <Paper className={classes.paper}>
                      <div className={classes.animate_in_img + " bg-img"}>
                        <img src={newEstimate} alt="Estmate type 1" />
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={8}>
                    <Paper className={classes.paper}>
                      <CreateNew
                        click={(type) => {
                          setEstimateType(type);
                          handleNext();
                        }}
                        overlay={props.overlay}
                      />
                    </Paper>
                  </Grid>
                </React.Fragment>
              )}
              {activeStep === 1 && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <Paper className={classes.paper}>
                      <div className={classes.animate_in_img + " bg-img"}>
                        {Projectvalue ? (
                          <img src={newEstimateValueimg2} alt="task based" />
                        ) : (
                          <img src={newEstimate2} alt="task based" />
                        )}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={8}>
                    <Paper className={classes.paper}>
                      <CreateProject
                        dataType={dataType}
                        click={handleNext}
                        onBack={handleBack}
                        inputvalue={CreateProjectvalue}
                        Projectvalue={Projectvalue}
                      />
                    </Paper>
                  </Grid>
                </React.Fragment>
              )}
              {activeStep === 2 && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <Paper className={classes.paper}>
                      <div className={classes.animate_in_img + " bg-img"}>
                        {Contactvalue ? (
                          <img
                            src={newEstimateValueimg3}
                            alt="finishing touch"
                          />
                        ) : (
                          <img src={newEstimate3} alt="task based" />
                        )}
                        {/* <img src={newEstimate3} alt="finishing touch" /> */}
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={8}>
                    <Paper className={classes.paper}>
                      <ContactSave
                        dataType={dataType}
                        estimateType={estimateType}
                        Projectvalue={Projectvalue}
                        click={handleNext}
                        classes={classes}
                        close={props.click}
                        onBack={handleBack}
                        onReset={handleReset}
                        newContactValue={newContactValue}
                      />
                    </Paper>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default withRouter(NewStepper);
