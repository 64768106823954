import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import AutoCompleteCmp from "../reusable-components/autoComplete/autocomplete";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import Button from "@material-ui/core/Button";
import $ from "jquery";
import fire from "../../config/firebase";
import Moment from "moment";
import uuid from "react-uuid";

class ContactSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createStyle: {
        display: "inline-block",
        verticalAlign: "middle",
        padding: "11px 19.5px",
        margin: "10px 5px",
        background: "rgba(0, 0, 0, 0.12)",
        color: "#fff",
        borderRadius: "5px",
        minHeight: "40px",
        lineHeight: "1.35",
        "&:hover": {
          backgroundColor: "#e70a89",
          boxShadow: "inset 0 -40px 10px -2px rgba(0,0,0,0.1)",
        },
      },
      linkstyle: {
        display: "inline-block",
        verticalAlign: "middle",
        padding: "11px 19.5px",
        margin: "10px 10px 10px 0",
        background: "#e70a89",
        color: "#fff",
        borderRadius: "5px",
        minHeight: "40px ",
        fontweight: "600",
        "&:hover": {
          backgroundColor: "#e70a89",
          boxShadow: "inset 0 -40px 10px -2px rgba(0,0,0,0.1)",
        },
      },
      createStyle1: {
        display: "inline-block",
        verticalAlign: "middle",
        padding: "8px 19.5px",
        margin: "10px 5px",
        background: "#e70a89",
        color: "#fff",
        borderRadius: "5px",
        minHeight: "40px",
        "&:hover": {
          backgroundColor: "#e70a89",
          boxShadow: "inset 0 -40px 10px -2px rgba(0,0,0,0.1)",
        },
      },
      newestimateback: {
        borderRadius: "5px",
        backgroundColor: "transparent",
        marginTop: "0",
        color: "#000",
        minWidth: "100px",
        minHeight: "40px",
        marginRight: "10px",
        boxShadow: "none",
        border: "2px solid #000",
        "&:hover": {
          backgroundColor: "#000 !important",
          color: "#fff !important",
          boxShadow: "inset 0 -40px 10px -2px rgba(0,0,0,0.1)",
        },
      },
      overlay_content_wrap: {
        animation: "fadeInRight 0.24s linear",
        animationDuration: "0.25s",
      },

      it1: false,
      it2: false,
      arrayItem: [],
      arrayItemPeople: [],
      organization: "",
      people: "",
    };

    this.estimates = this.estimates.bind(this);
  }

  estimates() {
    const user = fire.auth().currentUser.uid;
    const { dataType = "estimates" } = this.props;
    const pluralDataType = dataType === "estimates" ? "estimates" : "templates";

    const companyId = localStorage.getItem('companyId');
    const estimateRef = fire.database().ref(companyId).child(dataType);

    const projectname = this.props.Projectvalue;
    let type = "",
      filterType = "";

    if (this.props.estimateType === "time-based") {
      type = "TIME BASED ESTIMATE";
      filterType = "time-based";
    } else {
      type = "TASK BASED ESTIMATE";
      filterType = "task-based";
    }

    const estimateData = {
      billableSwitch: true,
      projectName: projectname,
      typeOf: type,
      creator: user,
      lastEditor: user,
      filterType: filterType,
      tags: [],
      phases: [
        {
          phaseId: uuid(),
          phaseName: "",
          phaseOrder: 1,
        },
      ],
      gantt: {
        data: [
          {
            bar_height: 35,
            duration: 1,
            end_date: Moment(new Date()).format("DD-MM-YYYY") + " 00:00", // "28-11-2022 00:00",
            hide_bar: true,
            id: new Date().getTime(),
            margin: 0,
            parent: 0,
            progress: 0,
            resource: "",
            row_height: 130,
            start_date: Moment(new Date()).format("DD-MM-YYYY") + " 00:00", // "28-11-2022 00:00",
            type: "project",
          },
        ],
      },
      types: "Web Design",
      date: Moment(new Date()).format("h:mm a MMMM DD, YYYY"),
      favorite: false,
      organization: this.state.organization,
      people: this.state.people,
      modified: Moment(new Date()).format("h:mm a MMMM DD, YYYY"),
      archive: false,
      timestamp: Math.floor(Date.now() / 1000),
    };
    if (this.props.estimateType !== "time-based") {
      delete estimateData.gantt;
    }
    const key = estimateRef.push(estimateData).getKey();
    for (let i = 0; i < this.state.arrayItem.length; i++) {
      if (this.state.organization === this.state.arrayItem[i]["title"]) {
        const id = this.state.arrayItem[i]["id"];
        let estimates = [];
        if (this.state.arrayItem[i][pluralDataType]) {
          estimates = this.state.arrayItem[i][pluralDataType];
        }
        let people = [];
        const person = this.state.arrayItemPeople.filter(item => item.title === this.state.people)[0]
        if (this.state.arrayItem[i]["people"]) {
          people = this.state.arrayItem[i]["people"];
        }
        estimates.push({
          id: key,
          title: projectname,
        });
        people.push({
          email: person.email,
          id: person.id,
          title: person.title
        })
        const organizationsRef = fire.database().ref(companyId).child("organizations").child(id);
        organizationsRef.update({
          estimates: estimates,
          people: people
        });
      }
    }
    for (let i = 0; i < this.state.arrayItemPeople.length; i++) {
      if (this.state.people === this.state.arrayItemPeople[i]["title"]) {
        const id = this.state.arrayItemPeople[i]["id"];
        let estimates = [];
        if (this.state.arrayItemPeople[i][pluralDataType]) {
          estimates = this.state.arrayItemPeople[i][pluralDataType];
        }
        estimates.push({
          id: key,
          title: projectname,
        });
        const org = this.state.arrayItem.filter(item => item.title === this.state.organization)[0].id
        const peoplelistRef = fire.database().ref(companyId).child("people").child(id);
        const data = {};
        data[pluralDataType] = estimates;
        data.organization = org
        peoplelistRef.update(data);
      }
    }
    let temptype = "";
    if (estimateData.typeOf === "TIME BASED ESTIMATE") {
      temptype = "-timebased";
    }
    this.props.history.push(
      "/" +
      pluralDataType +
      temptype +
      "/" +
      estimateData.projectName +
      "/" +
      key
    );
  }

  mange = () => {
    this.setState({
      it1: true,
    });
  };
  mange1 = () => {
    this.setState({
      it2: true,
    });
  };
  createEstimate = () => {
    $("#breadcrumb-separator").remove();
    $("#link-breadcrumb").remove();
    const title = this.props.Projectvalue;
    const breadcrumbHTML =
      '<li aria-hidden="true" class="MuiBreadcrumbs-separator" id="breadcrumb-separator">/</li><li id="link-breadcrumb" class="MuiBreadcrumbs-li"><a class="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary">' +
      title +
      "</a></li>";
    $(".breadcrumb ol").append(breadcrumbHTML);
  };
  newEstimate = ((type, value) => {
    if (type === "organization") {
      this.setState({ organization: value });
      this.getOrgList();
    } else {
      this.setState({ people: value });
      this.getContactList();
    }
  });
  newContactValue = (value) => {
    this.props.newContactValue(value);
  };
  getOrgList = () => {
    const companyId = localStorage.getItem('companyId');
    const organizationListRef = fire.database().ref(companyId).child("organizations");
    organizationListRef.once("value").then((snapshot) => {
      const listOrg = snapshot.val();
      organizationListRef.off("value");
      const arrOrg = [];
      for (const id in listOrg) {
        arrOrg.push({ id, ...listOrg[id] });
      }
      this.setState({ arrayItem: arrOrg });
    });
  }
  getContactList = () => {
    const companyId = localStorage.getItem('companyId');
    const peopleListRef = fire.database().ref(companyId).child("people");
    peopleListRef.once("value").then((snapshot) => {
      const listPeople = snapshot.val();
      peopleListRef.off("value");
      const arrPeople = [];
      for (const id in listPeople) {
        arrPeople.push({ id, ...listPeople[id] });
      }
      this.setState({ arrayItemPeople: arrPeople });
    });
  }
  componentDidMount = () => {
    this.getOrgList()
    this.getContactList()
  };
  render() {
    const { dataType = "estimates" } = this.props;
    const friendlyName = dataType === "estimates" ? "Estimate" : "Template";

    return (
      <React.Fragment>
        <div style={this.state.overlay_content_wrap}>
          <h1>… and the finishing touch.</h1>
          <p className="big-paragraph">Please choose a company and contact.</p>
          <div className={this.props.classes.rootGrid}>
            <Grid container spacing={1}>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6} className="createproject first">
                  <Paper className={this.props.classes.paper}>
                    <div className={this.props.classes.root}>
                      <BusinessOutlinedIcon
                        htmlColor="#000000"
                        className={this.props.classes.icon}
                      />
                      <AutoCompleteCmp
                        itemList={this.state.arrayItem}
                        dataType={"organization"}
                        newEstimate={this.newEstimate}
                        classes={this.props.classes.addTask}
                        title={"organization"}
                        mange={this.mange}
                        id={"yue"}
                      />
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="this.props.classes.icon createproject second"
                >
                  <Paper className={this.props.classes.paper}>
                    <div className={this.props.classes.root}>
                      <PermContactCalendarOutlinedIcon
                        htmlColor="#000000"
                        className={this.props.classes.icon}
                      />
                      <AutoCompleteCmp
                        itemList={this.state.arrayItemPeople}
                        dataType={"people"}
                        newEstimate={this.newEstimate}
                        classes={this.props.classes.addTask}
                        title={"contact"}
                        mange={this.mange1}
                        id={"yue1"}
                        newContactValue={this.newContactValue}
                      />
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Button
            style={this.state.newestimateback}
            onClick={this.props.onBack}
            className="back_btn"
          >
            Back
          </Button>
          {this.state.it1 && this.state.it2 ? (
            <React.Fragment>
              <Button
                style={this.state.createStyle1}
                className={"linkstyle " + this.props.classes.newestimate}
                onClick={() => {
                  this.createEstimate();
                  this.props.close();
                  this.props.onReset();
                  this.estimates();
                }}
              >
                Create {friendlyName}
              </Button>
            </React.Fragment>
          ) : (
            <Button
              style={this.state.createStyle}
              className={this.props.classes.newestimate}
            >
              Create {friendlyName}
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ContactSave);
