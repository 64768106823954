import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { debounce } from 'lodash';
import {
    Grid,
    MenuItem
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuDropdown from '../small-components/MenuDropdown/MenuDropdown';

function FilterBox(props) {
    const { organizations } = props;

    const newStyles = makeStyles({
        sortCol: {
            ' .arrow-outer': {
                transform: 'rotate(0deg)',
                transition: 'all 0.3s  ease-in-out'
            },
        },
        formRoot: {
            margin: '1rem 0',
        },
        formLabel: {
            fontSize: 14,
            color: "#000000",
        },
        formDisabled: {
            color: "#9e9e9e !important"
        },
        checkbox: {
            color: "#084D4F !important",
        },
        checkBoxDisabled: {
            opacity: '.5'
        },
        menuDropdown: {
            marginTop: '1rem',
            marginBottom: '1rem',
        },
        menuDropdownInput: {
            padding: '8.5px 10px',
            paddingRight: '31px !important',
            fontWeight: 300
        },
        menuDropdownIcon: {
            marginRight: '5.5px',
        },
        error: {
            border: "1px solid #db263b !important"
        }
    });

    const [sort, setSort] = useState('recent');
    const archivedEstimate = 2;

    const handleSort = (event) => {
        setSort(event.target.value);
        props.handleSort(event.target.value);
    };

    const classes = newStyles();
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const inputHandler = (e) => {
        setValue(e);
        props.change(e);
        setError(false);
    }
    const clearInput = () => {
        setValue('');
        props.change('');
        document.getElementById("tags").value = "";
    }
    const onsubmit = (event) => {
        if (['INPUT'].indexOf(event.target.tagName)) {
            return;
        }
        const { keyCode } = event;
        if (keyCode === 13 && value === "") {
            setError(true);
        }
    };
    const handleArchive = debounce((e) => {
        props.handleArchive();
    });
    useEffect(() => {
        document.getElementById("tags").addEventListener('keydown', onsubmit);
    }, [onsubmit]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <div className="input-field col" style={{ width: 300 }}>
                    <label>
                        <i className="material-icons icon">
                            <SearchIcon htmlColor='#000000' />
                        </i>
                    </label>
                    <input type="text"
                        className={`autoComplete ${error && classes.error}`}
                        onChange={(e) => inputHandler(e.target.value)}
                        defaultValue={props.defaultSearchValue && props.defaultSearchValue}
                        placeholder={`${props.placeholder ? props.placeholder : "Search Estimates"}`}
                        id="tags" />
                    {value !== "" && (
                        <ClearIcon onClick={clearInput} className="cross" />
                    )}

                </div>
            </Grid>
            <div className={props.classes.grow} />
            {props.dataType === "estimates" ? (
                <Grid item className={props.classes.sortCol}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedC"
                                onClick={(e) => handleArchive(e)}
                                classes={{ root: classes.checkbox, disabled: classes.checkBoxDisabled }}

                            />
                        }
                        label="Show archived"
                        disabled={archivedEstimate === 0 ? true : false}
                        classes={{ label: classes.formLabel, root: classes.formRoot, disabled: classes.formDisabled }}
                    />

                </Grid>) :
                null}
            <Grid item className={props.classes.sortCol}>
                <MenuDropdown
                    className={classes.menuDropdown}
                    value={props.orgAge}
                    change={props.handleOrganization}
                    displayEmpty
                    selectInputProps={{
                        classes: {
                            root: classes.menuDropdownInput,
                            icon: classes.menuDropdownIcon,
                        },
                    }}
                    inputProps={{ 'aria-label': 'Organization' }}
                    IconComponent={ExpandMoreIcon}
                    getContentAnchorEl={null}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    }}

                >
                    <MenuItem value="">Organization</MenuItem>
                    {organizations?.map((item, index) => (<MenuItem key={item.id + index} value={item.title}>{item.title}</MenuItem>))}

                </MenuDropdown>
            </Grid>
            <Grid item className={props.classes.sortCol}>
                <MenuDropdown
                    className={classes.menuDropdown}
                    value={props.age}
                    change={props.handleChange}
                    displayEmpty
                    selectInputProps={{
                        classes: {
                            root: classes.menuDropdownInput,
                            icon: classes.menuDropdownIcon,
                        },
                    }}
                    inputProps={{ 'aria-label': 'Type' }}
                    IconComponent={ExpandMoreIcon}
                    getContentAnchorEl={null}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    }}

                >
                    <MenuItem value="">Type</MenuItem>
                    <MenuItem value={"time-based"}>Time Based</MenuItem>
                    <MenuItem value={"task-based"}>Task Based</MenuItem>
                </MenuDropdown>

            </Grid>
            <Grid item className={props.classes.sortCol}>
                <MenuDropdown
                    className={classes.menuDropdown}
                    selectInputProps={{
                        classes: {
                            root: classes.menuDropdownInput,
                            icon: classes.menuDropdownIcon,
                        },
                    }}
                    getContentAnchorEl={null}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    }}
                    value={sort}
                    change={handleSort}
                >
                    <MenuItem value="favourites">Favorites</MenuItem>
                    <MenuItem value="recent">Recent</MenuItem>
                    <MenuItem value="ascending">Ascending</MenuItem>
                    <MenuItem value="descending">Descending</MenuItem>
                </MenuDropdown>

            </Grid>
        </Grid>
    )
}

function MapStateToProps(state) {
    return {
        organizations: state.getOrganizations.organizations,
    };
}
function MapDispatchToProps() {
    return {
    };
}

export default connect(MapStateToProps, MapDispatchToProps)(FilterBox);