import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    organizations: null,
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

export const getOrganizationsAsync = createAsyncThunk(
    'organizations/getOrganizations',
    async (query = {}) => {
        const companyId = query.companyId || localStorage.getItem('companyId');
        const orgsRef = fire.database().ref(companyId).child("organizations");
        const orgSnap = await orgsRef.once("value");
        const orgs = orgSnap.val() || {};
        orgsRef.off("value");

        const orgsList = [];
        for (const id in orgs) {
            orgsList.push({ id, database: "organizations", ...orgs[id] });
        }

        return { organizations: orgsList };
    }
);

// Redux Toolkit slice
export const getOrganizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrganizationsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getOrganizationsAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.organizations = action.payload.organizations
            });
    },
});

export default getOrganizationsSlice.reducer;
