import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Legend from '../UI/Legend';
import '../../../assets/scss/company-dropdown.scss';
import Manage_menu from '../ManageMenuItem';

const useStyles = makeStyles({
  paper: {
    borderRadius: 5,
    backgroundColor: 'rgba(8, 77, 79, 0.05)',
    '&:hover $iconButton': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  legend: {
    margin: '17px 0 17px 18px',
  },
  iconButton: {
    padding: 1,
    opacity: 0,
    visibility: 'hidden',
  },
  icon: {
    width: 22,
    height: 22,
  },
  addIconButton: {
    padding: '14px 20px',
  },
  label: {
    marginTop: 30,
  },
  optionOuter: {
    '&:hover': {
      '& button': {
        opacity: '1',
        visibility: 'visible'
      }
    },
    '& button': {
      opacity: 0,
      visibilty: 'hidden',
      minWidth: '32px',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          fill: '#e70a89 !important'
        }
      }

    },

  },
  btnOutr: {
    display: 'flex',
  }

});

export default function StatBox(props) {

  const classes = useStyles();
  const list = props.list;
  //let optionChange = optionChange;
  const optionChange = (value, itemsid) => {
    props.optionValue(value, itemsid);
  }
  const onIconButtonClick = () => {
    props.triggerDrawer('new');
  }
  return (
    <React.Fragment>
      {
        props.items.map(function (i, index) {
          return (
            <Grid item xs={4} className="stat-block" key={index}>
              <Paper className={classes.paper} elevation={0}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={8}>
                    <Legend className={classes.legend} component="h6" variant="h6">
                      {i.legend}
                    </Legend>
                  </Grid>
                  <Grid item xs={4} container justify="flex-end" alignItems="center" className={classes.optionOuter}>
                    {i.figure ? (
                      <React.Fragment>
                        <Typography component="span" variant="h5">
                          {i.type === "negative" ? '-' : "+"}{i.figure}{"%"}
                        </Typography>
                        <Manage_menu
                          optionsList={list} itemsid={i.id} type={i.contactType}
                          optionValues={optionChange} />
                      </React.Fragment>
                    ) : ''}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        })
      }
      <Grid item xs={4} className="stat-block">
        <Paper className={classes.paper} elevation={0}>
          <Grid container justify="space-between" alignItems="center" >
            <Grid item xs={8}>
              <Legend className={classes.legend} component="h6" variant="h6">
                Add Calculation
              </Legend>
            </Grid>
            <Grid item xs={4} container justify="flex-end" alignItems="center" class={'btnoutr'}>
              <IconButton
                aria-label="add stat"
                onClick={onIconButtonClick}
                classes={{ root: classes.addIconButton }}
              >
                <AddCircleRoundedIcon htmlColor="#084d4f" />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}