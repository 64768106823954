import firebase from 'firebase';

const urlConfigs = [
  {// https://us-central1-estimatic-staging.cloudfunctions.net/emailtouser
    urls: ['localhost'],
    firebaseConfigKey: 'estimatic-staging',
    functionUrl: `http://localhost:3000`
    // functionUrl: `https://us-central1-estimatic-staging.cloudfunctions.net`
  },
  {
    urls: ['estimatic-staging', 'app-staging--estimatic-react.netlify.app'],
    firebaseConfigKey: 'estimatic-staging',
    functionUrl: `https://us-central1-estimatic-staging.cloudfunctions.net`
  },
  // https://us-central1-estimatic-staging.cloudfunctions.net
  //emailtouser?email=edurna%2Bs34%40gmail.com&logintoken=666a2b-83e-823-2c71-dbffe12c65de&firstname=esref&lastname=durna&companyId=1234
  {
    urls: ['app.estimatic.com'],
    firebaseConfigKey: 'estimatic-v1',
    functionUrl: `https://us-central1-estimatic-v1.cloudfunctions.net`
  }
];
const firebaseConfigs = {
  'estimatic-staging': {
    apiKey: "AIzaSyASzTJhgnrR_qNsLFFDk-_GQNgq0T01vuc",
    authDomain: "estimatic-staging.firebaseapp.com",
    databaseURL: "https://estimatic-staging-default-rtdb.firebaseio.com",
    projectId: "estimatic-staging",
    storageBucket: "estimatic-staging.appspot.com",
    messagingSenderId: "276792956661",
    appId: "1:276792956661:web:580108fffc838ba75957bc"
  },
  'estimatic-v1': {
    apiKey: "AIzaSyDzAPTzHE7EKTYsJj21l00mZXCv9Msx-9k",
    authDomain: "estimatic-v1.firebaseapp.com",
    databaseURL: "https://estimatic-v1.firebaseio.com",
    projectId: "estimatic-v1",
    storageBucket: "estimatic-v1.appspot.com",
    messagingSenderId: "115578563399",
    appId: "1:115578563399:web:82aea2a68993cb8c277a62",
    measurementId: "G-JT8CPE3J0L"
  }
}
let currentUrlConfig = urlConfigs[0];

for (const urlConfig of urlConfigs) {
  for (const url of urlConfig.urls) {
    if (window.location.origin.indexOf(url) > 0) {
      currentUrlConfig = urlConfig;
      break;
    }
  }
}
// currentUrlConfig = urlConfigs[2]; // overwrite here

export const getFunctionUrl = () => {
  return currentUrlConfig.functionUrl;
}

const firebaseConfig = firebaseConfigs[currentUrlConfig.firebaseConfigKey]

const fire = firebase.initializeApp(firebaseConfig);
export default fire;