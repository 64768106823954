import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import Canvas from "../reusable-components/Canvas";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import fire from "../../config/firebase";
import PhaseCalculation from "./Phase/phaseCalculationUtil";
//import formatMoneyStr from "../../libs/formatMoneyStr";
import formatMoneyStrFriendly from "../../libs/formatMoneyStrFriendly";
import formatHour from "../../libs/formatHour";
import { colorArray } from "../../libs/color/colorUtils";
import * as getDisciplinesActionCreators from '../../store/disciplines/getDisciplines'
import * as getResourcesActionCreators from '../../store/resources/getResources'
const Summary = (props) => {
  const { resources, phases, disciplines, estimate: temp, calculations } = props;
  useEffect(() => {
    init();
    if (!disciplines) {
      const { getDisciplinesAsync } = props.getDisciplinesAction;
      getDisciplinesAsync();
    }
    if (!resources) {
      const { getResourcesAsync } = props.getResourcesAction;
      getResourcesAsync();
    }
    //getResourcesAction
  }, [disciplines, temp, resources, phases]);
  // const marginStyleRed = {
  //   color : '#e70a89',
  // }
  // const marginStyleGreen = {
  //   color : '#65a838',
  // }
  // console.log(props)

  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    if (summaryData) {
      summaryData.depMap = []
      summaryData.roleMap = []
      summaryData.phaseMap = []

    }

  }, [summaryData]);

  const init = async () => {
    const colorList = colorArray;
    // const { dataType, dataKey } = props;
    // let templateName = "estimates";
    // if (dataType === "estimates") {
    //   templateName = "estimates";
    // } else if (dataType === "templates") {
    //   templateName = "templates";
    // }
    // const companyId = localStorage.getItem('companyId'); // ref(companyId).child
    // const currentEstimate = fire.database().ref(companyId).child(templateName + "/" + dataKey);
    // const snap = await currentEstimate.once("value");
    // const estimates = snap.val();

    // const refCompany = fire.database().ref(companyId).child("company");
    // const companySnapshot = await refCompany.once("value");
    // const company = companySnapshot.val();
    // refCompany.off("value");

    // const disciplinesList = await getDbList("disciplines");

    if (!temp || !disciplines || !resources || !phases) {
      return;
    }

    const estimate = JSON.parse(JSON.stringify(temp));
    // const phasesList = await getDbList("phases");

    // const calculations = company.arrayItem.map((autocalculation) => {
    //   autocalculation.figure = parseInt(autocalculation.figure);
    //   return autocalculation;
    // });

    const depMapTemp = new Map();
    const roleMapTemp = new Map();
    const phaseMapTemp = new Map();
    const calculateMapTemp = new Map();

    const updateMap = (cMap, obj, keyName, values, defaultVal) => {
      const current = cMap.get(obj[keyName] || "Role Name") || {
        cost: 0,
        hours: 0,
        price: 0,
        name: obj[keyName] || defaultVal || "Role Name",
      };
      current.hours += values.hours;
      current.cost += values.cost;
      current.price += values.price;
      cMap.set(current.name, current);

    };
    let duplicate = 1;
    estimate?.phases?.sort((a, b) => a.phaseOrder - b.phaseOrder);

    estimate?.phases?.forEach((phase) => {
      const calculateMapTempItem = new Map();
      const phaseCal = new PhaseCalculation(phase, calculations, estimate);

      const phaseValues = {};
      phaseValues.hours = phaseCal.totalPhaseHours();
      phaseValues.cost = phaseCal.totalPhaseCost();
      phaseValues.price = phaseCal.totalPhasePrice();
      const phaseWithoutAuto = phaseCal.totalPhaseHours(false);

      phaseCal.calculations?.forEach((calculate) => {
        const calTotal = phaseCal.calculationApply(calculate, phaseWithoutAuto, 0);
        let current = { hours: 0, cost: 0, price: 0, name: calculate.legend };

        if (calculateMapTempItem.has(calculate.legend)) {
          current = calculateMapTempItem.get(calculate.legend);
        }
        current.hours += calTotal;
        const phaseHour = phaseValues.hours === 0 ? 1 : phaseValues.hours
        const calPercent = current.hours / phaseHour;
        console.log('calpercent', calPercent)
        current.cost = phaseValues.cost * calPercent;
        current.price = phaseValues.price * calPercent;
        calculateMapTempItem.set(calculate.legend, current);
      });

      phase.phaseName = phase.phaseName || `Phase-${duplicate++}`;
      updateMap(phaseMapTemp, phase, "phaseName", phaseValues);
      phase?.tasks?.sort((a, b) => a.taskOrder - b.taskOrder);
      phase?.tasks?.forEach((task) => {
        task?.resources?.forEach((resource) => {
          //console.log('resource abc', resource );
          const values = {};
          values.hours = phaseCal.resourceHours(resource, false) *
            (task.qty || 1);
          values.cost =
            phaseCal.resourceCalculate(resource, "cost", false) *
            (task.qty || 1);

          values.price =
            phaseCal.resourceCalculate(resource, "amount", false) *
            (task.qty ? task.qty : 1);

          // if (resource.resourceName === "Developer - Offshore") {
          //   console.log(resource)
          //   console.log(values)
          // }

          updateMap(depMapTemp, resource, "caption", values, 'Other');
          updateMap(roleMapTemp, resource, "title", values);
        });
      });

      calculateMapTempItem.forEach((item) => {

        const current = calculateMapTemp.get(item.name) || {
          cost: 0,
          hours: 0,
          price: 0,
          name: item.name
        };
        current.cost += item.cost;
        current.hours += item.hours;
        current.price += item.price;
        calculateMapTemp.set(item.name, current)

      });
    });
    const calculateMap = []
    calculateMapTemp.forEach(item => calculateMap.push(item))

    const fillChart = (mepTemp, nameField, valueField, findColorRef = []) => {
      const arr = [];
      mepTemp.forEach((value) => arr.push(value));

      const curChart = { color: [], name: [], perc: [] };
      let total = 0;
      for (const item of arr) {
        total += item[valueField];
      }
      for (let i = 0; i < arr.length; i++) {
        let itemColor = colorList[i % colorList.length];
        const dbColor = findColorRef.find((d) => d.title === arr[i].name);
        if (dbColor && dbColor.borderColor && dbColor.borderColor !== "#fffff") {
          itemColor = dbColor.borderColor;
        }
        arr[i].color = itemColor;
        curChart.name.push(arr[i][nameField]);
        //console.log('curChart.name', curChart.name);
        //console.log('Names ', arr[i][nameField]);
        if (arr[i][nameField] === 'Other') {
          curChart.color.push('#084d4f');
          //console.log('pushed!');
        } else {
          curChart.color.push(itemColor);
        }

        curChart.perc.push(100 * (arr[i][valueField] / total));
      }
      return [arr, curChart];
    };

    const [depMap, depChart] = fillChart(
      depMapTemp,
      "name",
      "price",
      disciplines
    );
    const [roleMap, roleChart] = fillChart(
      roleMapTemp,
      "name",
      "price",
      resources
    );

    const [phaseMap, phaseChart] = fillChart(
      phaseMapTemp,
      "name",
      "price",
      phases
    );

    const tempSummaryData = {
      depMap,
      depChart,
      roleMap,
      roleChart,
      phaseMap,
      phaseChart,
      calculateMap
    };

    setSummaryData(tempSummaryData);
  };

  const getDiscountValue = (price) => {
    const { estimate } = props;
    const { discountType } = estimate;
    let { discountValue = 0 } = estimate;
    if (!discountValue || discountValue === "" || discountValue === "0") {
      return 0;
    }
    if (discountValue === 0) {
      return 0;
    }
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (typeof discountValue !== "number") {
      if (specialChars.test(discountValue)) {
        discountValue = discountValue.replace(/[^a-zA-Z0-9]/g, "");
      }
      discountValue = parseFloat(discountValue);
    }
    if (discountType === "%") {
      const temp = (price * discountValue) / 100;
      return Math.round(temp);
    }
    console.log(discountType, discountValue)

    return discountValue;
  }

  const getMargin = (price, cost) => {
    if (!price) {
      return 0;
    }
    return ((price - cost - getDiscountValue(price)) / price)
  }

  if (!summaryData) {
    return null;
  }
  const getCellValue = (val) => {
    if (isNaN(val) || val === undefined || isFinite(val) === false) {
      return 0;
    }
    return val;
  };

  const isPerc = (arr) => {
    const val = arr.find((e) => e > 0)
    if (val >= 0) {
      return true;
    } else {
      return false;
    }
  }
  if (!summaryData) {
    return null;
  }
  const { depMap, depChart, roleMap, roleChart, phaseMap, phaseChart, calculateMap } =
    summaryData;
  depChart.perc = depChart.perc.filter(d => d > 0);

  return (
    <div id="summary" className="table col l12 s12">
      {depMap && depMap.length ? null : "No data"}

      {
        (depMap.length && depChart.perc.length > 0) && (
          <div className="row card">
            <div className="col l8 ">
              <table className="bordered summary-table">
                <thead>
                  <tr>
                    <th>Discipline</th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <th>Hours</th>
                    <th>Cost</th>
                    <th>Price</th>
                    <th>Margin</th>
                  </tr>
                </thead>
                <tbody>
                  {depMap.map((item, index) => {
                    // console.log('item.name', item.name);
                    // console.log('item.hours', item.hours);
                    // console.log('item.price', item.price);
                    if (item.cost !== 0 && item.price !== 0) {

                      return (
                        <tr className="row" key={index + item.name}>
                          <td>
                            <FiberManualRecordIcon htmlColor={(item.name === 'Other' || item.cost === 0 || item.price === 0) ? '#084d4f' : item.color} />
                            {`${item.name}` === ' ' ? ' ' : `${item.name}`}
                          </td>
                          <td>
                            <input
                              defaultValue={formatHour({ number: getCellValue(item.hours) })}
                              placeholder="Hours"
                              className="validate col s9"
                            ></input>
                          </td>
                          <td>
                            <input
                              defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.cost) })}
                              placeholder="Cost"
                              className="validate col s9"
                            ></input>
                          </td>
                          <td>
                            <input
                              defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.price) })}
                              placeholder="Price"
                              className="validate col s9"
                            ></input>
                          </td>
                          <td>
                            <input
                              //style={item.cost > item.price ? marginStyleRed : marginStyleGreen}
                              // defaultValue={formatMoneyStr({
                              //   number: item.price - item.cost,
                              // })}
                              defaultValue={getMargin(item.price, item.cost) ? (getMargin(item.price, item.cost) * 100).toFixed(0) + '%' : 0 + '%'}
                              placeholder="Margin"
                              className="validate col s9"
                            ></input>
                          </td>
                        </tr>
                      );

                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>
            <div className="col l4">
              <Canvas
                perc={depChart.perc}
                name={depChart.name}
                color={depChart.name === 'Other' ? '#084d4f' : depChart.color}
                sectionName={'DISCIPLINE'}
              />
            </div>
          </div>
        )}

      {(roleMap.length > 0 && isPerc(roleChart.perc)) && (
        <div className="row card">
          <div className="col l8">
            <table className="bordered summary-table">
              <thead>
                <tr>
                  <th>Role</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Hours</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Margin</th>
                </tr>
              </thead>
              <tbody>
                {roleMap.map((item, index) => {
                  return (
                    <tr className="row" key={index}>
                      <td>
                        <FiberManualRecordIcon htmlColor={item.color} />
                        {item.name}
                      </td>
                      <td>
                        <input
                          defaultValue={formatHour({ number: getCellValue(item.hours) })}
                          key={index + item.name}
                          placeholder="Hours"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.cost) })}
                          placeholder="Cost"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.price) })}
                          placeholder="Price"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          // defaultValue={formatMoneyStr({
                          //   number: item.price - item.cost,
                          // })}
                          defaultValue={getMargin(item.price, item.cost) ? (getMargin(item.price, item.cost) * 100).toFixed(0) + '%' : 0 + '%'}
                          placeholder="4"
                          className="validate col s9"
                        ></input>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="bordered summary-table">
              <thead>
                <tr>
                  <th>Auto Calculations</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Hours</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Margin</th>
                </tr>
              </thead>
              <tbody>
                {calculateMap.map((item, index) => {
                  return (
                    <tr className="row" key={index}>
                      <td>
                        <FiberManualRecordIcon htmlColor={item.color} />
                        {item.name}
                      </td>
                      <td>
                        <input
                          defaultValue={formatHour({ number: getCellValue(item.hours) })}
                          key={index + item.name}
                          placeholder="Hours"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.cost) })}
                          placeholder="Cost"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.price) })}
                          placeholder="Price"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          // defaultValue={formatMoneyStr({
                          //   number: item.price - item.cost,
                          // })}
                          defaultValue={getMargin(item.price, item.cost) ? (getMargin(item.price, item.cost) * 100).toFixed(0) + '%' : 0 + '%'}
                          placeholder="4"
                          className="validate col s9"
                        ></input>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="col l4">
            <Canvas
              perc={roleChart.perc}
              name={roleChart.name}
              color={roleChart.color}
              sectionName={'ROLE'}
            />
          </div>
        </div>
      )}
      {phaseMap.length > 0 && (
        <div className="row card">
          <div className="col l8">
            <table className="bordered summary-table">
              <thead>
                <tr>
                  <th>Phase</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Hours</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Margin</th>
                </tr>
              </thead>
              <tbody>
                {phaseMap.map((item, index) => {
                  return (
                    <tr className="row" key={index}>
                      <td>
                        <FiberManualRecordIcon htmlColor={item.color} />
                        {item.name || "Phase-" + (index + 1)}
                      </td>
                      <td>
                        <input
                          defaultValue={formatHour({ number: getCellValue(item.hours) })}
                          placeholder="Hours"
                          key={index + item.name}
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.cost) })}
                          placeholder="Cost"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          defaultValue={formatMoneyStrFriendly({ number: getCellValue(item.price) })}
                          placeholder="Price"
                          className="validate col s9"
                        ></input>
                      </td>
                      <td>
                        <input
                          // defaultValue={formatMoneyStr({
                          //   number: item.price - item.cost,
                          // })}
                          defaultValue={getMargin(item.price, item.cost) ? (getMargin(item.price, item.cost) * 100).toFixed(0) + '%' : 0 + '%'}
                          placeholder="Margin"
                          className="validate col s9"
                        ></input>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="col l4">
            <Canvas
              data={phaseMap}
              perc={!isPerc(phaseChart.perc) || phaseChart.perc.length === 0 ? [100] : phaseChart.perc}
              name={phaseChart.name}
              color={!isPerc(phaseChart.perc) ? '#F2F6F6' : (phaseChart.name === 'Phase-1') ? '#084d4f66' : phaseChart.color}
              sectionName={'PHASE'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

function MapStateToProps(state) {
  return {
    estimate: state.getEstimate.estimate,
    company: state.getCompany.company,
    resources: state.getResources.resources,
    phases: state.getPhases.phases,
    calculations: state.getCompany.calculations,
    disciplines: state.getDisciplines.disciplines,
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getDisciplinesAction: bindActionCreators(
      getDisciplinesActionCreators,
      dispatch
    ),
    getResourcesAction: bindActionCreators(
      getResourcesActionCreators,
      dispatch
    ),

  };
}

export default connect(MapStateToProps, MapDispatchToProps)(Summary);