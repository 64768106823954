import React, { useEffect, useState } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import clsx from "clsx";
import fire from "../../config/firebase";
import "../../assets/scss/navigation.scss";
import { NavLink, withRouter, Link } from "react-router-dom";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import AdminNav from "./AdminNav";
import Overlay from "../reusable-components/overlay";
import BreadCrumb from "../breadcrumb/BreadCrumb";
import menu from "../../assets/images/menu.svg";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Menu from "@material-ui/core/Menu";
import MenuOpenIcon from "../../assets/images/menu-open.svg";
import MenuItem from "@material-ui/core/MenuItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import StarIcon from "@material-ui/icons/Star";
import closeLogo from "../../assets/images/logo-close.svg";
import Search from "../reusable-components/Search/Search";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import estimatic_logo from "../../assets/images/estimatic-logo.svg";
import disciplines_icon_pink from "../../assets/images/disciplines_pink.svg";
import lottie from "lottie-web";
import animationData from "../../lottie/logo-animation";
import SettingsTable from "../reusable-components/SettingsTable";
import EstimateFor from "../reusable-components/EstimateFor";
import Profile from "../pages/Profile";
import AlertNavDialog from "../small-components/AlertNavDialog/AlertNavDialog";
import { estimateIcon, templateIcon } from "../icons";
import { isTrue } from "../../libs/utils";
import * as getEstimateActionCreators from '../../store/estimate/getEstimate';
import * as getTagsActionCreators from '../../store/tags/getTags';
import * as getCompanyActionCreators from '../../store/company/getCompany';
import * as getResourcesActionCreators from '../../store/resources/getResources';
import * as getUsersActionCreators from '../../store/users/getUsers';
import * as getOrganizationsActionCreators from '../../store/organizations/getOrganizations';
import * as getContactsActionCreators from '../../store/contacts/getContacts';
import * as getPhasesActionCreators from '../../store/phases/getPhases';
import * as getTasksActionCreators from '../../store/tasks/getTasks';

const drawerWidth = 275;

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
        top: "92px !important",
        borderRadius: "8px",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        position: "relative",
        width: "275px",
        "&:hover": {
            backgroundColor: fade(theme.palette.common.black, 0.1),
        },
        "& .MuiListItemText-primary": {
            fontSize: "16px",
        },
        "& .MuiListItemIcon-root": {
            minWidth: "35px",
        },
        "& .title": {
            marginTop: "20px",
        },
        "& .manage-links": {
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
        },
    },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    root: {
        display: "flex",
    },
    list: {
        width: "100%",
        maxWidth: 360,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: fade(theme.palette.common.black, 1),
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 8,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 8,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "0px 20px 0 10px",
        backgroundColor: fade(theme.palette.common.black, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    iconList: {
        position: "relative",
        padding: "13px 22px 13px 28px",
    },
    createNew: {
        padding: "18px 22px 18px 28px",
    },
    innerList: {
        position: "relative",
        padding: "10px 22px 10px 28px",
        backgroundColor: "rgba(8, 77, 79, 0.03)",
        "& .notfeaturedIcon": {
            display: "none",
        },
        "&:hover": {
            backgroundColor: "rgba(8, 77, 79, 0.03)",
            "&.not-featured": {
                "& .featuredIcon": {
                    display: "none",
                },
                "& .notfeaturedIcon": {
                    display: "block",
                },
            },
        },
        "&.not-featured": {
            "& .featuredIcon": {
                display: "none",
            },
            "& .notfeaturedIcon": {
                display: "block",
            },
        },
    },
    IconStyle: {
        minWidth: "52px",
    },
    links: {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
    },
    accordion: {
        padding: "0 20px 0 0",
    },
    accordionContent: {
        margin: "0px !important",
    },
    listinnertext: {
        whiteSpace: "break-spaces",
    },
    closeLogo: {
        marginRight: "20px",
        paddingLeft: "6px",
    },
    divider: {
        width: "calc(100% - 50px)",
        margin: "0 auto",
        backgroundColor: "rgba(8, 77, 79, 0.2)",
    },
    userName: {
        padding: "0px",
        borderRadius: "50%",
        minWidth: "inherit",
        width: "25px",
        height: "25px",
        color: "#ffffff",
        backgroundColor: "#2ab7ca",
        fontWeight: "900",
        lineHeight: "1",
        textAlign: "center",
        marginLeft: "16px",
        "&:focus": {
            backgroundColor: "#2ab7ca",
        },
    },
    help_link: {
        height: "30px",
        width: "30px",
        color: "white",
        '&:hover': {
            color: "rgba(231, 10, 137, 1)",
        },
    },
    wrapper_link: {
        height: "30px",
        width: "30px",
    },
}));

function SharedHeader(props) {
    const { company = {}, location, updateCnt = 0, history, update, changeRecord, users = [], getUsersAction } = props;

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        document.querySelector("header").classList.add("admin-active");
    };

    const handleClose = () => {
        setAnchorEl(null);
        document.querySelector("header").classList.remove("admin-active");
        const el = document.querySelector("header");
        if (el.classList.contains("nav-active")) {
            document.querySelector("header").classList.remove("nav-active");
        }
    };
    const createNew = (e) => {
        if (e) {
            e.preventDefault();
        }
        document.querySelector(".overlay").classList.add("activeOverlay");
        document.querySelector("body").classList.add("estimateAdditon");
    };

    const links1 = [
        {
            text: "Dashboard",
            href: "/dashboard",
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23.058"
                    height="23.084"
                    viewBox="0 0 23.058 23.084"
                >
                    <g>
                        <g>
                            <path
                                fill="#000"
                                strokeWidth="0.5px"
                                d="M22.482 10.69l-1.608-1.607L12.027.235a.8.8 0 0 0-1.137 0L2.042 9.083.434 10.691a.8.8 0 0 0 1.137 1.137l.235-.236v10.123a.8.8 0 0 0 .8.8H20.3a.8.8 0 0 0 .8-.8V11.592l.236.236a.8.8 0 0 0 1.137-1.137zM13.87 20.911H9.045v-6.435h4.825zm5.63 0h-4.021v-7.239a.8.8 0 0 0-.8-.8H8.24a.8.8 0 0 0-.8.8v7.239H3.414V9.983l8.043-8.043L19.5 9.983z"
                                transform="translate(.042 .314) translate(.208) translate(-.208)"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            text: "Estimates",
            href: "/estimates",
            icon: estimateIcon(),
        },
        {
            text: "Templates",
            href: "/templates",
            icon: templateIcon(),
        },
        {
            text: "Contacts",
            href: "/contacts",
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.5"
                    height="22.5"
                    viewBox="0 0 20.5 22.5"
                >
                    <g>
                        <path
                            fillRule="evenodd"
                            stroke="#fff"
                            strokeWidth="0.5px"
                            d="M22.622 5.168a2.425 2.425 0 0 1 .2.374A2.2 2.2 0 0 1 23 6.4v15.4a2.242 2.242 0 0 1-.378 1.232 2.192 2.192 0 0 1-.6.594 2.465 2.465 0 0 1-.378.2 2.256 2.256 0 0 1-.867.176H5.222A2.211 2.211 0 0 1 3 21.8V6.4a2.2 2.2 0 0 1 .178-.858 2.423 2.423 0 0 1 .2-.374 2.193 2.193 0 0 1 .6-.594A2.269 2.269 0 0 1 5.222 4.2h1.111V2h2.223v2.2h8.889V2h2.222v2.2h1.111a2.256 2.256 0 0 1 .867.176 2.466 2.466 0 0 1 .378.2 2.192 2.192 0 0 1 .599.592zM5.222 6.4v15.4h15.556V6.4zM13 15.068c-2.256 0-6.667 1.188-6.667 3.938V20.7h13.334v-1.683c0-2.761-4.411-3.949-6.667-3.949zm0 2.2A7.845 7.845 0 0 0 8.9 18.5h8.2a7.813 7.813 0 0 0-4.1-1.232zm0-3.168a3.3 3.3 0 1 0-3.333-3.3A3.327 3.327 0 0 0 13 14.1zm1.111-3.3A1.111 1.111 0 1 0 13 11.9a1.109 1.109 0 0 0 1.111-1.1z"
                            transform="translate(.25 .25) translate(-3 -2)"
                        />
                    </g>
                </svg>
            ),
        },
    ];


    const list1 = [];
    for (let i = 0; i < links1.length; i++) {
        list1.push(
            <Tooltip title={links1[i].text} interactive placement="right" key={i}>
                <ListItem
                    button
                    className={classes.iconList}
                    key={links1[i].text}
                >
                    <NavLink
                        exact
                        className={classes.links}
                        onClick={() => { checkUnSaved(links1[i].href) }}
                        to={'#'}
                        activeClassName={window.location.pathname.includes(links1[i].href || 'none') ? "active-drawer-link" : ''}
                    ></NavLink>
                    <NavLink exact to={'#'} activeClassName={window.location.pathname.includes(links1[i].href || 'none') ? "active-drawer-link" : ''}>
                        <ListItemIcon className={classes.IconStyle}>
                            {links1[i].icon}
                        </ListItemIcon>
                    </NavLink>
                    <ListItemText primary={links1[i].text} />
                </ListItem>
            </Tooltip>
        );
    }
    const container = React.useRef(null);
    React.useEffect(() => {
        setTimeout(() => {
            const menu = lottie.loadAnimation({
                container: container.current,
                renderer: "svg",
                loop: false,
                autoplay: false,
                animationData: animationData,
            });
        }, 1000)

    }, []);

    const menuDropdown = () => {
        if (!open) {
            const hamburger = document.querySelector(".menu-drawer");
            hamburger.click();
        }
        setOpen1(!open1);
        setOpen2(false);
    };
    const menuDropdown1 = () => {
        if (!open) {
            const hamburger = document.querySelector(".menu-drawer");
            hamburger.click();
        }
        setOpen1(false);
        setOpen2(!open2);
    };
    const featuredHandle = async (e, id, linkType = 'estimates') => {
        const favKey = linkType === 'estimates' ? 'favoriteestimate' : 'favoritetemplates';
        e.stopPropagation();
        const user = users.find(d => d.caption === userEmail);
        if (user) {
            const userID = user["id"];
            let favoriteestimate = [];
            if (user[favKey]) {
                favoriteestimate = user[favKey];
            }
            if (favoriteestimate) {
                const found = favoriteestimate.find(d => d.id === id);
                if (found) {
                    const companyId = localStorage.getItem('companyId');
                    const userRef = fire.database().ref(companyId).child("users").child(userID);
                    const temp = {};
                    temp[`${favKey}`] = favoriteestimate.filter(d => d.id !== id);
                    userRef.update(temp);
                    loadData();
                    const { getUsersAsync } = getUsersAction;
                    await getUsersAsync({});
                }
            }
        }

    };
    const logout = () => {
        fire
            .auth()
            .signOut()
            .then(() => { })
            .catch((error) => {
                console.error(error);
            });
        const storedEmail = localStorage.getItem('storedEmail');
        localStorage.clear();
        localStorage.setItem('storedEmail', storedEmail);
        window.location.pathname = "/";
    };
    const [currentUser, setCurrentUser] = React.useState("");
    const [firstChar, setFirstChar] = React.useState("");
    const [estimates, setEstimates] = React.useState([]);
    const [templates, setTemplates] = React.useState([]);


    const [userEmail, setUserEmail] = React.useState("");
    const [loaded, setLoaded] = React.useState(false);

    const getQuery = () => {

        const companyId = localStorage.getItem('companyId') || null;

        const arr = props.location.pathname.split('/');
        if (arr.length < 4) {
            return { companyId }
        }
        const estimateKey = arr[arr.length - 1];
        const dataType = props.match.path.includes("estimates")
            ? "estimates"
            : "templates";
        const dataTypeFavorite = props.match.path.includes("estimates")
            ? "favoriteestimate"
            : "favoritetemplates";
        const isTimebased = props.match.path.includes("timebased");
        const isTemplate = dataType === "templates";

        return { dataType, estimateKey, companyId, dataTypeFavorite, isTimebased, isTemplate }
    }

    const { companyId, estimateKey } = getQuery();
    const refreshData = async () => {
        if (!companyId) {
            return;
        }

        const { getTagsAsync } = props.getTagsAction;
        const { getCompanyAsync } = props.getCompanyAction;
        const { getUsersAsync } = props.getUsersAction;
        const { getOrganizationsAsync } = props.getOrganizationsAction;
        const { getContactsAsync } = props.getContactsAction;
        const { getPhasesAsync } = props.getPhasesAction;
        const { getTasksAsync } = props.getTasksAction;

        getTagsAsync(getQuery());
        getCompanyAsync(getQuery());
        getUsersAsync(getQuery());
        getOrganizationsAsync(getQuery())
        getContactsAsync(getQuery());
        getPhasesAsync(getQuery());
        getTasksAsync(getQuery());
        setLoaded(true);

    }
    const loadData = async () => {
        if (!companyId) {
            return;
        }

        if (currentUser === '' && props.user) {
            const curUser = props.user;
            setUserEmail(curUser.email);
            const uid = curUser;
            const name = curUser.displayName;
            const email = curUser.email;
            let firstChar = "";
            if (name) {
                firstChar = name.charAt(0);
            } else {
                firstChar = email.charAt(0);
            }
            setFirstChar(firstChar);
            setCurrentUser(uid);

            const ref = fire.database().ref(companyId).child("estimates");
            const snapShotEstimates = await ref.once("value");
            const estimates = snapShotEstimates.val();
            ref.off("value");
            const estimateList = [];
            for (const id in estimates) {
                estimateList.unshift({ id, ...estimates[id], linkType: 'estimates' });
            }
            setEstimates(
                estimateList.filter((d) => isTrue(d.archive) === false && d.projectName)
            );

            const refTemp = fire.database().ref(companyId).child("templates");
            const snapTemplates = await refTemp.once("value");
            const templates = snapTemplates.val();
            ref.off("value");
            const templatesList = [];
            for (const id in templates) {
                templatesList.unshift({ id, ...templates[id] });
            }
            setTemplates(
                templatesList.filter((d) => !isTrue(d.archive) && d.projectName)
            );

            refreshData();
        }
    };
    useEffect(() => {
        loadData();
    }, [updateCnt]);

    useEffect(() => {
        if (props.user && props.users.length && loaded === false) {
            loadData();
        }
    }, [props.user, props.users]);

    const favTemplateList = []
    const favEstimatesList = [];
    let favEstimates = [];
    let favTemplates = [];
    //Get favorite estimates list for logged in user
    const user = users.find(d => d.caption === userEmail);

    if (user) {
        favEstimates = user["favoriteestimate"] || [];
        favTemplates = user["favoritetemplates"] || [];

        for (const temp of estimates) {
            const fav = favEstimates.find(d => d.id === temp.id);
            if (fav) {
                temp.linkType = 'estimates'
                favEstimatesList.push(temp);
            }
        }
        for (const temp of templates) {
            const fav = favTemplates.find(d => d.id === temp.id);
            if (fav) {
                temp.linkType = 'templates'
                favTemplateList.push(temp);
            }
        }

        favTemplateList.sort((a, b) => b.timestamp - a.timestamp);
        favEstimatesList.sort((a, b) => b.timestamp - a.timestamp);
    }

    const typeCast = false;
    const [showMsgSaved, setShowMsgSaved] = useState(null);
    const getUnSavedChanged = () => {
        const pages = Object.keys(changeRecord || {});
        let showChangeRecordWarning = null;
        for (const page of pages) {
            if (changeRecord[page] === true) {
                showChangeRecordWarning = 'you have a pending change at' + page;
            }
        }
        return showChangeRecordWarning;
    }
    const checkUnSaved = (nagivateUrl) => {
        const showChangeRecordWarning = getUnSavedChanged();
        let navigate = true;
        if (showChangeRecordWarning) {
            navigate = false;
        }
        if (navigate) {
            setTimeout(() => {
                history.push(
                    nagivateUrl
                );
            }, 100);
        } else {
            setShowMsgSaved(nagivateUrl);
        }
    };
    const itemHandler = (estimate) => {
        let { linkType } = estimate;
        if (estimate.typeOf === "TIME BASED ESTIMATE") {
            linkType = "estimates-timebased";
        }
        const nagivateUrl = `/${linkType}/${estimate.projectName}/${estimate.id}`;
        checkUnSaved(nagivateUrl)
    };
    const headerUpdate = (name) => {
        const email = currentUser.email;
        let firstChar = "";
        if (name) {
            firstChar = name.charAt(0);
        } else {
            firstChar = email.charAt(0);
        }
        setFirstChar(firstChar);
    };
    return (
        <React.Fragment>
            <AlertNavDialog
                open={showMsgSaved ? true : false}
                onCancel={() => { setShowMsgSaved(null); }}
                onDiscard={() => { history.push(showMsgSaved); }}
                message="You have unsaved changes."
                text="Your changes will be lost if you don't save them."
            />
            <Profile style={{ display: "none" }} headerUpdate={headerUpdate} />
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <div className={"logo-wrap " + classes.toolbar}>
                            <div className="logo">
                                <img src={estimatic_logo} alt="" />
                            </div>
                        </div>
                        <div className="link-wrap">
                            <BreadCrumb company={{ companyName: props.estimate.companyId }} projectName={props.estimate.projectName} key={estimateKey} shared={true} />
                        </div>
                    </Toolbar>
                </AppBar>

                <Overlay />
            </div>
        </React.Fragment>
    );
}

function MapStateToProps(state) {
    return {
        estimate: state.getEstimate.estimate,
        tags: state.getTags.tags,
        resources: state.getResources.resources,
        company: state.getCompany.company,
        users: state.getUsers.users,
        calculations: state.getCompany.calculations,
        organizations: state.getOrganizations.organizations,
        contacts: state.getContacts.contacts,
        user: state.getUser.user
    };
}
function MapDispatchToProps(dispatch) {
    return {
        getEstimateAction: bindActionCreators(
            getEstimateActionCreators,
            dispatch
        ),

        getTagsAction: bindActionCreators(
            getTagsActionCreators,
            dispatch
        ),
        getCompanyAction: bindActionCreators(
            getCompanyActionCreators,
            dispatch
        ),
        getResourcesAction: bindActionCreators(
            getResourcesActionCreators,
            dispatch
        ),
        getUsersAction: bindActionCreators(
            getUsersActionCreators,
            dispatch
        ),
        getOrganizationsAction: bindActionCreators(
            getOrganizationsActionCreators,
            dispatch
        ),
        getContactsAction: bindActionCreators(
            getContactsActionCreators,
            dispatch
        ),
        getPhasesAction: bindActionCreators(
            getPhasesActionCreators,
            dispatch
        ),
        getTasksAction: bindActionCreators(
            getTasksActionCreators,
            dispatch
        ),

    };
}
export default compose(
    withRouter,
    connect(MapStateToProps, MapDispatchToProps)
)(SharedHeader);
