
import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Box from "@material-ui/core/Box/Box";
import NoContactsImage from '../../assets/images/NoContactsImage.svg'
import Typography from "@material-ui/core/Typography/Typography";
import { DefaultButton } from "../small-components/UI/Buttons";

const content_type = "Organizations"
const Button_type = "Organization"

export default function NoContent(props) {
  //const classes = useStyles();
  console.log(props)

  const handleCreate = () => {
    if (props.toggle) {
      props.toggle(true, 'new')
      console.log(props.toggle)
    } else {
      props.createNew(true, 'new', props.Button_type);
    }
  }

  return (
    <React.Fragment>
      <Grid container style={{ maxWidth: 1080, margin: '0 auto 27px', paddingTop: !props.searching ? '50px' : '20px' }}>
        <Grid item xs={3}>
          <Box maxWidth={230} maxHeight={208} position="relative" style={{ margin: '0 auto' }}>
            <img src={`${NoContactsImage}`} alt="No Contacts Image" style={{ width: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={9}>
          {props.searching ?

            <box style={{ paddingLeft: '13px' }}>
              <Typography variant="h1" component="h1"
                style={{ fontSize: '40px', fontWeight: 400, lineHeight: 1.38, marginBottom: '10px', wordBreak: 'break-word' }}>
                No results found.
              </Typography>
              <Typography variant="p" component="p" style={{ fontWeight: 100, fontSize: '20px', lineHeight: 1.35, marginBottom: '31px', wordBreak: 'break-word' }}>
                Please try again.
              </Typography>

            </box>
            :
            <box style={{ paddingLeft: '13px' }}>
              <Typography variant="h1" component="h1"
                style={{ fontSize: '40px', fontWeight: 400, lineHeight: 1.38, marginBottom: '10px', wordBreak: 'break-word' }}>
                {props.title}
              </Typography>
              <Typography variant="p" component="p" style={{ fontWeight: 100, fontSize: '20px', lineHeight: 1.35, marginBottom: '31px', wordBreak: 'break-word' }}>
                {props.description}
              </Typography>

              <DefaultButton onClick={() => handleCreate()}>
                Create {props.Button_type ? props.Button_type : Button_type}
              </DefaultButton>

            </box>}
        </Grid>

      </Grid>
    </React.Fragment >
  );
}
