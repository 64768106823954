import NumberFormat from 'react-number-format';
export default function formatMoneyTimeBased({ number = 0, perTitle = '', money = true, maximumFractionDigits = 0, beFriendly = true, numberFormat = false, grandTotal = false }) {

    if (typeof price === 'string') {
        number = parseFloat(number)
    }

    let friendlySymbol = ''
    if (beFriendly) {
        // console.log('number 1', number);
        if (number > 1000000) {
            number = number / 1000000;
            friendlySymbol = 'M'
            maximumFractionDigits = 1;
        } else if (number > 100000) {
            number = number / 1000;
            friendlySymbol = 'K'
            maximumFractionDigits = 1;
        }
        // console.log('number 2', number);
    }
    if (grandTotal) {
        if (number > 100000000) {
            number = number / 1000000;
            friendlySymbol = 'M'
            maximumFractionDigits = 1;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', money ? {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits
    } : { maximumFractionDigits });
    const result = formatter.format(number) + friendlySymbol + perTitle;
    if (numberFormat) {
        return <NumberFormat
            className="cost"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator={true}
            placeholder="0"
            style={{
                textAlign: 'right', right: '0', top: '-4px', border: '0',
                fontWeight: '500', fontSize: '25px', position: 'relative'
            }}
            value={result}
            readOnly
        />
    }
    return result;
}