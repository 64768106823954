import React from 'react';
import mergeWith from 'lodash/mergeWith';
import isString from 'lodash/isString';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: 10,
    width: '100%'
  },
  outlinedInputBox: {
    padding: '0 10px !important',
    '&:hover $outlinedInputFieldset': {
      border: '1px solid rgba(8, 77, 79, 0.1) !important'
    }
  },
  outlinedInput: {
    margin: '0 !important',
    borderBottom: 'none !important',
    height: '40px !important',
    fontSize: '14px !important',
    // fontWeight: 400,
    fontWeight: 300,
    lineHeight: 1.36,
    letterSpacing: 'normal',
    color: '#000000',
    '&:focus': {
      borderBottom: 'none !important',
      boxShadow: 'none !important'
    }
  },
  outlinedInputFocused: {
    '&$outlinedInputFieldset': {
      border: '1px solid rgba(8, 77, 79, 0.1) !important',
      borderRadius: '5px'
    }
  },
  //Check
  outlinedInputFieldset: {
    border: '1px solid rgba(8, 77, 79, 0.1) !important'
  },
  inputLabel: {
    '&.MuiFormLabel-filled': {
      color: 'rgba(8, 77, 79, 0.5) !important'
    }
  },
  inputLabelFocused: {
    color: 'rgba(8, 77, 79, 0.5) !important'
  },
  inputAdornment: {
    marginRight: 0,
    '&>p': {
      color: '#000000'
    }
  }
}));

function InputField({ className, adornment, adornmentPosition, inputProps, inputLabelProps, ...props }) {
  const classes = useStyles();

  let InputProps = {
    classes: {
      root: classes.outlinedInputBox,
      input: classes.outlinedInput,
      notchedOutline: classes.outlinedInputFieldset,
      focused: classes.outlinedInputFocused,
    },
    inputProps: { min: 0 },
  };

  if (adornment) {
    if (adornmentPosition === 'start') {
      InputProps.startAdornment = (
        <InputAdornment
          classes={{ positionStart: classes.inputAdornment }}
          position={adornmentPosition}
        >
          {adornment}
        </InputAdornment>
      );
    }
    if (adornmentPosition === 'end') {
      InputProps.endAdornment = (
        <InputAdornment
          classes={{ positionEnd: classes.inputAdornment }}
          position={adornmentPosition}
        >
          {adornment}
        </InputAdornment>
      );
    }
    if (!adornmentPosition) {
      InputProps.endAdornment = (
        <InputAdornment classes={{ positionEnd: classes.inputAdornment }} position="end" >
          {adornment}
        </InputAdornment>
      );
    }
  }

  InputProps = mergeWith(
    InputProps,
    inputProps,
    function (objValue, srcValue) {
      if (isString(objValue)) {
        return `${objValue} ${srcValue}`;
      }
    }
  );

  let InputLabelProps = {
    classes: {
      root: classes.inputLabel,
      focused: classes.inputLabelFocused
    }
  };

  InputLabelProps = mergeWith(
    InputLabelProps,
    inputLabelProps,
    function (objValue, srcValue) {
      if (isString(objValue)) {
        return `${objValue} ${srcValue}`;
      }
    }
  );

  return (
    <TextField
      {...props}
      className={clsx(classes.textField, className)}
      variant="outlined"
      size="small"
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
    />
  );
}

export default InputField;
