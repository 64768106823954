import React from 'react';
import { Breadcrumbs as MuBreadCrumbs, Link } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
const BreadCrumb = (props) => {
  let { location: { pathname }, company = {}, projectName, shared } = props;
  const { companyName = '' } = company;
  const pathArr = props.location.pathname.split('/');
  const key = pathArr[pathArr.length - 1];

  const arr = (pathname || '').split('/');
  const toomuch = arr.length >= 5;

  if (toomuch) {
    const result = [];
    const temp = []
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (i <= 1) {
        result.push(item);
      } else if (i !== arr.length - 1) {
        temp.push(item);
      } else if (i === arr.length - 1) {
        result.push(temp.join('-'));
        result.push(item);
      }
    }
    if (projectName) {
      result[result.length - 1] = projectName;
    }
    pathname = result.join('/');
  }

  if (key && pathname) {
    const temp = pathname.split('/');
    if (temp.length > 3) {
      temp.pop();
    }
    if (projectName && shared) {
      console.log(projectName)
      temp[temp.length - 1] = projectName;
    }
    if (temp[2] && temp[2].length > 50) {
      temp[2] = temp[2].slice(0, 50) + '...';
    }
    pathname = temp.join('/');
  }
  if (shared) {
    pathname = pathname.split('/')[2]
    pathname = '/estimates/' + pathname
  }
  pathname = pathname.replace("-timebased", "");
  const pathnames = pathname.substring(0, 1) === '/' ? pathname.substring(1) : pathname;
  const new_pathnames = pathnames.replace(/-/g, ' / ').replace(/\//g, ' / ');
  if (pathnames !== "estimates" && $(".estimated-list").length) {
    $("#breadcrumb-separator").remove();
    $("#link-breadcrumb").remove();
  }
  return (
    <React.Fragment>
      {companyName !== "" ?
        <MuBreadCrumbs aria-label="breadcrumb" className="breadcrumb">
          <span>{companyName}</span>
          <Link href={pathname}>{new_pathnames ? new_pathnames : 'Dashboard'}</Link>
          )
        </MuBreadCrumbs> : null}
    </React.Fragment>

  )
}
export default withRouter(BreadCrumb);