// import HomePage from './pages/HomePage';
import SignUpPage from './pages/SignUpPage';
//import PageNotFound from '../components/pages/PageNotFound';
import LoginPage from "./pages/LoginPage";
import ResetPassword from "./pages/ResetPassword";
import SharedEstimateFor from "../components/reusable-components/Shared/SharedEstimateFor";
import ForgotPassword from "./pages/ForgotPassword";
import GanttContainer from "./timeline/ganttContainer/GanttContainer";

export default [
  {
    path: "/timeline",
    component: GanttContainer,
    exact: true,
  },
  {
    path: "/",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/signup",
    component: SignUpPage,
    exact: true,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
    exact: false,
  },

  {
    path: "*/",
    component: LoginPage,
    exact: true,
  }
];
