import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './login.scss';
import estimatic_logo from '../../../assets/images/Estimatic_Logo.svg';
import $ from "jquery";

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
    padding: '50px 0',
    alignItems: 'center',
    height: 'calc(100vh - 60px)',
    appearance: 'none',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  img_wrap: {
    maxWidth: '200px',
    width: '100%'
  },
  large_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderRadius: '5px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: '#fff',
    padding: '59px 50px !important'

  },
  text_wrap: {
    fontSize: '20px',
    fontWeight: "100",
    paddingTop: '14px',
    marginBottom: '60px',
    lineHeight: 1.36

  },
  small_block: {
    border: 'solid 1px rgba(8, 77, 79, 0.2)',
    borderLeft: '0',
    borderRadius: '5px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    backgroundColor: '#fff',
    padding: '55px 50px 30px !important',
    maxWidth: '430px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch'
  },
  btn_wrap: {
    background: '#e70a89!important',
    width: '100%',
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'inherit',
    boxShadow: 'none',
    minHeight: '40px',
    '&:hover': {
      boxShadow: 'none',
      background: '#d00a7b !important',
    }
  },
  wrap_item: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '12px'
  },

  bottom_item: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
  errorMsg: {
    '& .MuiAlert-filledError': {
      backgroundColor: '#db263b',
      fontWeight: 300
    }
  }
}));
export default function Login2FA({ submit, change, forget }) {

  const [token, setToken] = useState('');

  $(document).on('input change', '.phase-name input', function () {
    const value = $(this).val();
    const index = $(this).parents('.phase-parent').index();
    $('#scope .small-col .inner-content:eq(' + index + ') .phase-text span').text(value);
  });

  const classes = useStyles();
  return (
    <div autoComplete="off" className={'form-wrap ' + classes.root}>
      <Grid container spacing={3} justify="center" className="inner-wrap">
        <Grid item xs={5} className={"large-block " + classes.large_block}>
          <div className={classes.img_wrap}>
            <img src={estimatic_logo} alt="Estimatic" />
          </div>
          <p className={classes.text_wrap}>
            Please Check your email
          </p>
          <div className="img-wrap">
            {/* <img src={robo_form} alt="robot" /> */}
          </div>
        </Grid>
        <Grid item xs={4} className={classes.small_block}>
          <form className="form">
            <Typography variant="h5" component="h5" style={{ fontWeight: 300 }}>
              Enter 2FA Code
            </Typography>
            <TextField id="email" variant="outlined"
              type="text"
              label="CODE"
              name="token"
              onChange={(e) => {
                setToken(e.target.value);
                change(e);
              }}
              style={{ fontWeight: 300 }}
              value={token}
            />
            <Button
              variant="contained"
              type="button"
              className={classes.btn_wrap}
              onClick={(e) => submit(e, token)}>
              Sign in
            </Button>
            <Grid item xs className={'link-wrap ' + classes.wrap_item}>
              <p>
                <Link href="#" onClick={forget}>
                  Forgot Password
                </Link>
              </p>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}