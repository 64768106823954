import React, { useState, useEffect } from "react";
import "../../assets/scss/drawer_overlay.scss";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import fire from "../../config/firebase";
import { useHistory } from "react-router-dom";

export default function DeleteTemplate(props) {
  const [name, setName] = useState("var");
  const history = useHistory();
  const { dataKey } = props;
  const companyId = localStorage.getItem('companyId');
  const estimateRef = fire.database().ref(companyId).child("estimates");
  const deleteEstimate = () => {
    estimateRef.child(dataKey).remove();
    history.push("/estimates");
  };

  useEffect(() => {
    if (dataKey) {
      estimateRef
        .child(dataKey)
        .once("value")
        .then((snapshot) => {
          const estimates = snapshot.val();
          estimateRef.off("value");
          if (estimates !== null) {
            const project = estimates.projectName;
            setName(project);
          }
        });
    }
  }, [dataKey, estimateRef]);

  return (
    <div id="delete" className="side-item">
      <div className="title">
        <h5>
          Delete Estimate
          <div className="cross" onClick={props.onClose}>
            <CloseIcon htmlColor="#084d4f" />
          </div>
        </h5>
      </div>
      <div className="content">
        {/*<img src={discount} alt="discount" />*/}
        <p>Are you sure you want to delete the estimates ‘{name}’?</p>
      </div>
      <div className="input-wrap">
        <Button
          variant="contained"
          onClick={deleteEstimate}
          type="button"
          className="delete apply"
        >
          Yes, Delete
        </Button>
        <Button
          variant="outlined"
          type="button"
          className="no_delete"
          onClick={props.onClose}
        >
          No, don’t delete
        </Button>
      </div>
    </div>
  );
}
