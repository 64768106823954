import React from "react";
import { fade, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import star_icon from "../../assets/images/star.svg";
import star_pink_icon from "../../assets/images/star_pink.svg";
import gear_icon from "../../assets/images/gear.svg";
import gear_pink_icon from "../../assets/images/gear_pink.svg";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: "275px",
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
    "& .MuiListItemText-primary": {
      fontSize: "16px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "38px",
    },
    "& .title": {
      marginTop: "20px",
    },
  },
}))(MenuItem);

const StyleLinks = {
  position: "absolute",
  top: "1px",
  left: "60px",
}
export default class AdminNav extends React.Component {
  constructor(props) {
    super(props);
    this.authListner = this.authListner.bind(this);
    this.state = {
      user: null,
    };
  }
  componentDidMount() {

    this.authListner();
  }
  authListner() {

    const companyId = localStorage.getItem('companyId');
    if (!companyId) {
      return;
    }
    const { user, users } = this.props;
    if (users.length) {
      const userDb = users.find(d => d.caption === user.email);
      if (userDb) {
        this.setState({ user: userDb });
      } else {
        this.setState({ user: null });
      }
    }
  }
  render() {

    const { user } = this.state;
    let isAdmin = false;
    if (user) {
      if (user.subtitle === 'admin'
      ) {
        isAdmin = true;
      }
    }
    return (
      <div>
        {isAdmin && (
          <React.Fragment>
            <div
              className="title-wrap"
              style={{ backgroundColor: "transparent", cursor: "default" }}
            >
              <ListItemText className="title" primary="ACCOUNT" />
            </div>
            <StyledMenuItem className="list-item">
              <a
                className="nav-links"
                href="/user-management"
                style={{ width: 275 }}
              >
                <ListItemIcon>
                  <PersonAddOutlinedIcon htmlcolor="#000000" />
                </ListItemIcon>
                <ListItemText
                  style={StyleLinks}
                  primary="User Management"
                />
              </a>
            </StyledMenuItem>
            <StyledMenuItem className="list-item">
              <a className="nav-links" href="/company-settings" style={{ width: 275 }}>
                <ListItemIcon className={"custom-icon"}>
                  <img src={gear_icon} alt="gear" />
                  <img src={gear_pink_icon} alt="gear" />
                </ListItemIcon>
                <ListItemText primary="Company Settings" style={StyleLinks} />
              </a>
            </StyledMenuItem>
            <StyledMenuItem className="list-item">
              <a className="nav-links" href="/billing" style={{ width: 275 }}>
                <ListItemIcon>
                  <MonetizationOnOutlinedIcon htmlcolor="#000000" />
                </ListItemIcon>
                <ListItemText primary="Billing" htmlcolor="#000000" style={StyleLinks} />
              </a>
            </StyledMenuItem>
            <StyledMenuItem className="list-item">
              <a className="nav-links" href="/upgrade-plan" style={{ width: 275 }}>
                {" "}
                <ListItemIcon className={"custom-icon"}>
                  <img src={star_icon} alt="star" />
                  <img src={star_pink_icon} alt="star_pink" />
                </ListItemIcon>

                <ListItemText primary="Upgrade Plan" style={StyleLinks} />
              </a>
            </StyledMenuItem>
            <Divider />
          </React.Fragment>
        )}
      </div>
    );
  }
}
