import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import "./DrawerOverlay.scss";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import PhaseCalculation from "../Phase/phaseCalculationUtil";
import ResourceEdit from "../resource/ResourceEdit";

const useStyles = makeStyles(() => ({
  gutters: {
    padding: "10px 10px 10px 30px",
  },
  toolbarTitle: {
    fontSize: 20,
    lineHeight: 1.25,
    fontWeight: 300,
  },
}));

function NewDrawer(props) {
  const classes = useStyles();

  const {
    resource = {},
    estimate = {},
    updateResourceFn,
    refreshData,
    onClose,
  } = props;
  const [cost, setCost] = useState(0);
  const [temp, setTemp] = useState(null);

  useEffect(() => {
    const phaseCalculationCls = new PhaseCalculation({}, {}, estimate);
    const costVal = phaseCalculationCls.resourceCalculate(
      resource,
      "cost",
      false
    );
    setCost(costVal);
  }, [estimate]);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={props.open}
        //onClose={props.close}
        className={props.clsName + ' new_drawer_item'}
        PaperProps={{
          style: {
            minWidth: 400,
            maxWidth: 400,
          },
        }}
      >
        <Toolbar classes={{ gutters: classes.gutters }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12}>
              <h5>
                Update Vendor Cost
                <div className="cross" onClick={props.onClose}>
                  <CloseIcon htmlColor="#084d4f" />
                </div>
              </h5>
            </Grid>

          </Grid>
        </Toolbar>
        <Divider />

        <Box px={4}>
          <div id="duplicate" className="side-item">
            <div className="content">
              <p>You are updating this Vendor's cost ...</p>
            </div>

            <div className="input-wrap">
              <div className="d_input">
                <ResourceEdit
                  editable={resource.type === "Vendor"}
                  resource={resource}
                  updateInstantly={true}
                  updateResource={(updatedData, resource, fieldKey, input) => {
                    setCost(input);
                    setTemp({ hours: updatedData.hours });
                  }
                  }
                  value={cost}
                  max={100000}
                  refresh={() => {
                    // setUpdateCnt(updateCnt + 1);
                  }}
                  field="cost"
                  fieldKey={resource.resourceId + "cost"}
                  isFocused={true}
                  vendorDrawer={true}
                />
              </div>
              <Button
                onClick={() => {
                  if (temp) {
                    updateResourceFn(temp, resource);
                    refreshData();
                  }
                  onClose();
                }}
                variant="contained"
                type="button"
                className="duplicate apply"
              >
                Update
              </Button>
            </div>
          </div>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

export default withRouter(NewDrawer);
