import React, { useState } from 'react';
import Calendar from "react-calendar";
import { Grid, TextField } from "@material-ui/core";
import "react-calendar/dist/Calendar.css";
import "./calender-drawer.scss";
import Moment from 'moment';
export default function CalenderDrawer(props) {
  const [value, setValue] = useState(new Date());
  const [startDate, setStartDate] = useState(Moment(new Date()).format('dddd, MMMM D YYYY'));
  const [endDate, setEndDate] = useState("");

  function change(value) {
    setValue(value);
    props.datechange(value);
    setStartDate(Moment(value[0]).format('dddd, MMMM D YYYY'));
    setEndDate(Moment(value[1]).format('dddd, MMMM D YYYY'));
  }
  return (
    <div className={'calender-outer'}>
      <Calendar
        onChange={change}
        value={value}
        //selectRange={props.type == "days" ? false : true}
        selectRange={true}
        calendarType={"US"}
      />
      <Grid item xs={12} className="content-outer">
        <p>
          START
        </p>
        <TextField className={`${startDate ? "calender-date" : ""}`} id="filled-basic" label="Filled" variant="filled" value={startDate} disabled={true} />
      </Grid>
      <Grid item xs={12} className="content-outer">
        <p>
          END
        </p>
        <TextField className={`${endDate ? "calender-date" : ""}`} id="filled-basic" label="Filled" variant="filled" value={endDate} disabled={true} />
      </Grid>
    </div>
  );
}