import React from "react";
import Button from "@material-ui/core/ButtonBase";
import IconButton from "@material-ui/core/IconButton";
import "./search.scss";
import icon1 from "../../../assets/images/estimates.svg";
import icon2 from "../../../assets/images/templates.svg";
import icon3 from "../../../assets/images/contacts.svg";
import iconhover1 from "../../../assets/images/Estimates-color.svg";
import iconhover2 from "../../../assets/images/Templates-color.svg";
import iconhover3 from "../../../assets/images/contacts-color.svg";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import NoResult from "../../../assets/images/search/new-no-search.svg";
import { connect } from "react-redux";
import { createProject } from "../../../store/actions/projectAction";
import fire from "../../../config/firebase";
import Highlighter from "react-highlight-words";
import latinize from "latinize";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  panel: {
    marginTop: theme.spacing(1.3),
  },
  subtitle: {
    flex: "0 0 100%",
    maxWidth: "100%",
    marginBottom: 10,
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.23,
    letterSpacing: 0.6,
    textTransform: "uppercase",
    color: "#084d4f",
  },
  title: {
    flex: "0 0 100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 28,
    height: 28,
    marginRight: 10,
    fill: "#000000",
  },
  caption: {
    flex: "0 0 100%",
    maxWidth: "100%",
    marginTop: 5,
    fontSize: "12px",
    lineHeight: 1.23,
  },
  archivetitle: {
    display: "inline-block",
    verticalAlign: "top",
    fontSize: "12px",
    color: "#000000",
    backgroundColor: "rgba(8,77,79,0.10)",
    padding: "5px 11px",
    borderRadius: "13px",
    marginLeft: "10px",
    textTransform: "capitalize",
  },
  boxtitle: {
    display: "inline-block",
    verticalAlign: "top",
  },
  menuItem: {
    padding: "8px 14px !important",
    "&:hover .MuiListItemIcon-root svg path": {
      fill: "#000000",
    },
  },
  listItemWrap: {
    minWidth: 36,
  },
  listIcon: {
    width: 27,
    height: 27,
  },
});
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: false,
      searchType: false,
      searchValue: "",
      estimateList: [],
      templateList: [],
      peopleList: [],
      organizationList: [],
      contactList: [],

      userEmail: "",

      open: false,
    };
    this.inputRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.wrapperRef1 = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setIsHover = (value) => {
    this.setState({
      open: value,
    });
  };

  searchKey = (e) => {
    if (e.target.value) {
      this.setState({
        searchType: true,
        searchValue: e.target.value,
        open: false,
      });
    } else {
      this.setState({
        searchType: false,
        searchValue: e.target.value,
      });
    }
  };

  searchtabs = (no, ind) => {
    const item1 = document.querySelectorAll(".search .tabs > li");
    item1.forEach((i, index) => {
      if (index === ind) {
        i.classList.add("active");
      } else {
        i.classList.remove("active");
      }
    });
    const item = document.querySelectorAll(".tab-outer>div");
    item.forEach((i) => {
      i.style.display = "none";
    });
    document.getElementById(no).style.display = "block";
  };
  searchclose = () => {
    document.querySelector("header .search").style.display = "";
    document.querySelector(".searchIcon").style.display = "block";
    document.getElementById("navbarsearch").style.width = "0px";
    document.getElementById("navbarsearch").style.height = "0px";
    document.querySelector(
      "#navbarsearch #expandtrigger .close-icon"
    ).style.opacity = "0";
    document.querySelector(
      "#navbarsearch #expandtrigger .close-icon"
    ).style.visibility = "hidden";
    document.querySelector(
      "#navbarsearch #expandtrigger .clear-btn"
    ).style.display = "none";
  };
  clearInput = () => {
    this.setState({
      searchType: false,
      searchValue: "",
    });
    document.getElementById("searchnavbar").value = "";
  };

  // componentDidMount() {
  //   document.addEventListener("click", this.handleClickOutside);
  //   this.inputRef.current.focus();
  //   $("#searchnavbar").on("keyup", function () {
  //     const value = $(this).val().toUpperCase();
  //     if (value.length < 1) {
  //       return false;
  //     }
  //     $(".search tr")
  //       .not(".result")
  //       .each(function () {
  //         // const value1 = $(this).find('span').text();
  //         $(this)
  //           .find("span:contains(" + value + ")")
  //           .html(function (_, html) {
  //             const regex = new RegExp(value, "g");
  //             return html.replace(
  //               regex,
  //               '<strong class="smallcaps">' + value + "</strong>"
  //             );
  //           });
  //       });
  //   });
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("click", this.handleClickOutside);
  // }

  handleClickOutside(event) {
    const el = document.querySelector("header");
    if (el.classList.contains("nav-active")) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        // document.querySelector("header .search").style.display = "";
      }
    } else {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        document.querySelector("header").classList.remove("nav-active");
        document.querySelector("header .search").style.display = "";
        document.querySelector(".searchIcon").style.display = "block";
        document.getElementById("navbarsearch").style.width = "0px";
        document.getElementById("navbarsearch").style.height = "0px";
        document.querySelector(
          "#navbarsearch #expandtrigger .close-icon"
        ).style.opacity = "0";
        document.querySelector(
          "#navbarsearch #expandtrigger .close-icon"
        ).style.visibility = "hidden";
        document.querySelector(
          "#navbarsearch #expandtrigger .clear-btn"
        ).style.display = "none";
      }
    }
  }

  SearchToggle = (e) => {
    e.preventDefault();
    document.querySelector("header").classList.add("nav-active");
    document.querySelector(".searchIcon").style.display = "none";
    document.getElementById("navbarsearch").style.width = "560px";
    document.getElementById("navbarsearch").style.height = "45px";
    document.querySelector("#navbarsearch input").focus();
    document.querySelector("header .search").style.display = "block";
    document.querySelector(
      "#navbarsearch #expandtrigger .close-icon"
    ).style.opacity = "1";
    document.querySelector(
      "#navbarsearch #expandtrigger .close-icon"
    ).style.visibility = "visible";
    document.querySelector(
      "#navbarsearch #expandtrigger .clear-btn"
    ).style.display = "block";
  };
  searchSubmit = (e) => {
    e.preventDefault();
  };
  handleClose = (e) => {
    e.preventDefault();
  };

  componentDidMount = () => {
    const { user } = this.props;

    if (user) {
      const companyId = localStorage.getItem('companyId');
      if (!companyId) {
        return;
      }
      const refEstimate = fire.database().ref(companyId).child("estimates");
      const refTemplate = fire.database().ref(companyId).child("templates");

      //Estimates
      refEstimate.once("value").then(
        (snapshot) => {
          const estimates = snapshot.val();
          refEstimate.off("value");
          const estimateList = [];
          for (const id in estimates) {
            if (estimates[id].tags === undefined) {
              estimates[id].tags = [];
            }
            estimateList.push({
              id,
              ...estimates[id],
              database: `estimates${estimates[id].filterType === 'time-based' ? '-timebased' : ''}`
            });

          }
          this.setState({ estimateList: estimateList });
        },
        (error) => {
          console.log("Error: " + error.code);
        }
      );
      // People
      const refPeople = fire.database().ref(companyId).child("people");
      refPeople.once("value").then(
        (snapshot) => {
          const people = snapshot.val();
          refPeople.off("value");
          const peopleList = [];
          for (const id in people) {
            if (people[id].tags === undefined) {
              people[id].tags = [];
            }
            peopleList.push({ id, ...people[id], database: "people" });
          }
          this.setState({ peopleList: peopleList });
        },
        (error) => {
          console.log("Error: " + error.code);
        }
      );
      //organization
      const organizationListRef = fire.database().ref(companyId).child("organizations");
      organizationListRef.once("value").then((snapshot) => {
        const listOrg = snapshot.val();
        organizationListRef.off("value");
        const arrOrg = [];
        for (const id in listOrg) {
          arrOrg.push({ id, ...listOrg[id], database: "organizations" });
        }
        this.setState({ organizationList: arrOrg });
      });

      refTemplate.once("value").then(
        (snapshot) => {
          const temaplets = snapshot.val();
          refTemplate.off("value");
          const temapletsList = [];
          for (const id in temaplets) {
            temapletsList.push({
              id,
              ...temaplets[id],
              database: `templates${temaplets[id].filterType === 'time-based' ? '-timebased' : ''}`,
            });

          }

          this.setState({ templateList: temapletsList });
        },
        (error) => {
          console.log("Error: " + error.code);
        }
      );

      this.setState({

        userEmail: user.email,

      });
    }

  };
  recentHandler = (title, id, database) => {

    const { users = [] } = this.props;
    const user = users.find(d => d.caption === this.state.userEmail);
    if (!user) {
      return;
    }
    const userID = user["id"];
    let recentSearch = [];
    const recentsearch = user["recentsearch"] || []
    const recentExist = !!recentsearch.find(d => d.id === id);
    if (recentExist === false) {
      recentSearch = [{ id }, ...recentsearch]
      //recentsearch.unshift({ id });
      const companyId = localStorage.getItem('companyId');
      const userRef = fire.database().ref(companyId).child("users").child(userID);
      userRef.update({
        recentsearch: recentSearch,
      });
    }

    localStorage.setItem("searchText", title);
    localStorage.setItem("searchItemID", id);
    localStorage.setItem("searchItemTable", database);

    if (database === "people" || database === "organizations") {
      this.props.history.push(`/contacts`);
    } else {
      this.props.history.push(`/${database}/${title}/${id}`);
    }

  };

  render() {
    const { users = [] } = this.props;
    const getTcon = (item) => {
      if (item.database.includes('estimate')) {
        return <img src={icon1} alt="icon"></img>
      }
      if (item.database.includes('template')) {
        return <img src={icon2} alt="icon"></img>
      }

    }

    const getHoverTcon = (item) => {
      if (item.database.includes('estimate')) {
        return <img src={iconhover1} alt="icon"></img>
      }
      if (item.database.includes('template')) {
        return <img src={iconhover2} alt="icon"></img>
      }

    }

    const allItems = [
      ...this.state.organizationList,
      ...this.state.peopleList,
      ...this.state.estimateList,
      ...this.state.templateList,
    ];
    const contactList = [
      ...this.state.peopleList,
      ...this.state.organizationList,
    ];
    const estimateList = [...this.state.estimateList];
    const templateList = [...this.state.templateList];
    const orgList = [...this.state.organizationList];
    let resultAll = [];
    let resultContact = [];
    let resultEstimate = [];
    let resultTemplate = [];
    const recentHandler = this.recentHandler;
    const searchText = this.state.searchValue;
    const { activeIndex, caseSensitive } = this.state;
    const searchWords = searchText.split(/\s/).filter((word) => word);
    if (this.state.searchValue !== "") {
      const key = this.state.searchValue.toLowerCase();
      const search = (item) => {
        let found = false;
        if (item.title) {
          found = item.title.toLowerCase().match(key);
        }
        if (found === false && item.projectName) {
          found = item.projectName.toLowerCase().match(key) !== null;
        }
        if (found === false && item.tags && item.tags.length) {
          const res = item.tags.find(d => d.title && d.title.toLowerCase().match(key) !== null);
          found = !!res;
        }
        return found;
      };

      resultAll = allItems.filter(search);
      resultContact = contactList.filter(search);
      resultEstimate = estimateList.filter(search);
      resultTemplate = templateList.filter(search);

    }
    let existuser = 0;
    for (let i = 0; i < users.length; i++) {
      if (users[i]["caption"] !== this.state.userEmail) {
        existuser = existuser + 1;
      }
    }

    let recentsearch = [];

    for (let i = 0; i < users.length; i++) {
      if (users[i]["caption"] === this.state.userEmail) {
        recentsearch = users[i]["recentsearch"];
      }
    }
    return (
      <React.Fragment>
        <div ref={this.wrapperRef}>
          <a className="searchIcon" onClick={this.SearchToggle}>
            <SearchIcon htmlColor="#ffffff" />
          </a>
          <form
            onSubmit={(e) => this.searchSubmit(e)}
            id="navbarsearch"
            style={{ width: "0px", height: "0px" }}
          >
            <div id="expandtrigger" className="input-field">
              <Tooltip
                title="Type to Search..."
                placement="bottom"
                open={this.state.open}
              >
                <input
                  id="searchnavbar"
                  type="search"
                  className="search-input"
                  autoComplete="off"
                  placeholder="Search"
                  onMouseOver={() => this.setIsHover(true)}
                  onMouseLeave={() => this.setIsHover(false)}
                  onChange={this.searchKey}
                  ref={this.inputRef}
                  style={{
                    padding: "0 120px 1px 58px",
                    width: "100%",
                    borderRadius: "23px",
                    autofocus: "autofocus",
                    background: "#333333",
                    overflow: "hidden",
                    display: "inline-block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                ></input>
              </Tooltip>
              <label className="label-icon" htmlFor="searchnavbar">
                <i>
                  <SearchIcon />
                </i>
              </label>
              <a href="#" className="clear-btn" onClick={this.clearInput}>
                Clear
              </a>
              <i
                className="material-icons close-icon"
                onClick={this.searchclose}
              >
                <CloseIcon />
              </i>
            </div>
          </form>
          <section className="search">
            <div className="container">
              <div className="row card">
                {!this.state.searchType ? (
                  <div
                    className="recent-searches"
                    style={{ width: "100%", marginTop: "10px" }}
                  >
                    <h6>Recent Searches</h6>
                    <div className="result-wrap">
                      <table className="bordered serach-table">
                        <tbody>
                          {recentsearch &&
                            recentsearch.slice(0, 15).map((item, index) => {
                              for (let i = 0; i < allItems.length; i++) {
                                if (allItems[i]["id"] === item.id) {
                                  if (allItems[i]["projectName"]) {
                                    return (
                                      <tr className="row" key={index + item.id}>
                                        <td
                                          className="table-heading"
                                          onClick={() =>
                                            recentHandler(
                                              allItems[i]["projectName"],
                                              allItems[i]["id"],
                                              allItems[i]["database"],
                                            )
                                          }
                                        >
                                          <div className="img-wrap">
                                            <img src={icon1} alt="icon"></img>
                                            <img
                                              src={iconhover1}
                                              alt="icon"
                                            ></img>
                                          </div>
                                          <span>
                                            {" "}
                                            {allItems[i]["projectName"]}
                                          </span>
                                        </td>
                                        <td className="last-modfied-date">
                                          <p>Last Modified</p>
                                          <p>
                                            {item.modified
                                              ? item.modified
                                              : "5:30pm November 23, 2020"}
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  } else if (allItems[i]["title"]) {
                                    return (
                                      <tr className="row" key={i}>
                                        <td
                                          className="table-heading"
                                          onClick={() =>
                                            recentHandler(
                                              allItems[i]["title"],
                                              allItems[i]["id"],
                                              allItems[i]["database"]
                                            )
                                          }
                                        >
                                          <div className="img-wrap">
                                            <img src={icon3} alt="icon" />
                                            <img src={iconhover3} alt="icon" />
                                          </div>
                                          <span>
                                            {allItems[i]["title"]}
                                            {allItems[i]["contactType"] ===
                                              "People" &&
                                              orgList.map((orgitem, index) => {
                                                if (
                                                  allItems[i][
                                                  "organization"
                                                  ] === orgitem.id
                                                )
                                                  return (
                                                    <p className="type search-type" key={index}>
                                                      {orgitem.title}
                                                    </p>
                                                  );
                                              })}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                }
                              }
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="col s12 icon-fields">
                      <ul className="tabs">
                        <li className="tab col s3 active">
                          <Button
                            className="searchAll"
                            onClick={() => this.searchtabs("all", 0)}
                          >
                            <p>All</p>
                          </Button>
                        </li>

                        <Tooltip
                          title="Estimates"
                          interactive
                          placement="bottom"
                        >
                          <li
                            className={`tab col s3 ${resultEstimate.length < 1 ? "deactivate" : ""
                              }`}
                          >
                            <Button className="tab-touch-wrap">
                              <IconButton
                                className="searchTabs"
                                onClick={() => this.searchtabs("estimates", 1)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="23"
                                  viewBox="0 0 20 23"
                                >
                                  <defs>
                                    <clipPath id="vzcsv22sga">
                                      <path
                                        fill="none"
                                        d="M0 0H20V23H0z"
                                      ></path>
                                    </clipPath>
                                  </defs>
                                  <g clip-path="url(#vzcsv22sga)">
                                    <path
                                      d="M16.572 0H3.088A3.017 3.017 0 0 0 0 2.934V19.1a3.017 3.017 0 0 0 3.088 2.934h13.484A3.017 3.017 0 0 0 19.66 19.1V2.934A3.017 3.017 0 0 0 16.572 0M17.6 19.1a1.006 1.006 0 0 1-1.029.978H3.088a1.006 1.006 0 0 1-1.029-.978V2.934a1.006 1.006 0 0 1 1.029-.978h13.484a1.006 1.006 0 0 1 1.029.978zM4.117 9.681h11.426v-5.77H4.117zm2.059-3.814h7.308v1.858H6.176zm-1.54 10.2h6.275v1.956H4.636zm2.055-2.476H4.632v-1.954h2.059zm2.162-1.956h2.059v1.956h-2.06zm4.117 0h2.059v1.956H12.97zm0 4.432h2.059v1.956H12.97z"
                                      transform="translate(0 .3)"
                                    ></path>
                                  </g>
                                </svg>
                              </IconButton>
                            </Button>
                          </li>
                        </Tooltip>

                        <Tooltip
                          title="Templates"
                          interactive
                          placement="bottom"
                        >
                          <li
                            className={`tab col s3 ${resultTemplate.length < 1 ? "deactivate" : ""
                              }`}
                          >
                            <Button className="tab-touch-wrap">
                              <IconButton
                                className="searchTabs "
                                onClick={() => this.searchtabs("templates", 2)}
                                aria-label="templates"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="21.522"
                                  viewBox="0 0 20 21.522"
                                >
                                  <g>
                                    <g>
                                      <path
                                        fillRule="evenodd"
                                        d="M19.182 1H6.455a1.9 1.9 0 0 0-1.819 1.957v13.7a1.9 1.9 0 0 0 1.818 1.957h12.728A1.9 1.9 0 0 0 21 16.653V2.957A1.9 1.9 0 0 0 19.182 1zM2.818 4.913H1v15.653a1.9 1.9 0 0 0 1.818 1.957h14.546v-1.957H2.818zm3.637 11.74h12.727V2.957H6.455z"
                                        transform="translate(1590 -291) translate(-1590 291) translate(-1 -1)"
                                      />
                                    </g>
                                    <g>
                                      <path
                                        d="M45.172 14.781h-1.4v-1.4a.375.375 0 0 0-.375-.375h-.9a.375.375 0 0 0-.374.375v1.4h-1.4a.375.375 0 0 0-.374.375v.9a.375.375 0 0 0 .374.374h1.4v1.4a.374.374 0 0 0 .374.374h.9a.375.375 0 0 0 .375-.374v-1.4h1.4a.374.374 0 0 0 .375-.374v-.9a.375.375 0 0 0-.375-.375"
                                        transform="translate(1590 -291) translate(-1580.715 295.159) translate(-40.356 -13.01)"
                                      />
                                      <path
                                        d="M44.606 40.814H46a.375.375 0 0 0 .375-.374v-.9a.375.375 0 0 0-.375-.374h-3.63a.374.374 0 0 0-.375.374v.9a.374.374 0 0 0 .375.374h2.237z"
                                        transform="translate(1590 -291) translate(-1580.715 295.159) translate(-41.591 -31.72)"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </IconButton>
                            </Button>
                          </li>
                        </Tooltip>

                        <Tooltip
                          title="Contacts"
                          interactive
                          placement="bottom"
                        >
                          <li
                            className={`tab col s3 ${resultContact.length < 1 ? "deactivate" : ""
                              }`}
                          >
                            <Button className="tab-touch-wrap">
                              <IconButton
                                className="searchTabs"
                                onClick={() => this.searchtabs("contacts", 3)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20.5"
                                  height="22.5"
                                  viewBox="0 0 20.5 22.5"
                                >
                                  <g>
                                    <path
                                      fillRule="evenodd"
                                      stroke="#fff"
                                      strokeWidth="0.5px"
                                      d="M22.622 5.168a2.425 2.425 0 0 1 .2.374A2.2 2.2 0 0 1 23 6.4v15.4a2.242 2.242 0 0 1-.378 1.232 2.192 2.192 0 0 1-.6.594 2.465 2.465 0 0 1-.378.2 2.256 2.256 0 0 1-.867.176H5.222A2.211 2.211 0 0 1 3 21.8V6.4a2.2 2.2 0 0 1 .178-.858 2.423 2.423 0 0 1 .2-.374 2.193 2.193 0 0 1 .6-.594A2.269 2.269 0 0 1 5.222 4.2h1.111V2h2.223v2.2h8.889V2h2.222v2.2h1.111a2.256 2.256 0 0 1 .867.176 2.466 2.466 0 0 1 .378.2 2.192 2.192 0 0 1 .599.592zM5.222 6.4v15.4h15.556V6.4zM13 15.068c-2.256 0-6.667 1.188-6.667 3.938V20.7h13.334v-1.683c0-2.761-4.411-3.949-6.667-3.949zm0 2.2A7.845 7.845 0 0 0 8.9 18.5h8.2a7.813 7.813 0 0 0-4.1-1.232zm0-3.168a3.3 3.3 0 1 0-3.333-3.3A3.327 3.327 0 0 0 13 14.1zm1.111-3.3A1.111 1.111 0 1 0 13 11.9a1.109 1.109 0 0 0 1.111-1.1z"
                                      transform="translate(.25 .25) translate(-3 -2)"
                                    />
                                  </g>
                                </svg>
                              </IconButton>
                            </Button>
                          </li>
                        </Tooltip>
                      </ul>
                    </div>
                    <div className="tab-outer">
                      <div id="all" className="col s12 icon-category">
                        {resultAll.length < 1 ? (
                          <div className="no-search">
                            <div className="row">
                              <div className="col s12">
                                <h6>
                                  We couldn’t find any results for “
                                  {this.state.searchValue}”. Please try another
                                  search.
                                </h6>
                                <div className="image">
                                  <img src={NoResult} alt="no result" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <table className="bordered serach-table">
                              <tbody>
                                <tr className="row result">
                                  <td>
                                    <span>{resultAll.length} results</span>
                                  </td>
                                </tr>
                                {resultAll.map(function (item) {
                                  return (
                                    <>
                                      <tr className="row">
                                        <td
                                          className="table-heading"
                                          onClick={() =>
                                            recentHandler(
                                              item.projectName
                                                ? item.projectName
                                                : item.title,
                                              item.id,
                                              item.database
                                            )
                                          }
                                        >
                                          <div className="img-wrap">
                                            {item.projectName ? (
                                              <React.Fragment>
                                                {getTcon(item)}
                                                {getHoverTcon(item)}
                                              </React.Fragment>
                                            ) : (
                                              <React.Fragment>
                                                <img src={icon3} alt="icon"></img>
                                                <img
                                                  src={iconhover3}
                                                  alt="icon"
                                                ></img>
                                              </React.Fragment>
                                            )}
                                          </div>
                                          <span>
                                            {" "}
                                            <Highlighter
                                              activeClassName={styles.Active}
                                              activeIndex={activeIndex}
                                              caseSensitive={caseSensitive}
                                              highlightClassName={
                                                styles.Highlight
                                              }
                                              highlightStyle={{
                                                fontWeight: "900",
                                                backgroundColor: "transparent",
                                              }}
                                              sanitize={latinize}
                                              searchWords={searchWords}
                                              textToHighlight={
                                                item.projectName
                                                  ? item.projectName
                                                  : item.title
                                              }
                                            />
                                          </span>
                                          {/* {item.title&&<p className="type">{orgitem.title}</p>} */}
                                        </td>
                                        {item.projectName && (
                                          <td className="last-modfied-date">
                                            <p>Last Modified</p>
                                            <p>
                                              {item.modified
                                                ? item.modified
                                                : "5:30pm November 23, 2020"}
                                            </p>
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      <div id="estimates" className="col s12 icon-category">
                        {resultEstimate.length < 1 ? (
                          <div className="no-search">
                            <div className="row">
                              <div className="col s12">
                                <h6>
                                  We couldn’t find any results for “
                                  {this.state.searchValue}”. Please try another
                                  search.
                                </h6>
                                <div className="image">
                                  <img src={NoResult} alt="no result" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <table className="bordered serach-table">
                              <tbody>
                                <tr className="row result">
                                  <td>
                                    <span>{resultEstimate.length} results</span>
                                  </td>
                                </tr>
                                {resultEstimate.map((item) => {
                                  return (
                                    <tr className="row">
                                      <td
                                        className="table-heading"
                                        onClick={() =>
                                          recentHandler(
                                            item.projectName,
                                            item.id,
                                            item.database
                                          )
                                        }
                                      >
                                        <div className="img-wrap">
                                          <img src={icon1} alt="icon"></img>
                                          <img
                                            src={iconhover1}
                                            alt="icon"
                                          ></img>
                                        </div>
                                        <span>
                                          {" "}
                                          <Highlighter
                                            activeClassName={styles.Active}
                                            activeIndex={activeIndex}
                                            caseSensitive={caseSensitive}
                                            highlightClassName={
                                              styles.Highlight
                                            }
                                            highlightStyle={{
                                              fontWeight: "900",
                                              backgroundColor: "transparent",
                                            }}
                                            sanitize={latinize}
                                            searchWords={searchWords}
                                            textToHighlight={item.projectName}
                                          />
                                        </span>
                                      </td>
                                      <td className="last-modfied-date">
                                        <p>Last Modified</p>
                                        <p>
                                          {item.modified
                                            ? item.modified
                                            : "5:30pm November 23, 2020"}
                                        </p>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      <div id="templates" className="col s12 icon-category">
                        {resultTemplate.length < 1 ? (
                          <div className="no-search">
                            <div className="row">
                              <div className="col s12">
                                <h6>
                                  We couldn’t find any results for “
                                  {this.state.searchValue}”. Please try another
                                  search.
                                </h6>
                                <div className="image">
                                  <img src={NoResult} alt="no result" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <table className="bordered serach-table">
                              <tbody>
                                <tr className="row result">
                                  <td>
                                    <span>{resultTemplate.length} results</span>
                                  </td>
                                </tr>
                                {resultTemplate.map((item) => {
                                  return (
                                    <tr className="row">
                                      <td
                                        className="table-heading"
                                        onClick={() =>
                                          recentHandler(
                                            item.projectName,
                                            item.id,
                                            item.database
                                          )
                                        }
                                      >
                                        <div className="img-wrap">
                                          <img src={icon2} alt="icon"></img>
                                          <img
                                            src={iconhover2}
                                            alt="icon"
                                          ></img>
                                        </div>

                                        <span>
                                          {" "}
                                          <Highlighter
                                            activeClassName={styles.Active}
                                            activeIndex={activeIndex}
                                            caseSensitive={caseSensitive}
                                            highlightClassName={
                                              styles.Highlight
                                            }
                                            highlightStyle={{
                                              fontWeight: "900",
                                              backgroundColor: "transparent",
                                            }}
                                            sanitize={latinize}
                                            searchWords={searchWords}
                                            textToHighlight={item.projectName}
                                          />
                                        </span>
                                      </td>
                                      <td className="last-modfied-date">
                                        <p>Last Modified</p>
                                        <p>
                                          {item.modified
                                            ? item.modified
                                            : "5:30pm November 23, 2020"}
                                        </p>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      <div id="contacts" className="col s12 icon-category">
                        {resultContact.length < 1 ? (
                          <div className="no-search">
                            <div className="row">
                              <div className="col s12">
                                <h6>
                                  We couldn’t find any results for “
                                  {this.state.searchValue}”. Please try another
                                  search.
                                </h6>
                                <div className="image">
                                  <img src={NoResult} alt="no result" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <table className="bordered serach-table">
                              <tbody>
                                <tr className="row result">
                                  <td>
                                    <span>{resultContact.length} results</span>
                                  </td>
                                </tr>
                                {resultContact.map(function (item) {
                                  return (
                                    <tr className="row">
                                      <td
                                        className="table-heading"
                                        onClick={() =>
                                          recentHandler(
                                            item.title,
                                            item.id,
                                            item.database
                                          )
                                        }
                                      >
                                        <div className="img-wrap">
                                          {item.contactType === "People" ? (
                                            <React.Fragment>
                                              <img src={icon3} alt="icon"></img>
                                              <img
                                                src={iconhover3}
                                                alt="icon"
                                              ></img>
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                              <BusinessOutlinedIcon htmlColor="#000" />
                                              <BusinessOutlinedIcon htmlColor="#e70a89" />
                                            </React.Fragment>
                                          )}
                                        </div>{" "}
                                        <span>
                                          <p>
                                            {" "}
                                            <Highlighter
                                              activeClassName={styles.Active}
                                              activeIndex={activeIndex}
                                              caseSensitive={caseSensitive}
                                              highlightClassName={
                                                styles.Highlight
                                              }
                                              highlightStyle={{
                                                fontWeight: "900",
                                                backgroundColor: "transparent",
                                              }}
                                              sanitize={latinize}
                                              searchWords={searchWords}
                                              textToHighlight={item.title}
                                            />
                                          </p>

                                          {item.contactType === "People" &&
                                            orgList.map(function (orgitem) {
                                              if (
                                                item.organization === orgitem.id
                                              )
                                                return (
                                                  <p className="type">
                                                    {orgitem.title}
                                                  </p>
                                                );
                                            })}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.project.projects,
    user: state.getUser.user,
    users: state.getUsers.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (project) => dispatch(createProject(project)),
  };
};
const connected = connect(mapStateToProps, mapDispatchToProps)(Search);
export default withRouter(connected);
