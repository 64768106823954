import React, { Component } from 'react';
import './new-templates.scss';
import TemplateList from './../TemplateList/TemplateList';

import { withRouter } from 'react-router-dom';
class ScopeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: false
    }
  }

  checkEditScreen = () => {
    this.setState({ overlay: true });
  }
  render() {
    const { estimateType } = this.props;
    return (
      <section
        className={`new-templates ${this.state.overlay === true ? "edit-template" : ""}`}
        style={{ minHeight: 'calc(100vh - 60px)' }}>
        <div className="container">
          <TemplateList

            update={this.props.update}
            classes={this.props.classes}

            checkEditScreen={this.checkEditScreen}
            estimateType={estimateType}
          />
        </div>
      </section>
    );
  }
}
export default withRouter(ScopeTable);