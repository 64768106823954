import NumberFormat from 'react-number-format';
export default function formatHour({ number = 0, tail = '', maximumFractionDigits = 2, numberFormat = false }) {
    if (typeof price === 'string') {
        number = parseFloat(number)
    }
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits });
    const result = formatter.format(number) + tail;
    if (numberFormat) {
        return <NumberFormat
            className="cost"
            allowNegative={false}
            isNumericString={true}
            thousandSeparator={true}
            placeholder="0"
            style={{
                textAlign: 'right', right: '0', top: '-4px', border: '0',
                fontWeight: '500', fontSize: '25px', position: 'relative'
            }}
            value={result}
            readOnly
        />
    }
    return result;
}