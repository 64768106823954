import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { DefaultButton, OutlineButton } from '../UI/Buttons';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  alertToolbarRoot: {
    justifyContent: 'flex-end',
  },
  alertToolbarGutters: {
    padding: '10px 18px 0 !important',
  },
  alertIconButton: {
    paddingBottom: '0 !important'
  },
  alertIcon: {
    width: 36,
    height: 36
  },
  alertPaper: {
    maxWidth: '350px !important',
    paddingBottom: '50px',
    borderRadius: '5px !important',
    textAlign: 'center',
  },
  alertTitle: {
    padding: '0 !important',
    '& > *': {
      fontSize: 20,
      fontWeight: 'normal',
      lineHeight: 1.35,
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#000000'
    }
  },
  alertContent: {
    padding: '10px 66px 0 !important'
  },
  alertText: {
    marginBottom: '0px !important',
    fontSize: '14px !important',
    fontWeight: 'normal !important',
    lineHeight: '1.36 !important',
    letterSpacing: 'normal !important',
    textAlign: 'center',
    color: '#000000 !important',
  },
  alertActions: {
    padding: '30px 30px 0 !important',
    justifyContent: 'center !important',
    '& > :not(:first-child)': {
      fontWeight: 300
      //fontWeight: 400
    }
  }
}));

export default function AlertDialog(props) {
  const classes = useStyles();
  const deleteHandler = () => {
    props.keyValue(props.phaseKey);
  }
  const saveContactHandler = () => {
    props.saveContact();
  }
  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-alertTitle"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.alertPaper }}
        className="delete-overlay"
      >
        <Toolbar classes={{ root: classes.alertToolbarRoot, gutters: classes.alertToolbarGutters }}>
          <IconButton aria-label="delete" onClick={props.onClose} className={`close-overlay ${classes.alertIconButton}`}>
            <CloseOutlinedIcon className={classes.alertIcon} htmlColor="#084d4f80" />
          </IconButton>
        </Toolbar>
        <DialogTitle id="alert-dialog-alertTitle" className={classes.alertTitle}>
          {props.message ? props.message : "Are you sure?"}
        </DialogTitle>
        <DialogContent className={classes.alertContent}>
          <DialogContentText id="alert-dialog-description" className={classes.alertText}>
            {props.text ? props.text :
              `Do you want to permanently delete this ${props.name ? props.name : "auto calculation"}?`}

          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.alertActions}>
          {props.deleteType === "navlink" ?

            <NavLink to={{
              pathname: "/estimates",
              userProps: false
            }}
              exact >
              <DefaultButton onClick={deleteHandler} color="primary" className="delete-confirm">
                Delete
              </DefaultButton>
            </NavLink>

            :
            props.buttonType === "save-contact" ?

              <DefaultButton onClick={saveContactHandler} color="primary" className="delete-confirm">
                Save
              </DefaultButton>
              :
              <DefaultButton onClick={deleteHandler} color="primary" className="delete-confirm">
                {props.button ? props.button : "Delete"}
              </DefaultButton>
          }

          <OutlineButton onClick={props.onClose} color="primary" className="close-overlay">
            Cancel
          </OutlineButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}