import ResourcesView from "./ResourcesView";
import GanttChart from "./GanttChart";
import Zoom from "./Zoom";
import Lightbox from "./Lightbox";
import RESOURCES_TYPES from "./ResourcesView/constant";
import Helper from "./Helper";

export default class GanttView {
	constructor(containerId, data, estimate, resouces, refreshCallBack, addResource) {
		this.containerId = containerId;
		this.data = data;
		this.resourceView = new ResourcesView();
		this.addResource = addResource;
		this.ganttChart = new GanttChart(this.resourceView, estimate, resouces, refreshCallBack, this.addResource);
		this.zoom = new Zoom();
		this.lightbox = new Lightbox();
		this.qtyContainer = null;
		this.estimate = estimate;
		this.refreshCallBack = refreshCallBack;
	}

	init() {
		gantt.config.layout = {
			rows: [
				this.ganttChart.setLayout,
				this.resourceView.setLayout,
				{ view: "scrollbar", id: "scrollHor" }
			]
		};
		this.setConfig();

		this.ganttChart.init();
		this.resourceView.init();
		this.zoom.init();
		this.lightbox.init();

		gantt.init(this.containerId);
		gantt.parse(this.data);
		gantt.getDatastore(gantt.config.resource_store).parse(RESOURCES_TYPES);
		if (!this.data || this.data.data.length === 0)
			this.resourceView.collapse();
	}

	setConfig() {
		gantt.config.scale_height = Helper.getScaleHeight();
		gantt.config.order_branch = true;
		gantt.config.duration_unit = "day";
		gantt.config.open_tree_initially = true;
		gantt.config.time_step = 1440; // hours in day
		gantt.config.work_time = true;
		gantt.config.correct_work_time = true;
		gantt.config.round_dnd_dates = false;
		gantt.config.details_on_dblclick = false;
		gantt.config.fit_tasks = true;
		gantt.config.show_links = false;
		gantt.config.show_progress = false;
		gantt.config.row_height = Helper.typeSizes.task.row_height;
		gantt.config.bar_height = Helper.typeSizes.task.bar_height;
		gantt.config.prevent_default_scroll = false;
	}

	updateGantt(data, est) {
		this.updateData(data, est);
	}

	updateData(data, est) {
		if (data) {
			if (est) {
				this.estimate = est;
				this.ganttChart.update(est);
			}
			this.data = data;
			gantt.clearAll();
			gantt.parse(this.data);
			gantt.getDatastore(gantt.config.resource_store).parse(RESOURCES_TYPES);

			if (!data.data.some(d => d.type === "task"))
				this.resourceView.collapse();
			else
				this.resourceView.expand();
		}
		else
			this.resourceView.collapse();

	}

	updateRoleNames(roles) {
		if (roles) {
			this.ganttChart.onUpdateRoleNames(roles);
		}
	}
}
