import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import { getCopyName } from "../../libs/getCopyName";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import NoContent from "../small-components/nocontent";

import MenuDropdown from "../small-components/MenuDropdown/MenuDropdown";
import SearchBox from "../small-components/SearchBox/SearchBox";
import InfoPanel from "../small-components/InfoPanel/InfoPanel";
import AsideDrawer from "../small-components/AsideDrawer/AsideDrawer";
import AlertDialog from "../small-components/AlertDialog/AlertDialog";
import InputField from "../small-components/UI/InputField";
import ColorPicker from "../small-components/ColorPicker/ColorPicker";
import { DefaultButton, OutlineButton } from "../small-components/UI/Buttons";
import Moment from "moment";
import { debounce } from "lodash";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import "../../assets/scss/manage-resources.scss";
import fire from "../../config/firebase";
import loaderimage from "../../assets/images/PageLoader.svg";
import { getNearestColor, colorArray } from "../../libs/color/colorUtils";
import { isEmptyString } from "../../libs/utils";

const styles = () => ({
  menuDropdown: {
    marginLeft: 10,
  },
  menuDropdownInput: {
    padding: "5.5px 10px",
    paddingRight: "31px !important",
  },
  menuDropdownIcon: {
    marginRight: "5.5px",
  },
  pagination: {
    width: "auto",
    backgroundColor: "transparent !important",
    boxShadow: "none",
  },
  paginationItem: {
    width: 30,
    height: 30,
    padding: "5px 11px 6px",
    backgroundColor: "transparent !important",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  paginationItemIcon: {
    width: 24,
    height: 24,
  },
  paginationItemSelected: {
    backgroundColor: "#084d4f !important",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "rgba(8, 77, 79, 0.9) !important",
    },
  },
  paginationItemDisabled: {
    opacity: "0.5 !important",
  },
  label: {
    marginTop: "30px",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#084d4f",
    textTransform: "uppercase",
  },
  buttonGroupRoot: {
    marginTop: "14px",
  },
  grouped: {
    minWidth: "100px",
    padding: "5px 18px",
    backgroundColor: "#FFFFFF",
  },
  buttonLabel: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: 1.36,
    letterSpacing: "0.14px",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#000000",
  },
  buttonOutlined: {
    borderColor: "#000000",
    borderRadius: "5px",
    "&.active": {
      backgroundColor: "rgba(8, 77, 79, 0.05)",
      "& span": {
        color: "#e70a89",
      },
    },
  },
  radio: {
    padding: "5px 10px",
    color: "#084d4f !important",
  },
  radioChecked: {
    color: "#084d4f !important",
  },
  radioLabel: {
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#084d4f",
    textTransform: "uppercase",
  },
  buttonWrap: {
    "& > .MuiButton-outlined": {
      marginLeft: 10,
    },
  },
  asideMenuDropdown: {
    marginTop: 10,
    width: "100%",
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: "31px !important",
  },
  asideMenuDropdownIcon: {
    marginRight: "5.5px",
  },
});

class ManageResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "most-recent",
      discipline: "",
      type: "",
      drawerState: false,
      dialogOpen: false,
      phaseKey: "",
      key: "",
      typeCast: "",
      arrayItem: [],
      arrayDiscipline: [],
      page: 1,
      editList: [
        {
          type: "Edit",
          icon: <EditOutlinedIcon htmlColor="#000" />,
          id: "edit",
        },
        {
          type: "Duplicate",
          icon: (
            <FileCopyOutlinedIcon htmlColor="#000" className="mirror_filter" />
          ),
          id: "duplicate",
        },
        {
          type: "Delete",
          icon: <DeleteOutlinedIcon htmlColor="#000" />,
          id: "delete",
        },
      ],
      listToShow: [],
      itemKey: "",
      optionAction: "",
      drawerName: "",
      drawerColor: "",
      drawerType: "",
      saveInfo: false,
      drawerDefaultName: "",
      drawerDefaultColor: "#00ff59",
      resourceType: "Role",
      drawerDiscipline: null,
      cost: "",
      drawerDefaultCost: 0,
      amountType: "rate",
      defaultamountType: "",
      amount: 0,
      drawerDefaultAmount: 0,
      uniqueId: 0,
      errorStateName: false,
      errorStateCost: false,
      errorStateAmount: false,
      loader: true,
      randomColor: "#00ff59",
      // colorArray: ['#ff0000', '#faff00', '#00ff15', '#0010ff', '#ff004a'],
      dataLength: 0,
      defaultDiscipline: null,
      drawerNameError: false
    };
    this.items = [...this.state.arrayItem];
    this.paginationCount = 10;
    this.refreshData = this.refreshData.bind(this);
    this.drawerInput = this.drawerInput.bind(this);
  }
  refreshData() {
    const companyId = localStorage.getItem('companyId');
    const ref = fire.database().ref(companyId).child("resources");
    ref.once("value").then(
      (snapshot) => {
        const resources = snapshot.val();
        ref.off("value");
        const resourceList = [];
        for (const id in resources) {
          resources[id].icon = WorkOutlineOutlinedIcon;
          resourceList.push({ id, ...resources[id] });
        }
        this.items = resourceList;
        this.setState({
          arrayItem: resourceList,
          loader: false,
          dataLength: resourceList.length,
        });
      },
      () => {
        // console.log("Error: " + error.code);
      }
    );
    const listRef = fire.database().ref(companyId).child("disciplines");
    listRef.once("value").then((snapshot) => {
      const list = snapshot.val();
      listRef.off("value");
      const listArr = [];
      for (const id in list) {
        listArr.push({ id, ...list[id] });
      }
      if (listArr && listArr.length > 0) {
        this.setState({
          arrayDiscipline: listArr,
        });
      }
    });
    const randomIndex = Math.random() * 100000;
    const color = colorArray[Math.floor(randomIndex % colorArray.length)];
    this.setState({ randomColor: color });
  }
  componentDidMount = () => {
    this.refreshData();
  };

  handleSort = (event) => {
    this.setState({ sort: event.target.value });
  };
  handleType = (event) => {
    this.setState({ type: event.target.value });
  };
  handleDiscipline = (event) => {
    this.setState({ discipline: event.target.value });
  };
  handleDrawerDiscipline = (event) => {
    this.setState({ drawerDiscipline: event.target.value });
  };
  handlePages = (e, key) => {
    const val = key.toLowerCase().split(" ").join("-");
    if (val === "time-based")
      this.setState({
        typeCast: true,
      });
    else
      this.setState({
        typeCast: true,
      });
  };
  inputText = debounce((e) => {
    this.setState({
      key: e,
      page: 1,
    });
  }, 700);
  handleChange = (event) => {
    this.setState({
      age: event.target.value,
      page: 1,
    });
  };
  handlePageChange = (event, value) => {
    this.setState({
      page: value,
    });
  };
  createNew = (e, id) => {
    e.preventDefault();
    document.querySelector(".item-option").classList.remove("activeOption");
    document.getElementById(id).style.display = "block";
    document.querySelector(".estimates-settings").style.display = "block";
  };
  overlay = (e) => {
    e.preventDefault();
    document.querySelector(".overlay").style.display = "block";
  };

  toggleDrawer = (open, type) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === true) {
      const randomIndex = Math.random() * 100000;
      const color = colorArray[Math.floor(randomIndex % colorArray.length)];
      this.setState({ randomColor: color });
    }
    this.setState({ drawerState: open, drawerType: type, drawerName: "" });
    if (type === "new") {
      this.setState({
        drawerDefaultName: "",
        drawerDiscipline: this.state.defaultDiscipline,
        resourceType: "Role",
        cost: 0,
        drawerDefaultCost: 0,
        amountType: "rate",
        defaultamountType: "rate",
        amount: 0,
        drawerDefaultAmount: 0,
      });
      const companyId = localStorage.getItem('companyId');
      const companyRef = fire.database().ref(companyId).child(`company`)
      companyRef.once('value').then((snapshot) => {
        const list = snapshot.val();
        this.setState({ amount: list.hourlyRate })
        this.setState({ cost: list.hourlyCost })
      });
    }
    this.setState({
      errorStateName: false,
      errorStateCost: false,
      errorStateAmount: false,
    });
  };
  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };
  handleDialogClose = () => {
    this.setState({ dialogOpen: false, drawerName: "" });
  };
  handlerResourceType = (type) => {
    if (type === "Vendor" && this.state.drawerType === "new") {
      this.inputElement.click();
      this.setState({ amountType: "percentage" });
    } else if (type === "Role" && this.state.drawerType === "new") {
      this.inputElementRate.click();
      this.setState({ amountType: "rate" });
    }
    this.setState({
      resourceType: type,
      errorStateName: false,
      errorStateCost: false,
      errorStateAmount: false,
    });
  };
  drawerInput(val) {
    this.setState({
      drawerName: val.trimStart(),
      drawerNameError: isEmptyString(val),
      errorStateName: isEmptyString(val)
    });
  };
  colorHandler = debounce((e) => {
    this.setState({ drawerColor: e });
  }, 300);

  handleDrawerAmount = (val = "") => {
    val = val.replace(/[e\+\-]/gi, "");
    const re = /^[0-9]/g;
    if (val !== '' && re.test(val) === false) {
      return;
    }
    if (val.length > 5) {
      return;
    }
    if (typeof val === "string") {
      val = val.slice(0, 5);
    }

    this.setState({
      amount: val,
      errorStateAmount: val === "",
    });
  };
  handleDrawerCost = (val = "") => {
    const re = /^[0-9]/g;
    val = val.replace(/[e\+\-]/gi, "");
    if (val !== "" && re.test(val) === false) {
      return;
    }
    if (val.length > 5) {
      return;
    }
    if (typeof val === "string") {
      val = val.slice(0, 5);
    }
    this.setState({
      cost: val,
      errorStateCost: val === "",
    });
  };
  handleAmountType = debounce((e) => {
    this.setState({ amountType: e, defaultamountType: e });
  }, 300);
  optionChange = debounce((value, itemIndex) => {
    const companyId = localStorage.getItem('companyId');
    const current = this.state.arrayItem.find((d) => d.id === itemIndex);
    if (value === "delete") {
      this.setState({
        dialogOpen: true,
        phaseKey: itemIndex,
        optionAction: value,
      });
    } else if (value === "duplicate") {
      let title = "",
        bordercolor = "",
        caption = "",
        subtitle = "",
        cost = "",
        amountType = "",
        amount = "";

      if (current) {
        title = getCopyName(current["title"], this.state.arrayItem, 'title') || '';
        bordercolor = getNearestColor(current["borderColor"]);
        caption = current["caption"] || '';
        subtitle = current["subtitle"] || '';
        cost = current["cost"] || '';
        amountType = current["amountType"] || '';
        amount = current["amount"] || '';

        const listRef = fire.database().ref(companyId).child("resources");
        const itemData = {
          title: title,
          borderColor: getNearestColor(bordercolor),
          date: Moment(new Date()).format("MM/DD/YYYY"),
          caption: caption,
          subtitle: subtitle,
          cost: cost,
          amountType: amountType,
          amount: amount,
          timestamp: Math.floor(Date.now() / 1000),
        };
        listRef.push(itemData);
        this.refreshData();
      }
    } else {
      if (current) {
        this.setState({
          phaseKey: itemIndex,
          optionAction: value,
          drawerState: true,
          drawerType: "old",
          drawerName: current["title"],
          drawerDefaultName: current["title"],
          drawerDefaultColor: current["borderColor"],
          drawerDiscipline: current["caption"] || '',
          resourceType: current["subtitle"],
          drawerDefaultCost: current["cost"],
          defaultamountType: current["amountType"],
          drawerDefaultAmount: current["amount"],
          cost: current["cost"],
          amount: current["amount"],
        });
      }
      setTimeout(() => {
        this.setState({
          optionAction: "",
        });
      }, 1000);
    }
  });
  drawerDelete = () => {
    this.setState({
      dialogOpen: true,
      optionAction: "delete",
    });
  };
  deleteHandler = debounce((key) => {
    const companyId = localStorage.getItem('companyId');
    const userRef = fire.database().ref(companyId).child("resources").child(key);
    userRef.remove();
    this.setState({
      itemKey: key,
      dialogOpen: false,
      drawerState: false,
    });
    setTimeout(() => {
      this.setState({
        itemKey: "",
      });
    }, 300);
    this.refreshData();
  });
  closeHandler = () => {
    this.setState({ dialogOpen: false });
  };
  saveItem = () => {
    const companyId = localStorage.getItem('companyId');
    const { drawerDiscipline, phaseKey, drawerDefaultColor, amountType, drawerColor, randomColor, drawerType, resourceType, cost } = this.state;
    if (this.state.drawerName === "") {
      this.setState({
        errorStateName: true,
      });
    }
    if (cost === 0) {
      this.setState({
        errorStateCost: true,
      });
    }
    if (this.state.amount === 0) {
      this.setState({
        errorStateAmount: true,
      });
    }
    let saveTrue = false;
    if (resourceType === "Vendor") {
      saveTrue = true;
    } else if (cost !== 0 && resourceType === "Role") {
      saveTrue = true;
    }
    if (
      this.state.drawerName !== "" &&
      saveTrue === true &&
      this.state.amount !== 0
    ) {
      this.setState({
        drawerState: false,
      });
      setTimeout(() => {
        this.setState({
          saveInfo: false,
          drawerName: "",
          drawerType: "",
        });
      }, 500);

      if (drawerType === "new") {
        const listRef = fire.database().ref(companyId).child("resources");
        let new_icon;
        if (resourceType === "Role") {
          new_icon = GroupOutlinedIcon;
        } else {
          new_icon = WorkOutlineOutlinedIcon;
        }
        const colorCode = drawerColor || randomColor;
        const resourceData = {
          //id: this.state.uniqueId,
          title: this.state.drawerName || '',
          borderColor: getNearestColor(colorCode),
          caption: this.state.drawerDiscipline || '',
          subtitle: resourceType || '',
          icon: JSON.stringify(new_icon),
          cost: cost,
          amountType: amountType,
          amount: this.state.amount,
          date: Moment(new Date()).format("MM/DD/YYYY"),
          timestamp: Math.floor(Date.now() / 1000),
        };
        listRef.push(resourceData);
      } else if (this.state.drawerType === "old") {
        let caption = "",
          subtitle = "";

        const amount = this.state.amount || this.state.drawerDefaultAmount;
        const cost = this.state.cost || this.state.drawerDefaultCost;
        const title = this.state.drawerName || this.state.drawerDefaultName;

        const amountType = "" + this.state.amountType + "";
        const bordercolor = getNearestColor(drawerColor || drawerDefaultColor);
        caption = "" + (drawerDiscipline || '') + "";
        subtitle = "" + resourceType + "";

        const listRef = fire.database().ref(companyId).child("resources").child(phaseKey);

        listRef.update({
          title: title,
          borderColor: getNearestColor(bordercolor),
          caption: caption,
          subtitle: subtitle,
          cost: cost,
          amountType: amountType,
          amount: amount,
        });
      }
    }
    this.refreshData();
    this.setState({ drawerColor: "" });
  };

  handleCreate = (open, type) => {
    this.setState({ drawerState: open, drawerType: type });
  };
  compareTimeStampDescending = (a, b) =>
    (b.timestamp || -1) - (a.timestamp || -1);

  render() {
    const { resourceType, drawerDefaultName, randomColor, arrayDiscipline, drawerDefaultColor, drawerType, drawerNameError } = this.state;
    const { classes } = this.props;
    const th = this;
    let listToShow = this.state.arrayItem;
    if (
      th.state.sort !== "" &&
      th.state.discipline === "" &&
      th.state.type === "" &&
      th.state.key === ""
    )
      //sort
      listToShow = this.items.sort(function (a, b) {
        if (th.state.sort === "ascending") {
          return a["title"].localeCompare(b["title"]);
        } else if (th.state.sort === "descending") {
          return b["title"].localeCompare(a["title"]);
        } else if (th.state.sort === "most-recent") {
          return th.compareTimeStampDescending(a, b);
        } else {
          return a["id"].toString().localeCompare(b["id"].toString());
        }
      });
    else if (
      th.state.sort === "" &&
      th.state.discipline === "" &&
      th.state.type === "" &&
      th.state.key !== ""
    )
      // key/ name search
      listToShow = this.items.filter(function (i) {
        return (i.title || '').toLowerCase().match(th.state.key.toLowerCase());
      });
    else if (
      th.state.sort === "" &&
      th.state.discipline === "" &&
      th.state.type !== "" &&
      th.state.key === ""
    )
      // type/ subtitle
      listToShow = this.items.filter(function (i) {
        return i.subtitle.toLowerCase().match(th.state.type.toLowerCase());
      });
    else if (
      th.state.sort === "" &&
      th.state.discipline !== "" &&
      th.state.type === "" &&
      th.state.key === ""
    )
      // discipline/ caption
      listToShow = this.items.filter(function (i) {
        return i.caption.toLowerCase().match(th.state.discipline.toLowerCase());
      });
    else if (
      th.state.sort === "" &&
      th.state.discipline !== "" &&
      th.state.type !== "" &&
      th.state.key === ""
    )
      // discipline and type
      listToShow = this.items.filter(function (i) {
        return (
          i.caption.toLowerCase().match(th.state.discipline.toLowerCase()) &&
          i.subtitle.toLowerCase().match(th.state.type.toLowerCase())
        );
      });
    else if (
      th.state.sort === "" &&
      th.state.discipline !== "" &&
      th.state.type === "" &&
      th.state.key !== ""
    )
      // discipline and key
      listToShow = this.items.filter(function (i) {
        return (
          i.caption.toLowerCase().match(th.state.discipline.toLowerCase()) &&
          i.title.toLowerCase().match(th.state.key.toLowerCase())
        );
      });
    else if (
      th.state.sort !== "" &&
      th.state.discipline !== "" &&
      th.state.type === "" &&
      th.state.key === ""
    )
      // discipline and sort
      listToShow =
        this.items.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return th.compareTimeStampDescending(a, b);
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.items.filter(function (i) {
          return (i.caption || '')
            .toLowerCase()
            .match(th.state.discipline.toLowerCase());
        });
    else if (
      th.state.sort === "" &&
      th.state.discipline === "" &&
      th.state.type !== "" &&
      th.state.key !== ""
    )
      // type and key
      listToShow = this.items.filter(function (i) {
        return (
          i.subtitle.toLowerCase().match(th.state.type.toLowerCase()) &&
          i.title.toLowerCase().match(th.state.key.toLowerCase())
        );
      });
    else if (
      th.state.sort !== "" &&
      th.state.discipline === "" &&
      th.state.type !== "" &&
      th.state.key === ""
    )
      // type and sort
      listToShow =
        this.items.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return th.compareTimeStampDescending(a, b);
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.items.filter(function (i) {
          return i.subtitle.toLowerCase().match(th.state.type.toLowerCase());
        });
    else if (
      th.state.sort !== "" &&
      th.state.discipline === "" &&
      th.state.type === "" &&
      th.state.key !== ""
    )
      // key - sort
      listToShow =
        this.items.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return th.compareTimeStampDescending(a, b);
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.items.filter(function (i) {
          return i.title.toLowerCase().match(th.state.key.toLowerCase());
        });
    else if (
      th.state.sort === "" &&
      th.state.discipline !== "" &&
      th.state.type !== "" &&
      th.state.key !== ""
    )
      // discipline and type and key
      listToShow = this.items.filter(function (i) {
        return (
          i.subtitle.toLowerCase().match(th.state.type.toLowerCase()) &&
          i.title.toLowerCase().match(th.state.key.toLowerCase()) &&
          i.caption.toLowerCase().match(th.state.discipline.toLowerCase())
        );
      });
    else if (
      th.state.sort !== "" &&
      th.state.discipline !== "" &&
      th.state.type !== "" &&
      th.state.key === ""
    )
      // discipline and sort and type
      listToShow =
        this.items.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return th.compareTimeStampDescending(a, b);
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.items.filter(function (i) {
          return (
            i.subtitle.toLowerCase().match(th.state.type.toLowerCase()) &&
            i.caption.toLowerCase().match(th.state.discipline.toLowerCase())
          );
        });
    else if (
      th.state.sort !== "" &&
      th.state.discipline !== "" &&
      th.state.type === "" &&
      th.state.key !== ""
    )
      // discipline - sort - key
      listToShow =
        this.items.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return th.compareTimeStampDescending(a, b);
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.items.filter(function (i) {
          return (
            i.title.toLowerCase().match(th.state.key.toLowerCase()) &&
            i.caption.toLowerCase().match(th.state.discipline.toLowerCase())
          );
        });
    else if (
      th.state.sort !== "" &&
      th.state.discipline === "" &&
      th.state.type !== "" &&
      th.state.key !== ""
    )
      // sort - key - type
      listToShow =
        this.items.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return th.compareTimeStampDescending(a, b);
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.items.filter(function (i) {
          return (
            i.title.toLowerCase().match(th.state.key.toLowerCase()) &&
            i.subtitle.toLowerCase().match(th.state.type.toLowerCase())
          );
        });
    else if (
      th.state.sort !== "" &&
      th.state.discipline !== "" &&
      th.state.type !== "" &&
      th.state.key !== ""
    )
      // sort - key - type - discipline
      listToShow =
        this.items.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return th.compareTimeStampDescending(a, b);
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.items.filter(function (i) {
          return (
            i.subtitle.toLowerCase().match(th.state.type.toLowerCase()) &&
            i.title.toLowerCase().match(th.state.key.toLowerCase()) &&
            i.caption.toLowerCase().match(th.state.discipline.toLowerCase())
          );
        });
    else
      listToShow = this.items.filter(function (i) {
        return i;
      });

    return (
      <React.Fragment>
        {this.state.loader === true ? (
          <div className="loader-container">
            <object data={loaderimage} type="image/svg+xml"></object>
          </div>
        ) : (
          <Box
            className="manage-resources"
            style={{
              paddingTop: "77px",
              minHeight: "calc(100vh - 60px)",
              backgroundColor: "rgba(8,77,79,0.05)",
            }}
          >
            {this.state.dataLength > 0 ? (
              <Box
                component="section"
                className="filter-menu"
                style={{ padding: "21px 0 20px" }}
              >
                <Box className="container">
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography
                        variant="h5"
                        component="h5"
                        style={{ fontWeight: 100, fontSize: "1.65rem" }}
                      >
                        Manage Resources
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Box textAlign="right" style={{ marginTop: 7 }}>
                        <DefaultButton
                          variant="contained"
                          startIcon={<SupervisedUserCircleOutlinedIcon />}
                          onClick={this.toggleDrawer(true, "new")}
                          style={{ fontWeight: 500 }}
                        >
                          Add Resource
                        </DefaultButton>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box className="filter-wrap" style={{ marginTop: 25 }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <SearchBox
                          placeholder="Search resources"
                          change={this.inputText}
                        ></SearchBox>
                      </Grid>
                      <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                        item
                        xs={6}
                      >
                        <MenuDropdown
                          className={classes.menuDropdown}
                          selectInputProps={{
                            classes: {
                              root: classes.menuDropdownInput,
                              icon: classes.menuDropdownIcon,
                            },
                          }}
                          value={this.state.discipline}
                          change={this.handleDiscipline}
                        >
                          <MenuItem value="">All Disciplines</MenuItem>
                          {arrayDiscipline.map(function (
                            item,
                            index
                          ) {
                            return (
                              <MenuItem key={index} value={item.title}>
                                {item.title}
                              </MenuItem>
                            );
                          })}
                        </MenuDropdown>
                        <MenuDropdown
                          className={classes.menuDropdown}
                          selectInputProps={{
                            classes: {
                              root: classes.menuDropdownInput,
                              icon: classes.menuDropdownIcon,
                            },
                          }}
                          size="small"
                          value={this.state.type}
                          change={this.handleType}
                        >
                          <MenuItem value="">All Types</MenuItem>
                          <MenuItem value="role">Role</MenuItem>
                          <MenuItem value="vendor">Vendor</MenuItem>
                        </MenuDropdown>
                        <MenuDropdown
                          className={classes.menuDropdown}
                          selectInputProps={{
                            classes: {
                              root: classes.menuDropdownInput,
                              icon: classes.menuDropdownIcon,
                            },
                          }}
                          size="small"
                          value={this.state.sort}
                          change={this.handleSort}
                        >
                          <MenuItem value="most-recent">Most Recent</MenuItem>
                          <MenuItem value="ascending">Ascending</MenuItem>
                          <MenuItem value="descending">Descending</MenuItem>
                        </MenuDropdown>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              component="section"
              className="panel-list"
              style={{ padding: "0 0 22px" }}
            >
              <Box className="container">
                {listToShow.length > 0 ? (
                  <InfoPanel
                    items={listToShow}
                    page={this.state.page}
                    classes={this.props.classes}
                    list={this.state.editList}
                    optionValue={this.optionChange}
                    paginationLimit={this.paginationCount}
                    searchWords={this.state.key}
                  />
                ) : (
                  // The changes for no fitered resoures
                  <NoContent
                    title="Resources. They get it done."
                    description="Add the employees and vendors who will do the work here."
                    Button_type="resources"
                    createNew={this.handleCreate}
                    searching={this.state.dataLength > 0}
                  />
                )}
              </Box>
            </Box>
            <Box component="aside" style={{ marginLeft: 79 }}>
              <AsideDrawer
                toolbarTitle={`${drawerType === "new" ? "Add" : "Edit"
                  } Resource`}
                open={this.state.drawerState}
                close={this.toggleDrawer(false)}
                clsName="resource-drawer"
              >
                <Typography className={classes.label} id="resource-type">
                  Resource Type {resourceType}
                </Typography>
                <ButtonGroup
                  aria-labelledby="resource-type"
                  classes={{
                    root: classes.buttonGroupRoot,
                    grouped: classes.grouped,
                  }}
                >
                  <Button
                    className={`${resourceType === "Role" ? "active" : ""
                      }`}
                    classes={{
                      label: classes.buttonLabel,
                      outlined: classes.buttonOutlined,
                    }}
                    onClick={() => this.handlerResourceType("Role")}
                  >
                    Role
                  </Button>
                  <Button
                    className={`${resourceType === "Vendor" ? "active" : ""
                      }`}
                    classes={{
                      label: classes.buttonLabel,
                      outlined: classes.buttonOutlined,
                    }}
                    onClick={() => this.handlerResourceType("Vendor")}
                  >
                    Vendor
                  </Button>
                </ButtonGroup>

                {resourceType === "Role" ? (
                  <React.Fragment>
                    <Typography className={classes.label} id="name">
                      Name
                    </Typography>
                    <InputField
                      label="Role Name "
                      defaultValue={
                        drawerType === "old"
                          ? drawerDefaultName
                          : ""
                      }
                      aria-labelledby="name"
                      required
                      className={
                        this.state.errorStateName === true && "errorInput"
                      }
                      onChange={(e) => this.drawerInput(e.target.value)}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {resourceType === "Vendor" ? (
                  <React.Fragment>
                    <Typography className={classes.label} id="name">
                      Name
                    </Typography>
                    <InputField
                      label="Vendor Name"
                      defaultValue={
                        drawerType === "old"
                          ? drawerDefaultName
                          : ""
                      }
                      aria-labelledby="name"
                      required
                      className={
                        this.state.errorStateName === true && "errorInput"
                      }
                      onChange={(e) => this.drawerInput(e.target.value)}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}

                {resourceType === "Role" ? (
                  <React.Fragment>
                    <Typography className={classes.label} id="hourly-cost">
                      Hourly Cost
                    </Typography>
                    <InputField
                      className={`resource_hourly_cost ${this.state.errorStateCost === true && "errorInput"
                        }`}
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      label="Amount"
                      aria-labelledby="hourly-cost"
                      adornment="$"
                      adornmentPosition="end"
                      value={this.state.cost}
                      required
                      defaultValue={
                        this.state.drawerType === "old"
                          ? this.state.drawerDefaultCost || ""
                          : ""
                      }
                      onChange={(e) => this.handleDrawerCost(e.target.value)}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}
                {resourceType === "Vendor" ? (
                  <React.Fragment>
                    <Typography className={classes.label} id="hourly-cost">
                      Hourly Cost
                    </Typography>
                    <InputField
                      className="resource_hourly_cost"
                      type="number"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      label="Amount"
                      aria-labelledby="hourly-cost"
                      adornment="$"
                      adornmentPosition="end"
                      value={this.state.cost}
                      // required
                      defaultValue={
                        this.state.drawerType === "old"
                          ? this.state.drawerDefaultCost
                          : ""
                      }
                      onChange={(e) => this.handleDrawerCost(e.target.value)}
                    />
                  </React.Fragment>
                ) : (
                  ""
                )}
                <Typography className={classes.label} id="amount"></Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="amount"
                    name="amount"
                    value={this.state.value}
                    defaultValue={this.state.defaultamountType}
                    onChange={(e) => this.handleAmountType(e.target.value)}
                  >
                    <FormControlLabel
                      value="rate"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.radioChecked,
                          }}
                          ref={(input) => (this.inputElementRate = input)}
                        />
                      }
                      label="Billable Rate"
                      classes={{ label: classes.radioLabel }}
                    />
                    <FormControlLabel
                      value="percentage"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.radioChecked,
                          }}
                          ref={(input) => (this.inputElement = input)}
                        />
                      }
                      label="Upsell Percentage"
                      classes={{ label: classes.radioLabel }}
                    />
                  </RadioGroup>
                </FormControl>
                <InputField
                  type="number"
                  onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  label="Amount"
                  className={`${this.state.defaultamountType === "percentage"
                    ? "percentage_num"
                    : "dollar_cost"
                    } ${this.state.errorStateAmount === true && "errorInput"}`}
                  aria-labelledby="amount"
                  adornment={
                    this.state.defaultamountType === "percentage" ? "%" : "$"
                  }
                  adornmentPosition={`${this.state.defaultamountType === "percentage"
                    ? "end"
                    : "start"
                    }`}
                  required
                  value={this.state.amount}
                  defaultValue={
                    this.state.drawerType === "old"
                      ? this.state.drawerDefaultAmount
                      : ""
                  }
                  onChange={(e) => this.handleDrawerAmount(e.target.value)}
                />

                <Typography className={classes.label} id="discipline">
                  Discipline
                </Typography>
                <MenuDropdown
                  className={classes.asideMenuDropdown}
                  selectInputProps={{
                    classes: {
                      root: classes.asideMenuDropdownInput,
                      icon: classes.asideMenuDropdownIcon,
                    },
                  }}
                  aria-labelledby="discipline"
                  value={this.state.drawerDiscipline}
                  change={this.handleDrawerDiscipline}
                >
                  <MenuItem key={100000} value={''}>
                    {''}
                  </MenuItem>
                  {this.state.arrayDiscipline.map(function (item, index) {
                    return (
                      <MenuItem key={index} value={item.title}>
                        {item.title}
                      </MenuItem>
                    );
                  })}
                </MenuDropdown>
                <Typography className={classes.label} id="color-picker">
                  Color
                </Typography>
                <ColorPicker
                  colorCode={this.colorHandler}
                  defaultColor={
                    drawerType === "old"
                      ? drawerDefaultColor
                      : randomColor
                  }
                />
                <Box className={classes.buttonWrap} mt={2}>
                  <DefaultButton
                    variant="contained"
                    // className={`${this.state.amount && this.state.drawerName ? "" : "resource-btn-disabled"}`}
                    onClick={drawerNameError === false ? this.saveItem : () => { }}>
                    Save
                  </DefaultButton>
                  {drawerType === "old" ? (
                    <OutlineButton
                      variant="outlined"
                      onClick={this.drawerDelete}
                    >
                      Delete
                    </OutlineButton>
                  ) : (
                    ""
                  )}
                </Box>
              </AsideDrawer>
            </Box>
          </Box>
        )}

        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={"resource"}
        />
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ManageResources);
