
export default function formatMoneyStr({ number = 0, perTitle = '', money = true, maximumFractionDigits = 0 }) {
    const formatter = new Intl.NumberFormat('en-US', money ? {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits
    } : {});
    if (typeof price === 'string') {
        number = parseFloat(number)
    }

    const result = formatter.format(number) + perTitle;
    return result;
}