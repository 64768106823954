import React from "react";
import '../../assets/scss/all-estimates.scss';
import { Typography } from '@material-ui/core';
import EstimaticSkyGondolaImage from "../../assets/images/estimate_empty.svg";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid/Grid";
import { DefaultButton } from "../small-components/UI/Buttons";
const content_type = "Estimates"
const Button_type = "Add Resources"
export default function NoItemAddedEstimate(props) {
  // const classes = useStyles();
  const { onClick } = props;

  return (
    <React.Fragment>
      <Grid container style={{ maxWidth: 1080, margin: '0 auto 27px' }}>
        <Grid item xs={3}>
          <Box maxWidth={226} maxHeight={208} position="relative">
            <img src={`${EstimaticSkyGondolaImage}`} alt="Estimatic Sky Gondola" style={{ width: '100%' }} />
          </Box></Grid>
        <Grid item xs={9}>
          <Box style={{ paddingLeft: '13px' }}>
            <Typography variant="h1" component="h1"
              style={{ fontSize: '40px', fontWeight: 400, lineHeight: 1.38, marginBottom: '10px' }}>
              {props.content_type ? props.content_type : content_type}. Show them what you can do.
            </Typography>
            <Typography variant="h2" component="p"
              style={{ fontWeight: 100, fontSize: '20px', lineHeight: 1.35, marginBottom: '31px' }}>
              Estimate your project with phases, tasks and resources to give your customers all the information they need to get started.
            </Typography>
            <DefaultButton onClick={(e) => onClick(e)}>
              Create {props.Button_type ? props.Button_type : Button_type}
            </DefaultButton>
          </Box>
        </Grid>
        <Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )

}