import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import RestoreIcon from '@material-ui/icons/Restore';
import SaveAsTemplate from '../../assets/images/templates.svg';
import SaveAsTemplate_pink from '../../assets/images/templates_pink.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function SelectMenu(props) {
    const { isTemplate } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const optionAction = (e, id) => {
        props.optionAction(e, id);
    }
    const templateType = localStorage.getItem("dataType");
    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}

                    onClick={handleToggle}
                >
                    <MoreVertIcon />
                </Button>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper className="select-menu-wrap">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-summary" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={() => optionAction("Duplicate", props.id, templateType)} >
                                            <FilterNoneIcon htmlColor="#000" className="mirror_filter" />
                                            Duplicate
                                        </MenuItem>
                                        <MenuItem onClick={() => optionAction("Save", props.id, templateType)}>
                                            <img src={SaveAsTemplate} alt="save" />
                                            <img src={SaveAsTemplate_pink} className="template_pink" alt="save pink" />
                                            {
                                                isTemplate ?
                                                    "Save As Estimate" :
                                                    "Save As Template"
                                            }

                                        </MenuItem>
                                        <MenuItem onClick={() => optionAction("History", props.id, templateType)}><RestoreIcon />Version History</MenuItem>
                                        <MenuItem onClick={() => optionAction("Delete", props.id, templateType)}><DeleteOutlinedIcon />Delete</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}