import Helper from "../Helper";

export default class Lightbox {
	constructor() {
		this.task = null;
		this.lightboxElements = null;
		this.isOneDateSelected = false;
		this.openingLightboxStatus = false;
		this.calendar = null;
		this.formatDate = gantt.date.date_to_str("%F %d, %Y");
		this.checkedInputValue = null;
	}

	init() {
		this.addLightboxLayout();
		this.setCalendarConfig();
		this.getlightboxElements();
		this.setLightboxConfig();
		this.setLightboxAttachEvent();
		this.calendarAttachEvent();
	}

	setLightboxConfig() {
		this.changeShowingLightboxMethod();
		this.changeHidingLightboxMethod();
	}

	setLightboxAttachEvent() {
		this.attachOnPeriodMenu();
		this.attachOnDeleteBtn();
		this.attachOnHideBtn();
		this.attachOnInputs();
		this.attachOnSaveBtn();
	}

	addLightboxLayout() {
		const lightbox = document.querySelector("#lightbox-view");
		const lightboxChild = lightbox.firstChild;

		if (!lightboxChild) {
			const layout = [ // esref
				"<div class='lightbox-container'>",
				"<div class='lightbox-title'>",
				"<span class='title-text'>Resource Duration2</span>",
				"<span class='title-icon'></span>",
				"</div>",
				"<div class='lightbox-time-config'>",
				"<div class='time_work'>",
				"<input type='radio' class='time-radio' value='timePerDay' id='time-radio-work' name='check' checked>",
				"<input type='number' class='time_work-time' name='perDay'>",
				"<div class='time_work-title'>",
				"<span class='title-name'>Hours per</span> ",
				"<span class='title-period'>day</span>",
				"<div class='menu-period'>",
				"<span class='menu-period_day active' data-period-type='day'>day</span>",
				"<span class='menu-period_week'  data-period-type='week'>week</span>",
				"</div>",
				"</div>",
				"</div>",
				"<div class='time_total'>",
				"<input type='radio' class='time-radio' value='totalTime' id='time-radio-total' name='check'>",
				"<input type='number' class='time_total-time' name='total'>",
				"<p class='time_total-title'>Total hours</p>",
				"</div>",
				"</div>",
				"<style> .lightbox-calendar #calendar .dhx_calendar { display: none; } .lightbox-calendar #calendar .dhx_calendar:last-child { display: block;  } </style>",
				"<div class='lightbox-calendar'>",
				"<div id='calendar'></div>",
				"</div>",
				"<div class='lightbox-dates'>",
				"<div class='dates-start'>",
				"<span class='start-title'>start</span>",
				"<span class='start-date'>date</span>",
				"</div>",
				"<div class='dates-end'>",
				"<span class='end-title'>end</span>",
				"<span class='end-date'>-</span>",
				"</div>",
				"</div>",
				"<div class='lightbox-buttons'>",
				"<button name='save' value='Save' id='save-btn'>Save</button>",
				"<button name='delete' value='Delete' id='delete-btn'>Delete</button>",
				"</div>",
				"</div>"
			];

			lightbox.insertAdjacentHTML("afterbegin", layout.join(""));

		}

		return lightbox;
	}

	setCalendarConfig() {
		const config = {
			range: true,
			width: 290,
		}

		this.calendar = new dhx.Calendar("calendar", config);
	}

	getlightboxElements() {
		const lightbox = this.addLightboxLayout();
		this.lightboxElements = {
			lightbox,
			closeIcon: lightbox.querySelector(".title-icon"),
			timePerDayInput: lightbox.querySelector(".time_work-time"),
			totalTimeInput: lightbox.querySelector(".time_total-time"),
			titlePeriod: lightbox.querySelector(".title-period"),
			menuPeriod: lightbox.querySelector(".menu-period"),
			dayPeriod: lightbox.querySelector(".menu-period_day"),
			weekPeriod: lightbox.querySelector(".menu-period_week"),
			radioInputs: lightbox.querySelectorAll("input[name='check']"),
			valueInputs: lightbox.querySelectorAll("input[type='number']"),
			startDateTitle: lightbox.querySelector(".start-date"),
			endDateTitle: lightbox.querySelector(".end-date"),
			saveBtn: lightbox.querySelector("#save-btn"),
			deleteBtn: lightbox.querySelector("#delete-btn")
		};
	}

	calendarAttachEvent() {
		this.calendar.events.on("Change", () => {
			this.setValueInNeighborInput();
		});

		this.calendar.config.disabledDates = (date) => {
			const disabled = {
				0: true,
				1: false,
				2: false,
				3: false,
				4: false,
				5: false,
				6: true
			};

			if (date < gantt.date.day_start(this.task.start_date)) {
				return true;
			}

			return disabled[date.getDay()];
		}

		this.calendar.events.on("BeforeChange", (date, oldDate, byClick) => {
			const currentValue = this.calendar.getValue(true);
			//const firstDate = currentValue[0];
			//const secondDate = currentValue[1] || null;
			this.checkForTheLastDate(date, oldDate);

			if (byClick) {
				const newValues = [];
				if (currentValue[1] > date) {
					this.lightboxElements.startDateTitle.innerText = this.formatDate(date);
					this.lightboxElements.endDateTitle.innerText = "-";
				}
				else if (oldDate > date) {
					this.lightboxElements.startDateTitle.innerText = this.formatDate(date);
					this.lightboxElements.endDateTitle.innerText = "-";
				}
				else {
					newValues.push(new Date(currentValue[0]))
					this.lightboxElements.endDateTitle.innerText = this.formatDate(date);
				}
				this.calendar.setValue(newValues);

				return true;
			}

		});
	}

	checkForTheLastDate(date, oldDate) {
		if (oldDate > date) {
			this.isOneDateSelected = false;
		}
	}

	extendDateDates(date, oldDate, byClick) {
		// const taskStartDate = this.task.start_date;
		// const endTask = this.task.end_date;
		const currentValue = this.calendar.getValue(true);

		if (byClick) {
			if (currentValue.length === 2) {
				const newValues = [];

				if (currentValue[1].getTime() > date.getTime()) {
					newValues.push(new Date(date))
					this.lightboxElements.endDateTitle.innerText = this.formatDate(date);
				} else {
					newValues.push(new Date(currentValue[0]))
					this.lightboxElements.startDateTitle.innerText = this.formatDate(currentValue[0]);
					this.lightboxElements.endDateTitle.innerText = "-";
				}

				this.calendar.setValue(newValues);

				this.isOneDateSelected = false;

				return true
			}
		}

		return false;
	}

	changeShowingLightboxMethod() {
		gantt.showLightbox = (id) => {
			this.task = gantt.getTask(id);
			this.lightboxElements.lightbox.classList.add("active");

			this.getCalendar();
			this.addValueInLightbox();
			this.inputValidation();

			gantt.showCover();

			return true;
		};
	}

	changeHidingLightboxMethod() {
		gantt.hideLightbox = () => {
			const lightbox = this.lightboxElements.lightbox;
			const periodMenu = this.lightboxElements.menuPeriod;

			periodMenu.classList.remove("active");
			lightbox.classList.remove("active");

			this.task = null;
			this.isOneDateSelected = false;

			gantt.hideCover();
		};
	}

	attachOnDeleteBtn() {
		this.lightboxElements.deleteBtn.onclick = () => {
			Helper.deleteTask(this.task.id, "closeLightbox");
		};
	}

	clearLightbox() {
		const timePerDayInput = this.lightboxElements.timePerDayInput;
		const totalTimeInput = this.lightboxElements.totalTimeInput;
		const titlePeriod = this.lightboxElements.titlePeriod;
		const periodMenu = this.lightboxElements.menuPeriod;
		const dayPeriod = periodMenu.firstElementChild;

		timePerDayInput.value = 0;
		totalTimeInput.value = 0;
		titlePeriod.innerText = "day";

		this.deleteActiveClassInPeriodMenu(periodMenu);
		dayPeriod.classList.add("active");
	}

	attachOnHideBtn() {
		window.addEventListener("click", (event) => {
			const element = event.target;
			const isItCover = element.classList.contains("gantt_cal_cover");
			const isItLightboxDeleteIcon = element.classList.contains("title-icon");

			if (isItCover || isItLightboxDeleteIcon) {
				gantt.hideLightbox();
				document.querySelector('body').classList.remove("gantt_resource_Drawer_open");
			}
		});
	}

	attachOnSaveBtn() {
		const saveButton = this.lightboxElements.saveBtn;
		saveButton.onclick = () => {
			const objWithInputValues = this.getCheckedInputValue();
			const validatedResult = this.inputValidation(objWithInputValues);
			const curentInputValues = this.getCheckedInputValue();
			const currentInputNode = curentInputValues.selectedInputNode;
			const neighborInput = this.getNeighborInput();

			if (!currentInputNode.value) {
				return this.toggleErrorClass(currentInputNode, neighborInput, "add");
			}

			return validatedResult ? this.saveValue(objWithInputValues) : true;
		};
	}

	saveValue(objWithInputValues) {
		const updatedTask = this.getUpdatedTask(objWithInputValues);

		if (updatedTask.$new) {
			delete updatedTask.$new;
			gantt.addTask(updatedTask, updatedTask.parent, gantt.getTaskIndex(updatedTask.id));
		}
		else if (gantt.isTaskExists(updatedTask.id)) {

			gantt.mixin(gantt.getTask(updatedTask.id), updatedTask, true);
			gantt.refreshTask(updatedTask.id);
			gantt.updateTask(updatedTask.id);
		}

		gantt.refreshData();
		gantt.hideLightbox();
	}

	getUpdatedTask(objWithInputValues) {
		const task = gantt.copy(this.task);
		const periodTypeValue = objWithInputValues.periodTypeValue;
		const startDate = objWithInputValues.startDate;
		const adjustedDate = objWithInputValues.adjustedDate;
		const hoursPerDay = this.countingHoursPerDay(objWithInputValues);
		const selectedInputName = objWithInputValues.selectedInputName;

		task.start_date = startDate;
		task.end_date = adjustedDate;
		task.hours_per_day = hoursPerDay;
		task.period_type = periodTypeValue;
		task.period_radio = selectedInputName;

		return task;
	}

	resetsTimePart(date) {
		return date ? gantt.date.date_part(date) : new Date();
	}

	countingHoursPerDay(objWithInputValues) {
		const totalHours = objWithInputValues.totalInputValue;
		const duration = objWithInputValues.duration;
		const hoursPerDay = totalHours / duration;

		objWithInputValues.duration = duration;

		return hoursPerDay;
	}

	inputValidation(objWithInputValues) {
		const curentInputValues = objWithInputValues || this.getCheckedInputValue();
		const currentInputNode = curentInputValues.selectedInputNode;
		const validatedResult = this.inputValueValidation(curentInputValues);
		const neighborInput = validatedResult ? this.getNeighborInput() : "";

		switch (validatedResult) {
			case false:
				this.toggleErrorClass(currentInputNode, neighborInput, "add");
				return false;
			case true:
				this.toggleErrorClass(currentInputNode, neighborInput, "remove");
				return true;
			default:
				return true;
		}
	}

	toggleErrorClass(currentInputNode, neighborInput, action) {
		const typeOfActions = {
			add: () => currentInputNode.classList.add("error-validation"),
			remove: () => {
				if (neighborInput) {
					neighborInput.classList.remove("error-validation");
				}
				currentInputNode.classList.remove("error-validation");
			}
		};

		(function changeValidationClass() { typeOfActions[action](); }());
	}

	inputValueValidation(objWithInputValues) {
		const rules = this.getValidationRules();
		const inputName = objWithInputValues.selectedInputName;
		const selectedValue = objWithInputValues.selectedInputValue;
		const perDayValue = objWithInputValues.perDayInputValue;
		const totalValue = objWithInputValues.totalInputValue;
		const type = inputName !== "total" ? objWithInputValues.periodTypeValue : "total";
		const duration = objWithInputValues.duration;
		const result = rules[type](selectedValue, duration);
		const isItNewResource = !perDayValue && !totalValue;

		return isItNewResource ? true : result;
	}

	getValidationRules() {
		const rules = {
			day: num => !(num < 0.1 || !num || num > 24 || Number.isNaN(num)),
			week: num => !(num < 1.25 || !num || num > 70 || Number.isNaN(num)),
			total: (num, duration) => !(
				num < 0.25 || !num ||
				Number.isNaN(num) ||
				num / duration < 0.25 ||
				num / duration > 24
			)
		};

		return rules;
	}

	getCheckedInputValue() {
		const periodTypeNode = this.lightboxElements.titlePeriod;
		const selectedInput = this.getSelectInputeNode();

		const selectedInputNode = selectedInput.node;
		const selectedInputName = selectedInput.name;
		const selectedInputValue = Number(selectedInputNode.value);

		const totalInputValue = Number(this.lightboxElements.totalTimeInput.value);
		const perDayInputValue = Number(this.lightboxElements.timePerDayInput.value);
		const periodTypeValue = periodTypeNode.innerHTML;

		const calendarDate = this.calendar.getValue(true);
		const startDate = this.resetsTimePart(calendarDate[0]);
		const endDate = this.resetsTimePart(calendarDate[1] || new Date(startDate));
		const adjustedDate = gantt.date.add(new Date(endDate), 1, "day");
		const duration = gantt.calculateDuration(startDate, adjustedDate);

		return {
			selectedInputNode,
			selectedInputName,
			selectedInputValue,
			totalInputValue,
			perDayInputValue,
			periodTypeValue,
			startDate,
			endDate,
			adjustedDate,
			duration
		};
	}

	getSelectInputeNode() {
		const radioInputsNodeInArray = Array.from(this.lightboxElements.radioInputs);
		const checkedRadioBtn = radioInputsNodeInArray.find(input => input.checked);
		const node = checkedRadioBtn.nextElementSibling;
		const name = node.name;

		return { node, name };
	}

	addValueInLightbox() {
		const lightboxValues = this.getValuesForLightbox();
		const isItNewTask = lightboxValues.isItNewTask;
		const startTaskDate = this.formatDate(lightboxValues.dateForCalendar[0] || new Date());
		const endTaskDate = lightboxValues.dateForCalendar[1] ? this.formatDate(lightboxValues.dateForCalendar[1]) : "-";
		const peridoName = lightboxValues.periodTypeValue;
		const selectedInput = lightboxValues.selectedInput || "perDay";
		const radioInputsType = {
			perDay: this.lightboxElements.radioInputs[0],
			total: this.lightboxElements.radioInputs[1]
		};

		radioInputsType[selectedInput].checked = true;

		this.lightboxElements.titlePeriod.innerText = peridoName;
		this.lightboxElements.startDateTitle.innerText = startTaskDate;
		this.lightboxElements.endDateTitle.innerText = isItNewTask ? "-" : endTaskDate;

		this.setValueInInput(lightboxValues);
		this.setDisableClassInInput();
	}

	setValueInInput(lightboxValues) {
		const perDayInputValue = lightboxValues.perDayInputValue;
		const totalInputValue = lightboxValues.totalInputValue;
		const timePerDayInputNode = this.lightboxElements.timePerDayInput;
		const totalTimeInputNode = this.lightboxElements.totalTimeInput;

		timePerDayInputNode.value = perDayInputValue;
		totalTimeInputNode.value = totalInputValue;
	}

	setDisableClassInInput() {
		this.clearDisableClassInInputs();

		const neighborInput = this.getNeighborInput();

		neighborInput.classList.add("disable");
	}

	clearDisableClassInInputs() {
		this.lightboxElements.valueInputs.forEach(item => item.classList.remove("disable"));
	}

	getNeighborInput() {
		const getNeighborInput = {
			total: this.lightboxElements.timePerDayInput,
			perDay: this.lightboxElements.totalTimeInput
		};
		const selectedInput = this.getSelectInputeNode();
		const selectedInputName = selectedInput.name;
		const neighborInput = getNeighborInput[selectedInputName];

		return neighborInput;
	}

	getValuesForLightbox() {
		const duration = gantt.calculateDuration(this.task.start_date, this.task.end_date);
		const periodType = this.task.period_type || "day";
		const hoursCalculation = this.getCalculatedTotalHoursMethods(periodType);

		const calculatedTotalHours = this.task.hours_per_day * duration;
		const roudingTotalHours = Number(calculatedTotalHours.toFixed(2));

		const isIntegerNumber = Number.isInteger(calculatedTotalHours);
		const totalHours = isIntegerNumber ? calculatedTotalHours : roudingTotalHours;
		const selectedInput = this.task.period_radio;

		const lightboxInfo = {
			startDate: this.task.start_date,
			endDate: gantt.date.add(new Date(this.task.end_date), -1, "day"),
			perDayInputValue: hoursCalculation.perDay(totalHours, duration),
			totalInputValue: totalHours,
			periodTypeValue: periodType
		};

		const startDate = lightboxInfo.startDate || new Date();
		const endDate = lightboxInfo.endDate || null;
		const dateForCalendar = [startDate, endDate];
		const perDayInputValue = lightboxInfo.perDayInputValue || "";
		const totalInputValue = lightboxInfo.totalInputValue || "";
		const periodTypeValue = lightboxInfo.periodTypeValue;
		const isItNewTask = this.task.hours_per_day === 0;

		return {
			dateForCalendar,
			perDayInputValue,
			totalInputValue,
			periodTypeValue,
			selectedInput,
			isItNewTask
		};
	}

	getCalendar() {
		const lightboxValues = this.getValuesForLightbox();
		const startDate = lightboxValues.dateForCalendar[0];
		const isItNewTask = lightboxValues.isItNewTask;

		const date = isItNewTask ? startDate : lightboxValues.dateForCalendar;

		this.calendar.setValue(date);
	}

	attachOnPeriodMenu() {
		const lightbox = this.lightboxElements.lightbox;
		const titlePeriod = this.lightboxElements.titlePeriod;
		const menuPeriod = this.lightboxElements.menuPeriod;

		this.openPeriodMenu(menuPeriod, titlePeriod);
		this.selectPeriodInPeriodMenu(menuPeriod, titlePeriod);
		this.closePeriodMenu(lightbox, menuPeriod, titlePeriod);
	}

	openPeriodMenu(menuPeriod, titlePeriod) {
		titlePeriod.onclick = () => {
			menuPeriod.classList.add("active");
			this.selectCurrentPeriod(menuPeriod, titlePeriod);
		};
	}

	selectPeriodInPeriodMenu(menuPeriod, titlePeriod) {
		menuPeriod.querySelectorAll("span").forEach((period) => {
			this.clickOnPeriod(period, menuPeriod, titlePeriod);
		});
	}

	selectCurrentPeriod(menuPeriod, titlePeriod) {
		const curentPeriodName = titlePeriod.innerText;
		const curentPeriodElement = this.lightboxElements[`${curentPeriodName}Period`];

		this.deleteActiveClassInPeriodMenu(menuPeriod);
		curentPeriodElement.classList.add("active");
	}

	clickOnPeriod(period, menuPeriod, titlePeriod) {
		period.onclick = () => {
			this.deleteActiveClassInPeriodMenu(menuPeriod);

			period.classList.add("active");
			titlePeriod.innerText = period.dataset.periodType;
			menuPeriod.classList.remove("active");

			this.setValueInNeighborInput();
		};
	}

	deleteActiveClassInPeriodMenu(menuPeriod) {
		menuPeriod.querySelectorAll("span").forEach((span) => {
			if (span.classList.contains("active")) {
				span.classList.remove("active");
			}
		});
	}

	closePeriodMenu(lightbox, menuPeriod) {
		lightbox.addEventListener("click", (event) => {
			const element = event.target;
			const isNotMenuElement = !element.classList.contains("title-period") &&
				!element.classList.contains("menu-period_days") &&
				!element.classList.contains("menu-period_week");

			if (isNotMenuElement) {
				menuPeriod.classList.remove("active");
			}
		});
	}

	attachOnInputs() {
		const inputsWithHours = this.lightboxElements.valueInputs;
		const radioInputs = this.lightboxElements.radioInputs;

		radioInputs.forEach(radio => this.attachEventsOnRadioInput(radio));
		inputsWithHours.forEach(input => this.changeValueInNeighborInput(input));
	}

	attachEventsOnRadioInput(radio) {
		radio.onchange = () => {
			this.cahngeFocusTooNeighborInput();
			this.inputValidation();
			this.setCursoreToSelectedInput();
		};
	}

	cahngeFocusTooNeighborInput() {
		const selectedInput = this.getSelectInputeNode().node;
		const neighborInput = this.getNeighborInput();

		(function clearDisableClass() { selectedInput.classList.remove("disable"); }());
		(function addDisableClassToNeighborInput() { neighborInput.classList.add("disable"); }());
		(function removeErrorClass() { neighborInput.classList.remove("error-validation"); }());
	}

	setCursoreToSelectedInput() {
		const selectedInput = this.getSelectInputeNode().node;

		selectedInput.focus();
	}

	changeValueInNeighborInput(input) {
		input.oninput = () => this.setValueInNeighborInput();
	}

	setValueInNeighborInput() {
		const objWithInputValues = this.getCheckedInputValue();
		const curentInputValue = objWithInputValues.selectedInputValue;
		if (!curentInputValue) return;

		const periodTypeValue = objWithInputValues.periodTypeValue;
		const duration = objWithInputValues.duration;
		const calculatedTotalHours = this.getCalculatedTotalHoursMethods(periodTypeValue);

		const neighborInput = this.getNeighborInput();
		const neighborInputName = neighborInput.name;
		const hours = calculatedTotalHours[neighborInputName](curentInputValue, duration);

		this.inputValidation(objWithInputValues);

		neighborInput.value = hours;
	}

	getCalculatedTotalHoursMethods(periodTypeValue) {
		return {
			perDay: (totalHours, duration) => {
				if (periodTypeValue === "week") {
					duration /= 5;
				}

				const hours = totalHours / duration;

				const result = Number.isInteger(hours) ? hours : Number(hours.toFixed(2));
				return result;
			},
			total: (value, duration) => {
				if (periodTypeValue === "week") {
					duration /= 5;
				}

				const hours = value * duration;

				const result = Number.isInteger(hours) ? hours : Number(hours.toFixed(2));
				return result;
			}
		};
	}
}
