import React, { useState, useEffect } from "react";
import "./DrawerOverlay.scss";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import fire from "../../../config/firebase";
import Moment from "moment";
import { withRouter } from "react-router-dom";

const DuplicateTemplate = (props) => {
  const [newName, setName] = useState("(copy of) " + props.projectName);
  const pathArr = props.location.pathname.split('/');
  let key;
  props.itemKey ? key = props.itemKey : key = pathArr[pathArr.length - 1];


  const handleDuplicate = () => {
    let copy = "";
    const pathArr = props.location.pathname.split('/');
    let key;
    props.itemKey ? key = props.itemKey : key = pathArr[pathArr.length - 1];
    const dataType = props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    const companyId = localStorage.getItem('companyId'); // ref(companyId).child
    const estimateRef = fire.database().ref(companyId).child(dataType + "/" + key);
    const dupRef = fire.database().ref(companyId).child(dataType);

    estimateRef.once("value", (snap) => {
      copy = snap.val();
      copy.projectName = newName;
      copy.date = Moment(new Date()).format("MM/DD/YYYY");
      copy.timestamp = Math.floor(Date.now() / 1000);
      copy.modified = Moment(new Date()).format("h:mm a MMMM DD, YYYY");
      dupRef.push(copy);
    });
    props.refreshData ? props.refreshData() : null;
  };

  return (
    <div id="duplicate" className="side-item">
      <div className="title">
        <h5>
          Duplicate Estimate
          <div className="cross" onClick={props.onClose}>
            <CloseIcon htmlColor="#084d4f" />
          </div>
        </h5>
      </div>
      <div className="content">
        <p>
          Are you sure you want to duplicate the estimates ‘{props.projectName}’?
        </p>
        <p>We recommend you rename the estimates.</p>
      </div>
      <div className="input-wrap">
        <div className="d_input">
          <TextField
            variant="outlined"
            type="text"
            label="Estimate"
            defaultValue={newName}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </div>
        <Button
          onClick={(e) => {
            handleDuplicate(e);
            props.onClose(e);
          }}
          variant="contained"
          type="button"
          className="duplicate apply"
        >
          Duplicate
        </Button>
      </div>
    </div>
  );
};

export default withRouter(DuplicateTemplate);
