import GridSettings from "./GridSettings";

export default class ResourceMenu extends GridSettings {
	constructor(resources, addResource) {
		super();
		this.addResource = addResource;
		this.сontainerWithNamesInput = null;
		this.taskId = null;
		this.curentResourceModel = null;
		this.currentType = null;
		this.resources = resources;
		// debugger;
	}

	updateResources(resources) {
		this.resources = resources;
	}
	attachResourceAdd(id) {
		const resourceDropdown = document.querySelectorAll('.resource-new_name');
		// console.log(resourceInput);
		// debugger;
		if (resourceDropdown && resourceDropdown.length) {
			const dropdown = resourceDropdown[0];
			// debugger;
			dropdown.onclick = () => {
				const task = gantt.getTask(id);
				const taskType = task.type;
				const nameNode = document.querySelector('.resource-new_name');
				const isItNewName = nameNode.classList.contains("resource-new_name");
				const newNameObject = this.getNewNameObject();
				const newNameText = newNameObject.nameText.innerText;
				const nameValue = isItNewName ? newNameText : nameNode.innerText;
				this.addResource(nameValue, taskType, this.currentType, id);
			}
		}
	}
	init() {
		this.сontainerWithNames = document.querySelector(".names-container");
		this.attachEventsOnResourceMenu();
		this.attachEventsOnNewNames();

		// debugger;
	}

	setLayout(resourceName, resourceType, task, exclude = false) {
		const taskId = task.id;
		const taskType = task.type;
		const iconName = resourceType || "none";
		const classToLabel = resourceName ? "disable" : "";
		const emptyLabelValue = {
			internal: "Role Name",
			external: "Vendor Name"
		}

		const layoutForResource = [
			`<div class='resource-menu ${exclude ? ' exclude' : ' include'}'>`,
			"<div class='resource-icon '>",
			`<span class='role-icon ${iconName.toLowerCase()}'></span>`,
			"</div>",
			"<div class='resource-input'>",
			`<input class='enter-resource' value='${resourceName || ""}' input-${taskId} type='text' name='' required='' autocomplete='off'>`,
			`<label class='resource-label ${classToLabel}' id='label-${taskId}'>${emptyLabelValue[resourceType]}</label>`,
			"</div>",
			`<span class='line' id='line-${taskId}'></span>`,
			"</div>"
		];

		const layoutForPhase = [
			"<div class='resource-menu phase-menu'>",
			"<div class='resource-input phase-input'>",
			`<input class='enter-resource enter-phase' value='${resourceName || ""}' input-${taskId} type='text' name='' required='' autocomplete='off'>`,
			`<label class='resource-label phase-label ${classToLabel}' id='label-${taskId}'>Phase Name</label>`,
			`</input>`,
			"</div>",
			`<span class='line' id='line-${taskId}'></span>`,
			"</div>"
		];

		return taskType === "project" ? layoutForPhase.join("") : layoutForResource.join("");
	}

	attachEventsOnResourceMenu() {
		gantt.attachEvent("onTaskClick", (id, event) => {
			const input = event.target;
			const isItInput = input.classList.contains("enter-resource");
			this.taskId = id;

			if (isItInput) {
				const inputContainer = input.parentNode;
				const menuContainer = inputContainer.parentNode;
				const label = inputContainer.querySelector(`#label-${id}`);
				const line = menuContainer.querySelector(`#line-${id}`);

				super.closeSelectedMenu("names-container", "active");
				// debugger;

				input.classList.add("cursore-active");
				line.classList.add("static");

				this.clearNamesInContainer();
				this.setNamesToContainer(id);
				this.attachResourceAdd(id);
				this.filterFunction(input.value);
				this.setInputLogic(input, label);
				this.showNamesContainer(menuContainer);
				// debugger;
				//
				// resource-new_name
				// const resourceInput = document.querySelector('.resource-new_name');
				// debugger;
				// console.log(resourceInput);
				// resourceInput.onclick = () => {
				// 	debugger;
				// 	// resource-new_name
				// 	// this.setChosedResource(this.taskId, input, true);
				// };
				//
				this.blurInputValidation(input);
				this.blockScroll()
			}
			return true;
		});
		// const resourceInput = document.querySelectorAll('input.enter-resource.enter-phase');
		// debugger;

		super.closeOnWindow("names-container");
		// debugger;
	}

	clearNamesInContainer() {
		while (this.сontainerWithNames.firstChild) {
			this.сontainerWithNames.removeChild(this.сontainerWithNames.firstChild);
		}
	}

	setNamesToContainer(id) {
		const task = gantt.getTask(id);
		const taskType = task.type;
		const resourceType = task.resource_type;
		const typeOfAddingText = {
			task: "as a new role",
			project: "as a new phase"
		};

		const addingText = typeOfAddingText[taskType];
		const names = [];
		const itItClearArray = names[0];
		const type = taskType === "project" ? "project" : resourceType
		const countingVariableType = taskType === "project" ? "margin" : "cost-per-hour";
		this.currentType = type;

		const resources = this.resources[type].filter(d => typeof d.title === 'string');

		// if (taskType === 'project' && resources.length > 0) {
		// 	const items = resources.filter(d => {
		// 		try {
		// 			return d.title && d.title.startsWith('xy');
		// 		}
		// 		catch (err) {
		// 			return false;
		// 		}
		// 	});
		// 	if (items.length > 0) {
		// 		console.log(items);
		// 		debugger;
		// 	}
		// }
		this.curentResourceModel = resources;

		const listNamesStart = "<div class='list-names'>";
		const listNamesEnd = "</div>";
		const newNameLayout = [
			"<div class='resource-new_name' >",
			"<div class='container'>",
			"<span class='new-resource-icon'></span>",
			`<span class='resource-text'>Add "<span class='new-name'></span>" ${addingText}</span>`,
			"</div>",
			"</div>"
		];

		names.push(listNamesStart);

		resources.forEach((res) => {
			const dataKeys = Object.keys(res);
			const dataKeyAttr = [];
			for (const curKey of dataKeys) {
				dataKeyAttr.push(` data-${curKey}='${res[curKey]}' `);
			}
			const layout = [
				"<div class='resource-name'>",
				`<span class='name' 
				${dataKeyAttr.join(' ')}
				data-${countingVariableType}="${res.cost_per_hour || res.margin}">${res.name}</span>`,
				"</div>"
			];
			names.push(layout.join(""));
		});

		names.push(listNamesEnd);
		names.push(newNameLayout.join(""));
		if (!itItClearArray) {
			this.сontainerWithNames.insertAdjacentHTML("afterbegin", names.join(""));
		}
	}

	filterFunction(inputValue) {
		const listNames = this.сontainerWithNames.firstChild;
		const namesContainer = listNames.childNodes;
		const newNameObject = this.getNewNameObject();

		namesContainer.forEach((nameContainer) => {
			const nameFromContainer = nameContainer.firstElementChild;
			const dropoutValue = nameFromContainer.textContent || nameFromContainer.innerText;
			const isItSame = dropoutValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

			if (isItSame) {
				nameContainer.style.display = "flex";
				nameContainer.dataset.visibility = "visibl";
			}
			else {
				nameContainer.style.display = "none";
				nameContainer.dataset.visibility = "invisible";
				this.similarityCheck(newNameObject, inputValue);
			}
		});
	}

	getNewNameObject() {
		const nameNode = this.сontainerWithNames.lastChild;
		const nameText = nameNode.lastElementChild.lastChild.querySelector(".new-name");

		return { nameText, nameNode };
	}

	similarityCheck(newNameObject, inputValue) {
		const namesData = this.curentResourceModel;
		const lowerCaseValue = inputValue;
		const isItTheSameValue = namesData.find(item => item.name === lowerCaseValue);

		switch (!!isItTheSameValue) {
			case false: this.addTextInAdderInput(newNameObject, inputValue);
				break;
			case true: newNameObject.nameNode.style.display = "none";
				break;
			default:
				break;
		}
	}

	addTextInAdderInput(newNameObject, inputValue) {
		newNameObject.nameNode.style.display = "flex";
		newNameObject.nameText.innerText = inputValue;
	}

	setInputLogic(input, label) {
		const newNameObject = this.getNewNameObject();

		input.oninput = (event) => {
			const inputNode = event.target;
			const inputValue = inputNode.value;

			this.filterFunction(inputValue);

			if (!inputValue) {
				label.classList.remove("disable");
				newNameObject.nameNode.style.display = "none";
				// this.currentValue = inputValue;
			}
			else {
				label.classList.add("disable");
			}
		};
	}

	showNamesContainer(menuContainer) {
		this.сontainerWithNames.classList.add("active");
		this.positioning(menuContainer);
	}

	positioning(menuContainer) {
		const menuPosition = menuContainer.getBoundingClientRect();
		const moveLeft = menuPosition.left - 120;
		const moveTop = menuPosition.top - (485 - window.scrollY);
		const topIndent = 45;

		this.сontainerWithNames.style.left = `${moveLeft}px`;
		this.сontainerWithNames.style.top = `${moveTop + topIndent}px`;
	}

	attachEventsOnNewNames() {
		this.сontainerWithNames.addEventListener("click", (event) => {

			// const resourceInput = document.querySelectorAll('input.enter-resource.enter-phase');
			// console.log(resourceInput);
			// debugger;
			// enter-resource enter-phase
			// this.сontainerWithNamesInput = document.querySelector(".enter-resource.enter-phase");

			const span = event.target;

			this.setChosedResource(this.taskId, span);
			this.taskId = null;
			this.curentResourceModel = null;
			this.currentType = null;
			gantt.hideCover();
		});
	}

	blurInputValidation(input) {
		input.onblur = (event) => {

			const inputNode = event.target;
			const inputValue = inputNode.value;
			const currentTask = gantt.getTask(this.taskId);
			if (currentTask) {
				const emptyValueInInput = !inputValue;

				if (emptyValueInInput && currentTask.resource) {
					this.updateCountInTask(currentTask, inputValue)
				}
				else if (inputValue !== currentTask.resource && inputValue) {
					if (inputValue.length) {
						// esref
						// this.setChosedResource(this.taskId, span);
						const span = event.target;

						this.setChosedResource(this.taskId, span, false);
					}
					// inputNode.value = currentTask.resource;
				}
			}
		}
	}

	updateCountInTask(task, inputValue) {
		if (task.type === "task") {
			task.cost_per_hour = 0;
			task.cost = 0;
			task.costPerHour = 0;
			task.amount = 0;
			gantt.updateTask(gantt.getParent(task.id))
		} else {
			task.margin = 0;
		}

		task.resource = inputValue;
		gantt.updateTask(task.id)
	}

	blockScroll() {
		gantt.showCover();
		this.addClassToCover();
	}

	addClassToCover() {
		const coverNode = document.querySelector(".gantt_cal_cover");
		coverNode.style.backgroundColor = "rgba(255, 255, 255, 0)";
	}

	setChosedResource(id, nameNode, saveToDb = true) {
		// saveToDb = false;
		//esref
		const isItNewName = nameNode.classList.contains("resource-new_name");
		const newNameObject = this.getNewNameObject();
		const newNameText = newNameObject.nameText.innerText;
		const task = gantt.getTask(id);
		if (task) {
			const taskType = task.type;
			let nameValue = isItNewName ? newNameText : nameNode.innerText;
			if (!nameValue) {
				nameValue = nameNode.value;
			}
			this.dataUpdate(taskType, nameValue, isItNewName, saveToDb);
			if (saveToDb) {
				this.setCoutingVariableToTask(task, nameNode, nameValue, isItNewName);
			} else {
				task.resource = nameValue;
			}
			this.ganttTaskUpdate(taskType, id);
		}
	}

	dataUpdate(taskType, nameValue, isItNewName, saveToDb = true) {
		if (!isItNewName) return;

		const type = this.currentType;
		const recourceModel = this.curentResourceModel;
		const newProject = { name: nameValue, margin: 0 };
		const newTask = { name: nameValue, cost_per_hour: 0 };

		const newItem = taskType === "project" ? newProject : newTask;
		if (saveToDb === false && ['project', 'task'].includes(taskType)) {
			newItem.saveToDb = false;
		}
		newItem.taskType = taskType;
		recourceModel.unshift(newItem);

		gantt.callEvent('onRoleNameAdded', [type, newItem]);
	}

	setCoutingVariableToTask(task, nameNode, nameValue, isItNewName) {
		isItNewName = isItNewName || !nameNode?.firstChild?.dataset;
		const margin = isItNewName ? "0" : nameNode.firstChild.dataset.margin;
		const costPerHour = isItNewName ? "0" : nameNode.firstChild.dataset.costPerHour;
		const amount = isItNewName ? "0" : nameNode.firstChild.dataset.amount;
		const dataKeys = nameNode && nameNode.firstChild && nameNode.firstChild.dataset ? Object.keys(nameNode.firstChild.dataset) : [];
		for (const key of dataKeys) {
			if (!task[key]) {
				task[key] = nameNode.firstChild.dataset[key];
			}
		}
		const currentCount = task.type === "project" ? margin : costPerHour;
		const currentAmount = task.type === "project" ? margin : amount;
		if (task.type === "project") {
			task.margin = Number(currentCount);
		} else {
			task.cost_per_hour = Number(currentCount);
			task.cost = Number(currentCount);
			task.costPerHour = Number(currentCount);
			task.amount = Number(currentAmount)
		}

		task.resource = nameValue;
	}

	ganttTaskUpdate(taskType, taskId) {
		if (taskType === "task") {
			const projectId = gantt.getParent(taskId);
			//  const project = gantt.getTask(projectId);
			// gantt.updateTask(projectId);
		}
		gantt.updateTask(taskId);
	}
}
