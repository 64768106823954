import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "../../assets/scss/footer.scss";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "12px",
  },
  textwrap: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#084D4F",
    fontSize: "12px",
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();
  //copy rights date
  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <div className={`footer ` + classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className="content-wrap" style={{ lineHeight: 1.24 }}>
          <span className={classes.textwrap}>
            © {date} Estimatic. All Rights Reserved
          </span>
          <span className='linkwrap'>
            <Link target="_blank" rel="noopener noreferrer" href="https://estimatic.com/policy/" >
              Privacy Policy
            </Link>
            <Link target="_blank" rel="noopener noreferrer" href="https://estimatic.com/terms/" >
              Legal
            </Link>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}
