import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import AutoCompleteTask from '../reusable-components/autoComplete/autoCompleteTask';
import SpeedDials from './SpeedDial';
import Resource from './resource/Resource';
import '../../assets/scss/autocomplete.scss';
import ClearIcons from "../../libs/icons";
import drag_icon from '../../assets/images/drag_icon.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AlertDialog from './AlertDialog/AlertDialog';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import uuid from 'react-uuid';
import fire from '../../config/firebase';
import formatMoneyStrFriendly from '../../libs/formatMoneyStrFriendly';
import Tooltip from '@material-ui/core/Tooltip';
import FormatMoneyStr from '../../libs/formatMoneyStr';
import { getCopyName } from '../../libs/getCopyName';
import * as getTasksActionCreators from '../../store/tasks/getTasks';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { debounce } from 'lodash';

class Task extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            speed: false,
            showResources: false,
            type: '',
            expandTask1: false,
            countTask: 0,
            // taskList: [],
            dialogOpen: false,
            autoCompleteChange: 0,
            qty: 1,
        }
        this.wrapperRef = React.createRef();
        this.wrapperRef1 = React.createRef();
        this.el = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.deleteResource = this.deleteResource.bind(this);
        this.resourceItem = this.resourceItem.bind(this);
        this.updateResource = this.updateResource.bind(this);
        this.addResource = this.addResource.bind(this);
        this.duplicateResource = this.duplicateResource.bind(this);
        this.checkTaskExclude = this.checkTaskExclude.bind(this);
        this.addEmptyResource = this.addEmptyResource.bind(this);

        this.updateTask = this.updateTask.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);

        this.openDrop1 = this.openDrop1.bind(this);

        this.closeHandler = this.closeHandler.bind(this);
        this.expandResources = this.expandResources.bind(this);
        this.qtyIncrease = this.qtyIncrease.bind(this);
        this.qtyDecrease = this.qtyDecrease.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.addNewTask = this.addNewTask.bind(this);
    }
    deleteHandler() {
        this.props.deleteTask(this.props.task);
        this.setState({
            dialogOpen: false,
        });
    }
    duplicateResource(resource) {
        const { task } = this.props;
        const newResource = { ...resource, resourceId: uuid() }
        newResource.resourceName = getCopyName(newResource.resourceName, task.resources, 'resourceName');
        task.resources.push(newResource);
        this.props.updateTask(this.props.task, { resources: task.resources });
    }
    addResource(resourceData, oldResource) {
        resourceData.type = oldResource.type;
        const { task, companyId, shared } = this.props;
        if (shared) {
            return;
        }
        const onSuccess = (resourceDrawerData) => {
            delete resourceDrawerData.resoureName;
            const listRef = fire.database().ref(companyId).child('resources');
            const keys = Object.keys(resourceDrawerData);
            for (const key of keys) {
                if (resourceDrawerData[key] === undefined) {
                    delete resourceDrawerData[key];
                }
            }
            listRef.push(resourceDrawerData);
            const newResource = Object.assign({}, oldResource, resourceData, resourceDrawerData);
            newResource.type = newResource.subtitle;
            delete newResource.subtitle;
            delete resourceDrawerData.title;
            const resourceIndex = task.resources.findIndex(d => d.resourceId === oldResource.resourceId);
            task.resources[resourceIndex] = newResource;
            this.props.updateTask(this.props.task, { resources: task.resources });
        };
        const onCancel = () => {
            this.setState({ autoCompleteChange: this.state.autoCompleteChange + 1 });
        }
        this.toggleDrawer(resourceData, onSuccess, onCancel);
    }
    updateResource = debounce((resourceData, oldData) => {
        if (this.props.shared) {
            return
        }
        const { task } = this.props;

        const safeIconJson = (d) => {
            if (d && d.icon && typeof d.icon === 'object') {
                return JSON.stringify(d.icon, null, 2);
            }
            return null;
        }
        const index = task.resources.findIndex(d => d.resourceId === oldData.resourceId);
        const dbResource = task.resources[index];
        if (dbResource) {
            delete resourceData.resourceId;
            if (resourceData.name) {
                resourceData.caption = resourceData.name;
            }
            const result = Object.assign({}, dbResource, resourceData);
            result.icon = safeIconJson(result);
            task.resources[index] = result;
            this.props.updateTask(this.props.task, { resources: task.resources });
        }
    }, 1000, { leading: true, trailing: true })

    componentWillReceiveProps(newProps) {
        if (
            newProps.swapped ||
            newProps.touched
        ) {
            this.setState({
                offsetY: this.el.getBoundingClientRect().top
            })
        }
    }
    componentDidUpdate() {

        const { offsetY } = this.state
        if (offsetY) {
            const { top } = this.el.getBoundingClientRect()
            this.transform(offsetY - top)
            setTimeout(
                () => {
                    this.resetTransform()
                    this.setState({ offsetY: 0 })
                },
                10
            );
        }
    }
    updateTask(data) {
        this.props.updateTask(this.props.task, data);
        // this.refreshData();
    }
    addNewTask(data) {
        const { companyId, shared, tasks } = this.props;
        if (shared) {
            return;
        }
        const taskRef = fire.database().ref(companyId).child('tasks');
        if (tasks.find(d => d.title === data.title) === undefined) {
            taskRef.push(data);
            const { getTasksAsync } = this.props.getTasksAction;
            getTasksAsync({})
        }
    }
    refreshData() {
        // const { companyId, shared } = this.props;
        // if (shared) {
        //     return;
        // }
        // const listRef = fire.database().ref(companyId).child('tasks');
        // const set = new Set();
        // listRef.once('value').then((snapshot) => {
        //     const list = snapshot.val();
        //     listRef.off('value');
        //     const listArr = [];
        //     for (const id in list) {
        //         if (set.has(list[id].title) === false) {
        //             listArr.push({ id, ...list[id] });
        //             set.add(list[id].title);
        //         }
        //     }
        //     this.setState({ taskList: listArr, loader: false });
        // });
        if (this.props.task.resources.length > 0) {
            this.setState({ showResources: true })
        }

        this.setState({ qty: parseInt(this.props.task.qty) })
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
        this.refreshData()
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.wrapperRef1.current === null) {
            return false;
        }
        else if (this.wrapperRef1 && !this.wrapperRef1.current.contains(event.target)) {
            if (this.state.expandTask1) {
                this.setState({
                    expandTask1: false
                })
            }
        }
    }

    toggleDrawer(data, onSucces, onCancel) {
        this.props.drawerInfoUpdate(data, onSucces, onCancel);
        this.props.toggleDrawer();
    }

    addEmptyResource(role) {
        const { task } = this.props;
        const resource = {
            qty: 1,
            hours: 1,
            resourceId: uuid(),
            cost: 0,
            amount: 0,
            type: role
        };
        if (!task.resources) {
            task.resources = [];
        }
        task.resources.push(resource);
        this.props.updateTask(this.props.task, { resources: task.resources });
    }

    resourceItem() {
        this.setState({
            showResources: true,
        });
    }
    deleteResource(resource) {
        const resources = this.props.task.resources.filter((d) => d.resourceId !== resource.resourceId);
        this.props.updateTask(this.props.task, { resources });
    }
    closeHandler() {
        this.setState({ dialogOpen: false });
    }
    openDrop1(e) {
        const target = this.state.expandTask1;
        this.setState({ expandTask1: !target });
        this.setState({ expandTask: false });
        const row = e.currentTarget.closest(".task-outer");
        const cnt = $(row).find(".task-count").length;
        this.setState({ countTask: cnt });
    }
    expandResources(e) {
        e.currentTarget.closest(".row").classList.toggle('expandResources');
        const row = e.currentTarget.closest(".row");
        $(row).find(".resource").slideToggle();
    }

    checkTaskExclude(e) {
        // console.log(e.currentTarget.closest(".row"))
        // e.currentTarget.closest(".row").classList.toggle('expandResources');
        const row = e.currentTarget.closest(".row");
        $(row).find(".resource").slideToggle();
        const { exclude = false } = this.props.task || {}
        this.props.updateTask(this.props.task, { exclude: !exclude });
    }
    qtyIncrease() {
        this.setState({ qty: parseInt(this.state.qty || 0) + 1 })
        this.props.updateTask(this.props.task, { qty: parseInt(this.state.qty || 0) + 1 });
    }
    qtyDecrease() {
        if (this.props.task.qty === 1) { return false; }
        this.setState({ qty: parseInt(this.state.qty) - 1 })
        this.props.updateTask(this.props.task, { qty: parseInt(this.state.qty) - 1 });
    }
    qtyChange(val) {
        val = parseInt(val);
        if (val < 1) {
            if (this.props.task.qty !== 1) { this.props.updateTask(this.props.task, { qty: 1 }); }
        } else {
            if (this.props.task.qty !== val) {
                this.props.updateTask(this.props.task, { qty: val });
            }
        }
    }
    isDigit = (inp = '') => {
        const valid = ['$', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
        for (let i = 0; i < inp.length; i++) {
            if (valid.includes(inp[i]) === false) {
                ;
                return false;
            }
        }
        return true;
    }

    render() {

        const { task, totalTaskPrice,
            estimate = {}, tasks,
            companyId, shared, toggleEditCostRateDrawer } = this.props;

        const { autoCompleteChange } = this.state;
        const { taskName } = task;
        const { exclude = false } = task;

        return (
            <React.Fragment>
                <AlertDialog
                    open={this.state.dialogOpen}
                    onClose={this.closeHandler}
                    keyValue={this.deleteHandler}
                    name={"task"}
                />
                <div className={exclude ? "row exclude" : "row"} key={`dfirst`} >
                    <div className="input-field col s12 l6 m6 " key="dfirst1"  >
                        <span>Task</span>
                        <div className='excludeTask'>
                            <AutoCompleteTask
                                type="task"
                                update={this.updateTask}
                                selectValue={taskName}
                                onChange={this.updateTask}
                                dataList={tasks}
                                classes={this.props.classes.addTask}
                                title={'Task Name'}
                                cla="taskName"
                                insert={this.addNewTask}
                                exclude={exclude}
                            />
                        </div>
                    </div>
                    <div className="col s12 l6 m6 counting-phase" key="dfirst2" >
                        <div className="counting-phase-qty">
                            <span>QTY</span>
                            <div className="list task_qty qty gray-circle" key="dfirst4" >
                                <ClickAwayListener onClickAway={() => {
                                    this.qtyChange(this.state.qty)
                                }}>
                                    <input type="number" min="1" value={this.state.qty}

                                        onChange={(e) => {
                                            if (this.isDigit(e.target.value) === false) {
                                                return false;
                                            }
                                            else {
                                                const val = e.target.value > 1 ? parseInt(e.target.value) : 1
                                                this.setState({ qty: val })
                                            }
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.charCode === 13 && this.state.qty) {
                                                //this.setState({ inputValue: event.target.value });
                                                this.qtyChange(this.state.qty)
                                            }
                                        }}
                                    />
                                </ClickAwayListener>
                                {shared !== true &&
                                    (task.qty < 2 ?
                                        <DeleteOutlinedIcon
                                            htmlColor="#000000"
                                            fontSize="small"
                                            className="delete"
                                            onClick={() => {
                                                this.setState({ dialogOpen: true });
                                            }}
                                        />
                                        :
                                        <RemoveIcon
                                            htmlColor="#000000"
                                            fontSize="small"
                                            className="delete"
                                            onClick={() => {
                                                this.qtyDecrease()
                                            }}
                                        />)
                                }
                                {shared !== true &&
                                    <AddIcon
                                        htmlColor="#000000"
                                        fontSize="small"
                                        className="add"
                                        onClick={() => {
                                            this.qtyIncrease()
                                        }
                                        } />
                                }
                            </div>
                        </div>
                        <div className="counting-phase-total" style={{ marginLeft: '5px' }} key="dfirst3">
                            <span>Subtotal</span>
                            <div className='taskSubtotal' >
                                <Tooltip title={totalTaskPrice * task.qty === 0 || totalTaskPrice * task.qty < 99999 ? "" : FormatMoneyStr({ number: totalTaskPrice * task.qty })} placement="top-end">
                                    <NumberFormat id={`task_total-${4}`}
                                        className="subtotal"
                                        allowNegative={false}
                                        isNumericString={true}
                                        thousandSeparator={true}
                                        placeholder="0"
                                        style={{ textAlign: 'right', right: '0', top: '-4px', border: '0', fontWeight: '600', color: 'rgba(0,0,0,0.5)', position: 'relative' }}
                                        value={formatMoneyStrFriendly({ number: totalTaskPrice * task.qty, numberFormat: false, beFriendly: true, maximumFractionDigits: 0 })}
                                        readOnly
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div >
                    {
                        ((shared == true && estimate.hideResource == true) || shared == false) && !exclude &&
                        <div className="col s12 resource" style={{ 'marginTop': '0' }} key="d1">
                            <div className="one-line-elment one-line-title" key="d11">
                                {((shared == true && estimate.hideResource == true && task.resources.length > 0) || shared == false) &&
                                    <div className="list">
                                        <h5>Resources</h5>
                                    </div>
                                }

                                {this.state.showResources && (<React.Fragment>
                                    <div className='title-resource-wrap' key="d6">
                                        <div className="list drop-box qty title" key="d2">
                                            QTY
                                        </div>
                                        {estimate.hideFinance == true && <div className="list hrs title" key="d3">
                                            Hours
                                        </div>}
                                        {estimate.shared !== true &&
                                            <div className="list cost title" key="d4">
                                                Cost
                                            </div>}
                                        <div className="list subtotal title" key="d5">
                                            Subtotal
                                        </div>
                                    </div>
                                </React.Fragment>)
                                }

                            </div>

                            {task?.resources?.map((resource, index) =>
                                <Resource
                                    toggleEditCostRateDrawer={() => toggleEditCostRateDrawer(resource, this.updateResource)}
                                    resource={resource}
                                    // updateCnt={this.props.updateCnt}
                                    addResource={this.addResource}
                                    duplicateResource={this.duplicateResource}
                                    editResources={this.editResources}
                                    deleteResource={this.deleteResource}
                                    updateResource={(newData, oldData) => this.updateResource(newData, oldData ? oldData : newData)}
                                    classes={this.props.classes}
                                    key={`${index}-${(resource.resourceName || '')}${(autoCompleteChange || '')}`}
                                    toggleEditDrawer={this.props.toggleEditDrawer}
                                    editVendorValue={this.props.editVendorValue}
                                    companyId={companyId}
                                    shared={shared}
                                />)
                            }

                            {shared !== true && <SpeedDials
                                click={this.addEmptyResource}
                                buttonItem={this.props.buttonItem}
                                defineRoleType={this.defineRoleType}
                                showResources={this.resourceItem}
                            />}
                        </div>
                    }
                    < span className="icon-box" key="dfirst6" >
                        <img src={drag_icon} alt="Drag" />
                    </span >
                    {shared !== true && <div className={this.state.expandTask1 ? 'active content-outer' : 'content-outer'} key="dfirst7">
                        <span className="top-arrow" onClick={this.expandResources}>
                            <ExpandMoreIcon htmlColor='#000' />
                        </span>
                        <MoreVertOutlinedIcon htmlColor='#000' className='open-close' onClick={this.openDrop1} ref={this.wrapperRef1} />
                        {this.state.expandTask1 && (<ul>
                            <li className="taskDuplicate" onClick={() => this.props.duplicateTask(this.props.task)}><span className="img-box">
                                <FileCopyOutlinedIcon htmlColor='#000' /></span> Duplicate</li>
                            {this.state.countTask > 1 || true ?
                                <div>
                                    <li className="taskDelete" onClick={this.checkTaskExclude}>
                                        <span className="img-box" >

                                            {exclude ? <ClearIcons
                                                htmlColor="#000"
                                                className="clear_icon"
                                                type="exclude" />
                                                :
                                                <ClearIcons
                                                    htmlColor="#000"
                                                    className="clear_icon"
                                                    type="include" />
                                            }</span>
                                        {exclude ? 'Include' : 'Exclude'}

                                    </li>
                                    <li className="taskDelete" onClick={() => this.setState({ dialogOpen: true })}>
                                        <span className="img-box"><DeleteOutlineOutlinedIcon htmlColor='#000' /></span> Delete</li>
                                </div>
                                : ""

                            }

                        </ul>)}
                    </div>
                    }
                </div>
            </React.Fragment >

        )
    }

}

function MapStateToProps(state) {
    return {
        tasks: state.getTasks.tasks,
        estimate: state.getEstimate.estimate,
    };
}
function MapDispatchToProps(dispatch) {
    return {
        getTasksAction: bindActionCreators(
            getTasksActionCreators,
            dispatch
        ),
    };
}
export default connect(MapStateToProps, MapDispatchToProps)(Task);
