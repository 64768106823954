import Helper from "../Helper";

export default class TimelineSettings {
	init() {
		this.attachOnTaskLine();
		this.setCellText();
		this.hideCell();
		this.setScaleStyle();
	}

	attachOnTaskLine() {
		gantt.attachEvent("onTaskClick", (id, event) => {
			if (event.target.closest(".task-cell-time")) {
				gantt.showLightbox(id);
			}

			return true;
		});
	}

	setCellText() {
		gantt.templates.task_text = (startTask, endTask, task) => {
			let html = "";
			const isItTask = task.type === "task";

			if (isItTask) {
				const calculateCellWidth = (start, end) => gantt.posFromDate(end) - gantt.posFromDate(start);
				const scale = gantt.getScale();
				const unit = scale.unit;
				const qty = isItTask ? task.qty : 0;
				const hoursPerDay = task.hours_per_day * qty;
				const workTime = isItTask ? hoursPerDay : "";

				let currentDate = new Date(startTask);
				let innerHTML = "";
				let innerCounter = 0;
				let width;

				while (currentDate.getTime() < endTask.getTime()) {
					const cellStart = gantt.date[`${unit}_start`](currentDate);
					const cellEnd = gantt.date.add(cellStart, scale.step, unit);

					if (innerCounter === 0) {
						width = calculateCellWidth(startTask, cellEnd);
					}
					else if (cellEnd.getTime() > endTask.getTime()) {
						width = calculateCellWidth(cellStart, endTask);
					}
					else {
						width = calculateCellWidth(cellStart, cellEnd);
					}

					innerCounter += 1;

					innerHTML += isItTask ? this.getHoursInCell(currentDate, workTime, startTask, endTask, cellEnd, width, unit) : "";
					currentDate = cellEnd;
				}

				html = `<div class="task-cell-time">${innerHTML}</div>`;
			}
			else if (task.type === "project") {
				const resourcesCount = gantt.getChildren(task.id).length - 2;
				const resourcesHTML = resourcesCount
					? `<span class="project-line_resources">- ${resourcesCount} resources</span>`
					: "";
				html = `${task.resource}${resourcesHTML}`;
			}

			return html;
		};
	}

	getHoursInCell(cellStart, workTime, startTask, endTask, cellEnd, cellWidth, unit) {
		const isItDayPeriod = unit === "day";
		let numberOfWorkDays = 0;
		let currentDate = new Date(cellStart);
		while (currentDate.getTime() < cellEnd.getTime()) {
			const isWorkDay = gantt.isWorkTime({ date: currentDate });

			if (
				isWorkDay
				&& currentDate.getTime() >= gantt.date.day_start(startTask).getTime()
				&& currentDate.getTime() < endTask.getTime()
			) {
				numberOfWorkDays += 1;
			}

			currentDate = gantt.date.add(currentDate, 1, "day");
		}

		const cellTime = workTime * numberOfWorkDays === 0 && isItDayPeriod ? "-" : Helper.numberRounding(workTime * numberOfWorkDays);
		const htmlValue = cellTime === "-" ? `<span class="cell-time disable">${cellTime}</span>` : `<span class="cell-time">${cellTime}</span>`;

		return [
			`<div class="cell-container" style="width:${cellWidth}px">`,
			htmlValue,
			"</div>"
		].join("");
	}

	hideCell() {
		gantt.templates.task_class = (start, end, task) => {
			switch (task.type) {
				case "header":
				case "button":
					return "task-line--hidden";
				default:
					return "";
			}
		};
	}

	setScaleStyle() {
		gantt.templates.scale_row_class = () => "scale";
	}

	updatePhase(id) {
		const currentPhaseId = gantt.getParent(id);

		gantt.updateTask(currentPhaseId);
	}
}
