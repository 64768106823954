import React from "react";
import "../../assets/scss/overlay.scss";
import EstimaticSetting from "./EstimaticSetting";
import NewStepper from "../small-components/stepper";
class Overlay extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  overlay = (e) => {
    e.preventDefault();
  };
  close = () => {
    document.querySelector(".overlay").classList.remove("activeOverlay");
    document.querySelector("body").classList.remove("estimateAdditon");
    // document.querySelector(".estimates-settings").style.display = "";
    const myClasses = document.querySelectorAll(
      ".estimates-settings .container > div"
    );

    const l = myClasses.length;
    for (let i; i < l; i++) {
      myClasses[i].style.display = "";
    }
  };
  containerClick = (e) => {
    e.stopPropagation();
  };
  documentClick = () => {
    document.querySelector(".overlay").classList.remove("activeOverlay");
    document.querySelector("body").classList.remove("estimateAdditon");
    document.querySelector(".estimates-settings").style.display = "";
    const myClasses = document.querySelectorAll(
      ".estimates-settings .container > div"
    );
    let i = 0;
    const l = myClasses.length;
    for (i; i < l; i++) {
      myClasses[i].style.display = "";
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="overlay" onClick={this.documentClick}>
          <div className="container" onClick={this.containerClick}>
            <div className="outerWrapper">
              <NewStepper click={this.close} overlay={this.overlay} />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Overlay;
