import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  tooltip: {
    top: '0'
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(1),
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
}));

const actions = [
  { icon: <PeopleOutlineOutlinedIcon />, name: 'Role' },
  { icon: <WorkOutlineOutlinedIcon />, name: 'Vendor' },
];

export default function SpeedDials(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="Add Resource"
          icon={<Tooltip title="Add Resource" placement="top">
            <SpeedDialIcon /></Tooltip>}
          onClick={handleOpen}
          open={open}
          direction='right'
        >
          {actions.map((action) => (
            <SpeedDialAction
              tooltipPlacement="top"
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                props.showResources()
                handleOpen()
                props.click(action.name)
                props.buttonItem()
              }}
            />
          ))}
        </SpeedDial>

      </div>
    </div>
  );
}