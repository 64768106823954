import React from 'react';
import mergeWith from 'lodash/mergeWith';
import isString from 'lodash/isString';

import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(() => ({
  menuItem: {
    padding: '8px 14px !important',
    '&:hover .MuiListItemIcon-root svg path': {
      fill: '#000000',
    },
  },
  menuIcon: {
    width: 27,
    height: 27,
    '&:hover': {
      color: '#e70a89',
    },
  },
  listItemWrap: {
    minWidth: 36,
  },
  listIcon: {
    width: 27,
    height: 27,
  },
}));

function IconDropdown({ icon, iconButtonProps, iconProps, ariaLabel, children }) {
  const classes = useStyles();
  const PropIcon = icon ? icon : MoreVertIcon;

  let iconButtonClasses = {};

  if (iconButtonProps && iconButtonProps.hasOwnProperty('classes')) {
    iconButtonClasses = mergeWith(
      iconButtonClasses,
      iconButtonProps.classes,
      function (objValue, srcValue) {
        if (isString(objValue)) {
          return `${objValue} ${srcValue}`;
        }
      }
    );
  }

  let iconClasses = {
    root: classes.menuIcon,
  };

  if (iconProps && iconProps.hasOwnProperty('classes')) {
    iconClasses = mergeWith(iconClasses, iconProps.classes, function (objValue, srcValue) {
      if (isString(objValue)) {
        return `${objValue} ${srcValue}`;
      }
    });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.target.parentElement.closest(".MuiListItemSecondaryAction-root").classList.add('click-state');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    document.querySelector('.MuiListItemSecondaryAction-root.click-state').classList.remove('click-state');
    setAnchorEl(null);
  };

  return (

    <React.Fragment>
      <IconButton
        aria-label={ariaLabel}
        aria-controls="icon-dropdown"
        aria-haspopup="true"
        classes={iconButtonClasses}
        onClick={handleClick}
      >
        <PropIcon htmlColor={open ? '#e70a89' : '#000000'} classes={iconClasses} />
      </IconButton>
      <Menu
        id="icon-dropdown"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: 134,
          },
        }}
      >
        {children}
      </Menu>
    </React.Fragment>
  );
}

export default IconDropdown;
