import React from 'react';
import mergeWith from 'lodash/mergeWith';
import isString from 'lodash/isString';
import clsx from 'clsx';
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(({
  formControl: {
    '& > .MuiInputBase-root::before': {
      display: 'none'
    },
    '& > .MuiInputBase-root::after': {
      display: 'none'
    }
  },
  icon: {
    width: 24,
    height: 24,
    fill: '#000000',
  },
  input: {
    minWidth: 74,
    paddingRight: '28px !important',
    border: '1px solid rgba(8, 77, 79, 0.2)',
    borderRadius: 5,
    fontSize: 14,
    // fontWeight: 400,
    fontWeight: 300,
    lineHeight: 1.36,
    textAlign: 'left',
    color: '#000000',
    position: 'relative',
    '&:focus': {
      borderRadius: 5,
      borderColor: 'rgba(8, 77, 79, 0.2)',
      background: 'transparent',
    },
  },
}));

function MenuDropdown(props) {
  const classes = useStyles(props);
  let inputProps = {
    classes: {
      root: classes.input,
      icon: classes.icon
    }
  };

  inputProps = mergeWith(
    inputProps,
    props.selectInputProps,
    function (objValue, srcValue) {
      if (isString(objValue)) {
        return `${objValue} ${srcValue}`;
      }
    }
  );

  return (
    <FormControl className={clsx(classes.formControl, props.className)}>
      {props.showLabel &&
        <InputLabel style={{ marginLeft: "10px" }}>
          {props.label}
        </InputLabel>}
      <Select
        displayEmpty
        value={props.value}
        onChange={props.change}
        inputProps={inputProps}
        IconComponent={props.icon ? props.icon : ExpandMoreIcon}
        disabled={props.disabled ? props.disabled : false}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
      >
        {props.children}
      </Select>
    </FormControl>
  );
}

export default MenuDropdown;
