import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

import MenuDropdown from "../small-components/MenuDropdown/MenuDropdown";
import SearchBox from "../small-components/SearchBox/SearchBox";
import InfoPanel from "../small-components/InfoPanel/InfoPanel";
import AsideDrawer from "../small-components/AsideDrawer/AsideDrawer";
import AlertDialog from "../small-components/AlertDialog/AlertDialog";
import InputField from "../small-components/UI/InputField";
import ColorPicker from "../small-components/ColorPicker/ColorPicker";
import { DefaultButton, OutlineButton } from "../small-components/UI/Buttons";
import Moment from "moment";
import { debounce } from "lodash";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import "../../assets/scss/manage-buttons.scss";
import NoContent from "../small-components/nocontent";
import fire from "../../config/firebase";
import loaderimage from "../../assets/images/PageLoader.svg";
import { getNearestColor, colorArray } from "../../libs/color/colorUtils";
import { getCopyName } from "../../libs/getCopyName";
import { isEmptyString } from "../../libs/utils";

const styles = () => ({
  menuDropdown: {
    marginLeft: 10,
  },
  menuDropdownInput: {
    padding: "5.5px 10px",
    paddingRight: "31px !important",
  },
  menuDropdownIcon: {
    marginRight: "5.5px",
  },
  pagination: {
    width: "auto",
    backgroundColor: "transparent !important",
    boxShadow: "none",
  },
  outerlabel: {
    "& label": {
      fontWeight: 300,
    },
  },

  paginationItem: {
    width: 30,
    height: 30,
    padding: "5px 11px 6px",
    backgroundColor: "transparent !important",
    fontSize: 14,
    fontWeight: 300,
    //  fontWeight: 400,
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  paginationItemIcon: {
    width: 24,
    height: 24,
  },
  paginationItemSelected: {
    backgroundColor: "#084d4f !important",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "rgba(8, 77, 79, 0.9) !important",
    },
  },
  paginationItemDisabled: {
    opacity: "0.5 !important",
  },
  label: {
    marginTop: "30px",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: 1.42,
    letterSpacing: "0.6px",
    color: "#084d4f",
    textTransform: "uppercase",
  },
  buttonWrap: {
    "& > .MuiButton-outlined": {
      marginLeft: 10,
    },
  },
  asideMenuDropdown: {
    marginTop: 10,
    width: "100%",
  },
  asideMenuDropdownInput: {
    padding: 10,
    paddingRight: "31px !important",
  },
  asideMenuDropdownIcon: {
    marginRight: "5.5px",
  },
});
class ManagePhases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "most-recent",
      drawerState: false,
      dialogOpen: false,
      phaseKey: "",
      key: "",
      typeCast: "",
      arrayItem: [],
      page: 1,
      editList: [
        {
          type: "Edit",
          icon: <EditOutlinedIcon htmlColor="#000" />,
          id: "edit",
        },
        {
          type: "Duplicate",
          icon: (
            <FileCopyOutlinedIcon htmlColor="#000" className="mirror_filter" />
          ),
          id: "duplicate",
        },
        {
          type: "Delete",
          icon: <DeleteOutlinedIcon htmlColor="#000" />,
          id: "delete",
        },
      ],
      listToShow: [],
      itemKey: "",
      optionAction: "",
      drawerName: "",
      drawerColor: "",
      drawerType: "",
      saveInfo: false,
      drawerDefaultName: "",
      drawerDefaultColor: "",
      uniqueId: 10,
      loader: true,
      randomColor: "#00ff59",
      // colorArray: ['#ff0000', '#faff00', '#00ff15', '#0010ff', '#ff004a'],
      dataLength: 0,
      drawerNameError: false
    };
    this.items = [...this.state.arrayItem];
    this.paginationCount = 10;
    this.refreshData = this.refreshData.bind(this);
  }
  handleSort = (event) => {
    this.setState({ sort: event.target.value });
  };
  handlePages = (e, key) => {
    const val = key.toLowerCase().split(" ").join("-");
    if (val === "time-based")
      this.setState({
        typeCast: true,
      });
    else
      this.setState({
        typeCast: true,
      });
  };
  inputText = debounce((e) => {
    this.setState({
      key: e,
      page: 1,
    });
  }, 700);
  handleChange = (event) => {
    this.setState({
      age: event.target.value,
      page: 1,
    });
  };
  handlePageChange = (event, value) => {
    this.setState({
      page: value,
    });
  };
  createNew = (e, id) => {
    e.preventDefault();
    document.querySelector(".item-option").classList.remove("activeOption");
    document.getElementById(id).style.display = "block";
    document.querySelector(".estimates-settings").style.display = "block";
  };
  overlay = (e) => {
    e.preventDefault();
    document.querySelector(".overlay").style.display = "block";
  };

  toggleDrawer = (open, type) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === true) {
      const randomIndex = Math.random() * 100000;
      const color = colorArray[Math.floor(randomIndex % colorArray.length)];
      this.setState({ randomColor: color });
    }
    this.setState({ drawerState: open, drawerType: type, drawerNameError: false, drawerName: "" });
  };
  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };
  handleDialogClose = () => {
    this.setState({ dialogOpen: false, drawerName: "" });
  };

  optionChange = debounce((value, itemIndex) => {
    const companyId = localStorage.getItem('companyId');
    if (value === "delete") {
      this.setState({
        dialogOpen: true,
        phaseKey: itemIndex,
        optionAction: value,
      });
    } else if (value === "duplicate") {
      let title = "",
        bordercolor = "";
      for (let i = 0; i < this.state.arrayItem.length; i++) {
        if (this.state.arrayItem[i]["id"] === itemIndex) {
          title = getCopyName(this.state.arrayItem[i]["title"], this.state.arrayItem, 'title')
          bordercolor = getNearestColor(this.state.arrayItem[i]["borderColor"]);
        }
      }
      const listRef = fire.database().ref(companyId).child("phases");
      const itemData = {
        title: title,
        borderColor: getNearestColor(bordercolor),
        date: Moment(new Date()).format("MM/DD/YYYY"),
      };
      listRef.push(itemData);
    } else {
      for (let i = 0; i < this.state.arrayItem.length; i++) {
        if (this.state.arrayItem[i]["id"] === itemIndex) {
          this.setState({
            phaseKey: itemIndex,
            optionAction: value,
            drawerState: true,
            drawerType: "old",
            drawerDefaultName: this.state.arrayItem[i]["title"],
            drawerName: this.state.arrayItem[i]["title"],
            drawerDefaultColor: getNearestColor(
              this.state.arrayItem[i]["borderColor"]
            ),
          });
        }
      }
      setTimeout(() => {
        this.setState({
          optionAction: "",
        });
      }, 1000);
    }
    this.refreshData();
  });
  drawerDelete = () => {
    this.setState({
      dialogOpen: true,
      optionAction: "delete",
    });
  };
  deleteHandler = debounce((key) => {
    const companyId = localStorage.getItem('companyId');
    const userRef = fire.database().ref(companyId).child("phases").child(key);
    userRef.remove();
    this.setState({
      dialogOpen: false,
      drawerState: false,
    });
    this.refreshData();
  });
  closeHandler = () => {
    this.setState({ dialogOpen: false });
  };
  drawerInput = debounce((value) => {
    this.setState({
      drawerName: value,
      drawerNameError: isEmptyString(value)
    });
  }, 300);
  colorHandler = debounce((e) => {
    this.setState({ drawerColor: e });
  }, 300);
  saveItem = () => {
    const companyId = localStorage.getItem('companyId');
    const { drawerName, drawerDefaultName, randomColor,
      drawerDefaultColor, drawerColor, drawerType, phaseKey } = this.state;
    if (isEmptyString(drawerName)) {
      this.setState({
        drawerNameError: true
      });
      return;
    }

    if (drawerType === "new") {
      const listRef = fire.database().ref(companyId).child("phases");
      let colorCode = randomColor;
      if (drawerColor) {
        colorCode = drawerColor;
      }
      const itemData = {
        title: drawerName.trim(),
        borderColor: getNearestColor(colorCode),
        date: Moment(new Date()).format("MM/DD/YYYY"),
        timestamp: Math.floor(Date.now() / 1000),
      };
      listRef.push(itemData);
    } else if (drawerType === "old") {
      let title = "",
        bordercolor = "";
      if (drawerName === "") {
        title = drawerDefaultName;
      } else {
        title = drawerName;
      }
      if (drawerColor === "") {
        bordercolor = getNearestColor(drawerDefaultColor);
      } else {
        bordercolor = getNearestColor(drawerColor);
      }
      const listRef = fire.database().ref(companyId).child("phases").child(phaseKey);
      listRef.update({
        title: title,
        borderColor: getNearestColor(bordercolor),
      });
    }
    this.refreshData();
    this.setState({
      drawerColor: "",
      drawerNameError: false,
      saveInfo: false,
      drawerName: "",
      drawerState: false,
      drawerType: "",
    });
  };
  refreshData() {
    const companyId = localStorage.getItem('companyId');
    const listRef = fire.database().ref(companyId).child("phases");
    listRef.once("value").then((snapshot) => {
      const list = snapshot.val();
      listRef.off("value");
      const listArr = [];
      for (const id in list) {
        listArr.push({ id, ...list[id] });
      }
      this.setState({
        arrayItem: listArr,
        loader: false,
        dataLength: listArr.length,
      });
    });
    const randomIndex = Math.random() * 100000;
    const color = colorArray[Math.floor(randomIndex % colorArray.length)];
    this.setState({ randomColor: color });
  }
  componentDidMount = () => {
    this.refreshData();
  };
  handleCreate = (open, type) => {
    this.setState({ drawerState: open, drawerType: type });
  };
  render() {
    const { classes } = this.props;
    const { drawerType, drawerNameError } = this.state;

    const th = this;
    let listToShow = this.state.arrayItem;
    if (th.state.sort !== "" && th.state.key === "")
      listToShow = this.state.arrayItem.sort(function (a, b) {
        if (th.state.sort === "ascending") {
          return a["title"].localeCompare(b["title"]);
        } else if (th.state.sort === "descending") {
          return b["title"].localeCompare(a["title"]);
        } else if (th.state.sort === "most-recent") {
          return b.timestamp - a.timestamp;
        } else {
          return a["id"].toString().localeCompare(b["id"].toString());
        }
      });
    else if (th.state.sort === "" && th.state.key !== "")
      listToShow = this.state.arrayItem.filter(function (i) {
        return i.title.toLowerCase().match(th.state.key.toLowerCase());
      });
    else if (th.state.sort !== "" && th.state.key !== "")
      listToShow =
        this.state.arrayItem.sort(function (a, b) {
          if (th.state.sort === "ascending") {
            return a["title"].localeCompare(b["title"]);
          } else if (th.state.sort === "descending") {
            return b["title"].localeCompare(a["title"]);
          } else if (th.state.sort === "most-recent") {
            return b.timestamp - a.timestamp;
          } else {
            return a["id"].toString().localeCompare(b["id"].toString());
          }
        }) &&
        this.state.arrayItem.filter(function (i) {
          return i.title.toLowerCase().match(th.state.key.toLowerCase());
        });
    else
      listToShow = this.state.arrayItem.filter(function (i) {
        return i;
      });

    const count = Math.ceil(listToShow.length / th.paginationCount);
    return (
      <React.Fragment>
        {this.state.loader === true ? (
          <div className="loader-container">
            <object data={loaderimage} type="image/svg+xml"></object>
          </div>
        ) : (
          <Box
            className="manage-phases"
            style={{
              paddingTop: 77,
              minHeight: "calc(100vh - 60px)",
              backgroundColor: "rgba(8,77,79,0.05)",
            }}
          >
            {this.state.dataLength > 0 ? <Box
              component="section"
              className="filter-menu"
              style={{ padding: "21px 0 20px" }}
            >
              <Box className="container">
                <Grid container>
                  <Grid item xs={10}>
                    <Typography
                      variant="h5"
                      component="h5"
                      style={{ fontWeight: 100, fontSize: "1.65rem" }}
                    >
                      Manage Phases
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right" style={{ marginTop: 7 }}>
                      <DefaultButton
                        variant="contained"
                        startIcon={<DateRangeOutlinedIcon />}
                        onClick={this.toggleDrawer(true, "new")}
                      >
                        Add Phase
                      </DefaultButton>
                    </Box>
                  </Grid>
                </Grid>
                <Box className="filter-wrap" style={{ marginTop: 25 }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <SearchBox
                        placeholder="Search phases"
                        change={this.inputText}
                      ></SearchBox>
                    </Grid>
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="center"
                      item
                      xs={6}
                    >
                      <MenuDropdown
                        className={classes.menuDropdown}
                        selectInputProps={{
                          classes: {
                            root: classes.menuDropdownInput,
                            icon: classes.menuDropdownIcon,
                          },
                        }}
                        size="small"
                        value={this.state.sort}
                        change={this.handleSort}
                      >
                        <MenuItem value="most-recent">Most Recent</MenuItem>
                        <MenuItem value="ascending">Ascending</MenuItem>
                        <MenuItem value="descending">Descending</MenuItem>
                      </MenuDropdown>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box> : null}

            <Box
              component="section"
              className="panel-list"
              style={{ padding: "0 0 22px" }}
            >
              <Box className="container">
                {listToShow.length > 0 ? (
                  <InfoPanel
                    items={listToShow}
                    page={this.state.page}
                    classes={this.props.classes}
                    list={this.state.editList}
                    optionValue={this.optionChange}
                    paginationLimit={this.paginationCount}
                    searchWords={this.state.key}
                    pagename="phases"
                  />
                ) : (
                  <NoContent title="Phases. Break it down."
                    description="Add phases here to break your projects down into smaller steps. The estimate will show how much each phase costs to get a more detailed picture."
                    Button_type="phase"
                    createNew={this.handleCreate}
                    searching={this.state.dataLength > 0}
                  />
                )}
              </Box>
            </Box>
            <Box component="aside" style={{ marginLeft: 79 }}>
              <AsideDrawer
                toolbarTitle={`${drawerType === "new" ? "Add" : "Edit"
                  } Phase`}
                open={this.state.drawerState}
                close={this.toggleDrawer(false)}
              >
                <Typography className={classes.label} id="name">
                  Name
                </Typography>
                <InputField
                  className={
                    drawerNameError ? "errorInput" : classes.outerlabel
                  }
                  label="Phase Name"
                  defaultValue={
                    this.state.drawerType === "old"
                      ? this.state.drawerDefaultName
                      : ""
                  }
                  aria-labelledby="name"
                  onChange={(e) => this.drawerInput(e.target.value)}
                />
                <Typography className={classes.label} id="color-picker">
                  Color
                </Typography>
                <ColorPicker
                  colorCode={this.colorHandler}
                  defaultColor={
                    this.state.drawerType === "old"
                      ? this.state.drawerDefaultColor
                      : this.state.randomColor
                  }
                />
                <Box className={classes.buttonWrap} mt={2}>
                  <DefaultButton variant="contained" onClick={this.saveItem}>
                    Save
                  </DefaultButton>
                  {this.state.drawerType === "old" ? (
                    <OutlineButton
                      variant="outlined"
                      onClick={this.drawerDelete}
                    >
                      Delete
                    </OutlineButton>
                  ) : (
                    ""
                  )}
                </Box>
              </AsideDrawer>
            </Box>
          </Box>
        )}

        <AlertDialog
          open={this.state.dialogOpen}
          onClose={this.closeHandler}
          phaseKey={this.state.phaseKey}
          keyValue={this.deleteHandler}
          name={"phase"}
          text={""}
          button={""}
        />
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ManagePhases);
