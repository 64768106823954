import React from 'react';
import '../../../assets/scss/summary.scss';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import formatMoneyStr from '../../../libs/formatMoneyStr';
import formatHour from '../../../libs/formatHour';
import InputField from "../../small-components/UI/InputField";
export default class Calculations extends React.Component {
    state = {
    }

    render() {
        const { calculation:calculationCopy, totalPhaseCost, phaseCalculationsOverwrite,
            enableCalculationToggleHandle, totalPhaseHoursWithoutAuto, totalPhasePriceWithoutAuto,
            enabled, updatePhase
        } = this.props;
        const calculation = JSON.parse(JSON.stringify(calculationCopy || {}));
        const {
            legend,
            figure,
            type,
        } = calculation;
        const getNotNan = (val) => {
            const result = isNaN(val) ? 0 : val;
            if (result < 1) {
                return result.toFixed(2);
            }
            return result.toFixed(2);
        }
        const getHours = () => {
            if (enabled) {
                const result = getNotNan((figure * totalPhaseHoursWithoutAuto) / 100);
                return result * (type === 'negative' ? -1 : 1);
            }
            return 0;
        }
        let paddingLeft = 0;

        if (figure !== undefined) {
            const len = figure.toString().length;
            if (len === 4) {
                paddingLeft = 20;
            }
            if (len === 3) {
                paddingLeft = 25;
            }
            if (len === 2) {
                paddingLeft = 32;
            }
            if (len === 1) {
                paddingLeft = 39;
            }
        }
        paddingLeft = 26;
        return (
            <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"

            >
                <Grid item xs={6}>
                    <Paper>
                        <Grid
                            className="task-inner-row"
                            container
                            spacing={3}
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item xs={2} className="switch-wrap">
                                <Paper>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            id="togBtn"
                                            checked={enabled}
                                            onChange={() => {
                                                enableCalculationToggleHandle(calculation);
                                            }}
                                        />
                                        <div className="slider round">
                                            <span className="on"></span>
                                            <span className="off"></span>
                                        </div>
                                    </label>
                                </Paper>
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                className="switch-title-wrap"

                            >
                                <Paper >
                                    {/* <input
                                        type="number"
                                        id="myPercent"
                                        disabled={true}
                                        // className="switchInput"
                                        // style={{ width: '40px' }}
                                        value={figure + '%'}
                                    /> */}
                                    <div >
                                        <InputField
                                            type="number"
                                            id="myPercent"
                                            disabled={enabled !== true}
                                            style={{ width: '100px', marginLeft: 20, marginRight: 0, textAlign: 'end', paddingLeft: paddingLeft }}
                                            value={type === 'negative' ? -Math.abs(figure) : figure}
                                            onChange={(e) => {
                                                phaseCalculationsOverwrite[calculation.id] = calculation;
                                                phaseCalculationsOverwrite[calculation.id].figure = e.target.value && e.target.value.length ? e.target.value : 0;
                                                phaseCalculationsOverwrite[calculation.id].figure = Math.min(100, parseInt(phaseCalculationsOverwrite[calculation.id].figure));
                                                if (phaseCalculationsOverwrite[calculation.id].figure < 0 && phaseCalculationsOverwrite[calculation.id].figure < -100) {
                                                    phaseCalculationsOverwrite[calculation.id].figure = -100;
                                                }
                                                updatePhase({
                                                    phaseCalculationsOverwrite
                                                });
                                            }}
                                        />
                                        <span
                                            style={{
                                                position: 'absolute', left: '90px', marginTop: '10px',
                                                color: enabled ? 'black' : 'gray'
                                            }}
                                        >%</span>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs
                                className="switch-title-wrap">
                                <Paper>{legend}</Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid >
                <Grid item xs={6} className="calc-values-wrap">
                    <Grid item xs className="wrap-count">
                        <Paper>
                            {
                                formatHour({ number: getHours() })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs className="cost-wrap">
                        <Paper>
                            {
                                formatMoneyStr({
                                    number: enabled
                                        ? getNotNan((type === 'negative' ? -1 : 1) * (figure) * totalPhaseCost / 100) : 0
                                })
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs className="price-wrap">
                        <Paper>
                            {formatMoneyStr({
                                number: enabled
                                    ? getNotNan((type === 'negative' ? -1 : 1) * (figure / 100) * totalPhasePriceWithoutAuto) : 0
                            })
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid >
        );
    }
}
