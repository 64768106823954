import React, { Component } from 'react';
import Chart from "react-apexcharts";
import Moment from "moment";
import EstimateCalculation from "../small-components/Phase/estimateCalculationUtil";
class Graph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      estimates: this.props.estimates,
      seriesQuarterly: [{
        name: '',
        data: [31, 40, 47, 40, 45, 75, 81]
      }],
      seriesMonth: [{
        name: '',
        data: [31, 40, 47, 40, 45, 75, 81]
      }],
      seriesYear: [{
        name: '',
        data: [31, 40, 47, 40, 45, 75, 81]
      }],
      optionsQuarterly: {
        chart: {
          height: 350,
          type: 'area'
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        title: {
          text: this.props.title,
          align: 'left'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: ['05/05', '05/06', '05/07', '05/08', '05/09', '05/10', '05/11'],
        },
      },
      optionsMonth: {
        chart: {
          height: 350,
          type: 'area'
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        title: {
          text: this.props.title,
          align: 'left'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: ['05/05', '05/06', '05/07', '05/08', '05/09', '05/10', '05/11'],
        },
      },
      optionsYear: {
        chart: {
          height: 350,
          type: 'area'
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        title: {
          text: this.props.title,
          align: 'left'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: ['05/05', '05/06', '05/07', '05/08', '05/09', '05/10', '05/11'],
        },
      },
    };
  }
  quarterlyGraph = () => {
    const dataY = [],
      dataX = [],
      newdataY = [],
      newdataX = [],
      data = this.state.estimates;
    let subtotal = 0,
      quarterMonthNew = "",
      count = 0;
    for (let i = 0; data.length > i; i++) {
      const estimateCalculator = new EstimateCalculation(
        data[i],
        this.props.calculations
      );
      let total = estimateCalculator.totalEstimatePrice();
      const date = Moment(data[i]['date']).format("MM/DD/YYYY");
      let quarterMonth = 0;
      if (!total) {
        total = 0;
      }
      const month = date.split('/')[0];
      const years = date.split('/')[2];
      if (month <= 3) {
        quarterMonth = 3;
      } else if (month > 3 && month <= 6) {
        quarterMonth = 6;
      } else if (month > 6 && month < 9) {
        quarterMonth = 9;
      } else if (month > 9 && month <= 12) {
        quarterMonth = 12;
      }
      if (i === 0) {
        quarterMonthNew = quarterMonth;
      }
      if (quarterMonthNew !== quarterMonth) {
        quarterMonthNew = quarterMonth;
        dataY.unshift(subtotal.toFixed(2));
        const date = Moment(data[i - 1]['date']).format("MM/DD/YYYY");
        const year = date.split('/')[2];
        count = count + 1;
        dataX.unshift(year);
        subtotal = total;
      } else {
        subtotal = subtotal + total;
      }
      if (i === data.length - 1) {
        dataY.unshift(subtotal.toFixed(2));
        count = count + 1;
        dataX.unshift(years);
      }
    }
    let lenX = dataX.length
    if (dataX.length > 4) {
      for (let i = 0; lenX - 4 > i; i++) {
        dataX.shift()

      }
    }
    let lenY = dataY.length
    if (dataY.length > 4) {
      for (let i = 0; lenY - 4 > i; i++) {
        dataY.shift()

      }
    }
    for (let k = 0; dataX.length > k; k++) {
      newdataX.push("Q" + (k + 1) + " " + dataX[k]);
      newdataY.push(dataY[k]);
    }
    this.setState({
      seriesQuarterly: [
        {
          name: "",
          data: newdataY
        }
      ],
      optionsQuarterly:
      {
        xaxis: {
          categories: newdataX
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              let finalVal = Math.round(val)

              return "$" + finalVal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        },
        dataLabels: {
          formatter: function (val) {
            let finalVal = Math.round(val)

            return "$" + finalVal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          }
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        title: {
          text: "Total Estimate Amount Per Quarter",
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }

      }

    }
    );
  }
  monthGraph = () => {
    const dataY = [],
      dataX = [],
      data = this.state.estimates;
    let subtotal = 0,
      monthNew = "";
    for (let i = 0; data.length > i; i++) {
      const estimateCalculator = new EstimateCalculation(
        data[i],
        this.props.calculations
      );
      let total = estimateCalculator.totalEstimatePrice();
      const date = Moment(data[i]['date']).format("MM/DD/YYYY");
      if (!total) {
        total = 0;
      }
      const month = date.split('/')[0];
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthName = months[month - 1];
      const year = date.split('/')[2];
      if (i === 0) {
        monthNew = month;
      }
      if (monthNew !== month) {
        monthNew = month;
        dataY.unshift(subtotal.toFixed(2));
        const date = Moment(data[i - 1]['date']).format("MM/DD/YYYY");
        const mon = date.split('/')[0];
        const monName = months[mon - 1];
        const y = date.split('/')[2]
        dataX.unshift(monName + " " + y);
        subtotal = total;
      } else {
        subtotal = subtotal + total;
      }
      if (i === data.length - 1) {
        dataY.unshift(subtotal.toFixed(2));
        dataX.unshift(monthName + " " + year);
      }
    }
    let lenX = dataX.length
    if (dataX.length > 12) {
      for (let i = 0; lenX - 12 > i; i++) {
        dataX.shift()

      }
    }
    let lenY = dataY.length
    if (dataY.length > 12) {
      for (let i = 0; lenY - 12 > i; i++) {
        dataY.shift()

      }
    }
    this.setState({
      seriesMonth: [
        {
          name: "",
          data: dataY
        }
      ],
      optionsMonth:
      {
        xaxis: {
          categories: dataX
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              let finalVal = Math.round(val)

              return "$" + finalVal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        },
        dataLabels: {
          formatter: function (val) {
            let finalVal = Math.round(val)

            return "$" + finalVal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          }
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        title: {
          text: "Total Estimate Amount Per Month",
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }

      }
    });
  }
  yearGraph = () => {
    const dataY = [],
      dataX = [],
      data = this.state.estimates;
    let subtotal = 0,
      yearNew = "";
    for (let i = 0; data.length > i; i++) {
      const estimateCalculator = new EstimateCalculation(
        data[i],
        this.props.calculations
      );
      let total = estimateCalculator.totalEstimatePrice();
      const date = Moment(data[i]['date']).format("MM/DD/YYYY");
      if (!total) {
        total = 0;
      }
      const year = date.split('/')[2];
      if (i === 0) {
        yearNew = year;
      }
      if (yearNew !== year) {
        yearNew = year;
        dataY.unshift(subtotal.toFixed(2));
        const date = Moment(data[i - 1]['date']).format("MM/DD/YYYY");
        const y = date.split('/')[2]
        dataX.unshift(y);
        subtotal = total;
      } else {
        subtotal = subtotal + total;
      }
      if (i === data.length - 1) {
        dataY.unshift(subtotal.toFixed(2));
        dataX.unshift(year);
      }

    }
    this.setState({
      seriesYear: [
        {
          name: "",
          data: dataY
        }
      ],
      optionsYear:
      {
        xaxis: {
          categories: dataX
        },
        dataLabels: {
          formatter: function (val) {
            let finalVal = Math.round(val)

            return "$" + finalVal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              let finalVal = Math.round(val)

              return "$" + finalVal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        title: {
          text: "Total Estimate Amount Per Year",
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }

      }

    }
    );
  }
  componentDidMount = () => {
    this.quarterlyGraph();
    this.monthGraph();
    this.yearGraph();

  }

  render() {

    return (

      <div id="chart">
        {this.props.graphType === 'quarterly' && (
          <Chart options={this.state.optionsQuarterly} series={this.state.seriesQuarterly} type={this.props.type} height={350} />
        )}
        {this.props.graphType === 'month' && (
          <Chart options={this.state.optionsMonth} series={this.state.seriesMonth} type={this.props.type} height={350} />
        )}
        {this.props.graphType === 'year' && (
          <Chart options={this.state.optionsYear} series={this.state.seriesYear} type={this.props.type} height={350} />
        )}
      </div>

    );
  }

}

export default Graph;