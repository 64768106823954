import React from "react";
import { withRouter } from "react-router-dom";
import './signup.scss';
import fire from '../../../config/firebase';
import queryString from 'query-string';
import estimatic_logo from '../../../assets/images/Estimatic_Logo.svg';
import { Typography, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { ObjectToList } from "../../../libs/utils";
class signupForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.signup = this.signup.bind(this);
    this.state = {
      email: '',
      password: '',
      errors: []
    };
    this.checkPassword = this.checkPassword.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  checkPassword(pass) {
    const errors = [];
    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(pass)) {
      errors.push('whitespace');
    }

    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(pass)) {
      errors.push("Uppercase");
    }

    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(pass)) {
      errors.push("Lowercase");
    }

    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(pass)) {
      errors.push("Digit");
    }

    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    if (!isContainsSymbol.test(pass)) {
      errors.push("Special");
    }

    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(pass)) {
      errors.push("Length");
    }

    this.setState({ errors });
    return errors.length === 0;

  }
  signup(e) {
    e.preventDefault();

    if (this.checkPassword(this.state.password) === false) {
      return false;
    }
    const { email, logintoken } = queryString.parse(this.props.location.search);

    if (email && logintoken) {
      fire.auth().signInWithEmailAndPassword(email, logintoken).then(({ user }) => {
        user.updatePassword(this.state.password).then(async () => {
          const userCompanyRef = fire.database().ref('userCompany').child(user.uid);
          const userCompanySnap = await userCompanyRef.once('value');
          const userCompany = userCompanySnap.val();
          const { companyId } = userCompany || {};
          localStorage.setItem('companyId', companyId);
          const usersRef = fire.database().ref(companyId).child("users");
          const snapshot = await usersRef.once('value');
          const list = snapshot.val();
          const userList = ObjectToList(list);
          const userDb = userList.find(d => d.caption === email);
          if (userDb) {
            const userData = {
              unconfirmed: false
            };
            const userRef = fire.database().ref(companyId).child("users").child(user.uid);
            userRef.update(userData);
          }
          window.location.pathname = '/dashboard';
        }).catch(() => {

        });
      }).catch(() => {

      });
    }
  }

  render() {
    const companyId = this.props.match.params.companyId;

    if (!this.state.companyId && companyId) {

      this.setState({ companyId });
      localStorage.setItem('companyId', companyId);
    }
    const getLi = (text, code) => {
      return <li style={{ color: this.state.errors.find(d => d === code) ? 'red' : '' }}>{text}
      </li>
    };
    const { email = '' } = queryString.parse(this.props.location.search || {});
    return (
      <div className="no-pad-bot sign-up-wrap">
        <div autoComplete="off" className="form-wrap">
          <Grid container spacing={3} justify="center" className="inner-wrap">
            <Grid item xs={5} className="large-block">
              <div className="img-wrap">
                <img src={estimatic_logo} alt="Estimatic" />
              </div>
              <p className="text-wrap">
                In faucibus nulla vel urna fringilla congue. Pellentesque scelerisque condimentum enim a facilisis. Donec
                feugiat odio non.
              </p>
            </Grid>

            <Grid item xs={6} className="small_block">
              <form className="form">
                <Typography variant="h5" component="h5" style={{ fontWeight: 300 }}>
                  User Sign Up
                </Typography>
                <TextField id="email" variant="outlined"
                  type="text"
                  label="Email"
                  name="email"
                  value={email}
                  // onChange={this.handleChange}
                  style={{ fontWeight: 300 }}
                />
                <FormControl className="" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type='password'
                    value={this.state.password}
                    name="password"
                    onChange={this.handleChange}
                    placeholder="Password"
                    className="validate"
                    v-model="password"
                    style={{ fontWeight: 300 }}
                    labelWidth={70}
                  />
                </FormControl>
                {/* {
                  this.state.errors.length > 0 ? <ul>
                    {
                      this.state.errors.map((msg, index) => {
                        return <li key={index}>{msg}</li>
                      })
                    }
                  </ul> : null} */}

                <Typography style={{ fontWeight: 300 }}>
                  Password must:
                </Typography>
                <ol style={{ listStyle: 'square' }}>
                  {getLi('not contain Whitespaces.', 'whitespace')}
                  {getLi('have at least one Uppercase Character.', 'Uppercase')}
                  {getLi('have at least one Lowercase Character.', 'Lowercase')}
                  {getLi('have at least one Digit.', 'Digit')}
                  {getLi('have at least one Special Character.', 'Special')}
                  {getLi('8-16 Characters Long.', 'Length')}
                </ol>
                <Button
                  variant="contained"
                  type="button"
                  className='btn_wrap'
                  onClick={this.signup}
                  name="action"
                >
                  Submit
                </Button>
              </form>
            </Grid>
          </Grid>

        </div>
      </div >
    )
  }
}

export default withRouter(signupForm);
