import React, { Component } from "react";
import "./DrawerOverlay.scss";
//import '../../assets/scss/_variables.scss';

import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import NumberFormat from "react-number-format";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Typography, Grid } from "@material-ui/core";
class Discounted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discountValue: props.estimates.discountValue || null,
      discountType:  props.estimates.discountType || "",

    };
  }
  disountTypeFun = (type) => {
    this.setState({ discountType: type });
  };
  discountRe = (value) => {
    this.setState({ discountValue: value });
  };
  handleApplyDiscount = () => {
    const { estimates = {} } = this.props;
    const discountType = this.state.discountType || estimates.discountType || 0;
    let discountValue = parseInt(this.state.discountValue || estimates.discountValue || 0);
    if (discountType === "%" && discountValue > 100) {
      return;
    }
    if (discountType === "0") {
      return;
    } else if (discountType === "$" && this.props.price - discountValue < 0) {
      discountValue = this.props.price;
    }

    this.props.update({
      discountValue,
      discountType,
    });
    this.props.onClose("right", false);
  };
  dicountedLable = () => {
    if (this.state.discountType === '') {
      return 'Amount'
    }
    if (this.state.discountType === '$' && this.state.discountValue > this.props.price) {
            return 'Cannot Exceed Total'
    }
    if (this.state.discountType === '' && this.state.discountValue > this.props.price) {
            return 'Cannot Exceed Total'
    } else if (this.state.discountType === '%' && this.state.discountValue >= 100) {
            return 'Max Amount is 100%'
    } else {
      return 'Amount'
    }
  }
  disableClass = () => {
    if (this.state.discountType === '') {
      return 'amount'
    }
    if (this.state.discountType === '$' && this.state.discountValue > this.props.price) {
      return 'amount_error'
    }
    if (this.state.discountType === '' && this.state.discountValue > this.props.price) {
      return 'amount_error'
    } else if (this.state.discountType === '%' && this.state.discountValue >= 100) {
      return 'amount_error'
    } else {
      return 'amount'
    }
  }
  componentDidMount = () => {
    if (this.props.discountType) {
      this.setState({ discountType: this.props.discountType });
    }
  };
  render() {
    const { estimates = {} } = this.props;
    const discountType = this.state.discountType || estimates.discountType || 0;
    const discountValue =
      this.state.discountValue || estimates.discountValue || 0;
    return (
      <div className="side-item">
        <div className="title">
          <h5>
            Apply Discount
            <div className="cross" onClick={this.props.onClose}>
              <CloseIcon htmlColor="#084d4f" />
            </div>
          </h5>
        </div>
        <div className="content">
          {/*<img src={discount} alt="discount" />*/}
          <p>
            {" "}
            Please enter the discount amount and select a type to apply.
          </p>
        </div>
        <div className="input-wrap">
          <Grid container spacing={1}>
            <Grid item className="d_value discount-overlay">
              <Typography variant="h3" component="p">
                Discount
              </Typography>
              <NumberFormat
                allowNegative={false}
                isNumericString={true}
                thousandSeparator={true}
                placeholder="Amount"
                defaultValue={discountValue}
                className={this.disableClass()}
                onValueChange={(values) => {
                  const { value } = values;
                  value <= 100 ? this.discountRe(value) : this.discountRe(100);
                  this.discountRe(value);
                }}
              />
              <label className={this.disableClass()}>
                {
                  this.dicountedLable()
                }
              </label>
            </Grid>
            <Grid item className="d_type">
              <Typography variant="h3" component="p">
                Type
              </Typography>
              <Button
                variant="contained"
                type="button"
                className={discountType === "%" ? "active type_percentage" : "type_percentage"}
                onClick={() => this.disountTypeFun("%")}
              >
                %
              </Button>
              <Button
                variant="contained"
                type="button"
                className={discountType === "$" ? "active type_dollar" : "type_dollar"}
                onClick={() => this.disountTypeFun("$")}
              >
                <AttachMoneyIcon />
              </Button>
            </Grid>
            <Button
              variant="contained"
              type="button"
              className="apply"
              disabled={this.state.discountType==="" || (this.state.discountValue === "" || this.state.discountValue === '0' || this.state.discountValue === 0)}
              onClick={() => {
                this.handleApplyDiscount();
              }}
            >
              Apply
            </Button>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Discounted;
