import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { DefaultButton } from "../../small-components/UI/Buttons";
import Divider from "@material-ui/core/Divider";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import Button from "@material-ui/core/Button";
import { InsertLink } from "@material-ui/icons";
import InputField from "../../small-components/UI/InputField";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { FormControl } from '@material-ui/core';
import { LanguageOutlined } from '@material-ui/icons';
import { getFunctionUrl } from '../../../config/firebase';
import { Alert } from '@material-ui/lab';
import validator from 'validator';
const useStyles = makeStyles(() => ({
  alertMessage: {
    marginTop: '30px'
  },
  switch_track: {
    backgroundColor: "#084d4f",
    opacity: 0.1
  },
  linkShareToolbarRoot: {
    justifyContent: 'space-between',
    padding: '0'
  },
  linkShareToolbarLeft: {
    justifyContent: 'flex-start',
    marginLeft: '-3px',
    // marginTop: '10px'
  },
  linkShareToolbarGutters: {
    // padding: '10px ',
  },
  iconButton: {
    paddingBottom: '10px'
  },
  alertIcon: {
    width: 36,
    height: 36,
    marginRight: '-5px'
  },
  linkSharePaper: {
    maxWidth: '550px !important',
    paddingBottom: '20px',
    borderRadius: '5px !important',
    textAlign: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '10px',
  },
  linkShareTitle: {
    padding: '0 !important',
    '& > *': {
      fontSize: 20,
      fontWeight: 'normal',
      lineHeight: 1.35,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#000000',
    }
  },
  linkShareText: {
    marginBottom: '0px !important',
    fontSize: '14px !important',
    fontWeight: 'normal !important',
    lineHeight: '1.36 !important',
    letterSpacing: 'normal !important',
    textAlign: 'center',
    color: '#000000 !important',
  },
  alertActions: {
    padding: '30px 30px 0 !important',
    justifyContent: 'center !important',
    '& > :not(:first-child)': {
      fontWeight: 300
      //fontWeight: 400
    }
  },
  divider: {
    width: "100%",
    margin: "0 auto",
    backgroundColor: "rgba(8, 77, 79, 0.2)",
  },

  copyLinkButton: {
    width: '100%',
    height: '40px',
    margin: '0px 0 20px',
    padding: '11px 165.9px 10px 166px',
    borderRadius: '5px',
    backgroundColor: '#084d4f',
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#084d4f',
      opacity: 1

    }
  },

  shareWithEstimaticUsers: {
    width: '300px',
    height: '20px',
    margin: '16px 0px 0px 0px',
    fontFamily: 'NotoSans',
    fontSize: '20px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.35',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
    marginLeft: '10px',
  },
  iconPosition: {
    width: '10px',
    margin: '12px 0 0 0',
  },
  emailAddress: {
    marginLeft: '0px',
    width: '80%',
    marginRight: '0px',
  },
  sendButton: {
    marginTop: '10px',
    marginRight: '15px',
    width: '20%',
    marginLeft: '-15px'
  },
  rightSwitchProps: {
    marginLeft: '5px'
  },
  leftSwitchProps: {
    marginLeft: '0px'
  },
  switchpos: {
    marginTop: '-30px'
  }
}));

export default function ShareDialog(props) {
  const classes = useStyles();
  const [hideFinance, setHideFinance] = useState(true);
  const [hideResource, setHideResource] = useState(true);
  const [internalEmail, setInternalEmail] = useState('')
  const [publicEmail, setPublicEmail] = useState('');
  const [internalEmailLabel, setInternalEmailLabel] = useState('');
  const [publicEmailLabel, setPublicEmailLabel] = useState('')

  const handleEmail = (email, mailType) => {
    if (mailType === "internal") {
      setInternalEmail(email)
    } else { setPublicEmail(email) }
    let validemail = ""
    if (validator.isEmail(email)) {
      validemail = "";
    } else {
      validemail = "Incorrect Email";
    }
    if (validemail !== "") {
      mailType === "internal" ? setInternalEmailLabel(validemail) : setPublicEmailLabel(validemail)
    }
    else if (validemail === "") {
      mailType === "internal" ? setInternalEmailLabel("Email Address") : setPublicEmailLabel("Email Address")
    }
  };

  const sendSharedEstimateLink = async ({ email, publicUrl }) => {
    if (email) {
      let sharedEstimateLink;
      if (publicUrl) {
        sharedEstimateLink = props.onShareEstimate({ hideResource, hideFinance });
      } else {
        sharedEstimateLink = window.location.href;
      }
      setSharedSuccesfully(email);
      await axios.get(`${getFunctionUrl()}/sendsharedestimate?email=${encodeURIComponent(email)}&estimatelink=${encodeURIComponent(sharedEstimateLink)}&time=${new Date().getTime()}`,
        {
          headers: {
            // 'access-control-request-headers': 'content-type,x-client-version',
            'accept': '*/*'
          }
        });

    }
  }

  const [internalCopied, setInternalCopied] = React.useState(false);
  const [publicCopied, setPublicCopied] = React.useState(false);
  // publicEmail

  const handleCopyInternal = () => {
    setInternalCopied(true);
    setPublicCopied(false);
    navigator.clipboard.writeText(window.location.href);
  };
  const handlePublicCopied = () => {
    setInternalCopied(false);
    setPublicCopied(true);
    navigator.clipboard.writeText(props.onShareEstimate({ hideResource, hideFinance }));
  };
  const handleClose = () => {
    props.closeDialog()
  }
  const [sharedSuccesfully, setSharedSuccesfully] = useState(null);

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={props.open}
        //  onClose={props.onClose}
        aria-labelledby="linkShare-dialog-title"
        aria-describedby="linkShare-dialog-description"
        classes={{ paper: classes.linkSharePaper }}
        className="linkShare-overlay"
      >
        {sharedSuccesfully && (
          <div className={classes.alertMessage}>
            <Alert
              onClose={() => setSharedSuccesfully(null)}
              variant="filled"
              icon={<CheckCircleOutlinedIcon />}
              severity='success'
            >
              Estimate has been shared succesfully with {sharedSuccesfully}.
            </Alert>
          </div>
        )}
        <Toolbar classes={{ root: classes.linkShareToolbarRoot, gutters: classes.linkShareToolbarGutters }}>
          <DialogTitle id="linkShare-dialog-title" className={`${classes.linkShareTitle}`}>
            Share Estimate
          </DialogTitle>

          <IconButton aria-label="delete" onClick={handleClose} className={`close-overlay ${classes.iconButton}`} style={{ paddingRight: '0px' }}>
            <CloseOutlinedIcon className={classes.alertIcon} htmlColor="#084d4f80" />
          </IconButton>
        </Toolbar>
        <Divider className={classes.divider} />
        <div className={classes.linkShareToolbarLeft} >
          <div className="row" style={{ marginBottom: '10px' }}>
            <div className={classes.iconPosition}>
              <GroupOutlinedIcon htmlcolor="#000000" fontSize='large' />
            </div>
            <div className={classes.shareWithEstimaticUsers}>
              Share with Estimatic Users
            </div>
          </div>
        </div>
        <Button
          component="label"
          variant="contained"
          className={classes.copyLinkButton}
          startIcon={<InsertLink />}
          onClick={handleCopyInternal}
          disableElevation={true}
        >
          {internalCopied ? 'Link copied!' : 'Copy link'}
        </Button>
        <div className="row">
          <div className={classes.emailAddress}>
            <InputField
              className={`${internalEmailLabel !== "Email Address" && internalEmail
                ? "errorState"
                : ""
                }`}
              label={`${internalEmailLabel !== "Email Address" && internalEmail
                ? internalEmailLabel
                : "Email Address"
                }`}
              aria-labelledby="email"
              defaultValue={''}
              onChange={(e) => handleEmail(e.target.value, "internal")}
            />
          </div>
          <div className={classes.sendButton} >
            <DefaultButton disabled={internalEmailLabel === "Email Address" ? false : true} onClick={() => sendSharedEstimateLink({ email: internalEmail, publicUrl: false })} color="primary" className="delete-confirm" >
              Send
            </DefaultButton>
          </div>
        </div>
        <Divider className={classes.divider} />
        {(!props.isTemplate && !props.isTimebased) &&
          <div className={classes.linkShareToolbarLeft} >
            <div className="row" style={{ marginBottom: '0', marginTop: '25px' }}>
              <div className={classes.iconPosition}>
                <LanguageOutlined htmlcolor="#000000" fontSize='large' />
              </div>
              <div className={classes.shareWithEstimaticUsers} >
                Share Public Link
              </div>
            </div>
          </div>
        }
        {(!props.isTemplate && !props.isTimebased) &&
          <div className={classes.switchpos}>
            <div id="setting" className="side-item">
              <div className='row' >
                <div className={classes.leftSwitchProps} >
                  <FormControl component="fieldset">
                    <FormGroup className="bill_switch" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                      <FormControlLabel
                        control={<Switch checked={hideFinance}
                          onChange={() => { setHideFinance(!hideFinance) }}
                          name='financials' size='medium'
                        />}
                        label="Share Financials"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                <div className={classes.rightSwitchProps} >
                  <FormGroup className="bill_switch" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                    <FormControlLabel
                      control={<Switch checked={hideResource}
                        onChange={() => { setHideResource(!hideResource) }}
                        name='resources' size='medium'
                      />}
                      label="Share Resources"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        }
        {(!props.isTemplate && !props.isTimebased) &&
          <Button
            component="label"
            variant="contained"
            className={classes.copyLinkButton}
            startIcon={<InsertLink />}
            onClick={handlePublicCopied}
            disableElevation={true}
          >
            {publicCopied ? 'Link copied!' : 'Copy link'}
          </Button>
        }
        {(!props.isTemplate && !props.isTimebased) &&
          <div className="row">
            <div className={classes.emailAddress}>
              <InputField
                className={`${publicEmailLabel !== "Email Address" && publicEmail
                  ? "errorState"
                  : ""
                  }`}
                label={`${publicEmailLabel !== "Email Address" && publicEmail
                  ? publicEmailLabel
                  : "Email Address"
                  }`}
                aria-labelledby="email"
                defaultValue={''}
                onChange={(e) => handleEmail(e.target.value, "public")}
              />
            </div>
            <div className={classes.sendButton} >
              <DefaultButton disabled={publicEmailLabel === "Email Address" ? false : true} onClick={() => sendSharedEstimateLink({ email: publicEmail, publicUrl: true })} color="primary" className="delete-confirm" >
                Send
              </DefaultButton>
            </div>
          </div>

        }




      </Dialog>
    </React.Fragment>
  );
}